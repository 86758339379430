import { formatDate } from '../../../../../../utils/formatDate';
import { t } from 'react-switch-lang';
import React from 'react';
import { eng, formatNumber, hasValue } from '../../../../../../utils/const';
import classes from '../../Cattle.module.scss';

const renderColWithTooltip = (col, date) => {
  // return <Tooltip title={hasValue(date) ? formatDate(col) : hasValue(col) && col} placement="top" color={'#67B278'}
  //                 overlayInnerStyle={{ borderRadius: '5px' }}>
  return hasValue(col) ? (date ? formatDate(col) : col) : '-';
  // </Tooltip>;
};

const renderBigCol = (col1) => {
  // return <Tooltip title={col} placement="top" color={'#67B278'}
  //                 overlayInnerStyle={{ borderRadius: '5px' }}>
  return (
    <div className="d-flex flex-column">
      <div className={`${classes['customColumn']} mb-2`}>
        {hasValue(col1) ? formatNumber(col1) : '-'}
      </div>
      <div>{'-'}</div>
    </div>
  );
  // </Tooltip>;
};

const renderBigColTwoFields = (col1, col2) => {
  // return <Tooltip title={col1 + ' ' + col2} placement="top" color={'#67B278'}
  //                 overlayInnerStyle={{ borderRadius: '5px' }}>
  return (
    <div className="d-flex flex-column">
      <div className={`${classes['customColumn']} mb-2`}>
        {hasValue(col1) ? formatNumber(col1) : '-'}
      </div>
      <div>{hasValue(col2) ? formatNumber(col2) : '-'}</div>
    </div>
  );
  // </Tooltip>;
};

export const columns = [
  {
    title: t('pages.cattle.categoryNameCattle'),
    dataIndex: eng ? 'categoryNameEn' : 'categoryName',
    key: 'categoryName',
    render: (categoryName) => renderColWithTooltip(categoryName.toUpperCase()),
  },
  {
    title: t('pages.cattle.openingQuantity'),
    dataIndex: 'openingQuantity',
    key: 'openingQuantity',
    render: (openingQuantity) => renderBigCol(openingQuantity),
  },
  {
    title: t('pages.cattle.comment'),
    dataIndex: 'comment',
    key: 'comment',
    render: (comment) => renderColWithTooltip(comment),
  },
  {
    title: t('pages.cattle.input').toUpperCase(),
    dataIndex: ['inputQuantity'],
    key: 'input',
    render: (text, row) => renderBigCol(row['inputQuantity']),
  },
  {
    title: t('pages.cattle.output').toUpperCase(),
    dataIndex: ['outputQuantity'],
    key: 'output',
    render: (text, row) => renderBigCol(row['outputQuantity']),
  },
  {
    title: t('pages.cattle.death'),
    dataIndex: ['deathQuantity'],
    key: 'death',
    render: (text, row) => renderBigColTwoFields(row['deathQuantity']),
  },
  {
    title: t('pages.cattle.closing'),
    dataIndex: ['closingQuantity'],
    key: 'closing',
    render: (text, row) =>
      renderBigColTwoFields(hasValue(row['closingQuantity']) ? row['closingQuantity'] : '-'),
  },
];
