import { t } from 'react-switch-lang';
import { eng } from '../../../../../../../utils/const';

const goatsBasicFields = [
  {
    value: 'goatCategory',
    label: () => t('pages.goat.categoryNameGoat'),
    type: 'select', // integer, double, select, textarea, string
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api:'goat-categories',
      optionValue: 'id',
      optionLabel: eng ? 'nameEn' : 'name',
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.goat.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.goat.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'offspringQuantity',
    label: () => t('pages.goat.offspringQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'offspringValue',
    label: () => t('pages.goat.offspringValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseQuantity',
    label: () => t('pages.goat.purchaseQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseValue',
    label: () => t('pages.goat.purchaseValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForSlaughterQuantity',
    label: () => t('pages.goat.soldForSlaughterQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForSlaughterValue',
    label: () => t('pages.goat.soldForSlaughterValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForBreedingQuantity',
    label: () => t('pages.goat.soldForBreedingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForBreedingValue',
    label: () => t('pages.goat.soldForBreedingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForUnknownReasonQuantity',
    label: () => t('pages.goat.soldForUnknownReasonQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForUnknownReasonValue',
    label: () => t('pages.goat.soldForUnknownReasonValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'saleQuantity',
    label: () => t('pages.goat.saleQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'saleValue',
    label: () => t('pages.goat.saleValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionQuantity',
    label: () => t('pages.goat.ownConsumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionValue',
    label: () => t('pages.goat.ownConsumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingQuantity',
    label: () => t('pages.goat.spentOnProcessingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingValue',
    label: () => t('pages.goat.spentOnProcessingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsQuantity',
    label: () => t('pages.goat.paymentInProductsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsValue',
    label: () => t('pages.goat.paymentInProductsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathQuantity',
    label: () => t('pages.goat.deathQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathValue',
    label: () => t('pages.goat.deathValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'inputQuantity',
    label: () => t('pages.goat.inputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'outputQuantity',
    label: () => t('pages.goat.outputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.goat.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.goat.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.goat.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default goatsBasicFields;