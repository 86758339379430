import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RouteLeavingGuard from '../components/routeLeavingGuard/RouteLeavingGuard';

// use setIsDirty(true) in input components' onChange prop

const RedirectPrompt = createContext({});

const RedirectPromptProvider = ({ children }) => {
  const { pathname } = useLocation();

  const [isDirty, setIsDirty] = useState(false);
  const [reset, setReset] = useState(false);

  const data = {
    setIsDirty: setIsDirty,
  };

  // cleans states when redirect happens
  useEffect(() => {
    setIsDirty(false);
    setReset((prevState) => !prevState);
  }, [pathname]);

  return (
    <RedirectPrompt.Provider value={data}>
      <RouteLeavingGuard when={isDirty} reset={reset} />
      {children}
    </RedirectPrompt.Provider>
  );
};

export const useRedirectPrompt = () => {
  return useContext(RedirectPrompt);
};

export default RedirectPromptProvider;
