import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const moneyOverheadFields = [
  {
    value: 'overheadExpenseType',
    label: () => t('pages.overheadExpenses.overheadExpenseType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'overhead-expense-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.overheadExpenses.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingAmount',
    label: () => t('pages.overheadExpenses.openingAmount'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseQuantity',
    label: () => t('pages.overheadExpenses.purchaseQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseValue',
    label: () => t('pages.overheadExpenses.purchaseValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumptionQuantity',
    label: () => t('pages.overheadExpenses.consumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumptionValue',
    label: () => t('pages.overheadExpenses.consumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'forAH',
    label: () => t('pages.overheadExpenses.forAH'),
    type: 'integer',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.overheadExpenses.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.overheadExpenses.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.overheadExpenses.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default moneyOverheadFields;