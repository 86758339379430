import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const plantProductsFields = [
  {
    value: 'productionPlantProduct',
    label: () => t('pages.plantProducts.productionPlantProduct'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'production-plant-products',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'productionCode',
    label: () => t('pages.plantProducts.productionCode'),
    type: 'select',
    options: {
      list: [],
      api: 'production-codes',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'productionType',
    label: () => t('pages.plantProducts.productionType'),
    type: 'select',
    options: {
      list: [],
      api: 'plant-production-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'firstTurn',
    label: () => t('pages.plantProducts.firstTurn'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'area',
    label: () => t('pages.plantProducts.area'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'irrigated',
    label: () => t('pages.plantProducts.irrigated'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'irrigationType',
    label: () => t('pages.plantProducts.irrigationType'),
    type: 'select',
    options: {
      list: [],
      api: 'irrigation-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.plantProducts.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.plantProducts.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'fruitType',
    label: () => t('pages.plantProducts.fruitType'),
    type: 'select',
    options: {
      list: [],
      api: 'production-plant-products',
      optionValue: 'id',
      optionLabel: 'name',
      pagination: true
    },
    displayInLimits: true
  },
  {
    value: 'otherCerealType',
    label: () => t('pages.plantProducts.otherCerealType'),
    type: 'text',
    options: {},
    displayInLimits: false
  },
  {
    value: 'productionQuantity',
    label: () => t('pages.plantProducts.productionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldQuantity',
    label: () => t('pages.plantProducts.soldQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldValue',
    label: () => t('pages.plantProducts.soldValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionQuantity',
    label: () => t('pages.plantProducts.ownConsumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionValue',
    label: () => t('pages.plantProducts.ownConsumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsQuantity',
    label: () => t('pages.plantProducts.paymentInProductsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsValue',
    label: () => t('pages.plantProducts.paymentInProductsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingQuantity',
    label: () => t('pages.plantProducts.spentOnProcessingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingValue',
    label: () => t('pages.plantProducts.spentOnProcessingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForCropsQuantity',
    label: () => t('pages.plantProducts.consumedForCropsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForCropsValue',
    label: () => t('pages.plantProducts.consumedForCropsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForCattleQuantity',
    label: () => t('pages.plantProducts.consumedForCattleQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForCattleValue',
    label: () => t('pages.plantProducts.consumedForCattleValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForPigsQuantity',
    label: () => t('pages.plantProducts.consumedForPigsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForPigsValue',
    label: () => t('pages.plantProducts.consumedForPigsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForPoultryQuantity',
    label: () => t('pages.plantProducts.consumedForPoultryQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForPoultryValue',
    label: () => t('pages.plantProducts.consumedForPoultryValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.plantProducts.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.plantProducts.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.plantProducts.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default plantProductsFields;