import React, { useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import { t } from 'react-switch-lang';

export const LongTermAssetsExtra = ({ closeSidebar }) => {
  let { ahid } = useParams();

  const items = [
    {
      name: 'agriculturalLand',
      link: `/agricultural-holding/${ahid}/long-term-assets/agricultural-lands`,
      text: t('sidebarExtra.longTermAssets.agriculturalLand'),
      tooltip: t('sidebarExtra.longTermAssets.agriculturalLand'),
    },
    {
      name: 'permanentCrops',
      link: `/agricultural-holding/${ahid}/long-term-assets/permanent-crops`,
      text: t('sidebarExtra.longTermAssets.permanentCrops'),
      tooltip: t('sidebarExtra.longTermAssets.permanentCrops'),
    },
    {
      name: 'farmBuildings',
      link: `/agricultural-holding/${ahid}/long-term-assets/farm-buildings`,
      text: t('sidebarExtra.longTermAssets.farmBuildings'),
      tooltip: t('sidebarExtra.longTermAssets.farmBuildings'),
    },
    {
      name: 'machineryAndEquipment',
      link: `/agricultural-holding/${ahid}/long-term-assets/machinery-and-equipment`,
      text: t('sidebarExtra.longTermAssets.machineryAndEquipment'),
      tooltip: t('sidebarExtra.longTermAssets.machineryAndEquipment'),
    },
  ];

  const query = window.location.pathname;

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div
            className={`system-link links ${query === item.link ? 'active' : ''}`}
            key={index}
            onClick={() => closeSidebar()}
          >
            <Link to={item.link}>{item.text}</Link>
          </div>
        );
      })}
    </div>
  );
};
