import React, { useContext } from 'react';
import { t } from 'react-switch-lang';
import SaveBtn from '../../../../components/buttons/SaveBtn';
import { useHistory, useParams } from 'react-router-dom';
import AdministrationTable from '../../../../components/table/AdministrationTable';
import { columns } from './utils/tableColumns';
import { onDelete } from './utils/deleteFromTable';
import UserData from '../../../../contexts/UserData';
import { useShowDeleted } from '../../../../contexts/ShowDeletedEntries';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import AgriculturalHoldingData from '../../../../contexts/AgriculturalHoldingData';
import { hasValue, readOnly } from '../../../../utils/const';

const MachineryEquipment = () => {
  const history = useHistory();
  let { ahid } = useParams();

  const user = useContext(UserData);
  const { showDeleted, toggleShowDeleted } = useShowDeleted();

  const agriculturalData = useContext(AgriculturalHoldingData);
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const onUpdate = (record) => {
    history.push({
      pathname: `/agricultural-holding/${ahid}/long-term-assets/machinery-and-equipment/${record.id}`,
      state: {
        add: false,
        edit: true,
      },
    });
  };

  const onRowClick = (record) => {
    history.push({
      pathname: `/agricultural-holding/${ahid}/long-term-assets/machinery-and-equipment/${record.id}`,
      state: {
        add: false,
        edit: false,
        isDeleted: record.isDeleted,
      },
    });
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-end mb-5">
        {user.isAdmin && (
          <SaveBtn
            label={showDeleted ? t('common.hideDeleted') : t('common.showDeleted')}
            className="mt-2 mr-3 grayText"
            onClick={() => toggleShowDeleted()}
            thin={true}
            icon={true}
            iconAntd={showDeleted ? EyeInvisibleOutlined : EyeOutlined}
          />
        )}
        {(user.isAdmin || user.isCollector) && !isLocked && !readOnly && (
          <SaveBtn
            label={t('common.add')}
            onClick={() =>
              history.push({
                pathname: `/agricultural-holding/${ahid}/long-term-assets/machinery-and-equipment/add`,
                state: {
                  edit: true,
                  add: true,
                },
              })
            }
          />
        )}
      </div>
      <AdministrationTable
        api="machinery-equipments"
        query={`${!showDeleted ? 'isDeleted.equals=false&' : ''}agroHoldingId.equals=${ahid}`}
        user={user}
        columns={columns}
        methods={{ onDelete, onUpdate }}
        className={'table-hover'}
        onClickRow={onRowClick}
        isLocked={isLocked || readOnly}
        group={'long-term-assets'}
        historyEntity={'machineryEquipment'}
      />
    </div>
  );
};

export default MachineryEquipment;
