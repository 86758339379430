import { t } from 'react-switch-lang';
import { eng } from '../../../../../../../utils/const';

const sheepBasicFields = [
  {
    value: 'sheepCategory',
    label: () => t('pages.sheep.categoryNameSheep'),
    type: 'select', // integer, double, select, textarea, string
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api:'sheep-categories',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.sheep.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.sheep.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'offspringQuantity',
    label: () => t('pages.sheep.offspringQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'offspringValue',
    label: () => t('pages.sheep.offspringValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseQuantity',
    label: () => t('pages.sheep.purchaseQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseValue',
    label: () => t('pages.sheep.purchaseValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForSlaughterQuantity',
    label: () => t('pages.sheep.soldForSlaughterQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForSlaughterValue',
    label: () => t('pages.sheep.soldForSlaughterValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForBreedingQuantity',
    label: () => t('pages.sheep.soldForBreedingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForBreedingValue',
    label: () => t('pages.sheep.soldForBreedingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForUnknownReasonQuantity',
    label: () => t('pages.sheep.soldForUnknownReasonQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForUnknownReasonValue',
    label: () => t('pages.sheep.soldForUnknownReasonValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'saleQuantity',
    label: () => t('pages.sheep.saleQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'saleValue',
    label: () => t('pages.sheep.saleValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionQuantity',
    label: () => t('pages.sheep.ownConsumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionValue',
    label: () => t('pages.sheep.ownConsumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingQuantity',
    label: () => t('pages.sheep.spentOnProcessingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingValue',
    label: () => t('pages.sheep.spentOnProcessingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsQuantity',
    label: () => t('pages.sheep.paymentInProductsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsValue',
    label: () => t('pages.sheep.paymentInProductsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathQuantity',
    label: () => t('pages.sheep.deathQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathValue',
    label: () => t('pages.sheep.deathValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'inputQuantity',
    label: () => t('pages.sheep.inputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'outputQuantity',
    label: () => t('pages.sheep.outputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.sheep.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.sheep.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.sheep.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default sheepBasicFields;