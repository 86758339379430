import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { t } from 'react-switch-lang';
import '../../../components/formDynamic/fieldContainer/components/explainErrorModal/ExplainErrorModal.scss';
import { fetchMessages, sendSingleMessage } from '../../../services/errorExplanationsChat/errorExplanationsChatApi';
import UserData from '../../../contexts/UserData';
import { useLocation } from 'react-router-dom';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import InputField from '../../../components/formDynamic/inputField/InputField';
import ChatArrow from '../../../assets/images/icons/chat-arrow.svg';
import { formatDate } from '../../../utils/formatDate';
import { hasValue, notOnlySpacesAndLineBreaks, readOnly, scrollToBottom } from '../../../utils/const';
import Exclusion from '../../../assets/images/icons/cancel-small-grey.svg';

const ChatModal = ({ isOpen, toggle, className, explainError }) => {

  const [messagesWithDate, setMessagesWithDate] = useState([]);
  const [message, setMessage] = useState({ value: undefined });
  const [payload, setPayload] = useState({});

  const chatDivRef = useRef();

  const location = useLocation();

  const lang = localStorage.getItem('language');

  const user = useContext(UserData);

  const validation = {
    message: { type: 'string', required: { value: false }, min: { value: 2 } },
  };

  const [submitDisabled, setSubmitDisabled] = useState({ errorComment: true });

  const getErrorExplanationMessages = async (id) => {
    const { data } = await fetchMessages(id);
    const messagesTemp = data.map(message => {
      return {
        ...message,
        date: formatDate(new Date(message.createdDate)),
      };
    });
    const messagesByDay = _.groupBy(messagesTemp, 'date');
    setMessagesWithDate([...Object.entries({ ...messagesByDay })]);
    scrollToBottom(chatDivRef);
  };

  const sendMessage = async (payload) => {
    if (hasValue(message?.value) && notOnlySpacesAndLineBreaks(message?.value) && message?.value?.length >= 3 && message?.value?.length <= 255) {
      await sendSingleMessage(payload);
      setMessage(prevValue => ({ ...prevValue, value: undefined }));
      getErrorExplanationMessages(explainError?.id);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getErrorExplanationMessages(explainError?.id);
    }
  }, [isOpen]);

  useDidMountEffect(() => {
    const payload = {
      message: message?.value,
      wasRead: false,
      errorExplanation: {
        ...explainError,
      },
    };

    setPayload(payload);
  }, [message]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={`${className} explain-modal`} backdrop="static">
      <ModalHeader toggle={toggle}>
        <div>{t('common.chat')}</div>
        <img src={Exclusion} alt="Exclusion" onClick={toggle}
             style={{ cursor: 'pointer' }}/>
      </ModalHeader>
      <ModalBody>
        <div className='d-flex flex-column pt-4 pb-4'>
          <div className='d-flex pb-2 modalTitle'>
            {(() => {
              if (hasValue(explainError) && explainError?.message)
                return explainError.message;

              if (hasValue(explainError) && explainError?.dvcDescription)
                return lang === 'eng' ? explainError.dvcDescriptionEn : explainError.dvcDescription;
            })()}
          </div>
        </div>
        {!location.pathname.includes('add') &&
        <div>
          <div className='d-flex pb-3 pt-3 border-top border-bottom'>
            {t('common.chatTitle')}
          </div>
          <div ref={chatDivRef} className='d-flex flex-column w-100 chatContainer pt-3'>
            {
              !messagesWithDate.length > 0 ?
                <div className='d-flex justify-content-center pt-4 pb-5'>
                  {t('common.noMessages')}
                </div>
                :
                <>
                  {
                    React.Children.toArray(
                      messagesWithDate?.map((messageObject) => {
                        return (
                          <>
                            {/*Date by day*/}
                            <div className='d-flex justify-content-center dateContainer'>
                              {messageObject[0]}
                            </div>
                            {
                              React.Children.toArray(
                                messageObject[1]?.map((messages) => {
                                  return (
                                    <div>
                                      <div className='d-flex flex-column'>
                                          <span
                                            className={`messageTime ${user.userData.login === messages.createdBy ? 'myMessageTime' : 'receivedMessageTime'}`}>
                                            {new Date(messages.createdDate).toTimeString().substr(0, 5)}
                                          </span>
                                        {/*Single message*/}
                                        <div
                                          className={`message ${user.userData.login === messages.createdBy ? 'myMessage' : 'receivedMessage'}`}>
                                          {user.userData.login !== messages.createdBy &&
                                          <span className='user'>{messages.createdBy}</span>
                                          }
                                          <div>{messages.message}</div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }))
                            }
                          </>
                        );
                      }))
                  }
                </>
            }
          </div>
          <div>
            <InputField
              value={message.value}
              setErrorExplanations={() => {
              }}
              placeholder={t('common.insertMessage')}
              showErrors
              validation={validation['message']}
              name='message'
              chat={true}
              suffixImage={ChatArrow}
              onClickSuffixImage={() => sendMessage(payload)}
              onEnter={() => sendMessage(payload)}
              onChange={(e) => {
                setMessage({
                  ...message,
                  value: e,
                });
              }}
              setSubmitDisabled={setSubmitDisabled}
              disabled={readOnly}
            />
          </div>
        </div>
        }
      </ModalBody>
    </Modal>
  );
};

export default ChatModal;