import React, { Suspense } from 'react';
import classes from './Buttons.module.scss';
import PropTypes from 'prop-types';
import { t } from 'react-switch-lang';

const MyIcon = React.lazy(() => import('../../components/icons/MyIcon'));
const Loader = React.lazy(() => import('../../components/loader/Loader'));

// if loaderOnDisabled true and loader true - loaderIcon is shown when btn is disabled
// if loaderOnDisabled false - loaderIcon is shown when loader is true

// only icon - set label '' icon true

const DeleteBtn = ({
  onClick,
  label = t('common.delete'),
  className = '',
  disabled = false,
  loader = true,
  icon = true,
  loaderOnDisabled = true,
}) => {
  return (
    <button
      className={`${className} ${classes['delete']} ${classes['button']} ${
        label.length > 0 ? '' : 'px-0 py-1'
      }`}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
    >
      {icon && (
        <Suspense fallback={<></>}>
          <MyIcon
            className={`${label.length > 0 ? 'mr-1' : 'mx-1'}`}
            imgName="trash-white.svg"
          />
        </Suspense>
      )}
      <span className={`${label.length > 0 ? classes['text'] : ''}`}>
        {label}
      </span>
      {(loaderOnDisabled && loader && disabled) ||
      (!loaderOnDisabled && loader) ? (
        <Suspense fallback={<></>}>
          <Loader color="light" />
        </Suspense>
      ) : null}
    </button>
  );
};

DeleteBtn.propTypes = {
  label: PropTypes.string,
};

export default DeleteBtn;