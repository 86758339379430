import { Tooltip } from 'antd';
import { t } from 'react-switch-lang';
import { eng, formatNumber, hasValue } from '../../../../utils/const';
import React from 'react';

const renderColWithTooltip = (col) => {
  return (
    <Tooltip
      title={col}
      placement="top"
      color={'#67B278'}
      overlayInnerStyle={{ borderRadius: '5px' }}
    >
      {hasValue(col) ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.labour.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: (id) => renderColWithTooltip(id),
  },
  {
    title: t('pages.labour.workforceType'),
    dataIndex: ['workforceType', eng ? 'nameEn' : 'name'],
    key: 'workforceType',
    ellipsis: true,
    render: (id) => renderColWithTooltip(id),
  },
  {
    title: t('pages.labour.workforceRole'),
    dataIndex: ['workforceRole', eng ? 'nameEn' : 'name'],
    key: 'workforceRole',
    ellipsis: true,
    render: (id) => renderColWithTooltip(id),
  },
  // {
  //   title: t('pages.labour.yearOfBirth'),
  //   dataIndex: 'yearOfBirth',
  //   key: 'yearOfBirth',
  //   ellipsis: true,
  //   render: yearOfBirth => renderColWithTooltip(yearOfBirth),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.labour.comment'),
  //   dataIndex: 'comment',
  //   key: 'comment',
  //   ellipsis: true,
  //   render: comment => renderColWithTooltip(comment),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.labour.gender'),
  //   dataIndex: ['gender', 'id'],
  //   key: 'gender',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.labour.agriculturalEducation'),
  //   dataIndex: ['agriculturalEducation', 'id'],
  //   key: 'agriculturalEducation',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  {
    title: t('pages.labour.numberOfPersons'),
    dataIndex: 'numberOfPersons',
    key: 'numberOfPersons',
    ellipsis: true,
    render: (numberOfPersons) => renderColWithTooltip(formatNumber(numberOfPersons)),
  },
  {
    title: t('pages.labour.numberOfWorkingDays'),
    dataIndex: 'numberOfWorkingDays',
    key: 'numberOfWorkingDays',
    ellipsis: true,
    render: (numberOfWorkingDays) => renderColWithTooltip(formatNumber(numberOfWorkingDays)),
  },
  {
    title: t('pages.labour.annualWorkUnits'),
    dataIndex: 'annualWorkUnits',
    key: 'annualWorkUnits',
    ellipsis: true,
    render: (annualWorkUnits) =>
      renderColWithTooltip(
        annualWorkUnits !== null ? formatNumber(annualWorkUnits) : annualWorkUnits,
      ),
  },
  {
    title: t('pages.labour.salary'),
    dataIndex: 'salary',
    key: 'salary',
    ellipsis: true,
    render: (salary) => renderColWithTooltip(formatNumber(salary)),
  },
  // {
  //   title: t('pages.labour.additionalActivitiesPercent'),
  //   dataIndex: 'additionalActivitiesPercent',
  //   key: 'additionalActivitiesPercent',
  //   ellipsis: true,
  //   render: additionalActivitiesPercent => renderColWithTooltip(additionalActivitiesPercent),
  //   responsive: ['md', 'lg']
  // },
];
