import React, { useEffect, useState } from 'react';
import { axiosGet } from '../../../services/axios';
import classes from '../Charts.module.scss';
import Column from '@ant-design/charts/lib/plots/column';
import { formatNumber } from '../../../utils/const';

// model function should be a functions that takes api data and transforms into chart-readable data
//
// defaultClick determines if a chart should have a default onClick function or not (pie slice moving, etc.)

const StackedColumnChart = React.memo(({
                                         label,
                                         api,
                                         modelFunction,
                                         defaultClick = true,
                                         ...rest
                                       }) => {
  const [data, setData] = useState([]);

  // Configuration for pie charts
  const config = {
    data: data,
    isStack: true,
    xField: 'xAxis',
    yField: 'value',
    seriesField: 'type',
    width: 280,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
    },
    tooltip: {
      showTitle: false,
      formatter: (element) => {
        return { name: element.type, value: formatNumber(element.value) };
      },
    }
  };

  // useEffect that takes api and custom function for modeling data and feeds the data to chart
  useEffect(() => {
    axiosGet(api)
      .then((response) => setData(modelFunction(response?.data)))
      .catch((err) => {
      });
  }, [api]);

  return (
    <>
    {
      data?.length > 0 ?
        <div className={`d-flex flex-column align-items-center ${classes['chartContainer']}`}>
          <span className={classes['title']}>{label}</span>
          <Column {...rest} {...config} />
        </div>
        :
        <></>
    }
    </>
  );
});

export default StackedColumnChart;
