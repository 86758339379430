import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import SelectConstField from '../../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import classes from './LandAndBuildingForm.module.scss';
import { Toast } from '../../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../../utils/const';
import UserData from '../../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../../components/formDynamic/helpers';
import {
  fetchSingleLandAndBuilding,
  getLandBuildingExpenseType,
} from '../../../../../../services/money/expenses/landAndBuilding';
import Loader from '../../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';

const LandAndBuildingForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    landBuildingExpenseType: { type: 'selectSingle', required: { value: true } },
    area: { type: 'number', required: { value: false } },
    consumptionValue: { type: 'number', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [landAndBuilding, setLandAndBuilding] = useState();

  const [landBuildingExpenseType, setLandBuildingExpenseType] = useState({ value: undefined, disabled: !edit });
  const [area, setArea] = useState({ value: 0, disabled: !edit });
  const [consumptionValue, setConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });

  // Enums
  const [landBuildingExpenseTypeList, setLandBuildingExpenseTypeList] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/
  /***** END DEPENDANT VALIDATION *****/


  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'landBuildingExpense', landAndBuilding, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(landBuildingExpenseType.value?.value && { landBuildingExpenseType: { id: parseInt(landBuildingExpenseType.value?.value) } }),
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(area?.value) && { area: parseFloatCustom(area.value) }),
        ...(hasValue(consumptionValue?.value) && { consumptionValue: parseFloatCustom(consumptionValue.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`land-building-expenses?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.landBuilding') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`land-building-expenses/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.landBuilding') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchLandAndBuilding = async () => {
    try {
      const { data } = await fetchSingleLandAndBuilding(props.match.params.id);
      setLandAndBuilding(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchLandBuildingExpenseType = async () => {
    const data = await getLandBuildingExpenseType();
    setLandBuildingExpenseTypeList(data);
  };

  useEffect(() => {
    fetchLandBuildingExpenseType();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchLandAndBuilding().then(landAndBuilding => {
          landAndBuilding?.landBuildingExpenseType && setLandBuildingExpenseType(prevState => ({
            ...prevState,
            value: defaultOption(landAndBuilding?.landBuildingExpenseType),
          }));
          setComment(prevState => ({ ...prevState, value: landAndBuilding?.comment }));
          setArea(prevState => ({ ...prevState, value: parseDouble(landAndBuilding?.area) }));
          setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(landAndBuilding?.consumptionValue) }));
          setErrorExplanations(landAndBuilding?.errorExplanations ? landAndBuilding?.errorExplanations : []);
          let explanations = landAndBuilding?.errorExplanations ? landAndBuilding?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'landBuildingExpense', landAndBuilding, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        data?.landBuildingExpenseType && setLandBuildingExpenseType(prevState => ({
          ...prevState,
          value: defaultOption(data?.landBuildingExpenseType),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setArea(prevState => ({ ...prevState, value: parseDouble(data?.area) }));
        setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(data?.consumptionValue) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);
        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'landBuildingExpense', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setLandBuildingExpenseType(prevState => ({ ...prevState, disabled: false }));
        setArea(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setConsumptionValue(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  const oneEdit = () => {
    setEdit(true);

    // const payload = {
    //   id: props.match.params.id,
    //   agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
    //   agroHoldingId: ahid,
    //   landBuildingExpenseType: { id: parseInt(landBuildingExpenseType.value?.value) },
    //   ...(comment?.value && { comment: comment.value }),
    //   ...(hasValue(area?.value) && { area: parseFloatCustom(area.value) }),
    //   ...(hasValue(consumptionValue?.value) && { consumptionValue: parseFloatCustom(consumptionValue.value) }),
    //   errorExplanations: clearEmptyExplanations(errorExplanations),
    // }
    //
    // handleSubmit(false, true, payload);
  };

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.landAndBuilding.landBuildingExpenseType')}
                    placeholder={t('pages.landAndBuilding.placeholder.landBuildingExpenseType')}
                    value={landBuildingExpenseType.value}
                    name='landBuildingExpenseType'
                    onChange={(e) => {
                      setLandBuildingExpenseType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={landBuildingExpenseTypeList}
                    disabled={landBuildingExpenseType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['landBuildingExpenseType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.landAndBuilding.area')}
                    placeholder={t('pages.landAndBuilding.placeholder.area')}
                    value={area.value}
                    name='area'
                    suffix='ha'
                    type={'double'}
                    onChange={(e) => {
                      setArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={area.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['area']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.landAndBuilding.consumptionValue')}
                    placeholder={t('pages.landAndBuilding.placeholder.consumptionValue')}
                    value={consumptionValue.value}
                    name='consumptionValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setConsumptionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.landAndBuilding.comment')}
                    placeholder={t('pages.landAndBuilding.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      oneEdit();
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className='button cancel'
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/expenses/land-and-building`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className='button save'
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                oneEdit();
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default LandAndBuildingForm;
