import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { t } from 'react-switch-lang';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import SelectConstField from '../../../../../../components/formDynamic/selectField/SelectConstField';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import classes from './LeasingForm.module.scss';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Toast } from '../../../../../../utils/sweetAlert';
import UserData from '../../../../../../contexts/UserData';
import { fetchSingleLeasing, getLeasingTypes } from '../../../../../../services/money/loans/leasing';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../../utils/const';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../../components/formDynamic/helpers';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import Loader from '../../../../../../components/loader/Loader';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';

const LeasingForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserData);
  const leasingId = props.match.params.id;
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;

  const [leasing, setLeasing] = useState();
  const [edit, setEdit] = useState(location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  // states for form data
  const [leasingType, setLeasingType] = useState({ value: undefined, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });
  const [interestValue, setInterestValue] = useState({ value: 0, disabled: !edit });

  const [leasingTypesOptions, setLeasingTypesOptions] = useState([]);

  const validationInitial = {
    leasingType: { type: 'selectSingle', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    closingValue: { type: 'number', required: { value: false } },
    interestValue: { type: 'number', required: { value: false } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);


  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'leasing', leasing, ahid, agriculturalData, setErrorExplanations, setValidation);
  };

  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(leasingType?.value?.value && { leasingType: { id: parseInt(leasingType?.value?.value) } }),
        ...(comment?.value && { comment: comment?.value }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue?.value) }),
        ...(hasValue(interestValue?.value) && { interestValue: parseFloatCustom(interestValue?.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue?.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };

      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`leasings?submitData=${isSubmit}`, payload);

          if (response.status === 201 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.leasing') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload :
            {
              ...payload,
              id: leasingId,
            };
          const response = await axiosPut(`leasings/${leasingId}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.leasing') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };

  const fetchLeasingType = async () => {
    const arr = await getLeasingTypes();
    setLeasingTypesOptions(arr);
  };

  // fn for fetching single agr land data
  const getLeasing = async (id) => {
    try {
      // todo update with cancel token
      const { data } = await fetchSingleLeasing(id);
      setLeasing(data);
      return data;
    } catch (e) {

    }
  };

  // handle switching from preview to edit
  useEffect(() => {
    if (!isAudit) {
      if (!location.pathname.includes('add')) {
        if (edit) {
          setLeasingType(prevState => ({ ...prevState, disabled: !edit }));
          setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
          setComment(prevState => ({ ...prevState, disabled: !edit }));
          setClosingValue(prevState => ({ ...prevState, disabled: !edit }));
          setInterestValue(prevState => ({ ...prevState, disabled: !edit }));
        }
      }
    }
  }, [edit]);

  // handles redirecting and loading API data into form
  useEffect(() => {

    fetchLeasingType();


    const defaultOption = (option) => {
      return {
        label: eng ? option.nameEn : option.name,
        value: option.id,
        disabled: false,
      };
    };

    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        getLeasing(leasingId)
          .then(leasing => {
            leasing?.leasingType && setLeasingType(prevState => ({
              ...prevState,
              value: defaultOption(leasing?.leasingType) || undefined,
            }));
            setOpeningValue(prevState => ({ ...prevState, value: parseDouble(leasing?.openingValue) || undefined }));
            setComment(prevState => ({ ...prevState, value: leasing?.comment || undefined }));
            setClosingValue(prevState => ({ ...prevState, value: parseDouble(leasing?.closingValue) || undefined }));
            setInterestValue(prevState => ({ ...prevState, value: parseDouble(leasing?.interestValue) || undefined }));

            let explanations = leasing?.errorExplanations ? leasing?.errorExplanations : [];

            handleApiErrorsFn({}, 'edit', validation, 'leasing', leasing, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
            setLoading(false);
          });
      } else {
        data?.leasingType && setLeasingType(prevState => ({
          ...prevState,
          value: defaultOption(data?.leasingType) || undefined,
        }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) || undefined }));
        setComment(prevState => ({ ...prevState, value: data?.comment || undefined }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) || undefined }));
        setInterestValue(prevState => ({ ...prevState, value: parseDouble(data?.interestValue) || undefined }));

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'leasing', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);
  //
  // const prevVal = usePrevious(submitDisabled);
  // useDidMountEffect(() => {
  //   (!compareObjects(submitDisabled, prevVal) && showErrors) && handleSubmit(false);
  // }, [submitDisabled]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return <>
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.leasing.leasingType')}
                    placeholder={t('pages.leasing.placeholder.leasingType')}
                    value={leasingType?.value}
                    name='leasingType'
                    onChange={(e) => {
                      setLeasingType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={leasingTypesOptions}
                    disabled={leasingType?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['leasingType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showRequiredError={showRequiredError}
                    showErrors={showErrors}
                    label={t('pages.leasing.openingValue')}
                    placeholder={t('pages.leasing.placeholder.openingValue')}
                    value={openingValue?.value}
                    name='openingValue'
                    suffix='€'
                    type='double'
                    onChange={(e) => {
                      setOpeningValue(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={openingValue?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.leasing.interestValue')}
                    placeholder={t('pages.leasing.placeholder.interestValue')}
                    value={interestValue?.value}
                    name='interestValue'
                    suffix='€'
                    type='double'
                    onChange={(e) => {
                      setInterestValue(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={interestValue?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['interestValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.leasing.closingValue')}
                    placeholder={t('pages.leasing.placeholder.closingValue')}
                    value={closingValue?.value}
                    name='closingValue'
                    suffix='€'
                    type='double'
                    onChange={(e) => {
                      setClosingValue(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={closingValue?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.leasing.comment')}
                    placeholder={t('pages.leasing.placeholder.comment')}
                    value={comment?.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={comment?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className='d-flex justify-content-center pt-3'>
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={() => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/loans/leasing`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  loaderOnDisabled={false}
                  loader={false}
                  disabled={!edit}
                  form={true}
                  className='pl-5 pr-5'
                  onClick={() => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  </>;
};

export default LeasingForm;
