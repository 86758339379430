import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Sidebar.scss';

import Home from '../../../../assets/images/sidebarIcons/home.svg';
import Labour from '../../../../assets/images/sidebarIcons/workforce.svg';
import LongTermAssets from '../../../../assets/images/sidebarIcons/longTermAssets.svg';
import Cattle from '../../../../assets/images/sidebarIcons/cattle.svg';
import Money from '../../../../assets/images/sidebarIcons/money.svg';
import Manufacturing from '../../../../assets/images/sidebarIcons/manufacturing.svg';

import { LongTermAssetsExtra } from './components/LongTermAssetsExtra';
import { LivestockExtra } from './components/LivestockExtra';

import { t } from 'react-switch-lang';
import { ProductionExtra } from './components/ProductionExtra';
import { MoneyExtra } from './components/MoneyExtra';
import SidebarDrawer from './sidebarDrawer/SidebarDrawer';
import { useWindowWidth } from '../../../../contexts/WindowWidth';

const Sidebar = () => {
  let { ahid } = useParams();

  const windowWidth = useWindowWidth();

  const [mainSidebarOpen, setMainSidebarOpen] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [longTermAssetsExtra, setLongTermAssetsExtra] = useState(false);
  const [livestockExtra, setLivestockExtra] = useState(false);
  const [manufacturingExtra, setManufacturingExtra] = useState(false);
  const [moneyExtra, setMoneyExtra] = useState(false);
  const [sidebarItems, setSidebarItems] = useState([]);

  const queryFolder = `${window.location.pathname.split('/').slice(0, 4).join('/')}`;

  const toggleSidebarExtra =
    longTermAssetsExtra === true ||
    livestockExtra === true ||
    manufacturingExtra === true ||
    moneyExtra ||
    true;

  const openLongTermAssetsExtra = () => {
    if (toggleSidebarExtra) {
      setSidebarOpen(true);
      setLongTermAssetsExtra(true);
      setLivestockExtra(false);
      setManufacturingExtra(false);
      setMoneyExtra(false);
    } else {
      setSidebarOpen(!sidebarOpen);
      setLongTermAssetsExtra(!longTermAssetsExtra);
      setLivestockExtra(false);
      setManufacturingExtra(false);
      setMoneyExtra(false);
    }
  };

  const openLivestockExtra = () => {
    if (toggleSidebarExtra) {
      setSidebarOpen(true);
      setLongTermAssetsExtra(false);
      setLivestockExtra(true);
      setManufacturingExtra(false);
      setMoneyExtra(false);
    } else {
      setSidebarOpen(!sidebarOpen);
      setLongTermAssetsExtra(false);
      setLivestockExtra(!livestockExtra);
      setManufacturingExtra(false);
      setMoneyExtra(false);
    }
  };

  const openManufacturingExtra = () => {
    if (toggleSidebarExtra) {
      setSidebarOpen(true);
      setLongTermAssetsExtra(false);
      setLivestockExtra(false);
      setManufacturingExtra(true);
      setMoneyExtra(false);
    } else {
      setSidebarOpen(!sidebarOpen);
      setLongTermAssetsExtra(false);
      setLivestockExtra(false);
      setManufacturingExtra(!manufacturingExtra);
      setMoneyExtra(false);
    }
  };

  const openMoneyExtra = () => {
    if (toggleSidebarExtra) {
      setSidebarOpen(true);
      setLongTermAssetsExtra(false);
      setLivestockExtra(false);
      setManufacturingExtra(false);
      setMoneyExtra(true);
    } else {
      setSidebarOpen(!sidebarOpen);
      setLongTermAssetsExtra(false);
      setLivestockExtra(false);
      setManufacturingExtra(!moneyExtra);
      setMoneyExtra(false);
    }
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const navigation = [
    {
      name: 'dashboard',
      className: 'links',
      link: `/agricultural-holding/${ahid}/dashboard`,
      img: Home,
      tooltip: t('sidebar.dashboard'),
      alt: t('sidebar.dashboard'),
    },
    {
      name: 'labour',
      className: 'links',
      link: `/agricultural-holding/${ahid}/labour`,
      linkAudit: `/${ahid}/revisions/labour`,
      img: Labour,
      tooltip: t('sidebar.labour'),
      alt: t('sidebar.labour'),
    },
    {
      name: 'longTermAssets',
      className: `links ${sidebarOpen && longTermAssetsExtra ? 'linksExtra' : ''}`,
      link: `/agricultural-holding/${ahid}/long-term-assets`,
      linkAudit: `/${ahid}/revisions/long-term-assets`,
      onClick: () => openLongTermAssetsExtra(),
      img: LongTermAssets,
      tooltip: t('sidebar.longTermAssets'),
      alt: t('sidebar.longTermAssets'),
    },
    {
      name: 'livestock',
      className: `links ${sidebarOpen && livestockExtra ? 'linksExtra' : ''}`,
      link: `/agricultural-holding/${ahid}/livestock`,
      linkAudit: `/${ahid}/revisions/livestock`,
      onClick: () => openLivestockExtra(),
      img: Cattle,
      tooltip: t('sidebar.livestock'),
      alt: t('sidebar.livestock'),
    },
    {
      name: 'production',
      className: `links ${sidebarOpen && manufacturingExtra ? 'linksExtra' : ''}`,
      link: `/agricultural-holding/${ahid}/production`,
      linkAudit: `/${ahid}/revisions/production`,
      onClick: () => openManufacturingExtra(),
      img: Manufacturing,
      tooltip: t('sidebar.production'),
      alt: t('sidebar.production'),
    },
    {
      name: 'money',
      className: `links ${sidebarOpen && moneyExtra ? 'linksExtra' : ''}`,
      link: `/agricultural-holding/${ahid}/money`,
      linkAudit: `/${ahid}/revisions/money`,
      onClick: () => openMoneyExtra(),
      img: Money,
      tooltip: t('sidebar.money'),
      alt: t('sidebar.money'),
    },
  ];

  useEffect(() => {
    setSidebarItems(navigation);
  }, [longTermAssetsExtra, livestockExtra, manufacturingExtra, moneyExtra, sidebarOpen]);

  /*Hide sidebar on outside click*/
  const sideMenu = useRef(null);
  const sideMenuExtra = useRef(null);
  const [isOpen, setIsOpen] = useState(true);

  const toggle = (e) => {
    // e.preventDefault(); Select doesn't close on click outside if this is applied
    setIsOpen(!isOpen);
    closeSidebar();
  };

  const handleClickOutside = (e) => {
    if (
      sideMenu.current &&
      !sideMenu.current.contains(e.target) &&
      !sideMenuExtra.current.contains(e.target)
    ) {
      toggle(e);
    }
  };

  useEffect(() => {
    isOpen
      ? document.addEventListener('mousedown', handleClickOutside, false)
      : document.removeEventListener('mousedown', handleClickOutside, false);
    // Clear eventListener
    return () => document.removeEventListener('mousedown', toggle, false);
  }, [isOpen]);

  /*End hide sidebar on outside click*/

  useEffect(() => {
    let tooltipNode;
    const sidebarExtra = document.querySelector('.sidebar');

    const destroyTooltip = () => {
      if (tooltipNode) tooltipNode.remove();
      tooltipNode = null;
    };
    document.querySelector('.sidebar').addEventListener('mouseleave', destroyTooltip);
    sidebarExtra.addEventListener('scroll', destroyTooltip);

    return () => {
      if (document.querySelector('.sidebar') !== null) {
        document.querySelector('.sidebar').removeEventListener('mouseleave', destroyTooltip);
        destroyTooltip();
      }
    };
  }, []);

  // prevents sidebar from automatically opening on reload (mainly for mobile view)
  useEffect(() => {
    setMainSidebarOpen(false);
  }, []);

  return (
    <>
      {windowWidth < 606 && (
        <div className="sidebar-switcher">
          <SidebarDrawer
            item={navigation.find(
              (elem) => queryFolder === elem.link || queryFolder === elem?.linkAudit,
            )}
            sidebarOpen={mainSidebarOpen}
            setSidebarOpen={(e) => setMainSidebarOpen(e)}
          />
        </div>
      )}
      {(mainSidebarOpen || windowWidth > 605) && (
        <>
          <div className="sidebar" ref={sideMenu}>
            {sidebarItems.map((item, index) => {
              if (typeof item.onClick === 'function') {
                return (
                  <div
                    className={`${item.className} ${
                      (queryFolder === item.link || queryFolder === item?.linkAudit) && 'active'
                    }`}
                    key={index}
                  >
                    <button onClick={item.onClick} className="position-relative">
                      <img src={item.img} width={'35px'} height={'35px'} alt={item.alt} />
                      <div className="tooltip-text">{item.tooltip}</div>
                    </button>
                  </div>
                );
              } else {
                return (
                  <div
                    className={`${item.className} ${
                      (queryFolder === item.link || queryFolder === item?.linkAudit) && 'active'
                    }`}
                    key={'close' + index}
                    onClick={() => {
                      closeSidebar();
                      setMainSidebarOpen(false);
                    }}
                  >
                    <Link
                      to={item.link}
                      className="position-relative d-flex justify-content-center align-items-center text-decoration-none"
                    >
                      <img src={item.img} width={'35px'} height={'35px'} alt={item.alt} />
                      <div className="tooltip-text">{item.tooltip}</div>
                    </Link>
                  </div>
                );
              }
            })}
          </div>
          <div
            className={`sidebarExtra ulScroll ${sidebarOpen === true ? 'opened' : 'closed'}`}
            ref={sideMenuExtra}
          >
            {longTermAssetsExtra === true ? (
              <LongTermAssetsExtra
                closeSidebar={() => {
                  closeSidebar();
                  setMainSidebarOpen(false);
                }}
              />
            ) : (
              <></>
            )}
            {livestockExtra === true ? (
              <LivestockExtra
                closeSidebar={() => {
                  closeSidebar();
                  setMainSidebarOpen(false);
                }}
              />
            ) : (
              <></>
            )}
            {manufacturingExtra === true ? (
              <ProductionExtra
                closeSidebar={() => {
                  closeSidebar();
                  setMainSidebarOpen(false);
                }}
              />
            ) : (
              <></>
            )}
            {moneyExtra === true ? (
              <MoneyExtra
                closeSidebar={() => {
                  closeSidebar();
                  setMainSidebarOpen(false);
                }}
              />
            ) : (
              <></>
            )}
            <div />
          </div>
        </>
      )}
    </>
  );
};

export default Sidebar;
