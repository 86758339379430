import React from 'react';
import classes from './CardsGridAdministration.module.scss';
import Download from '../../assets/images/icons/download-holo.svg';
import Delete from '../../assets/images/dashboard/delete.svg';
import Edit from '../../assets/images/dashboard/edit.svg';
import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';
import Arrow from '../../assets/images/icons/arrow-black-right.svg';
import { useNotificationBadge } from '../../contexts/NotificationBadge';
import BadgeWrapper from '../badgeWrapper/BadgeWrapper';

// list
//    title
//    image
//    explanations
//        title
//        value
//    onClick
export const CardsGridAdministration = ({ list, document = false, enums = false, crud = false, query = '' }) => {
  const { hasGlobalRequests } = useNotificationBadge();

  return <div className={classes['container']}>
    {
      list.length === 0
        ? null
        : list && list.map((item, id) =>
        item.title.toLowerCase().includes(query.toLowerCase()) &&
        <div key={item.title + '' + id}
             onClick={() => item.onClick && item.onClick()}
             className={
               `${document ? classes['document'] : enums ? classes['enum'] : classes['item']}`
             }>
          {item.image?.length > 0 ?
            item.title === t('administration.sidebar.requests') ?
              <BadgeWrapper condition={hasGlobalRequests} offset={[0, 0]} style={{ height: 12, width: 12, marginTop: 44, right: 'calc(50% - 25px)' }}>
                <div className={classes['image']}>
                  <img src={item.image} alt={item.title} />
                </div>
              </BadgeWrapper>
              :
              <div className={classes['image']}>
                <img src={item.image} alt={item.title} />
              </div>
            :
            <></>
          }
          <div className={document ? classes['titleDocument'] : enums ? classes['titleEnum'] : classes['title']}>
            <div className={`${enums && classes['titleLimit']}`}>{document || enums ?
              <Tooltip title={item.title}>{item.title}</Tooltip> : item.title}</div>
            {enums && <div className='w-auto'><img src={Arrow} alt='Arrow' /></div>}
          </div>
          {item.explanations &&
          <div className={classes['explanationContainer']}>
            {
              item.explanations.map((explanation) => (
                  <div key={item.title + '' + explanation.title}
                       className={document ? explanation.title === t('pages.documents.columns.description') ? classes['explanationDescription'] : classes['explanationDocument'] : classes['explanation']}>
                    <div className={`${classes['explanationTitle']} w-50`}>{explanation.title}</div>
                    {
                      explanation.title === t('pages.documents.columns.description') ?
                        <Tooltip title={explanation.value}>
                          <div className={`${classes['explanationDescriptionValue']} w-50`}>{explanation.value}</div>
                        </Tooltip>
                        :
                        <div className={'w-50'}>{explanation.value}</div>
                    }
                  </div>
                ),
              )
            }
          </div>
          }
          {
            document &&
            <div className={classes['iconContainer']}>
              <img src={Download} alt='Download'
                   className='ml-2'
                   onClick={(e) => {
                     e.stopPropagation();
                     item.download();
                   }}
              />
              {
                crud &&
                <>
                  <img src={Edit} alt='Delete'
                       className='ml-2'
                       onClick={(e) => {
                         e.stopPropagation();
                         item.edit();
                       }}
                  />
                  <img src={Delete} alt='Delete'
                       className='ml-2'
                       onClick={(e) => {
                         e.stopPropagation();
                         item.delete();
                       }}
                  />
                </>
              }
            </div>
          }
        </div>)
    }
  </div>;
};