import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const subsidiesFields = [
  {
    value: 'grantSubsidyCategory',
    label: () => t('pages.grantsSubsidies.grantCategory'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'grant-subsidy-categories',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'grantBasicUnitsType',
    label: () => t('pages.grantsSubsidies.grantBasicUnitsType'),
    type: 'select',
    options: {
      list: [],
      api: 'grant-basic-unit-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'grantFinancedType',
    label: () => t('pages.grantsSubsidies.grantFinancedType'),
    type: 'select',
    options: {
      list: [],
      api: 'grant-financed-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'numberOfBasicUnits',
    label: () => t('pages.grantsSubsidies.numberOfBasicUnits'),
    type: 'integer',
    options: {},
    displayInLimits: true
  },
  {
    value: 'grantType',
    label: () => t('pages.grantsSubsidies.grantType'),
    type: 'select',
    options: {
      list: [],
      api: 'grant-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'forLivestockPurposeValue',
    label: () => t('pages.grantsSubsidies.forLivestockPurposeValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'forPlantPurposeValue',
    label: () => t('pages.grantsSubsidies.forPlantPurposeValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'totalValue',
    label: () => t('pages.grantsSubsidies.totalValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.grantsSubsidies.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default subsidiesFields;