import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const OtherMoneyTypes = () => {
  return (
    <div>
      <StandardTable api={'other-money-types'} title={t('administration.enums.title.otherMoneyType')} />

    </div>
  );
};

export default OtherMoneyTypes;
