import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const moneyFlowFields = [
  {
    value: 'cashFlowType',
    label: () => t('pages.moneyFlow.moneyFlowType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'cash-flow-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'purchasesVATPaidValue',
    label: () => t('pages.moneyFlow.purchasesVATPaidValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'salesVATChargedValue',
    label: () => t('pages.moneyFlow.salesVATChargedValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'vatAdvanceValue',
    label: () => t('pages.moneyFlow.vatAdvanceValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'vatReturnValue',
    label: () => t('pages.moneyFlow.vatReturnValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.moneyFlow.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.moneyFlow.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default moneyFlowFields;