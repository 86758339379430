import React from 'react';
import PropTypes from 'prop-types';
import classes from './FormLabel.module.scss';

const FormLabel = ({label, required, componentName}) => {
  return (
    <div className={classes['label']}>
      <label htmlFor={componentName}>{label}</label> {required === true ? '*' : ''}
    </div>
  );
};

FormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default FormLabel;
