import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LimitsForm from '../../administration/limits/limitsForm/LimitsForm';
import { fetchSingleRevision } from '../../../../services/revision/revision';
import dataModelEntities from '../../explainableControls/utils/dataModelEntities';
import LabourForm from '../../labour/labourForm/LabourForm';
import AgriculturalLandForm from '../../longTermAssets/agriculturalLand/agriculturalLandForm/AgriculturalLandForm';
import FarmBuildingsForm from '../../longTermAssets/farmBuilding/farmBuildingsForm/FarmBuildingsForm';
import MachineryEquipmentForm
  from '../../longTermAssets/machineryEquipment/machineryEnquipmentForm/MachineryEquipmentForm';
import PermanentCropsForm from '../../longTermAssets/permanentCrops/permanentCropsForm/PermanentCropsForm';
import CattleBasicForm from '../../livestock/cattle/cattleForms/CattleBasicForm';
import CattleFatteningForm from '../../livestock/cattle/cattleForms/CattleFatteningForm';
import GoatBasicForm from '../../livestock/goats/goatsForms/GoatBasicForm';
import GoatFatteningForm from '../../livestock/goats/goatsForms/GoatFatteningForm';
import OtherLivestockBasicForm from '../../livestock/otherLivestock/otherLivestockForms/OtherLivestockBasicForm';
import OtherLivestockFatteningForm
  from '../../livestock/otherLivestock/otherLivestockForms/OtherLivestockFatteningForm';
import PigsBasicForm from '../../livestock/pigs/pigsForms/PigsBasicForm';
import PigsFatteningForm from '../../livestock/pigs/pigsForms/PigsFatteningForm';
import PoultryBasicForm from '../../livestock/poultry/poultryForms/PoultryBasicForm';
import PoultryFatteningForm from '../../livestock/poultry/poultryForms/PoultryFatteningForm';
import SheepBasicForm from '../../livestock/sheep/sheepForms/SheepBasicForm';
import SheepFatteningForm from '../../livestock/sheep/sheepForms/SheepFatteningForm';
import AnimalProductsForm from '../../production/animalProducts/animalProductsForm/AnimalProductsForm';
import ManufacturedProductsForm
  from '../../production/manufacturedProducts/manufacturedProductsForm/ManufacturedProductsForm';
import OtherProductsForm from '../../production/otherProducts/otherProductsForm/OtherProductsForm';
import PlantProductsForm from '../../production/plantProducts/plantProductsForm/PlantProductsForm';
import UnfinishedProductsForm from '../../production/unfinishedProducts/unfinishedProductsForm/UnfinishedProductsForm';
import AnimalExpensesForm from '../../money/expenses/animalExpenses/animalForm/AnimalExpensesForm';
import InsuranceExpensesForm from '../../money/expenses/insuranceExpenses/insuranceExpensesForm/InsuranceExpensesForm';
import LabourExpenseForm from '../../money/expenses/labourExpenses/labourForm/LabourExpenseForm';
import LandAndBuildingForm from '../../money/expenses/landAndBuildingExpenses/landAndBuildingForm/LandAndBuildingForm';
import OverheadExpenseForm from '../../money/expenses/overheadExpenses/overheadForm/OverheadExpenseForm';
import PlantProductionExpensesForm
  from '../../money/expenses/plantProductionExpenses/plantForm/PlantProductionExpensesForm';
import ProcessingExpenseForm from '../../money/expenses/processingExpenses/processingForm/ProcessingExpenseForm';
import TaxForm from '../../money/expenses/taxExpenses/taxForm/TaxForm';
import SubsidyForm from '../../money/grants/subsidies/subsidyForm/SubsidyForm';
import LoanForm from '../../money/loans/loan/loanForm/LoanForm';
import LeasingForm from '../../money/loans/leasing/leasingForm/LeasingForm';
import MoneyFlowForm from '../../money/moneyFlow/moneyFlowForm/MoneyFlowForm';
import OtherIncomeForm from '../../money/otherIncome/otherIncomeForm/OtherIncomeForm';

const RevisionContent = ({ props, lastRevisionNumber }) => {
  //entity, id, revisionNumber, ahid
  let params = useParams();
  const [data, setData] = useState('');
  const [display, setDisplay] = useState(false);

  const fetchData = (entity, id, revisionNumber) => {
    fetchSingleRevision(entity, id, revisionNumber)
      .then(r => {
        setData(r.data);
        setDisplay(true);
      })
      .catch(r => {
        setDisplay(false);

      });
  };

  useEffect(() => {
    if (params?.entity && params?.id && (params?.revisionNumber || lastRevisionNumber !== 0)) {
      const entityData = dataModelEntities[params?.entity];
      if (entityData) {
        fetchData(entityData?.entity, params?.id, params?.revisionNumber ? params?.revisionNumber : lastRevisionNumber);
      }
    }
  }, [params?.entity, params?.id, params?.revisionNumber, lastRevisionNumber]);
  return <>
    {display &&
    {
      'limits': <LimitsForm
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'labour': <LabourForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'agriculturalLand': <AgriculturalLandForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'farmBuilding': <FarmBuildingsForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'machineryEquipment': <MachineryEquipmentForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'permanentCrop': <PermanentCropsForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'cattleOwned': <CattleBasicForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'cattleServiceFattening': <CattleFatteningForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'goatOwned': <GoatBasicForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'goatServiceFattening': <GoatFatteningForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'otherOwned': <OtherLivestockBasicForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'otherServiceFattening': <OtherLivestockFatteningForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'pigOwned': <PigsBasicForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'pigServiceFattening': <PigsFatteningForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'poultryOwned': <PoultryBasicForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'poultryServiceFattening': <PoultryFatteningForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'sheepOwned': <SheepBasicForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'sheepServiceFattening': <SheepFatteningForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'animalProduct': <AnimalProductsForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'manufacturedProduct': <ManufacturedProductsForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'otherProduct': <OtherProductsForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'plantProduct': <PlantProductsForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'unfinishedProduct': <UnfinishedProductsForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'animalProductionExpense': <AnimalExpensesForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'insuranceExpense': <InsuranceExpensesForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'labourMachineryExpense': <LabourExpenseForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'landBuildingExpense': <LandAndBuildingForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'overheadExpense': <OverheadExpenseForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'plantProductionExpense': <PlantProductionExpensesForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'processingExpense': <ProcessingExpenseForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'taxExpense': <TaxForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'subsidy': <SubsidyForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'loan': <LoanForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'leasing': <LeasingForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'moneyFlow': <MoneyFlowForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />,
      'otherIncome': <OtherIncomeForm
        props={props}
        data={data}
        isAudit={true}
        type={'show'}
        updateTable={() => {
        }}
        closeModal={() => {
        }}
        isDisabled={true}
      />
    }[params?.entity]
    }
  </>;
};

export default RevisionContent;