import React, { useEffect, useState } from 'react';
import FieldContainer from '../fieldContainer/FieldContainer';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import classes from './TextareaField.module.scss';
import { validate } from '../../../utils/validationDynamic';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

const TextareaField = (
  {
    label,
    helpLabel,
    disabled = false,
    showErrors,
    name,
    value,
    defaultValue,
    onChange,
    onSubmit = () => {
    },
    errorExplanations,
    setErrorExplanations = () => {
    },
    hasWriteExplanationPermission = true,
    validation,
    setError,
    placeholder,
    setSubmitDisabled,
    styles,
    rows = 2,
    ...rest
  }) => {
  let uniqueId = uniqid();

  let componentName = name ? name : uniqueId;

  const redirectPrompt = useRedirectPrompt();

  const [messageData, setMessageData] = useState(validate({ ...validation, required: { value: false } }, value));
  const [stateSubmit, setStateSubmit] = useState(false);

  const prevVal = usePrevious(value);
  const prevValidation = usePrevious(validation);

  useEffect(() => {
    // on component unmounting return states to initial
    return () => {
      setMessageData({});
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    if ((!compareObjects(prevVal, value)) || (!compareObjects({ ...validation, required: { value: false } }, prevValidation))) {
      let errorObject = validate({ ...validation, required: { value: false } }, value);
      if (errorObject?.errors?.errorType === 'required'|| errorObject?.apiErrors?.errorType === "apiError" || errorObject?.errors?.errorType === "error") {
        setStateSubmit(false);
        setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
      } else {
        setStateSubmit(true);
        setSubmitDisabled(prevState => ({ ...prevState, [name]: false }));
      }
      setMessageData(errorObject);
    }
    // eslint-disable-next-line
  }, [value, validation]);

  return (
    <FieldContainer
      label={label}
      componentName={componentName}
      showErrors={showErrors}
      helpLabel={helpLabel}
      required={
        validation
          ? validation.required
          ? validation.required.value === true
          : false
          : false
      }
      additionalMessageData={messageData}
      customClasses={`${styles}`}
      errorExplanations={errorExplanations}
      setErrorExplanations={setErrorExplanations}
      hasWriteExplanationPermission={hasWriteExplanationPermission}
      {...rest}
    >
        <textarea
          disabled={disabled}
          autoComplete="off"
          className={`
                    ${classes['textarea-field']}
                    ${disabled ? classes['disabled'] : ''}
                    ${showErrors && messageData?.errors?.errorType !== 'noErrors' ? classes['error'] : ''}`}
          name={componentName}
          value={value ? value : ''}
          style={{ zIndex: '-1' }}
          onChange={(e) => onChange(e.target.value)}
          onDoubleClick={(e) => e.target.select()}
          onBlur={() => stateSubmit && onSubmit()}
          placeholder={placeholder ? placeholder : t('common.insertValue')}
          title={placeholder ? placeholder : t('common.insertValue')}
          onClick={(e) => {
            // e.target.focus();
            // e.target.setSelectionRange(e.target.value.length, e.target.value.length);
            // e.target.select();
            redirectPrompt.setIsDirty(true);
          }}
          id={componentName}
          rows={rows}
        />
    </FieldContainer>
  );
};

TextareaField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  validation: PropTypes.object,
  setError: PropTypes.func,
};

export default TextareaField;
