import React from 'react';
import { DEFAULT_MESSAGE, DEFAULT_VALIDATION, REGEX } from './validationDefaults';

export const checkIfExplanationValid = (obj) => {
  if (obj?.explanations?.errorType === 'explanation') {
    return obj?.explanations?.body.every(item => item?.explanation);
  } else {
    return true;
  }
};

// validation functions
export const checkString = (rules, value) => {
  let errors = { body: <></>, errorType: 'noErrors' };
  let apiErrors = { body: <></>, errorType: 'noErrors' };
  let explanations = { body: <></>, errorType: 'noErrors' };
  let warnings = { body: <></>, errorType: 'noErrors' };
  let required = { body: <></>, errorType: 'noErrors' };
  const inp = value === null || value === undefined ? '' : value.toString().trim();
  // front validation
  if (rules.hasOwnProperty('required') && rules.required.value && inp === '') {
    required = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'required' }
      : { body: <></>, errorType: 'noErrors' };
  }
  if (inp !== '' && rules.hasOwnProperty('min') && inp.length < rules.min.value) {
    errors = rules.min.hasOwnProperty('message')
      ? { body: rules.min.message, errorType: 'error' }
      : { body: `${DEFAULT_MESSAGE.MINCHAR} ${rules.min.value}!`, errorType: 'error' };
  } else if (inp !== '' && rules.hasOwnProperty('max') && inp.length > rules.max.value) {
    errors = rules.max.hasOwnProperty('message')
      ? { body: rules.max.message, errorType: 'error' }
      : { body: `${DEFAULT_MESSAGE.MAXCHAR} ${rules.max.value}!`, errorType: 'error' };
  } else if (!rules.hasOwnProperty('max') && inp.length > (rules.type === 'textarea' ? DEFAULT_VALIDATION.TEXTAREA_MAX : DEFAULT_VALIDATION.STRING_MAX)) {
    errors = {
      body: `${DEFAULT_MESSAGE.MAXCHAR} ${rules.type === 'textarea' ? DEFAULT_VALIDATION.TEXTAREA_MAX : DEFAULT_VALIDATION.STRING_MAX}!`,
      errorType: 'error',
    };
  } else if (
    inp !== '' &&
    rules.hasOwnProperty('matches') &&
    rules.matches.value === 'PASSWORD' &&
    REGEX['PASSWORD'] !== inp
  ) {
    errors = rules.matches.hasOwnProperty('message')
      ? { body: rules.matches.message, errorType: 'error' }
      : { body: DEFAULT_MESSAGE.MISMATCH, errorType: 'error' };
  } else if (
    inp !== '' &&
    rules.hasOwnProperty('matches') &&
    rules.matches.value !== 'PASSWORD' &&
    !REGEX[rules.matches.value].test(inp)
  ) {
    errors = rules.matches.hasOwnProperty('message')
      ? { body: rules.matches.message, errorType: 'error' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'error' };
  }
  // api validation
  if (
    rules.hasOwnProperty('apiError') &&
    rules.apiError.value === true
  ) {
    apiErrors = rules.apiError.hasOwnProperty('messages')
      ? { body: rules.apiError.messages, errorType: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'apiError' };
  }
  // explanations
  if (
    rules.hasOwnProperty('explanation') &&
    rules.explanation.value === true
  ) {
    explanations = rules.explanation.hasOwnProperty('messages')
      ? { body: rules.explanation.messages, errorType: 'explanation' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'explanation' };
  }
  // warnings
  if (
    rules.hasOwnProperty('warning') &&
    rules.warning.value === true
  ) {
    warnings = rules.warning.hasOwnProperty('messages')
      ? { body: rules.warning.messages, errorType: 'warning' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'warning' };
  }
  return { errors: errors, apiErrors: apiErrors, explanations: explanations, warnings: warnings, required: required };
};

export const checkNumber = (rules, value) => {
  let errors = { body: <></>, errorType: 'noErrors' };
  let apiErrors = { body: <></>, errorType: 'noErrors' };
  let explanations = { body: <></>, errorType: 'noErrors' };
  let warnings = { body: <></>, errorType: 'noErrors' };
  let required = { body: <></>, errorType: 'noErrors' };
  const inp = value === null || value === undefined ? '' : value;

  if (rules.hasOwnProperty('required') && rules.required.value && inp === '') {
    required = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'required' }
      : { body: DEFAULT_MESSAGE.REQUIRED, errorType: 'required' };
  }
  if (inp !== '' && rules.hasOwnProperty('min') && inp < rules.min.value) {
    errors = rules.min.hasOwnProperty('message')
      ? { body: rules.min.message, errorType: 'error' }
      : { body: `${DEFAULT_MESSAGE.MINVALUE} ${rules.min.value}!`, errorType: 'error' };
  } else if (inp !== '' && rules.hasOwnProperty('max') && inp > rules.max.value) {
    errors = rules.max.hasOwnProperty('message')
      ? { body: rules.max.message, errorType: 'error' }
      : { body: `${DEFAULT_MESSAGE.MAXVALUE} ${rules.max.value}!`, errorType: 'error' };
  } else if (!rules.hasOwnProperty('max') && inp > DEFAULT_VALIDATION.NUMBER_MAX) {
    errors = { body: `${DEFAULT_MESSAGE.MAXVALUE} ${DEFAULT_VALIDATION.NUMBER_MAX}!`, errorType: 'error' };
  } else if (
    inp !== '' &&
    rules.hasOwnProperty('matches') &&
    !REGEX[rules.matches.value].test(inp)
  ) {
    errors = rules.matches.hasOwnProperty('message')
      ? { body: rules.matches.message, errorType: 'error' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'error' };
  }
  // api validation
  if (
    rules.hasOwnProperty('apiError') &&
    rules.apiError.value === true
  ) {
    apiErrors = rules.apiError.hasOwnProperty('messages')
      ? { body: rules.apiError.messages, errorType: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'apiError' };
  }
  // explanations
  if (
    rules.hasOwnProperty('explanation') &&
    rules.explanation.value === true
  ) {
    explanations = rules.explanation.hasOwnProperty('messages')
      ? { body: rules.explanation.messages, errorType: 'explanation' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'explanation' };
  }
  // warnings
  if (
    rules.hasOwnProperty('warning') &&
    rules.warning.value === true
  ) {
    warnings = rules.warning.hasOwnProperty('messages')
      ? { body: rules.warning.messages, errorType: 'warning' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'warning' };
  }
  return { errors: errors, apiErrors: apiErrors, explanations: explanations, warnings: warnings, required: required };
};

export const checkSelectSingle = (rules, value) => {
  let errors = { body: <></>, errorType: 'noErrors' };
  let apiErrors = { body: <></>, errorType: 'noErrors' };
  let explanations = { body: <></>, errorType: 'noErrors' };
  let warnings = { body: <></>, errorType: 'noErrors' };
  let required = { body: <></>, errorType: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    (value === null || value === undefined)
  ) {
    required = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'required' }
      : { body: DEFAULT_MESSAGE.REQUIRED, errorType: 'required' };
  }
  // api validation
  if (
    rules.hasOwnProperty('apiError') &&
    rules.apiError.value === true
  ) {
    apiErrors = rules.apiError.hasOwnProperty('messages')
      ? { body: rules.apiError.messages, errorType: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'apiError' };
  }
  // explanations
  if (
    rules.hasOwnProperty('explanation') &&
    rules.explanation.value === true
  ) {
    explanations = rules.explanation.hasOwnProperty('messages')
      ? { body: rules.explanation.messages, errorType: 'explanation' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'explanation' };
  }
  // warnings
  if (
    rules.hasOwnProperty('warning') &&
    rules.warning.value === true
  ) {
    warnings = rules.warning.hasOwnProperty('messages')
      ? { body: rules.warning.messages, errorType: 'warning' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'warning' };
  }
  return { errors: errors, apiErrors: apiErrors, explanations: explanations, warnings: warnings, required: required };
};


export const checkSelectMulti = (rules, value) => {
  let errors = { body: <></>, errorType: 'noErrors' };
  let apiErrors = { body: <></>, errorType: 'noErrors' };
  let explanations = { body: <></>, errorType: 'noErrors' };
  let warnings = { body: <></>, errorType: 'noErrors' };
  let required = { body: <></>, errorType: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    !(Array.isArray(value) && value.length > 0)
  ) {
    required = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'required' }
      : { body: DEFAULT_MESSAGE.REQUIRED, errorType: 'required' };
  }
  // api validation
  if (
    rules.hasOwnProperty('apiError') &&
    rules.apiError.value === true
  ) {
    apiErrors = rules.apiError.hasOwnProperty('messages')
      ? { body: rules.apiError.messages, errorType: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'apiError' };
  }
  // explanations
  if (
    rules.hasOwnProperty('explanation') &&
    rules.explanation.value === true
  ) {
    explanations = rules.explanation.hasOwnProperty('messages')
      ? { body: rules.explanation.messages, errorType: 'explanation' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'explanation' };
  }
  // warnings
  if (
    rules.hasOwnProperty('warning') &&
    rules.warning.value === true
  ) {
    warnings = rules.warning.hasOwnProperty('messages')
      ? { body: rules.warning.messages, errorType: 'warning' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'warning' };
  }
  return { errors: errors, apiErrors: apiErrors, explanations: explanations, warnings: warnings, required: required };
};

export const checkDate = (rules, value) => {
  let errors = { body: <></>, errorType: 'noErrors' };
  let apiErrors = { body: <></>, errorType: 'noErrors' };
  let explanations = { body: <></>, errorType: 'noErrors' };
  let warnings = { body: <></>, errorType: 'noErrors' };
  let required = { body: <></>, errorType: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    (value === null || value === undefined || value.length === 0)
  ) {
    required = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'required' }
      : { body: DEFAULT_MESSAGE.REQUIRED, errorType: 'required' };
  }
  // api validation
  if (
    rules.hasOwnProperty('apiError') &&
    rules.apiError.value === true
  ) {
    apiErrors = rules.apiError.hasOwnProperty('messages')
      ? { body: rules.apiError.messages, errorType: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'apiError' };
  }
  // explanations
  if (
    rules.hasOwnProperty('explanation') &&
    rules.explanation.value === true
  ) {
    explanations = rules.explanation.hasOwnProperty('messages')
      ? { body: rules.explanation.messages, errorType: 'explanation' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'explanation' };
  }
  // warnings
  if (
    rules.hasOwnProperty('warning') &&
    rules.warning.value === true
  ) {
    warnings = rules.warning.hasOwnProperty('messages')
      ? { body: rules.warning.messages, errorType: 'warning' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'warning' };
  }
  return { errors: errors, apiErrors: apiErrors, explanations: explanations, warnings: warnings, required: required };
};

export const checkRadio = (rules, value) => {
  let message = { body: <></>, type: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.error.value &&
    (value === null || value === undefined || value.length === 0)
  ) {
    message = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'error' }
      : { body: DEFAULT_MESSAGE.REQUIRED, errorType: 'error' };
  } else if (
    rules.hasOwnProperty('error') &&
    rules.required.value === true
  ) {
    message = rules.error.hasOwnProperty('messages')
      ? { body: rules.error.messages, errorType: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'apiError' };
  }
  return message;
};

export const checkCheckbox = (rules, value) => {
  let errors = { body: <></>, errorType: 'noErrors' };
  let apiErrors = { body: <></>, errorType: 'noErrors' };
  let explanations = { body: <></>, errorType: 'noErrors' };
  let warnings = { body: <></>, errorType: 'noErrors' };
  let required = { body: <></>, errorType: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    !(Array.isArray(value) && value.length > 0)
  ) {
    required = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'required' }
      : { body: DEFAULT_MESSAGE.REQUIRED, errorType: 'required' };
  }
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    !(Array.isArray(value) && value.length > 0)
  ) {
    required = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'required' }
      : { body: DEFAULT_MESSAGE.REQUIRED, errorType: 'required' };
  }
  // api validation
  if (
    rules.hasOwnProperty('apiError') &&
    rules.apiError.value === true
  ) {
    apiErrors = rules.apiError.hasOwnProperty('messages')
      ? { body: rules.apiError.messages, errorType: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'apiError' };
  }
  // explanations
  if (
    rules.hasOwnProperty('explanation') &&
    rules.explanation.value === true
  ) {
    explanations = rules.explanation.hasOwnProperty('messages')
      ? { body: rules.explanation.messages, errorType: 'explanation' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'explanation' };
  }
  // warnings
  if (
    rules.hasOwnProperty('warning') &&
    rules.warning.value === true
  ) {
    warnings = rules.warning.hasOwnProperty('messages')
      ? { body: rules.warning.messages, errorType: 'warning' }
      : { body: DEFAULT_MESSAGE.INVALID, errorType: 'warning' };
  }
  return { errors: errors, apiErrors: apiErrors, explanations: explanations, warnings: warnings, required: required };
};

// usage
// rulesObj = {
//        type: string, - which validation function to call, required
//       required: {value: true/false, message: string(if not defined, default message is shown)}, - rule
//       min: {value: 2, message: string(if not defined, default message is shown)},
//       max: {value: 255, message: string(if not defined, default message is shown)},
//       matches: {value: 'PORT', message: string(if not defined, default message is shown)}, - value is property in REXEG object
// }
// value - field value
export const validate = (rulesObj, value) => {
  switch (rulesObj.type) {
    case 'string':
    case 'textarea':
      return checkString(rulesObj, value);
    case 'number':
      return checkNumber(rulesObj, value);
    case 'selectSingle':
      return checkSelectSingle(rulesObj, value);
    case 'selectMulti':
      return checkSelectMulti(rulesObj, value);
    case 'date':
      return checkDate(rulesObj, value);
    case 'radio':
      return checkRadio(rulesObj, value);
    case 'checkbox':
      return checkCheckbox(rulesObj, value);
    default:
      return {};
  }
};
