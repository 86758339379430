import { t } from 'react-switch-lang';
import Tooltip from 'antd/lib/tooltip';
import React from 'react';
import { eng, formatNumber, hasValue } from '../../../../../utils/const';

// tooltip that shows on table entry hover
const renderColWithTooltip = (col) => {
  return (
    <Tooltip title={col} placement="topLeft" color={'#67B278'}>
      {hasValue(col) ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.farmBuildings.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: (id) => renderColWithTooltip(id),
  },
  {
    title: t('pages.farmBuildings.objectType'),
    dataIndex: ['objectType', eng ? 'nameEn' : 'name'],
    key: 'objectType',
    ellipsis: true,
    render: (landCategory) => renderColWithTooltip(landCategory),
  },

  {
    title: t('pages.farmBuildings.openingValue'),
    dataIndex: 'openingValue',
    key: 'openingValue',
    ellipsis: true,
    render: (openingArea) => renderColWithTooltip(formatNumber(openingArea)),
  },
  {
    title: t('pages.farmBuildings.openingArea'),
    dataIndex: 'openingArea',
    key: 'openingArea',
    ellipsis: true,
    render: (openingValue) => renderColWithTooltip(formatNumber(openingValue)),
  },
  {
    title: t('pages.farmBuildings.investmentValue'),
    dataIndex: 'investmentValue',
    key: 'investmentValue',
    ellipsis: true,
    render: (investmentValue) => renderColWithTooltip(formatNumber(investmentValue)),
  },
  {
    title: t('pages.farmBuildings.investmentArea'),
    dataIndex: 'investmentArea',
    key: 'investmentArea',
    ellipsis: true,
    render: (purchasedArea) => renderColWithTooltip(formatNumber(purchasedArea)),
  },
  {
    title: t('pages.farmBuildings.closingValue'),
    dataIndex: 'closingValue',
    key: 'closingValue',
    ellipsis: true,
    render: (openingArea) => renderColWithTooltip(formatNumber(openingArea)),
  },
  {
    title: t('pages.farmBuildings.closingArea'),
    dataIndex: 'closingArea',
    key: 'closingArea',
    ellipsis: true,
    render: (purchasedArea) => renderColWithTooltip(formatNumber(purchasedArea)),
  },
];
