import { axiosGet } from '../axios';

export const getAllAgriculturalLands = () => {
  return axiosGet('agricultural-lands');
};

export const fetchSingleAgriculturalLand = (id) => {
  return axiosGet(`agricultural-lands/${id}`);
};

export const fetchLandCategories = () => {
  return axiosGet('land-categories');
};
