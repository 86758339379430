import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const moneyProcessingFields = [
  {
    value: 'processingExpenseType',
    label: () => t('pages.processingExpenses.processingExpenseType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'processing-expense-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.processingExpenses.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseValue',
    label: () => t('pages.processingExpenses.purchaseValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumptionValue',
    label: () => t('pages.processingExpenses.consumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'otherProcessingExpense',
    label: () => t('pages.processingExpenses.otherProcessingExpense'),
    type: 'text',
    options: {},
    displayInLimits: false
  },
  {
    value: 'otherProcessingExpenseApproved',
    label: () => t('pages.processingExpenses.otherProcessingExpenseApproved'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'closingValue',
    label: () => t('pages.processingExpenses.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.processingExpenses.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default moneyProcessingFields;