import { axiosGet } from '../axios';
import { eng } from "../../utils/const";

const defaultOption = (option) => {
  return {
    label: eng ? option.nameEn : option.name,
    value: option.id,
    disabled: false,
  };
};

export const fetchSingleOtherIncome = (id) => {
  return axiosGet(`other-incomes/${id}`);
};

export const getOtherIncomeTypes = () => {
  return axiosGet('other-money-types').then(r => {
    let arrTemp = [];
    for (let item of r.data) {
      arrTemp.push(
        defaultOption(item),
      );
    }
    return arrTemp;
  }).catch(() => []);
};
