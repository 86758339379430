import { t } from 'react-switch-lang';

export const roles = [
  {
    value: 'ROLE_ADMIN',
    label: t('common.admin'),
  },
  {
    value: 'ROLE_COLLECTOR',
    label: t('common.collector'),
  },
  {
    value: 'ROLE_VIEWER',
    label: t('common.viewer'),
  },
];
