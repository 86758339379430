import React, { useContext, useState } from 'react';
import { columns } from './utils/requestsColumns';
import UserData from '../../../../contexts/UserData';
import RequestsTable from '../../../../components/table/requestsTable/RequestsTable';
import Toast from '../../../../components/toast/Toast';
import { t } from 'react-switch-lang';
import PropTypes from 'prop-types';
import { axiosPut } from '../../../../services/axios';

const Requests = ({ inModal = false }) => {
  const user = useContext(UserData);

  const [updateTable, setUpdateTable] = useState(false);

  // Handles button click for completing requests
  const handleComplete = (record) => {
    axiosPut(`requests/complete/${record.id}`)
      .then(() => setUpdateTable((prevState) => !prevState))
      .catch(() =>
        Toast.fire({
          title: t('common.error'),
          icon: 'error',
          iconColor: '#f27474',
        }),
      );
  };

  return (
    <div className="d-flex flex-column">
      <RequestsTable
        api={'requests'}
        pageable={false}
        columns={columns}
        handleComplete={handleComplete}
        key={updateTable}
        user={user}
        inModal={inModal}
      />
    </div>
  );
};

export default Requests;

Requests.propTypes = {
  inModal: PropTypes.bool,
};
