import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SelectConstField from '../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getAnimalProducts,
  getProductionAnimalProducts,
} from '../../../../../services/production/animalProducts/animalProductsApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './AnimalProductsForm.module.scss';
import { Toast } from '../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import UserData from '../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import CheckboxField from '../../../../../components/formDynamic/checkboxField/CheckboxField';
import Loader from '../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const AnimalProductsForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const [animalProducts, setAnimalProducts] = useState();

  const [productionAnimalProduct, setProductionAnimalProduct] = useState({ value: undefined, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [openingQuantity, setOpeningQuantity] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [otherAnimalProduct, setOtherAnimalProduct] = useState({
    value: undefined,
    disabled: !edit ? true : !user.isAdmin,
  });
  const [otherAnimalProductApproved, setOtherAnimalProductApproved] = useState({
    value: undefined,
    disabled: !edit ? true : !user.isAdmin,
  });
  const [productionQuantity, setProductionQuantity] = useState({ value: 0, disabled: !edit });
  const [soldQuantity, setSoldQuantity] = useState({ value: 0, disabled: !edit });
  const [soldValue, setSoldValue] = useState({ value: 0, disabled: !edit });
  const [ownConsumptionQuantity, setOwnConsumptionQuantity] = useState({ value: 0, disabled: !edit });
  const [ownConsumptionValue, setOwnConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [paymentInProductsQuantity, setPaymentInProductsQuantity] = useState({ value: 0, disabled: !edit });
  const [paymentInProductsValue, setPaymentInProductsValue] = useState({ value: 0, disabled: !edit });
  const [spentOnProcessingQuantity, setSpentOnProcessingQuantity] = useState({ value: 0, disabled: !edit });
  const [spentOnProcessingValue, setSpentOnProcessingValue] = useState({ value: 0, disabled: !edit });
  const [closingQuantity, setClosingQuantity] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });

  const [errorExplanations, setErrorExplanations] = useState([]);

  // Enums
  const [productionAnimalProductOptionsList, setProductionAnimalProductOptionsList] = useState([]);
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const validationInitial = {
    productionAnimalProduct: { type: 'selectSingle', required: { value: true } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    openingQuantity: { type: 'number', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    otherAnimalProduct: { type: 'string', required: { value: false }, min: { value: 3 } },
    otherAnimalProductApproved: { type: 'checkbox', required: { value: false } },
    productionQuantity: {
      type: 'number',
      required: { value: false },
      warning: { value: true, messages: t('pages.animalProducts.warnings.productionQuantity') },
    },
    soldQuantity: { type: 'number', required: { value: false } },
    soldValue: { type: 'number', required: { value: false } },
    ownConsumptionQuantity: {
      type: 'number',
      required: { value: false },
      warning: { value: true, messages: t('pages.animalProducts.warnings.ownConsumptionQuantity') },
    },
    ownConsumptionValue: { type: 'number', required: { value: false } },
    paymentInProductsQuantity: { type: 'number', required: { value: false } },
    paymentInProductsValue: { type: 'number', required: { value: false } },
    spentOnProcessingQuantity: { type: 'number', required: { value: false } },
    spentOnProcessingValue: { type: 'number', required: { value: false } },
    closingQuantity: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [validation, setValidation] = useState(validationInitial);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/
    // Validation fields for resetting api errors
    // const fieldProductionAnimalProduct = { ...validation['productionAnimalProduct'], error: { value: false } };

    // todo change dependant fields
  const setProductionAnimalProductDependentFields = (e) => {
      // setShowErrors(true);
      // setValidation({
      //   ...validation,
      //   ['productionAnimalProduct']: fieldProductionAnimalProduct,
      // });
      if (e?.value) {
        if (e.value === 1 || e.value === 3 || e.value === 4 ||
          e.value === 9 || e.value === 10 || e.value === 11 ||
          e.value === 12 || e.value === 13 || e.value === 14 || e.value === 19) {
          setOpeningQuantity(prevState => ({ ...prevState, value: 0, disabled: true }));
          setOpeningValue(prevState => ({ ...prevState, value: 0, disabled: true }));
        } else {
          setOpeningQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
        }

        if (e.value !== 9) {
          setOtherAnimalProduct(prevState => ({ ...prevState, disabled: true, value: undefined }));
          setOtherAnimalProductApproved(prevState => ({ ...prevState, disabled: true, value: undefined }));
        } else {
          setOtherAnimalProduct(prevState => ({ ...prevState, disabled: !user.isAdmin }));
          setOtherAnimalProductApproved(prevState => ({ ...prevState, disabled: !user.isAdmin }));
        }

        if (e.value === 2 || e.value === 6 || e.value === 7 ||
          e.value === 9 || e.value === 10 || e.value === 11 ||
          e.value === 12 || e.value === 13 || e.value === 14 ||
          e.value === 15 || e.value === 16 || e.value === 17 ||
          e.value === 18 || e.value === 19) {
          setSpentOnProcessingQuantity({ value: 0, disabled: true });
          setSpentOnProcessingValue({ value: 0, disabled: true });
        } else {
          setSpentOnProcessingQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setSpentOnProcessingValue(prevState => ({ ...prevState, disabled: !edit }));
        }

        if (e.value === 10 || e.value === 11 ||
          e.value === 12 || e.value === 13 || e.value === 14 ||
          e.value === 19) {
          setProductionQuantity({ value: 0, disabled: true });
          setSoldQuantity({ value: 0, disabled: true });
          setOwnConsumptionQuantity({ value: 0, disabled: true });
          setOwnConsumptionValue({ value: 0, disabled: true });
          setPaymentInProductsQuantity({ value: 0, disabled: true });
          setPaymentInProductsValue({ value: 0, disabled: true });
          setClosingValue({ value: 0, disabled: true });
          setClosingQuantity({ value: 0, disabled: true });
        } else {
          setProductionQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setSoldQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setOwnConsumptionQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setOwnConsumptionValue(prevState => ({ ...prevState, disabled: !edit }));
          setPaymentInProductsQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setPaymentInProductsValue(prevState => ({ ...prevState, disabled: !edit }));
          setClosingValue(prevState => ({ ...prevState, disabled: !edit }));
          setClosingQuantity(prevState => ({ ...prevState, disabled: true }));
        }
      }
    };

  useEffect(() => {
    if (!isAudit) {
      const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

      const val = parseFloatCustom(returnNullVal(openingQuantity?.value) +
        returnNullVal(productionQuantity?.value) -
        returnNullVal(soldQuantity?.value) -
        returnNullVal(ownConsumptionQuantity?.value) -
        returnNullVal(paymentInProductsQuantity?.value) -
        returnNullVal(spentOnProcessingQuantity?.value)).toFixed(2);

      setClosingQuantity(prevState => ({
        prevState,
        value: parseFloat(val) >= 0 ? val : 0,
      }));
    }
  }, [openingQuantity.value,
    productionQuantity.value,
    soldQuantity.value,
    ownConsumptionQuantity.value,
    paymentInProductsQuantity.value,
    spentOnProcessingQuantity.value]);
  /***** END DEPENDANT VALIDATION *****/


  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'animalProduct', animalProducts, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(productionAnimalProduct.value?.value && { productionAnimalProduct: { id: parseInt(productionAnimalProduct.value?.value) } }),
        ...(comment?.value && { comment: comment.value }),
        ...(otherAnimalProduct?.value && { otherAnimalProduct: otherAnimalProduct.value }),
        otherAnimalProductApproved: otherAnimalProductApproved?.disabled ? null : hasValue(otherAnimalProductApproved?.value) && otherAnimalProductApproved?.value[0] === 'true',
        ...(hasValue(openingQuantity.value) && { openingQuantity: parseFloatCustom(openingQuantity.value) }),
        ...(hasValue(openingValue.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(hasValue(productionQuantity.value) && { productionQuantity: parseFloatCustom(productionQuantity.value) }),
        ...(hasValue(soldQuantity.value) && { soldQuantity: parseFloatCustom(soldQuantity.value) }),
        ...(hasValue(soldValue.value) && { soldValue: parseFloatCustom(soldValue.value) }),
        ...(hasValue(ownConsumptionQuantity.value) && { ownConsumptionQuantity: parseFloatCustom(ownConsumptionQuantity.value) }),
        ...(hasValue(ownConsumptionValue.value) && { ownConsumptionValue: parseFloatCustom(ownConsumptionValue.value) }),
        ...(hasValue(paymentInProductsQuantity?.value) && { paymentInProductsQuantity: parseFloatCustom(paymentInProductsQuantity.value) }),
        ...(hasValue(paymentInProductsValue?.value) && { paymentInProductsValue: parseFloatCustom(paymentInProductsValue.value) }),
        ...(hasValue(spentOnProcessingQuantity?.value) && { spentOnProcessingQuantity: parseFloatCustom(spentOnProcessingQuantity.value) }),
        ...(hasValue(spentOnProcessingValue?.value) && { spentOnProcessingValue: parseFloatCustom(spentOnProcessingValue.value) }),
        ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`animal-products?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.animalProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add');
          }
        } catch (e) {
          handleApiErrors(e, 'add');
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`animal-products/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.animalProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit');
          }
        } catch (e) {
          handleApiErrors(e, 'edit');
        }
      }
    }
  };

  /*****END SUBMIT FORM *****/

  const fetchAnimalProduct = async () => {
    try {
      const { data } = await getAnimalProducts(props.match.params.id);
      setAnimalProducts(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };

  const fetchProductionAnimalProducts = async () => {
    try {
      const { data } = await getProductionAnimalProducts();
      let productionAnimalProductsTemp = [];
      for (let productionAnimalProduct of data) {
        productionAnimalProductsTemp.push(
          defaultOption(productionAnimalProduct),
        );
      }
      setProductionAnimalProductOptionsList([...productionAnimalProductsTemp]);
    } catch (e) {
    }
  };

  useEffect(() => {
    fetchProductionAnimalProducts();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchAnimalProduct().then(animalProducts => {
          setProductionAnimalProduct(prevState => ({
            ...prevState,
            value: defaultOption(animalProducts?.productionAnimalProduct),
          }));
          setComment(prevState => ({ ...prevState, value: animalProducts?.comment }));
          setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(animalProducts?.openingQuantity) }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(animalProducts?.openingValue) }));
          setProductionQuantity(prevState => ({
            ...prevState,
            value: parseDouble(animalProducts?.productionQuantity),
          }));
          setOtherAnimalProduct(prevState => ({ ...prevState, value: animalProducts?.otherAnimalProduct }));
          setOtherAnimalProductApproved((prevState => ({
            ...prevState,
            value: animalProducts?.otherAnimalProductApproved ? [animalProducts?.otherAnimalProductApproved ? 'true' : 'false'] : [],
          })));
          setSoldQuantity(prevState => ({ ...prevState, value: parseDouble(animalProducts?.soldQuantity) }));
          setSoldValue(prevState => ({ ...prevState, value: parseDouble(animalProducts?.soldValue) }));
          setOwnConsumptionQuantity(prevState => ({
            ...prevState,
            value: parseDouble(animalProducts?.ownConsumptionQuantity),
          }));
          setOwnConsumptionValue(prevState => ({
            ...prevState,
            value: parseDouble(animalProducts?.ownConsumptionValue),
          }));
          setPaymentInProductsQuantity(prevState => ({
            ...prevState,
            value: parseDouble(animalProducts?.paymentInProductsQuantity),
          }));
          setPaymentInProductsValue(prevState => ({
            ...prevState,
            value: parseDouble(animalProducts?.paymentInProductsValue),
          }));
          setSpentOnProcessingQuantity(prevState => ({
            ...prevState,
            value: parseDouble(animalProducts?.spentOnProcessingQuantity),
          }));
          setSpentOnProcessingValue(prevState => ({
            ...prevState,
            value: parseDouble(animalProducts?.spentOnProcessingValue),
          }));
          setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(animalProducts?.closingQuantity) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(animalProducts?.closingValue) }));
          setErrorExplanations(animalProducts?.errorExplanations ? animalProducts?.errorExplanations : []);

          let explanations = animalProducts?.errorExplanations ? animalProducts?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'animalProduct', animalProducts, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        setProductionAnimalProduct(prevState => ({
          ...prevState,
          value: defaultOption(data?.productionAnimalProduct),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(data?.openingQuantity) }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setProductionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.productionQuantity) }));
        setOtherAnimalProduct(prevState => ({ ...prevState, value: data?.otherAnimalProduct }));
        setOtherAnimalProductApproved((prevState => ({
          ...prevState,
          value: data?.otherAnimalProductApproved ? [data?.otherAnimalProductApproved ? 'true' : 'false'] : [],
        })));
        setSoldQuantity(prevState => ({ ...prevState, value: parseDouble(data?.soldQuantity) }));
        setSoldValue(prevState => ({ ...prevState, value: parseDouble(data?.soldValue) }));
        setOwnConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.ownConsumptionQuantity) }));
        setOwnConsumptionValue(prevState => ({ ...prevState, value: parseDouble(data?.ownConsumptionValue) }));
        setPaymentInProductsQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.paymentInProductsQuantity),
        }));
        setPaymentInProductsValue(prevState => ({ ...prevState, value: parseDouble(data?.paymentInProductsValue) }));
        setSpentOnProcessingQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.spentOnProcessingQuantity),
        }));
        setSpentOnProcessingValue(prevState => ({ ...prevState, value: parseDouble(data?.spentOnProcessingValue) }));
        setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(data?.closingQuantity) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'animalProduct', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (edit) {
      setProductionAnimalProduct(prevState => ({ ...prevState, disabled: false }));
      setComment(prevState => ({ ...prevState, disabled: false }));
      setOpeningQuantity(prevState => ({ ...prevState, disabled: false }));
      setOpeningValue(prevState => ({ ...prevState, disabled: false }));
      setProductionQuantity(prevState => ({ ...prevState, disabled: false }));
      setOtherAnimalProduct(prevState => ({ ...prevState, disabled: !user.isAdmin }));
      setOtherAnimalProductApproved(prevState => ({ ...prevState, disabled: !user.isAdmin }));
      setSoldQuantity(prevState => ({ ...prevState, disabled: false }));
      setSoldValue(prevState => ({ ...prevState, disabled: false }));
      setOwnConsumptionQuantity(prevState => ({ ...prevState, disabled: false }));
      setOwnConsumptionValue(prevState => ({ ...prevState, disabled: false }));
      setPaymentInProductsQuantity(prevState => ({ ...prevState, disabled: false }));
      setPaymentInProductsValue(prevState => ({ ...prevState, disabled: false }));
      setSpentOnProcessingQuantity(prevState => ({ ...prevState, disabled: false }));
      setSpentOnProcessingValue(prevState => ({ ...prevState, disabled: false }));
      // setClosingQuantity({ ...closingQuantity, disabled: false });
      setClosingValue(prevState => ({ ...prevState, disabled: false }));
    }
  }, [edit]);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(counter + 1);
    if (!isAudit && counter >= 1) {
      setProductionAnimalProductDependentFields(productionAnimalProduct?.value);
    }
  }, [productionAnimalProduct?.value, edit]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED


  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    label={t('pages.animalProducts.productionAnimalProduct')}
                    placeholder={t('pages.animalProducts.placeholder.productionAnimalProduct')}
                    value={productionAnimalProduct.value}
                    name='productionAnimalProduct'
                    onChange={(e) => {
                      setProductionAnimalProduct({ ...productionAnimalProduct, value: e });
                      setShowErrors(true);
                    }}
                    options={productionAnimalProductOptionsList}
                    disabled={productionAnimalProduct.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['productionAnimalProduct']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.otherAnimalProduct')}
                    placeholder={t('pages.animalProducts.placeholder.otherAnimalProduct')}
                    helpLabel={t('pages.animalProducts.help.otherAnimalProduct')}
                    value={otherAnimalProduct.value}
                    name='otherAnimalProduct'
                    type={'text'}
                    onChange={(e) => {
                      setOtherAnimalProduct({
                        ...otherAnimalProduct,
                        value: e,
                      });
                    }}
                    disabled={otherAnimalProduct.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherAnimalProduct']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProducts.otherAnimalProductApproved')}
                    helpLabel={t('pages.animalProducts.help.otherAnimalProductApproved')}
                    name='otherAnimalProductApproved'
                    options={[{ value: 'true', label: '' }]}
                    value={otherAnimalProductApproved?.value}
                    onChange={(e) => {
                      setOtherAnimalProductApproved(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={otherAnimalProductApproved?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherAnimalProductApproved']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    label={t('pages.animalProducts.openingQuantity')}
                    placeholder={t('pages.animalProducts.placeholder.openingQuantity')}
                    value={openingQuantity.value}
                    name='openingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningQuantity({
                        ...openingQuantity,
                        value: e,
                      });
                    }}
                    disabled={openingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['openingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.openingValue')}
                    placeholder={t('pages.animalProducts.placeholder.openingValue')}
                    value={openingValue.value}
                    name='openingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue({
                        ...openingValue,
                        value: e,
                      });
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.productionQuantity')}
                    placeholder={t('pages.animalProducts.placeholder.productionQuantity')}
                    value={productionQuantity.value}
                    name='productionQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setProductionQuantity({
                        ...productionQuantity,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['productionQuantity']: { ...validation['productionQuantity'], error: { value: false } },
                      });
                    }}
                    disabled={productionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['productionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.soldQuantity')}
                    placeholder={t('pages.animalProducts.placeholder.soldQuantity')}
                    value={soldQuantity.value}
                    name='soldQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setSoldQuantity({
                        ...soldQuantity,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['soldQuantity']: { ...validation['soldQuantity'], error: { value: false } },
                      });
                    }}
                    disabled={soldQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['soldQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.soldValue')}
                    placeholder={t('pages.animalProducts.placeholder.soldValue')}
                    value={soldValue.value}
                    name='soldValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSoldValue({
                        ...soldValue,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['soldValue']: { ...validation['soldValue'], error: { value: false } },
                      });
                    }}
                    disabled={soldValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['soldValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.ownConsumptionQuantity')}
                    placeholder={t('pages.animalProducts.placeholder.ownConsumptionQuantity')}
                    value={ownConsumptionQuantity.value}
                    name='ownConsumptionQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOwnConsumptionQuantity({
                        ...ownConsumptionQuantity,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['ownConsumptionQuantity']: {
                          ...validation['ownConsumptionQuantity'],
                          error: { value: false },
                        },
                      });
                    }}
                    disabled={ownConsumptionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['ownConsumptionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    label={t('pages.animalProducts.ownConsumptionValue')}
                    placeholder={t('pages.animalProducts.placeholder.ownConsumptionValue')}
                    value={ownConsumptionValue.value}
                    name='ownConsumptionValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOwnConsumptionValue({
                        ...ownConsumptionValue,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['ownConsumptionValue']: { ...validation['ownConsumptionValue'], error: { value: false } },
                      });
                    }}
                    disabled={ownConsumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['ownConsumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.paymentInProductsQuantity')}
                    placeholder={t('pages.animalProducts.placeholder.paymentInProductsQuantity')}
                    value={paymentInProductsQuantity.value}
                    name='paymentInProductsQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setPaymentInProductsQuantity({
                        ...paymentInProductsQuantity,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['paymentInProductsQuantity']: {
                          ...validation['paymentInProductsQuantity'],
                          error: { value: false },
                        },
                      });
                    }}
                    disabled={paymentInProductsQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['paymentInProductsQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.paymentInProductsValue')}
                    placeholder={t('pages.animalProducts.placeholder.paymentInProductsValue')}
                    value={paymentInProductsValue.value}
                    name='paymentInProductsValue'
                    type={'double'}
                    suffix='€'
                    onChange={(e) => {
                      setPaymentInProductsValue({
                        ...paymentInProductsValue,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['paymentInProductsValue']: {
                          ...validation['paymentInProductsValue'],
                          error: { value: false },
                        },
                      });
                    }}
                    disabled={paymentInProductsValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['paymentInProductsValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.spentOnProcessingQuantity')}
                    placeholder={t('pages.animalProducts.placeholder.spentOnProcessingQuantity')}
                    value={spentOnProcessingQuantity.value}
                    name='spentOnProcessingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setSpentOnProcessingQuantity({
                        ...spentOnProcessingQuantity,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['spentOnProcessingQuantity']: {
                          ...validation['spentOnProcessingQuantity'],
                          error: { value: false },
                        },
                      });
                    }}
                    disabled={spentOnProcessingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['spentOnProcessingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.spentOnProcessingValue')}
                    placeholder={t('pages.animalProducts.placeholder.spentOnProcessingValue')}
                    value={spentOnProcessingValue.value}
                    suffix='€'
                    name='spentOnProcessingValue'
                    type={'double'}
                    onChange={(e) => {
                      setSpentOnProcessingValue({
                        ...spentOnProcessingValue,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['spentOnProcessingValue']: {
                          ...validation['spentOnProcessingValue'],
                          error: { value: false },
                        },
                      });
                    }}
                    disabled={spentOnProcessingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['spentOnProcessingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.closingQuantity')}
                    placeholder={t('pages.animalProducts.placeholder.closingQuantity')}
                    value={closingQuantity.value}
                    name='closingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setClosingQuantity({
                        ...closingQuantity,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['closingQuantity']: { ...validation['closingQuantity'], error: { value: false } },
                      });
                    }}
                    disabled={closingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['closingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.animalProducts.closingValue')}
                    placeholder={t('pages.animalProducts.placeholder.closingValue')}
                    value={closingValue.value}
                    name='closingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue({
                        ...closingValue,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['closingValue']: { ...validation['closingValue'], error: { value: false } },
                      });
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <TextareaField
                    label={t('pages.animalProducts.comment')}
                    placeholder={t('pages.animalProducts.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment({
                        ...comment,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['comment']: { ...validation['comment'], error: { value: false } },
                      });
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/production/animal-products`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};

export default AnimalProductsForm;
