import React from 'react';
import classes from './SidebarDrawer.module.scss';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { UpOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const SidebarDrawer = ({ item, sidebarOpen, setSidebarOpen }) => {
  return (
    <Button className={classes.button} onClick={() => setSidebarOpen(!sidebarOpen)}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center">
          <img src={item?.img} width={'25px'} height={'25px'} alt={item?.alt} />
          <div className="ml-3">{item?.tooltip}</div>
        </div>
        {sidebarOpen ? (
          <UpOutlined style={{ strokeWidth: '100', stroke: 'black' }} />
        ) : (
          <DownOutlined style={{ strokeWidth: '100', stroke: 'black' }} />
        )}
      </div>
    </Button>
  );
};

export default SidebarDrawer;

SidebarDrawer.propTypes = {
  item: PropTypes.object,
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func,
};
