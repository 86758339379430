import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { t } from 'react-switch-lang';
import { OtherLivestock } from './OtherLivestock';
import { OtherLivestockFattening } from './OtherLivestockFattening';
import { useHistory } from 'react-router-dom';

const OtherLivestockFormContainer = (props) => {

  const { TabPane } = Tabs;

  const [defaultTab, setDefaultTab] = useState(1);

  const history = useHistory();

  useEffect(() => {
    setDefaultTab(localStorage.getItem('otherLivestockFattening'));
  }, []);

  useEffect(() => history.listen(() => {
    if (!history?.location?.pathname.includes('other')) {
      localStorage.setItem('otherLivestockFattening', '1');
    }
  }), []);

  function callback(key) {
    setDefaultTab(key);
    localStorage.setItem('otherLivestockFattening', key);
  }


  return (
    <Tabs defaultActiveKey={defaultTab} key={defaultTab} onChange={callback}>
      <TabPane tab={ t('pages.other.owned') } key="1">
        <OtherLivestock />
      </TabPane>
      <TabPane tab={ t('pages.other.fattening') } key="2">
        <OtherLivestockFattening />
      </TabPane>
    </Tabs>
  )
};

export default OtherLivestockFormContainer;