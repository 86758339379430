import React from 'react';
import FrCategory from "../../../../../components/administration/enums/frCategoryForm/FrCategory";
import {t} from "react-switch-lang";

const ProcessingExpenseType = () => {
  return (
    <div>
      <FrCategory api={'processing-expense-types'} title={t('administration.enums.title.processingExpenseType')}/>
    </div>
  );
};

export default ProcessingExpenseType;
