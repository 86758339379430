import React, { useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import { t } from 'react-switch-lang';

export const ProductionExtra = ({ closeSidebar }) => {
  let { ahid } = useParams();

  const items = [
    {
      name: 'plantProducts',
      link: `/agricultural-holding/${ahid}/production/plant-products`,
      text: t('sidebarExtra.production.plantProducts'),
      tooltip: t('sidebarExtra.production.plantProducts'),
    },
    {
      name: 'manufacturedProducts',
      link: `/agricultural-holding/${ahid}/production/manufactured-products`,
      text: t('sidebarExtra.production.manufacturedProducts'),
      tooltip: t('sidebarExtra.production.manufacturedProducts'),
    },
    {
      name: 'animalProducts',
      link: `/agricultural-holding/${ahid}/production/animal-products`,
      text: t('sidebarExtra.production.animalProducts'),
      tooltip: t('sidebarExtra.production.animalProducts'),
    },
    {
      name: 'otherProducts',
      link: `/agricultural-holding/${ahid}/production/other-products`,
      text: t('sidebarExtra.production.otherProducts'),
      tooltip: t('sidebarExtra.production.otherProducts'),
    },
    {
      name: 'unfinishedProducts',
      link: `/agricultural-holding/${ahid}/production/unfinished-products`,
      text: t('sidebarExtra.production.unfinishedProducts'),
      tooltip: t('sidebarExtra.production.unfinishedProducts'),
    },
  ];

  const query = window.location.pathname;

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div
            className={`system-link links ${query === item.link ? 'active' : ''}`}
            key={index}
            onClick={() => closeSidebar()}
          >
            <Link to={item.link}>{item.text}</Link>
          </div>
        );
      })}
    </div>
  );
};
