import React, { useRef, useState } from 'react';
import ValidationForm from '../../../components/form/validationForm/ValidationForm';
import InputField from '../../../components/form/inputField/InputField';
import SaveBtn from '../../../components/buttons/SaveBtn';
import { axiosPost } from '../../../services/axios';
import classes from './ForgotPassword.module.scss';
import { t } from 'react-switch-lang';
import ModalWrapper from '../../../components/modal/modalWrapper/ModalWrapper';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

const ForgotPassword = ({ isModalOpen, setIsModalOpen, modalRightAlign }) => {
  const formRef = useRef();
  const [email, setEmail] = useState();

  const validation = {
    emailAddress: { type: 'string', required: { value: true }, matches: { value: 'EMAIL' } },
  };

  const submitData = async () => {
    try {
      const response = await axiosPost(`account/reset-password/init?mail=${email}`);
      if (response.status === 200) {
        // makes a popup that prompts user to go back to login page
        Swal.fire({
          text: t('reset.initSuccess'),
          icon: 'success',
          confirmButtonText: t('reset.ok'),
        });
        setIsModalOpen(false);
      }
    } catch (err) {
      Swal.fire({
        text: t('reset.error'),
        icon: 'error',
        confirmButtonText: t('reset.ok'),
      });
    }
  };

  return (
    <ModalWrapper
      className={modalRightAlign && 'modal-shift-right'}
      onClose={() => setIsModalOpen(false)}
      isModalOpen={isModalOpen}
      header={<span className={`${classes['title']}`}>{t('reset.enterEmail')}</span>}
    >
      <div className="d-flex flex-column">
        <div className="w-100 mt-3">
          <div className="d-flex w-100 justify-content-center">
            <div style={{ width: '100%' }}>
              <ValidationForm validation={validation} ref={formRef}>
                <div className="d-flex flex-column">
                  <InputField
                    label={t('contactUs.email')}
                    placeholder={t('contactUs.emailPlaceholder')}
                    value={email}
                    name="emailAddress"
                    groupField
                    onChange={(e) => {
                      setEmail(e);
                    }}
                    disabled={false}
                  />
                </div>
                <div className="d-flex w-100 justify-content-center mt-4">
                  <SaveBtn
                    loaderOnDisabled={false}
                    loader={false}
                    label={t('common.confirm')}
                    className="pl-5 pr-5"
                    onClick={() => {
                      formRef.current.submitForm(submitData);
                    }}
                  />
                </div>
              </ValidationForm>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ForgotPassword;

ForgotPassword.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};
