import { t } from 'react-switch-lang';

export const eng = localStorage.getItem('language') === 'eng';

const agriculturalHoldingsFields = [
  {
    value: 'ahFadnId',
    label: () => t('pages.agriculturalHolding.ahFadnId'),
    type: 'number',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'isActive',
    label: () => t('pages.agriculturalHolding.isActive'),
    type: 'checkbox',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'primaryCollector',
    label: () => t('pages.agriculturalHolding.primaryCollector'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'admin/users/role',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false,
    },
    displayInLimits: false,
  },
  {
    value: 'secondaryCollector',
    label: () => t('pages.agriculturalHolding.secondaryCollector'),
    type: 'select',
    options: {
      list: [],
      api: 'admin/users/role',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false,
    },
    displayInLimits: false,
  },
  {
    value: 'ricaAhId',
    label: () => t('pages.agriculturalHolding.ricaAhId'),
    type: 'text',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'mnRegistrationId',
    label: () => t('pages.agriculturalHolding.mnRegistrationId'),
    type: 'text',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'office',
    label: () => t('pages.agriculturalHolding.office'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'city',
    label: () => t('pages.agriculturalHolding.city'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'settlement',
    label: () => t('pages.agriculturalHolding.settlement'),
    type: 'text',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'zipCode',
    label: () => t('pages.agriculturalHolding.zipCode'),
    type: 'text',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'ownershipType',
    label: () => t('pages.agriculturalHolding.ownershipType'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'taxType',
    label: () => t('pages.agriculturalHolding.taxType'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'vat',
    label: () => t('pages.agriculturalHolding.vat'),
    type: 'checkbox',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'altitude',
    label: () => t('pages.agriculturalHolding.altitude'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'productionType',
    label: () => t('pages.agriculturalHolding.productionType'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'farmType',
    label: () => t('pages.agriculturalHolding.farmType'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'economicSize',
    label: () => t('pages.agriculturalHolding.economicSize'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'areaConstraint',
    label: () => t('pages.agriculturalHolding.areaConstraint'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'userFarmingSurfacesTotal',
    label: () => t('pages.agriculturalHolding.userFarmingSurfacesTotal'),
    type: 'double',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'usedFarmingSurfConventionalProd',
    label: () => t('pages.agriculturalHolding.usedFarmingSurfConventionalProd'),
    type: 'double',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'usedFarmingSurfIrrigation',
    label: () => t('pages.agriculturalHolding.usedFarmingSurfIrrigation'),
    type: 'double',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'otherGainfulActivities',
    label: () => t('pages.agriculturalHolding.otherGainfulActivities'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'protectedProductFarmType',
    label: () => t('pages.agriculturalHolding.protectedProductFarmType'),
    type: 'select',
    options: {
      list: [], // it's async
    },
    displayInLimits: false,
  },
  {
    value: 'organicProductionOnly',
    label: () => t('pages.agriculturalHolding.organicProductionOnly'),
    type: 'checkbox',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'geoLatitude',
    label: () => t('pages.agriculturalHolding.geoLatitude'),
    type: 'double',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'geoLongitude',
    label: () => t('pages.agriculturalHolding.geoLongitude'),
    type: 'double',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'naturaArea',
    label: () => t('pages.agriculturalHolding.naturaArea'),
    type: 'checkbox',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'weighting',
    label: () => t('pages.agriculturalHolding.weighting'),
    type: 'double',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'hadErrors',
    label: () => t('pages.agriculturalHolding.hadErrors'),
    type: 'checkbox',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'fromPreviousYear',
    label: () => t('pages.agriculturalHolding.fromPreviousYear'),
    type: 'checkbox',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'lockedBy',
    label: () => t('pages.agriculturalHolding.lockedBy'),
    type: 'number',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'lockedDate',
    label: () => t('pages.agriculturalHolding.lockedDate'),
    type: 'date',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'dropped',
    label: () => t('pages.agriculturalHolding.dropped'),
    type: 'checkbox',
    options: {},
    displayInLimits: false,
  },
];

export default agriculturalHoldingsFields;