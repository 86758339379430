import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import SelectConstField from '../../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import classes from './PlantProductionExpensesForm.module.scss';
import { Toast } from '../../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../../utils/const';
import UserData from '../../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../../components/formDynamic/helpers';
import {
  fetchSinglePlantProdExpense,
  getMineralFertilizerType,
  getPlantExpenseType,
} from '../../../../../../services/money/expenses/plantProductionExpenses';
import CheckboxField from '../../../../../../components/formDynamic/checkboxField/CheckboxField';
import Loader from '../../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';


const PlantProductionExpensesForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    plantExpenseType: { type: 'selectSingle', required: { value: true } },
    openingQuantity: { type: 'number', required: { value: false } },
    openingValue: { type: 'number', required: { value: true } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    mineralFertilizerType: { type: 'selectSingle', required: { value: true } },
    otherPlantExpense: { type: 'string', required: { value: false }, min: { value: 3 } },
    otherPlantExpenseApproved: { type: 'checkbox', required: { value: false } },
    purchaseQuantity: { type: 'number', required: { value: false } },
    purchaseValue: { type: 'number', required: { value: false } },
    consumptionQuantity: { type: 'number', required: { value: false } },
    consumptionValue: { type: 'number', required: { value: false } },
    closingQuantity: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [plant, setPlant] = useState();

  const [plantExpenseType, setPlantExpenseType] = useState({ value: undefined, disabled: !edit });
  const [openingQuantity, setOpeningQuantity] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [mineralFertilizerType, setMineralFertilizerType] = useState({ value: undefined, disabled: !edit });
  const [otherPlantExpense, setOtherPlantExpense] = useState({ value: undefined, disabled: !edit });
  const [otherPlantExpenseApproved, setOtherPlantExpenseApproved] = useState({
    value: undefined,
    disabled: user.isAdmin ? !edit : true,
  });
  const [purchaseQuantity, setPurchaseQuantity] = useState({ value: 0, disabled: !edit });
  const [purchaseValue, setPurchaseValue] = useState({ value: 0, disabled: !edit });
  const [consumptionQuantity, setConsumptionQuantity] = useState({ value: 0, disabled: !edit });
  const [consumptionValue, setConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [closingQuantity, setClosingQuantity] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });


  const [plantExpenseTypeList, setPlantExpenseTypeList] = useState([]);
  const [mineralFertilizerTypeList, setMineralFertilizerTypeList] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/

  const changePlantExpense = (e) => {
    if (e?.value) {
      if (![2, 3, 4].includes(e?.value)) {
        setOpeningQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setClosingQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));

      } else {
        setOpeningQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setClosingQuantity(prevState => ({ ...prevState, disabled: true }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: !edit }));
      }

      if (e?.value !== 2) {
        setMineralFertilizerType(prevState => ({ ...prevState, value: null, disabled: true }));
      } else {
        setMineralFertilizerType(prevState => ({ ...prevState, disabled: !edit }));
      }

      if (e?.value !== 6) {
        setOtherPlantExpense(prevState => ({ ...prevState, value: null, disabled: true }));
        setOtherPlantExpenseApproved(prevState => ({ ...prevState, value: [''], disabled: true }));
      } else {
        setOtherPlantExpense(prevState => ({ ...prevState, disabled: !edit }));
        setOtherPlantExpenseApproved(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };

  const calculateClosingValue = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(returnNullVal(openingValue.value)
      + returnNullVal(purchaseValue.value)
      - returnNullVal(consumptionValue.value)).toFixed(2);

    setClosingValue(prevState => ({
      ...prevState,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };

  const calculateClosingQuantity = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(returnNullVal(openingQuantity.value)
      + returnNullVal(purchaseQuantity.value)
      - returnNullVal(consumptionQuantity.value)).toFixed(2);

    setClosingQuantity(prevState => ({
      ...prevState,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };
  /***** END DEPENDANT VALIDATION *****/


  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'plantProductionExpense', plant, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/


  /*****START SUBMIT FORM *****/

  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking ? isPreLocking : isSubmit ? Object.values(submitDisabled).every(value => !value) : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(plantExpenseType?.value && { plantExpenseType: { id: parseInt(plantExpenseType.value?.value) } }),
        ...(mineralFertilizerType?.value && { mineralFertilizerType: { id: parseInt(mineralFertilizerType.value?.value) } }),
        otherPlantExpenseApproved: otherPlantExpenseApproved?.disabled ? null : hasValue(otherPlantExpenseApproved?.value) && otherPlantExpenseApproved?.value[0] === 'true',
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(openingQuantity?.value) && { openingQuantity: parseFloatCustom(openingQuantity.value) }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(hasValue(purchaseQuantity?.value) && { purchaseQuantity: parseFloatCustom(purchaseQuantity.value) }),
        ...(hasValue(consumptionQuantity?.value) && { consumptionQuantity: parseFloatCustom(consumptionQuantity.value) }),
        ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        ...(hasValue(consumptionValue?.value) && { consumptionValue: parseFloatCustom(consumptionValue.value) }),
        ...(hasValue(purchaseValue?.value) && { purchaseValue: parseFloatCustom(purchaseValue.value) }),
        ...(otherPlantExpense?.value && { otherPlantExpense: otherPlantExpense.value }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`plant-production-expenses?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.plantProductionExpenses') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`plant-production-expenses/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.plantProductionExpenses') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchPlantProd = async () => {
    try {
      const { data } = await fetchSinglePlantProdExpense(props.match.params.id);
      setPlant(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchPlantProdType = async () => {
    const data = await getPlantExpenseType();
    setPlantExpenseTypeList(data);
  };

  const fetchMineralFertilizerType = async () => {
    const data = await getMineralFertilizerType();
    setMineralFertilizerTypeList(data);
  };

  useEffect(() => {
    fetchPlantProdType();
    fetchMineralFertilizerType();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchPlantProd().then(plant => {
          plant?.plantExpenseType && setPlantExpenseType(prevState => ({
            ...prevState,
            value: defaultOption(plant?.plantExpenseType),
          }));
          plant?.mineralFertilizerType && setMineralFertilizerType(prevState => ({
            ...prevState,
            value: defaultOption(plant?.mineralFertilizerType),
          }));
          setComment(prevState => ({ ...prevState, value: plant?.comment }));
          setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(plant?.openingQuantity) }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(plant?.openingValue) }));
          setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(plant?.purchaseValue) }));
          setPurchaseQuantity(prevState => ({ ...prevState, value: parseDouble(plant?.purchaseQuantity) }));
          setConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(plant?.consumptionQuantity) }));
          setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(plant?.closingQuantity) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(plant?.closingValue) }));
          setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(plant?.consumptionValue) }));
          setOtherPlantExpense(prevState => ({ ...prevState, value: plant?.otherPlantExpense }));
          setOtherPlantExpenseApproved(prevState => ({
            ...prevState,
            value: plant?.otherPlantExpenseApproved
              ? plant?.otherPlantExpenseApproved ? ['true'] : ['false']
              : [],
          }));
          setErrorExplanations(plant?.errorExplanations ? plant?.errorExplanations : []);
          let explanations = plant?.errorExplanations ? plant?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'plantProductionExpense', plant, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        data?.plantExpenseType && setPlantExpenseType(prevState => ({
          ...prevState,
          value: defaultOption(data?.plantExpenseType),
        }));
        data?.mineralFertilizerType && setMineralFertilizerType(prevState => ({
          ...prevState,
          value: defaultOption(data?.mineralFertilizerType),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setOpeningQuantity(prevState => ({ ...prevState, value: parseFloatCustom(data?.openingQuantity) }));
        setOpeningValue(prevState => ({ ...prevState, value: parseFloatCustom(data?.openingValue) }));
        setPurchaseValue(prevState => ({ ...prevState, value: parseFloatCustom(data?.purchaseValue) }));
        setPurchaseQuantity(prevState => ({ ...prevState, value: parseFloatCustom(data?.purchaseQuantity) }));
        setConsumptionQuantity(prevState => ({ ...prevState, value: parseFloatCustom(data?.consumptionQuantity) }));
        setClosingQuantity(prevState => ({ ...prevState, value: parseFloatCustom(data?.closingQuantity) }));
        setClosingValue(prevState => ({ ...prevState, value: parseFloatCustom(data?.closingValue) }));
        setConsumptionValue(prevState => ({ ...prevState, value: parseFloatCustom(data?.consumptionValue) }));
        setOtherPlantExpense(prevState => ({ ...prevState, value: data?.otherPlantExpense }));
        setOtherPlantExpenseApproved(prevState => ({
          ...prevState,
          value: data?.otherPlantExpenseApproved
            ? data?.otherPlantExpenseApproved ? ['true'] : ['false']
            : [],
        }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);
        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'plantProductionExpense', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setPlantExpenseType(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setOpeningQuantity(prevState => ({ ...prevState, disabled: false }));
        setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        setMineralFertilizerType(prevState => ({ ...prevState, disabled: false }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: false }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: false }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: false }));
        // setClosingQuantity(prevState => ({ ...prevState, disabled: false }));
        setClosingValue(prevState => ({ ...prevState, disabled: false }));
        setConsumptionValue(prevState => ({ ...prevState, disabled: false }));
        setOtherPlantExpense(prevState => ({ ...prevState, disabled: false }));
        setOtherPlantExpenseApproved(prevState => ({ ...prevState, disabled: !user.isAdmin }));
      }
    }
  }, [edit]);

  useEffect(() => {
    if (!isAudit) {
      changePlantExpense(plantExpenseType?.value);
    }
  }, [plantExpenseType?.value, edit]);


  useEffect(() => {
    if (!isAudit) {
      calculateClosingValue();
    }
  }, [openingValue.value,
    purchaseValue.value,
    consumptionValue.value]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingQuantity();
    }
  }, [openingQuantity.value,
    purchaseQuantity.value,
    consumptionQuantity.value]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.plantExpenseType')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.plantExpenseType')}
                    value={plantExpenseType.value}
                    name='plantExpenseType'
                    onChange={(e) => {
                      setPlantExpenseType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={plantExpenseTypeList}
                    disabled={plantExpenseType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['plantExpenseType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.openingQuantity')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.openingQuantity')}
                    value={openingQuantity.value}
                    name='openingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.openingValue')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.openingValue')}
                    value={openingValue.value}
                    name='openingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <SelectConstField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.mineralFertilizerType')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.mineralFertilizerType')}
                    value={mineralFertilizerType.value}
                    name='mineralFertilizerType'
                    onChange={(e) => {
                      setMineralFertilizerType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={mineralFertilizerTypeList}
                    disabled={mineralFertilizerType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['mineralFertilizerType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    notRequired={true}
                    showRequiredError={false}
                  />
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.otherPlantExpense')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.otherPlantExpense')}
                    helpLabel={t('pages.plantProductionExpenses.help.otherPlantExpense')}
                    value={otherPlantExpense.value}
                    name='otherPlantExpense'
                    type={'text'}
                    onChange={(e) => {
                      setOtherPlantExpense(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={otherPlantExpense.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherPlantExpense']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.otherPlantExpenseApproved')}
                    helpLabel={t('pages.plantProductionExpenses.help.otherPlantExpenseApproved')}
                    name='otherPlantExpenseApproved'
                    options={[{ value: 'true', label: '' }]}
                    value={otherPlantExpenseApproved?.value}
                    onChange={(e) => {
                      setOtherPlantExpenseApproved(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={otherPlantExpenseApproved?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherPlantExpenseApproved']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.purchaseQuantity')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.purchaseQuantity')}
                    value={purchaseQuantity.value}
                    name='purchaseQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                </div>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.purchaseValue')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.purchaseValue')}
                    value={purchaseValue.value}
                    name='purchaseValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.consumptionQuantity')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.consumptionQuantity')}
                    value={consumptionQuantity.value}
                    name='consumptionQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setConsumptionQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumptionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumptionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.consumptionValue')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.consumptionValue')}
                    value={consumptionValue.value}
                    suffix='€'
                    name='consumptionValue'
                    type={'double'}
                    onChange={(e) => {
                      setConsumptionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.closingQuantity')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.closingQuantity')}
                    value={closingQuantity.value}
                    name='closingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setClosingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.closingValue')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.closingValue')}
                    value={closingValue.value}
                    suffix='€'
                    name='closingValue'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    label={t('pages.plantProductionExpenses.comment')}
                    placeholder={t('pages.plantProductionExpenses.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showRequiredError={showRequiredError}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/expenses/plant-production-expenses`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};
export default PlantProductionExpensesForm;
