import React from 'react';
import Requests from './Requests';
import ModalWrapper from '../../../../components/modal/modalWrapper/ModalWrapper';
import { t } from 'react-switch-lang';
import PropTypes from 'prop-types';

const RequestsModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <ModalWrapper
      className="modalXl-responsive"
      header={t('pageTitle.requests')}
      onClose={() => setIsModalOpen(false)}
      isModalOpen={isModalOpen}
    >
      <Requests inModal={true} />
    </ModalWrapper>
  );
};

export default RequestsModal;

RequestsModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};
