import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from 'react-switch-lang'

const EconomicSize = () => {
  return (
    <div>
      <StandardTable api={'economic-sizes'} title={t('administration.enums.title.economicSize')} />

    </div>
  );
};

export default EconomicSize;
