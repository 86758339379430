import React from 'react';
import FrCategory from "../../../../../components/administration/enums/frCategoryForm/FrCategory";
import {t} from "react-switch-lang";

const OverheadExpenseType = () => {
  return (
    <div>
      <FrCategory api={'overhead-expense-types'} title={t('administration.enums.title.overheadExpenseType')}/>
    </div>
  );
};

export default OverheadExpenseType;
