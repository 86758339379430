import { axiosGet, axiosPost } from '../axios';

export const fetchMessages = (id) => {
  return axiosGet(`error-explanation-messages?errorExplanationId.equals=${id}`);
};

export const sendSingleMessage = (payload) => {
  return axiosPost('error-explanation-messages', payload);
};

export const readMessages = (id) => {
  return axiosGet(`error-explanations/${id}/read-messages`);
};