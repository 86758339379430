import React, { useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../../../components/form/validationForm/ValidationForm';
import InputField from '../../../../../components/form/inputField/InputField';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import { t } from 'react-switch-lang';
import SelectAsyncField from '../../../../../components/form/selectField/SelectAsyncField';
import { eng, hasValue, parseFloatCustom } from '../../../../../utils/const';
import { Toast } from '../../../../../utils/sweetAlert';

const WeightingsForm = ({ data, type, updateTable, closeModal, isDisabled }) => {
  const formRef = useRef();

  const initialData = {
    region: undefined,
    farmType: undefined,
    economicSize: undefined,
    population: 0,
    weight: 1
  };

  const [changedData, setChangedData] = useState(initialData);

  const validation = {
    region: { type: 'selectSingle', required: { value: true } },
    farmType: { type: 'selectSingle', required: { value: true } },
    economicSize: { type: 'selectSingle', required: { value: true } },
    population: { type: 'number', required: { value: true } },
    weight: { type: 'number', required: { value: true } },
  };

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData,
        region: { id: changedData.region.value },
        farmType: { id: changedData.farmType.value },
        economicSize: { id: changedData.economicSize.value },
        ...(hasValue(changedData?.weight) && { weight: parseFloatCustom(changedData.weight) })
      };

      try {
        const response = await axiosPost('weights', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.weightings.messages.successAdd'),
            icon: 'success',
            timer: 2000,
          });
        }
        cancel();
      } catch (err) {
        if (err.response.data.errorKey === 'error.uniqueConstraintViolated') {
          Toast.fire({
            title: t('administration.weightings.messages.uniqueConstraintViolated'),
            icon: 'error',
            timer: 5000,
          });
        }
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData,
        region: { id: changedData.region.value },
        farmType: { id: changedData.farmType.value },
        economicSize: { id: changedData.economicSize.value },
        ...(hasValue(changedData?.weight) && { weight: parseFloatCustom(changedData.weight) })
      };

      try {
        const response = await axiosPut(`weights/${data.id}`, payload);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.weightings.messages.successEdit'),
            icon: 'success',
            timer: 2000,
          });
        }
        cancel();
      } catch (err) {
        if (err.response.data.errorKey === 'error.uniqueConstraintViolated') {
          Toast.fire({
            title: t('administration.weightings.messages.uniqueConstraintViolated'),
            icon: 'error',
            timer: 5000,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        region: { value: data.region.id, label: eng ? data.region.nameEn : data.region.name },
        farmType: { value: data.farmType.id, label: eng ? data.farmType.nameEn : data.farmType.name },
        economicSize: { value: data.economicSize.id, label: eng ? data.economicSize.nameEn : data.economicSize.name },
        population: data.population,
        weight: data.weight,
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <SelectAsyncField
          label={t('administration.weightings.columns.region')}
          placeholder={t('administration.weightings.placeholders.region')}
          name='region'
          value={changedData.region}
          api={'regions'}
          pagination={false}
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, region: e });
          }}
          disabled={isDisabled}
        />
        <SelectAsyncField
          label={t('administration.weightings.columns.farmType')}
          placeholder={t('administration.weightings.placeholders.farmType')}
          name='farmType'
          value={changedData.farmType}
          api={'farm-types'}
          pagination={false}
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, farmType: e });
          }}
          disabled={isDisabled}
        />
        <SelectAsyncField
          label={t('administration.weightings.columns.economicSize')}
          placeholder={t('administration.weightings.placeholders.economicSize')}
          name='economicSize'
          value={changedData.economicSize}
          api={'economic-sizes'}
          pagination={false}
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, economicSize: e });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.weightings.columns.population')}
          placeholder={t('administration.weightings.placeholders.population')}
          value={changedData.population}
          name='population'
          type='number'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, population: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.weightings.columns.weight')}
          placeholder={t('administration.weightings.placeholders.weight')}
          value={changedData.weight}
          name='weight'
          type='double'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, weight: e, });
          }}
          disabled={isDisabled}
        />
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)} />
        </div>
      )}
    </>
  );
};

export default WeightingsForm;
