import React, { useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../components/form/validationForm/ValidationForm';
import InputField from '../../../components/form/inputField/InputField';
import SaveBtn from '../../../components/buttons/SaveBtn';
import { axiosPost } from '../../../services/axios';
import classes from './ResetPassword.module.scss';
import Swal from 'sweetalert2';
import { getLanguage, setLanguage, t } from 'react-switch-lang';
import { useHistory, useLocation } from 'react-router-dom';
import AgroLogo from '../../../assets/images/agro-logo.png';
import Government from '../../../assets/images/government.svg';
import Ministry from '../../../assets/images/ministry.svg';
import Logo from '../../../assets/images/logo-big.svg';
import LogoEn from '../../../assets/images/logo-en-big.svg';
import ContactUs from '../contactUs/ContactUs';
import { REGEX } from '../../../utils/validationDefaults';
import { useWindowWidth } from '../../../contexts/WindowWidth';
import { useWindowHeight } from '../../../contexts/WindowHeight';

const ResetPassword = () => {
  const formRef = useRef();
  const history = useHistory();
  const { search } = useLocation();

  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [refreshConfirmPassword, setRefreshConfirmPassword] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // changes password value inside validation file
  REGEX.currentPassword = password;

  const lang = search.split('lang=')[1] === 'en' ? 'eng' : getLanguage();

  const validation = {
    password: {
      type: 'string',
      required: { value: true },
      min: { value: 8 },
      max: { value: 100 },
      matches: { value: 'STRONGPASSWORD' },
    },
    confirmPassword: {
      type: 'string',
      required: { value: true },
      min: { value: 8 },
      max: { value: 100 },
      matches: { value: 'PASSWORD' },
    },
  };

  const submitData = async () => {
    const payload = {
      key: search.split('&')[0].split('key=')[1],
      newPassword: password,
    };

    try {
      const response = await axiosPost('account/reset-password/finish', payload);
      if (response.status === 200) {
        // makes a popup that prompts user to go back to login page
        Swal.fire({
          text: t('reset.resetSuccess'),
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          history.push('/login');
        });
      }
    } catch (err) {
      Swal.fire({
        text: t('reset.error'),
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  // watches confirmPassword field when changing password field
  useEffect(() => {
    setRefreshConfirmPassword((prevState) => !prevState);
  }, [REGEX.PASSWORD]);

  useEffect(() => {
    setLanguage(lang);
    localStorage.setItem('language', getLanguage());

    let isAuth = localStorage.getItem('jwt-token');
    if (!(isAuth === null || isAuth === undefined)) {
      history.push('/home');
    }
  }, []);

  return (
    <div
      className={`d-flex vw-100 vh-100 align-items-center justify-content-center ${classes['contentWrapper']}`}
    >
      {isModalOpen && <ContactUs isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}

      {windowWidth > 900 && (
        <div
          className={`${classes['contentWrapper__background']} w-50 h-100 d-flex flex-column justify-content-between align-items-center`}
        >
          <div className="pt-4">
            <img src={AgroLogo} alt="Agricultural logo" width={'330px'} height={'auto'} />
          </div>
          <div className="d-flex w-75 pb-4 justify-content-between">
            <img src={Ministry} alt="Ministry" />
            <img src={Government} alt="Government" />
          </div>
        </div>
      )}
      <div className={`${windowWidth > 900 ? 'w-50' : 'w-100'} h-100 d-flex flex-column align-items-center ${windowHeight > 650 && windowWidth > 390 ? 'justify-content-center' : 'mt-5'}`}>
        <img
          src={lang === 'eng' ? LogoEn : Logo}
          alt="Logo"
          className={`${classes['contentWrapper__logo']}`}
          width={'183px'}
          height={'auto'}
        />
        <div className={`${classes['contentWrapper__welcome']}`}>{t('reset.finishReset')}</div>
        <div className={`${classes['contentWrapper__form']}`}>
          <ValidationForm validation={validation} ref={formRef}>
            <InputField
              label={t('register.password')}
              placeholder={t('register.passwordPlaceholder')}
              value={password}
              name="password"
              type="password"
              groupField
              onChange={(e) => {
                setPassword(e);
              }}
              disabled={false}
            />
            <InputField
              label={t('register.confirmPassword')}
              placeholder={t('register.passwordPlaceholder')}
              value={confirmPassword}
              name="confirmPassword"
              type="password"
              groupField
              onChange={(e) => {
                setConfirmPassword(e);
              }}
              disabled={false}
              key={refreshConfirmPassword + 'confirmPassword'}
            />
            <div className="d-flex w-100 justify-content-center">
              <SaveBtn
                loaderOnDisabled={false}
                loader={false}
                label={t('common.confirm')}
                className={`${classes.submit} pl-5 pr-5`}
                onClick={() => {
                  formRef.current.submitForm(submitData);
                }}
              />
            </div>
          </ValidationForm>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
