import { t } from 'react-switch-lang';
import { eng, formatNumber, hasValue } from '../../../../../utils/const';
import Tooltip from 'antd/lib/tooltip';
import React from 'react';

// tooltip that shows on table entry hover
const renderColWithTooltip = (col) => {
  return (
    <Tooltip title={col} placement="topLeft" color={'#67B278'}>
      {hasValue(col) ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.agriculturalLand.id'),
    dataIndex: 'id',
    key: 'id',
    width: 100,
    ellipsis: true,
  },
  {
    title: t('pages.agriculturalLand.landCategory'),
    dataIndex: ['landCategory', eng ? 'nameEn' : 'name'],
    key: 'landCategory',
    ellipsis: true,
    render: (landCategory) => renderColWithTooltip(landCategory),
  },

  {
    title: t('pages.agriculturalLand.openingArea'),
    dataIndex: 'openingArea',
    key: 'openingArea',
    ellipsis: true,
    render: (openingArea) => renderColWithTooltip(formatNumber(openingArea)),
  },
  {
    title: t('pages.agriculturalLand.openingValue'),
    dataIndex: 'openingValue',
    key: 'openingValue',
    ellipsis: true,
    render: (openingValue) => renderColWithTooltip(formatNumber(openingValue)),
  },
  // {
  //   title: t('pages.agriculturalLand.comment'),
  //   dataIndex: 'comment',
  //   key: 'comment',
  //   ellipsis: true,
  //   render: (comment) => renderColWithTooltip(comment),
  // },
  // {
  //   title: t('pages.agriculturalLand.investmentValue'),
  //   dataIndex: 'investmentValue',
  //   key: 'investmentValue',
  //   ellipsis: true,
  //   render: (investmentValue) => renderColWithTooltip(formatNumber(investmentValue)),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.agriculturalLand.purchasedArea'),
  //   dataIndex: 'purchasedArea',
  //   key: 'purchasedArea',
  //   ellipsis: true,
  //   render: (purchasedArea) => renderColWithTooltip(formatNumber(purchasedArea)),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.agriculturalLand.purchasedValue'),
  //   dataIndex: 'purchasedValue',
  //   key: 'purchasedValue',
  //   ellipsis: true,
  //   render: (purchasedValue) => renderColWithTooltip(purchasedValue),
  // },
  // {
  //   title: t('pages.agriculturalLand.soldArea'),
  //   dataIndex: 'soldArea',
  //   key: 'soldArea',
  //   ellipsis: true,
  //   render: (soldArea) => renderColWithTooltip(soldArea),
  // },
  // {
  //   title: t('pages.agriculturalLand.soldValue'),
  //   dataIndex: 'soldValue',
  //   key: 'soldValue',
  //   ellipsis: true,
  //   render: (soldValue) => renderColWithTooltip(soldValue),
  // },
  // {
  //   title: t('pages.agriculturalLand.subsidiesValue'),
  //   dataIndex: 'subsidiesValue',
  //   key: 'subsidiesValue',
  //   ellipsis: true,
  //   render: (subsidiesValue) => renderColWithTooltip(subsidiesValue),
  // },
  {
    title: t('pages.agriculturalLand.closingArea'),
    dataIndex: 'closingArea',
    key: 'closingArea',
    ellipsis: true,
    render: (closingArea) => renderColWithTooltip(formatNumber(closingArea)),
  },
  {
    title: t('pages.agriculturalLand.closingValue'),
    dataIndex: 'closingValue',
    key: 'closingValue',
    ellipsis: true,
    render: (closingValue) => renderColWithTooltip(formatNumber(closingValue)),
  },
  // {
  //   title: t('pages.agriculturalLand.rentedArea'),
  //   dataIndex: 'rentedArea',
  //   key: 'rentedArea',
  //   ellipsis: true,
  //   render: (rentedArea) => renderColWithTooltip(rentedArea),
  // },
  // {
  //   title: t('pages.agriculturalLand.sharecroppedArea'),
  //   dataIndex: 'sharecroppedArea',
  //   key: 'sharecroppedArea',
  //   ellipsis: true,
  //   render: (sharecroppedArea) => renderColWithTooltip(sharecroppedArea),
  // },
  // {
  //   title: t('pages.agriculturalLand.usedPublicLandArea'),
  //   dataIndex: 'usedPublicLandArea',
  //   key: 'usedPublicLandArea',
  //   ellipsis: true,
  //   render: (usedPublicLandArea) => renderColWithTooltip(usedPublicLandArea),
  // },
  // {
  //   title: t('pages.agriculturalLand.usedPublicLandDays'),
  //   dataIndex: 'usedPublicLandDays',
  //   key: 'usedPublicLandDays',
  //   ellipsis: true,
  //   render: (usedPublicLandDays) => renderColWithTooltip(usedPublicLandDays),
  // },
  // {
  //   title: t('pages.agriculturalLand.rentIsPaid'),
  //   dataIndex: 'rentIsPayed',
  //   key: 'rentIsPayed',
  //   ellipsis: true,
  //   render: (rentIsPayed) => renderColWithTooltip(rentIsPayed ? t('common.yes') : t('common.no')),
  // },
];
