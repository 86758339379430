import React, { useContext } from 'react';
import AdministrationTable from '../../../components/table/AdministrationTable';
import { t } from 'react-switch-lang';
import SaveBtn from '../../../components/buttons/SaveBtn';
import { useHistory, useParams } from 'react-router-dom';
import UserData from '../../../contexts/UserData';
import { columns } from './utils/tableData';
import { onDelete } from './utils/deleteFromTable';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useShowDeleted } from '../../../contexts/ShowDeletedEntries';
import { hasValue, readOnly } from '../../../utils/const';
import AgriculturalHoldingData from '../../../contexts/AgriculturalHoldingData';

export const Labour = () => {
  let { ahid } = useParams();

  const agriculturalData = useContext(AgriculturalHoldingData);
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const history = useHistory();

  const user = useContext(UserData);

  const { showDeleted, toggleShowDeleted } = useShowDeleted();

  const onUpdate = (record) => {
    history.push({
      pathname: `/agricultural-holding/${ahid}/labour/${record.id}`,
      state: {
        add: false,
        edit: true,
      },
    });
  };

  const onRowClick = (record) => {
    history.push({
      pathname: `/agricultural-holding/${ahid}/labour/${record.id}`,
      state: {
        add: false,
        edit: false,
        isDeleted: record.isDeleted,
      },
    });
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-end mb-5">
        {user.isAdmin && (
          <SaveBtn
            label={showDeleted ? t('common.hideDeleted') : t('common.showDeleted')}
            className="mt-2 mr-3 grayText"
            onClick={() => toggleShowDeleted()}
            thin={true}
            icon={true}
            iconAntd={showDeleted ? EyeInvisibleOutlined : EyeOutlined}
          />
        )}
        {(user.isAdmin || user.isCollector) && !isLocked && !readOnly && (
          <SaveBtn
            label={t('common.add')}
            onClick={() =>
              history.push({
                pathname: `/agricultural-holding/${ahid}/labour/add`,
                state: {
                  edit: true,
                  add: true,
                },
              })
            }
          />
        )}
      </div>
      <AdministrationTable
        api="labour"
        query={`${!showDeleted ? 'isDeleted.equals=false&' : ''}agroHoldingId.equals=${ahid}`}
        columns={columns}
        methods={{ onDelete, onUpdate }}
        className={'table-hover'}
        onClickRow={onRowClick}
        user={user}
        isLocked={isLocked || readOnly}
        group={'labour'}
        historyEntity={'labour'}
      />
    </div>
  );
};
