import { axiosGet } from '../../axios';
import { eng } from '../../../utils/const';

export const getPlantProducts = (id) => {
  return axiosGet(`plant-products/${id}`);
};

export const getProductionPlantProducts = () => {
  return axiosGet('production-plant-products');
};

export const getProductionCodes = () => {
  return axiosGet('production-codes');
};

export const getProductionTypes = () => {
  return axiosGet('plant-production-types');
};

export const getIrrigationTypes = () => {
  return axiosGet('irrigation-types');
};

const defaultOption = (option) => {
  return {
    label: eng ? option.nameEn : option.name,
    value: option.id,
    disabled: false,
  };
};

export const fetchPlantProductTypes = () => {
  return axiosGet('production-plant-products').then(r => {
    let arrTemp = [];
    for (let item of r.data) {
      arrTemp.push(defaultOption(item));
    }
    return { data: r.data, filtered: arrTemp };
  }).catch(() => ({ data: [], filtered: [] }));
};
