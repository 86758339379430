import { Spinner } from 'react-bootstrap';
import React from 'react';

export const rows = 20;

export const loader = (
  <div className="text-center mt-3">
    <Spinner variant="primary" animation="border" />
  </div>
);
