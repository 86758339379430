import { t } from 'react-switch-lang';

const dataModelEntities = {
  cattleOwned: {
    entity: 'CATTLE_OWNED',
    title: () => t('pageTitle.cattleOwned'),
    backLink: '/agricultural-holding/:ahid/livestock/cattle'
  },
  cattleServiceFattening: {
    entity: 'CATTLE_SERVICE_FATTENING',
    title: () => t('pageTitle.cattleFattening'),
    backLink: '/agricultural-holding/:ahid/livestock/cattle'
  },
  goatOwned: {
    entity: 'GOAT_OWNED',
    title: () => t('pageTitle.goatOwned'),
    backLink: '/agricultural-holding/:ahid/livestock/goats'
  },
  goatServiceFattening: {
    entity: 'GOAT_SERVICE_FATTENING',
    title: () => t('pageTitle.goatFattening'),
    backLink: '/agricultural-holding/:ahid/livestock/goats'
  },
  otherOwned: {
    entity: 'OTHER_OWNED',
    title: () => t('pageTitle.otherOwned'),
    backLink: '/agricultural-holding/:ahid/livestock/other-livestock'
  },
  otherServiceFattening: {
    entity: 'OTHER_SERVICE_FATTENING',
    title: () => t('pageTitle.otherFattening'),
    backLink: '/agricultural-holding/:ahid/livestock/other-livestock'
  },
  pigOwned: {
    entity: 'PIG_OWNED',
    title: () => t('pageTitle.pigsOwned'),
    backLink: '/agricultural-holding/:ahid/livestock/pigs'
  },
  pigServiceFattening: {
    entity: 'PIG_SERVICE_FATTENING',
    title: () => t('pageTitle.pigsFattening'),
    backLink: '/agricultural-holding/:ahid/livestock/pigs'
  },
  sheepOwned: {
    entity: 'SHEEP_OWNED',
    title: () => t('pageTitle.sheepOwned'),
    backLink: '/agricultural-holding/:ahid/livestock/sheep'
  },
  sheepServiceFattening: {
    entity: 'SHEEP_SERVICE_FATTENING',
    title: () => t('pageTitle.sheepFattening'),
    backLink: '/agricultural-holding/:ahid/livestock/sheep'
  },
  poultryOwned: {
    entity: 'POULTRY_OWNED',
    title: () => t('pageTitle.poultryOwned'),
    backLink: '/agricultural-holding/:ahid/livestock/poultry'
  },
  poultryServiceFattening: {
    entity: 'POULTRY_SERVICE_FATTENING',
    title: () => t('pageTitle.poultryFattening'),
    backLink: '/agricultural-holding/:ahid/livestock/poultry'
  },
  plantProduct: {
    entity: 'PLANT_PRODUCT',
    title: () => t('pageTitle.plantProducts'),
    backLink: '/agricultural-holding/:ahid/production/plant-products'
  },
  manufacturedProduct: {
    entity: 'MANUFACTURED_PRODUCT',
    title: () => t('pageTitle.manufacturedProducts'),
    backLink: '/agricultural-holding/:ahid/production/manufactured-products'
  },
  animalProduct: {
    entity: 'ANIMAL_PRODUCT',
    title: () => t('pageTitle.animalProducts'),
    backLink: '/agricultural-holding/:ahid/production/animal-products'
  },
  otherProduct: {
    entity: 'OTHER_PRODUCT',
    title: () => t('pageTitle.otherProducts'),
    backLink: '/agricultural-holding/:ahid/production/other-products'
  },
  unfinishedProduct: {
    entity: 'UNFINISHED_PRODUCT',
    title: () => t('pageTitle.unfinishedProducts'),
    backLink: '/agricultural-holding/:ahid/production/unfinished-products'
  },
  agriculturalLand: {
    entity: 'AGRICULTURAL_LAND',
    title: () => t('pageTitle.agriculturalLands'),
    backLink: '/agricultural-holding/:ahid/long-term-assets/agricultural-lands'
  },
  permanentCrop: {
    entity: 'PERMANENT_CROP',
    title: () => t('pageTitle.permanentCrops'),
    backLink: '/agricultural-holding/:ahid/long-term-assets/permanent-crops'
  },
  farmBuilding: {
    entity: 'FARM_BUILDING',
    title: () => t('pageTitle.farmBuildings'),
    backLink: '/agricultural-holding/:ahid/long-term-assets/farm-buildings'
  },
  machineryEquipment: {
    entity: 'MACHINERY_EQUIPMENT',
    title: () => t('pageTitle.machineryAndEquipment'),
    backLink: '/agricultural-holding/:ahid/long-term-assets/machinery-and-equipment'
  },
  labour: {
    entity: 'LABOUR',
    title: () => t('pageTitle.labour'),
    backLink: '/agricultural-holding/:ahid/labour'
  },
  plantProductionExpense: {
    entity: 'PLANT_PRODUCTION_EXPENSE',
    title: () => t('pageTitle.plantProduction'),
    backLink: '/agricultural-holding/:ahid/money/expenses/plant-production-expenses'
  },
  animalProductionExpense: {
    entity: 'ANIMAL_PRODUCTION_EXPENSE',
    title: () => t('pageTitle.animalProduction'),
    backLink: '/agricultural-holding/:ahid/money/expenses/animal-production-expenses'
  },
  processingExpense: {
    entity: 'PROCESSING_EXPENSE',
    title: () => t('pageTitle.manufacturerProcessing'),
    backLink: '/agricultural-holding/:ahid/money/expenses/manufacturer-processing'
  },
  labourMachineryExpense: {
    entity: 'LABOUR_MACHINERY_EXPENSE',
    title: () => t('pageTitle.labourMachinery'),
    backLink: '/agricultural-holding/:ahid/money/expenses/labour-and-machinery'
  },
  landBuildingExpense: {
    entity: 'LAND_BUILDING_EXPENSE',
    title: () => t('pageTitle.landBuilding'),
    backLink: '/agricultural-holding/:ahid/money/expenses/land-and-building'
  },
  overheadExpense: {
    entity: 'OVERHEAD_EXPENSE',
    title: () => t('pageTitle.overhead'),
    backLink: '/agricultural-holding/:ahid/money/expenses/overhead'
  },
  insuranceExpense: {
    entity: 'INSURANCE_EXPENSE',
    title: () => t('pageTitle.insurance'),
    backLink: '/agricultural-holding/:ahid/money/expenses/insurance'
  },
  taxExpense: {
    entity: 'TAX_EXPENSE',
    title: () => t('pageTitle.tax'),
    backLink: '/agricultural-holding/:ahid/money/expenses/tax'
  },
  subsidy: {
    entity: 'SUBSIDY_GRANT',
    title: () => t('pageTitle.subsidies'),
    backLink: '/agricultural-holding/:ahid/money/subsidies'
  },
  moneyFlow: {
    entity: 'CASH_FLOW',
    title: () => t('pageTitle.moneyFlow'),
    backLink: '/agricultural-holding/:ahid/money/money-flow'
  },
  otherIncome: {
    entity: 'OTHER_INCOME',
    title: () => t('pageTitle.otherIncome'),
    backLink: '/agricultural-holding/:ahid/money/other-income'
  },
  loan: {
    entity: 'LOAN',
    title: () => t('pageTitle.loans'),
    backLink: '/agricultural-holding/:ahid/money/loans/loan'
  },
  leasing: {
    entity: 'LEASING',
    title: () => t('pageTitle.leasing'),
    backLink: '/agricultural-holding/:ahid/money/loans/leasing'
  },
  limits: {
    entity: 'LIMIT',
    title: () => t('pageTitle.limits'),
    backLink: '/administration/limits'
  },
  AGRO_HOLDINNG: {
    entity: 'AGRO_HOLDINNG',
    title: () => '',
    backLink: ''
  },
  AH_ORGANIC_SECTOR: {
    entity: 'AH_ORGANIC_SECTOR',
    title: () => '',
    backLink: ''
  },
  AH_PROTECTED_PRODUCT: {
    entity: 'AH_PROTECTED_PRODUCT',
    title: () => '',
    backLink: ''
  },
  AH_USER: {
    entity: 'AH_USER',
    title: () => '',
    backLink: ''
  },
  ERROR_EXPLANATION: {
    entity: 'ERROR_EXPLANATION',
    title: () => '',
    backLink: ''
  },
  ERROR_EXPLANATION_MESSAGE: {
    entity: 'ERROR_EXPLANATION_MESSAGE',
    title: () => '',
    backLink: ''
  },
}

export default dataModelEntities;