import React, { useRef, useState } from 'react';
import ValidationForm from '../../../../components/form/validationForm/ValidationForm';
import InputField from '../../../../components/form/inputField/InputField';
import SaveBtn from '../../../../components/buttons/SaveBtn';
import { axiosPost } from '../../../../services/axios';
import classes from '../ContactUs.module.scss';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../components/form/textareaField/TextareaField';
import ModalWrapper from '../../../../components/modal/modalWrapper/ModalWrapper';
import Toast from '../../../../components/toast/Toast';
import { eng } from '../../../../utils/const';

const ContactUsAuth = ({ isModalOpen, setIsModalOpen }) => {

  const formRef = useRef();
  const [mailData, setMailData] = useState({});

  const validation = {
    title: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 30 } },
    message: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 200 } },
  };

  const submitData = async () => {
    const payload = {
      ...mailData,
      langKey: eng ? 'en' : 'sr',
    }

    try {
      const response = await axiosPost('contact/user', payload);
      if (response.status === 200) {
        // makes a popup that prompts user to go back to login page
        Toast.fire({
          title: t('contactUs.success'),
          icon: 'success',
        });
        setIsModalOpen(false);
      }
    } catch (err) {
      Toast.fire({
        title: t('contactUs.error'),
        icon: 'error',
      });
    }
  };

  return (
    <ModalWrapper onClose={() => setIsModalOpen(false)} isModalOpen={isModalOpen} header={<span className={`${classes['title']}`}>{t('contactUs.contactUsTitle')}</span>}>
      <div className='d-flex flex-column'>
        <div className='w-100 mt-3'>
          <div className='d-flex w-100 justify-content-center'>
            <div style={{ width: '100%' }}>
              <ValidationForm validation={validation} ref={formRef}>
                <div className='d-flex flex-column'>
                  <InputField
                    label={t('contactUs.title')}
                    placeholder={t('contactUs.titlePlaceholder')}
                    value={mailData?.title}
                    name='title'
                    groupField
                    onChange={(e) => {
                      setMailData(prevState => ({
                        ...prevState,
                        title: e,
                      }));
                    }}
                    disabled={false}
                  />
                  <TextareaField
                    label={t('contactUs.message')}
                    placeholder={t('contactUs.messagePlaceholder')}
                    value={mailData?.message}
                    name='message'
                    groupField
                    onChange={(e) => {
                      setMailData(prevState => ({
                        ...prevState,
                        message: e,
                      }));
                    }}
                    disabled={false}
                  />
                </div>
                <div className='d-flex w-100 justify-content-end mt-2'>
                  <SaveBtn
                    loaderOnDisabled={false}
                    loader={false}
                    label={t('contactUs.enter')}
                    className='pl-5 pr-5'
                    onClick={() => {
                      formRef.current.submitForm(submitData);
                    }}
                  />
                </div>
              </ValidationForm>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ContactUsAuth;