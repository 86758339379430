import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { t } from 'react-switch-lang';
import { Pigs } from './Pigs';
import { PigsFattening } from './PigsFattening';
import { useHistory } from 'react-router-dom';

const PigsFormContainer = (props) => {

  const { TabPane } = Tabs;

  const [defaultTab, setDefaultTab] = useState(1);

  const history = useHistory();

  useEffect(() => {
    setDefaultTab(localStorage.getItem('pigsFattening'));
  }, []);

  useEffect(() => history.listen(() => {
    if (!history?.location?.pathname.includes('pigs')) {
      localStorage.setItem('pigsFattening', '1');
    }
  }), []);

  function callback(key) {
    setDefaultTab(key);
    localStorage.setItem('pigsFattening', key);
  }
  return (
    <Tabs defaultActiveKey={defaultTab} key={defaultTab} onChange={callback}>
      <TabPane tab={ t('pages.pig.owned') } key="1">
        <Pigs />
      </TabPane>
      <TabPane tab={ t('pages.pig.fattening') } key="2">
        <PigsFattening />
      </TabPane>
    </Tabs>
  )
};

export default PigsFormContainer;