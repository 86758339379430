import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const LoanCategory = () => {
  return (
    <div>
      <StandardTable api={'loan-categories'} title={t('administration.enums.title.loanCategory')} />

    </div>
  );
};

export default LoanCategory;
