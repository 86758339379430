import React, { useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../../components/form/validationForm/ValidationForm';
import InputField from '../../../../components/form/inputField/InputField';
import SaveBtn from '../../../../components/buttons/SaveBtn';
import CancelBtn from '../../../../components/buttons/CancelBtn';
import { axiosPostFile, axiosPutFile } from '../../../../services/axios';
import Toast from '../../../../components/toast/Toast';
import { t } from 'react-switch-lang';
import FilePicker from '../../../../components/form/filePicker/FilePicker';
import { ProgressBar } from 'react-bootstrap';
import { whitelist } from '../utils/whitelist';

const DocumentsForm = ({ data, type, updateCards, closeModal, isDisabled }) => {
  const formRef = useRef();
  const initialData = {
    name: '',
    description: '',
  };

  const [changedData, setChangedData] = useState(initialData);

  const [validation, setValidation] = useState({
    name: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 255 } },
    description: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 255 } },
    file: { type: 'string', required: { value: type === 'add' }, error: { value: false } },
  });

  const [fileName, setFileName] = useState('');
  const [fileValue, setFileValue] = useState('');
  const [progress, setProgress] = useState();
  const [disabled, setDisabled] = useState(false);

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = new FormData();
      payload.append('name', changedData.name);
      payload.append('description', changedData.description);
      payload.append('file', changedData.file);

      setDisabled(true);

      try {
        const response = await axiosPostFile('documents', payload, setProgress);
        if (response.status === 201) {
          updateCards();
          Toast.fire({
            title: t('common.successAdd', { entity: t('messages.document') }) + '!',
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
        Toast.fire({
          title: t('pages.documents.file.uploadError'),
          icon: 'error',
          iconColor: '#f27474'
        });
        cancel();
      }
    } else if (type === 'edit') {
      const payload = new FormData();
      payload.append('id', data.id);
      payload.append('name', changedData.name);
      payload.append('description', changedData.description);
      changedData?.file && payload.append('file', changedData.file);

      setDisabled(true);

      try {
        const response = await axiosPutFile('documents', payload, data.id, changedData?.file && setProgress);
        if (response.status === 200) {
          updateCards();
          Toast.fire({
            title: t('common.successEdit', { entity: t('messages.document') }) + '!',
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
        Toast.fire({
          title: t('pages.documents.file.uploadError'),
          icon: 'error',
          iconColor: '#f27474'
        });
        cancel();
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        name: data.name,
        description: data.description,
      });

      setFileName(data.name + '.' + data.url.split('.')[1]);
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <InputField
          label={t('pages.documents.columns.name')}
          placeholder={t('pages.documents.placeholders.enterName')}
          value={changedData.name}
          name='name'
          groupField
          onChange={(e) => {
            setChangedData({
              ...changedData,
              name: e,
            });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('pages.documents.columns.description')}
          placeholder={t('pages.documents.placeholders.enterDescription')}
          value={changedData.description}
          name='description'
          groupField
          onChange={(e) => {
            setChangedData({
              ...changedData,
              description: e,
            });
          }}
          disabled={isDisabled}
        />
        <FilePicker
          value={fileValue}
          defaultValue={changedData.file}
          defaultFileName={fileName}
          name='file'
          groupField
          onChange={(e, value) => {
            // limits file size to 150MB
            setValidation(prevState => ({
              ...prevState,
              file: { ...prevState?.file,
                error: e?.size > 157286400 ?
                  { value: true, messages: t('pages.documents.file.fileTooLarge') } : { value: false },
              },
            }));

            setChangedData({
              ...changedData,
              file: e,
            });
            setFileValue(value);
          }}
          whitelist={whitelist}
        />
      </ValidationForm>
      {progress && <ProgressBar animated variant='success' now={progress} label={`${progress}%`} />}
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            disabled={disabled}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn
            className='button cancel'
            loaderOnDisabled={true}
            disabled={disabled}
            onClick={(e) => cancel(e)} />
        </div>
      )}
    </>
  );
};

export default DocumentsForm;
