import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from 'react-switch-lang'

const UserType = () => {
  return (
    <div>
      <StandardTable api={'user-types'} title={t('administration.enums.title.userType')} />
    </div>
  );
};

export default UserType;
