import React from 'react';
import LivestockTableEnums from "../../../../../components/administration/enums/livestockEnums/LivestockTableEnums";
import {t} from "react-switch-lang";

const PoultryCategory = () => {
  return (
    <div>
      <LivestockTableEnums api={'poultry-categories'} title={t('administration.enums.title.poultryCategory')}/>
    </div>
  );
};

export default PoultryCategory;
