import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';
import { formatNumber, hasValue } from '../../../../../utils/const';
import React from 'react';

const renderColWithTooltip = (col) => {
  return <Tooltip title={col} placement="topLeft" color={'#67B278'}
                  overlayInnerStyle={{ borderRadius: '5px' }}>{hasValue(col) ? col : '/'}</Tooltip>;
};

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: id => renderColWithTooltip(id),
    width: 50,
  },
  {
    title: t('administration.enums.columns.name'),
    dataIndex: ['name'],
    key: 'name',
    ellipsis: true,
    render: id => renderColWithTooltip(id),

  },
  {
    title: t('administration.enums.columns.nameEn'),
    dataIndex: ['nameEn'],
    key: 'nameEn',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.soCode'),
    dataIndex: ['soCode'],
    key: 'soCode',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.sortVal'),
    dataIndex: 'sortVal',
    key: 'sortVal',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.soUnit'),
    dataIndex: 'soUnit',
    key: 'soUnit',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.frCategory'),
    dataIndex: 'frCategory',
    key: 'frCategory',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.frLuGrazingDayMultiplier'),
    dataIndex: 'frLuGrazingDayMultiplier',
    key: 'frLuGrazingDayMultiplier',
    ellipsis: true,
    render: id => renderColWithTooltip(formatNumber(id))
  },
    {
    title: t('administration.enums.columns.luGrazingDayMultiplier'),
    dataIndex: 'luGrazingDayMultiplier',
    key: 'luGrazingDayMultiplier',
    ellipsis: true,
    render: id => renderColWithTooltip(formatNumber(id))
  },

];
