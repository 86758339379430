import React, { useContext, useState } from 'react';
import UserData from '../../../../contexts/UserData';
import Prompt from '../../../../components/prompt/Prompt';
import { t } from 'react-switch-lang';
import { axiosDelete } from '../../../../services/axios';
import Toast from '../../../../components/toast/Toast';
import Swal from 'sweetalert2';
import SaveBtn from '../../../../components/buttons/SaveBtn';
import ModalWrapper from '../../../../components/modal/modalWrapper/ModalWrapper';
import AdministrationTable from '../../../../components/table/AdministrationTable';
import { columns } from './utils/table/weightingsColumns';
import WeightingsForm from './weightingsForm/WeightingsForm';
import { readOnly } from '../../../../utils/const';

const Weightings = () => {
  const user = useContext(UserData);

  const [updateTable, setUpdateTable] = useState(false);

  //modals
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalInfo, setModalInfo] = useState({
    dataDisabled: false,
    changeType: '',
  });

  const onModalOpen = (data, disabled, type) => {
    setModalInfo({ dataDisabled: disabled, changeType: type });
    setModalData(data);
    setIsModalOpen(true);
  };

  const onModalExit = () => {
    setIsModalOpen(false);
    if (updateTable) {
      setUpdateTable(false);
    }
  };

  const onDelete = async (record) => {
    return new Promise((resolve, reject) => {
      Prompt.fire({
        title: t('administration.weightings.actions.deleteWeighting'),
        text: t('administration.weightings.messages.deleteQuestion') + record.id + '?',
        confirmButtonText: t('lang.common.delete'),
        cancelButtonText: t('lang.common.cancel'),
        preConfirm: async () => {
          try {
            const response = await axiosDelete('weights', record.id);
            if (response.status === 204) {
              Toast.fire({
                title: t('administration.weightings.messages.successDelete'),
                icon: 'success',
              });
              resolve(response);
              setUpdateTable(true);
            } else {
              reject(response);
            }
          } catch (err) {
            Swal.fire({
              text: t('common.apiErrorDelete'),
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        },
      });
    });
  };

  const onUpdate = (record) => {
    onModalOpen(record, false, 'edit');
  };

  const onRowClick = (record) => {
    onModalOpen(record, true, 'show');
  };

  const onUpdateTable = () => {
    setUpdateTable(true);
  };

  return (
    user.isAdmin && (
      <div className="w-100 h-100">
        <div className="d-flex justify-content-end">
          {!readOnly &&
          <SaveBtn
            label={t('common.add')}
            className="mb-5"
            onClick={() => onModalOpen({}, false, 'add')}
          />
          }
        </div>
        <ModalWrapper
          header={
            modalInfo.changeType === 'add'
              ? t('administration.weightings.actions.addNewWeighting')
              : modalInfo.changeType === 'edit'
              ? t('administration.weightings.actions.editWeighting')
              : t('administration.weightings.actions.showWeighting')
          }
          isModalOpen={isModalOpen}
          /* eslint-disable-next-line react/no-children-prop */
          children={
            <WeightingsForm
              data={modalData}
              type={modalInfo.changeType}
              updateTable={onUpdateTable}
              closeModal={onModalExit}
              isDisabled={modalInfo.dataDisabled}
            />
          }
          onClose={() => onModalExit()}
        />
        <AdministrationTable
          api={'weights'}
          columns={columns}
          methods={{ onDelete, onUpdate }}
          onClickRow={onRowClick}
          key={updateTable}
          className={'table-hover'}
          user={user}
          isLocked={readOnly}
        />
      </div>
    )
  );
};

export default Weightings;
