import React, { useContext, useState } from 'react';
import Chat from '../../../../../../assets/images/icons/explain.svg';
import Edit from '../../../../../../assets/images/dashboard/edit.svg';
import Delete from '../../../../../../assets/images/dashboard/delete.svg';
import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';
import { hasValue } from '../../../../../../utils/const';
import LockingErrorsExplanationModal from './LockingErrorsExplanationModal';
import { deleteErrorExplanation } from '../../../../../../services/errroExplanations/errorExplanationsApi';
import { Toast } from '../../../../../../utils/sweetAlert';
import LockingExplanation from '../../../../../../contexts/LockingExplanation';

const LockingErrorExplanation = ({ row }) => {

  const renderColWithTooltip = (col) => {
    return <Tooltip title={col} placement="topLeft" color={'#67B278'}
                    overlayInnerStyle={{ borderRadius: '5px' }}>{hasValue(col) ? col : '/'}</Tooltip>;
  };

  const [isOpen, setIsOpen] = useState(false);

  const lockingExplanation = useContext(LockingExplanation);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const openExplainModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = () => {
  };

  const deleteExplanationError = async () => {
    lockingExplanation.setPreLockExplanation('reset');
    const response = await deleteErrorExplanation(row?.relatedExplanation?.id);
    if (response.status === 204) {
      Toast.fire({
        title: t('administration.lockingErrors.messages.explanationDeleteSuccess'),
        icon: 'success',
      });
      lockingExplanation.setPreLockExplanation('refresh');
    } else {
      Toast.fire({
        title: t('administration.lockingErrors.messages.explanationDeleteError'),
        icon: 'error',
      });
    }
  };

  return (
    <div className='d-flex' key={row.field + row.dvcNumber}>
      {row?.relatedExplanation?.explanation ?
        <div className='d-flex w-100 justify-content-between'>
          <div className='mr-1 w-75'>
            {renderColWithTooltip(row?.relatedExplanation?.explanation)}
          </div>
          <div className='d-flex flex-column justify-content-between'>
            <img src={Edit} alt={'Edit'} className='mb-2' onClick={() => openExplainModal()}/>
            <img src={Delete} alt={'Delete'} onClick={() => deleteExplanationError()}/>
          </div>
        </div>
        :
        <div className='insertExplanation d-flex justify-content-center align-items-center'
             onClick={() => openExplainModal()}>
          <img src={Chat} alt={'Chat'} className='mr-1' />
          {t('common.insertExplanation')}
        </div>
      }
      <LockingErrorsExplanationModal row={row} isOpen={isOpen} toggle={toggle}/>
    </div>
  );
};

export default LockingErrorExplanation;