import React, { useContext, useEffect, useState } from 'react';
import { CardsGrid } from '../../../../components/cardsGrid/CardsGrid';
import { axiosGetMulti } from '../../../../services/axios';
import Swal from 'sweetalert2';
import { t } from 'react-switch-lang';
import { useHistory, useParams } from "react-router-dom";
import PremiumImg from '../../../../assets/images/money/premiums.svg';
import SubsideImg from '../../../../assets/images/money/Subsides.svg';

export const Grants = () => {
  let { ahid } = useParams();
  const history = useHistory();

  const [grantsTotal, setGrantsTotal] = useState({
    subsidies: 0,
    premiums: 0,
  });

  useEffect(() => {
    axiosGetMulti([
      `subsidy-grants/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      // `premium-grants/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
    ])
      .then((r) => {
        setGrantsTotal({
          subsidies: r[0].data,
          // premiums: r[1].data,
        });
      })
      .catch((r) => {
        Swal.fire({
          text: t('common.error'),
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
  }, []);

  const list = [
    {
      title: t('pageTitle.subsidies'),
      image: SubsideImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: grantsTotal.subsidies,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/grants/subsidies`),
    },
    // {
    //   title: t('pageTitle.premiums'),
    //   image: PremiumImg,
    //   explanation: {
    //     title: t('common.numberOfExpenses'),
    //     value: grantsTotal.premiums,
    //   },
    //   onClick: () =>
    //     history.push(`/agricultural-holding/${ahid}/money/grants/premiums`),
    // },
  ];

  return (
    <div className="d-flex">
      <CardsGrid list={list} />
    </div>
  );
};
