import { Tooltip } from 'antd';
import { t } from 'react-switch-lang';
import { hasValue } from '../../../../utils/const';
import React from 'react';
import { makeLink } from './makeLink';
import dataModels from './limitsData';

const renderColWithTooltip = (col) => {
  return <Tooltip title={col} placement="topLeft" color={'#67B278'}
                  overlayInnerStyle={{ borderRadius: '5px' }}>{hasValue(col) ? col : '/'}</Tooltip>;
};

const renderTableName = (dataModel) => {
  const model = dataModels.find(item => item.value === dataModel);
  return model?.label ? model?.label() : dataModel ? dataModel : '/';
};

const renderFieldName = (dataModel, fieldName) => {
  const model = dataModels.find(item => item.value === dataModel);
  const field = model?.fields ? model.fields.find(item => item.value === fieldName) : null;
  return field?.label ? field?.label() : fieldName ? fieldName : '/';
};

export const columns = [
  {
    title: t('administration.explainableControls.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: id => renderColWithTooltip(id),
  },
  {
    title: t('administration.explainableControls.agroHoldingFadnId'),
    dataIndex: 'agroHoldingFadnId',
    key: 'agroHoldingFadnId',
    ellipsis: true,
    width: 120,
    render: agroHoldingFadnId => renderColWithTooltip(agroHoldingFadnId),
  },
  {
    title: t('administration.explainableControls.dataModel'),
    dataIndex: 'dataModel',
    key: 'dataModel',
    ellipsis: true,
    render: dataModel => renderColWithTooltip(renderTableName(dataModel)),
  },
  // {
  //   title: t('administration.explainableControls.dataModel'),
  //   dataIndex: ['dataModel', 'field'],
  //   key: 'dataModel',
  //   ellipsis: true,
  //   render: row => renderColWithTooltip((t('pages' + '.' + row?.dataModel + '.' + row?.field))),
  //   responsive: ['xs', 'sm']
  // },
  {
    title: t('administration.explainableControls.field'),
    // dataIndex: 'field',
    key: 'field',
    ellipsis: false,
    render: row => <a target={'_blank'} href={makeLink(row)}
                      className={'tableLink'}>{renderColWithTooltip(renderFieldName(row?.dataModel, row?.field))}</a>,
  },
  {
    title: t('administration.explainableControls.dvcNumber'),
    dataIndex: 'dvcNumber',
    key: 'dvcNumber',
    ellipsis: true,
    render: dvcNumber => renderColWithTooltip(dvcNumber),
  },
  {
    title: t('administration.explainableControls.dateAndTime'),
    dataIndex: ['errorExplanationMessages', 'createdDate'],
    key: 'dateAndTime',
    ellipsis: true,
    render: dateAndTime => renderColWithTooltip(dateAndTime),
  },
  // {
  //   title: t('administration.explainableControls.isApproved'),
  //   dataIndex: 'isApproved',
  //   key: 'isApproved',
  //   ellipsis: true,
  //   render: isApproved => isApproved ? t('common.yes').toUpperCase() : t('common.no').toUpperCase(),
  //   responsive: ['xs', 'sm']
  // },
  {
    title: t('administration.explainableControls.explanation'),
    dataIndex: 'explanation',
    key: 'explanation',
    ellipsis: false,
    render: explanation => renderColWithTooltip(explanation),
  }
];