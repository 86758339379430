import { t } from 'react-switch-lang';
import { eng, formatNumber } from '../../../../../../utils/const';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
  },
  {
    title: t('administration.weightings.columns.region'),
    dataIndex: ['region', eng ? 'nameEn' : 'name'],
    key: 'region',
    ellipsis: true,
  },
  {
    title: t('administration.weightings.columns.farmType'),
    dataIndex: ['farmType', eng ? 'nameEn' : 'name'],
    key: 'farmType',
    ellipsis: true,
  },
  {
    title: t('administration.weightings.columns.economicSize'),
    dataIndex: ['economicSize', eng ? 'nameEn' : 'name'],
    key: 'economicSize',
    ellipsis: true,
  },
  {
    title: t('administration.weightings.columns.population'),
    dataIndex: 'population',
    key: 'population',
    ellipsis: true,
    render: (data) => formatNumber(data),
  },
  {
    title: t('administration.weightings.columns.weight'),
    dataIndex: 'weight',
    key: 'weight',
    ellipsis: true,
    render: (data) => formatNumber(data),
  },
];
