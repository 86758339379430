import { t } from 'react-switch-lang';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    responsive: [],
  },
  {
    title: t('administration.users.columns.username'),
    dataIndex: 'login',
    key: 'login',
    ellipsis: true,
  },
  {
    title: t('administration.users.columns.firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
  },
  {
    title: t('administration.users.columns.lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    ellipsis: true,
  },
  {
    title: t('administration.users.columns.email'),
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: t('administration.users.columns.role'),
    dataIndex: 'authorities',
    key: 'role',
    ellipsis: true,
    render: (role) =>
      role[0] === 'ROLE_ADMIN'
        ? t('common.admin')
        : role[0] === 'ROLE_COLLECTOR'
        ? t('common.collector')
        : role[0] === 'ROLE_VIEWER'
        ? t('common.viewer')
        : t('common.unassigned'),
  },
  {
    title: t('administration.users.columns.activated'),
    dataIndex: 'userActive',
    key: 'userActive',
    ellipsis: true,
    render: (userActive) => (userActive ? t('common.yes') : t('common.no')),
  },
];
