import React from 'react';
import classes from './Status.module.scss';
import PropTypes from 'prop-types';

const Status = ({ completed, label }) => {
  return (
    <div
      className={`transition ${classes['status']} ${
        completed ? classes['status--complete'] : classes['status--on_hold']
      }`}
    >
      <span>{label}</span>
    </div>
  );
};

export default Status;

Status.propTypes = {
  completed: PropTypes.bool,
  label: PropTypes.string,
};
