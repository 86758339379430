import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { t } from 'react-switch-lang';

export const LivestockExtra = ({ closeSidebar }) => {
  let { ahid } = useParams();

  const items = [
    {
      name: 'cattle',
      link: `/agricultural-holding/${ahid}/livestock/cattle`,
      text: t('sidebarExtra.livestock.cattle'),
      tooltip: t('sidebarExtra.livestock.cattle'),
    },
    {
      name: 'pigs',
      link: `/agricultural-holding/${ahid}/livestock/pigs`,
      text: t('sidebarExtra.livestock.pigs'),
      tooltip: t('sidebarExtra.livestock.pigs'),
    },
    {
      name: 'sheep',
      link: `/agricultural-holding/${ahid}/livestock/sheep`,
      text: t('sidebarExtra.livestock.sheep'),
      tooltip: t('sidebarExtra.livestock.sheep'),
    },
    {
      name: 'goat',
      link: `/agricultural-holding/${ahid}/livestock/goats`,
      text: t('sidebarExtra.livestock.goats'),
      tooltip: t('sidebarExtra.livestock.goats'),
    },
    {
      name: 'poultry',
      link: `/agricultural-holding/${ahid}/livestock/poultry`,
      text: t('sidebarExtra.livestock.poultry'),
      tooltip: t('sidebarExtra.livestock.poultry'),
    },
    {
      name: 'other',
      link: `/agricultural-holding/${ahid}/livestock/other-livestock`,
      text: t('sidebarExtra.livestock.other'),
      tooltip: t('sidebarExtra.livestock.other'),
    },
  ];

  const query = window.location.pathname;

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div
            className={`system-link links ${query === item.link ? 'active' : ''}`}
            key={index}
            onClick={() => closeSidebar()}
          >
            <Link to={item.link}>{item.text}</Link>
          </div>
        );
      })}
    </div>
  );
};
