import { t } from 'react-switch-lang';
import cattleOwnedFields from './formFieldsLists/livestock/cattle/cattleBasic';
import cattleFatteningFields from './formFieldsLists/livestock/cattle/cattleFattening';
import goatsBasicFields from './formFieldsLists/livestock/goats/goatsBasic';
import goatsFatteningFields from './formFieldsLists/livestock/goats/goatsFattening';
import otherLivestockBasicFields from './formFieldsLists/livestock/otherLivestock/otherLivestockBasic';
import otherLivestockFatteningFields from './formFieldsLists/livestock/otherLivestock/otherLivestockFattening';
import pigsBasicFields from './formFieldsLists/livestock/pigs/pigsBasic';
import pigsFatteningFields from './formFieldsLists/livestock/pigs/pigsFattening';
import sheepBasicFields from './formFieldsLists/livestock/sheep/sheepBasic';
import sheepFatteningFields from './formFieldsLists/livestock/sheep/sheepFattening';
import poultryBasicFields from './formFieldsLists/livestock/poultry/poultryBasic';
import poultryFatteningFields from './formFieldsLists/livestock/poultry/poultryFattening';
import plantProductsFields from './formFieldsLists/production/plantProducts';
import manufacturedProductsFields from './formFieldsLists/production/manufacturedProducts';
import animalProductsFields from './formFieldsLists/production/animalProducts';
import otherProductsFields from './formFieldsLists/production/otherProducts';
import unfinishedProductsFields from './formFieldsLists/production/unfinishedProducts';
import agriculturalLandFields from './formFieldsLists/longTermAssets/agriculturalLand';
import permanentCropsFields from './formFieldsLists/longTermAssets/permanentCrops';
import farmBuildingsFields from './formFieldsLists/longTermAssets/farmBuildings';
import machineryFields from './formFieldsLists/longTermAssets/machinery';
import labourFields from './formFieldsLists/labour';
import moneyPlantProductionFields from './formFieldsLists/money/expenses/moneyPlantProduction';
import moneyAnimalProductionFields from './formFieldsLists/money/expenses/moneyAnimalProduction';
import moneyProcessingFields from './formFieldsLists/money/expenses/moneyProcessing';
import moneyLabourFields from './formFieldsLists/money/expenses/moneyLabour';
import moneyLandFields from './formFieldsLists/money/expenses/moneyLand';
import moneyOverheadFields from './formFieldsLists/money/expenses/moneyOverhead';
import moneyInsuranceFields from './formFieldsLists/money/expenses/moneyInsurance';
import moneyTaxFields from './formFieldsLists/money/expenses/moneyTax';
import subsidiesFields from './formFieldsLists/money/subsidies';
import moneyFlowFields from './formFieldsLists/money/moneyFlow';
import otherIncomeFields from './formFieldsLists/money/otherIncome';
import loansFields from './formFieldsLists/money/loans/moneyLoans';
import leasingFields from './formFieldsLists/money/loans/moneyLeasing';
import agriculturalHoldingsFields from './formFieldsLists/administration/agriculturalHoldings';

const dataModels = [
  //AgriculturalHoldingForm
  {
    value: 'agroHolding', //dataModel
    label: () => t('pageTitle.agriculturalHoldings'),
    displayInLimits: false,
    isDataCollection: true,
    fields: agriculturalHoldingsFields,
  },
  {
    //CattleBasicForm
    value: 'cattleOwned', //dataModel
    label: () => t('pageTitle.cattleOwned'),
    displayInLimits: true,
    isDataCollection: true,
    fields: cattleOwnedFields,
  },
  {
    //CattleFatteningForm
    value: 'cattleServiceFattening',
    label: () => t('pageTitle.cattleFattening'),
    displayInLimits: true,
    isDataCollection: true,
    fields: cattleFatteningFields,
  },
  {
    //GoatBasicForm
    value: 'goatOwned',
    label: () => t('pageTitle.goatOwned'),
    displayInLimits: true,
    isDataCollection: true,
    fields: goatsBasicFields,
  },
  {
    //GoatFatteningForm
    value: 'goatServiceFattening',
    label: () => t('pageTitle.goatFattening'),
    displayInLimits: true,
    isDataCollection: true,
    fields: goatsFatteningFields,
  },
  {
    //OtherLivestockBasicForm
    value: 'otherOwned',
    label: () => t('pageTitle.otherOwned'),
    displayInLimits: true,
    isDataCollection: true,
    fields: otherLivestockBasicFields,
  },
  {
    //OtherLivestockFatteningForm
    value: 'otherServiceFattening',
    label: () => t('pageTitle.otherFattening'),
    displayInLimits: true,
    isDataCollection: true,
    fields: otherLivestockFatteningFields,
  },
  {
    //PigsBasicForm
    value: 'pigOwned',
    label: () => t('pageTitle.pigsOwned'),
    displayInLimits: true,
    isDataCollection: true,
    fields: pigsBasicFields,
  },
  {
    //PigsFatteningForm
    value: 'pigServiceFattening',
    label: () => t('pageTitle.pigsFattening'),
    displayInLimits: true,
    isDataCollection: true,
    fields: pigsFatteningFields,
  },
  {
    //SheepBasicForm
    value: 'sheepOwned',
    label: () => t('pageTitle.sheepOwned'),
    displayInLimits: true,
    isDataCollection: true,
    fields: sheepBasicFields,
  },
  {
    //SheepFatteningForm
    value: 'sheepServiceFattening',
    label: () => t('pageTitle.sheepFattening'),
    displayInLimits: true,
    isDataCollection: true,
    fields: sheepFatteningFields,
  },
  {
    //PoultryBasicForm
    value: 'poultryOwned',
    label: () => t('pageTitle.poultryOwned'),
    displayInLimits: true,
    isDataCollection: true,
    fields: poultryBasicFields,
  },
  {
    //PoultryFatteningForm
    value: 'poultryServiceFattening',
    label: () => t('pageTitle.poultryFattening'),
    displayInLimits: true,
    isDataCollection: true,
    fields: poultryFatteningFields,
  },
  {
    //PlantProductsForm
    value: 'plantProduct',
    label: () => t('pageTitle.plantProducts'),
    displayInLimits: true,
    isDataCollection: true,
    fields: plantProductsFields,
  },
  {
    //ManufacturedProductsForm
    value: 'manufacturedProduct',
    label: () => t('pageTitle.manufacturedProducts'),
    displayInLimits: true,
    isDataCollection: true,
    fields: manufacturedProductsFields,
  },
  {
    //AnimalProductsForm
    value: 'animalProduct',
    label: () => t('pageTitle.animalProducts'),
    displayInLimits: true,
    isDataCollection: true,
    fields: animalProductsFields,
  },
  {
    //OtherProductsForm
    value: 'otherProduct',
    label: () => t('pageTitle.otherProducts'),
    displayInLimits: true,
    isDataCollection: true,
    fields: otherProductsFields,
  },
  {
    //UnfinishedProductsForm
    value: 'unfinishedProduct',
    label: () => t('pageTitle.unfinishedProducts'),
    displayInLimits: true,
    isDataCollection: true,
    fields: unfinishedProductsFields,
  },
  {
    //AgriculturalLandForm
    value: 'agriculturalLand',
    label: () => t('pageTitle.agriculturalLands'),
    displayInLimits: true,
    isDataCollection: false,
    fields: agriculturalLandFields,
  },
  {
    //PermanentCropsForm
    value: 'permanentCrop',
    label: () => t('pageTitle.permanentCrops'),
    displayInLimits: true,
    isDataCollection: false,
    fields: permanentCropsFields,
  },
  {
    //FarmBuildingsForm
    value: 'farmBuilding',
    label: () => t('pageTitle.farmBuildings'),
    displayInLimits: true,
    isDataCollection: false,
    fields: farmBuildingsFields,
  },
  {
    //MachineryEquipmentForm
    value: 'machineryEquipment',
    label: () => t('pageTitle.machineryAndEquipment'),
    displayInLimits: true,
    isDataCollection: false,
    fields: machineryFields,
  },
  {
    //LabourForm
    value: 'labour',
    label: () => t('pageTitle.labour'),
    displayInLimits: true,
    isDataCollection: false,
    fields: labourFields,
  },
  {
    //PlantProductionExpensesForm
    value: 'plantProductionExpense',
    label: () => t('pageTitle.plantProduction'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyPlantProductionFields,
  },
  {
    //AnimalExpensesForm
    value: 'animalProductionExpense',
    label: () => t('pageTitle.animalProduction'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyAnimalProductionFields,
  },
  {
    //ProcessingExpenseForm
    value: 'processingExpense',
    label: () => t('pageTitle.manufacturerProcessing'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyProcessingFields,
  },
  {
    //LabourExpenseForm
    value: 'labourMachineryExpense',
    label: () => t('pageTitle.labourMachinery'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyLabourFields,
  },
  {
    //LandAndBuildingForm
    value: 'landBuildingExpense',
    label: () => t('pageTitle.landBuilding'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyLandFields,
  },
  {
    //OverheadExpenseForm
    value: 'overheadExpense',
    label: () => t('pageTitle.overhead'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyOverheadFields,
  },
  {
    //InsuranceExpensesForm
    value: 'insuranceExpense',
    label: () => t('pageTitle.insurance'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyInsuranceFields,
  },
  {
    //TaxForm
    value: 'taxExpense',
    label: () => t('pageTitle.tax'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyTaxFields,
  },
  {
    //SubsidyForm
    value: 'subsidy',
    label: () => t('pageTitle.subsidies'),
    displayInLimits: true,
    isDataCollection: false,
    fields: subsidiesFields,
  },
  {
    //MoneyFlowForm
    value: 'moneyFlow',
    label: () => t('pageTitle.moneyFlow'),
    displayInLimits: true,
    isDataCollection: false,
    fields: moneyFlowFields,
  },
  {
    //OtherIncomeForm
    value: 'otherIncome',
    label: () => t('pageTitle.otherIncome'),
    displayInLimits: true,
    isDataCollection: false,
    fields: otherIncomeFields,
  },
  {
    //LoanForm
    value: 'loan',
    label: () => t('pageTitle.loans'),
    displayInLimits: true,
    isDataCollection: false,
    fields: loansFields,
  },
  {
    //LeasingForm
    value: 'leasing',
    label: () => t('pageTitle.leasing'),
    displayInLimits: true,
    isDataCollection: false,
    fields: leasingFields,
  },
];


export default dataModels;