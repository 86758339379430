import { t } from 'react-switch-lang';

export const eng = localStorage.getItem('language') === 'eng';

const agriculturalLandFields = [
  {
    value: 'landCategory',
    label: () => t('pages.agriculturalLand.landCategory'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'land-categories',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'openingArea',
    label: () => t('pages.agriculturalLand.openingArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.agriculturalLand.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'investmentValue',
    label: () => t('pages.agriculturalLand.investmentValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchasedArea',
    label: () => t('pages.agriculturalLand.purchasedArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchasedValue',
    label: () => t('pages.agriculturalLand.purchasedValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldArea',
    label: () => t('pages.agriculturalLand.soldArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldValue',
    label: () => t('pages.agriculturalLand.soldValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'subsidiesValue',
    label: () => t('pages.agriculturalLand.subsidiesValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'rentedArea',
    label: () => t('pages.agriculturalLand.rentedArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'sharecroppedArea',
    label: () => t('pages.agriculturalLand.sharecroppedArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'usedPublicLandArea',
    label: () => t('pages.agriculturalLand.usedPublicLandArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'usedPublicLandDays',
    label: () => t('pages.agriculturalLand.usedPublicLandDays'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'rentIsPayed',
    label: () => t('pages.agriculturalLand.rentIsPayed'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'closingArea',
    label: () => t('pages.agriculturalLand.closingArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.agriculturalLand.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.agriculturalLand.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default agriculturalLandFields;