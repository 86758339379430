import { t } from 'react-switch-lang';

export const eng = localStorage.getItem('language') === 'eng';

const permanentCropsFields = [
  {
    value: 'plantationType',
    label: () => t('pages.permanentCrops.plantationType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'plantation-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'dateProcured',
    label: () => t('pages.permanentCrops.dateProcured'),
    type: 'date',
    options: {},
    displayInLimits: false
  },
  {
    value: 'purchasedArea',
    label: () => t('pages.permanentCrops.purchasedArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchasedValue',
    label: () => t('pages.permanentCrops.purchasedValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingArea',
    label: () => t('pages.permanentCrops.openingArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.permanentCrops.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'rented',
    label: () => t('pages.permanentCrops.rented'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'ownLandValuePercent',
    label: () => t('pages.permanentCrops.ownLandValuePercent'),
    type: 'integer',
    options: {},
    displayInLimits: true
  },
  {
    value: 'rentIsPayed',
    label: () => t('pages.permanentCrops.rentIsPayed'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'youngCropPlantationType',
    label: () => t('pages.permanentCrops.youngCropPlantationType'),
    type: 'select',
    options: {
      list: [],
      api: 'plantation-types',
      optionValue: 'id',
      optionLabel: 'name',
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'investmentValue',
    label: () => t('pages.permanentCrops.investmentValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldArea',
    label: () => t('pages.permanentCrops.soldArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldValue',
    label: () => t('pages.permanentCrops.soldValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'accumulatedDepreciationValue',
    label: () => t('pages.permanentCrops.accumulatedDepreciationValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'depreciationRate',
    label: () => t('pages.permanentCrops.depreciationRate'),
    type: 'integer',
    options: {},
    displayInLimits: true
  },
  {
    value: 'depreciationValue',
    label: () => t('pages.permanentCrops.depreciationValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'subsidiesValue',
    label: () => t('pages.permanentCrops.subsidiesValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'acquisitionArea',
    label: () => t('pages.permanentCrops.acquisitionArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'acquisitionValue',
    label: () => t('pages.permanentCrops.acquisitionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingArea',
    label: () => t('pages.permanentCrops.closingArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.permanentCrops.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.permanentCrops.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default permanentCropsFields;