export const whitelist = [
  'video/quicktime',
  'video/mpeg',
  'video/vnd.mpegurl',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/x-sgi-movie',
  'video/x-flv',
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-word',
  'application/msword',
  'application/msexcel',
  'application/mspowerpoint',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.oasis.opendocument.text',
  'application/rtf',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/csv',
].join(', ');
