// default messages
import { setDefaultLanguage, setTranslations, t } from 'react-switch-lang';
import eng from '../languages/eng.json';
import mne from '../languages/mne.json';

setTranslations({ eng, mne });
const lang = localStorage.getItem('language');

if (lang === null) {
  setDefaultLanguage('mne');
} else {
  setDefaultLanguage(lang);
}

export const DEFAULT_VALIDATION = {
  STRING_MAX: 255,
  TEXTAREA_MAX: 4000,
  NUMBER_MAX: 100000000,
};

export const DEFAULT_MESSAGE = {
  REQUIRED: t('messages.required'),
  INVALID: t('messages.invalid'),
  MINCHAR: t('messages.minchar'),
  MAXCHAR: t('messages.maxchar'),
  MINVALUE: t('messages.minvalue'),
  MAXVALUE: t('messages.maxvalue'),
  MISMATCH: t('messages.mismatch'),
  WEAKPASS: t('messages.weakPass'),
};

export const REGEX = {
  // eslint-disable-next-line
  DATE: /^[0-3][0-9].[0-1][0-9].[1-2][0-9][0-9][0-9].?$/i,
  // eslint-disable-next-line
  DATETIME: /^[0-3][0-9]\/[0-1][0-9]\/[1-2][0-9][0-9][0-9] [0-2][0-9]\:[0-5][0-9]$/i,
  CODE: /^[0-9a-zA-Z]+$/i,
  // eslint-disable-next-line
  NUMBER: /^[0-9]+$/i,
  // eslint-disable-next-line
  PHONE: /^[\+0][0-9]{5,14}$/,
  EMAIL: /^[a-z0-9]+([-._]?[a-z0-9]+)?@[a-z0-9]+((-?[a-z0-9]+)?\.){1,2}[a-z]{2,}$/i,
  USERNAME: /^[_'.@A-zÀ-ž0-9-]*$/,
  HOST: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  PORT: /^[0-9]{0,4}$/,
  ZIP: /^\d{5}(?:-\d{4})?$/,
  validNameAndNumberRegex: /^[.A-Za-z0-9-_ŠšĐđČčĆćŽžШшџЏЂђЧчЋћЖжЉљЊњаАбБвВгГдДејЈЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦџЏчЧѕЅшШщЩъЪыЫьЬэЭюЮяЯЉљЊњХх ]*$/,
  PASSWORD: '',
  set currentPassword(input) {
    this.PASSWORD = input;
  },
  STRONGPASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]{8,}$/,
};
