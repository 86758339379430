import React from 'react';
import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';
import { formatDateTime } from '../../../../utils/formatDate';

// const lang = localStorage.getItem('language');

const renderColWithTooltip = (col) => {
  return (
    <Tooltip title={col} placement="topLeft" color={'#10394c'}>
      {col ? col : '/'}
    </Tooltip>
  );
};

export const columns = (report) => [
  {
    title: t('pages.agriculturalHolding.ahFadnId'),
    dataIndex: 'agroHoldingFadnId',
    key: 'agroHoldingFadnId',
    ellipsis: true,
    width: 100,
    render: (ahFadnId) => renderColWithTooltip(ahFadnId),
  },
  report === 'sr'
    ? {
        title: t('reports.table.srSection'),
        dataIndex: 'srSection',
        key: 'srSection',
        ellipsis: true,
        render: (srSection) => renderColWithTooltip(srSection),
      }
    : {
        title: t('reports.table.frTable'),
        dataIndex: 'frTable',
        key: 'frTable',
        ellipsis: true,
        render: (frTable) => renderColWithTooltip(frTable),
      },
  {
    title: t('reports.table.resultCode'),
    dataIndex: 'resultCode',
    key: 'resultCode',
    ellipsis: true,
    render: (resultCode) => renderColWithTooltip(resultCode),
  },
  {
    title: t('reports.table.resultFormatted'),
    dataIndex: ['resultFormatted'],
    key: 'resultFormatted',
    ellipsis: true,
    render: (resultFormatted) => renderColWithTooltip(resultFormatted),
  },
  {
    title: t('reports.table.lastModified'),
    dataIndex: ['lastModified'],
    key: 'lastModified',
    ellipsis: true,
    render: (lastModified) => renderColWithTooltip(formatDateTime(lastModified, true)),
  },
];
