import React, { useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../components/form/validationForm/ValidationForm';
import InputField from '../../../components/form/inputField/InputField';
import SaveBtn from '../../../components/buttons/SaveBtn';
import { axiosPost } from '../../../services/axios';
import classes from './Login.module.scss';
import Swal from 'sweetalert2';
import { getLanguage, setLanguage, t } from 'react-switch-lang';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from '../../../assets/images/logo-big.svg';
import LogoEn from '../../../assets/images/logo-en-big.svg';
import AgroLogo from '../../../assets/images/agro-logo.png';
import Government from '../../../assets/images/government.svg';
import Ministry from '../../../assets/images/ministry.svg';
import Chat from '../../../assets/images/chat.svg';
import { eng } from '../../../utils/const';
import LanguageEn from '../../../assets/images/navbar/language-en.svg';
import LanguageCg from '../../../assets/images/navbar/language-cg.svg';
import ContactUs from '../contactUs/ContactUs';
import CheckboxField from '../../../components/form/checkboxField/CheckboxField';
import ForgotPassword from '../forgotPassword/ForgotPassword';
import { useWindowWidth } from '../../../contexts/WindowWidth';
import Loader from '../../../components/loader/Loader';

const Login = () => {
  const formRef = useRef();
  const history = useHistory();
  const location = useLocation();

  const windowWidth = useWindowWidth();

  // state for handling redirect after login if location state has a link
  const [redirect, setRedirect] = useState('');

  const [loading, setLoading] = useState(true);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState([]);

  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);

  const validation = {
    username: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 50 } },
    password: { type: 'string', required: { value: true }, min: { value: 8 }, max: { value: 100 } },
  };

  const submitData = async () => {
    const payload = {
      username: username,
      password: password,
      rememberMe: rememberMe[0] === 'true',
    };

    try {
      const response = await axiosPost('authenticate', payload);
      if (response.status === 200) {
        const lang = getLanguage();
        if (lang === null || lang === '' || lang === undefined) {
          localStorage.setItem('language', 'mne');
        } else {
          localStorage.setItem('language', lang);
        }
        const token = response.data.id_token;
        localStorage.setItem('jwt-token', token);

        // todo fix so UserContext is fetched when using history
        redirect ? window.location = redirect : window.location = '/home';
      }
    } catch (err) {
      Swal.fire({
        text: t('login.error'),
        icon: 'error',
        confirmButtonText: t('login.errorConfirm'),
      });
    }
  };

  // set default language to mne when opening site
  useEffect(() => {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'mne');
    }
  }, []);

  useEffect(() => {
    setLoading(false);

    setRedirect(location?.state?.link);
    let isAuth = localStorage.getItem('jwt-token');
    if (isAuth === null || isAuth === undefined) {
      history.push('/');
    } else {
      history.push('/home');
    }
  }, []);

  const handleSetLanguage = (key) => {
    setLoading(true);

    setLanguage(key);
    localStorage.setItem('language', getLanguage());
    window.location.reload();
  };

  return loading ?
    <div
      className={`d-flex vw-100 vh-100 align-items-center justify-content-center`}
    >
      <Loader size={windowWidth > 450 ? 'lg' : 'sm'} />
    </div>
    :
    <div
      className={`d-flex vw-100 vh-100 align-items-center justify-content-center ${classes['contentWrapper']}`}
    >
      {windowWidth > 900 &&
        <div
          className={`${classes['contentWrapper__background']} w-50 h-100 d-flex flex-column justify-content-between align-items-center`}
        >
          <div className="pt-4">
            <img src={AgroLogo} alt="Agricultural logo" width={'330px'} height={'auto'} />
          </div>
          <div className="d-flex w-75 pb-4 justify-content-between">
            <img src={Ministry} alt="Ministry" />
            <img src={Government} alt="Government" />
          </div>
        </div>
      }
      <div className={`${windowWidth > 900 ? 'w-50' : 'w-100'} h-100 d-flex flex-column align-items-center ${windowWidth > 450 && 'justify-content-start'}`}>
        <div className={`d-flex justify-content-end ${classes['contentWrapper__actions']}  pb-5 pt-4 ${windowWidth > 450 ? 'pl-5 pr-5' : 'pl-4 pr-4'}`}>
          <img src={Chat} alt="Chat" onClick={() => setIsContactUsModalOpen(true)} className="pr-3 cursor" />
          <img
            src={eng ? LanguageCg : LanguageEn}
            alt="Language"
            onClick={() => handleSetLanguage(eng ? 'mne' : 'eng')}
            className="cursor"
          />
        </div>
        <img
          src={eng ? LogoEn : Logo}
          alt="Logo"
          className={`${classes['contentWrapper__logo']}`}
          width={'183px'}
          height={'auto'}
        />
        <div className={`${classes['contentWrapper__welcome']}`}>{t('login.welcome')}</div>
        <div className={`${classes['contentWrapper__form']} ${windowWidth > 450 ? 'w-50' : 'w-75'}`}>
          <ValidationForm validation={validation} ref={formRef}>
            <InputField
              label={t('login.username')}
              placeholder={t('login.usernamePlaceholder')}
              value={username}
              name="username"
              groupField
              onChange={(e) => {
                setUsername(e);
              }}
              disabled={false}
            />
            <InputField
              label={t('login.password')}
              placeholder={t('login.passwordPlaceholder')}
              value={password}
              name="password"
              type="password"
              groupField
              onChange={(e) => {
                setPassword(e);
              }}
              disabled={false}
            />
            <CheckboxField
              options={[{ value: 'true', label: t('login.rememberMe') }]}
              value={rememberMe}
              onChange={(e) => {
                setRememberMe(e);
              }}
            />
            <div className={`${classes['contentWrapper__form--forgot']}`}><span onClick={() => setIsForgotPasswordModalOpen(true)}>{t('common.forgot')}</span></div>
            <div className="d-flex w-100 justify-content-center">
              <SaveBtn
                loaderOnDisabled={false}
                loader={false}
                label={t('login.enter')}
                className="pl-5 pr-5"
                onClick={() => {
                  formRef.current.submitForm(submitData);
                }}
              />
            </div>
          </ValidationForm>
          <div className={`${classes['contentWrapper__register']}`}>
            {t('register.redirectToRegister')}
            <span
              className={`${classes['contentWrapper__register--link']}`}
              onClick={() => history.push('/register')}
            >
              &nbsp;{t('register.here')}
            </span>
          </div>
        </div>
      </div>
      {isContactUsModalOpen && <ContactUs isModalOpen={isContactUsModalOpen} setIsModalOpen={setIsContactUsModalOpen} modalRightAlign={windowWidth > 900} />}
      {isForgotPasswordModalOpen && <ForgotPassword isModalOpen={isForgotPasswordModalOpen} setIsModalOpen={setIsForgotPasswordModalOpen} modalRightAlign={windowWidth > 900} />}
    </div>
};

export default Login;
