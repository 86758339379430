import React, { useContext, useRef, useState } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { t } from 'react-switch-lang';
import Exclusion from '../../../../../../assets/images/icons/cancel-small-grey.svg';
import ModalBody from 'reactstrap/lib/ModalBody';
import { hasValue, notOnlySpacesAndLineBreaks, readOnly, scrollToBottom } from '../../../../../../utils/const';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import ChatArrow from '../../../../../../assets/images/icons/chat-arrow.svg';
import UserData from '../../../../../../contexts/UserData';
import { formatDate } from '../../../../../../utils/formatDate';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  sendErrorExplanation,
  updateErrorExplanation,
} from '../../../../../../services/errroExplanations/errorExplanationsApi';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import { Toast } from '../../../../../../utils/sweetAlert';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import LockingExplanation from '../../../../../../contexts/LockingExplanation';

const LockingErrorsExplanationModal = ({ row, isOpen, toggle }) => {

  const [errorComment, setErrorComment] = useState({ value: undefined });

  const [submitDisabled, setSubmitDisabled] = useState({ errorComment: true });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedNewMessage, setIsSubmittedNewMessage] = useState(false);

  const [allMessages, setAllMessages] = useState([]);
  const [messagesWithDate, setMessagesWithDate] = useState([]);
  const [message, setMessage] = useState({ value: undefined });
  const [messageFormatted, setMessageFormatted] = useState(({}));

  const chatDivRef = useRef();

  const location = useLocation();
  const history = useHistory();

  const user = useContext(UserData);

  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);

  const validation = {
    errorComment: { type: 'string', required: { value: false }, min: { value: 3 }, max: { value: 1000 } },
    message: { type: 'string', required: { value: false }, min: { value: 2 } },
  };

  const lang = localStorage.getItem('language');

  const lockingExplanation = useContext(LockingExplanation);

  const setErrExp = async (crud) => {
    if (hasValue(errorComment.value)) {
      lockingExplanation.setPreLockExplanation('reset');
      if (crud === 'add') {
        const payload = {
          agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
          agroHoldingId: ahid,
          dataModel: 'agroHolding',
          dataModelID: ahid,
          dvcNumber: row?.dvcNumber,
          field: 'lockedBy',
          ...(crud === 'add' ? { isApproved: null } : { isApproved: row?.isApproved }),
          explanation: errorComment.value.trim(),
        };

        try {
          const response = await sendErrorExplanation(payload);
          toggle();
          if (response.status === 201) {
            Toast.fire({
              title: t('administration.lockingErrors.messages.explanationFilledSuccess'),
              icon: 'success',
            });
            lockingExplanation.setPreLockExplanation('refresh');
            // window.location.reload();
          }
        } catch (e) {
          toggle();
          Toast.fire({
            title: t('administration.lockingErrors.messages.explanationFilledError'),
            icon: 'error',
          });
        }
      } else {
        let explanationError = row?.relatedExplanation;
        explanationError['explanation'] = errorComment.value.trim();

        try {
          const response = await updateErrorExplanation(explanationError?.id, explanationError);
          toggle();
          if (response.status === 200) {
            Toast.fire({
              title: t('administration.lockingErrors.messages.explanationUpdateSuccess'),
              icon: 'success',
            });
            lockingExplanation.setPreLockExplanation('refresh');
            // window.location.reload();
          }
        } catch (e) {
          toggle();
          Toast.fire({
            title: t('administration.lockingErrors.messages.explanationUpdateError'),
            icon: 'error',
          });
        }
      }
    }

    setIsSubmitted(true);
  };

  const getErrorExplanationMessages = () => {
    const data = row?.relatedExplanation?.errorExplanationMessages || [];
    setAllMessages(data);

    const messagesTemp = data.map(message => {
      return {
        ...message,
        date: formatDate(new Date(message.createdDate)),
      };
    });
    const messagesByDay = _.groupBy(messagesTemp, 'date');
    setMessagesWithDate([...Object.entries({ ...messagesByDay })]);

    // needs timeout to be after rerender of the div that contains messages
    setTimeout(() => {
      scrollToBottom(chatDivRef);
    }, 10);
  };


  const mergeLocalAndFetchedMessages = async (message) => {
    const allMessagesTemp = row?.relatedExplanation?.errorExplanationMessages || allMessages;
    allMessagesTemp.push(message);
    setAllMessages(allMessagesTemp);

    const messagesTemp = allMessagesTemp.map(message => {
      return {
        ...message,
        date: formatDate(new Date(message.createdDate)),
      };
    });

    const messagesByDay = _.groupBy(messagesTemp, 'date');
    setMessagesWithDate([...Object.entries({ ...messagesByDay })]);

    // let errorExplanationsArrayTemp = errorExplanationsArray;

    // for (let i = 0; i < errorExplanationsArrayTemp.length; i++) {
    //   if (errorExplanationsArrayTemp[i].dvcNumber === row.dvcNumber && errorExplanationsArrayTemp[i].field === row.field) {
    //     let messagesArray = [];
    //
    //     for (let j = 0; j < allMessages.length; j++) {
    //       const message = {
    //         createdBy: allMessages[j].createdBy,
    //         createdDate: allMessages[j].createdDate,
    //         message: allMessages[j].message,
    //         id: allMessages[j]?.id || null,
    //         wasRead: false,
    //       };
    //       messagesArray.push(message);
    //     }
    //
    //     errorExplanationsArrayTemp[i].errorExplanationMessages = messagesArray;
    //   }
    // }
    // setErrorExplanationsArray([...errorExplanationsArrayTemp]);

    setIsSubmittedNewMessage(true);

    // needs timeout to be after rerender of the div that contains messages
    setTimeout(() => {
      scrollToBottom(chatDivRef);
      setIsSubmittedNewMessage(false);
    }, 10);
  };

  const sendMessage = () => {
    if (hasValue(message?.value) && notOnlySpacesAndLineBreaks(message?.value) && message?.value?.length >= 2 && message?.value?.length <= 255) {
      mergeLocalAndFetchedMessages(messageFormatted);
      setMessage(prevValue => ({ ...prevValue, value: undefined }));
    }
  };

  useDidMountEffect(() => {
    if (isOpen) {
      setErrorComment(prevState => ({
        ...prevState,
        value: hasValue(row?.relatedExplanation) ? row?.relatedExplanation?.explanation : undefined,
      }));
      getErrorExplanationMessages();
    }
  }, [isOpen]);

  useDidMountEffect(() => {
    if (hasValue(message?.value)) {
      const messageFormattedTemp = {
        createdBy: user.userData.login,
        createdDate: new Date(),
        date: formatDate(new Date()),
        id: null,
        message: message?.value,
        wasRead: false,
      };

      setMessageFormatted({ ...messageFormattedTemp });
    }
  }, [message?.value]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='explain-modal' backdrop="static">
      <ModalHeader toggle={toggle}>
        <div>{t('messages.insertExplain')}</div>
        <img src={Exclusion} alt="Exclusion" onClick={toggle}
             style={{ cursor: 'pointer' }}/>
      </ModalHeader>
      <ModalBody>
        <form>
          <div className='d-flex flex-column p-4'>
            <div className='d-flex pb-2 modalTitle'>
              {
                row?.relatedExplanation?.message
              }
            </div>
            <TextareaField
              value={errorComment.value}
              setErrorExplanations={() => {
              }}
              placeholder={t('common.insertExplanation')}
              showErrors
              validation={validation['errorComment']}
              name='errorComment'
              rows='4'
              onChange={(e) => {
                setErrorComment({
                  ...errorComment,
                  value: e,
                });
              }}
              setSubmitDisabled={setSubmitDisabled}
              disabled={readOnly}
            />
            <div className='d-flex justify-content-end w-100 pb-4'>
              <CancelBtn
                className="button cancel w-25"
                loader={false}
                onClick={(e) => {
                  toggle();
                }}
              />
              <SaveBtn
                className="button save w-25"
                loader={false}
                disabled={submitDisabled.errorComment || readOnly}
                onClick={(e) => {
                  setErrExp(hasValue(row?.relatedExplanation) ? 'edit' : 'add');
                }}
              />
            </div>
            {row?.relatedExplanation?.explanation &&
            <div>
              <div className='d-flex pb-3 pt-3 border-top border-bottom'>
                {t('common.chatTitle')}
              </div>
              <div ref={chatDivRef} className='d-flex flex-column w-100 chatContainer pt-3'>
                {
                  !messagesWithDate.length > 0 ?
                    <div className='d-flex justify-content-center pt-4 pb-5'>
                      {t('common.noMessages')}
                    </div>
                    :
                    <>
                      {
                        React.Children.toArray(
                          messagesWithDate?.map((messageObject) => {
                            return (
                              <>
                                {/*Date by day*/}
                                <div className='d-flex justify-content-center dateContainer'>
                                  {messageObject[0]}
                                </div>
                                {
                                  React.Children.toArray(
                                    messageObject[1]?.map((messages) => {
                                      return (
                                        <div>
                                          <div className='d-flex flex-column'>
                                          <span
                                            className={`messageTime ${user.userData.login === messages.createdBy ? 'myMessageTime' : 'receivedMessageTime'}`}>
                                            {new Date(messages.createdDate).toTimeString().substr(0, 5)}
                                          </span>
                                            {/*Single message*/}
                                            <div
                                              className={`message ${user.userData.login === messages.createdBy ? 'myMessage' : 'receivedMessage'}`}>
                                              {user.userData.login !== messages.createdBy &&
                                              <span className='user'>{messages.createdBy}</span>
                                              }
                                              <div>{messages.message}</div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }))
                                }
                              </>
                            );
                          }))
                      }
                    </>
                }
              </div>
              <div>
                <InputField
                  value={message.value}
                  setErrorExplanations={() => {
                  }}
                  placeholder={t('common.insertMessage')}
                  showErrors
                  validation={validation['message']}
                  name='message'
                  chat={true}
                  suffixImage={ChatArrow}
                  onClickSuffixImage={() => sendMessage()}
                  onEnter={() => sendMessage()}
                  onChange={(e) => {
                    setMessage({
                      ...message,
                      value: e,
                    });
                  }}
                  setSubmitDisabled={setSubmitDisabled}
                  disabled={readOnly}
                />
              </div>
            </div>
            }
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default LockingErrorsExplanationModal;