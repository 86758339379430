import React from 'react';
import FrCategory from "../../../../../components/administration/enums/frCategoryForm/FrCategory";
import {t} from "react-switch-lang";

const PlantExpenseType = () => {
  return (
    <div>
      <FrCategory api={'plant-expense-types'} title={t('administration.enums.title.plantExpenseType')}/>
    </div>
  );
};

export default PlantExpenseType;
