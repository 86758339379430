import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const moneyLandFields = [
  {
    value: 'landBuildingExpenseType',
    label: () => t('pages.landAndBuilding.landBuildingExpenseType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'land-building-expense-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'area',
    label: () => t('pages.landAndBuilding.area'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumptionValue',
    label: () => t('pages.landAndBuilding.consumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.landAndBuilding.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default moneyLandFields;