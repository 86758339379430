import { t } from 'react-switch-lang';
import { eng } from '../../../../../../../utils/const';

const goatsFatteningFields = [
  {
    value: 'goatCategory',
    label: () => t('pages.goat.categoryNameGoat'),
    type: 'select', // integer, double, select, textarea, string
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api:'goat-categories',
      apiQuery: 'canBeServiceFattening.equals=true',
      optionValue: 'id',
      optionLabel: eng ? 'nameEn' : 'name',
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.goat.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathQuantity',
    label: () => t('pages.goat.deathQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'inputQuantity',
    label: () => t('pages.goat.inputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'outputQuantity',
    label: () => t('pages.goat.outputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.goat.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.goat.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default goatsFatteningFields;