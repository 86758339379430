import React from 'react';
import Badge from 'antd/lib/badge';
import PropTypes from 'prop-types';

// Wrap this component around element with opening and closing tag
// offset is passed as an array of 2 numbers: [left, top]

const BadgeWrapper = ({ condition, style, className, offset, children }) => {
  return condition ? (
    <Badge
      style={style ? style : { width: 10, height: 10 }}
      className={className && className}
      dot
      offset={offset ? offset : [-5, 7]}
    >
      {children}
    </Badge>
  ) : (
    children
  );
};

export default BadgeWrapper;

BadgeWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};
