import React from 'react';
import FrCategory from "../../../../../components/administration/enums/frCategoryForm/FrCategory";
import {t} from "react-switch-lang";

const LabourMachineryExpenseType = () => {
  return (
    <div>
      <FrCategory api={'labour-machinery-expense-types'} title={t('administration.enums.title.labourMachineryExpenseType')}/>
    </div>
  );
};

export default LabourMachineryExpenseType;
