import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../formDynamic/textareaField/TextareaField';
import InputField from '../../../../formDynamic/inputField/InputField';
import useDidMountEffect from '../../../../../app/hooks/useDidMountEffect';
import './ExplainErrorModal.scss';
import { hasValue, notOnlySpacesAndLineBreaks, readOnly, scrollToBottom } from '../../../../../utils/const';
import CancelBtn from '../../../../buttons/CancelBtn';
import SaveBtn from '../../../../buttons/SaveBtn';
import ChatArrow from '../../../../../assets/images/icons/chat-arrow.svg';
import { useLocation } from 'react-router-dom';
import UserData from '../../../../../contexts/UserData';
import Exclusion from '../../../../../assets/images/icons/cancel-small-grey.svg';
import { formatDate } from '../../../../../utils/formatDate';
import { makeValidationKeys } from '../../../helpers';

const ExplainErrorModal = ({ isOpen, toggle, className, modal, onSubmit }) => {

  const { explainError, errorExplanationsArray, setErrorExplanationsArray } = modal;

  const [errorComment, setErrorComment] = useState({ value: undefined });

  const [submitDisabled, setSubmitDisabled] = useState({ errorComment: true });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedNewMessage, setIsSubmittedNewMessage] = useState(false);

  const [allMessages, setAllMessages] = useState([]);
  const [messagesWithDate, setMessagesWithDate] = useState([]);
  const [message, setMessage] = useState({ value: undefined });
  const [messageFormatted, setMessageFormatted] = useState(({}));

  const [showErrors, setShowErrors] = useState(false);


  const chatDivRef = useRef();

  const location = useLocation();

  const user = useContext(UserData);

  const validationInitial = {
    errorComment: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 1000 } },
    message: { type: 'string', required: { value: false }, min: { value: 2 } },
  };

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [validation, setValidation] = useState(validationInitial);

  const lang = localStorage.getItem('language');

  const setErrExp = (submit = false) => {
    if (errorComment.value !== undefined) {

      let errorExplanationsArrayTemp = errorExplanationsArray.map(el => (
        el.dvcNumber === explainError.dvcNumber && el.field === explainError.field ? {
          ...el,
          ['explanation']: errorComment.value.trim(),
        } : el
      ));

      setErrorExplanationsArray(errorExplanationsArrayTemp);
    }

    setIsSubmitted(true);
  };

  const getErrorExplanationMessages = () => {
    const data = explainError?.errorExplanationMessages || [];
    setAllMessages(data);

    const messagesTemp = data.map(message => {
      return {
        ...message,
        date: formatDate(new Date(message.createdDate)),
      };
    });
    const messagesByDay = _.groupBy(messagesTemp, 'date');
    setMessagesWithDate([...Object.entries({ ...messagesByDay })]);

    // needs timeout to be after rerender of the div that contains messages
    setTimeout(() => {
      scrollToBottom(chatDivRef);
    }, 10);
  };

  const mergeLocalAndFetchedMessages = async (message) => {
    const allMessagesTemp = explainError?.errorExplanationMessages || allMessages;
    allMessagesTemp.push(message);
    setAllMessages(allMessagesTemp);

    setAllMessages(allMessagesTemp);

    const messagesTemp = allMessagesTemp.map(message => {
      return {
        ...message,
        date: formatDate(new Date(message.createdDate)),
      };
    });

    const messagesByDay = _.groupBy(messagesTemp, 'date');
    setMessagesWithDate([...Object.entries({ ...messagesByDay })]);

    let errorExplanationsArrayTemp = errorExplanationsArray;

    for (let i = 0; i < errorExplanationsArrayTemp.length; i++) {
      if (errorExplanationsArrayTemp[i].dvcNumber === explainError.dvcNumber && errorExplanationsArrayTemp[i].field === explainError.field) {
        let messagesArray = [];

        for (let j = 0; j < allMessages.length; j++) {
          const message = {
            createdBy: allMessages[j].createdBy,
            createdDate: allMessages[j].createdDate,
            message: allMessages[j].message,
            id: allMessages[j]?.id || null,
            wasRead: false,
          };
          messagesArray.push(message);
        }

        errorExplanationsArrayTemp[i].errorExplanationMessages = messagesArray;
      }
    }
    setErrorExplanationsArray([...errorExplanationsArrayTemp]);

    setIsSubmittedNewMessage(true);

    // needs timeout to be after rerender of the div that contains messages
    setTimeout(() => {
      scrollToBottom(chatDivRef);
      setIsSubmittedNewMessage(false);
    }, 10);
  };

  const sendMessage = () => {
    if (hasValue(message?.value) && notOnlySpacesAndLineBreaks(message?.value) && message?.value?.length >= 3 && message?.value?.length <= 255) {
      mergeLocalAndFetchedMessages(messageFormatted);
      setMessage(prevValue => ({ ...prevValue, value: undefined }));
    }
  };

  useDidMountEffect(() => {
    isSubmitted && onSubmit();
    isSubmitted && setTimeout(() => {
      toggle();
    }, 500);
  }, [isSubmitted]);

  useDidMountEffect(() => {
    isSubmittedNewMessage && onSubmit();
  }, [isSubmittedNewMessage]);

  useDidMountEffect(() => {
    if (isOpen) {
      !location.pathname.includes('add') && explainError?.explanation && getErrorExplanationMessages();
      const field = errorExplanationsArray.find(item => item?.field === explainError?.field &&
        item?.dvcNumber === explainError?.dvcNumber);

      if (hasValue(field) && field.hasOwnProperty('explanation')) {
        setErrorComment({ ...errorComment, value: field.explanation });
      } else {
        setErrorComment({ ...errorComment, value: undefined });
      }
    } else {
      setIsSubmitted(false);
    }
  }, [isOpen]);

  useDidMountEffect(() => {
    if (hasValue(message?.value)) {
      const messageFormattedTemp = {
        createdBy: user.userData.login,
        createdDate: new Date(),
        date: formatDate(new Date()),
        id: null,
        message: message?.value,
        wasRead: false,
      };

      setMessageFormatted({ ...messageFormattedTemp });
    }
  }, [message?.value]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={`${className} explain-modal`} backdrop="static">
      <ModalHeader toggle={toggle}>
        <div>{t('messages.insertExplain')}</div>
        <img src={Exclusion} alt="Exclusion" onClick={toggle}
             style={{ cursor: 'pointer' }}/>
      </ModalHeader>
      <ModalBody>
        <div className='d-flex flex-column p-4'>
          <form>
            <div className='d-flex pb-2 modalTitle'>
              {(() => {
                if (hasValue(explainError) && explainError?.message)
                  return explainError.message;

              if (hasValue(explainError) && explainError?.dvcDescription)
                return lang === 'eng' ? explainError.dvcDescriptionEn : explainError.dvcDescription;
            })()}
          </div>
          <TextareaField
            value={errorComment.value}
            setErrorExplanations={() => {
            }}
            placeholder={t('common.insertExplanation')}
            validation={validation['errorComment']}
            name='errorComment'
            groupField
            rows='4'
            onChange={(e) => {
              setErrorComment({
                ...errorComment,
                value: e,
              });
            }}
            setSubmitDisabled={setSubmitDisabled}
            showErrors={showErrors}
            disabled={modal.permissions || readOnly}
          />
          <div className='d-flex justify-content-end w-100 pb-4'>
            <CancelBtn
              className="button cancel w-25"
              loader={false}
              onClick={(e) => {
                toggle();
              }}
              keepDirty={true}
            />
            <SaveBtn
              className="button save w-25"
              loader={false}
              formValidation={validation}
              disabled={modal.permissions ? (modal.permissions || readOnly) : (submitDisabled.errorComment || readOnly)}
              onClick={(e) => {
                setShowErrors(true);
                hasValue(errorComment.value) && setErrExp(true);
              }}
              keepDirty={true}
            />
          </div>
          </form>
          {!location.pathname.includes('add') && explainError?.explanation &&
          <div>
            <div className='d-flex pb-3 pt-3 border-top border-bottom'>
              {t('common.chatTitle')}
            </div>
            <div ref={chatDivRef} className='d-flex flex-column w-100 chatContainer pt-3'>
              {
                !messagesWithDate.length > 0 ?
                  <div className='d-flex justify-content-center pt-4 pb-5'>
                    {t('common.noMessages')}
                  </div>
                  :
                  <>
                    {
                      React.Children.toArray(
                        messagesWithDate?.map((messageObject) => {
                          return (
                            <>
                              {/*Date by day*/}
                              <div className='d-flex justify-content-center dateContainer'>
                                {messageObject[0]}
                              </div>
                              {
                                React.Children.toArray(
                                  messageObject[1]?.map((messages) => {
                                    return (
                                      <div>
                                        <div className='d-flex flex-column'>
                                          <span
                                            className={`messageTime ${user.userData.login === messages.createdBy ? 'myMessageTime' : 'receivedMessageTime'}`}>
                                            {new Date(messages.createdDate).toTimeString().substr(0, 5)}
                                          </span>
                                          {/*Single message*/}
                                          <div
                                            className={`message ${user.userData.login === messages.createdBy ? 'myMessage' : 'receivedMessage'}`}>
                                            {user.userData.login !== messages.createdBy &&
                                            <span className='user'>{messages.createdBy}</span>
                                            }
                                            <div>{messages.message}</div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }))
                              }
                            </>
                          );
                        }))
                    }
                  </>
              }
            </div>
            <div>
              <InputField
                value={message.value}
                setErrorExplanations={() => {
                }}
                placeholder={t('common.insertMessage')}
                showErrors
                validation={validation['message']}
                name='message'
                chat={true}
                suffixImage={ChatArrow}
                onClickSuffixImage={() => sendMessage()}
                onEnter={() => sendMessage()}
                onChange={(e) => {
                  setMessage({
                    ...message,
                    value: e,
                  });
                }}
                setSubmitDisabled={setSubmitDisabled}
                disabled={modal.permissions || readOnly}
              />
            </div>
          </div>
          }
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExplainErrorModal;
