import React, { useEffect, useRef, useState } from 'react';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import Toast from '../../../../../../components/toast/Toast';
import { t } from 'react-switch-lang';
import ValidationForm from '../../../../../../components/form/validationForm/ValidationForm';
import InputField from '../../../../../../components/form/inputField/InputField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import SelectConstField from '../../../../../../components/form/selectField/SelectConstField';

const ProductionAnimalProductsForm = ({data, type, updateTable, closeModal, isDisabled}) => {

  const formRef = useRef();

  const initialData = {
    name: '',
    nameEn: '',
    unit: '',
    sortVal: '',
    frCategory: '',
    yieldUnit: '',
  };
  const [changedData, setChangedData] = useState(initialData);
  const tableOptions = [
    {
    value: 'A',
    label: 'A',
  }, {

    value: 'B',
    label: 'B',
  }, {
    value: "C",
    label: 'C',
  }, {
    value: "D",
    label: "D",
  }, {

    value: "E",
    label: "E",
  }, {

    value: "F",
    label: "F",
  },
    {

      value: "G",
      label: "G",
    },
    {

      value: "H",
      label: "H",
    },
    {

      value: "I",
      label: "I",
    },
    {

      value: "J",
      label: "J",
    },
    {

      value: "K",
      label: "K",
    },
    {

      value: "L",
      label: "L",
    },
    {

      value: "M",
      label: "M",
    }];
  const validation = {
    name: {type: 'string', required: {value: true}, min: {value: 3}},
    nameEn: {type: 'string', required: {value: true}, min: {value: 3}},
    unit: { type: 'string', required: { value: false }, min: { value: 1 } },
    yieldUnit: { type: 'string', required: { value: false }, min: { value: 1 } },
    sortVal: {type: 'number', required: {value: true}, min: {value: 1}},
    frCategory: {type: 'number', required: {value: true}, min: {value: 1}},
    frTable: {type: 'selectSingle', required: {value: true}},
  }

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData,
        frTable: changedData.frTable.value
      };

      try {
        const response = await axiosPost('production-animal-products', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.productionAnimalProducts.successAdd'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData,
        frTable: changedData.frTable.value

      };

      try {
        const response = await axiosPut(`production-animal-products/${data.id}`, payload);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.productionAnimalProducts.successEdit'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        name: data.name,
        nameEn: data.nameEn,
        unit: data.unit,
        yieldUnit: data.yieldUnit,
        sortVal: data.sortVal,
        frCategory: data.frCategory,
        frTable: tableOptions.find(option => data.frTable[0] === option.value)
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <InputField
          label={t('administration.enums.columns.name')}
          placeholder={t('administration.enums.placeholders.name')}
          value={changedData.name}
          name='name'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, name: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.nameEn')}
          placeholder={t('administration.enums.placeholders.nameEn')}
          value={changedData.nameEn}
          name='nameEn'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, nameEn: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.sortVal')}
          placeholder={t('administration.enums.placeholders.sort')}
          value={changedData.sortVal}
          name='sortVal'
          type='number'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, sortVal: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.unit')}
          placeholder={t('administration.enums.placeholders.unit')}
          value={changedData.unit}
          name='unit'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, unit: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.yieldUnit')}
          placeholder={t('administration.enums.placeholders.yieldUnit')}
          value={changedData.yieldUnit}
          name='yieldUnit'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, yieldUnit: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.frCategory')}
          placeholder={t('administration.enums.placeholders.frCategory')}
          value={changedData.frCategory}
          name='frCategory'
          type='number'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, frCategory: e,});
          }}
          disabled={isDisabled}
        />
        <SelectConstField
          label={t('administration.enums.columns.frTable')}
          placeholder={t('administration.enums.placeholders.frTable')}
          value={changedData?.frTable}
          name='frTable'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, frTable: e,});
          }}
          options={tableOptions}
          disabled={isDisabled}
        />
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)}/>
        </div>
      )}
    </>
  );
};

export default ProductionAnimalProductsForm;
