import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const leasingFields = [
  {
    value: 'leasingType',
    label: () => t('pages.leasing.leasingType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'leasing-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.leasing.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'interestValue',
    label: () => t('pages.leasing.interestValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.leasing.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.leasing.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default leasingFields;