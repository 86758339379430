import React, { useEffect, useState } from 'react';
import FieldContainer from '../fieldContainer/FieldContainer';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import classes from './TextareaField.module.scss';
import { validate } from '../../../utils/validation';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import _ from 'lodash';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

const TextareaField = React.forwardRef(
  (
    {
      label,
      disabled = false,
      name,
      value,
      defaultValue,
      onChange,
      onBlur = () => {},
      validation,
      setError,
      placeholder,
      styles,
      rows = 2,
      displayError,
      tabIndex,
      ...rest
    },
    ref,
  ) => {
    let uniqueId = uniqid();

    let componentName = name ? name : uniqueId;

    const redirectPrompt = useRedirectPrompt();

    const [errMessage, setErrMessage] = useState(validate(validation, value));

    const prevVal = usePrevious(value);
    const prevValidation = usePrevious(validation);

    useEffect(() => {
      // on component unmounting return states to initial
      return () => {
        setErrMessage({});
      };
    }, []);

    useEffect(() => {
      // eslint-disable-next-line
      if ((!compareObjects(prevVal, value)) || (!compareObjects(validation, prevValidation))) {
        let errorObject = validate(validation, value);
        if (errorObject.type !== 'noErrors') {
          if (errorObject.type === 'apiError') {
            setError('apiError')
          } else {
            setError('error')
          }
        } else {
          setError('noErrors')
        }
        setErrMessage(errorObject);
      }
      // eslint-disable-next-line
    }, [value, validation]);

    return (
      <FieldContainer
        label={label}
        required={
          validation
            ? validation.required
            ? validation.required.value === true
            : false
            : false
        }
        componentName={componentName}
        errorMessage={!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? errMessage : ''}
        customClasses={`${styles}`}
        {...rest}
      >
        <textarea
          tabIndex={tabIndex}
          disabled={disabled}
          autoComplete="off"
          data-refkey={componentName}
          className={`
                    ${classes['textarea-field']}
                    ${disabled ? classes['disabled'] : ''}
                    ${!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? classes['error'] : ''}`}
          ref={ref}
          name={componentName}
          value={value}
          defaultValue={defaultValue}
          onChange={(e) => {
            onChange(e.target.value);
            redirectPrompt.setIsDirty(true);
          }}
          onBlur={(e) => onBlur()}
          placeholder={placeholder ? placeholder : t('common.insertValue')}
          title={placeholder ? placeholder : t('common.insertValue')}
          // onClick={(e) => e.target.focus()}
          id={componentName}
          rows={rows}
        />
      </FieldContainer>
    );
  },
);

TextareaField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  validation: PropTypes.object,
  displayError: PropTypes.object,
  setError: PropTypes.func,
};

export default TextareaField;
