import React from 'react';
import './ErrorPages.scss';
import Loader from '../../../components/loader/Loader.jsx';

const LoadingPage = () => {
    return (
        <div className="error-page-container position-relative">
            <div className="d-inline-block position-absolute info-containter">
                <Loader size="lg"/>
            </div>
        </div>
    );
};

export default LoadingPage;
