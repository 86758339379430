import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { t } from 'react-switch-lang';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import CheckboxField from '../../../../../components/formDynamic/checkboxField/CheckboxField';
import DateField from '../../../../../components/formDynamic/dateField/DateField';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './PermanentCropsForm.module.scss';
import {
  fetchPlantationType,
  fetchSinglePermanentCrop,
} from '../../../../../services/permanentCrops/permanentCropsApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Toast } from '../../../../../utils/sweetAlert';
import UserData from '../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import SelectAsyncField from '../../../../../components/formDynamic/selectField/SelectAsyncField';
import Loader from '../../../../../components/loader/Loader';
import { getIsCurrentFADNYear } from '../../../../../services/financialYear';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const checkIfSelectedYearIsCurrent = async (date) => {
  const selectedYear = date && !isNaN(date.getTime()) ? new Date(date).getFullYear() : null;
  const fadnYear = await getIsCurrentFADNYear();
  return fadnYear && selectedYear
    ? selectedYear === fadnYear
      ? true
      : selectedYear <= (fadnYear - 1)
        ? false
        : null
    : null;
};

const PermanentCropsForm = ({ props, data, isAudit = false }) => {

  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [permanentCrop, setPermanentCrop] = useState();

  const [plantationType, setPlantationType] = useState({ value: undefined, disabled: !edit });
  const [dateProcured, setDateProcured] = useState({ value: undefined, disabled: !edit });
  const [purchasedArea, setPurchasedArea] = useState({ value: 0, disabled: !edit });
  const [purchasedValue, setPurchasedValue] = useState({ value: 0, disabled: !edit });
  const [openingArea, setOpeningArea] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [rented, setRented] = useState({ value: [], disabled: !edit });
  const [ownLandValuePercent, setOwnLandValuePercent] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [rentIsPayed, setRentIsPaid] = useState({ value: undefined, disabled: !edit });
  const [youngCropPlantationType, setYoungCropPlantationType] = useState({ value: undefined, disabled: !edit });
  const [investmentValue, setInvestmentValue] = useState({ value: 0, disabled: !edit });
  const [soldArea, setSoldArea] = useState({ value: 0, disabled: !edit });
  const [soldValue, setSoldValue] = useState({ value: 0, disabled: !edit });
  const [accumulatedDepreciationValue, setAccumulatedDepreciationValue] = useState({
    value: 0,
    disabled: isAudit ? true : location.pathname.includes('add') ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : !edit,
  });
  const [depreciationRate, setDepreciationRate] = useState({ value: 0, disabled: true });
  const [depreciationValue, setDepreciationValue] = useState({ value: 0, disabled: !edit });
  const [subsidiesValue, setSubsidiesValue] = useState({ value: 0, disabled: !edit });
  const [closingArea, setClosingArea] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: true });
  const [acquisitionArea, setAcquisitionArea] = useState({
    value: 0,
    disabled: isAudit ? true : location.pathname.includes('add') ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : !edit,
  });
  const [acquisitionValue, setAcquisitionValue] = useState({
    value: 0,
    disabled: isAudit ? true : location.pathname.includes('add') ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : !edit,
  });

  const [allPlantationType, setAllPlantationType] = useState([]);
  const [measurementUnit, setMeasurementUnit] = useState('');

  const [errorExplanations, setErrorExplanations] = useState([]);
  const [plantationTypeOptionList, setPlantationTypeOptionList] = useState([]);

  const validationInitial = {
    plantationType: { type: 'selectSingle', required: { value: true } },
    dateProcured: { type: 'date', required: { value: true } },
    purchasedArea: { type: 'number', required: { value: false }, min: { value: 0 } },
    purchasedValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    openingArea: { type: 'number', required: { value: false }, min: { value: 0 } },
    openingValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    rented: { type: 'checkbox', required: { value: false } },
    ownLandValuePercent: { type: 'number', required: { value: true }, max: { value: 100 } },
    comment: { type: 'string', required: { value: false }, min: { value: 3 } },
    rentIsPayed: { type: 'checkbox', required: { value: false } },
    youngCropPlantationType: { type: 'selectSingle', required: { value: false } },
    investmentValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    soldArea: { type: 'number', required: { value: false }, min: { value: 0 } },
    soldValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    accumulatedDepreciationValue: { type: 'number', required: { value: false } },
    depreciationRate: { type: 'number', required: { value: false } },
    depreciationValue: { type: 'number', required: { value: true } },
    subsidiesValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    closingArea: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
    acquisitionArea: { type: 'number', required: { value: false } },
    acquisitionValue: { type: 'number', required: { value: false } },
  };

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [validation, setValidation] = useState(validationInitial);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'permanentCrop', permanentCrop, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** START DEPENDANT VALIDATION *****/

  const changePermanentCrops = (e) => {
    if (e?.value) {
      if (e?.value !== 4) {
        setYoungCropPlantationType(prevState => ({ ...prevState, value: null, disabled: true }));
      } else {
        setYoungCropPlantationType(prevState => ({ ...prevState, disabled: !edit }));

      }
    }
  };
  const changeRented = (e) => {
    if (!_.isEmpty(e) && e[0] === 'true') {
      setOwnLandValuePercent(prevState => ({ ...prevState, value: 0, disabled: true }));
    } else {
      setOwnLandValuePercent(prevState => ({ ...prevState, disabled: !edit }));
    }
  };
  const changeDateProcured = async (e) => {
    if (e?.value) {
      const isSelectedCurrentYear = await checkIfSelectedYearIsCurrent(e?.value);
      if (isSelectedCurrentYear === true) {
        setOpeningArea(prevState => ({ ...prevState, value: 0, disabled: true }));
        setOpeningValue(prevState => ({ ...prevState, value: 0, disabled: true }));
      } else {
        setOpeningArea(prevState => ({ ...prevState, disabled: !edit }));
        setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
      }

      if (isSelectedCurrentYear === false) {
        setPurchasedArea(prevState => ({ ...prevState, value: 0, disabled: true }));
        setPurchasedValue(prevState => ({ ...prevState, value: 0, disabled: true }));
      } else {
        setPurchasedArea(prevState => ({ ...prevState, disabled: !edit }));
        setPurchasedValue(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };

  const calculateDepreciationValue = async () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;
    const returnMuliVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 1;
    const getMonths = async () => {
      const isSelectedCurrentYear = await checkIfSelectedYearIsCurrent(dateProcured?.value);

      return isSelectedCurrentYear === true
        ? (dateProcured?.value ? 12 - dateProcured?.value.getMonth() : 0)
        : isSelectedCurrentYear === false
          ? 12 : 1;
    };

    const monthsNo = await getMonths();

    setDepreciationValue(prevState => ({
      ...prevState,
      disabled: true,
      value: parseFloatCustom(
        (returnNullVal(acquisitionValue.value)
          * (returnMuliVal(depreciationRate.value) / 100)
          / 12
          * monthsNo).toFixed(2)),
    }));
  };

  const checkClosingAndSold = () => {
    if (closingArea?.value === 0 && soldArea?.value > 0) {
      setClosingValue(prevState => ({ ...prevState, value: 0 }));
    }
  };

  const calculateClosingValue = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(
      returnNullVal(openingValue.value)
      + returnNullVal(purchasedValue.value)
      + returnNullVal(investmentValue.value)
      - returnNullVal(soldValue.value)
      - returnNullVal(depreciationRate.value),
    ).toFixed(2);

    setClosingValue(prevState => ({
      ...prevState,
      disabled: true,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };

  const calculateClosingArea = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;
    setClosingArea(prevState => ({
      ...prevState,
      disabled: true,
      value: parseFloatCustom(
        returnNullVal(openingArea.value)
        + returnNullVal(purchasedArea.value)
        - returnNullVal(soldArea.value),
      ).toFixed(2),
    }));
  };

  const onObjectTypeChange = (e) => {
    if (e?.value) {
      const selected = allPlantationType.find(item => item.id === e?.value);
      if (selected) {
        setMeasurementUnit(selected?.unit);
        setDepreciationRate(prevState => ({ ...prevState, value: selected?.depreciationRate }));
      }
    }
  };

  /***** END DEPENDANT VALIDATION *****/

  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(plantationType.value?.value && { plantationType: { id: parseInt(plantationType?.value?.value) } }),
        ...(hasValue(openingArea?.value) && { openingArea: parseFloatCustom(openingArea?.value) }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue?.value) }),
        ...(dateProcured?.value && { dateProcured: dateProcured?.value }),
        ...(hasValue(purchasedArea?.value) && { purchasedArea: parseFloatCustom(purchasedArea?.value) }),
        ...(hasValue(purchasedValue?.value) && { purchasedValue: parseFloatCustom(purchasedValue?.value) }),
        ...({ rented: Boolean(rented?.value && rented?.value[0] === 'true') }),
        ...(hasValue(ownLandValuePercent?.value) && { ownLandValuePercent: parseInt(ownLandValuePercent?.value) }),
        ...(comment?.value && { comment: comment?.value }),
        ...({ rentIsPayed: Boolean(rentIsPayed?.value && rentIsPayed?.value[0] === 'true') }),
        ...(hasValue(youngCropPlantationType?.value) && { youngCropPlantationType: { id: parseInt(youngCropPlantationType?.value?.value) } }),
        ...(hasValue(investmentValue?.value) && { investmentValue: parseFloatCustom(investmentValue?.value) }),
        ...(hasValue(soldArea?.value) && { soldArea: parseFloatCustom(soldArea?.value) }),
        ...(hasValue(soldValue?.value) && { soldValue: parseFloatCustom(soldValue?.value) }),
        ...(hasValue(accumulatedDepreciationValue?.value) && { accumulatedDepreciationValue: parseFloatCustom(accumulatedDepreciationValue?.value) }),
        ...(hasValue(depreciationRate?.value) && { depreciationRate: parseInt(depreciationRate?.value) }),
        ...(hasValue(depreciationValue?.value) && { depreciationValue: parseFloatCustom(depreciationValue?.value) }),
        ...(hasValue(subsidiesValue?.value) && { subsidiesValue: parseFloatCustom(subsidiesValue?.value) }),
        ...(hasValue(closingArea?.value) && { closingArea: parseFloatCustom(closingArea?.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue?.value) }),
        ...(hasValue(acquisitionArea?.value) && { acquisitionArea: parseFloatCustom(acquisitionArea?.value) }),
        ...(hasValue(acquisitionValue?.value) && { acquisitionValue: parseFloatCustom(acquisitionValue?.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`permanent-crops?submitData=${isSubmit}`, payload);

          if (response.status === 201 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.permanentCrops') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add');
          }
        } catch (e) {
          handleApiErrors(e, 'add');
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`permanent-crops/${permanentCrop.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.permanentCrops') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit');
          }
        } catch (e) {
          handleApiErrors(e, 'edit');
        }
      }
    }
  };

  const getPermanentCrop = async (id) => {
    try {
      const { data } = await fetchSinglePermanentCrop(props.match.params.id);
      setPermanentCrop(data);
      return data;
    } catch (e) {

    }
  };
  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };

  const getPlantationType = async () => {
    try {
      const { data, filtered } = await fetchPlantationType();
      setAllPlantationType(data);
      setPlantationTypeOptionList(filtered);
    } catch (e) {
    }
  };

  useEffect(() => {
    getPlantationType();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        getPermanentCrop().then(permanentCrops => {
          setPlantationType(prevState => ({
            ...prevState,
            value: permanentCrops?.plantationType ? defaultOption(permanentCrops?.plantationType) : undefined,
          }));
          setDateProcured(prevState => ({
            ...prevState,
            value: permanentCrops?.dateProcured ? new Date(permanentCrops?.dateProcured) : undefined,
          }));
          setOpeningArea(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.openingArea) }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.openingValue) }));
          setPurchasedArea(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.purchasedArea) }));
          setPurchasedValue(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.purchasedValue) }));
          setRented(prevState => ({
            ...prevState,
            value: permanentCrops?.rented ? [permanentCrops?.rented ? 'true' : 'false'] : [],
          }));
          setComment(prevState => ({ ...prevState, value: permanentCrops?.comment }));
          setRentIsPaid(prevState => ({
            ...prevState,
            value: permanentCrops?.rentIsPayed ? [permanentCrops?.rentIsPayed ? 'true' : 'false'] : [],
          }));
          setYoungCropPlantationType(prevState => ({
            ...prevState,
            value: permanentCrops?.youngCropPlantationType ? defaultOption(permanentCrops?.youngCropPlantationType) : undefined,
          }));
          setOwnLandValuePercent(prevState => ({ ...prevState, value: permanentCrops?.ownLandValuePercent }));
          setInvestmentValue(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.investmentValue) }));
          setSoldArea(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.soldArea) }));
          setSoldValue(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.soldValue) }));
          setAccumulatedDepreciationValue(prevState => ({
            ...prevState,
            value: parseDouble(permanentCrops?.accumulatedDepreciationValue),
          }));
          setClosingArea(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.closingArea) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.closingValue) }));
          setAcquisitionArea(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.acquisitionArea) }));
          setAcquisitionValue(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.acquisitionValue) }));
          setDepreciationValue(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.depreciationValue) }));
          setDepreciationRate(prevState => ({ ...prevState, value: permanentCrops?.depreciationRate }));
          setSubsidiesValue(prevState => ({ ...prevState, value: parseDouble(permanentCrops?.subsidiesValue) }));
          setErrorExplanations(permanentCrops?.errorExplanations ? permanentCrops?.errorExplanations : []);
          let explanations = permanentCrops?.errorExplanations ? permanentCrops?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'permanentCrop', permanentCrops, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);

        });
      } else {
        setPlantationType(prevState => ({
          ...prevState,
          value: data?.plantationType ? defaultOption(data?.plantationType) : undefined,
        }));
        setDateProcured(prevState => ({
          ...prevState,
          value: data?.dateProcured ? new Date(data?.dateProcured) : undefined,
        }));
        setOpeningArea(prevState => ({ ...prevState, value: parseDouble(data?.openingArea) }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setPurchasedArea(prevState => ({ ...prevState, value: parseDouble(data?.purchasedArea) }));
        setPurchasedValue(prevState => ({ ...prevState, value: parseDouble(data?.purchasedValue) }));
        setRented(prevState => ({
          ...prevState,
          value: data?.rented ? [data?.rented ? 'true' : 'false'] : [],
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setRentIsPaid(prevState => ({
          ...prevState,
          value: data?.rentIsPayed ? [data?.rentIsPayed ? 'true' : 'false'] : [],
        }));
        setYoungCropPlantationType(prevState => ({
          ...prevState,
          value: permanentCrop?.youngCropPlantationType ? defaultOption(data?.youngCropPlantationType) : undefined,
        }));
        setOwnLandValuePercent(prevState => ({ ...prevState, value: data?.ownLandValuePercent }));
        setInvestmentValue(prevState => ({ ...prevState, value: parseDouble(data?.investmentValue) }));
        setSoldArea(prevState => ({ ...prevState, value: parseDouble(data?.soldArea) }));
        setSoldValue(prevState => ({ ...prevState, value: parseDouble(data?.soldValue) }));
        setAccumulatedDepreciationValue(prevState => ({
          ...prevState,
          value: parseDouble(data?.accumulatedDepreciationValue),
        }));
        setClosingArea(prevState => ({ ...prevState, value: parseDouble(data?.closingArea) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setAcquisitionArea(prevState => ({ ...prevState, value: parseDouble(data?.acquisitionArea) }));
        setAcquisitionValue(prevState => ({ ...prevState, value: parseDouble(data?.acquisitionValue) }));
        setDepreciationValue(prevState => ({ ...prevState, value: parseDouble(data?.depreciationValue) }));
        setDepreciationRate(prevState => ({ ...prevState, value: data?.depreciationRate }));
        setSubsidiesValue(prevState => ({ ...prevState, value: parseDouble(data?.subsidiesValue) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);
        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'permanentCrop', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setPlantationType(prevState => ({ ...prevState, disabled: false }));
        setDateProcured(prevState => ({ ...prevState, disabled: false }));
        // setOwnLandValuePercent(prevState => ({...prevState, disabled: false}));
        // setOpeningArea(prevState => ({ ...prevState, disabled: false }));
        // setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        // setPurchasedArea(prevState => ({ ...prevState, disabled: false }));
        // setPurchasedValue(prevState => ({ ...prevState, disabled: false }));
        setSoldArea(prevState => ({ ...prevState, disabled: false }));
        setSoldValue(prevState => ({ ...prevState, disabled: false }));
        setRented(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setRentIsPaid(prevState => ({ ...prevState, disabled: false }));
        // setClosingValue(prevState => ({ ...prevState, disabled: false }));
        // setClosingArea(prevState => ({ ...prevState, disabled: false }));
        setAcquisitionArea(prevState => ({
          ...prevState,
          disabled: isAudit ? true : edit ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : true,
        }));
        setAcquisitionValue(prevState => ({
          ...prevState,
          disabled: isAudit ? true : edit ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : true,
        }));
        setYoungCropPlantationType(prevState => ({ ...prevState, disabled: false }));
        setInvestmentValue(prevState => ({ ...prevState, disabled: false }));
        setAccumulatedDepreciationValue(prevState => ({
          ...prevState,
          disabled: isAudit ? true : edit ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : true,
        }));
        // setDepreciationValue(prevState => ({ ...prevState, disabled: false }));
        // setDepreciationRate(prevState => ({ ...prevState, disabled: false }));
        setSubsidiesValue(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);


  useEffect(() => {
    if (!isAudit) {
      changePermanentCrops(plantationType?.value);
    }
  }, [plantationType?.value, edit]);

  useEffect(() => {
    if (!isAudit) {
      changeRented(rented?.value);
    }
  }, [rented?.value, edit]);

  useEffect(() => {
    if (!isAudit) {
      changeDateProcured(dateProcured);
    }
  }, [
    dateProcured.value,
    edit,
  ]);

  useEffect(() => {
    if (!isAudit) {
      calculateDepreciationValue();
    }
  }, [
    plantationType.value,
    dateProcured.value,
    acquisitionValue.value,
    depreciationRate.value,
  ]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingValue();
    }
  }, [
    openingValue.value,
    purchasedValue.value,
    investmentValue.value,
    soldValue.value,
    depreciationRate.value,
  ]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingArea();
    }
  }, [
    openingArea?.value,
    purchasedArea?.value,
    soldArea?.value,
  ]);

  const [depreciationRateCounter, setDepreciationRateCounter] = useState(0);

  useEffect(() => {
    if (!isAudit) {
      setDepreciationRateCounter(depreciationRate + 1);
      depreciationRateCounter > 1 && onObjectTypeChange(plantationType?.value);
    }
  }, [
    plantationType?.value,
    edit,
  ]);

  useEffect(() => {
    if (!isAudit) {
      checkClosingAndSold();
    }
  }, [
    closingArea?.value,
    soldArea?.value,
  ]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm'/>
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectAsyncField
                    pagination={false}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    api='plantation-types'
                    placeholder={t('pages.permanentCrops.placeholders.plantationType')}
                    label={t('pages.permanentCrops.plantationType')}
                    value={plantationType.value}
                    name='plantationType'
                    onChange={(e) => {
                      setShowErrors(true);
                      setPlantationType(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={plantationType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['plantationType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <DateField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholderText={t('pages.permanentCrops.placeholders.dateProcured')}
                    label={t('pages.permanentCrops.dateProcured')}
                    value={dateProcured.value}
                    name='dateProcured'
                    onChange={(e) => {
                      setDateProcured({
                        ...dateProcured,
                        value: e,
                      });
                      setShowErrors(true);
                    }}
                    disabled={dateProcured.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['dateProcured']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.purchasedArea')}
                    helpLabel={t('pages.permanentCrops.help.purchasedArea')}
                    placeholder={t('pages.permanentCrops.placeholders.purchasedArea')}
                    value={purchasedArea.value}
                    name='purchasedArea'
                    suffix='ha'
                    type={'double'}
                    onChange={(e) => {
                      setPurchasedArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchasedArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchasedArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.purchasedValue')}
                    helpLabel={t('pages.permanentCrops.help.purchasedValue')}
                    placeholder={t('pages.permanentCrops.placeholders.purchasedValue')}
                    value={purchasedValue.value}
                    name='purchasedValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setPurchasedValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchasedValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchasedValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.openingArea')}
                    helpLabel={t('pages.permanentCrops.help.openingArea')}
                    placeholder={t('pages.permanentCrops.placeholders.openingArea')}
                    value={openingArea.value}
                    name='openingArea'
                    suffix='ha'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.openingValue')}
                    helpLabel={t('pages.permanentCrops.help.openingValue')}
                    placeholder={t('pages.permanentCrops.placeholders.openingValue')}
                    value={openingValue.value}
                    name='openingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.rented')}
                    name='rented'
                    options={[{ value: 'true', label: '' }]}
                    value={rented?.value}
                    onChange={(e) => {
                      setRented(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={rented?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['rented']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.ownLandValuePercent')}
                    helpLabel={t('pages.permanentCrops.help.ownLandValuePercent')}
                    placeholder={t('pages.permanentCrops.placeholders.ownLandValuePercent')}
                    value={ownLandValuePercent.value}
                    name='ownLandValuePercent'
                    type={'number'}
                    onChange={(e) => {
                      setOwnLandValuePercent(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={ownLandValuePercent.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['ownLandValuePercent']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.rentIsPayed')}
                    name='rentIsPayed'
                    options={[{ value: 'true', label: '' }]}
                    value={rentIsPayed?.value}
                    onChange={(e) => {
                      setRentIsPaid(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={rentIsPayed?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['rentIsPayed']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <SelectAsyncField
                    pagination={false}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    api='plantation-types'
                    placeholder={t('pages.permanentCrops.placeholders.youngCropPlantationType')}
                    label={t('pages.permanentCrops.youngCropPlantationType')}
                    value={youngCropPlantationType.value}
                    name='youngCropPlantationType'
                    onChange={(e) => {
                      setShowErrors(true);
                      setYoungCropPlantationType(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={youngCropPlantationType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['youngCropPlantationType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.investmentValue')}
                    placeholder={t('pages.permanentCrops.placeholders.investmentValue')}
                    value={investmentValue.value}
                    name='investmentValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setInvestmentValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={investmentValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['investmentValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.soldArea')}
                    helpLabel={t('pages.permanentCrops.help.soldArea')}
                    placeholder={t('pages.permanentCrops.placeholders.soldArea')}
                    value={soldArea.value}
                    suffix='ha'
                    name='soldArea'
                    type={'double'}
                    onChange={(e) => {
                      setSoldArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  {/*<SelectConstField*/}
                  {/*  showErrors={showErrors}*/}
                  {/*  showRequiredError={showRequiredError}*/}
                  {/*  label={t('pages.permanentCrops.youngCropPlantationType')}*/}
                  {/*  placeholder={t('pages.permanentCrops.placeholders.youngCropPlantationType')}*/}
                  {/*  value={youngCropPlantationType.value}*/}
                  {/*  name="youngCropPlantationType"*/}
                  {/*  onChange={(e) => {*/}
                  {/*    setYoungCropPlantationType(prevState => ({*/}
                  {/*      ...prevState,*/}
                  {/*      value: e,*/}
                  {/*    }));*/}
                  {/*    setShowErrors(true)*/}
                  {/*  }}*/}
                  {/*  options={plantationTypeOptionList}*/}
                  {/*  disabled={youngCropPlantationType.disabled}*/}
                  {/*  styles={`${classes['formFieldsWidth']}`}*/}
                  {/*  validation={validation['youngCropPlantationType']}*/}
                  {/*  errorExplanations={errorExplanations}*/}
                  {/*  setErrorExplanations={setErrorExplanations}*/}
                  {/*  onSubmit={() => handleSubmit(false)}*/}
                  {/*  hasWriteExplanationPermission={!add && !edit}*/}
                  {/*  setSubmitDisabled={setSubmitDisabled}*/}
                  {/*/>*/}
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.soldValue')}
                    placeholder={t('pages.permanentCrops.placeholders.soldValue')}
                    value={soldValue.value}
                    name='soldValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSoldValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.accumulatedDepreciationValue')}
                    placeholder={t('pages.permanentCrops.placeholders.accumulatedDepreciationValue')}
                    value={accumulatedDepreciationValue.value}
                    name='accumulatedDepreciationValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setAccumulatedDepreciationValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={accumulatedDepreciationValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['accumulatedDepreciationValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.depreciationRate')}
                    placeholder={t('pages.permanentCrops.placeholders.depreciationRate')}
                    value={depreciationRate.value}
                    name='depreciationRate'
                    type={'number'}
                    onChange={(e) => {
                      setDepreciationRate(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={depreciationRate.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['depreciationRate']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.depreciationValue')}
                    placeholder={t('pages.permanentCrops.placeholders.depreciationValue')}
                    value={depreciationValue.value}
                    name='depreciationValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setDepreciationValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={depreciationValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['depreciationValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.subsidiesValue')}
                    placeholder={t('pages.permanentCrops.placeholders.subsidiesValue')}
                    value={subsidiesValue.value}
                    name='subsidiesValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSubsidiesValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={subsidiesValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['subsidiesValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.acquisitionArea')}
                    placeholder={t('pages.permanentCrops.placeholders.acquisitionArea')}
                    value={acquisitionArea.value}
                    name='acquisitionArea'
                    suffix='ha'
                    type={'double'}
                    onChange={(e) => {
                      setAcquisitionArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={acquisitionArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['acquisitionArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.acquisitionValue')}
                    placeholder={t('pages.permanentCrops.placeholders.acquisitionValue')}
                    value={acquisitionValue.value}
                    name='acquisitionValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setAcquisitionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={acquisitionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['acquisitionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    helpLabel={t('pages.permanentCrops.help.closingArea')}
                    label={t('pages.permanentCrops.closingArea')}
                    placeholder={t('pages.permanentCrops.placeholders.closingArea')}
                    value={closingArea.value}
                    name='closingArea'
                    suffix='ha'
                    type={'double'}
                    onChange={(e) => {
                      setClosingArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.closingValue')}
                    placeholder={t('pages.permanentCrops.placeholders.closingValue')}
                    value={closingValue.value}
                    name='closingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.permanentCrops.comment')}
                    placeholder={t('pages.permanentCrops.placeholders.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className='d-flex justify-content-center pt-3'>
              <div className='d-flex flex-column-reverse flex-md-row'>
                <CancelBtn
                  className='button cancel'
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={() => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/long-term-assets/permanent-crops`)
                      :
                      history.goBack();

                  }}
                />
                <SaveBtn
                  className='button save'
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={() => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};

export default PermanentCropsForm;
