export const calendar = {
  "notifications": "Podsjetnici",
    "reminders": "Podsjetnici",
    "seeAll": "Detaljni pregled",
    "January": "Januar",
    "February": "Februar",
    "March": "Mart",
    "April": "April",
    "May": "Maj",
    "June": "Jun",
    "July": "Jul",
    "August": "Avgust",
    "September": "Septembar",
    "October": "Oktobar",
    "November": "Novembar",
    "December": "Decembar",
    "0": "Ponedjeljak",
    "1": "Utorak",
    "2": "Srijeda",
    "3": "Četvrtak",
    "4": "Petak",
    "5": "Subota",
    "6": "Nedelja",
    "monday": "Ponedeljak",
    "addNotification": "Kreiranje podsjetnika",
    "addNewNotification": "Dodaj podsjetnik",
    "editNotification": "Izmijeni podsjetnik",
    "deleteNotification": "Brisanje podsjetnika",
    "date": "Datum",
    "time": "Vrijeme",
    "name": "Naziv podsjetnika",
    "description": "Opis",
    "typeNotification": "Tip podsjetnika",
    "channel": "Način obavještenja",
    "email": "Email",
    "application": "Aplikativno",
    "timeValidator": "Vrijeme mora biti između 07h i 19h",
    "noData": "Nemate podsjetnike za "
};

export const message = {
  "successAdd": "Uspješno dodavanje",
  "successEdit": "Uspješna izmjena",
  "successDelete": "Uspješno brisanje",
  "errorApi": "Došlo je do greške! Molimo Vas da pokušate ponovo!",
  "errorData": "Podaci nisu validni! Molimo Vas da ih provjerite!",
  "expirationTimeInvalid": "Vrijeme isteka mora biti veće",
}
