import { t } from 'react-switch-lang';

export const eng = localStorage.getItem('language') === 'eng';

const manufacturedProductsFields = [
  {
    value: 'productionManufacturedProduct',
    label: () => t('pages.manufacturedProducts.productionManufacturedProduct'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'production-manufactured-products',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: true
    },
    displayInLimits: true
  },
  {
    value: 'productionCode',
    label: () => t('pages.manufacturedProducts.productionCode'),
    type: 'select',
    options: {
      list: [],
      api: 'production-codes',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'area',
    label: () => t('pages.manufacturedProducts.area'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'irrigated',
    label: () => t('pages.manufacturedProducts.irrigated'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'otherManufacturedProduct',
    label: () => t('pages.manufacturedProducts.otherManufacturedProduct'),
    type: 'text',
    options: {},
    displayInLimits: false
  },
  {
    value: 'otherManufacturedProductApproved',
    label: () => t('pages.manufacturedProducts.otherManufacturedProductApproved'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.manufacturedProducts.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.manufacturedProducts.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'productionQuantity',
    label: () => t('pages.manufacturedProducts.productionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldQuantity',
    label: () => t('pages.manufacturedProducts.soldQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldValue',
    label: () => t('pages.manufacturedProducts.soldValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionQuantity',
    label: () => t('pages.manufacturedProducts.ownConsumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionValue',
    label: () => t('pages.manufacturedProducts.ownConsumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsQuantity',
    label: () => t('pages.manufacturedProducts.paymentInProductsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsValue',
    label: () => t('pages.manufacturedProducts.paymentInProductsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForAHQuantity',
    label: () => t('pages.manufacturedProducts.consumedForAHQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumedForAHValue',
    label: () => t('pages.manufacturedProducts.consumedForAHValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.manufacturedProducts.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.manufacturedProducts.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.manufacturedProducts.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default manufacturedProductsFields;