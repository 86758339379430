import React, { createContext, useContext, useEffect, useState } from 'react';

const WindowHeight = createContext({});

const WindowHeightProvider = ({ children }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <WindowHeight.Provider value={windowHeight}>{children}</WindowHeight.Provider>;
};

export const useWindowHeight = () => {
  return useContext(WindowHeight);
};

export default WindowHeightProvider;
