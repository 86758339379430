// helper function for setting the correct category format
export const defaultOption = (option, enabled = []) => {
  const lang = localStorage.getItem('language');

  return {
    value: option?.id,
    label: lang === 'eng' ? option?.nameEn : option?.name,
    disabled: enabled?.length > 0 ? !enabled.includes(option?.id) : false
  }
}

// function for handling all api requests
// accepts state setter, api call function and array of numbers which are ids of fields that should be enabled (if there are disabled fields)
export const handleFetch = async (setOptionsList, fetchFunction, enabled = []) => {
  try {
    const { data } = await fetchFunction();

    // maps fetched data to correct format
    setOptionsList([...data.map(option => defaultOption(option, enabled))]);
  } catch (e) {
    // console.log(e);
  }
}
