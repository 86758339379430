import { axiosGet } from '../axios';

export const getLabour = (id) => {
  return axiosGet(`labour/${id}`);
};

export const getWorkforceRoles = () => {
  return axiosGet('workforce-roles');
};

export const getWorkforceTypes = () => {
  return axiosGet('workforce-types');
};