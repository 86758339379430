import { t } from 'react-switch-lang';

export const genderEnum = [
  {
    value: '1',
    label: t('enums.enumGender.male'),
    disabled: false,
  },
  {
    value: '2',
    label: t('enums.enumGender.female'),
    disabled: false,
  },
];

export const agriculturalEducationEnum = [
  {
    value: '1',
    label: t('enums.enumAgriculturalEducation.practicalExperience'),
    disabled: false,
  },
  {
    value: '2',
    label: t('enums.enumAgriculturalEducation.elementaryEducation'),
    disabled: false,
  },
  {
    value: '3',
    label: t('enums.enumAgriculturalEducation.highSchoolUniversityEducation'),
    disabled: false,
  },
];