import { axiosGet } from '../axios';

export const getAllAgroHoldings = () => {
  return axiosGet('agro-holdings');
};

export const fetchSingleAgriculturalHolding = (id) => {
  return axiosGet(`agro-holdings/${id}`);
};

export const fetchAllAltitudes = () => {
  return axiosGet('altitudes');
};

export const fetchAllAreaConstraints = () => {
  return axiosGet('area-constraints');
};

export const fetchAllOffices = () => {
  return axiosGet('counties');
};

export const fetchAllCities = () => {
  return axiosGet('cities');
};

export const fetchAllEconomicSizes = () => {
  return axiosGet('economic-sizes');
};

export const fetchAllFarmTypes = () => {
  return axiosGet('farm-types');
};

export const fetchAllOtherGainfulActivities = () => {
  return axiosGet('other-gainful-activities');
};

export const fetchAllOwnershipTypes = () => {
  return axiosGet('ownership-types');
};

export const fetchAllProductionTypes = () => {
  return axiosGet('production-types');
};

export const fetchAllProtectedProductFarmTypes = () => {
  return axiosGet('protected-product-farm-types');
};

export const fetchAllTaxTypes = () => {
  return axiosGet('tax-types');
};
