import React, { useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../components/form/validationForm/ValidationForm';
import InputField from '../../../components/form/inputField/InputField';
import SaveBtn from '../../../components/buttons/SaveBtn';
import { axiosPost } from '../../../services/axios';
import classes from './Register.module.scss';
import Swal from 'sweetalert2';
import { getLanguage, setLanguage, t } from 'react-switch-lang';
import { useHistory } from 'react-router-dom';
import SelectConstField from '../../../components/form/selectField/SelectConstField';
import AgroLogo from '../../../assets/images/agro-logo.png';
import Government from '../../../assets/images/government.svg';
import Ministry from '../../../assets/images/ministry.svg';
import Chat from '../../../assets/images/chat.svg';
import { eng } from '../../../utils/const';
import LanguageEn from '../../../assets/images/navbar/language-en.svg';
import LanguageCg from '../../../assets/images/navbar/language-cg.svg';
import Logo from '../../../assets/images/logo-big.svg';
import LogoEn from '../../../assets/images/logo-en-big.svg';
import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import ContactUs from '../contactUs/ContactUs';
import { REGEX } from '../../../utils/validationDefaults';
import { useWindowWidth } from '../../../contexts/WindowWidth';
import Loader from '../../../components/loader/Loader';

const Register = () => {
  const formRef = useRef();
  const history = useHistory();
  const windowWidth = useWindowWidth();

  const [loading, setLoading] = useState(true);

  const [registerData, setRegisterData] = useState({});
  const [confirmPassword, setConfirmPassword] = useState();
  const [refreshConfirmPassword, setRefreshConfirmPassword] = useState(false);
  const [refreshLogin, setRefreshLogin] = useState(false);
  const [refreshEmail, setRefreshEmail] = useState(false);

  // changes password value inside validation file
  REGEX.currentPassword = registerData?.password;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const offices = [{
    id: 1,
    value: 'bijeloPolje',
    label: t('enums.enumOfficesExtended.bijeloPolje'),
  }, {
    id: 2,
    value: 'bar',
    label: t('enums.enumOfficesExtended.bar'),
  }, {
    id: 3,
    value: 'berane',
    label: t('enums.enumOfficesExtended.berane'),
  }, {
    id: 4,
    value: "cetinje",
    label: t('enums.enumOfficesExtended.cetinje'),
  }, {
    id: 5,
    value: "niksic",
    label: t('enums.enumOfficesExtended.niksic'),
  }, {
    id: 6,
    value: "pljevlja",
    label: t('enums.enumOfficesExtended.pljevlja'),
  }, {
    id: 7,
    value: "podgorica",
    label: t('enums.enumOfficesExtended.podgorica'),
  }];

  const [validation, setValidation] = useState({
    login: { type: 'string', required: { value: false }, min: { value: 3 }, max: { value: 50 }, matches: { value: 'USERNAME' } },
    firstName: { type: 'string', required: { value: true }, min: { value: 2 }, max: { value: 20 } },
    lastName: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 40 } },
    email: { type: 'string', required: { value: true }, matches: { value: 'EMAIL' } },
    office: { type: 'selectSingle', required: { value: true } },
    password: {
      type: 'string',
      required: { value: true },
      min: { value: 8 },
      max: { value: 100 },
      matches: { value: 'STRONGPASSWORD' },
    },
    confirmPassword: {
      type: 'string',
      required: { value: true },
      min: { value: 8 },
      max: { value: 100 },
      matches: { value: 'PASSWORD' },
    },
  });

  const submitData = async () => {
    const payload = {
      ...registerData,
      langKey: eng ? 'en' : 'sr',
      login: registerData?.login ? registerData?.login?.trim() : registerData?.email.split('@')[0],
      countyDTO: { id: registerData?.office?.id },
    };

    try {
      const response = await axiosPost('register', payload);
      if (response.status === 201) {
        // makes a popup that prompts user to go back to login page
        Swal.fire({
          text: t('register.success'),
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          history.push('/login');
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        // shows error when entered username already exists
        if (err.response.data?.errorKey === 'userexists') {
          setValidation({
            ...validation,
            login: { ...validation['login'], error: { value: true, messages: t('register.userExists') } },
          });
          setRefreshLogin(prevState => !prevState);
        }

        // shows error when entered email already exists
        if (err.response.data?.errorKey === 'emailexists') {
          setValidation({
            ...validation,
            email: { ...validation['email'], error: { value: true, messages: t('register.emailExists') } },
          });
          setRefreshEmail(prevState => !prevState);
        }

        formRef.current.submitForm();
      } else {
        Swal.fire({
          text: t('register.error'),
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  // dynamically sets username if user hasn't specified it
  useEffect(() => {
    if ((/^[a-z0-9]+([-._]?[a-z0-9]+)?@[a-z0-9]+(-?[a-z0-9]+)?\.[a-z]{2,}$/i).test(registerData?.email) && !registerData?.login) {
      setRegisterData(prevState => ({
        ...prevState,
        login: registerData?.email.split('@')[0],
      }));
    }
  }, [registerData?.email]);

  // watches confirmPassword field when changing password field
  useEffect(() => {
    setRefreshConfirmPassword(prevState => !prevState);
  }, [REGEX.PASSWORD]);

  useEffect(() => {
    setLoading(false);

    let isAuth = localStorage.getItem('jwt-token');
    if (!(isAuth === null || isAuth === undefined)) {
      history.push('/home');
    }
  }, []);

  const handleSetLanguage = (key) => {
    setLoading(true);

    setLanguage(key);
    localStorage.setItem('language', getLanguage());
    window.location.reload();
  };

  return loading ?
    <div
      className={`d-flex vw-100 vh-100 align-items-center justify-content-center`}
    >
      <Loader size={windowWidth > 450 ? 'lg' : 'sm'} />
    </div>
    :
    <div className={`d-flex vw-100 vh-100 align-items-center justify-content-center ${classes['contentWrapper']}`}>
      {isModalOpen && <ContactUs isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
      {windowWidth > 900 &&
        <div
          className={`${classes['contentWrapper__background']} w-50 h-100 d-flex flex-column justify-content-between align-items-center`}>
          <div className='pt-4'>
            <img src={AgroLogo} alt='Agricultural logo' width={'330px'} height={'auto'} />
          </div>
          <div className='d-flex w-75 pb-4 justify-content-between'>
            <img src={Ministry} alt='Ministry' />
            <img src={Government} alt='Government' />
          </div>
        </div>
      }
      <div
        className={`${windowWidth > 900 ? 'w-50' : 'w-100'} h-100 position-relative d-flex flex-column align-items-center ${classes['contentWrapper__container']}`}>
        <div className={`w-100 d-flex justify-content-between align-items-center pb-5 pt-4 ${windowWidth > 450 ? 'pl-5 pr-5' : 'pl-4 pr-4'}`}>
          <div className={`${classes['contentWrapper__back']}`} onClick={() => history.push('/login')}>
            <ArrowLeftOutlined className='mr-2' />
            {t('common.back')}
          </div>
          <div className={`d-flex justify-content-end ${classes['contentWrapper__actions']}`}>
            <img src={Chat} alt='Chat' onClick={() => setIsModalOpen(true)} className='pr-3 cursor' />
            <img src={eng ? LanguageCg : LanguageEn} alt='Language'
                 onClick={() => handleSetLanguage(eng ? 'mne' : 'eng')}
                 className='cursor' />
          </div>
        </div>
        <img src={eng ? LogoEn : Logo} alt='Logo' className={`${classes['contentWrapper__logo']}`} width={'183px'}
             height={'auto'} />
        <div className={`${classes['contentWrapper__welcome']}`}>{t('register.welcome')}</div>
        <div className={`${classes['contentWrapper__form']} ${windowWidth > 450 ? 'w-50' : 'w-75'} pb-4`}>
          <ValidationForm validation={validation} ref={formRef}>
            <InputField
              label={t('register.email')}
              placeholder={t('register.emailPlaceholder')}
              value={registerData?.email}
              name='email'
              groupField
              onChange={(e) => {
                setRegisterData(prevState => ({
                  ...prevState,
                  email: e,
                }));
                setValidation({
                  ...validation,
                  email: { ...validation['email'], error: { value: false } },
                });
              }}
              disabled={false}
              key={refreshEmail + 'email'}
            />
            <InputField
              label={t('register.login')}
              placeholder={t('register.loginPlaceholder')}
              value={registerData?.login}
              name='login'
              groupField
              onChange={(e) => {
                setRegisterData(prevState => ({
                  ...prevState,
                  login: e,
                }));
                setValidation({
                  ...validation,
                  login: { ...validation['login'], error: { value: false } },
                });
              }}
              disabled={false}
              key={refreshLogin + 'login'}
            />
            <InputField
              label={t('register.firstName')}
              placeholder={t('register.firstNamePlaceholder')}
              value={registerData?.firstName}
              name='firstName'
              groupField
              onChange={(e) => {
                setRegisterData(prevState => ({
                  ...prevState,
                  firstName: e,
                }));
              }}
              disabled={false}
            />
            <InputField
              label={t('register.lastName')}
              placeholder={t('register.lastNamePlaceholder')}
              value={registerData?.lastName}
              name='lastName'
              groupField
              onChange={(e) => {
                setRegisterData(prevState => ({
                  ...prevState,
                  lastName: e,
                }));
              }}
              disabled={false}
            />
            <SelectConstField
              label={t('register.office')}
              placeholder={t('register.officePlaceholder')}
              value={registerData?.office}
              name='office'
              groupField
              onChange={(e) => {
                setRegisterData(prevState => ({
                  ...prevState,
                  office: e,
                }));
              }}
              options={offices}
              disabled={false}
            />
            <InputField
              label={t('register.password')}
              placeholder={t('register.passwordPlaceholder')}
              value={registerData?.password}
              name='password'
              type='password'
              groupField
              onChange={(e) => {
                setRegisterData(prevState => ({
                  ...prevState,
                  password: e,
                }));
              }}
              disabled={false}
            />
            <InputField
              label={t('register.confirmPassword')}
              placeholder={t('register.passwordPlaceholder')}
              value={confirmPassword}
              name='confirmPassword'
              type='password'
              groupField
              onChange={(e) => {
                setConfirmPassword(e);
              }}
              disabled={false}
              key={refreshConfirmPassword + 'confirmPassword'}
            />
            <div className='d-flex w-100 justify-content-center'>
              <SaveBtn
                loaderOnDisabled={false}
                loader={false}
                label={t('register.enter')}
                className={`${classes.submit} pl-5 pr-5`}
                onClick={() => {
                  formRef.current.submitForm(submitData);
                }}
              />
            </div>
          </ValidationForm>
        </div>
      </div>
    </div>
};

export default Register;