import React, { useEffect, useState } from 'react';
import classes from './ExplainForm.module.scss';
import Chat from '../../assets/images/icons/explain.svg';
import { t } from 'react-switch-lang';
import { hasValue } from '../../utils/const';
import { readMessages } from '../../services/errorExplanationsChat/errorExplanationsChatApi';

const ExplainError = ({ explanationError, openExplainModal, errorExplanationsArray, setErrorExplanationsArray, onSubmit }) => {

  const [isExplanationFilled, setIsExplanationFilled] = useState(false);

  const [refreshExplain, setRefreshExplain] = useState(false);

  const [field, setField] = useState(undefined);

  const lang = localStorage.getItem('language');

  useEffect(() => {
    const isExplain = errorExplanationsArray.find(explainError => parseInt(explainError.dvcNumber) === parseInt(explanationError.dvcNumber)
      && explainError.field === explanationError.field);

    setField(isExplain);

    setIsExplanationFilled(
      (isExplain !== undefined && isExplain?.hasOwnProperty('explanation') && hasValue(isExplain.explanation)) ||
      isExplain !== undefined && isExplain?.hasOwnProperty('dvcDescription'));

    setRefreshExplain(!refreshExplain);
  }, [errorExplanationsArray]);

  return (
    <div
      className={`${classes['formFieldsWidth']} ${isExplanationFilled ? 'validationExplainView' : 'validationExplain validation-error'}`}
    >
      <div className="pb-2">
        {(() => {
          if (hasValue(field) && field?.message)
            return field.message;

          if (hasValue(field) && field?.dvcDescription)
            return lang === 'eng' ? field.dvcDescriptionEn : field.dvcDescription;

          if (hasValue(explanationError) && explanationError?.message)
            return explanationError.message;

          if (hasValue(explanationError) && explanationError?.dvcDescription)
            return lang === 'eng' ? explanationError.dvcDescriptionEn : explanationError.dvcDescription;
        })()}
      </div>
      <div className="d-flex">
        <div className="d-flex cursor explanation"
             onClick={(e) => {
               openExplainModal(explanationError);
               readMessages(explanationError?.id).then(() => {

                 let errorExplanationsArrayTemp = errorExplanationsArray;

                 for (let i = 0; i < errorExplanationsArrayTemp.length; i++) {
                   if (errorExplanationsArrayTemp[i].dvcNumber === explanationError.dvcNumber && errorExplanationsArrayTemp[i].field === explanationError.field) {
                     errorExplanationsArrayTemp[i].unreadMessagesCount = 0;
                   }
                 }
                 setErrorExplanationsArray([...errorExplanationsArrayTemp]);

                 setTimeout(() => {
                   onSubmit();
                 }, 10);
               });
             }}>
          <img src={Chat} alt={'Chat'} className="mr-1"/>
          <div className="text-uppercase">{
            isExplanationFilled > 0 ?
              t('messages.viewExplain')
              :
              t('messages.insertExplain')
          }</div>
          {explanationError?.unreadMessagesCount > 0 &&
            <div className={`${classes['circle']}`}/>
          }
        </div>
      </div>
    </div>
  );
};

export default ExplainError;