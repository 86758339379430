import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const moneyAnimalProductionFields = [
  {
    value: 'livestockExpenseType',
    label: () => t('pages.animalProductionExpenses.livestockExpenseType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'livestock-expense-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.animalProductionExpenses.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.animalProductionExpenses.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'otherLivestockExpense',
    label: () => t('pages.animalProductionExpenses.otherLivestockExpense'),
    type: 'text',
    options: {},
    displayInLimits: false
  },
  {
    value: 'otherLivestockExpenseApproved',
    label: () => t('pages.animalProductionExpenses.otherLivestockExpenseApproved'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'purchaseQuantity',
    label: () => t('pages.animalProductionExpenses.purchaseQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseValue',
    label: () => t('pages.animalProductionExpenses.purchaseValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumptionQuantity',
    label: () => t('pages.animalProductionExpenses.consumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumptionValue',
    label: () => t('pages.animalProductionExpenses.consumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.animalProductionExpenses.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.animalProductionExpenses.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.animalProductionExpenses.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default moneyAnimalProductionFields;