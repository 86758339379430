import React, { useContext } from 'react';
import { t } from 'react-switch-lang';
import SaveBtn from '../../../../components/buttons/SaveBtn';
import { useHistory, useParams } from 'react-router-dom';
import { columns } from './utils/table/tableColumns';
import { onDelete } from './utils/table/deleteFromTable';
import UserData from '../../../../contexts/UserData';
import LivestockTable from '../../../../components/table/livestockTable/LivestockTable';
import { useShowDeleted } from '../../../../contexts/ShowDeletedEntries';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import AgriculturalHoldingData from '../../../../contexts/AgriculturalHoldingData';
import { hasValue, readOnly } from '../../../../utils/const';
import { useWindowWidth } from '../../../../contexts/WindowWidth';

export const Goats = () => {
  let { ahid } = useParams();
  const history = useHistory();

  const windowWidth = useWindowWidth();

  const user = useContext(UserData);
  const { showDeleted, toggleShowDeleted } = useShowDeleted();

  const agriculturalData = useContext(AgriculturalHoldingData);
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const onUpdate = (record) => {
    history.push({
      pathname: `/agricultural-holding/${ahid}/livestock/goats/${record.id}`,
      state: {
        add: false,
        edit: true,
      },
    });
  };

  const onRowClick = (record) => {
    history.push({
      pathname: `/agricultural-holding/${ahid}/livestock/goats/${record.id}`,
      state: {
        add: false,
        edit: false,
        isDeleted: record.isDeleted,
      },
    });
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap justify-content-end mb-5">
        {user.isAdmin && (
          <SaveBtn
            label={showDeleted ? t('common.hideDeleted') : t('common.showDeleted')}
            className="mt-2 mr-3 grayText"
            onClick={() => toggleShowDeleted()}
            thin={true}
            icon={true}
            iconAntd={showDeleted ? EyeInvisibleOutlined : EyeOutlined}
          />
        )}
        {(user.isAdmin || user.isCollector) && !isLocked && !readOnly && (
          <SaveBtn
            label={t('common.addOwnedGoat')}
            className={`${windowWidth < 380 && 'mt-2'} mr-3`}
            onClick={() =>
              history.push({
                pathname: `/agricultural-holding/${ahid}/livestock/goats/add`,
                state: {
                  edit: true,
                  add: true,
                  fattening: false,
                },
              })
            }
          />
        )}
      </div>
      <LivestockTable
        api={`goat-owned-with-cat/ah/${ahid}`}
        query={`${
          showDeleted ? 'includeDeleted=true&' : 'includeDeleted=false&'
        }agroHoldingId.equals=${ahid}`}
        columns={columns}
        methods={{ onDelete, onUpdate }}
        className={'table-hover'}
        onClickRow={onRowClick}
        user={user}
        isLocked={isLocked || readOnly}
        group={'livestock'}
        historyEntity={'goatOwned'}
      />
    </div>
  );
};
