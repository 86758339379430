import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import DefaultLayout from '../../components/layout/default/DefaultLayout.jsx';
import AuthLayout from '../../components/layout/auth/AuthLayout';

export default function RouteWrapper({
                                       component: Component,
                                       path,
                                       isPrivate,
                                       isRestricted,
                                       previous,
                                       pageTitle,
                                       authorized,
                                       setIsLoading,
                                       resourceData,
                                       routeHidden,
                                       authorisedRoles,
                                       hasBackButton,
                                       backLink,
                                       ...rest
                                     }) {
  const Layout = isPrivate ? AuthLayout : DefaultLayout;


  /**
   * If not included on both previous cases, redirect users to the desired route.
   */

  const { search } = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (routeHidden === true) {
          return <>
            {setIsLoading()}
            <Redirect from={path} to="/404"/>
          </>;
        } else if (authorized) {
          return <Layout previous={previous} pageTitle={pageTitle} hasBackButton={hasBackButton} backLink={backLink}>
            <Component
              {...props}
              props={props}
              previous={previous}
              pageTitle={pageTitle}
            />
          </Layout>;
        } else {
          return <>
            {setIsLoading()}
            <Redirect from={path} to={Boolean(localStorage.getItem('jwt-token')) ? '/403' : { pathname: '/login', state: { link: path + "" + search } }}/>
          </>;
        }
      }}/>
  );
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
