import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SelectConstField from '../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getProductionPlantProducts,
  getUnfinishedProducts,
} from '../../../../../services/production/unfinishedProducts/unfinishedProductsApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './UnfinishedProductsForm.module.scss';
import { Toast } from '../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import UserData from '../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import Loader from '../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const UnfinishedProductsForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const [unfinishedProducts, setUnfinishedProducts] = useState();

  const validationInitial = {
    productionPlantProduct: { type: 'selectSingle', required: { value: true } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    value: { type: 'number', required: { value: true } },
    area: { type: 'number', required: { value: true } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  const [productionPlantProduct, setProductionPlantProduct] = useState({ value: undefined, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [value, setValue] = useState({ value: 0, disabled: !edit });
  const [area, setArea] = useState({ value: 0, disabled: !edit });

  // Enums
  const [productionPlantProductOptionsList, setProductionPlantProductOptionsList] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/
  // Validation fields for resetting api errors


  /***** END DEPENDANT VALIDATION *****/

  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'unfinishedProduct', unfinishedProducts, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(productionPlantProduct.value?.value && { productionPlantProduct: { id: parseInt(productionPlantProduct.value?.value) } }),
        ...(hasValue(value?.value) && { value: parseFloatCustom(value.value) }),
        ...(hasValue(area?.value) && { area: parseFloatCustom(area.value) }),
        ...(comment?.value && { comment: comment.value }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`unfinished-products?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.unfinishedProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`unfinished-products/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.unfinishedProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };

  /*****END SUBMIT FORM *****/

  const fetchUnfinishedProduct = async () => {
    try {
      const { data } = await getUnfinishedProducts(props.match.params.id);
      setUnfinishedProducts(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option, disabled = false) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: disabled,
    };
  };

  const fetchProductionPlantProducts = async () => {
    try {
      const { data } = await getProductionPlantProducts();
      let productionPlantProductsTemp = [];
      const allowedOptions = [9, 21, 24, 25, 30, 33, 41, 44, 45, 47, 48, 54, 58, 95];
      for (let productionPlantProduct of data) {
        allowedOptions.includes(parseInt(productionPlantProduct.id)) && productionPlantProductsTemp.push(
          defaultOption(
            productionPlantProduct,
            false),
        );
      }

      // productionPlantProductsTemp[28].disabled = false;
      // productionPlantProductsTemp[29].disabled = false;
      // productionPlantProductsTemp[30].disabled = false;
      // productionPlantProductsTemp[31].disabled = false;
      // productionPlantProductsTemp[33].disabled = false;
      // productionPlantProductsTemp[34].disabled = false;
      // productionPlantProductsTemp[37].disabled = false;
      // productionPlantProductsTemp[40].disabled = false;
      // productionPlantProductsTemp[46].disabled = false;
      // productionPlantProductsTemp[51].disabled = false;
      // productionPlantProductsTemp[57].disabled = false;
      // productionPlantProductsTemp[59].disabled = false;
      // productionPlantProductsTemp[60].disabled = false;
      // productionPlantProductsTemp[65].disabled = false;

      setProductionPlantProductOptionsList([...productionPlantProductsTemp]);
    } catch (e) {
    }
  };

  useEffect(() => {
    fetchProductionPlantProducts();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchUnfinishedProduct().then(unfinishedProducts => {
          setProductionPlantProduct(prevState => ({
            ...prevState,
            value: defaultOption(unfinishedProducts?.productionPlantProduct),
          }));
          setComment(prevState => ({ ...prevState, value: unfinishedProducts?.comment }));
          setValue(prevState => ({ ...prevState, value: parseDouble(unfinishedProducts?.value) }));
          setArea(prevState => ({ ...prevState, value: parseDouble(unfinishedProducts?.area) }));
          setErrorExplanations(unfinishedProducts?.errorExplanations ? unfinishedProducts?.errorExplanations : []);

          let explanations = unfinishedProducts?.errorExplanations ? unfinishedProducts?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'unfinishedProduct', unfinishedProducts, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        setProductionPlantProduct(prevState => ({
          ...prevState,
          value: defaultOption(data?.productionPlantProduct),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setValue(prevState => ({ ...prevState, value: parseDouble(data?.value) }));
        setArea(prevState => ({ ...prevState, value: parseDouble(data?.area) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'unfinishedProduct', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setProductionPlantProduct(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setValue(prevState => ({ ...prevState, disabled: false }));
        setArea(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    label={t('pages.unfinishedProducts.productionPlantProduct')}
                    placeholder={t('pages.unfinishedProducts.placeholder.productionPlantProduct')}
                    value={productionPlantProduct.value}
                    name='productionPlantProduct'
                    onChange={(e) => {
                      setProductionPlantProduct(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={productionPlantProductOptionsList}
                    disabled={productionPlantProduct.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionPlantProduct']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.unfinishedProducts.area')}
                    placeholder={t('pages.unfinishedProducts.placeholder.area')}
                    value={area.value}
                    name='area'
                    type={'double'}
                    suffix={'ha'}
                    onChange={(e) => {
                      setArea({
                        ...area,
                        value: e,
                      });
                    }}
                    disabled={area.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['area']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    label={t('pages.unfinishedProducts.value')}
                    placeholder={t('pages.unfinishedProducts.placeholder.value')}
                    value={value.value}
                    suffix='€'
                    name='value'
                    type={'double'}
                    groupField
                    onChange={(e) => {
                      setValue({
                        ...value,
                        value: e,
                      });
                    }}
                    disabled={value.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['value']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <TextareaField
                    label={t('pages.animalProducts.comment')}
                    placeholder={t('pages.animalProducts.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment({
                        ...comment,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['comment']: { ...validation['comment'], error: { value: false } },
                      });
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className='button cancel'
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/production/unfinished-products`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className='button save'
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};

export default UnfinishedProductsForm;
