import React, { useEffect, useRef, useState } from 'react';
import FieldContainer from '../fieldContainer/FieldContainer';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import classes from './InputField.module.scss';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { checkIfExplanationValid, validate } from '../../../utils/validationDynamic';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import useDebounce from '../../../hooks/debounceHook/debounceHook';
import useDidMountEffect from '../../../app/hooks/useDidMountEffect';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

// <InputField
//                     label="Input field"
//                     value={value}
//                     name="input-field"
//                     onChange={(e) => {
//                         setValue(e)
//                     }}/>

const InputField = (
  {
    name,
    label,
    helpLabel,
    showErrors,
    showRequiredError = false,
    value,
    onChange,
    setSubmitDisabled,
    onSubmit = () => {
    },
    errorExplanations,
    setErrorExplanations = () => {
    },
    hasWriteExplanationPermission = true,
    validation = { type: '' },
    disabled = false,
    type = 'text',
    placeholder,
    suffix = false,
    chat = false,
    suffixImage,
    onClickSuffixImage = () => {
    },
    onEnter = () => {
    },
    setError,
    styles,
    noMin = false,
    ...rest
  }) => {
  let uniqueId = uniqid();
  let componentName = name ? name : uniqueId;

  const ref = useRef();

  const redirectPrompt = useRedirectPrompt();

  const [messageData, setMessageData] = useState(validate({ ...validation, required: { value: !disabled && validation.required.value } }, value));
  const [isDirty, setIsDirty] = useState(false);

  const prevVal = usePrevious(value);
  const prevValidation = usePrevious({ ...validation, required: { value: !disabled && validation.required.value } });

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    // on component unmounting return states to initial
    return () => {
      setMessageData({});
    };
  }, []);

  // START maintaining focus of input after api errors trigger form rerender
  const [active, setActive] = useState(document.activeElement);

  const handleFocusIn = (e) => {
    setActive(document.activeElement);
  };

  useEffect(() => {
    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, []);
  // END maintaining focus of input after api errors trigger form rerender

  useEffect(() => {
    // eslint-disable-next-line
    if ((!compareObjects(prevVal, value)) || (!compareObjects({
      ...validation,
      required: { value: !disabled && validation.required.value },
    }, prevValidation))) {
      let errorObject = validate({ ...validation, required: { value: !disabled && validation.required.value } }, value);
      if (errorObject?.required?.errorType === 'required' || errorObject?.apiErrors?.errorType === 'apiError' || errorObject?.errors?.errorType === 'error') {
        setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
      } else {
        if (!checkIfExplanationValid(errorObject)) {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
        } else {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: false }));
        }
      }
      setMessageData(errorObject);
    }
    // eslint-disable-next-line
  }, [value, validation, disabled]);

  const debouncedTerm = useDebounce(value, 300);

  const submitField = () => {
    !chat && onSubmit().then(() => {
      document.getElementsByName(active.name)[0].focus();
    });
  };

  useDidMountEffect(() => {
    submitField();
    // eslint-disable-next-line
  }, [debouncedTerm]);

  useEffect(() => {
    ref.current.addEventListener('keydown', (e) => {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }, []);

  // checks if double is negative value and trims the number to have only one minus character
  const handleNegativeValue = (double) => {
    return double.toString().charAt(0) === '-' ?
      '-' + double.toString().replaceAll('-', '')
      :
      double.toString().replaceAll('-', '')
  }

  // transform for field whose value is calculated, for example closing value
  const isInt = (n) => {
    return parseInt(n) === n;
  };

  const doubleWithComma = (double) => {
    return double.toString().replace('.', ',');
  };

  const toDouble = (value) => {
    return value === 0 ? '0,00' : doubleWithComma(value);
  };

  return (
    <FieldContainer
      label={label}
      componentName={componentName}
      showErrors={showErrors}
      helpLabel={helpLabel}
      required={
        !disabled && validation.required.value
      }
      additionalMessageData={messageData}
      customClasses={`${styles}`}
      errorExplanations={errorExplanations}
      setErrorExplanations={setErrorExplanations}
      hasWriteExplanationPermission={hasWriteExplanationPermission}
      onSubmit={onSubmit}
      showRequiredMessage={(isDirty || showRequiredError)}
      opened={opened}
      setOpened={setOpened}
      {...rest}
    >
      <div className={classes['suffix-container']}>
        <input
          disabled={disabled}
          autoComplete="off"
          ref={ref}
          type={type === 'text' ? 'text' : type === 'password' ? 'password' : type}
          className={`
                    ${classes['input-field']}
                    ${disabled ? classes['disabled'] : ''}
                    ${!chat && showErrors && messageData?.errors?.errorType !== 'noErrors' ? classes['error'] : ''}
                    ${!chat && (isDirty || showRequiredError) && messageData?.required?.errorType !== 'noErrors' ? classes['error'] : ''}
                `}
          name={componentName}
          value={(value !== null && value !== undefined) ? !noMin && value < 0 ? 0 : !isInt(value) && !noMin ? doubleWithComma(value) : type === 'double' ? toDouble(value) : value : ''} // setting value to 0 if calculation negative
          onBlur={() => {
            onSubmit();
            setIsDirty(true);
            redirectPrompt.setIsDirty(true);
          }}
          onKeyPress={(event) => {
            // allow only numbers
            if (type === 'number' && !/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
            // allow only numbers and comma
            if (type === 'double' && !/[0-9,-]/.test(event.key)) {
              event.preventDefault();
            }

            // for explanations chat
            if (event.key === 'Enter') {
              event.preventDefault();
              onEnter();
            }
          }}
          onWheelCapture={e => {
            e.target.blur();
          }}
          onChange={(e) => {
            if ((type === 'number' && /^[0-9,-]*$/.test(e.target.value)) || (type === 'double' && /^[0-9,-]*$/.test(e.target.value)) || (type !== 'number' && type !== 'double')) { // this is for paste
              onChange(type === 'double' ?
                !noMin ?
                  e.target.value.toString().split(',', 2).map((el, i) => i ? el.split('').slice(0, 2).join('') : el).join(',').replace('-', '')
                  :
                  handleNegativeValue(e.target.value).split(',', 2).map((el, i) => i ? el.split('').slice(0, 2).join('') : el).join(',')
                :
                e.target.value.replace('-', ''));
            }
            setIsDirty(true);
            redirectPrompt.setIsDirty(true);
          }}
          onDoubleClick={(e) => e.target.select()}
          step={type === 'number' ? '1' : type === 'double' ? '0.01' : undefined}
          placeholder={placeholder ? !disabled && placeholder : !disabled && t('common.selectValue')}
          title={placeholder ? placeholder : t('common.insertValue')}
          onClick={(e) => {
            setActive(ref.current);
            // e.target.focus();
            // value === 0 && e.target.select();
            // !(type === 'number' || type === 'double') && e.target.setSelectionRange(e.target.value.length, e.target.value.length);
          }}
          id={componentName}
          min={!noMin && 0}
        />
        {suffix && <div className={classes['suffix']}>{suffix}</div>}
        {suffixImage && <div className={classes['suffix']}><img src={suffixImage} alt={'Suffix image'} onClick={() => {
          onClickSuffixImage();
        }}/></div>}
      </div>
    </FieldContainer>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  validation: PropTypes.object,
  setError: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  type: PropTypes.oneOf(['text', 'number', 'double', 'password']),
};

export default InputField;
