
import React, {useEffect, useRef, useState} from 'react';
import {axiosPost, axiosPut} from "../../../../../services/axios";
import Toast from "../../../../toast/Toast";
import {t} from "react-switch-lang";
import ValidationForm from "../../../../form/validationForm/ValidationForm";
import InputField from "../../../../form/inputField/InputField";
import SaveBtn from "../../../../buttons/SaveBtn";
import CancelBtn from "../../../../buttons/CancelBtn";

const FrCategoryForm = ({ data, type, updateTable, closeModal, isDisabled, api, title}) => {

  const formRef = useRef();

  const initialData = {
    name: '',
    nameEn: '',
    sortVal: '',
    frCategory: null
  };
  const [changedData, setChangedData] = useState(initialData);

  const validation = {
    name: { type: 'string', required: { value: true }, min: { value: 3 } },
    nameEn: { type: 'string', required: { value: true }, min: { value: 3 } },
    sortVal: { type: 'number', required: { value: true }, min: { value: 1 } },
    frCategory: { type: 'number', required: { value: false }, min: { value: 1 }, max: { value: 100000 } },
  }

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData
      };

      try {
        const response = await axiosPost(api, payload);
        if (response.status === 200 || response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.message.successAdd') + `${title}`,
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData
      };

      try {
        const response = await axiosPut(`${api}/${data.id}`, payload);
        if (response.status === 200 || response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.message.successEdit') + `${title}`,
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        name: data.name,
        nameEn: data.nameEn,
        sortVal: data.sortVal,
        frCategory: data.frCategory
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <InputField
          label={t('administration.enums.columns.name')}
          placeholder={t('administration.enums.placeholders.name')}
          value={changedData.name}
          name='name'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, name: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.nameEn')}
          placeholder={t('administration.enums.placeholders.nameEn')}
          value={changedData.nameEn}
          name='nameEn'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, nameEn: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.sortVal')}
          placeholder={t('administration.enums.placeholders.sort')}
          value={changedData.sortVal}
          name='sortVal'
          type='number'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, sortVal: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.frCategory')}
          placeholder={t('administration.enums.placeholders.frCategory')}
          value={changedData.frCategory}
          name='frCategory'
          type='number'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, frCategory: e, });
          }}
          disabled={isDisabled}
        />
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)} />
        </div>
      )}
    </>
  );
};

export default FrCategoryForm;
