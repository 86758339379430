import React, { createContext, useContext, useState } from 'react';

const ShowDeletedEntries = createContext({});

const ShowDeletedEntriesProvider = ({ children }) => {
  const [showDeleted, setShowDeleted] = useState(false);

  const toggle = () => {
    setShowDeleted((prevState) => !prevState);
  };

  const data = {
    showDeleted: showDeleted,
    toggleShowDeleted: () => toggle(),
  };

  return <ShowDeletedEntries.Provider value={data}>{children}</ShowDeletedEntries.Provider>;
};

export const useShowDeleted = () => {
  return useContext(ShowDeletedEntries);
};

export default ShowDeletedEntriesProvider;
