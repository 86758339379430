import React, {useEffect, useRef, useState} from 'react';
import {axiosPost, axiosPut} from "../../../../../../services/axios";
import Toast from "../../../../../../components/toast/Toast";
import {t} from "react-switch-lang";
import ValidationForm from "../../../../../../components/form/validationForm/ValidationForm";
import InputField from "../../../../../../components/form/inputField/InputField";
import SaveBtn from "../../../../../../components/buttons/SaveBtn";
import CancelBtn from "../../../../../../components/buttons/CancelBtn";
import { hasValue, parseFloatCustom } from '../../../../../../utils/const';

const MineralFertilizerTypeForm = ({data, type, updateTable, closeModal, isDisabled}) => {

  const formRef = useRef();

  const initialData = {
    name: '',
    nameEn: '',
    kPercent: '',
    sortVal: '',
    pPercent: '',
    nPercent: ''
  };
  const [changedData, setChangedData] = useState(initialData);

  const validation = {
    name: {type: 'string', required: {value: true}, min: {value: 3}},
    nameEn: {type: 'string', required: {value: true}, min: {value: 3}},
    kPercent: {type: 'number', required: {value: true}, min: {value: 1}},
    pPercent: {type: 'number', required: {value: true}, min: {value: 1}},
    sortVal: {type: 'number', required: {value: true}, min: {value: 1}},
    nPercent: {type: 'number', required: {value: true}, min: {value: 1}}
  }

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData,
        ...(hasValue(changedData?.kPercent) && { kPercent: parseFloatCustom(changedData.kPercent) }),
        ...(hasValue(changedData?.pPercent) && { pPercent: parseFloatCustom(changedData.pPercent) }),
        ...(hasValue(changedData?.nPercent) && { nPercent: parseFloatCustom(changedData.nPercent) }),
      };

      try {
        const response = await axiosPost('mineral-fertilizer-types', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.mineralFertilizerType.successAdd'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData,
        ...(hasValue(changedData?.kPercent) && { kPercent: parseFloatCustom(changedData.kPercent) }),
        ...(hasValue(changedData?.pPercent) && { pPercent: parseFloatCustom(changedData.pPercent) }),
        ...(hasValue(changedData?.nPercent) && { nPercent: parseFloatCustom(changedData.nPercent) }),
      };

      try {
        const response = await axiosPut(`mineral-fertilizer-types/${data.id}`, payload);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.mineralFertilizerType.successEdit'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        name: data.name,
        nameEn: data.nameEn,
        kPercent: data.kPercent,
        nPercent: data.nPercent,
        sortVal: data.sortVal,
        pPercent: data.pPercent
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <InputField
          label={t('administration.enums.columns.name')}
          placeholder={t('administration.enums.placeholders.name')}
          value={changedData.name}
          name='name'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, name: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.nameEn')}
          placeholder={t('administration.enums.placeholders.nameEn')}
          value={changedData.nameEn}
          name='nameEn'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, nameEn: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.sortVal')}
          placeholder={t('administration.enums.placeholders.sort')}
          value={changedData.sortVal}
          name='sortVal'
          type='number'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, sortVal: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.kPercent')}
          placeholder={t('administration.enums.placeholders.kPercent')}
          value={changedData.kPercent}
          name='kPercent'
          type='double'
          step='2'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, kPercent: e,});
          }}
          disabled={isDisabled}
        />  <InputField
          label={t('administration.enums.columns.pPercent')}
          placeholder={t('administration.enums.placeholders.pPercent')}
          value={changedData.pPercent}
          name='pPercent'
          type='double'
          step='2'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, pPercent: e,});
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.nPercent')}
          placeholder={t('administration.enums.placeholders.nPercent')}
          value={changedData.nPercent}
          name='nPercent'
          type='double'
          step='2'
          groupField
          onChange={(e) => {
            setChangedData({...changedData, nPercent: e,});
          }}
          disabled={isDisabled}
        />
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)}/>
        </div>
      )}
    </>
  );
};

export default MineralFertilizerTypeForm;
