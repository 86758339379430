import React, { useContext, useEffect, useState } from 'react';
import { columns } from './utils/tableData';
import UserData from '../../../../../contexts/UserData';
import LockingErrorsTable from '../../../../../components/table/lockingErrorsTable/LockingErrorsTable';
import { useParams } from 'react-router-dom';
import { fetchAgriculturalHoldingErrorExplanationsStatistics } from '../../../../../services/agriculturalHolding/agriculturalHoldingApi';
import './LockingErrors.scss';
import { t } from 'react-switch-lang';
import { fetchSingleAgriculturalHolding } from '../../../../../services/agriculturalHoldings/agriculturalHoldingsApi';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import LockingExplanation from '../../../../../contexts/LockingExplanation';
import { useWindowWidth } from '../../../../../contexts/WindowWidth';

const LockingErrors = () => {

  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);

  const user = useContext(UserData);

  const windowWidth = useWindowWidth();

  const [statistics, setStatistics] = useState([]);
  const [approved, setApproved] = useState({});
  const [denied, setDenied] = useState({});
  const [pending, setPending] = useState({});

  const lockingExplanation = useContext(LockingExplanation);

  const onApprove = async (id) => {
  };

  const onDecline = async (id) => {
  };

  const onView = (explainError) => {
  };

  const onRowClick = (explainError) => {
  };

  const fetchStatistics = async () => {
    const { data } = await fetchAgriculturalHoldingErrorExplanationsStatistics(ahid);

    function getCountByStatus(status) {
      return data.find(el => el.type === status) === undefined ? 0 : data.find(el => el.type === status).number;
    }

    setPending(getCountByStatus('PENDING'));
    setDenied(getCountByStatus('DENIED'));
    setApproved(getCountByStatus('APPROVED'));
    setStatistics(data);
  };

  useEffect(() => {
    fetchStatistics();
  }, [lockingExplanation?.preLockExplanation]);

  useEffect(() => {
    if (ahid) {
      fetchSingleAgriculturalHolding(ahid)
        .then((r) => {
          agriculturalData.setAgriculturalHoldingData(r.data);
        })
        .catch(() => {
          agriculturalData.setAgriculturalHoldingData({});
        });
    } else {
    }
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className='d-flex flex-wrap mb-5'>
        <div className='pending mb-1'>
          <span className='mr-1'>{t('administration.lockingErrors.pending').toUpperCase()}</span>
          {'(' + pending + ')'}
        </div>
        <div className='denied mb-1'>
          <span className='mr-1'>{t('administration.lockingErrors.denied').toUpperCase()}</span>
          {'(' + denied + ')'}
        </div>
        <div className='approved mb-1'>
          <span className='mr-1'>{t('administration.lockingErrors.approved').toUpperCase()}</span>
          {'(' + approved + ')'}
        </div>
      </div>
      <LockingErrorsTable
        api={`agro-holdings/lock/${ahid}`}
        columns={columns(windowWidth)}
        methods={{ onDecline, onApprove, onView }}
        className={'table-hover'}
        onClickRow={onRowClick}
        user={user}
        refresh={lockingExplanation?.preLockExplanation}
      />
    </div>
  );
};

export default LockingErrors;