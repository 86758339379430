import Swal from 'sweetalert2';
import './prompt.scss';

const Prompt = Swal.mixin({
  showCancelButton: true,
  showCloseButton: false,
  // closeButtonHtml: `<img src=${CancelImg} alt="close"/>`,
  buttonsStyling: false,
  reverseButtons: true,
  showLoaderOnConfirm: false,
  allowOutsideClick: false,
  customClass: {
    header: 'm-0 pt-4 pb-3 pl-3 pr-3 align-items-start border',
    title: 'h5',
    confirmButton: 'button delete p-2 ml-2 align-items-center d-flex',
    cancelButton: 'button p-2',
    actions: 'modals-footer align-items-end pb-4',
    content: 'modals-body text-left border-bottom-1 p-4',
    popup: 'p-0',
  },
});

export default Prompt;
