import {
  fetchAllAltitudes,
  fetchAllAreaConstraints,
  fetchAllCities,
  fetchAllOffices,
  fetchAllEconomicSizes,
  fetchAllFarmTypes,
  fetchAllOtherGainfulActivities,
  fetchAllOwnershipTypes,
  fetchAllProductionTypes,
  fetchAllProtectedProductFarmTypes,
  fetchAllTaxTypes,
} from '../../../../../services/agriculturalHoldings/agriculturalHoldingsApi';
import { handleFetch } from '../../../../../utils/selectObjectFormatter';

export const getAltitudes = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllAltitudes);
};

export const getAreaConstraints = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllAreaConstraints);
};

export const getOffices = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllOffices);
}

export const getCities = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllCities);
};

export const getEconomicSizes = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllEconomicSizes);
};

export const getFarmTypes = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllFarmTypes);
};

export const getAllOtherGainfulActivities = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllOtherGainfulActivities);
};

export const getAllOwnershipTypes = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllOwnershipTypes);
};

export const getAllProductionTypes = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllProductionTypes);
};

export const getAllProtectedProductFarmTypes = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllProtectedProductFarmTypes, [1]);
};

export const getAllTaxTypes = (setOptionsList) => {
  handleFetch(setOptionsList, fetchAllTaxTypes);
};
