import UserAvatar from '../../avatar/UserAvatar';
import React from 'react';
import PropTypes from 'prop-types';

const UserLabel = ({ option }) => {
  return (
    <div className="d-flex">
      <UserAvatar user={option} className="mr-2" />
      <div className="d-flex align-items-center ml-2 ">
        {option.firstName} {option.lastName}
      </div>
    </div>
  );
};

export default UserLabel;

UserLabel.propTypes = {
  option: PropTypes.object,
};
