import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const GrantsBasicUnitsType = () => {
  return (
    <div>
      <StandardTable api={'grant-basic-unit-types'} title={t('administration.enums.title.grantsBasicUnitsType')} />
    </div>
  );
};

export default GrantsBasicUnitsType;
