import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { t } from 'react-switch-lang';
import { axiosPost, axiosPut } from '../../../../services/axios';
import SaveBtn from '../../../../components/buttons/SaveBtn';
import CheckboxField from '../../../../components/form/checkboxField/CheckboxField';
import InputField from '../../../../components/form/inputField/InputField';
import SelectConstField from '../../../../components/form/selectField/SelectConstField';
import ValidationForm from '../../../../components/form/validationForm/ValidationForm';
import CancelBtn from '../../../../components/buttons/CancelBtn';
import classes from './AgriculturalHoldingForm.module.scss';
import { fetchSingleAgriculturalHolding } from '../../../../services/agriculturalHoldings/agriculturalHoldingsApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Toast } from '../../../../utils/sweetAlert';
import DateField from '../../../../components/form/dateField/DateField';
import {
  getAllOtherGainfulActivities,
  getAllOwnershipTypes,
  getAllProductionTypes,
  getAllProtectedProductFarmTypes,
  getAllTaxTypes,
  getAltitudes,
  getAreaConstraints,
  getCities,
  getEconomicSizes,
  getFarmTypes,
  getOffices,
} from '../utils/form/selectApiCalls';
import SelectAsyncField from '../../../../components/form/selectField/SelectAsyncField';
import UserData from '../../../../contexts/UserData';
import { defaultOption } from '../../../../utils/selectObjectFormatter';
import UserLabel from '../../../../components/labels/userLabel/UserLabel';
import { eng, parseFloatCustom, readOnly } from '../../../../utils/const';

const AgriculturalHoldingForm = (props) => {

  const user = useContext(UserData);
  const formRef = useRef();
  const location = useLocation();
  const history = useHistory();

  const [agriculturalHolding, setAgriculturalHolding] = useState();

  const agriculturalHoldingId = props.match.params.id;
  const add = location?.state?.add !== undefined ? location.state.add : false;

  //state for redirection handling by user role
  const [userData, setUserData] = useState('');

  const [edit, setEdit] = useState(location?.state?.edit !== undefined ? location.state.edit : false);

  // when form is submitted from edit, this state hides preview/edit switching button (EditOutlined)
  const [submitted, setSubmitted] = useState(false);

  // states for form data
  const [ahFadnId, setAhFadnId] = useState({ value: undefined, disabled: !edit });
  const [isActive, setIsActive] = useState({ value: ['true'], disabled: !edit });
  const [primaryCollector, setPrimaryCollector] = useState({ value: undefined, disabled: !edit });
  const [secondaryCollector, setSecondaryCollector] = useState({ value: undefined, disabled: !edit });
  const [ricaAhId, setRicaAhId] = useState({ value: undefined, disabled: !edit });
  const [mnRegistrationId, setMnRegistrationId] = useState({ value: undefined, disabled: !edit });
  const [office, setOffice] = useState({ value: undefined, disabled: !edit });
  const [city, setCity] = useState({ value: undefined, disabled: !edit });
  const [settlement, setSettlement] = useState({ value: undefined, disabled: !edit });
  const [zipCode, setZipCode] = useState({ value: undefined, disabled: !edit });
  const [ownershipType, setOwnershipType] = useState({ value: undefined, disabled: !edit });
  const [taxType, setTaxType] = useState({ value: undefined, disabled: !edit });
  const [vat, setVat] = useState({ value: [], disabled: !edit });
  const [altitude, setAltitude] = useState({ value: undefined, disabled: !edit });
  const [productionType, setProductionType] = useState({ value: undefined, disabled: !edit });
  const [farmType, setFarmType] = useState({ value: undefined, disabled: !edit });
  const [economicSize, setEconomicSize] = useState({ value: undefined, disabled: !edit });
  const [areaConstraint, setAreaConstraint] = useState({ value: undefined, disabled: !edit });
  const [usedFarmingSurfacesTotal, setUsedFarmingSurfacesTotal] = useState({ value: 0, disabled: true });
  const [usedFarmingSurfConventionalProd, setUsedFarmingSurfConventionalProd] = useState({ value: 0, disabled: true });
  const [usedFarmingSurfOrganicProd, setUsedFarmingSurfOrganicProd] = useState({ value: 0, disabled: true });
  const [usedFarmingSurfIrrigation, setUsedFarmingSurfIrrigation] = useState({ value: 0, disabled: true });
  const [otherGainfulActivities, setOtherGainfulActivities] = useState({ value: undefined, disabled: !edit });
  const [protectedProductFarmType, setProtectedProductFarmType] = useState({ value: undefined, disabled: !edit });
  const [organicProductionOnly, setOrganicProductionOnly] = useState({ value: [], disabled: !edit });
  const [geoLatitude, setGeoLatitude] = useState({ value: undefined, disabled: !edit });
  const [geoLongitude, setGeoLongitude] = useState({ value: undefined, disabled: !edit });
  const [naturaArea, setNaturaArea] = useState({ value: [], disabled: !edit });
  const [weighting, setWeighting] = useState({ value: undefined, disabled: true });
  const [hadErrors, setHadErrors] = useState({ value: [], disabled: true });
  const [fromPreviousYear, setFromPreviousYear] = useState({ value: [], disabled: !edit });
  const [lockedBy, setLockedBy] = useState({ value: [], disabled: true });
  const [lockedDate, setLockedDate] = useState({ value: undefined, disabled: true });
  const [dropped, setDropped] = useState({ value: [], disabled: !edit });
  const [viewers, setViewers] = useState({ value: [], disabled: !edit });
  const [organicSectors, setOrganicSectors] = useState({ value: [], disabled: !edit });
  const [protectedProducts, setProtectedProducts] = useState({ value: [], disabled: !edit });

  // states for select inputs
  const [altitudeOptionsList, setAltitudeOptionsList] = useState([]);
  const [areaConstraintOptionsList, setAreaConstraintOptionsList] = useState([]);
  const [officeOptionsList, setOfficeOptionsList] = useState([]);
  const [cityOptionsList, setCityOptionsList] = useState([]);
  const [economicSizeOptionsList, setEconomicSizeOptionsList] = useState([]);
  const [farmTypeOptionsList, setFarmTypeOptionsList] = useState([]);
  const [otherGainfulActivitiesOptionsList, setOtherGainfulActivitiesOptionsList] = useState([]);
  const [ownershipTypeOptionsList, setOwnershipTypeOptionsList] = useState([]);
  const [productionTypeOptionsList, setProductionTypeOptionsList] = useState([]);
  const [protectedProductFarmTypeOptionsList, setProtectedProductFarmTypeOptionsList] = useState([]);
  const [taxTypeOptionsList, setTaxTypeOptionsList] = useState([]);

  const [refreshValidation, setRefreshValidation] = useState(false);

  const [validation, setValidation] = useState({
    ahFadnId: { type: 'number', required: { value: true }, min: { value: 1 } },
    isActive: { type: 'checkbox', required: { value: false } },  // bool
    primaryCollector: { type: 'selectSingle', required: { value: true } },  // dropdown (ROLE_COLLECTOR)
    secondaryCollector: { type: 'selectSingle', required: { value: false } },  // dropdown (ROLE_COLLECTOR)
    ricaAhId: { type: 'string', required: { value: false } }, // unique (has to return validation error)
    mnRegistrationId: { type: 'string', required: { value: false } }, // unique (has to return validation error)
    office: { type: 'selectSingle', required: { value: false } }, // dropdown
    city: { type: 'selectSingle', required: { value: false } },
    settlement: { type: 'string', required: { value: false } },
    zipCode: { type: 'string', required: { value: false }, matches: { value: 'ZIP' } },
    ownershipType: { type: 'number', required: { value: false } },  // dropdown
    taxType: { type: 'number', required: { value: false } },  // dropdown
    vat: { type: 'checkbox', required: { value: false } },  // bool
    altitude: { type: 'number', required: { value: false } },  // dropdown
    productionType: { type: 'number', required: { value: false } }, // dropdown
    farmType: { type: 'number', required: { value: false } }, // dropdown
    economicSize: { type: 'number', required: { value: false } }, // dropdown
    areaConstraint: { type: 'number', required: { value: false } }, // dropdown
    userFarmingSurfacesTotal: { type: 'number', required: { value: false }, min: { value: 0 } },
    usedFarmingSurfConventionalProd: { type: 'number', required: { value: false }, min: { value: 0 } },
    usedFarmingSurfOrganicProd: { type: 'number', required: { value: false }, min: { value: 0 } },
    usedFarmingSurfIrrigation: { type: 'number', required: { value: false }, min: { value: 0 } },
    otherGainfulActivities: { type: 'number', required: { value: false } }, // dropdown
    protectedProductFarmType: { type: 'number', required: { value: false } }, // dropdown
    organicProductionOnly: { type: 'checkbox', required: { value: false } },  // bool
    geoLatitude: { type: 'number', required: { value: false }, min: { value: -90 }, max: { value: 90 } },
    geoLongitude: { type: 'number', required: { value: false }, min: { value: -180 }, max: { value: 80 } },
    naturaArea: { type: 'checkbox', required: { value: false } },  // bool (required)
    weighting: { type: 'number', required: { value: false }, min: { value: 0 } },
    hadErrors: { type: 'checkbox', required: { value: false } }, // bool
    fromPreviousYear: { type: 'checkbox', required: { value: false } }, // bool
    lockedBy: { type: 'number', required: { value: false } },
    lockedDate: { type: 'date', required: { value: false } },
    dropped: { type: 'checkbox', required: { value: false } }, // bool (required)
    viewers: { type: 'selectSingle', required: { value: false } },
    organicSectors: { type: 'selectSingle', required: { value: false } },
    protectedProducts: { type: 'selectSingle', required: { value: false } },
  });

  const isDisplayed = !add && !edit && !user.isViewer && !submitted &&
    !add && !edit && !user.isViewer && !submitted && !readOnly;

  const handleApiErrors = (e) => {
    if (e.response.status === 400 && e.response.data.message === 'error.validation') {
      const errors = e.response.data.fieldErrors;
      const formFields = Object.keys(validation);
      let validationTemp = validation;

      for (let i = 0; i < errors.length; i++) {
        for (let j = 0; j < formFields.length; j++) {
          if (formFields[j] === errors[i].field) {
            let field = { ...validationTemp[formFields[j]] };
            let messages = field?.error?.messages ? field.error.messages : [];
            !messages.includes(errors[i].message) && messages.push(errors[i].message);
            field = { ...field, error: { value: true, messages: messages } };

            validationTemp = {
              ...validationTemp,
              [formFields[j]]: field,
            };
          }
        }
      }
      setValidation({ ...validationTemp });

      // force form to rerender after validation object has changed
      setRefreshValidation(!refreshValidation);
      formRef.current.submitForm();
    }
  };

  const handleSubmit = async () => {
    const payload = {
      ...(ahFadnId?.value && { ahFadnId: parseInt(ahFadnId?.value) }),
      ...(isActive?.value && { isActive: isActive?.value[0] === 'true' }),
      ...(primaryCollector?.value && { primaryCollector: { id: parseInt(primaryCollector?.value?.value) } }),
      ...(secondaryCollector?.value && { secondaryCollector: { id: parseInt(secondaryCollector?.value?.value) } }),
      ...(ricaAhId?.value && { ricaAhId: ricaAhId?.value }),
      ...(mnRegistrationId?.value && { mnRegistrationId: mnRegistrationId?.value }),
      ...(office?.value && { county: { id: parseInt(office?.value?.value) } }),
      ...(city?.value && { city: { id: parseInt(city?.value?.value) } }),
      ...(settlement?.value && { settlement: settlement?.value }),
      ...(zipCode?.value && { zipCode: zipCode?.value }),
      ...(ownershipType?.value && { ownershipType: { id: parseInt(ownershipType?.value?.value) } }),
      ...(taxType?.value && { taxType: { id: parseInt(taxType?.value?.value) } }),
      ...(vat?.value && { vat: vat?.value[0] === 'true' }),
      ...(altitude?.value && { altitude: { id: parseInt(altitude?.value?.value) } }),
      ...(productionType?.value && { productionType: { id: parseInt(productionType?.value?.value) } }),
      ...(farmType?.value && { farmType: { id: parseInt(farmType?.value?.value) } }),
      ...(economicSize?.value && { economicSize: { id: parseInt(economicSize?.value?.value) } }),
      ...(areaConstraint?.value && { areaConstraint: { id: parseInt(areaConstraint?.value?.value) } }),
      usedFarmingSurfacesTotal: parseFloatCustom(usedFarmingSurfacesTotal?.value),
      usedFarmingSurfConventionalProd: parseFloatCustom(usedFarmingSurfConventionalProd?.value),
      usedFarmingSurfOrganicProd: parseFloatCustom(usedFarmingSurfOrganicProd?.value),
      usedFarmingSurfIrrigation: parseFloatCustom(usedFarmingSurfIrrigation?.value),
      ...(otherGainfulActivities?.value && { otherGainfulActivities: { id: parseInt(otherGainfulActivities?.value?.value) } }),
      ...(protectedProductFarmType?.value && { protectedProductFarmType: { id: parseInt(protectedProductFarmType?.value?.value) } }),
      ...(organicProductionOnly?.value && { organicProductionOnly: organicProductionOnly?.value[0] === 'true' }),
      ...(geoLatitude?.value && { geoLatitude: parseFloatCustom(geoLatitude?.value) }),
      ...(geoLongitude?.value && { geoLongitude: parseFloatCustom(geoLongitude?.value) }),
      ...(naturaArea?.value && { naturaArea: naturaArea?.value[0] === 'true' }),
      ...(weighting?.value && { weighting: parseFloatCustom(weighting?.value) }),
      ...(hadErrors?.value && { hadErrors: hadErrors?.value[0] === 'true' }),
      ...(fromPreviousYear?.value && { fromPreviousYear: fromPreviousYear?.value[0] === 'true' }),
      ...(lockedBy?.value && { lockedBy: parseInt(lockedBy?.value) }),
      ...(lockedDate?.value && { lockedDate: lockedDate?.value }),
      ...(dropped?.value && { dropped: dropped?.value[0] === 'true' }),
      ...(viewers?.value && { viewers: viewers.value.map((elem) => ({ id: elem.value })) }),
      ...(organicSectors?.value && { organicSectors: organicSectors.value.map((elem) => ({ id: elem.value })) }),
      ...(protectedProducts?.value && { protectedProducts: protectedProducts.value.map((elem) => ({ id: elem.value })) }),
    };

    if (add) {
      try {
        const response = await axiosPost('agro-holdings', payload);

        if (response.status === 201) {
          setEdit(false);
          setSubmitted(true);
          Toast.fire({
            title: t('common.successAdd', { entity: t('messages.agriculturalHolding') }),
            icon: 'success',
          });
          history.goBack();
        }
      } catch (e) {
        handleApiErrors(e);
      }
    } else {
      try {
        const editPayload = {
          ...payload,
          id: agriculturalHolding.id,
        };
        const response = await axiosPut(`agro-holdings/${agriculturalHolding.id}`, editPayload);
        if (response.status === 200) {
          setEdit(false);
          setSubmitted(true);
          Toast.fire({
            title: t('common.successEdit', { entity: t('messages.agriculturalHolding') }),
            icon: 'success',
          });
          history.goBack();
        }
      } catch (e) {
        handleApiErrors(e);
      }
    }
  };

  // fn for fetching single agr holding data
  const getAgriculturalHolding = async (id) => {
    try {
      // todo update with cancel token
      const { data } = await fetchSingleAgriculturalHolding(id);
      setAgriculturalHolding(data);
      return data;
    } catch (e) {

    }
  };

  // handle switching from preview to edit
  useEffect(() => {
    setAhFadnId(prevState => ({ ...prevState, disabled: !edit }));
    setIsActive(prevState => ({ ...prevState, disabled: !edit }));
    setPrimaryCollector(prevState => ({ ...prevState, disabled: !edit }));
    setSecondaryCollector(prevState => ({ ...prevState, disabled: !edit }));
    setRicaAhId(prevState => ({ ...prevState, disabled: !edit }));
    setMnRegistrationId(prevState => ({ ...prevState, disabled: !edit }));
    setOffice(prevState => ({ ...prevState, disabled: !edit }));
    setCity(prevState => ({ ...prevState, disabled: !edit }));
    setSettlement(prevState => ({ ...prevState, disabled: !edit }));
    setZipCode(prevState => ({ ...prevState, disabled: !edit }));
    setOwnershipType(prevState => ({ ...prevState, disabled: !edit }));
    setTaxType(prevState => ({ ...prevState, disabled: !edit }));
    setVat(prevState => ({ ...prevState, disabled: !edit }));
    setAltitude(prevState => ({ ...prevState, disabled: !edit }));
    setProductionType(prevState => ({ ...prevState, disabled: !edit }));
    setFarmType(prevState => ({ ...prevState, disabled: !edit }));
    setEconomicSize(prevState => ({ ...prevState, disabled: !edit }));
    setAreaConstraint(prevState => ({ ...prevState, disabled: !edit }));
    setOtherGainfulActivities(prevState => ({ ...prevState, disabled: !edit }));
    setProtectedProductFarmType(prevState => ({ ...prevState, disabled: !edit }));
    setOrganicProductionOnly(prevState => ({ ...prevState, disabled: !edit }));
    setGeoLatitude(prevState => ({ ...prevState, disabled: !edit }));
    setGeoLongitude(prevState => ({ ...prevState, disabled: !edit }));
    setNaturaArea(prevState => ({ ...prevState, disabled: !edit }));
    setFromPreviousYear(prevState => ({ ...prevState, disabled: !edit }));
    setDropped(prevState => ({ ...prevState, disabled: !edit }));
    setViewers(prevState => ({ ...prevState, disabled: !edit }));
    setOrganicSectors(prevState => ({ ...prevState, disabled: !edit }));
    setProtectedProducts(prevState => ({ ...prevState, disabled: !edit }));
  }, [edit]);

  // handles loading API data into form
  useEffect(() => {
    getAltitudes(setAltitudeOptionsList);
    getAreaConstraints(setAreaConstraintOptionsList);
    getOffices(setOfficeOptionsList);
    getCities(setCityOptionsList);
    getEconomicSizes(setEconomicSizeOptionsList);
    getFarmTypes(setFarmTypeOptionsList);
    getAllOtherGainfulActivities(setOtherGainfulActivitiesOptionsList);
    getAllOwnershipTypes(setOwnershipTypeOptionsList);
    getAllProductionTypes(setProductionTypeOptionsList);
    getAllProtectedProductFarmTypes(setProtectedProductFarmTypeOptionsList);
    getAllTaxTypes(setTaxTypeOptionsList);

    if (!add) {
      getAgriculturalHolding(agriculturalHoldingId)
        .then(agriculturalHolding => {
          setAhFadnId(prevState => ({ ...prevState, value: agriculturalHolding?.ahFadnId || undefined }));
          setIsActive(prevState => ({ ...prevState, value: agriculturalHolding?.isActive ? ['true'] : [] }));
          setPrimaryCollector(prevState => ({
            ...prevState,
            value: {
              value: agriculturalHolding?.primaryCollector?.id,
              label: <UserLabel option={agriculturalHolding?.primaryCollector}/>,
            } || undefined,
          }));
          agriculturalHolding?.secondaryCollector && setSecondaryCollector(prevState => ({
            ...prevState,
            value: {
              value: agriculturalHolding?.secondaryCollector?.id,
              label: <UserLabel option={agriculturalHolding?.secondaryCollector}/>,
            } || undefined,
          }));
          setRicaAhId(prevState => ({ ...prevState, value: agriculturalHolding?.ricaAhId || undefined }));
          setMnRegistrationId(prevState => ({
            ...prevState,
            value: agriculturalHolding?.mnRegistrationId || undefined,
          }));
          agriculturalHolding?.county && setOffice(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.county) || undefined,
          }));
          agriculturalHolding?.city && setCity(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.city) || undefined,
          }));
          setSettlement(prevState => ({ ...prevState, value: agriculturalHolding?.settlement || undefined }));
          setZipCode(prevState => ({ ...prevState, value: agriculturalHolding?.zipCode || undefined }));
          agriculturalHolding?.ownershipType && setOwnershipType(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.ownershipType) || undefined,
          }));
          agriculturalHolding?.taxType && setTaxType(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.taxType) || undefined,
          }));
          setVat(prevState => ({ ...prevState, value: agriculturalHolding?.vat ? ['true'] : [] }));
          agriculturalHolding?.altitude && setAltitude(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.altitude) || undefined,
          }));
          agriculturalHolding?.productionType && setProductionType(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.productionType) || undefined,
          }));
          agriculturalHolding?.farmType && setFarmType(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.farmType) || undefined,
          }));
          agriculturalHolding?.economicSize && setEconomicSize(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.economicSize) || undefined,
          }));
          agriculturalHolding?.areaConstraint && setAreaConstraint(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.areaConstraint) || undefined,
          }));
          setUsedFarmingSurfacesTotal(prevState => ({
            ...prevState,
            value: agriculturalHolding?.usedFarmingSurfacesTotal,
          }));
          setUsedFarmingSurfConventionalProd(prevState => ({
            ...prevState,
            value: agriculturalHolding?.usedFarmingSurfConventionalProd,
          }));
          setUsedFarmingSurfOrganicProd(prevState => ({
            ...prevState,
            value: agriculturalHolding?.usedFarmingSurfOrganicProd,
          }));
          setUsedFarmingSurfIrrigation(prevState => ({
            ...prevState,
            value: agriculturalHolding?.usedFarmingSurfIrrigation,
          }));
          agriculturalHolding?.otherGainfulActivities && setOtherGainfulActivities(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.otherGainfulActivities) || undefined,
          }));
          agriculturalHolding?.protectedProductFarmType && setProtectedProductFarmType(prevState => ({
            ...prevState,
            value: defaultOption(agriculturalHolding?.protectedProductFarmType) || undefined,
          }));
          setOrganicProductionOnly(prevState => ({
            ...prevState,
            value: agriculturalHolding?.organicProductionOnly ? ['true'] : [],
          }));
          setGeoLatitude(prevState => ({ ...prevState, value: agriculturalHolding?.geoLatitude || undefined }));
          setGeoLongitude(prevState => ({ ...prevState, value: agriculturalHolding?.geoLongitude || undefined }));
          setNaturaArea(prevState => ({ ...prevState, value: agriculturalHolding?.naturaArea ? ['true'] : [] }));
          setWeighting(prevState => ({ ...prevState, value: agriculturalHolding?.weighting || undefined }));
          setHadErrors(prevState => ({ ...prevState, value: agriculturalHolding?.hadErrors ? ['true'] : [] }));
          setFromPreviousYear(prevState => ({
            ...prevState,
            value: agriculturalHolding?.fromPreviousYear ? ['true'] : [],
          }));
          agriculturalHolding?.lockedBy && setLockedBy(prevState => ({
            ...prevState,
            value: {
              value: agriculturalHolding?.lockedBy?.id,
              label: agriculturalHolding?.lockedBy?.login,
            } || undefined,
          }));
          setLockedDate(prevState => ({
            ...prevState,
            value: agriculturalHolding?.lockedDate ? new Date(agriculturalHolding?.lockedDate) : undefined,
          }));
          setDropped(prevState => ({ ...prevState, value: agriculturalHolding?.dropped ? ['true'] : [] }));
          agriculturalHolding?.viewers && setViewers(prevState => ({
            ...prevState,
            value: agriculturalHolding.viewers.map((elem) => ({
              value: elem?.id,
              label: <UserLabel option={elem}/>,
            })) || undefined,
          }));
          agriculturalHolding?.organicSectors && setOrganicSectors(prevState => ({
            ...prevState,
            value: agriculturalHolding.organicSectors.map((elem) => ({
              value: elem?.id,
              label: eng ? elem?.nameEn : elem?.name,
            })) || undefined,
          }));
          agriculturalHolding?.protectedProducts && setProtectedProducts(prevState => ({
            ...prevState,
            value: agriculturalHolding.protectedProducts.map((elem) => ({
              value: elem?.id,
              label: eng ? elem?.nameEn : elem?.name,
            })) || undefined,
          }));
        });
    }
  }, []);

  return (
    <div className='d-flex w-100'>
      <div className='d-flex flex-column justify-content-center w-100'>
        <ValidationForm validation={validation} ref={formRef}>
          <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
            <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
              <InputField
                label={t('pages.agriculturalHolding.ahFadnId')}
                value={ahFadnId?.value}
                name='ahFadnId'
                type='number'
                groupField
                onChange={(e) => {
                  setAhFadnId(prevState => ({ ...prevState, value: e }));
                  setValidation(prevState => ({
                    ...prevState,
                    ahFadnId: { ...prevState?.ahFadnId, error: { value: false } },
                  }));
                }}
                disabled={!user.isAdmin || ahFadnId?.disabled || fromPreviousYear?.value[0] === 'true'}
                apiErrors={validation['ahFadnId']?.error?.value}
                styles={`${classes['formFieldsWidth']}`}
              />
              {user.isAdmin &&
              <CheckboxField
                label={t('pages.agriculturalHolding.isActive')}
                name='isActive'
                options={[{ value: 'true', label: '' }]}
                value={isActive?.value}
                groupField
                onChange={(e) => {
                  setIsActive(prevState => ({ ...prevState, value: e }));
                }}
                disabled={isActive?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              }
              {user.isAdmin ?
                <>
                  <SelectAsyncField
                    label={t('pages.agriculturalHolding.primaryCollector')}
                    optionLabel={'firstName'}
                    value={primaryCollector?.value}
                    name='primaryCollector'
                    groupField
                    onChange={(e) => {
                      setPrimaryCollector(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={primaryCollector?.disabled}
                    api={'admin/users/role'}
                    user={true}
                    roleName='ROLE_COLLECTOR'
                    searchTerm={'searchTermName'}
                    styles={`${classes['formFieldsWidth']}`}
                    duplicate={secondaryCollector?.value}
                    key={secondaryCollector?.value?.value + 'primary'}
                    isClearable={true}
                  />
                  <SelectAsyncField
                    label={t('pages.agriculturalHolding.secondaryCollector')}
                    optionLabel={'firstName'}
                    value={secondaryCollector?.value}
                    name='secondaryCollector'
                    groupField
                    onChange={(e) => {
                      setSecondaryCollector(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={secondaryCollector?.disabled}
                    api={'admin/users/role'}
                    user={true}
                    roleName='ROLE_COLLECTOR'
                    searchTerm={'searchTermName'}
                    styles={`${classes['formFieldsWidth']}`}
                    duplicate={primaryCollector?.value}
                    key={primaryCollector?.value?.value + 'secondary'}
                    isClearable={true}
                  />
                </>
                :
                <>
                  <SelectConstField
                    label={t('pages.agriculturalHolding.primaryCollector')}
                    value={primaryCollector?.value}
                    name='primaryCollector'
                    groupField
                    options={[]}
                    onChange={() => {
                    }}
                    disabled={true}
                  />
                  <SelectConstField
                    label={t('pages.agriculturalHolding.secondaryCollector')}
                    value={secondaryCollector?.value}
                    name='secondaryCollector'
                    groupField
                    options={[]}
                    onChange={() => {
                    }}
                    disabled={true}
                  />
                </>
              }
              <InputField
                label={t('pages.agriculturalHolding.ricaAhId')}
                value={ricaAhId?.value}
                name='ricaAhId'
                type='text'
                groupField
                onChange={(e) => {
                  setRicaAhId(prevState => ({ ...prevState, value: e }));
                  setValidation(prevState => ({
                    ...prevState,
                    ricaAhId: { ...prevState?.ricaAhId, error: { value: false } },
                  }));
                }}
                disabled={!user.isAdmin || ricaAhId?.disabled}
                apiErrors={validation['ricaAhId']?.error?.value}
                styles={`${classes['formFieldsWidth']}`}
              />
              <InputField
                label={t('pages.agriculturalHolding.mnRegistrationId')}
                value={mnRegistrationId?.value}
                name='mnRegistrationId'
                type='text'
                groupField
                onChange={(e) => {
                  setMnRegistrationId(prevState => ({ ...prevState, value: e }));
                  setValidation(prevState => ({
                    ...prevState,
                    mnRegistrationId: { ...prevState?.mnRegistrationId, error: { value: false } },
                  }));
                }}
                disabled={user.isViewer || mnRegistrationId?.disabled}
                apiErrors={validation['mnRegistrationId']?.error?.value}
                styles={`${classes['formFieldsWidth']}`}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.office')}
                value={office?.value}
                name='office'
                groupField
                onChange={(e) => {
                  setOffice(prevState => ({ ...prevState, value: e }));
                }}
                options={officeOptionsList}
                disabled={user.isViewer || office?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.city')}
                value={city?.value}
                name='city'
                groupField
                onChange={(e) => {
                  setCity(prevState => ({ ...prevState, value: e }));
                }}
                options={cityOptionsList}
                disabled={user.isViewer || city?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <InputField
                label={t('pages.agriculturalHolding.settlement')}
                value={settlement?.value}
                name='settlement'
                type='text'
                groupField
                onChange={(e) => {
                  setSettlement(prevState => ({ ...prevState, value: e }));
                }}
                disabled={user.isViewer || settlement?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <InputField
                label={t('pages.agriculturalHolding.zipCode')}
                value={zipCode?.value}
                name='zipCode'
                type='text'
                groupField
                onChange={(e) => {
                  setZipCode(prevState => ({ ...prevState, value: e }));
                }}
                disabled={user.isViewer || zipCode?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.ownershipType')}
                value={ownershipType?.value}
                name='ownershipType'
                groupField
                onChange={(e) => {
                  setOwnershipType(prevState => ({ ...prevState, value: e }));
                }}
                options={user.isViewer || ownershipTypeOptionsList}
                disabled={ownershipType?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.taxType')}
                value={taxType?.value}
                name='taxType'
                groupField
                onChange={(e) => {
                  setTaxType(prevState => ({ ...prevState, value: e }));
                }}
                options={taxTypeOptionsList}
                disabled={user.isViewer || taxType?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <CheckboxField
                label={t('pages.agriculturalHolding.vat')}
                name='vat'
                options={[{ value: 'true', label: '' }]}
                value={vat?.value}
                groupField
                onChange={(e) => {
                  setVat(prevState => ({ ...prevState, value: e }));
                }}
                disabled={user.isViewer || vat?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.altitude')}
                value={altitude?.value}
                name='altitude'
                groupField
                onChange={(e) => {
                  setAltitude(prevState => ({ ...prevState, value: e }));
                }}
                options={altitudeOptionsList}
                disabled={user.isViewer || altitude?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.productionType')}
                value={productionType?.value}
                name='productionType'
                groupField
                onChange={(e) => {
                  setProductionType(prevState => ({ ...prevState, value: e }));
                }}
                options={productionTypeOptionsList}
                disabled={user.isViewer || productionType?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.farmType')}
                value={farmType?.value}
                name='farmType'
                groupField
                onChange={(e) => {
                  setFarmType(prevState => ({ ...prevState, value: e }));
                }}
                options={farmTypeOptionsList}
                disabled={!user.isAdmin || farmType?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.economicSize')}
                value={economicSize?.value}
                name='economicSize'
                groupField
                onChange={(e) => {
                  setEconomicSize(prevState => ({ ...prevState, value: e }));
                }}
                options={economicSizeOptionsList}
                disabled={!user.isAdmin || economicSize?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.areaConstraint')}
                value={areaConstraint?.value}
                name='areaConstraint'
                groupField
                onChange={(e) => {
                  setAreaConstraint(prevState => ({ ...prevState, value: e }));
                }}
                options={areaConstraintOptionsList}
                disabled={user.isViewer || areaConstraint?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <InputField
                label={t('pages.agriculturalHolding.userFarmingSurfacesTotal')}
                value={usedFarmingSurfacesTotal?.value}
                name='userFarmingSurfacesTotal'
                type='double'
                groupField
                onChange={(e) => {
                  setUsedFarmingSurfacesTotal(prevState => ({ ...prevState, value: e }));
                }}
                disabled={usedFarmingSurfacesTotal?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <InputField
                label={t('pages.agriculturalHolding.usedFarmingSurfConventionalProd')}
                value={usedFarmingSurfConventionalProd?.value}
                name='usedFarmingSurfConventionalProd'
                type='double'
                groupField
                onChange={(e) => {
                  setUsedFarmingSurfConventionalProd(prevState => ({ ...prevState, value: e }));
                }}
                disabled={usedFarmingSurfConventionalProd?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
            </div>
            <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
              <InputField
                label={t('pages.agriculturalHolding.usedFarmingSurfOrganicProd')}
                value={usedFarmingSurfOrganicProd?.value}
                name='usedFarmingSurfOrganicProd'
                type='double'
                groupField
                onChange={(e) => {
                  setUsedFarmingSurfOrganicProd(prevState => ({ ...prevState, value: e }));
                }}
                disabled={usedFarmingSurfOrganicProd?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <InputField
                label={t('pages.agriculturalHolding.usedFarmingSurfIrrigation')}
                value={usedFarmingSurfIrrigation?.value}
                name='usedFarmingSurfIrrigation'
                type='double'
                groupField
                onChange={(e) => {
                  setUsedFarmingSurfIrrigation(prevState => ({ ...prevState, value: e }));
                }}
                disabled={usedFarmingSurfIrrigation?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.otherGainfulActivities')}
                value={otherGainfulActivities?.value}
                name='otherGainfulActivities'
                groupField
                onChange={(e) => {
                  setOtherGainfulActivities(prevState => ({ ...prevState, value: e }));
                }}
                options={user.isViewer || otherGainfulActivitiesOptionsList}
                disabled={otherGainfulActivities?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <SelectConstField
                label={t('pages.agriculturalHolding.protectedProductFarmType')}
                value={protectedProductFarmType?.value}
                name='protectedProductFarmType'
                groupField
                onChange={(e) => {
                  setProtectedProductFarmType(prevState => ({ ...prevState, value: e }));
                }}
                options={user.isViewer || protectedProductFarmTypeOptionsList}
                disabled={protectedProductFarmType?.disabled}
                styles={`${classes['formFieldsWidth']}`}
                isClearable={true}
              />
              <CheckboxField
                label={t('pages.agriculturalHolding.organicProductionOnly')}
                name='organicProductionOnly'
                options={[{ value: 'true', label: '' }]}
                value={organicProductionOnly?.value}
                groupField
                onChange={(e) => {
                  setOrganicProductionOnly(prevState => ({ ...prevState, value: e }));
                }}
                disabled={user.isViewer || organicProductionOnly?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <InputField
                label={t('pages.agriculturalHolding.geoLatitude')}
                value={geoLatitude?.value}
                name='geoLatitude'
                type='double'
                isNegative={true}
                groupField
                onChange={(e) => {
                  setGeoLatitude(prevState => ({ ...prevState, value: e }));
                }}
                disabled={!user.isAdmin || geoLatitude?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <InputField
                label={t('pages.agriculturalHolding.geoLongitude')}
                value={geoLongitude?.value}
                name='geoLongitude'
                type='double'
                isNegative={true}
                groupField
                onChange={(e) => {
                  setGeoLongitude(prevState => ({ ...prevState, value: e }));
                }}
                disabled={!user.isAdmin || geoLongitude?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <CheckboxField
                label={t('pages.agriculturalHolding.naturaArea')}
                name='naturaArea'
                options={[{ value: 'true', label: '' }]}
                value={naturaArea?.value}
                groupField
                onChange={(e) => {
                  setNaturaArea(prevState => ({ ...prevState, value: e }));
                }}
                disabled={!user.isAdmin || naturaArea?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <InputField
                label={t('pages.agriculturalHolding.weighting')}
                value={weighting?.value}
                name='weighting'
                type='double'
                groupField
                onChange={(e) => {
                  setWeighting(prevState => ({ ...prevState, value: e }));
                }}
                disabled={!user.isAdmin || weighting?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <CheckboxField
                label={t('pages.agriculturalHolding.hadErrors')}
                name='hadErrors'
                options={[{ value: 'true', label: '' }]}
                value={hadErrors?.value}
                groupField
                onChange={(e) => {
                  setHadErrors(prevState => ({ ...prevState, value: e }));
                }}
                disabled={!user.isAdmin || hadErrors?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              {user.isAdmin &&
              <CheckboxField
                label={t('pages.agriculturalHolding.fromPreviousYear')}
                name='fromPreviousYear'
                options={[{ value: 'true', label: '' }]}
                value={fromPreviousYear?.value}
                groupField
                onChange={(e) => {
                  setFromPreviousYear(prevState => ({ ...prevState, value: e }));
                }}
                disabled={fromPreviousYear?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              }
              <InputField
                label={t('pages.agriculturalHolding.lockedBy')}
                value={lockedBy?.value}
                name='lockedBy'
                type='number'
                groupField
                onChange={(e) => {
                  setLockedBy(prevState => ({ ...prevState, value: e }));
                }}
                disabled={!user.isAdmin || lockedBy?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              <DateField
                label={t('pages.agriculturalHolding.lockedDate')}
                value={lockedDate?.value}
                name='lockedDate'
                groupField
                onChange={(e) => {
                  setLockedDate(prevState => ({ ...prevState, value: e }));
                }}
                disabled={!user.isAdmin || lockedDate?.disabled}
                isClearable={false}
                styles={`${classes['formFieldsWidth']}`}
              />
              <CheckboxField
                label={t('pages.agriculturalHolding.dropped')}
                name='dropped'
                options={[{ value: 'true', label: '' }]}
                value={dropped?.value}
                groupField
                onChange={(e) => {
                  setDropped(prevState => ({ ...prevState, value: e }));
                }}
                disabled={!user.isAdmin || dropped?.disabled}
                styles={`${classes['formFieldsWidth']}`}
              />
              {user.isAdmin ?
                <>
                  <SelectAsyncField
                    label={t('pageTitle.users')}
                    value={viewers?.value}
                    name='viewers'
                    groupField
                    onChange={(e) => {
                      setViewers(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={!user.isAdmin || viewers?.disabled}
                    api={'admin/users/role'}
                    user={true}
                    roleName='ROLE_VIEWER'
                    searchTerm={'searchTermName'}
                    styles={`${classes['formFieldsWidth']}`}
                    isClearable={true}
                    isMulti={true}
                  />
                  <SelectAsyncField
                    label={t('pageTitle.organicSectors')}
                    value={organicSectors?.value}
                    name='organicSectors'
                    groupField
                    onChange={(e) => {
                      setOrganicSectors(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={!user.isAdmin || organicSectors?.disabled}
                    api={'organic-sectors'}
                    searchTerm={'searchTermName'}
                    styles={`${classes['formFieldsWidth']}`}
                    isClearable={true}
                    isMulti={true}
                  />
                  <SelectAsyncField
                    label={t('pageTitle.protectedProducts')}
                    value={protectedProducts?.value}
                    name='protectedProducts'
                    groupField
                    onChange={(e) => {
                      setProtectedProducts(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={!user.isAdmin || protectedProducts?.disabled}
                    api={'protected-products'}
                    searchTerm={'searchTermName'}
                    styles={`${classes['formFieldsWidth']}`}
                    isClearable={true}
                    isMulti={true}
                  />
                </>
                :
                <>
                  <SelectConstField
                    label={t('pageTitle.users')}
                    className={`${classes['formFieldsWidth']}`}
                    value={viewers?.value}
                    name='viewers'
                    groupField
                    options={[]}
                    onChange={() => {
                    }}
                    disabled={true}
                    isMulti={true}
                  />
                  <SelectConstField
                    label={t('pageTitle.organicSectors')}
                    className={`${classes['formFieldsWidth']}`}
                    value={organicSectors?.value}
                    name='organicSectors'
                    groupField
                    options={[]}
                    onChange={() => {
                    }}
                    disabled={true}
                    isMulti={true}
                  />
                  <SelectConstField
                    label={t('pageTitle.protectedProducts')}
                    className={`${classes['formFieldsWidth']}`}
                    value={protectedProducts?.value}
                    name='protectedProducts'
                    groupField
                    options={[]}
                    onChange={() => {
                    }}
                    disabled={true}
                    isMulti={true}
                  />
                </>
              }
            </div>
            {isDisplayed &&
            <div className={classes.editBtn}>
              <EditOutlined
                onClick={() => {
                  setEdit(true);
                }}
              />
            </div>
            }
          </div>
        </ValidationForm>
        <div className='d-flex justify-content-center pt-3'>
          <div className='d-flex flex-column-reverse flex-md-row'>
            {!user.isViewer && !readOnly &&
            <>
              <CancelBtn
                className='button cancel'
                loader={false}
                onClick={() => {
                  history.goBack() === undefined ?
                    history.push('/administration/agricultural-holdings')
                    :
                    history.goBack();
                }}
              />
              <SaveBtn
                className='button save'
                loader={false}
                disabled={!edit}
                onClick={() => {
                  formRef.current.submitForm(handleSubmit);
                }}
              />
            </>
            }
          </div>
        </div>
      </div>
      {isDisplayed &&
      <div className={`${classes['editBtnSmallScreen']} pl-3`}>
        <EditOutlined
          onClick={() => {
            setEdit(true);
          }}
        />
      </div>
      }
    </div>
  );
};

export default AgriculturalHoldingForm;
