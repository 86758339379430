import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageTitle from '../../../components/layout/auth/navbar/pageTitle/PageTitle';
import classes from './Revisions.module.scss';
import HistoryList from './historyList/HistoryList';
import dataModelEntities from '../explainableControls/utils/dataModelEntities';
import RevisionContent from './revisionContent/RevisionContent';

const Revisions = (props) => {
  let params = useParams();
  let history = useHistory();
  const [entityData, setEntityData] = useState({});
  const [lastRevisionNumber, setLastRevisionNumber] = useState(0);

  useEffect(() => {
    const entity = dataModelEntities[params?.entity];
    if (entity) {
      setEntityData(entity);
    } else {
      history.push('/home');
    }
  }, [params?.entity]);

  return <div>
    <div className='pl-2'>
      <PageTitle title={entityData?.title ? entityData?.title() : ''}
                 backLink={entityData?.backLink?.replace(':ahid', params?.ahid)}
                 isCustomTitle
                 hasBackButton />
    </div>
    <div className={classes['container']}>
      <div className={classes['form-container']}>
        <RevisionContent props={props} lastRevisionNumber={lastRevisionNumber} />
      </div>
      <div className={classes['revisions-history']}>
        <HistoryList ahId={params?.ahid}
                     entity={entityData?.entity}
                     group={params?.group}
                     locationEntity={params?.entity}
                     entityId={params?.id}
                     setLastRevisionNumber={(e) => setLastRevisionNumber(e)}
                     activeRevisionNumber={params?.revisionNumber} />
      </div>
    </div>
  </div>;
};

export default Revisions;