import React, { useEffect, useState } from 'react';
import InputField from '../../../../../components/form/inputField/InputField';
import SelectConstField from '../../../../../components/form/selectField/SelectConstField';
import { axiosGet } from '../../../../../services/axios';

const ConditionalValue = ({
                            conditionalAttribute,
                            isComponentWrapper,
                            label,
                            name,
                            value,
                            onValueChange,
                            isDisabled,
                            ...rest
                          }) => {
  const [selectOptions, setSelectOptions] = useState([]);
  // disabled if !conditionalAttribute?.value
  // type conditionalAttribute?.additional?.type: select, integer/double/number
  // if integer/double/number displays number field
  // if select - options (has list or api,apiQuery,optionLabel(string or fn(data)), optionValue)

  const formatOptions = (options, optionValue, optionLabel) => {
    return {
      options: options.map((option) => ({
        value: Number(option[optionValue]) ? Number(option[optionValue]) : option[optionValue],
        label:
        typeof(optionLabel) === 'function'
          ? optionLabel(option)
          : option[optionLabel],
      }))
    };
  };

  const fetchData = async (api, optionValue, optionLabel) => {
    const { data } = await axiosGet(`${api}`);
    const { options } = formatOptions(data, optionValue, optionLabel);
    setSelectOptions(options);
  };

  useEffect(() => {
    if (conditionalAttribute?.additional?.type === 'select') {
      const options = conditionalAttribute?.additional?.options;
      if (Array.isArray(options?.list) && !options?.api) {
        setSelectOptions(options.list.map(item => ({
          ...item,
          value: Number(item.value) ? Number(item.value) : item.value
        })));
      } else if (options?.api && options?.api?.length > 0) {
        fetchData(`${options.api}${options?.apiQuery ? `&${options?.apiQuery}` : ''}`,
          options.optionValue,
          options.optionLabel);
      } else {
        setSelectOptions([]);
      }
    } else {
      setSelectOptions([]);
    }
  }, [conditionalAttribute?.additional?.type]);

  return conditionalAttribute?.additional?.type === 'select'
    ? <>
      <SelectConstField
        label={label}
        name={name}
        value={selectOptions?.find(item => item?.value === value)}
        groupField
        isClearable
        options={selectOptions}
        onChange={(e) => {
          onValueChange(e?.value);
        }}
        disabled={Boolean(conditionalAttribute?.value) ? isDisabled : true}
        {...rest}
      />
    </>
    : <InputField
      label={label}
      value={value}
      name={name}
      type='number'
      groupField
      onChange={(e) => {
        onValueChange(e);
      }}
      disabled={Boolean(conditionalAttribute?.value) ? isDisabled : true}
      {...rest}
    />;
};

export default ConditionalValue;