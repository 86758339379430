import React from 'react';
import { DEFAULT_MESSAGE, DEFAULT_VALIDATION, REGEX } from './validationDefaults';

// validation functions
export const checkString = (rules, value) => {
  let message = { body: <></>, type: 'noErrors' };
  const inp = value === null || value === undefined ? '' : value.toString().trim();
  if (rules.hasOwnProperty('required') && rules.required.value && inp === '') {
    message = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, errorType: 'error' }
      : { body: DEFAULT_MESSAGE.REQUIRED, errorType: 'error' };
  } else if (inp !== '' && rules.hasOwnProperty('min') && inp.length < rules.min.value) {
    message = rules.min.hasOwnProperty('message')
      ? { body: rules.min.message, type: 'error' }
      : { body: `${DEFAULT_MESSAGE.MINCHAR} ${rules.min.value}!`, type: 'error' };
  } else if (inp !== '' && rules.hasOwnProperty('max') && inp.length > rules.max.value) {
    message = rules.max.hasOwnProperty('message')
      ? { body: rules.max.message, type: 'error' }
      : { body: `${DEFAULT_MESSAGE.MAXCHAR} ${rules.max.value}!`, type: 'error' };
  } else if (
    !rules.hasOwnProperty('max') &&
    inp.length >
      (rules.type === 'textarea' ? DEFAULT_VALIDATION.TEXTAREA_MAX : DEFAULT_VALIDATION.STRING_MAX)
  ) {
    message = {
      body: `${DEFAULT_MESSAGE.MAXCHAR} ${
        rules.type === 'textarea' ? DEFAULT_VALIDATION.TEXTAREA_MAX : DEFAULT_VALIDATION.STRING_MAX
      }!`,
      type: 'error',
    };
  } else if (
    inp !== '' &&
    rules.hasOwnProperty('matches') &&
    rules.matches.value === 'STRONGPASSWORD' &&
    !REGEX[rules.matches.value].test(inp)
  ) {
    message = rules.matches.hasOwnProperty('message')
      ? { body: rules.matches.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.WEAKPASS, type: 'error' };
  } else if (
    inp !== '' &&
    rules.hasOwnProperty('matches') &&
    rules.matches.value === 'PASSWORD' &&
    REGEX['PASSWORD'] !== inp
  ) {
    message = rules.matches.hasOwnProperty('message')
      ? { body: rules.matches.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.MISMATCH, type: 'error' };
  } else if (
    inp !== '' &&
    rules.hasOwnProperty('matches') &&
    rules.matches.value !== 'PASSWORD' &&
    !REGEX[rules.matches.value].test(inp)
  ) {
    message = rules.matches.hasOwnProperty('message')
      ? { body: rules.matches.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'error' };
  } else if (rules.hasOwnProperty('error') && rules.error.value === true) {
    message = rules.error.hasOwnProperty('messages')
      ? { body: rules.error.messages, type: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'apiError' };
  }
  return message;
};

export const checkNumber = (rules, value) => {
  const inp = value === null || value === undefined ? '' : value;
  let message = { body: <></>, type: 'noErrors' };
  if (rules.hasOwnProperty('required') && rules.required.value && inp === '') {
    message = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.REQUIRED, type: 'error' };
  } else if (inp !== '' && rules.hasOwnProperty('min') && Number(inp) < Number(rules.min.value)) {
    message = rules.min.hasOwnProperty('message')
      ? { body: rules.min.message, type: 'error' }
      : { body: `${DEFAULT_MESSAGE.MINVALUE} ${rules.min.value}!`, type: 'error' };
  } else if (inp !== '' && rules.hasOwnProperty('max') && Number(inp) > Number(rules.max.value)) {
    message = rules.max.hasOwnProperty('message')
      ? { body: rules.max.message, type: 'error' }
      : { body: `${DEFAULT_MESSAGE.MAXVALUE} ${rules.max.value}!`, type: 'error' };
  } else if (!rules.hasOwnProperty('max') && inp > DEFAULT_VALIDATION.NUMBER_MAX) {
    message = {
      body: `${DEFAULT_MESSAGE.MAXVALUE} ${DEFAULT_VALIDATION.NUMBER_MAX}!`,
      type: 'error',
    };
  } else if (
    inp !== '' &&
    rules.hasOwnProperty('matches') &&
    !REGEX[rules.matches.value].test(inp)
  ) {
    message = rules.matches.hasOwnProperty('message')
      ? { body: rules.matches.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'error' };
  } else if (rules.hasOwnProperty('error') && rules.error.value === true) {
    message = rules.error.hasOwnProperty('messages')
      ? { body: rules.error.messages, type: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'apiError' };
  }
  return message;
};

export const checkSelectSingle = (rules, value) => {
  let message = { body: <></>, type: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    (value === null || value === undefined)
  ) {
    message = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.REQUIRED, type: 'error' };
  } else if (rules.hasOwnProperty('error') && rules.error.value === true) {
    message = rules.error.hasOwnProperty('messages')
      ? { body: rules.error.messages, type: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'apiError' };
  }
  return message;
};

export const checkSelectMulti = (rules, value) => {
  let message = { body: <></>, type: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    !(Array.isArray(value) && value.length > 0)
  ) {
    message = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.REQUIRED, type: 'error' };
  } else if (rules.hasOwnProperty('error') && rules.error.value === true) {
    message = rules.error.hasOwnProperty('messages')
      ? { body: rules.error.messages, type: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'apiError' };
  }
  return message;
};

export const checkDate = (rules, value) => {
  let message = { body: <></>, type: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    (value === null || value === undefined || value.length === 0)
  ) {
    message = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.REQUIRED, type: 'error' };
  } else if (rules.hasOwnProperty('error') && rules.error.value === true) {
    message = rules.error.hasOwnProperty('messages')
      ? { body: rules.error.messages, type: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'apiError' };
  }
  return message;
};

export const checkRadio = (rules, value) => {
  let message = { body: <></>, type: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.error.value &&
    (value === null || value === undefined || value.length === 0)
  ) {
    message = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.REQUIRED, type: 'error' };
  } else if (rules.hasOwnProperty('error') && rules.required.value === true) {
    message = rules.error.hasOwnProperty('messages')
      ? { body: rules.error.messages, type: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'apiError' };
  }
  return message;
};

export const checkCheckbox = (rules, value) => {
  let message = { body: <></>, type: 'noErrors' };
  if (
    rules.hasOwnProperty('required') &&
    rules.required.value &&
    !(Array.isArray(value) && value.length > 0)
  ) {
    message = rules.required.hasOwnProperty('message')
      ? { body: rules.required.message, type: 'error' }
      : { body: DEFAULT_MESSAGE.REQUIRED, type: 'error' };
  } else if (rules.hasOwnProperty('error') && rules.error.value === true) {
    message = rules.error.hasOwnProperty('messages')
      ? { body: rules.error.messages, type: 'apiError' }
      : { body: DEFAULT_MESSAGE.INVALID, type: 'apiError' };
  }
  return message;
};

// usage
// rulesObj = {
//        type: string, - which validation function to call, required
//       required: {value: true/false, message: string(if not defined, default message is shown)}, - rule
//       min: {value: 2, message: string(if not defined, default message is shown)},
//       max: {value: 255, message: string(if not defined, default message is shown)},
//       matches: {value: 'PORT', message: string(if not defined, default message is shown)}, - value is property in REXEG object
// }
// value - field value
export const validate = (rulesObj, value) => {
  switch (rulesObj.type) {
    case 'string':
    case 'textarea':
      return checkString(rulesObj, value);
    case 'number':
      return checkNumber(rulesObj, value);
    case 'selectSingle':
      return checkSelectSingle(rulesObj, value);
    case 'selectMulti':
      return checkSelectMulti(rulesObj, value);
    case 'date':
      return checkDate(rulesObj, value);
    case 'radio':
      return checkRadio(rulesObj, value);
    case 'checkbox':
      return checkCheckbox(rulesObj, value);
    default:
      return {};
  }
};
