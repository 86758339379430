import { t } from 'react-switch-lang';
import { agriculturalEducationEnum, genderEnum } from '../../../labour/utils/labourEnums';

export const eng = localStorage.getItem('language') === 'eng';

const labourFields = [
  {
    value: 'workforceType',
    label: () => t('pages.labour.workforceType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'workforce-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false,
    },
    displayInLimits: true,
  },
  {
    value: 'workforceRole',
    label: () => t('pages.labour.workforceRole'),
    type: 'select',
    options: {
      list: [],
      api: 'workforce-roles',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false,
    },
    displayInLimits: true,
  },
  {
    value: 'yearOfBirth',
    label: () => t('pages.labour.yearOfBirth'),
    type: 'date',
    options: {},
    displayInLimits: false,
  },
  {
    value: 'gender',
    label: () => t('pages.labour.gender'),
    type: 'select',
    options: {
      list: genderEnum,
    },
    displayInLimits: true,
  },
  {
    value: 'agriculturalEducation',
    label: () => t('pages.labour.agriculturalEducation'),
    type: 'select',
    options: {
      list: agriculturalEducationEnum,
    },
    displayInLimits: true,
  },
  {
    value: 'numberOfPersons',
    label: () => t('pages.labour.numberOfPersons'),
    type: 'integer',
    options: {},
    displayInLimits: true,
  },
  {
    value: 'numberOfWorkingDays',
    label: () => t('pages.labour.numberOfWorkingDays'),
    type: 'integer',
    options: {},
    displayInLimits: true,
  },
  {
    value: 'annualWorkUnits',
    label: () => t('pages.labour.annualWorkUnits'),
    type: 'double',
    options: {},
    displayInLimits: true,
  },
  {
    value: 'percentOfAWU',
    label: () => t('pages.labour.additionalAnnualWorkUnit'),
    type: 'integer',
    options: {},
    displayInLimits: true,
  },
  {
    value: 'salary',
    label: () => t('pages.labour.salary'),
    type: 'double',
    options: {},
    displayInLimits: true,
  },
  {
    value: 'additionalActivitiesPercent',
    label: () => t('pages.labour.additionalActivitiesPercent'),
    type: 'integer',
    options: {},
    displayInLimits: true,
  },
  {
    value: 'comment',
    label: () => t('pages.labour.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false,
  },
];

export default labourFields;