import { t } from 'react-switch-lang';
import { eng } from '../../administration/agriculturalHoldings';

const otherLivestockBasicFields = [
  {
    value: 'otherLivestockCategory',
    label: () => t('pages.other.categoryNameOther'),
    type: 'select', // integer, double, select, textarea, string
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api:'other-livestock-categories',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.other.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.other.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'offspringQuantity',
    label: () => t('pages.other.offspringQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'offspringValue',
    label: () => t('pages.other.offspringValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseQuantity',
    label: () => t('pages.other.purchaseQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseValue',
    label: () => t('pages.other.purchaseValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForSlaughterQuantity',
    label: () => t('pages.other.soldForSlaughterQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForSlaughterValue',
    label: () => t('pages.other.soldForSlaughterValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForBreedingQuantity',
    label: () => t('pages.other.soldForBreedingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForBreedingValue',
    label: () => t('pages.other.soldForBreedingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForUnknownReasonQuantity',
    label: () => t('pages.other.soldForUnknownReasonQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForUnknownReasonValue',
    label: () => t('pages.other.soldForUnknownReasonValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'saleQuantity',
    label: () => t('pages.other.saleQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'saleValue',
    label: () => t('pages.other.saleValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionQuantity',
    label: () => t('pages.other.ownConsumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionValue',
    label: () => t('pages.other.ownConsumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingQuantity',
    label: () => t('pages.other.spentOnProcessingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingValue',
    label: () => t('pages.other.spentOnProcessingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsQuantity',
    label: () => t('pages.other.paymentInProductsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsValue',
    label: () => t('pages.other.paymentInProductsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathQuantity',
    label: () => t('pages.other.deathQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathValue',
    label: () => t('pages.other.deathValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'inputQuantity',
    label: () => t('pages.other.inputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'outputQuantity',
    label: () => t('pages.other.outputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.other.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.other.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.other.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default otherLivestockBasicFields;