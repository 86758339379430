import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Resizable } from 'react-resizable';
import ThumbsDown from '../../../assets/images/icons/thumbsDown.svg';
import ThumbsDownFilled from '../../../assets/images/icons/thumbsDownFilled.svg';
import ThumbsUp from '../../../assets/images/icons/thumbsUp.svg';
import ThumbsUpFilled from '../../../assets/images/icons/thumbsUpFilled.svg';
import View from '../../../assets/images/icons/view.svg';
import 'antd/dist/antd.css';
import './ExplainableControlsTable.scss';
import { axiosGet } from '../../../services/axios';
import { t } from 'react-switch-lang';
import uniqid from 'uniqid';
import { hasValue } from '../../../utils/const';
import Loader from '../../loader/Loader';
import { useWindowWidth } from '../../../contexts/WindowWidth';

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
/**
 *
 * @param {object} props
 * @param {string} props.api 'Endpoint for fetching table data'
 * @param {array} props.columns 'Columns structure'
 * @param {integer} [props.rows=20] 'Number of rows to fetch'
 * @param {callback} [props.onRowClick] 'Callback function that takes record, rowIndex and click event parameters'
 * @param {object} props.methods 'Object containing onUpdate, onCreate, onDelete methods.'
 * @param {function} props.methods.onDelete 'Handles onDelete event. Must return response object.'
 * @param {function} props.methods.onUpdate 'Handles onUpdate event. Must return response object.'
 */

const ExplainableControlsTable = ({
                                    api,
                                    query,
                                    queryParamName,
                                    columns,
                                    rows = 20,
                                    onRowClick,
                                    methods,
                                    actionsWidth = '150px',
                                    onClickRow,
                                    updated,
                                    user,
                                    xScroll = true,
                                    ...rest
                                  }) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const windowWidth = useWindowWidth();

  let uniqueId = uniqid();

  // query = query ? (queryParamName ? `${queryParamName}=${query}` : `name.contains=${query}`) : '';

  const { onApprove, onDecline, onView } = methods;

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (api.includes('?')) {
        const { data } = await axiosGet(`${api}`);
        setItems(data);
        setHasMore(data.length === rows);
        setPage(1);
        setIsLoading(false);
      } else {
        const { data } = await axiosGet(`${api}?page=0&size=${rows}`);
        setItems(data);
        setHasMore(data.length === rows);
        setPage(1);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchMoreData = async () => {
    try {
      const { data } = await axiosGet(`${api}?page=${page}&size=${rows}`);
      setItems([...items, ...data]);
      setHasMore(data.length === rows);
      setPage((page) => page + 1);
    } catch (err) {
    }
  };

  useEffect(() => {
    if (api !== undefined && api !== '') {
      fetchData();
    }
  }, [api]);

  const mergedColumns = columns.map((col, index) => {
    return {
      ...col,
      title: () => <Tooltip title={col.title} placement="top" color={'#67B278'}
                            overlayInnerStyle={{ borderRadius: '5px' }}>{col.title}</Tooltip>,
      onCell: (record) => ({
        record,
        dataindex: col.dataIndex,
        rules: col.rules,
        options: col.options,
      }),
    };
  });

  // div for setting table height when table is loading
  const heightSetter = (
    <div style={{height: 40}} />
  )

  const loader = (
    <div style={{width: 40, zIndex: 30}} className=' mt-3'>
      <Loader size='sm' />
    </div>
  );

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize = (index) => (e, { size }) => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    return { columns: nextColumns };
  };

  const resizeColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const handleApprove = async (id) => {
    try {
      await onApprove(id);
      fetchData();
    } catch (err) {
    }
  };

  const handleDecline = async (id) => {
    try {
      await onDecline(id);
      fetchData();
    } catch (err) {
    }
  };

  user.isAdmin && mergedColumns.push({
    title: t('common.actions'),
    key: 'actions',
    align: 'center',
    width: actionsWidth ? actionsWidth : 100,
    fixed: 'right',
    render: (record) => {
      return (
        <div className='d-flex justify-content-center'>
          {hasValue(record?.isApproved) && record?.isApproved === true ?
            <Tooltip title={t('common.approve')} placement="top" color={'#67B278'}
                     overlayInnerStyle={{ borderRadius: '5px' }}>
              <button
                type="button"
                className="btn p-0 mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  handleApprove(record?.id);
                }}
              >
                <img src={ThumbsUpFilled} alt="approve"/>
              </button>
            </Tooltip>
            :
            <Tooltip title={t('common.approve')} placement="top" color={'#67B278'}
                     overlayInnerStyle={{ borderRadius: '5px' }}>
              <button
                type="button"
                className="btn p-0 mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  handleApprove(record?.id);
                }}
              >
                <img src={ThumbsUp} alt="approve"/>
              </button>
            </Tooltip>
          }
          {hasValue(record?.isApproved) && record?.isApproved === false ?
            <Tooltip title={t('common.decline')} placement="top" color={'#E50019'}
                     overlayInnerStyle={{ borderRadius: '5px' }}>
              <button
                type="button"
                className="btn p-0 mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDecline(record?.id);
                }}
              >
                <img src={ThumbsDownFilled} alt="decline"/>
              </button>
            </Tooltip>
            :
            <Tooltip title={t('common.decline')} placement="top" color={'#E50019'}
                     overlayInnerStyle={{ borderRadius: '5px' }}>
              <button
                type="button"
                className="btn p-0 mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDecline(record?.id);
                }}
              >
                <img src={ThumbsDown} alt="decline"/>
              </button>
            </Tooltip>
          }
          <Tooltip title={t('common.view')} placement="top" color={'#67B278'}
                   overlayInnerStyle={{ borderRadius: '5px' }}>
            <button
              type="button"
              className="btn p-0 mr-2"
              onClick={(e) => {
                e.stopPropagation();
                onView(record);
              }}
            >
              <img src={View} alt="view"/>
            </button>
          </Tooltip>
        </div>
      );
    },
  });

  return (
    <div>
      <InfiniteScroll
        next={fetchMoreData}
        hasMore={hasMore}
        loader={loader}
        scrollableTarget="agroContainer"
        dataLength={items.length}
        style={{ overflowY: 'hidden' }}
      >
        <div id={'explainableControlsTable'}>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: () => record?.id && (onClickRow ? onClickRow(record) : null),
              };
            }}
            loading={{indicator: loader, spinning: isLoading}}
            components={components}
            columns={mergedColumns}
            dataSource={items}
            // sticky={true}
            pagination={false}
            locale={{ emptyText: !isLoading ? t('common.noData') : heightSetter }}
            rowKey={(record) => record.id}
            scroll={{ x: xScroll && windowWidth < 1100 ? 1500 : false }}
            {...rest}
          />
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ExplainableControlsTable;
