import { Tooltip } from 'antd';
import { t } from 'react-switch-lang';
import React from 'react';
import { hasValue } from '../../../../../../utils/const';
import { makeLink } from './makeLink';
import dataModels from '../../../../explainableControls/utils/limitsData';
import '../LockingErrors.scss';
import LockingErrorExplanation from './LockingErrorsExplanation';

const renderColWithTooltip = (col) => {
  return <Tooltip title={col} placement="topLeft" color={'#67B278'}
                  overlayInnerStyle={{ borderRadius: '5px' }}>{hasValue(col) ? col : '/'}</Tooltip>;
};

const renderTableName = (dataModel) => {
  const model = dataModels.find(item => item.value === dataModel);
  return model?.label ? model?.label() : dataModel ? dataModel : '/';
};

const renderFieldName = (dataModel, fieldName) => {
  const model = dataModels.find(item => item.value === dataModel);
  const field = model?.fields ? model.fields.find(item => item.value === fieldName) : null;
  return field?.label ? field?.label() : fieldName ? fieldName : '/';
};

export const columns = (windowWidth) => [
  {
    title: t('administration.lockingErrors.id'),
    dataIndex: 'entityId',
    key: 'entityId',
    ellipsis: true,
    width: 100,
    render: entityId => renderColWithTooltip(entityId),
  },
  {
    title: t('administration.lockingErrors.dataModel'),
    dataIndex: 'objectName',
    key: 'objectName',
    ellipsis: true,
    render: objectName => renderColWithTooltip(renderTableName(objectName)),
  },
  {
    title: t('administration.lockingErrors.field'),
    // dataIndex: 'field',
    key: 'field',
    ellipsis: false,
    render: row => hasValue(row?.entityId) && row?.objectName !== 'agroHolding' ?
      <a target={'_blank'} href={makeLink(row)}
         className={'tableLink'}>{renderColWithTooltip(renderFieldName(row?.objectName, row?.field))}</a> : renderFieldName(row?.objectName, row?.field),
  },
  {
    title: t('administration.lockingErrors.errorType'),
    dataIndex: 'errorType',
    key: 'errorType',
    ellipsis: false,
    render: errorType => renderColWithTooltip(errorType),
  },
  {
    title: t('administration.lockingErrors.dvcNumber'),
    dataIndex: 'dvcNumber',
    key: 'dvcNumber',
    ellipsis: true,
    render: dvcNumber => renderColWithTooltip(dvcNumber),
  },
  {
    title: t('administration.lockingErrors.description'),
    dataIndex: 'message',
    key: 'message',
    ellipsis: false,
    render: message => renderColWithTooltip(message),
  },
  {
    title: t('administration.lockingErrors.explanation'),
    // dataIndex: 'message',
    key: 'explanation',
    ellipsis: false,
    width: windowWidth > 400 ? '216px' : '',
    render: row => row?.objectName === 'agroHolding' && row?.errorType === 'EXPLAIN' ?
      <LockingErrorExplanation row={row}/> : '/',
  },
];