import React, { Suspense, useEffect, useState } from 'react';
import classes from './Buttons.module.scss';
import PropTypes from 'prop-types';
import { t } from 'react-switch-lang';
import Tick from '../../assets/images/icons/tick-dark.svg';
import Icon from '@ant-design/icons';
import { hasValue } from '../../utils/const';
import { useRedirectPrompt } from '../../contexts/RedirectPrompt';

// const MyIcon = React.lazy(() => import('../../components/icons/MyIcon'));
const Loader = React.lazy(() => import('../../components/loader/Loader'));

// if loaderOnDisabled true and loader true - loaderIcon is shown when btn is disabled
// if loaderOnDisabled false - loaderIcon is shown when loader is true

// only icon - set label '' icon true

const SaveBtn = ({
                   onClick,
                   label = t('common.save'),
                   className = '',
                   disabled = false,
                   loader = true,
                   icon = false,
                   iconSvg,
                   iconAntd,
                   loaderOnDisabled = true,
                   thin = false,
                   form = false,
                   formValidation = {},
                   keepDirty = false
                 }) => {

  const [isFormValidated, setIsFormValidated] = useState(false);

  const { setIsDirty } = useRedirectPrompt();

  const checkValidation = () => {
    let objectArray = Object.values(formValidation);
    for (let i = 0; i < objectArray.length; i++) {
      if (hasValue(objectArray[i]?.apiError)) {
        setIsFormValidated(false);
        break;
      } else {
        setIsFormValidated(true);
      }
    }
  };

  useEffect(() => {
    isFormValidated && onClick && onClick();
  }, [isFormValidated]);


  return (
    <button
      className={`${className} ${thin ? classes['save-grey'] : classes['save']} ${classes['button']} ${thin && classes['button--thin_button']} ${
        label.length > 0 ? '' : 'px-0 py-1'
      }`}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
        !keepDirty && setIsDirty(false);

        // if (!form) {
        //   onClick && onClick();
        // } else {
        //   checkValidation();
        // }
      }}
    >
      {icon && (
        // <Suspense fallback={<></>}>
        //   <MyIcon
        //     className={`${label.length > 0 ? 'mr-1' : 'mx-1'}`}
        //     imgName="tick-white.svg"
        //   />
        // </Suspense>
        iconAntd ? <Icon className='mr-1' component={iconAntd} alt="icon"/> :
          <img className='mr-1' src={iconSvg ? iconSvg : Tick} alt='icon'/>
      )}
      <span className={`${label.length > 0 ? classes['text'] : ''}`}>
        {label}
      </span>
      {(loaderOnDisabled && loader && disabled) ||
      (!loaderOnDisabled && loader) ? (
        <Suspense fallback={<></>}>
          <Loader color="light"/>
        </Suspense>
      ) : null}
    </button>
  );
};

SaveBtn.propTypes = {
  label: PropTypes.string,
};

export default SaveBtn;