import React, { useEffect, useState } from 'react';
import FieldContainer from '../fieldContainer/FieldContainer';
import classes from './CheckboxField.module.scss';
import Checkbox from './components/Checkbox';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { validate } from '../../../utils/validation';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { compareObjects } from "../../../utils/const";
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

//     const optionList = [
//         {
//             value: 'val1',
//             label: 'Val 1',
//             disabled: true
//         },
//         {
//             value: 'val2',
//             label: 'Val 2'
//         },
//         {
//             value: 'val3',
//             label: 'Val 3'
//         }
//     ]

//  const [checkbox, setCheckbox] = useState();

// <CheckboxField
//                 label="Test checkbox"
//                 options={options}
//                 value={checkbox}
//                 onChange={(e) => setCheckbox(e)}/>

const CheckboxField = React.forwardRef(
  (
    {
      label,
      className,
      options,
      name,
      value = [],
      onChange,
      disabled,
      validation = { type: '' },
      setError,
      displayError,
      align,
      ...rest
    },
    ref,
  ) => {
    let uniqueId = uniqid();
    let componentName = name ? name : uniqueId;

    const redirectPrompt = useRedirectPrompt();

    const [checkbox, setCheckbox] = useState(value);

    const [errMessage, setErrMessage] = useState(validate(validation, value));

    const prevVal = usePrevious(value);
    const prevValidation = usePrevious(validation);

      useEffect(() => {
          // on component unmounting return states to initial
          return () => {
              setCheckbox([])
              setErrMessage({})
          }
      }, [])

    const compareArrays = (oldArr, newArr) => {
      if (Array.isArray(oldArr) && Array.isArray(newArr)) {
        return !(
          oldArr.length === newArr.length &&
          oldArr.every((value, index) => value === newArr[index])
        );
      } else {
        return false;
      }
    };

    useEffect(() => {
      //   // eslint-disable-next-line
      // if ((!compareObjects(prevVal, value)) || (!compareObjects(validation, prevValidation))) {
      //   let error = validate(validation, checkbox);
      //   if (error.toString().length > 0) {
      //     setError && setError(true);
      //   } else {
      //     setError && setError(false);
      //   }
      //   setErrMessage(error);
      // }

      // eslint-disable-next-line
      if ((!compareObjects(prevVal, value)) || (!compareObjects(validation, prevValidation))) {
        let errorObject = validate(validation, checkbox);
        if (errorObject.type !== 'noErrors') {
          if (errorObject.type === 'apiError') {
            setError('apiError');
          } else if (errorObject.type === 'error') {
            setError('error');
          }
        } else {
          setError('noErrors');
        }
        setErrMessage(errorObject);
      }
        // eslint-disable-next-line
    }, [checkbox, validation]);

    // handles setting checked/unchecked on data load
    useEffect(() => {
      setCheckbox(value);
    }, [value]);

    const handleCheckbox = (e) => {
      if (checkbox.includes(e)) {
        const newCheckbox = checkbox.filter((item) => item !== e);
        setCheckbox(newCheckbox);
        onChange(newCheckbox);
      } else {
        const newCheckbox = [...checkbox, e];
        setCheckbox(newCheckbox);
        onChange(newCheckbox);
      }
    };

    return (
      <FieldContainer
        label={label}
        required={
          validation
            ? validation.required
              ? validation.required.value === true
              : false
            : false
        }
        errorMessage={!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? errMessage : <></>}
        {...rest}
      >
        <div
          className={`${className ? className : ''}`}
        >
          {Array.isArray(options) &&
          options.map((item, index) => {
            return (
              <Checkbox
                key={`${name}-${index}`}
                checked={checkbox}
                name={componentName}
                ref={index === 0 ? ref : null}
                value={item.value}
                label={item.label}
                onChange={(e) => {
                  handleCheckbox(e);
                  redirectPrompt.setIsDirty(true);
                }}
                disabled={
                  disabled === true
                    ? true
                    : item.disabled !== undefined
                      ? item.disabled
                      : false
                }
                align={align}
              />
            );
          })}
        </div>
      </FieldContainer>
    );
  },
);

CheckboxField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    }),
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  validation: PropTypes.object,
  setError: PropTypes.func,
  displayError: PropTypes.object,
};

export default CheckboxField;
