import React, { useEffect, useState } from 'react';
import { getLanguage, t } from 'react-switch-lang';
import Swal from 'sweetalert2';
import { fetchRevisionList } from '../../../../services/revision/revision';
import { format } from 'date-fns';
import HistoryItem from './HistoryItem';
import { useHistory } from 'react-router-dom';

const returnUserName = (data) => {
  return <div>{data?.userFirstName ? data?.userFirstName : ''} {data?.userLastName ? data?.userLastName : ''}</div>;
};

const formatTime = (time) => {
  //insertTime
  //04.05.21, 14:34h (č if language is set on mne)
  const lang = getLanguage();

  return `${format(new Date(time), `dd.MM.yy, ${lang === 'mne' ? 'HH.mm' : 'HH:mm'}`)}${lang === 'mne' ? 'č' : 'h'}`;
};

const HistoryList = ({ ahId, group, entity, locationEntity, entityId, activeRevisionNumber, setLastRevisionNumber }) => {
  const history = useHistory();
//revisionNumber
  //insertTime
  //revisionType
  //userFirstName userLastName
  const revisionType = {
    DELETE: {
      title: t('revisionType.delete'),
      color: 'red',
    },
    INSERT: {
      title: t('revisionType.insert'),
      color: 'green',
    },
    UPDATE: {
      title: t('revisionType.update'),
      color: 'orange',
    },
  };

  const goTo = (revisionNumber) => {
    history.push(`${ahId ? `/${ahId}` : ''}/revisions${group ? `/${group}` : ''}${locationEntity ? `/${locationEntity}` : ''}${entityId ? `/${entityId}` : ''}${revisionNumber ? `/${revisionNumber}` : ''}`);
  };

  const [list, setList] = useState([]);

  const fetchList = async (entity, entityId) => {
    try {
      const response = await fetchRevisionList(entity, entityId);
      setLastRevisionNumber(response?.data[0] ? response?.data[0]?.revisionNumber : 0);
      setList(response?.data?.map(item => ({
        ...item,
        time: formatTime(item.insertTime),
        user: returnUserName(item),
        type: { ...revisionType[item.revisionType] },
      })));
    } catch (err) {
      Swal.fire({
        text: t('common.apiErrorDelete'),
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  useEffect(() => {
    if (entity && entityId) {
      fetchList(entity, entityId);
    }
  }, [entity, entityId]);

  return <div>
    {list && list?.length === 0
      ? <div className="p-2">{t('messages.noHistoryData')}</div>
      : list?.map(item => <HistoryItem time={item?.time}
                                       key={`${item?.revisionNumber}-${activeRevisionNumber}`}
                                       type={{ ...item.type, revisionType: item?.revisionType }}
                                       user={item?.user}
                                       goTo={() => goTo(item?.revisionNumber)}
                                       revisionNumber={item?.revisionNumber}
                                       activeRevisionNumber={activeRevisionNumber}/>)}
  </div>;
};

export default HistoryList;