import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import SelectConstField from '../../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import classes from './SubsidyForm.module.scss';
import { Toast } from '../../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../../utils/const';
import UserData from '../../../../../../contexts/UserData';
import {
  getAllBasicUnitTypeLists,
  getAllFinancedTypes,
  getAllGrantSubsidyCategories,
  getAllGrantTypes,
  getAllSubsidyCategories,
  getSubsidy,
} from '../../../../../../services/money/grants/subsidies';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../../components/formDynamic/helpers';
import Loader from '../../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';

const SubsidyForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const [subsidy, setSubsidy] = useState();

  const [subsidyCategory, setSubsidyCategory] = useState({ value: undefined, disabled: !edit });
  const [grantSubsidyCategory, setGrantSubsidyCategory] = useState({ value: undefined, disabled: !edit });
  const [grantBasicUnitsType, setGrantBasicUnitsType] = useState({ value: undefined, disabled: !edit });
  const [grantFinancedType, setFinancedType] = useState({ value: undefined, disabled: !edit });
  const [grantType, setGrantType] = useState({ value: undefined, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [forLivestockPurposeValue, setForLivestockPurposeValue] = useState({ value: 0, disabled: !edit });
  const [forPlantPurposeValue, setForPlantPurposeValue] = useState({ value: 0, disabled: !edit });
  const [numberOfBasicUnits, setNumberOfBasicUnits] = useState({ value: 0, disabled: !edit });
  const [totalValue, setTotalValue] = useState({ value: 0, disabled: !edit });

  const [subsidyCategoryOptions, setSubsidyCategoryOptions] = useState([]);
  const [grantSubsidyCategoryOptions, setGrantSubsidyCategoryOptions] = useState([]);
  const [grantBasicUnitsTypeOptions, setGrantBasicUnitsTypeOptions] = useState([]);
  const [grantFinancedTypeOptions, setFinancedTypeOptions] = useState([]);
  const [grantTypeOptions, setGrantTypeOptions] = useState([]);

  const [errorExplanations, setErrorExplanations] = useState([]);

  const validationInitial = {
    subsidyCategory: { type: 'selectSingle', required: { value: true } },
    grantSubsidyCategory: { type: 'selectSingle', required: { value: true } },
    grantBasicUnitsType: { type: 'selectSingle', required: { value: false } },
    grantType: { type: 'selectSingle', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    forLivestockPurposeValue: { type: 'number', required: { value: false } },
    forPlantPurposeValue: { type: 'number', required: { value: false } },
    numberOfBasicUnits: { type: 'number', required: { value: false } },
    totalValue: { type: 'number', required: { value: false } },
    grantFinancedType: { type: 'selectSingle', required: { value: false } },
  };

  const [validation, setValidation] = useState({ ...validationInitial });
  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  /***** START SET API ERRORS *****/
//subsidy
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'subsidy', subsidy, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(hasValue(subsidyCategory.value?.value) && { subsidyCategory: { id: parseInt(subsidyCategory.value?.value) } }),
        ...(hasValue(grantSubsidyCategory.value?.value) && { grantSubsidyCategory: { id: parseInt(grantSubsidyCategory.value?.value) } }),
        ...(hasValue(grantBasicUnitsType.value?.value) && { grantBasicUnitsType: { id: parseInt(grantBasicUnitsType.value?.value) } }),
        ...(hasValue(grantFinancedType.value?.value) && { grantFinancedType: { id: parseInt(grantFinancedType.value?.value) } }),
        ...(hasValue(grantType.value?.value) && { grantType: { id: parseInt(grantType.value?.value) } }),
        ...(hasValue(comment?.value) && { comment: comment.value }),
        ...(hasValue(forLivestockPurposeValue?.value) && { forLivestockPurposeValue: parseFloatCustom(forLivestockPurposeValue.value) }),
        ...(hasValue(forPlantPurposeValue?.value) && { forPlantPurposeValue: parseFloatCustom(forPlantPurposeValue.value) }),
        ...(hasValue(numberOfBasicUnits?.value) && { numberOfBasicUnits: parseFloatCustom(numberOfBasicUnits.value) }),
        ...(hasValue(totalValue?.value) && { totalValue: parseFloatCustom(totalValue.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`subsidy-grants?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.subsidy') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add');
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`subsidy-grants/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.subsidy') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit');
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchSubsidy = async () => {
    try {
      const { data } = await getSubsidy(props.match.params.id);
      setSubsidy(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchGrantSubsidyCategoriesOptions = async () => {
    const arr = await getAllSubsidyCategories();
    setSubsidyCategoryOptions(arr);
  };

  const [grantSubsidyCategoryOptionsRaw, setGrantSubsidyCategoryOptionsRaw] = useState([]);
  const fetchProgramNameOptions = async (id) => {
    const arr = await getAllGrantSubsidyCategories(id);
    setGrantSubsidyCategoryOptions(arr.filteredOptions);
    setGrantSubsidyCategoryOptionsRaw(arr.rawOptions);
  };

  const fetchGrantBasicUnitTypes = async () => {
    const arr = await getAllBasicUnitTypeLists();
    setGrantBasicUnitsTypeOptions(arr);
  };

  const fetchGrantTypes = async () => {
    const arr = await getAllGrantTypes();
    setGrantTypeOptions(arr);
  };

  const fetchFinancedType = async () => {
    const arr = await getAllFinancedTypes();
    setFinancedTypeOptions(arr);
  };


  useEffect(() => {
    fetchGrantSubsidyCategoriesOptions();
    // fetchProgramNameOptions();
    fetchGrantBasicUnitTypes();
    fetchGrantTypes();
    fetchFinancedType();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchSubsidy().then(subsidy => {
          subsidy?.subsidyCategory && setSubsidyCategory(prevState => ({
            ...prevState,
            value: defaultOption(subsidy?.subsidyCategory),
          }));
          subsidy?.grantSubsidyCategory && setGrantSubsidyCategory(prevState => ({
            ...prevState,
            value: defaultOption(subsidy?.grantSubsidyCategory),
          }));
          subsidy?.grantBasicUnitsType && setGrantBasicUnitsType(prevState => ({
            ...prevState,
            value: defaultOption(subsidy?.grantBasicUnitsType),
          }));
          subsidy?.grantType && setGrantType(prevState => ({
            ...prevState,
            value: defaultOption(subsidy?.grantType),
          }));
          subsidy?.grantFinancedType && setFinancedType(prevState => ({
            ...prevState,
            value: defaultOption(subsidy?.grantFinancedType),
          }));
          setComment(prevState => ({ ...prevState, value: subsidy?.comment }));
          setForLivestockPurposeValue(prevState => ({
            ...prevState,
            value: parseDouble(subsidy?.forLivestockPurposeValue),
          }));
          setForPlantPurposeValue(prevState => ({ ...prevState, value: parseDouble(subsidy?.forPlantPurposeValue) }));
          setNumberOfBasicUnits(prevState => ({ ...prevState, value: parseDouble(subsidy?.numberOfBasicUnits) }));
          setTotalValue(prevState => ({ ...prevState, value: parseDouble(subsidy?.totalValue) }));

          setErrorExplanations(subsidy?.errorExplanations ? subsidy?.errorExplanations : []);

          let explanations = subsidy?.errorExplanations ? subsidy?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'subsidy', subsidy, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        data?.subsidyCategory && setSubsidyCategory(prevState => ({
          ...prevState,
          value: defaultOption(data?.subsidyCategory),
        }));
        data?.grantSubsidyCategory && setGrantSubsidyCategory(prevState => ({
          ...prevState,
          value: defaultOption(data?.grantSubsidyCategory),
        }));
        data?.grantBasicUnitsType && setGrantBasicUnitsType(prevState => ({
          ...prevState,
          value: defaultOption(data?.grantBasicUnitsType),
        }));
        data?.grantType && setGrantType(prevState => ({
          ...prevState,
          value: defaultOption(data?.grantType),
        }));
        data?.grantFinancedType && setFinancedType(prevState => ({
          ...prevState,
          value: defaultOption(data?.grantFinancedType),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setForLivestockPurposeValue(prevState => ({
          ...prevState,
          value: parseDouble(data?.forLivestockPurposeValue),
        }));
        setForPlantPurposeValue(prevState => ({ ...prevState, value: parseDouble(data?.forPlantPurposeValue) }));
        setNumberOfBasicUnits(prevState => ({ ...prevState, value: parseDouble(data?.numberOfBasicUnits) }));
        setTotalValue(prevState => ({ ...prevState, value: parseDouble(data?.totalValue) }));

        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'edit', validation, 'subsidy', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        // setValidation({ ...validationInitial });
        setSubsidyCategory(prevState => ({ ...prevState, disabled: false }));
        setGrantSubsidyCategory(prevState => ({ ...prevState, disabled: false }));
        setGrantBasicUnitsType(prevState => ({ ...prevState, disabled: true }));
        setGrantType(prevState => ({ ...prevState, disabled: false }));
        setFinancedType(prevState => ({ ...prevState, disabled: true }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setForLivestockPurposeValue(prevState => ({ ...prevState, disabled: false }));
        setForPlantPurposeValue(prevState => ({ ...prevState, disabled: false }));
        setNumberOfBasicUnits(prevState => ({ ...prevState, disabled: false }));
        setTotalValue(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  const [counter, setCounter] = useState(0);
  const [counterProgramName, setCounterProgramName] = useState(0);

  useEffect(() => {
    if (!isAudit) {
      setCounter(counter + 1);
      setCounterProgramName(counterProgramName + 1);
      if (hasValue(subsidyCategory?.value?.value)) {
        fetchProgramNameOptions(subsidyCategory?.value?.value);
        counter > 1 ? setGrantSubsidyCategory(prevState => ({
          ...prevState,
          value: null,
          disabled: false,
        })) : setGrantSubsidyCategory(prevState => ({ ...prevState, disabled: !edit }));
      } else {
        setGrantSubsidyCategory(prevState => ({ ...prevState, disabled: true }));
      }
    }
  }, [subsidyCategory?.value?.value]);

  useEffect(() => {
    if (!isAudit) {
      setCounterProgramName(counterProgramName + 1);
      // kakvo seljacenje omg - slazem se
      if ((!location.pathname.includes('add') && counterProgramName > 2) || (location.pathname.includes('add') && counterProgramName > 1)) {
        if (hasValue(grantSubsidyCategory?.value?.value)) {
          const selectedProgramName = grantSubsidyCategoryOptionsRaw.filter(grantSubsidyCategoryOptionRaw => {
            return grantSubsidyCategoryOptionRaw.id === parseInt(grantSubsidyCategory?.value?.value);
          });

          const grantBasicUnitTypeTemp = selectedProgramName[0].grantBasicUnitType;
          const grantFinancedTypeTemp = selectedProgramName[0].grantFinancedType;

          setGrantBasicUnitsType(prevState => ({
            ...prevState,
            value: {
              label: eng ? grantBasicUnitTypeTemp?.nameEn : grantBasicUnitTypeTemp?.name,
              value: grantBasicUnitTypeTemp?.id,
            },
            disabled: true,
          }));
          setFinancedType(prevState => ({
            ...prevState,
            value: {
              label: eng ? grantFinancedTypeTemp?.nameEn : grantFinancedTypeTemp?.name,
              value: grantFinancedTypeTemp?.id,
            },
            disabled: true,
          }));
        } else {
          setGrantBasicUnitsType(prevState => ({ ...prevState, value: null, disabled: true }));
          setFinancedType(prevState => ({ ...prevState, value: null, disabled: true }));
        }
      } else {
        setGrantBasicUnitsType(prevState => ({ ...prevState, disabled: true }));
        setFinancedType(prevState => ({ ...prevState, disabled: true }));
      }
    }
  }, [grantSubsidyCategory?.value]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm'/>
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    label={t('pages.grantsSubsidies.subsidyCategory')}
                    placeholder={t('pages.grantsSubsidies.placeholder.subsidyCategory')}
                    value={subsidyCategory.value}
                    name='subsidyCategory'
                    onChange={(e) => {
                      setSubsidyCategory(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={subsidyCategoryOptions}
                    disabled={subsidyCategory.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['subsidyCategory']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <SelectConstField
                    label={t('pages.grantsSubsidies.grantSubsidyCategory')}
                    placeholder={t('pages.grantsSubsidies.placeholder.grantSubsidyCategory')}
                    value={grantSubsidyCategory.value}
                    name='grantSubsidyCategory'
                    onChange={(e) => {
                      setGrantSubsidyCategory(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={grantSubsidyCategoryOptions}
                    disabled={grantSubsidyCategory.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['grantSubsidyCategory']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <SelectConstField
                    label={t('pages.grantsSubsidies.grantBasicUnitsType')}
                    placeholder={t('pages.grantsSubsidies.placeholder.grantBasicUnitsType')}
                    value={grantBasicUnitsType.value}
                    name='grantBasicUnitsType'
                    onChange={(e) => {
                      setGrantBasicUnitsType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={grantBasicUnitsTypeOptions}
                    disabled={grantBasicUnitsType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['grantBasicUnitsType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <SelectConstField
                    label={t('pages.grantsSubsidies.grantFinancedType')}
                    placeholder={t('pages.grantsSubsidies.placeholder.grantFinancedType')}
                    value={grantFinancedType.value}
                    name='grantFinancedType'
                    onChange={(e) => {
                      setFinancedType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={grantFinancedTypeOptions}
                    disabled={grantFinancedType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['grantFinancedType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.grantsSubsidies.numberOfBasicUnits')}
                    placeholder={t('pages.grantsSubsidies.placeholder.numberOfBasicUnits')}
                    value={numberOfBasicUnits.value}
                    name='numberOfBasicUnits'
                    type={'double'}
                    onChange={(e) => {
                      setNumberOfBasicUnits({
                        ...numberOfBasicUnits,
                        value: e,
                      });
                    }}
                    disabled={numberOfBasicUnits.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['numberOfBasicUnits']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <SelectConstField
                    label={t('pages.grantsSubsidies.grantType')}
                    placeholder={t('pages.grantsSubsidies.placeholder.grantType')}
                    value={grantType.value}
                    name='grantType'
                    onChange={(e) => {
                      setGrantType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={grantTypeOptions}
                    disabled={grantType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['grantType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    label={t('pages.grantsSubsidies.forLivestockPurposeValues')}
                    placeholder={t('pages.grantsSubsidies.placeholder.forLivestockPurposeValues')}
                    value={forLivestockPurposeValue.value}
                    name='forLivestockPurposeValue'
                    type={'double'}
                    onChange={(e) => {
                      setForLivestockPurposeValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={forLivestockPurposeValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['forLivestockPurposeValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.grantsSubsidies.forPlantPurposeValues')}
                    placeholder={t('pages.grantsSubsidies.placeholder.forPlantPurposeValues')}
                    value={forPlantPurposeValue.value}
                    name='forPlantPurposeValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setForPlantPurposeValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={forPlantPurposeValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['forPlantPurposeValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.grantsSubsidies.totalValue')}
                    placeholder={t('pages.grantsSubsidies.placeholder.totalValue')}
                    value={totalValue.value}
                    name='totalValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setTotalValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={totalValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['totalValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <TextareaField
                    label={t('pages.grantsSubsidies.comment')}
                    placeholder={t('pages.grantsSubsidies.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className='button cancel'
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/subsidies`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className='button save'
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default SubsidyForm;
