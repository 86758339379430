import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import './SelectField.scss';
import FieldContainer from '../fieldContainer/FieldContainer';
import { validate } from '../../../utils/validation';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

// ! label is optional prop !

// if single - initial empty value undefined
// if multi - initial empty value []

//     const options = [
//         {
//             value: 'val1',
//             label: 'label1'
//         }, {
//             value: 'val2',
//             label: 'label2'
//         }, {
//             value: 'val3',
//             label: 'label3'
//         },
//     ]

//      <SelectConstField
//                 onChange={(e) => {
//                 }}
//                 options={options}/>

const SelectConstField = React.forwardRef(
  (
    {
      label,
      name,
      className,
      placeholder,
      disabled = false,
      isClearable = false,
      isSearchable = true,
      isMulti = false,
      value,
      defaultValue,
      options = [],
      onChange,
      validation,
      displayError,
      setError,
      api,
      refresh,
      styles,
      tabIndex,
      isAudit = false,
      ...rest
    },
    ref
  ) => {
    let uniqueId = uniqid();
    let componentName = name ? name : uniqueId;

    const redirectPrompt = useRedirectPrompt();

    const [errMessage, setErrMessage] = useState(validate(validation, value));

    const prevVal = usePrevious(value);
    const prevValidation = usePrevious(validation);

      useEffect(() => {
        // on component unmounting return states to initial
          return () => {
              setErrMessage({})
          }
      }, []);

    useEffect(() => {
      // eslint-disable-next-line
      if ((!compareObjects(prevVal, value)) || (!compareObjects(validation, prevValidation))) {
        let errorObject = validate(validation, value);
        if (errorObject.type !== 'noErrors') {
          if (errorObject.type === 'apiError') {
            setError('apiError')
          } else {
            setError('error')
          }
        } else {
          setError('noErrors')
        }
        setErrMessage({...errorObject});
      }
        // eslint-disable-next-line
    }, [value, validation]);



    return (
      <FieldContainer
        componentName={componentName}
        label={label}
        required={
          validation
            ? validation.required
              ? validation.required.value === true
              : false
            : false
        }
        errorMessage={!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? errMessage : <></>}
        customClasses={`${styles}`}
        {...rest}
      >
        <Select
          tabIndex={tabIndex}
          className={`select-field-general ${className ? className : ''} ${
            disabled ? 'disabled' : ''
          } ${!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? ' error ' : ''}`}
          data-refkey={componentName}
          placeholder={disabled ? '' : placeholder ? placeholder : t('common.selectValue')}
          value={value}
          defaultValue={defaultValue}
          isDisabled={disabled}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isMulti={isMulti}
          noOptionsMessage={() => t('common.noData')}
          loadingMessage={() => t('common.loading')}
          onChange={(e) => {
            onChange(e);
            !isAudit && redirectPrompt.setIsDirty(true);
          }}
          name={componentName}
          options={options}
          isOptionDisabled={(option) => option.disabled}
          ref={ref}
          inputId={componentName}
        />
      </FieldContainer>
    );
  }
);

SelectConstField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isClearable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isSearchable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  validation: PropTypes.object,
  setError: PropTypes.func,
  displayError: PropTypes.object
};

export default SelectConstField;
