import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import ModalWrapper from '../modal/modalWrapper/ModalWrapper';
import { t } from 'react-switch-lang';
import CancelBtn from '../buttons/CancelBtn';
import DeleteBtn from '../buttons/DeleteBtn';

const RouteLeavingGuard = ({ when, reset }) => {
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const showModal = (location) => {
    setIsModalVisible(true);
    setLastLocation(location);
  };

  const closeModal = () => setIsModalVisible(false);

  // handles stock prompt and replaces it with our custom modal
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      showModal(nextLocation);
      return false; // stop redirect
    }
    return true; // do redirect
  };

  // closes modal and redirects if link is present and confirm is clicked
  const handleConfirmNavigationClick = () => {
    if (lastLocation) {
      setConfirmedNavigation(true);
    }
    closeModal();
  };

  // effect for navigation after page leave is confirmed
  useEffect(() => {
    if (confirmedNavigation) {
      history.push(lastLocation?.pathname);
    }
  }, [confirmedNavigation]);

  // uses context to clean states when redirect happens
  useEffect(() => {
    setIsModalVisible(false);
    setLastLocation(null);
    setConfirmedNavigation(false);
  }, [reset]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ModalWrapper onClose={closeModal} isModalOpen={isModalVisible}>
        <span>{t('common.redirectConfirm')}</span>
        <div className="d-flex justify-content-center mb-2 mt-4">
          <DeleteBtn
            label={t('common.redirectLeave')}
            icon={false}
            onClick={handleConfirmNavigationClick}
          />
          <CancelBtn
            label={t('common.redirectStay')}
            className="text-capitalize"
            onClick={closeModal}
            keepDirty={true}
          />
        </div>
      </ModalWrapper>
    </>
  );
};
export default RouteLeavingGuard;
