import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import './SelectField.scss';
import FieldContainer from '../fieldContainer/FieldContainer';
import { checkIfExplanationValid, validate } from '../../../utils/validationDynamic';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

// ! label is optional prop !

// if single - initial empty value undefined
// if multi - initial empty value []

//     const options = [
//         {
//             value: 'val1',
//             label: 'label1'
//         }, {
//             value: 'val2',
//             label: 'label2'
//         }, {
//             value: 'val3',
//             label: 'label3'
//         },
//     ]

//      <SelectConstField
//                 onChange={(e) => {
//                 }}
//                 options={options}/>

const SelectConstField = (
  {
    label,
    helpLabel,
    showErrors,
    showRequiredError,
    isDirtyProp = true,
    name,
    className,
    placeholder,
    onSubmit = () => {
    },
    setSubmitDisabled,
    errorExplanations,
    setErrorExplanations = () => {
    },
    hasWriteExplanationPermission = true,
    disabled = false,
    isClearable = false,
    isSearchable = true,
    isMulti = false,
    value,
    defaultValue,
    options = [],
    onChange,
    onClick,
    validation,
    displayError,
    api,
    refresh,
    styles,
    notRequired = false,
    ...rest
  },
) => {
  let uniqueId = uniqid();
  let componentName = name ? name : uniqueId;

  const redirectPrompt = useRedirectPrompt();

  const [messageData, setMessageData] = useState(validate({ ...validation, required: { value: !disabled } }, value));
  const [isDirty, setIsDirty] = useState(false);

  const prevVal = usePrevious(value);
  const prevValidation = usePrevious({ ...validation, required: { value: !disabled } });

  useEffect(() => {
    // on component unmounting return states to initial
    return () => {
      setMessageData({});
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    if ((!compareObjects(prevVal, value)) || (!compareObjects({
      ...validation,
      required: { value: !disabled },
    }, prevValidation))) {
      let errorObject = validate({ ...validation, required: { value: !disabled } }, value);
      if (errorObject?.required?.errorType === 'required' || errorObject?.apiErrors?.errorType === 'apiError') {
        setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
      } else {
        if (!checkIfExplanationValid(errorObject)) {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
        } else {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: false }));
        }
      }
      isDirty && disabled === false && onSubmit();
      setMessageData(errorObject);
    }
    // eslint-disable-next-line
  }, [value, validation, disabled]);

  return (
    <FieldContainer
      label={label}
      showErrors={showErrors}
      componentName={name}
      required={
        !disabled
      }
      additionalMessageData={messageData}
      customClasses={`${styles}`}
      errorExplanations={errorExplanations}
      setErrorExplanations={setErrorExplanations}
      hasWriteExplanationPermission={hasWriteExplanationPermission}
      onSubmit={onSubmit}
      showRequiredMessage={(notRequired === true ? false : isDirty || showRequiredError)}
      {...rest}
    >
      <Select
        inputId={componentName}
        className={`select-field-general ${className ? className : ''} ${
          disabled ? 'disabled' : ''
        } ${showErrors && messageData?.errors?.errorType !== 'noErrors' && showRequiredError
          ? ' error ' : ''} ${(notRequired === true ? false : isDirty || showRequiredError) && messageData?.required?.errorType !== 'noErrors'
          ? ' error ' : ''}`}
        data-refkey={componentName}
        placeholder={placeholder ? !disabled && placeholder : !disabled && t('common.selectValue')}
        value={value}
        defaultValue={defaultValue}
        isDisabled={disabled}
        isClearable={true}
        isSearchable={isSearchable}
        isMulti={isMulti}
        noOptionsMessage={() => t('common.noData')}
        loadingMessage={() => t('common.loading')}
        onChange={(e) => {
          onChange(e);
          setIsDirty(isDirtyProp);
          redirectPrompt.setIsDirty(isDirtyProp);
        }}
        name={componentName}
        onMenuClose={() => {
          setIsDirty(isDirtyProp);
        }}
        options={options}
        isOptionDisabled={(option) => option.disabled}
      />
    </FieldContainer>
  );
};

SelectConstField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isClearable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isSearchable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  validation: PropTypes.object,
  displayError: PropTypes.object,
};

export default SelectConstField;