import React, { useContext, useEffect, useState } from 'react';
import AgriculturalHoldingData from '../../../contexts/AgriculturalHoldingData';
import {
  modelLivestockData,
  modelPieData,
  modelPieDataObject,
  modelIncomeStackedColumnData,
  modelStackedColumnData,
  modelTreeData,
} from './utils/modelChartData';
import PieChart from '../../../components/charts/pieChart/PieChart';
import TreemapChart from '../../../components/charts/treemapChart/TreemapChart';
import StackedColumnChart from '../../../components/charts/stackedColumnChart/StackedColumnChart';
import { t } from 'react-switch-lang';
import { axiosGet } from '../../../services/axios';
import _ from 'lodash';
import classes from '../home/Home.module.scss';
import Pie from '../../../assets/images/dashboard/pie.png';
import Loading from '../errorPages/Loading';

const Dashboard = () => {
  const agroHolding = useContext(AgriculturalHoldingData);

  const [chosenLivestockApi, setChosenLivestockApi] = useState('');
  const [chosenLivestockLabel, setChosenLivestockLabel] = useState('');
  const [isLivestockChosen, setIsLivestockChosen] = useState(false);

  // state for checking if charts have data
  const [chartsAlive, setChartsAlive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleChartClick = (evt) => {
    setChosenLivestockLabel(evt.data.data.type);
    setChosenLivestockApi(`${evt.data.data.apiCall}/ah/${agroHolding?.agriculturalHoldingData?.id}/livestock-count`);
    setIsLivestockChosen(true);
  };

  // useEffect for checking if charts have data
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      // variable for setting chartAlive state
      let isAlive = false;
      const setAlive = () => isAlive = true;

      // general response we want from all chart apis for setting the isAlive variable
      const callApi = (api) => axiosGet(api).then((response) => !_.isEmpty(response?.data) && setAlive());

      // check every api sequentially and stop calls at first api that returns data
      !isAlive && await callApi(`labour/ah/${agroHolding?.agriculturalHoldingData?.id}/working-days`);
      !isAlive && await callApi(`long-term-assets/ah/${agroHolding?.agriculturalHoldingData?.id}/values-by-category`);
      !isAlive && await callApi(`plant-products/ah/${agroHolding?.agriculturalHoldingData?.id}/area-values`);
      !isAlive && await callApi(`agro-holdings/${agroHolding?.agriculturalHoldingData?.id}/sales-chart`);
      !isAlive && await callApi(`cattle-owned/${agroHolding?.agriculturalHoldingData?.id}/livestock-by-type-count`);
      !isAlive && await callApi(`agro-holdings/${agroHolding?.agriculturalHoldingData?.id}/income-structure`);
      !isAlive && await callApi(`subsidy-grant/ah/${agroHolding?.agriculturalHoldingData?.id}/total-value-by-category`);
      !isAlive && await callApi(`expenses/ah/${agroHolding?.agriculturalHoldingData?.id}/values-by-category`);

      // set the final view
      setChartsAlive(isAlive);
      setIsLoading(false);
    })()
  }, [])

  return isLoading ?
    <div style={{ height: '60vh' }} className={`d-flex flex-column justify-content-center align-items-center`}>
      <Loading />
    </div>
    :
    chartsAlive ?
      <div className='d-flex flex-wrap justify-content-center'>
        <PieChart
          label={t('pageTitle.labour')}
          api={`labour/ah/${agroHolding?.agriculturalHoldingData?.id}/working-days`}
          modelFunction={modelPieData}
        />
        <PieChart
          label={t('pageTitle.longTermAssets')}
          api={`long-term-assets/ah/${agroHolding?.agriculturalHoldingData?.id}/values-by-category`}
          modelFunction={modelPieDataObject}
        />
        <TreemapChart
          label={t('pageTitle.plantProducts')}
          api={`plant-products/ah/${agroHolding?.agriculturalHoldingData?.id}/area-values`}
          modelFunction={modelTreeData}
        />
        <PieChart
          label={t('pages.cattle.sale')}
          api={`agro-holdings/${agroHolding?.agriculturalHoldingData?.id}/sales-chart`}
          modelFunction={modelPieDataObject}
        />
        <PieChart
          label={t('pageTitle.livestock')}
          api={`cattle-owned/${agroHolding?.agriculturalHoldingData?.id}/livestock-by-type-count`}
          modelFunction={modelLivestockData}
          defaultClick={false}
          onReady={(plot) => {
            plot.on('element:click', (evt) => {
              handleChartClick(evt);
            });
          }}
        />
        {
          isLivestockChosen &&
          <PieChart
            label={chosenLivestockLabel}
            api={chosenLivestockApi}
            modelFunction={modelPieData}
          />
        }
        <StackedColumnChart
          label={t('income.incomeStructure')}
          api={`agro-holdings/${agroHolding?.agriculturalHoldingData?.id}/income-structure`}
          modelFunction={modelIncomeStackedColumnData}
        />
        <StackedColumnChart
          label={t('money.subsidies')}
          api={`subsidy-grant/ah/${agroHolding?.agriculturalHoldingData?.id}/total-value-by-category`}
          modelFunction={modelStackedColumnData}
        />
        <PieChart
          label={t('pageTitle.expenses')}
          api={`expenses/ah/${agroHolding?.agriculturalHoldingData?.id}/values-by-category`}
          modelFunction={modelPieDataObject}
        />
      </div>
      :
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${classes.placeholder}`}>
        <img src={Pie} alt='farm-placeholder'/>
        <div className='mt-5'>{t('pages.dashboard.emptyCharts')}</div>
      </div>
};

export default Dashboard;
