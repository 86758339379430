import { Tooltip } from 'antd';
import { formatDate } from '../../../../../utils/formatDate';
import { t } from 'react-switch-lang';
import { eng, formatNumber, hasValue } from '../../../../../utils/const';
import React from 'react';

const renderColWithTooltip = (col, date) => {
  return (
    <Tooltip
      title={date ? formatDate(col) : hasValue(col) && col}
      placement="top"
      color={'#67B278'}
      overlayInnerStyle={{ borderRadius: '5px' }}
    >
      {hasValue(col) ? (date ? formatDate(col) : col) : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.permanentCrops.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: (id) => renderColWithTooltip(id),
  },
  {
    title: t('pages.permanentCrops.plantationType'),
    dataIndex: ['plantationType', eng ? 'nameEn' : 'name'],
    key: 'plantationType',
    ellipsis: true,
    render: (id) => renderColWithTooltip(id),
  },
  // {
  //   title: t('pages.permanentCrops.dateProcured'),
  //   dataIndex: 'dateProcured',
  //   key: 'dateProcured',
  //   ellipsis: true,
  //   render: dateProcured => renderColWithTooltip(dateProcured, 'date'),
  //   responsive: ['xs', 'sm']
  // },
  {
    title: t('pages.permanentCrops.openingValue'),
    dataIndex: 'openingValue',
    key: 'openingValue',
    ellipsis: true,
    render: (openingValue) => renderColWithTooltip(formatNumber(openingValue)),
  },
  {
    title: t('pages.permanentCrops.soldArea'),
    dataIndex: 'soldArea',
    key: 'soldArea',
    ellipsis: true,
    render: (soldArea) => renderColWithTooltip(formatNumber(soldArea)),
  },
  {
    title: t('pages.permanentCrops.soldValue'),
    dataIndex: 'soldValue',
    key: 'soldValue',
    ellipsis: true,
    render: (soldValue) => renderColWithTooltip(formatNumber(soldValue)),
  },
  {
    title: t('pages.permanentCrops.closingArea'),
    dataIndex: 'closingArea',
    key: 'closingArea',
    ellipsis: true,
    render: (closingArea) => renderColWithTooltip(formatNumber(closingArea)),
  },
  {
    title: t('pages.permanentCrops.closingValue'),
    dataIndex: 'closingValue',
    key: 'closingValue',
    ellipsis: true,
    render: (closingValue) => renderColWithTooltip(formatNumber(closingValue)),
  },
];
