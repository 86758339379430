import axios from 'axios';
import { BASE_URL, token } from '../../utils/const';

export const getUsersList = (pageNumber, searchData) => {
  const searchTerm =
    searchData !== undefined && searchData.length > 0 ? `&searchTerm=${searchData}` : '';
  return axios({
    method: 'GET',
    url: `${BASE_URL}/users?page=${pageNumber}&size=20&sort=firstName${searchTerm}`,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    },
  });
};

export const getAllUsers = (pageNumber) => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/users/all?page=${pageNumber}&size=20&sort=firstName`,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    },
  });
};

export const searchAllUsers = () => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/users/all`,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    },
  });
};

export const createUser = (formData) => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/users`,
    data: JSON.stringify(formData),
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const updateUser = (formData) => {
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/users`,
    data: formData,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
  });
};

export const addUserToGroup = (groupID, userID) => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/groups/${groupID}/users`,
    data: JSON.stringify(userID),
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const getRoles = (data, setRoleFunction) => {
  return axios
    .get(`${BASE_URL}/roles?size=50`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((r) => {
      const roles = [];
      const result = [];
      data.map((item) => {
        roles.push(r.data.find((a) => a.name === item));
      });
      roles.map((role) =>
        result.push({
          label: role.description,
          value: role.name,
        }),
      );
      setRoleFunction(result);
    })
    .catch((r) => {});
};

export const getGroups = (data, setGroupFunction) => {
  return axios
    .get(`${BASE_URL}/groups?size=50`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((r) => {
      const groups = [];
      const result = [];
      r.data.map((group) => groups.push({ label: group.name, value: group.id }));
      data.map((item) => {
        result.push(groups.find((a) => a.value === item));
      });
      setGroupFunction(result);
    })
    .catch((r) => {});
};
// users/${userID}/groups

export const getUserGroups = (userID, setUserGroupsFunction, setSend) => {
  return axios
    .get(`${BASE_URL}/groups/user/${userID}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((r) => {
      var array = [];
      r.data.map((a) => array.push(a.id));
      setSend(array);
      getGroups(array, setUserGroupsFunction);
      // setUserGroupsFunction(array);
    });
};

export const deleteUserGroup = (userID, groupID) => {
  return axios({
    method: 'DELETE',
    url: `${BASE_URL}/groups/users/${userID}/${groupID}`,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    },
  });
};

export const deleteUser = (userID) => {
  return axios({
    method: 'DELETE',
    url: `${BASE_URL}/users/${userID}`,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    },
  });
};

export const deactivateUser = (userID) => {
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/users/${userID}/change-user-activation`,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    },
  });
};

export const getUserByLogin = (login) => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/users/${login}`,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    },
  });
};

export const getUserByUsernameApi = (login) => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/users/${login}`,
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: '*/*',
    },
  });
};

// export const changeProfileImage = async (image) => {
//   let returnData = {};
//   let data = new FormData();
//
//   data.append('file', image, image.name);
//
//   await axios
//     .post(`${BASE_URL}/users/change-profile-image`, data, {
//       headers: {
//         Authorization: 'Bearer ' + token,
//         'Content-Type': 'multipart/form-data',
//       },
//     })
//     .then((response) => {
//       returnData = {message: 'SUCCESS', data: response};
//     })
//     .catch((response) => {
//       if (response) {
//         returnData = {message: 'FAILED', data: response};
//       }
//     });
//   return returnData;
// };

export const fetchUserApi = () => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/account`,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
  });
};

export const activateUser = (search) => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/activate${search}`,
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
  });
};
