import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import classes from './Navbar.module.scss';
import Swal from 'sweetalert2';
import { fetchUserApi } from '../../../../services/users/usersAPI';
import { message } from '../../../../utils/language';
import Logo from '../../../../assets/images/logo.svg';
import LogoEn from '../../../../assets/images/logo-en.svg';
import Logout from '../../../../assets/images/navbar/logout.svg';
import Bell from '../../../../assets/images/navbar/bell.svg';
import Edit from '../../../../assets/images/navbar/edit.svg';
import Delete from '../../../../assets/images/navbar/delete.svg';
import View from '../../../../assets/images/navbar/eye.svg';
import Profile from '../../../../assets/images/navbar/profile.svg';
import Contact from '../../../../assets/images/chat.svg';
import { getLanguage, setLanguage, t } from 'react-switch-lang';
import LanguageEn from '../../../../assets/images/navbar/language-en.svg';
import LanguageCg from '../../../../assets/images/navbar/language-cg.svg';
import { eng, hasValue, readOnly } from '../../../../utils/const';
import UserData from '../../../../contexts/UserData';
import UserProfile from '../../../../app/pages/userProfile/UserProfile';
import AgriculturalHoldingData from '../../../../contexts/AgriculturalHoldingData';
import ContactUsAuth from '../../../../app/pages/contactUs/auth/ContactUsAuth';
import OptionsDropdown from './optionsDropdown/OptionsDropdown';
import RequestsModal from '../../../../app/pages/administration/requests/RequestsModal';
import logout from '../../../../utils/logout';
import { axiosDelete, axiosGet } from '../../../../services/axios';
import BadgeWrapper from '../../../badgeWrapper/BadgeWrapper';
import { fetchAgriculturalHolding } from '../../../../services/agriculturalHolding/agriculturalHoldingApi';
import { useNotificationBadge } from '../../../../contexts/NotificationBadge';
import { useWindowWidth } from '../../../../contexts/WindowWidth';
import NavigationDropdown from './navigationDropdown/NavigationDropdown';
import Prompt from '../../../prompt/Prompt';
import Toast from '../../../toast/Toast';

export const NavbarTop = ({ home = false }) => {
  // const { dataChanged }= useContext(UserData);

  let { ahid } = useParams();
  const history = useHistory();
  const location = useLocation();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const { hasGlobalRequests, setHasGlobalRequests } = useNotificationBadge();

  const [agriculturalHolding, setAgriculturalHolding] = useState({});

  const user = useContext(UserData);
  const windowWidth = useWindowWidth();

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isRequestsModalOpen, setIsRequestsModalOpen] = useState(false);

  const [hasRequests, setHasRequests] = useState(false);

  // redirects to different forms for admin and collector
  const agroEditLink = user.isAdmin ? `/administration/agricultural-holdings/${ahid}` : user.isCollector ? `/agricultural-holdings-collector/${ahid}` : `/agricultural-holdings-viewer/${ahid}`;

  const navLinks = [
    {
      path: '/home',
      name: t('sidebar.home'),
    },
    {
      path: '/documents',
      name: t('sidebar.documents'),
    },
    // ...(user.isAdmin ? [{
    //   path: '/forms',
    //   name: t('sidebar.forms'),
    // }] : []),
    {
      path: '/reports',
      name: t('sidebar.reports'),
    },
    ...(user.isAdmin ? [{
      path: '/administration',
      name: t('sidebar.administration'),
    }] : []),
  ];

  const fetchUser = () => {
    fetchUserApi()
      .then((r) => {
        // setProfileElementStyle(r.data);
      })
      .catch(() => {
        Swal.fire({
          text: `${message.errorApi}`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
  };

  // navigation links
  const Navigation = () => React.Children.toArray(
    navLinks?.map((link) => {
      return (
        <Link to={link.path}
              className={`${location.pathname === link.path && classes['active']} ${classes['navLinks']}`}>
          {link.path === '/administration' ? <BadgeWrapper className={classes['badge']} condition={hasGlobalRequests}
                                                           offset={[4, 0]}>{link.name}</BadgeWrapper> : link.name}
        </Link>);
    }));

  const onDelete = () => {
    return new Promise((resolve, reject) => {
      Prompt.fire({
        title: t('common.delete') + ' ' + t('messages.agriculturalHolding') + ' ' + agriculturalData.agriculturalHoldingData?.ahFadnId,
        text:
          t('common.deleteQuestion', {
            entity: t('messages.agriculturalHolding') + ' ' + agriculturalData.agriculturalHoldingData?.ahFadnId,
          }) + '?',
        confirmButtonText: t('common.delete'),
        cancelButtonText: t('common.cancel'),
        preConfirm: async () => {
          try {
            const response = await axiosDelete('agro-holdings', ahid);
            if (response.status === 204) {
              Toast.fire({
                title:
                  t('common.successDelete', {
                    entity: t('messages.agriculturalHolding') + ' ' + agriculturalData.agriculturalHoldingData?.ahFadnId,
                  }) + '!',
                icon: 'success',
              });
              resolve(response);
              history.push('/home');
            } else {
              reject(response);
            }
          } catch (err) {
            Swal.fire({
              text: t('pages.agriculturalHolding.messages.deleteError'),
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        },
      });
    });
  };

  const handleSetLanguage = (key) => {
    setLanguage(key);
    localStorage.setItem('language', getLanguage());
    window.location.reload();
  };

  useEffect(() => {
    ahid && axiosGet(`requests?agroHoldingId.equals=${ahid}`)
      .then((response) => {
        response?.data?.length > 0 && setHasRequests(response.data.filter((elem) => !elem.completed).length > 0);
      });

    axiosGet('requests').then((response) => {
      response?.data?.length > 0 && setHasGlobalRequests(response.data.filter((elem) => !elem.completed).length > 0);
    });
  }, [isRequestsModalOpen]);

  //fetch user data again when profile image is updated
  // useEffect(() => {
  //   fetchUser();
  // }, [dataChanged]);

  const getAgroHolding = async () => {
    const { data } = await fetchAgriculturalHolding(ahid);
    setAgriculturalHolding(data);
  };

  useEffect(() => {
    getAgroHolding();
  }, []);

  return (
    <div className={`d-flex flex-column w-100 ${classes['navBar']}`}>
      <div className={`d-flex flex-column ${classes['navBar__header']}`}>
        <div className={`d-flex justify-content-between w-100`}>
          <img src={eng ? LogoEn : Logo} alt='Logo' className={`${classes['navBar__logo']}`}
               onClick={() => history.push('/home')}/>
          <div className='d-flex w-75 justify-content-end'>
            {windowWidth > 768 && <Navigation/>}
            <img src={eng ? LanguageCg : LanguageEn} alt='Language'
                 onClick={() => handleSetLanguage(eng ? 'mne' : 'eng')}
                 className={`${classes['navBar__header--actions']}`}/>
            {
              !user.isAdmin &&
              <img src={Contact} alt='Contact' onClick={() => setIsContactModalOpen(true)}
                   className={`${classes['navBar__header--actions']}`}/>
            }
            <img src={Profile} alt='Profile' onClick={() => setIsProfileModalOpen(true)}
                 className={`${classes['navBar__header--actions']}`}/>
            <img src={Logout} alt='Logout' onClick={() => logout(history)}
                 className={`${classes['navBar__header--actions']}`}/>
            {!(windowWidth > 480) &&
            <NavigationDropdown navLinks={navLinks} className={classes['navBar__header--actions']}/>}
          </div>
        </div>
        {(windowWidth <= 768 && windowWidth > 480) &&
        <div className='d-flex mt-2'>
          <Navigation/>
        </div>
        }
      </div>
      {ahid && !home && (location.pathname.includes('/agricultural-holding/') || location.pathname.includes('/revisions')) &&
      <div className={`d-flex justify-content-between align-items-center ${classes['navBar__title']}`}>
        <div className='d-flex flex-column w-100'>
          <a href={`/agricultural-holding/${agriculturalData.agriculturalHoldingData?.id}/dashboard`}
             className={`${classes['navBar__title--main']}`}>
            {t('common.ah')} {hasValue(agriculturalHolding?.ahFadnId) ? agriculturalHolding?.ahFadnId : agriculturalData.agriculturalHoldingData?.ahFadnId}
          </a>
          <div className={`${classes['navBar__title--subtitle']}`}>
            {t('pages.dashboard.mibpg')}: {hasValue(agriculturalData.agriculturalHoldingData?.ricaAhId) ? agriculturalHolding?.ricaAhId ? agriculturalHolding?.ricaAhId : agriculturalData.agriculturalHoldingData.ricaAhId : t('common.undefined')}
          </div>
        </div>
        <div className='d-flex'>
          {(user.isAdmin || user.isCollector) && !readOnly ?
            windowWidth > 768 ?
              <OptionsDropdown isAdmin={user.isAdmin} isCollector={user.isCollector} redirectLink={agroEditLink}
                               onDelete={onDelete}/>
              :
              <>
                <img className={`${classes['navBar__header--actions']}`} onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname: agroEditLink,
                    state: {
                      add: false,
                      edit: true,
                    },
                  });
                }} src={Edit} alt={'Edit'}/>
                <img className={`${classes['navBar__header--actions']}`} onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }} src={Delete} alt={'Delete'}/>
              </>
            :
            <>
              {!readOnly &&
              <img className={`${classes['navBar__header--actions']}`} onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: agroEditLink,
                  state: {
                    add: false,
                    edit: user.isAdmin || user.isCollector,
                  },
                });
              }} src={user.isViewer ? View : Edit} alt={user.isViewer ? 'View' : 'Edit'}/>
              }
            </>
          }
          <BadgeWrapper condition={hasRequests}>
            <img style={{ margin: 0 }} src={Bell} onClick={() => setIsRequestsModalOpen(true)}
                 className={`${classes['navBar__header--actions']}`} alt='Notification'/>
          </BadgeWrapper>
        </div>
      </div>
      }
      {isProfileModalOpen && <UserProfile isModalOpen={isProfileModalOpen} setIsModalOpen={setIsProfileModalOpen}/>}
      {isContactModalOpen && <ContactUsAuth isModalOpen={isContactModalOpen} setIsModalOpen={setIsContactModalOpen}/>}
      {isRequestsModalOpen &&
      <RequestsModal isModalOpen={isRequestsModalOpen} setIsModalOpen={setIsRequestsModalOpen}/>}
    </div>
  );
};
