import React from 'react';
import FrCategory from "../../../../../components/administration/enums/frCategoryForm/FrCategory";
import {t} from "react-switch-lang";

const ProductionOtherProduct = () => {
  return (
    <>
      <FrCategory api={'production-other-products'} title={t('administration.enums.title.productionOtherProduct')}/>
    </>
  );
};

export default ProductionOtherProduct;
