import React from 'react';
import EnumsTable from "../../../../../components/administration/enums/enumsTypes/EnumsTable";
import {t} from "react-switch-lang";

const ObjectType = () => {
  return (
    <div>
      <EnumsTable api={'object-types'} title={t('administration.enums.title.objectType')}/>
    </div>
  );
};

export default ObjectType;
