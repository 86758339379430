import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const moneyPlantProductionFields = [
  {
    value: 'plantExpenseType',
    label: () => t('pages.plantProductionExpenses.plantExpenseType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'plant-expense-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.plantProductionExpenses.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.plantProductionExpenses.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'mineralFertilizerType',
    label: () => t('pages.plantProductionExpenses.mineralFertilizerType'),
    type: 'select',
    options: {
      list: [],
      api: 'mineral-fertilizer-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'otherPlantExpense',
    label: () => t('pages.plantProductionExpenses.otherPlantExpense'),
    type: 'text',
    options: {},
    displayInLimits: false
  },
  {
    value: 'otherPlantExpenseApproved',
    label: () => t('pages.plantProductionExpenses.otherPlantExpenseApproved'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'purchaseQuantity',
    label: () => t('pages.plantProductionExpenses.purchaseQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseValue',
    label: () => t('pages.plantProductionExpenses.purchaseValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumptionQuantity',
    label: () => t('pages.plantProductionExpenses.consumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'consumptionValue',
    label: () => t('pages.plantProductionExpenses.consumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.plantProductionExpenses.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.plantProductionExpenses.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.plantProductionExpenses.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default moneyPlantProductionFields;