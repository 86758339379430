import React from 'react';
import classes from './Breadcrumbs.module.scss';
import { t } from 'react-switch-lang';
import Arrow from '../../../../../../assets/images/icons/arrow-breadcrumb.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useWindowWidth } from '../../../../../../contexts/WindowWidth';

// Declare in Routes' previous prop, ex:
// previous = {[{ title: 'PageTitle', link: '/path' / true / false }, ...]}

// title - string that will be searched through pageTitle object in translations
// link - bool that determines if breadcrumb is clickable or not

const Breadcrumbs = ({ breadcrumbs }) => {
  const history = useHistory();
  const location = useLocation();

  const windowWidth = useWindowWidth();

  return breadcrumbs?.length > 0 ? <div className='d-flex flex-wrap'>
    {breadcrumbs.map((item, index) =>
      <div key={item?.title + 'Breadcrumb'} className='d-flex'>
        {
          item?.link ?
            <span className={`${classes['item']} curr navbar-brand d-block mr-1`}
                  onClick={() => history.push(location.pathname.replace('-fattening', '').split('/').slice(0, -(breadcrumbs.length - index)).join('/'))}>
              <span className={classes['is-link']}>{`${t(`pageTitle.${item?.title}`)}`}</span>
            </span>
            :
            <span className={`${classes['item']} ${classes['is-text']} curr navbar-brand d-block mr-1`}>
              {`${t(`pageTitle.${item?.title}`)}`}
            </span>
        }
        <img style={{ color: 'red', ...(windowWidth <= 605 && {width: 8}) }} className='curr navbar-brand d-block mr-2' src={Arrow} alt='arrow' />
      </div>,
    )}
  </div> : <></>;
};

export default Breadcrumbs;