import React, { useContext, useEffect, useState } from 'react';
import { CardsGridAdministration } from '../../../components/cardsGridAdministration/CardsGridAdministration';
import { axiosDelete, axiosGet } from '../../../services/axios';
import { t } from 'react-switch-lang';
import SaveBtn from '../../../components/buttons/SaveBtn';
import Prompt from '../../../components/prompt/Prompt';
import Toast from '../../../components/toast/Toast';
import Swal from 'sweetalert2';
import ModalWrapper from '../../../components/modal/modalWrapper/ModalWrapper';
import DocumentsForm from './documentsForm/DocumentsForm';
import InfiniteScroll from 'react-infinite-scroll-component';
import classes from './Documents.module.scss';
import { loader, rows } from './utils/documentsConsts';
import { moldData } from './utils/documentsDataManipulation';
import UserData from '../../../contexts/UserData';
import { readOnly } from '../../../utils/const';
import { useWindowWidth } from '../../../contexts/WindowWidth';

export const Documents = () => {
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const user = useContext(UserData);
  const windowWidth = useWindowWidth();

  //modals
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalInfo, setModalInfo] = useState({
    dataDisabled: false,
    changeType: '',
  });

  const onModalOpen = (data, disabled, type) => {
    setModalData(data);
    setModalInfo({ dataDisabled: disabled, changeType: type });
    setIsModalOpen(true);
  };

  const onModalExit = () => {
    setIsModalOpen(false);
  };

  const onDelete = (document) => {
    return new Promise((resolve, reject) => {
      Prompt.fire({
        title: t('common.delete') + ' ' + t('messages.document') + ' ' + document?.name,
        text:
          t('common.deleteQuestion', {
            entity: t('messages.document') + ' ' + document?.name,
          }) + '?',
        confirmButtonText: t('common.delete'),
        cancelButtonText: t('common.cancel'),
        preConfirm: async () => {
          try {
            const response = await axiosDelete('documents', document?.id);
            if (response.status === 204) {
              Toast.fire({
                title:
                  t('common.successDelete', {
                    entity: t('messages.document') + ' ' + document?.name,
                  }) + '!',
                icon: 'success',
              });
              resolve(response);
              setRefresh(prevState => !prevState);
            } else {
              reject(response);
            }
          } catch (err) {
            Swal.fire({
              text: t('common.apiErrorDelete'),
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        },
      });
    });
  };

  const fetchData = () => {
    axiosGet('documents')
      .then((response) => {
        const listData = moldData(response, onDelete, onModalOpen);
        setList(listData);
        setHasMore(listData.length === rows);
        setPage(prevState => prevState + 1);
      })
      .catch((err) => {
      });
  };

  const fetchMoreData = () => {
    axiosGet(`documents?page=${page}&size=${rows}`)
      .then((response) => {
        const listData = moldData(response, onDelete, onModalOpen);
        setList(prevState => ([...prevState, ...listData]));
        setHasMore(listData.length === rows);
        setPage(prevState => prevState + 1);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    // sets page to 0 when refresh happens, which is when new document is added so infinite scroll can start from beginning
    setPage(0);
    fetchData();
  }, [refresh]);

  return (
    <>
      <div style={windowWidth > 768 ? { marginTop: -90 } : { marginTop: -50 }} className='d-flex justify-content-end'>
        {user.isAdmin && !readOnly && (
          <SaveBtn
            label={t('common.add')}
            className='mb-5'
            onClick={() => onModalOpen({}, false, 'add')}
          />
        )}
      </div>
      <div id='cardContainer' className={`d-flex ${classes['content']}`}>
        <InfiniteScroll
          next={fetchMoreData}
          hasMore={hasMore}
          loader={loader}
          scrollableTarget='cardContainer'
          dataLength={list.length}
          style={{ overflowY: 'hidden', padding: 10 }}
        >
          <CardsGridAdministration list={list} document={true} crud={user.isAdmin && !readOnly}/>
        </InfiniteScroll>
      </div>
      <ModalWrapper
        header={
          modalInfo.changeType === 'add'
            ? t('pages.documents.actions.addNewDocument')
            : modalInfo.changeType === 'edit'
              ? t('pages.documents.actions.editDocument')
              : t('pages.documents.actions.showDocument')
        }
        isModalOpen={isModalOpen}
        /* eslint-disable-next-line react/no-children-prop */
        children={
          <DocumentsForm
            data={modalData}
            type={modalInfo.changeType}
            updateCards={() => setRefresh(prevState => !prevState)}
            closeModal={onModalExit}
            isDisabled={modalInfo.dataDisabled}
          />
        }
        onClose={() => onModalExit()}
      />
    </>
  );
};
