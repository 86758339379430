import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import SelectConstField from '../../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import classes from './AnimalExpensesForm.module.scss';
import { Toast } from '../../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../../utils/const';
import UserData from '../../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../../components/formDynamic/helpers';
import {
  fetchSingleAnimalProdExpense,
  getLivestockExpenseType,
} from '../../../../../../services/money/expenses/animalProductionExpenses';
import CheckboxField from '../../../../../../components/formDynamic/checkboxField/CheckboxField';
import Loader from '../../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';


const AnimalExpensesForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    livestockExpenseType: { type: 'selectSingle', required: { value: true } },
    openingQuantity: { type: 'number', required: { value: false } },
    openingValue: { type: 'number', required: { value: true } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    otherLivestockExpense: { type: 'string', required: { value: false }, min: { value: 3 } },
    otherLivestockExpenseApproved: { type: 'checkbox', required: { value: false } },
    purchaseQuantity: { type: 'number', required: { value: false } },
    purchaseValue: {
      type: 'number',
      required: { value: false },
      warning: { value: true, messages: t('pages.animalProductionExpenses.warnings.purchaseValue') },
    },
    consumptionQuantity: { type: 'number', required: { value: false } },
    consumptionValue: { type: 'number', required: { value: false } },
    closingQuantity: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [animal, setAnimal] = useState();

  const [livestockExpenseType, setLivestockExpenseType] = useState({ value: undefined, disabled: !edit });
  const [openingQuantity, setOpeningQuantity] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [otherLivestockExpense, setOtherLivestockExpense] = useState({ value: undefined, disabled: !edit });
  const [otherLivestockExpenseApproved, setOtherLivestockExpenseApproved] = useState({
    value: undefined,
    disabled: user.isAdmin ? !edit : true,
  });
  const [purchaseQuantity, setPurchaseQuantity] = useState({ value: 0, disabled: !edit });
  const [purchaseValue, setPurchaseValue] = useState({ value: 0, disabled: !edit });
  const [consumptionQuantity, setConsumptionQuantity] = useState({ value: 0, disabled: !edit });
  const [consumptionValue, setConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [closingQuantity, setClosingQuantity] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });


  const [livestockExpenseTypeList, setLivestockExpenseTypeList] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/

  const changeAnimalExpense = (e) => {
    if (e?.value) {
      if (e?.value === 4) {
        setOpeningQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setClosingQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));

      } else {
        if (e?.value !== 6) {
          setOpeningQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setPurchaseQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setClosingQuantity(prevState => ({ ...prevState, disabled: true }));
          setConsumptionQuantity(prevState => ({ ...prevState, disabled: !edit }));
        }
      }
      if (e?.value !== 4) {
        setOtherLivestockExpense(prevState => ({ ...prevState, value: undefined, disabled: true }));
        setOtherLivestockExpenseApproved(prevState => ({ ...prevState, value: ['false'], disabled: true }));

      } else {
        setOtherLivestockExpense(prevState => ({ ...prevState, disabled: !edit }));
        setOtherLivestockExpenseApproved(prevState => ({ ...prevState, disabled: !edit }));
      }
      if (e?.value === 6) {
        setOpeningQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setOpeningValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setClosingQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setClosingValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumptionValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));

      } else {
        if (e?.value !== 4) {
          setOpeningQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setPurchaseQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setClosingQuantity(prevState => ({ ...prevState, disabled: true }));
          setConsumptionQuantity(prevState => ({ ...prevState, disabled: !edit }));
        }
        setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: !edit }));
        setClosingValue(prevState => ({ ...prevState, disabled: !edit }));
        setConsumptionValue(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };

  const calculateClosingValue = (livestockExpenseType) => {
    if (livestockExpenseType !== 6) {
      const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

      const calculatedValue = parseFloatCustom(returnNullVal(openingValue.value)
        + returnNullVal(purchaseValue.value)
        - returnNullVal(consumptionValue.value)).toFixed(2);

      setClosingValue(prevState => ({
        ...prevState,
        value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
      }));
    }
  };

  const calculateClosingQuantity = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(returnNullVal(openingQuantity.value)
      + returnNullVal(purchaseQuantity.value)
      - returnNullVal(consumptionQuantity.value)).toFixed(2);

    setClosingQuantity(prevState => ({
      ...prevState,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };
  /***** END DEPENDANT VALIDATION *****/


  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'animalProductionExpense', animal, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/
  /*****START SUBMIT FORM *****/

  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking ? isPreLocking : isSubmit ? Object.values(submitDisabled).every(value => !value) : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(livestockExpenseType?.value && { livestockExpenseType: { id: parseInt(livestockExpenseType.value?.value) } }),
        otherLivestockExpenseApproved: otherLivestockExpenseApproved?.disabled ? null : hasValue(otherLivestockExpenseApproved?.value) && otherLivestockExpenseApproved?.value[0] === 'true',
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(openingQuantity?.value) && { openingQuantity: parseFloatCustom(openingQuantity.value) }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(hasValue(purchaseQuantity?.value) && { purchaseQuantity: parseFloatCustom(purchaseQuantity.value) }),
        ...(hasValue(consumptionQuantity?.value) && { consumptionQuantity: parseFloatCustom(consumptionQuantity.value) }),
        ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        ...(hasValue(consumptionValue?.value) && { consumptionValue: parseFloatCustom(consumptionValue.value) }),
        ...(hasValue(purchaseValue?.value) && { purchaseValue: parseFloatCustom(purchaseValue.value) }),
        ...(otherLivestockExpense?.value && { otherLivestockExpense: otherLivestockExpense.value }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`animal-production-expenses?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.animalProductionExpenses') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`animal-production-expenses/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.animalProductionExpenses') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchAnimalProd = async () => {
    try {
      const { data } = await fetchSingleAnimalProdExpense(props.match.params.id);
      setAnimal(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchAnimalProdType = async () => {
    const data = await getLivestockExpenseType();
    setLivestockExpenseTypeList(data);
  };

  useEffect(() => {
    fetchAnimalProdType();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchAnimalProd().then(animal => {
          animal?.livestockExpenseType && setLivestockExpenseType(prevState => ({
            ...prevState,
            value: defaultOption(animal?.livestockExpenseType),
          }));
          setComment(prevState => ({ ...prevState, value: animal?.comment }));
          setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(animal?.openingQuantity) }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(animal?.openingValue) }));
          setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(animal?.purchaseValue) }));
          setPurchaseQuantity(prevState => ({ ...prevState, value: parseDouble(animal?.purchaseQuantity) }));
          setConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(animal?.consumptionQuantity) }));
          setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(animal?.closingQuantity) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(animal?.closingValue) }));
          setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(animal?.consumptionValue) }));
          setOtherLivestockExpense(prevState => ({ ...prevState, value: animal?.otherLivestockExpense }));
          setOtherLivestockExpenseApproved(prevState => ({
            ...prevState,
            value: animal?.otherLivestockExpenseApproved
              ? [animal?.otherLivestockExpenseApproved ? 'true' : 'false']
              : [],
          }));
          setErrorExplanations(animal?.errorExplanations ? animal?.errorExplanations : []);
          let explanations = animal?.errorExplanations ? animal?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'animalProductionExpense', animal, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        data?.livestockExpenseType && setLivestockExpenseType(prevState => ({
          ...prevState,
          value: defaultOption(data?.livestockExpenseType),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(data?.openingQuantity) }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(data?.purchaseValue) }));
        setPurchaseQuantity(prevState => ({ ...prevState, value: parseDouble(data?.purchaseQuantity) }));
        setConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.consumptionQuantity) }));
        setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(data?.closingQuantity) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(data?.consumptionValue) }));
        setOtherLivestockExpense(prevState => ({ ...prevState, value: data?.otherLivestockExpense }));
        setOtherLivestockExpenseApproved(prevState => ({
          ...prevState,
          value: data?.otherLivestockExpenseApproved
            ? [data?.otherLivestockExpenseApproved ? 'true' : 'false']
            : [],
        }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);
        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'animalProductionExpense', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        // setValidation({ ...validationInitial });
        setLivestockExpenseType(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setOpeningQuantity(prevState => ({ ...prevState, disabled: false }));
        setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: false }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: false }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: false }));
        setClosingQuantity(prevState => ({ ...prevState, disabled: true }));
        setClosingValue(prevState => ({ ...prevState, disabled: false }));
        setConsumptionValue(prevState => ({ ...prevState, disabled: false }));
        setOtherLivestockExpense(prevState => ({ ...prevState, disabled: false }));
        setOtherLivestockExpenseApproved(prevState => ({ ...prevState, disabled: !user.isAdmin }));
      }
    }
  }, [edit]);


  useEffect(() => {
    if (!isAudit) {
      changeAnimalExpense(livestockExpenseType?.value);
    }
  }, [livestockExpenseType?.value, edit]);


  useEffect(() => {
    if (!isAudit) {
      calculateClosingValue(livestockExpenseType?.value?.value);
    }
  }, [openingValue?.value,
    purchaseValue?.value,
    consumptionValue?.value,
    livestockExpenseType?.value?.value]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingQuantity();
    }
  }, [openingQuantity.value,
    purchaseQuantity.value,
    consumptionQuantity.value]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED


  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.livestockExpenseType')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.livestockExpenseType')}
                    value={livestockExpenseType.value}
                    name='livestockExpenseType'
                    onChange={(e) => {
                      setLivestockExpenseType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={livestockExpenseTypeList}
                    disabled={livestockExpenseType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['livestockExpenseType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.openingQuantity')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.openingQuantity')}
                    value={openingQuantity.value}
                    name='openingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.openingValue')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.openingValue')}
                    value={openingValue.value}
                    suffix='€'
                    name='openingValue'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.otherLivestockExpense')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.otherLivestockExpense')}
                    helpLabel={t('pages.animalProductionExpenses.help.otherLivestockExpense')}
                    value={otherLivestockExpense.value}
                    name='otherLivestockExpense'
                    type={'text'}
                    onChange={(e) => {
                      setOtherLivestockExpense(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={otherLivestockExpense.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherLivestockExpense']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.otherLivestockExpenseApproved')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.otherLivestockExpenseApproved')}
                    helpLabel={t('pages.animalProductionExpenses.help.otherLivestockExpenseApproved')}
                    name='otherLivestockExpenseApproved'
                    options={[{ value: 'true', label: '' }]}
                    value={otherLivestockExpenseApproved?.value}
                    onChange={(e) => {
                      setOtherLivestockExpenseApproved(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={otherLivestockExpenseApproved?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherLivestockExpenseApproved']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.purchaseQuantity')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.purchaseQuantity')}
                    value={purchaseQuantity.value}
                    name='purchaseQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.purchaseValue')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.purchaseValue')}
                    value={purchaseValue.value}
                    suffix='€'
                    name='purchaseValue'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.consumptionQuantity')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.consumptionQuantity')}
                    value={consumptionQuantity.value}
                    name='consumptionQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setConsumptionQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumptionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumptionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.consumptionValue')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.consumptionValue')}
                    value={consumptionValue.value}
                    suffix='€'
                    name='consumptionValue'
                    type={'double'}
                    onChange={(e) => {
                      setConsumptionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.closingQuantity')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.closingQuantity')}
                    value={closingQuantity.value}
                    name='closingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setClosingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.closingValue')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.closingValue')}
                    value={closingValue.value}
                    suffix='€'
                    name='closingValue'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.animalProductionExpenses.comment')}
                    placeholder={t('pages.animalProductionExpenses.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className='button cancel'
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/expenses/animal-production-expenses`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className='button save'
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};

export default AnimalExpensesForm;
