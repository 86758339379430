import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { t } from 'react-switch-lang';
import { CattleFattening } from './CattleFattening';
import { Cattle } from './Cattle';
import { useHistory } from 'react-router-dom';

const CattleFormContainer = (props) => {

  const { TabPane } = Tabs;

  const [defaultTab, setDefaultTab] = useState(1);

  const history = useHistory();

  useEffect(() => {
    setDefaultTab(localStorage.getItem('cattleFattening'));
  }, []);

  useEffect(() => history.listen(() => {
    if (!history?.location?.pathname.includes('cattle')) {
      localStorage.setItem('cattleFattening', '1');
    }
  }), []);

  function callback(key) {
    setDefaultTab(key);
    localStorage.setItem('cattleFattening', key);
  }

  return (
    <Tabs defaultActiveKey={defaultTab} key={defaultTab} onChange={callback}>
      <TabPane tab={t('pages.cattle.owned')} key="1">
        <Cattle/>
      </TabPane>
      <TabPane tab={t('pages.cattle.fattening')} key="2">
        <CattleFattening/>
      </TabPane>
    </Tabs>
  );
};

export default CattleFormContainer;