import React, { useEffect, useState } from 'react';
import { t } from 'react-switch-lang';
import { useHistory, useParams } from 'react-router-dom';
import { axiosGetMulti } from '../../../../services/axios';
import { CardsGrid } from '../../../../components/cardsGrid/CardsGrid';
import LoanImg from '../../../../assets/images/money/loans.svg';
import LeasingImg from '../../../../assets/images/money/leasing.svg';

export const Loans = () => {
  let { ahid } = useParams();
  const history = useHistory();

  const [grantsTotal, setGrantsTotal] = useState({
    loans: 0,
    leasings: 0,
  });

  useEffect(() => {
    axiosGetMulti([`loans/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      `leasings/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`])
      .then(r => {
        setGrantsTotal({
          loans: r[0].data,
          leasings: r[1].data,
        });
      }).catch(r => {
      Swal.fire({
        text: t('common.error'),
        icon: 'error',
        confirmButtonText: 'OK',
      });
    });
  }, []);

  const list = [
    {
      title: t('pageTitle.loans'),
      image: LoanImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: grantsTotal.loans,
      },
      onClick: () => history.push(`/agricultural-holding/${ahid}/money/loans/loan`),
    }, {
      title: t('pageTitle.leasing'),
      image: LeasingImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: grantsTotal.leasings,
      },
      onClick: () => history.push(`/agricultural-holding/${ahid}/money/loans/leasing`),
    },
  ];


  return (
    <div className='d-flex'>
      <CardsGrid list={list}/>
    </div>
  );
};