import FormLabel from '../formLabel/FormLabel';
import classes from './FieldContainer.module.scss';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FormHelp from '../formHelp/FormHelp';
import ExplainError from '../../explainError/ExplainError';
import ExplainErrorModal from './components/explainErrorModal/ExplainErrorModal';

const FieldContainer = ({
                          label,
                          componentName,
                          helpLabel = '',
                          children,
                          required,
                          additionalMessageData,
                          customClasses,
                          errorExplanations,
                          setErrorExplanations,
                          hasWriteExplanationPermission,
                          onSubmit,
                          showErrors,
                          showRequiredMessage = false,
                        }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [modal, setModal] = useState({});

  const openExplainModal = (explainError) => {
    setModal({
      explainError: explainError,
      errorExplanationsArray: errorExplanations,
      setErrorExplanationsArray: (e) => setErrorExplanations(e),
      permissions: hasWriteExplanationPermission,
    });
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`${classes['field-container']} ${customClasses}`}
    >
      <div className="d-flex align-items-center">
        {label ? <FormLabel componentName={componentName} label={label} required={required}/> : <></>}
        {helpLabel !== '' ? <FormHelp helpLabel={helpLabel}/> : <></>}
      </div>
      <div>
        {children}
      </div>
      {/* front error messages */}
      {showRequiredMessage && additionalMessageData?.required && additionalMessageData?.required?.errorType === 'required' &&
      <div className={`${classes['formFieldsWidth']} explanations mt-2 validation-error`}>
        <div className='validationError'>
          {additionalMessageData?.required?.body}
        </div>
      </div>
      }
      {/* front error messages */}
      {showErrors && additionalMessageData?.errors && additionalMessageData?.errors?.errorType === 'error' &&
      <div className={`${classes['formFieldsWidth']} explanations mt-2 validation-error`}>
        <div className='validationError'>
          {additionalMessageData?.errors?.body}
        </div>
      </div>
      }
      {/* api error messages */}
      {additionalMessageData?.apiErrors && additionalMessageData?.apiErrors?.errorType === 'apiError' &&
      <div className={`${classes['formFieldsWidth']} explanations mt-2 validation-error`}>
        {
          React.Children.toArray(
            additionalMessageData?.apiErrors?.body?.map((error) => {
              return (
                <div className='validationError'>
                  {error?.message}
                </div>
              )
            }))
        }
      </div>}
      {/* explanations */}
      {additionalMessageData?.explanations && additionalMessageData?.explanations?.errorType === 'explanation' &&
      <>
        <div className={`${classes['formFieldsWidth']} explanations mt-2`}>
          {
            React.Children.toArray(
              additionalMessageData?.explanations?.body?.map((explanationError) => {
                return (
                  <ExplainError key={explanationError?.field + explanationError?.dvcNumber}
                                explanationError={explanationError}
                                openExplainModal={openExplainModal} errorExplanationsArray={errorExplanations}
                                setErrorExplanationsArray={(e) => setErrorExplanations(e)}
                                onSubmit={onSubmit}/>
                );
              }).sort((a, b) => a.props.explanationError.dvcNumber - b.props.explanationError.dvcNumber))
          }
        </div>
        <ExplainErrorModal modal={modal} isOpen={isOpen} toggle={toggle} onSubmit={onSubmit}/>
      </>
      }
      {/* warnings */}
      {additionalMessageData?.warnings && additionalMessageData?.warnings?.errorType === 'warning' &&
      <div className={`${classes['formFieldsWidth']} explanations mt-2`}>
        {
          React.Children.map(additionalMessageData?.warnings?.body, message => (
            <div className='warningError'>
              {message}
            </div>
          ))
        }
      </div>}

    </div>
  );
};

FieldContainer.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.element.isRequired,
};

export default FieldContainer;
