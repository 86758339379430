import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const GrantsPremiumType = () => {
  return (
    <div>
      <StandardTable api={'grant-premium-types'} title={t('administration.enums.title.grantsPremiumType')} />
    </div>
  );
};

export default GrantsPremiumType;
