import React, { useContext, useState } from 'react';
import ExplainableControlsTable from '../../../components/table/explainableControlsTable/ExplainableControlsTable';
import UserData from '../../../contexts/UserData';
import { columns } from './utils/tableData';
import {
  errorExplanationApprove,
  errorExplanationDecline,
} from '../../../services/explainableControls/explainableControlsApi';
import ChatModal from './ChatModal';

const ExplainableControls = () => {

  const user = useContext(UserData);

  const [isOpen, setIsOpen] = useState(false);

  const [explainError, setExplainError] = useState({});

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onApprove = async (id) => {
    const {data} = await errorExplanationApprove(id)
  };

  const onDecline = async (id) => {
    const {data} = await errorExplanationDecline(id)
  };

  const onView = (explainError) => {
    setExplainError(explainError);
    setIsOpen(!isOpen);
  };

  const onRowClick = (explainError) => {
  };

  return (
    <div className="d-flex flex-column">
      <ExplainableControlsTable
        api="error-explanations"
        columns={columns}
        methods={{ onDecline, onApprove, onView }}
        className={'table-hover'}
        onClickRow={onRowClick}
        user={user}
      />
      <ChatModal isOpen={isOpen} toggle={toggle} className={'class'} explainError={explainError}/>
    </div>
  );
};

export default ExplainableControls;