import React, { useContext, useState } from 'react';
import UserData from '../../../../contexts/UserData';
import { t } from 'react-switch-lang';
import { axiosDelete } from '../../../../services/axios';
import Toast from '../../../../components/toast/Toast';
import Swal from 'sweetalert2';
import Prompt from '../../../../components/prompt/Prompt';
import SaveBtn from '../../../buttons/SaveBtn';
import ModalWrapper from '../../../modal/modalWrapper/ModalWrapper';
import LivestockEnumsForm from '../../../../components/administration/enums/livestockEnums/form/LivestockEnumsForm';
import AdministrationTable from '../../../table/AdministrationTable';
import { columns } from './utils/livestockColumns';
import { readOnly } from '../../../../utils/const';

const LivestockTableEnums = ({ api, title }) => {
  const user = useContext(UserData);

  const [updateTable, setUpdateTable] = useState(false);

  //modals
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalInfo, setModalInfo] = useState({
    dataDisabled: false,
    changeType: '',
  });

  const onModalOpen = (data, disabled, type) => {
    setModalInfo({ dataDisabled: disabled, changeType: type });
    setModalData(data);
    setIsModalOpen(true);
  };

  const onModalExit = () => {
    setIsModalOpen(false);
    if (updateTable) {
      setUpdateTable(false);
    }
  };

  const onDelete = async (record) => {
    return new Promise((resolve, reject) => {
      Prompt.fire({
        title: t('lang.common.delete') + `${title}`,
        text: t('administration.enums.message.deleteQuestion') + `${title}` + record.id + '?',
        confirmButtonText: t('lang.common.delete'),
        cancelButtonText: t('lang.common.cancel'),
        preConfirm: async () => {
          try {
            const response = await axiosDelete(api, record.id);
            if (response.status === 204) {
              Toast.fire({
                title: t('administration.enums.message.successDelete') + `${title}` + '!',
                icon: 'success',
              });
              resolve(response);
              setUpdateTable(true);
            } else {
              reject(response);
            }
          } catch (err) {
            Swal.fire({
              text: t('common.apiErrorDelete'),
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        },
      });
    });
  };

  const onUpdate = (record) => {
    onModalOpen(record, false, 'edit');
  };

  const onRowClick = (record) => {
    onModalOpen(record, true, 'show');
  };

  const onUpdateTable = () => {
    setUpdateTable(true);
  };

  return (
    <div className="w-100 h-100">
      <div className="d-flex justify-content-end">
        {!readOnly &&
        <SaveBtn
          label={t('common.add') + `${title}`}
          className="mb-5 mt-3"
          onClick={() => onModalOpen({}, false, 'add')}
        />
        }
      </div>
      <ModalWrapper
        header={
          modalInfo.changeType === 'add'
            ? t('common.add') + `${title}`
            : modalInfo.changeType === 'edit'
            ? t('common.edit') + `${title}`
            : t('common.show') + `${title}`
        }
        isModalOpen={isModalOpen}
        /* eslint-disable-next-line react/no-children-prop */
        children={
          <LivestockEnumsForm
            api={api}
            title={title}
            data={modalData}
            type={modalInfo.changeType}
            updateTable={onUpdateTable}
            closeModal={onModalExit}
            isDisabled={modalInfo.dataDisabled}
          />
        }
        onClose={() => onModalExit()}
      />
      <AdministrationTable
        api={api}
        columns={columns}
        methods={{ onDelete, onUpdate }}
        onClickRow={onRowClick}
        key={updateTable}
        className={'table-hover'}
        user={user}
        isLocked={readOnly}
        noPagination={true}
      />
    </div>
  );
};

export default LivestockTableEnums;
