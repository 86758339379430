import React from 'react';
import { Tooltip } from 'antd';
import { t } from 'react-switch-lang';
import { eng, formatNumber, hasValue } from '../../../../../utils/const';

const renderColWithTooltip = (col) => {
  return (
    <Tooltip
      title={col}
      placement="topLeft"
      color={'#67B278'}
      overlayInnerStyle={{ borderRadius: '5px' }}
    >
      {hasValue(col) ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.manufacturedProducts.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: (id) => renderColWithTooltip(id),
  },
  {
    title: t('pages.manufacturedProducts.productionManufacturedProduct'),
    dataIndex: ['productionManufacturedProduct', eng ? 'nameEn' : 'name'],
    key: 'productionManufacturedProduct',
    ellipsis: true,
    render: (productionManufacturedProduct) => renderColWithTooltip(productionManufacturedProduct),
  },
  {
    title: t('pages.manufacturedProducts.productionCode'),
    dataIndex: ['productionCode', eng ? 'nameEn' : 'name'],
    key: 'productionCode',
    ellipsis: true,
    render: (productionCode) => renderColWithTooltip(productionCode),
  },

  {
    title: t('pages.manufacturedProducts.openingQuantity'),
    dataIndex: 'openingQuantity',
    key: 'openingQuantity',
    ellipsis: true,
    render: (openingQuantity) => renderColWithTooltip(formatNumber(openingQuantity)),
  },
  {
    title: t('pages.manufacturedProducts.openingValue'),
    dataIndex: 'openingValue',
    key: 'openingValue',
    ellipsis: true,
    render: (openingValue) => renderColWithTooltip(formatNumber(openingValue)),
  },
  // {
  //   title: t('pages.manufacturedProducts.otherManufacturedProduct'),
  //   dataIndex: 'otherManufacturedProduct',
  //   key: 'otherManufacturedProduct',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.productionQuantity'),
  //   dataIndex: 'productionQuantity',
  //   key: 'productionQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.soldQuantity'),
  //   dataIndex: 'soldQuantity',
  //   key: 'soldQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.soldValue'),
  //   dataIndex: 'soldValue',
  //   key: 'soldValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.ownConsumptionQuantity'),
  //   dataIndex: 'ownConsumptionQuantity',
  //   key: 'ownConsumptionQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.ownConsumptionValue'),
  //   dataIndex: 'ownConsumptionValue',
  //   key: 'ownConsumptionValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.paymentInProductsQuantity'),
  //   dataIndex: 'paymentInProductsQuantity',
  //   key: 'paymentInProductsQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.paymentInProductsValue'),
  //   dataIndex: 'paymentInProductsValue',
  //   key: 'paymentInProductsValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.consumedForAHQuantity'),
  //   dataIndex: 'consumedForAHQuantity',
  //   key: 'consumedForAHQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.consumedForAHValue'),
  //   dataIndex: 'consumedForAHValue',
  //   key: 'consumedForAHValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // },
  // {
  //   title: t('pages.manufacturedProducts.closingQuantity'),
  //   dataIndex: 'closingQuantity',
  //   key: 'closingQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  // {
  //   title: t('pages.manufacturedProducts.closingValue'),
  //   dataIndex: 'closingValue',
  //   key: 'closingValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // }
];
