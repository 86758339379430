import { t } from 'react-switch-lang';

export const validateDuplicate = (err, validation, setValidation, setRefreshLogin, setRefreshEmail) => {
  if (err.response.data.errorKey === 'userexists') {
    setValidation({
      ...validation,
      login: { ...validation['login'], error: { value: true, messages: t('register.userExists') } },
    });
    setRefreshLogin(prevState => !prevState);
  } else if (err.response.data.errorKey === 'emailexists') {
    setValidation({
      ...validation,
      email: { ...validation['email'], error: { value: true, messages: t('register.emailExists') } },
    });
    setRefreshEmail(prevState => !prevState);
  }
}