import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SelectConstField from '../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  fetchPlantProductTypes,
  getIrrigationTypes,
  getPlantProducts,
  getProductionCodes,
  getProductionTypes,
} from '../../../../../services/production/plantProducts/plantProductsApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './PlantProductsForm.module.scss';
import { Toast } from '../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import UserData from '../../../../../contexts/UserData';
import SelectAsyncField from '../../../../../components/formDynamic/selectField/SelectAsyncField';
import CheckboxField from '../../../../../components/formDynamic/checkboxField/CheckboxField';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import Loader from '../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const productIdCase = (id) => {
  if ([5, 8, 10, 20, 34, 39, 40, 46,
    49, 50, 52, 55, 56, 57, 59, 60,
    62, 63, 64, 65, 66, 68, 69, 70,
    71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 85, 86, 87, 89,
    90, 92, 102].includes(id)) {
    return [11];
  } else if ([7, 84].includes(id)) {
    return [14];
  } else if ([93].includes(id)) {
    return [13, 14];
  } else if ([94, 95, 96, 97, 98, 99, 100, 101].includes(id)) {
    return [11, 13, 14];
  } else if ([9, 13, 16, 17, 21, 24, 25, 30,
    33, 41, 44, 47, 48, 54, 58, 88].includes(id)) {
    return [11, 12];
  } else if ([45].includes(id)) {
    return [11, 12, 14];
  } else return [];
};

const filterProductionCodeById = (list, id) => {
  const listIds = productIdCase(id);
  return listIds?.length > 0
    ? list.filter(item => listIds.includes(item.value))
    : list;
};

const PlantProductsForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  /***** INITIAl VALIDATION *****/
  const validationInitial = {
    productionPlantProduct: { type: 'selectSingle', required: { value: true } },
    productionCode: { type: 'selectSingle', required: { value: true } },
    productionType: { type: 'selectSingle', required: { value: true } },
    firstTurn: { type: 'checkbox', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    area: { type: 'number', required: { value: true } },
    irrigated: { type: 'number', required: { value: true } },
    openingQuantity: { type: 'number', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    irrigationType: { type: 'selectSingle', required: { value: true } },
    fruitType: { type: 'selectSingle', required: { value: false } },
    otherCerealType: { type: 'string', required: { value: false }, min: { value: 3 } },
    productionQuantity: { type: 'number', required: { value: false } },
    soldQuantity: { type: 'number', required: { value: false } },
    soldValue: { type: 'number', required: { value: false } },
    ownConsumptionQuantity: { type: 'number', required: { value: false } },
    ownConsumptionValue: { type: 'number', required: { value: false } },
    paymentInProductsQuantity: { type: 'number', required: { value: false } },
    paymentInProductsValue: { type: 'number', required: { value: false } },
    spentOnProcessingQuantity: { type: 'number', required: { value: false } },
    spentOnProcessingValue: { type: 'number', required: { value: false } },
    consumedForCropsQuantity: { type: 'number', required: { value: false } },
    consumedForCropsValue: { type: 'number', required: { value: false } },
    consumedForCattleQuantity: { type: 'number', required: { value: false } },
    consumedForCattleValue: { type: 'number', required: { value: false } },
    consumedForPigsQuantity: { type: 'number', required: { value: false } },
    consumedForPigsValue: { type: 'number', required: { value: false } },
    consumedForPoultryQuantity: { type: 'number', required: { value: false } },
    consumedForPoultryValue: { type: 'number', required: { value: false } },
    closingQuantity: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
  };

  const changeFieldValidationRequired = (fieldName, required = false) => {

    setValidation(prevState => ({
      ...prevState,
      [fieldName]: { ...prevState[fieldName], required: { value: required } },
    }));
    setTimeout(() => {
      setSubmitDisabled(prevState => ({ ...prevState, [fieldName]: edit ? false : required }));
    }, 500);
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  /***** DATA *****/
  const [plantProducts, setPlanProducts] = useState();

  const [productionPlantProduct, setProductionPlantProduct] = useState({ value: undefined, disabled: !edit });
  const [productionCode, setProductionCode] = useState({ value: undefined, disabled: !edit });
  const [firstTurn, setFirstTurn] = useState({ value: undefined, disabled: !edit });
  const [productionType, setProductionType] = useState({ value: undefined, disabled: !edit });
  const [area, setArea] = useState({ value: 0, disabled: !edit });
  const [irrigated, setIrrigated] = useState({ value: 0, disabled: !edit });
  const [openingQuantity, setOpeningQuantity] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [irrigationType, setIrrigationType] = useState({ value: undefined, disabled: !edit });
  const [fruitType, setFruitType] = useState({ value: undefined, disabled: !edit });
  const [otherCerealType, setOtherCerealType] = useState({ value: undefined, disabled: !edit });
  const [productionQuantity, setProductionQuantity] = useState({ value: 0, disabled: !edit });
  const [soldQuantity, setSoldQuantity] = useState({ value: 0, disabled: !edit });
  const [soldValue, setSoldValue] = useState({ value: 0, disabled: !edit });
  const [ownConsumptionQuantity, setOwnConsumptionQuantity] = useState({ value: 0, disabled: !edit });
  const [ownConsumptionValue, setOwnConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [paymentInProductsQuantity, setPaymentInProductsQuantity] = useState({ value: 0, disabled: !edit });
  const [paymentInProductsValue, setPaymentInProductsValue] = useState({ value: 0, disabled: !edit });
  const [spentOnProcessingQuantity, setSpentOnProcessingQuantity] = useState({ value: 0, disabled: !edit });
  const [spentOnProcessingValue, setSpentOnProcessingValue] = useState({ value: 0, disabled: !edit });
  const [consumedForCropsQuantity, setConsumedForCropsQuantity] = useState({ value: 0, disabled: !edit });
  const [consumedForCropsValue, setConsumedForCropsValue] = useState({ value: 0, disabled: !edit });
  const [consumedForCattleQuantity, setConsumedForCattleQuantity] = useState({ value: 0, disabled: !edit });
  const [consumedForCattleValue, setConsumedForCattleValue] = useState({ value: 0, disabled: !edit });
  const [consumedForPigsQuantity, setConsumedForPigsQuantity] = useState({ value: 0, disabled: !edit });
  const [consumedForPigsValue, setConsumedForPigsValue] = useState({ value: 0, disabled: !edit });
  const [consumedForPoultryQuantity, setConsumedForPoultryQuantity] = useState({ value: 0, disabled: !edit });
  const [consumedForPoultryValue, setConsumedForPoultryValue] = useState({ value: 0, disabled: !edit });
  const [closingQuantity, setClosingQuantity] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });

  // Enums
  const [productionCodeOptionsList, setProductionCodeOptionsList] = useState([]);
  const [productionCodeList, setProductionCodeList] = useState([]);
  const [productionTypeOptionsList, setProductionTypeOptionsList] = useState([]);
  const [irrigationTypeOptionsList, setIrrigationTypeOptionsList] = useState([]);

  const [measurementUnit, setMeasurementUnit] = useState('');

  const [allProductionPlantProductsTypes, setAllProductionPlantProductsTypes] = useState([]);
  const [productionPlantProductsList, setProductionPlantProductsList] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/
  const changePlantProduct = (e) => {
    // setProductionPlantProduct(prevState => ({...prevState, value: e}));
    if (e?.value) {
      // const selected = plantProducts.find(item => item.id === e?.value);
      // if (selected) {
      //   setMeasurementUnit(selected?.unit);
      // }

      if (![97, 98, 101, 100, 95,
        99, 94, 96, 93, 47].includes(e?.value)) {
        setFirstTurn(prevState => ({ ...prevState, disabled: true, value: undefined }));
      } else {
        setFirstTurn(prevState => ({ ...prevState, disabled: !edit }));
      }
      if ([55, 56, 57].includes(e?.value)) {
        setOpeningQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setOpeningValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else if (e?.value !== 87) {
        setOpeningQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
      }
      if (e?.value !== 30) {
        setOtherCerealType(prevState => ({ ...prevState, disabled: true, value: undefined }));
      } else {
        setOtherCerealType(prevState => ({ ...prevState, disabled: !edit }));
      }
      if (e?.value === 87) {
        setOpeningQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        changeFieldValidationRequired('openingQuantity', false);
        setOpeningValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        changeFieldValidationRequired('openingValue', false);
        setSoldQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setSoldValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setArea(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setIrrigated(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        if (![55, 56, 57].includes(e?.value)) {
          setOpeningQuantity(prevState => ({ ...prevState, disabled: !edit }));
          setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
          changeFieldValidationRequired('openingQuantity', edit);
          changeFieldValidationRequired('openingValue', edit);
        }
        setSoldQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setSoldValue(prevState => ({ ...prevState, disabled: !edit }));
        // if(e?.value !== 83){
        //   setArea(prevState => ({...prevState, disabled: !edit}));
        //   setIrrigated(prevState => ({...prevState, disabled: !edit}));
        // }

      }
      if (e?.value !== 87) {
        setFruitType(prevState => ({ ...prevState, disabled: true, value: undefined }));
      } else {
        setFruitType(prevState => ({ ...prevState, disabled: !edit }));
      }
      // if(e?.value === 83){
      //   setProductionCode(prevState => ({...prevState, disabled: true, value: undefined}));
      //   changeFieldValidationRequired('productionCode', false);
      //   setArea(prevState => ({...prevState, disabled: true, value: 0}));
      //   setIrrigated(prevState => ({...prevState, disabled: true, value: 0}));
      // }else{
      //   setProductionCode(prevState => ({...prevState, disabled: !edit}));
      //   changeFieldValidationRequired('productionCode', true);
      //   if([55, 56, 57].includes(e?.value)){
      //     setArea(prevState => ({...prevState, disabled: !edit}));
      //     setIrrigated(prevState => ({...prevState, disabled: !edit}));
      //   }
      // }
      if (![9, 13, 16, 17, 21,
        24, 30, 33, 34, 39,
        40, 41, 44, 46, 47,
        48, 49, 52, 54, 58,
        88].includes(e?.value)) {
        setConsumedForCattleQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumedForCattleValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumedForPigsQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumedForPigsValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumedForPoultryQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumedForPoultryValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setConsumedForCattleQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setConsumedForCattleValue(prevState => ({ ...prevState, disabled: !edit }));
        setConsumedForPigsQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setConsumedForPigsValue(prevState => ({ ...prevState, disabled: !edit }));
        setConsumedForPoultryQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setConsumedForPoultryValue(prevState => ({ ...prevState, disabled: !edit }));
      }
      if ([5, 8, 9, 10, 16,
        17, 20, 24, 30, 34,
        39, 40, 44, 45, 47,
        48, 49, 50, 52, 54,
        55, 56, 57, 58, 84,
        85, 86, 87, 88, 90].includes(e?.value)) {
        setOwnConsumptionQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setOwnConsumptionValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setOwnConsumptionQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setOwnConsumptionValue(prevState => ({ ...prevState, disabled: !edit }));
      }
      if ([55, 56, 57, 84, 85,
        86, 87, 90].includes(e?.value)) {
        setPaymentInProductsQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setPaymentInProductsValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setPaymentInProductsQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setPaymentInProductsValue(prevState => ({ ...prevState, disabled: !edit }));
      }
      if ([5, 8, 9, 10, 16,
        17, 20, 21, 24, 30,
        34, 39, 40, 44, 45,
        47, 48, 50, 52, 54,
        55, 56, 57, 58, 84,
        85, 86, 87, 88, 89,
        90, 93].includes(e?.value)) {
        setSpentOnProcessingQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setSpentOnProcessingValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setSpentOnProcessingQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setSpentOnProcessingValue(prevState => ({ ...prevState, disabled: !edit }));
      }
      if ([5, 7, 8, 17, 24,
        39, 40, 50, 52, 55,
        56, 57, 59, 60, 62,
        63, 64, 65, 66, 68,
        69, 70, 71, 72, 73,
        74, 75, 76, 77, 78,
        79, 80, 81, 82, 84,
        86, 87, 88, 89, 90,
        92, 94, 98, 100, 101].includes(e?.value)) {
        setConsumedForCropsQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumedForCropsValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setConsumedForCropsQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setConsumedForCropsValue(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };

  const changeIrrigated = (irrigated) => {
    if (irrigated === 0) {
      setIrrigationType(prevState => ({ ...prevState, disabled: true, value: null }));
    } else {
      setIrrigationType(prevState => ({ ...prevState, disabled: !edit }));
    }
  };

  const calculateClosingQuantity = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(returnNullVal(openingQuantity.value)
      + returnNullVal(productionQuantity.value)
      - returnNullVal(soldQuantity.value)
      - returnNullVal(ownConsumptionQuantity.value)
      - returnNullVal(paymentInProductsQuantity.value)
      - returnNullVal(spentOnProcessingQuantity.value)
      - returnNullVal(consumedForCropsQuantity.value)
      - returnNullVal(consumedForCattleQuantity.value)
      - returnNullVal(consumedForPigsQuantity.value)
      - returnNullVal(consumedForPoultryQuantity.value)).toFixed(2);

    setClosingQuantity(prevState => ({
      ...prevState,
      disabled: true,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };
  /***** END DEPENDANT VALIDATION *****/

  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'plantProduct', plantProducts, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(hasValue(productionPlantProduct.value) && { productionPlantProduct: { id: parseInt(productionPlantProduct.value?.value) } }),
        ...(hasValue(productionType.value) && { productionType: { id: parseInt(productionType.value?.value) } }),
        ...(hasValue(irrigationType.value) ? { irrigationType: { id: parseInt(irrigationType.value?.value) } } : { irrigationType: null }),
        ...(hasValue(productionCode.value) && {
          productionCode: {
            id: parseInt(productionCode.value?.value),
          },
        }),
        ...(hasValue(fruitType.value) && { fruitType: { id: parseInt(fruitType.value?.value) } }),
        firstTurn: firstTurn?.disabled ? null : hasValue(firstTurn?.value) && firstTurn?.value[0] === 'true',
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(otherCerealType?.value) && { otherCerealType: otherCerealType.value }),
        ...(hasValue(area?.value) && { area: parseFloatCustom(area.value) }),
        ...(hasValue(irrigated?.value) && { irrigated: parseFloatCustom(irrigated.value) }),
        ...(hasValue(openingQuantity?.value) && { openingQuantity: parseFloatCustom(openingQuantity.value) }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(hasValue(productionQuantity?.value) && { productionQuantity: parseFloatCustom(productionQuantity.value) }),
        ...(hasValue(soldQuantity?.value) && { soldQuantity: parseFloatCustom(soldQuantity.value) }),
        ...(hasValue(soldValue?.value) && { soldValue: parseFloatCustom(soldValue.value) }),
        ...(hasValue(ownConsumptionQuantity?.value) && { ownConsumptionQuantity: parseFloatCustom(ownConsumptionQuantity.value) }),
        ...(hasValue(ownConsumptionValue?.value) && { ownConsumptionValue: parseFloatCustom(ownConsumptionValue.value) }),
        ...(hasValue(paymentInProductsQuantity?.value) && { paymentInProductsQuantity: parseFloatCustom(paymentInProductsQuantity.value) }),
        ...(hasValue(paymentInProductsValue?.value) && { paymentInProductsValue: parseFloatCustom(paymentInProductsValue.value) }),
        ...(hasValue(spentOnProcessingQuantity?.value) && { spentOnProcessingQuantity: parseFloatCustom(spentOnProcessingQuantity.value) }),
        ...(hasValue(spentOnProcessingValue?.value) && { spentOnProcessingValue: parseFloatCustom(spentOnProcessingValue.value) }),
        ...(hasValue(consumedForCropsQuantity?.value) && { consumedForCropsQuantity: parseFloatCustom(consumedForCropsQuantity.value) }),
        ...(hasValue(consumedForCropsValue?.value) && { consumedForCropsValue: parseFloatCustom(consumedForCropsValue.value) }),
        ...(hasValue(consumedForCattleQuantity?.value) && { consumedForCattleQuantity: parseFloatCustom(consumedForCattleQuantity.value) }),
        ...(hasValue(consumedForCattleValue?.value) && { consumedForCattleValue: parseFloatCustom(consumedForCattleValue.value) }),
        ...(hasValue(consumedForPigsQuantity?.value) && { consumedForPigsQuantity: parseFloatCustom(consumedForPigsQuantity.value) }),
        ...(hasValue(consumedForPigsValue?.value) && { consumedForPigsValue: parseFloatCustom(consumedForPigsValue.value) }),
        ...(hasValue(consumedForPoultryQuantity?.value) && { consumedForPoultryQuantity: parseFloatCustom(consumedForPoultryQuantity.value) }),
        ...(hasValue(consumedForPoultryValue?.value) && { consumedForPoultryValue: parseFloatCustom(consumedForPoultryValue.value) }),
        ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`plant-products?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.plantProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`plant-products/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.plantProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };

  /*****END SUBMIT FORM *****/


  /***** START FETCH DATA *****/
  const fetchPlantProduct = async () => {
    try {
      const { data } = await getPlantProducts(props.match.params.id);
      setPlanProducts(data);
      return data;
    } catch (e) {
    }
  };

  const getPlantProductTypes = async () => {
    try {
      const { data, filtered } = await fetchPlantProductTypes();
      setAllProductionPlantProductsTypes(data);
      setProductionPlantProductsList(filtered);
    } catch (e) {
    }
  };

  const onPlantProductTypeChange = (e) => {
    if (e?.value) {
      const selected = allProductionPlantProductsTypes.find(item => item.id === e?.value);
      if (selected) {
        setMeasurementUnit(selected?.unit);
      }
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchProductionCodes = async () => {
    try {
      const { data } = await getProductionCodes();
      let productionCodesTemp = [];
      for (let productionCode of data) {
        productionCodesTemp.push(
          defaultOption(productionCode),
        );
      }
      setProductionCodeOptionsList([...productionCodesTemp]);
    } catch (e) {
    }
  };


  const fetchProductionTypes = async () => {
    try {
      const { data } = await getProductionTypes();
      let productionTypesTemp = [];
      for (let productionType of data) {
        productionTypesTemp.push(
          defaultOption(productionType),
        );
      }
      setProductionTypeOptionsList([...productionTypesTemp]);
    } catch (e) {
    }
  };

  const fetchIrrigationTypes = async () => {
    try {
      const { data } = await getIrrigationTypes();
      let irrigationTypesTemp = [];
      for (let irrigationTypes of data) {
        irrigationTypesTemp.push(
          defaultOption(irrigationTypes),
        );
      }
      setIrrigationTypeOptionsList([...irrigationTypesTemp]);
    } catch (e) {
    }
  };

  useEffect(() => {
    if (!isAudit) {
      calculateClosingQuantity();
    }
  }, [openingQuantity.value,
    productionQuantity.value,
    soldQuantity.value,
    ownConsumptionQuantity.value,
    paymentInProductsQuantity.value,
    spentOnProcessingQuantity.value,
    consumedForCropsQuantity.value,
    consumedForCattleQuantity.value,
    consumedForPigsQuantity.value,
    consumedForPoultryQuantity.value]);

  useEffect(() => {
    if (!isAudit) {
      setProductionCodeList(filterProductionCodeById(productionCodeOptionsList, productionPlantProduct?.value?.value));
    }
  }, [productionPlantProduct?.value?.value]);

  useEffect(() => {
    if (!isAudit) {
      changeIrrigated(parseFloatCustom(irrigated?.value));
    }
  }, [irrigated?.value, edit]);

  useEffect(() => {
    getPlantProductTypes();
    fetchProductionCodes();
    fetchProductionTypes();
    fetchIrrigationTypes();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchPlantProduct().then(plantProducts => {
          setProductionPlantProduct(prevState => ({
            ...prevState,
            value: hasValue(plantProducts?.productionPlantProduct) ? defaultOption(plantProducts?.productionPlantProduct) : null,
          }));
          setProductionType(prevState => ({
            ...prevState,
            value: hasValue(plantProducts?.productionType) ? defaultOption(plantProducts?.productionType) : null,
          }));
          setProductionCode(prevState => ({
            ...prevState,
            value: hasValue(plantProducts?.productionCode) ? defaultOption(plantProducts?.productionCode) : null,
          }));
          setIrrigated(prevState => ({ ...prevState, value: parseDouble(plantProducts?.irrigated) }));
          setIrrigationType(prevState => ({
            ...prevState,
            value: hasValue(plantProducts?.irrigationType) ? defaultOption(plantProducts?.irrigationType) : null,
            disabled: !hasValue(plantProducts?.irrigationType),
          }));
          setFruitType(prevState => ({
            ...prevState,
            value: hasValue(plantProducts?.fruitType) ? defaultOption(plantProducts?.fruitType) : null,
          }));
          setFirstTurn(prevState => ({
            ...prevState,
            value: plantProducts?.firstTurn ? [plantProducts?.firstTurn ? 'true' : 'false'] : [],
          }));
          setComment(prevState => ({ ...prevState, value: plantProducts?.comment }));
          setArea(prevState => ({ ...prevState, value: parseDouble(plantProducts?.area) }));
          setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(plantProducts?.openingQuantity) }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(plantProducts?.openingValue) }));
          setProductionQuantity(prevState => ({ ...prevState, value: parseDouble(plantProducts?.productionQuantity) }));
          setOtherCerealType(prevState => ({ ...prevState, value: plantProducts?.otherCerealType }));
          setSoldQuantity(prevState => ({ ...prevState, value: parseDouble(plantProducts?.soldQuantity) }));
          setSoldValue(prevState => ({ ...prevState, value: parseDouble(plantProducts?.soldValue) }));
          setOwnConsumptionQuantity(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.ownConsumptionQuantity),
          }));
          setOwnConsumptionValue(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.ownConsumptionValue),
          }));
          setPaymentInProductsQuantity(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.paymentInProductsQuantity),
          }));
          setPaymentInProductsValue(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.paymentInProductsValue),
          }));
          setSpentOnProcessingQuantity(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.spentOnProcessingQuantity),
          }));
          setSpentOnProcessingValue(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.spentOnProcessingValue),
          }));
          setConsumedForCropsQuantity(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.consumedForCropsQuantity),
          }));
          setConsumedForCropsValue(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.consumedForCropsValue),
          }));
          setConsumedForCattleQuantity(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.consumedForCattleQuantity),
          }));
          setConsumedForCattleValue(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.consumedForCattleValue),
          }));
          setConsumedForPigsQuantity(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.consumedForPigsQuantity),
          }));
          setConsumedForPigsValue(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.consumedForPigsValue),
          }));
          setConsumedForPoultryQuantity(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.consumedForPoultryQuantity),
          }));
          setConsumedForPoultryValue(prevState => ({
            ...prevState,
            value: parseDouble(plantProducts?.consumedForPoultryValue),
          }));
          setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(plantProducts?.closingQuantity) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(plantProducts?.closingValue) }));
          setErrorExplanations(plantProducts?.errorExplanations ? plantProducts?.errorExplanations : []);
          let explanations = plantProducts?.errorExplanations ? plantProducts?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'plantProduct', plantProducts, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        setProductionPlantProduct(prevState => ({
          ...prevState,
          value: hasValue(data?.productionPlantProduct) ? defaultOption(data?.productionPlantProduct) : null,
        }));
        setProductionType(prevState => ({
          ...prevState,
          value: hasValue(data?.productionType) ? defaultOption(data?.productionType) : null,
        }));
        setProductionCode(prevState => ({
          ...prevState,
          value: hasValue(data?.productionCode) ? defaultOption(data?.productionCode) : null,
        }));
        setIrrigationType(prevState => ({
          ...prevState,
          value: hasValue(data?.irrigationType) ? defaultOption(data?.irrigationType) : null,
        }));
        setFruitType(prevState => ({
          ...prevState,
          value: hasValue(data?.fruitType) ? defaultOption(data?.fruitType) : null,
        }));
        setFirstTurn(prevState => ({
          ...prevState,
          value: data?.firstTurn ? [data?.firstTurn ? 'true' : 'false'] : [],
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setArea(prevState => ({ ...prevState, value: parseDouble(data?.area) }));
        setIrrigated(prevState => ({ ...prevState, value: parseDouble(data?.irrigated) }));
        setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(data?.openingQuantity) }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setProductionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.productionQuantity) }));
        setOtherCerealType(prevState => ({ ...prevState, value: data?.otherCerealType }));
        setSoldQuantity(prevState => ({ ...prevState, value: parseDouble(data?.soldQuantity) }));
        setSoldValue(prevState => ({ ...prevState, value: parseDouble(data?.soldValue) }));
        setOwnConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.ownConsumptionQuantity) }));
        setOwnConsumptionValue(prevState => ({ ...prevState, value: parseDouble(data?.ownConsumptionValue) }));
        setPaymentInProductsQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.paymentInProductsQuantity),
        }));
        setPaymentInProductsValue(prevState => ({ ...prevState, value: parseDouble(data?.paymentInProductsValue) }));
        setSpentOnProcessingQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.spentOnProcessingQuantity),
        }));
        setSpentOnProcessingValue(prevState => ({ ...prevState, value: parseDouble(data?.spentOnProcessingValue) }));
        setConsumedForCropsQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.consumedForCropsQuantity),
        }));
        setConsumedForCropsValue(prevState => ({ ...prevState, value: parseDouble(data?.consumedForCropsValue) }));
        setConsumedForCattleQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.consumedForCattleQuantity),
        }));
        setConsumedForCattleValue(prevState => ({ ...prevState, value: parseDouble(data?.consumedForCattleValue) }));
        setConsumedForPigsQuantity(prevState => ({ ...prevState, value: parseDouble(data?.consumedForPigsQuantity) }));
        setConsumedForPigsValue(prevState => ({ ...prevState, value: parseDouble(data?.consumedForPigsValue) }));
        setConsumedForPoultryQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.consumedForPoultryQuantity),
        }));
        setConsumedForPoultryValue(prevState => ({ ...prevState, value: parseDouble(data?.consumedForPoultryValue) }));
        setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(data?.closingQuantity) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);
        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'plantProduct', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setProductionCode(prevState => ({ ...prevState, disabled: false }));
        setProductionPlantProduct(prevState => ({ ...prevState, disabled: false }));
        setProductionType(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        // setIrrigationType(prevState => ({ ...prevState, disabled: false })); // dependent on irrigated
        setProductionQuantity(prevState => ({ ...prevState, disabled: false }));
        setClosingValue(prevState => ({ ...prevState, disabled: false }));
        setArea(prevState => ({ ...prevState, disabled: false }));
        setIrrigated(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  useEffect(() => {
    if (!isAudit) {
      changePlantProduct(productionPlantProduct?.value);
      onPlantProductTypeChange(productionPlantProduct?.value);
    }
  }, [productionPlantProduct?.value, edit]);


  /***** END FETCH DATA *****/

    // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    label={t('pages.plantProducts.productionPlantProduct')}
                    placeholder={t('pages.plantProducts.placeholder.productionPlantProduct')}
                    value={productionPlantProduct.value}
                    name='productionPlantProduct'
                    onChange={(e) => {
                      setShowErrors(true);
                      // changePlantProduct(e);
                      setProductionPlantProduct(prevState => ({ ...prevState, value: e }));
                    }}
                    options={productionPlantProductsList}
                    disabled={productionPlantProduct.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionPlantProduct']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  {/*<SelectAsyncField*/}
                  {/*  pagination={false}*/}
                  {/*  showErrors={showErrors}*/}
                  {/*  showRequiredError={showRequiredError}*/}
                  {/*  api="production-plant-products"*/}
                  {/*  label={t('pages.plantProducts.productionPlantProduct')}*/}
                  {/*  placeholder={t('pages.plantProducts.placeholder.productionPlantProduct')}*/}
                  {/*  value={productionPlantProduct.value}*/}
                  {/*  name="productionPlantProduct"*/}
                  {/*  onChange={(e) => {*/}
                  {/*    setShowErrors(true);*/}
                  {/*    // changePlantProduct(e);*/}
                  {/*    setProductionPlantProduct(prevState => ({ ...prevState, value: e }));*/}
                  {/*  }}*/}
                  {/*  disabled={productionPlantProduct.disabled}*/}
                  {/*  styles={`${classes['formFieldsWidth']}`}*/}
                  {/*  validation={validation['productionPlantProduct']}*/}
                  {/*  errorExplanations={errorExplanations}*/}
                  {/*  setErrorExplanations={setErrorExplanations}*/}
                  {/*  onSubmit={() => handleSubmit(false)}*/}
                  {/*  hasWriteExplanationPermission={!add && !edit}*/}
                  {/*  setSubmitDisabled={setSubmitDisabled}*/}
                  {/*/>*/}
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.productionCode')}
                    placeholder={t('pages.plantProducts.placeholder.productionCode')}
                    value={productionCode.value}
                    name='productionCode'
                    onChange={(e) => {
                      setProductionCode(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                      setShowErrors(true);
                    }}
                    options={productionCodeList}
                    disabled={productionCode.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionCode']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.productionType')}
                    placeholder={t('pages.plantProducts.placeholder.productionType')}
                    value={productionType.value}
                    name='productionType'
                    onChange={(e) => {
                      setProductionType(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                      setShowErrors(true);
                    }}
                    options={productionTypeOptionsList}
                    disabled={productionType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.firstTurn')}
                    name='firstTurn'
                    options={[{ value: 'true', label: '' }]}
                    value={firstTurn?.value}
                    onChange={(e) => {
                      setFirstTurn(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={firstTurn?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['firstTurn']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.area')}
                    placeholder={t('pages.plantProducts.placeholder.area')}
                    value={area.value}
                    suffix={'ha'}
                    name='area'
                    type={'double'}
                    onChange={(e) => {
                      setArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={area.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['area']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.irrigated')}
                    suffix='ha'
                    placeholder={t('pages.plantProducts.placeholder.irrigated')}
                    value={irrigated.value}
                    name='irrigated'
                    type={'double'}
                    onChange={(e) => {
                      setIrrigated(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={irrigated.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['irrigated']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.irrigationType')}
                    placeholder={t('pages.plantProducts.placeholder.irrigationType')}
                    value={irrigationType.value}
                    name='irrigationType'
                    onChange={(e) => {
                      setIrrigationType(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                      setShowErrors(true);
                    }}
                    options={irrigationTypeOptionsList}
                    disabled={irrigationType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['irrigationType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.openingQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.openingQuantity')}
                    value={openingQuantity.value}
                    name='openingQuantity'
                    suffix={measurementUnit}
                    type={'double'}
                    onChange={(e) => {
                      setOpeningQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.openingValue')}
                    placeholder={t('pages.plantProducts.placeholder.openingValue')}
                    value={openingValue.value}
                    suffix='€'
                    name='openingValue'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <SelectAsyncField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    api='production-plant-products'
                    label={t('pages.plantProducts.fruitType')}
                    placeholder={t('pages.plantProducts.placeholder.fruitType')}
                    value={fruitType.value}
                    name='fruitType'
                    onChange={(e) => {
                      setShowErrors(true);
                      setFruitType(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={fruitType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['fruitType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.otherCerealType')}
                    placeholder={t('pages.plantProducts.placeholder.otherCerealType')}
                    value={otherCerealType.value}
                    name='otherCerealType'
                    type={'text'}
                    onChange={(e) => {
                      setOtherCerealType(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={otherCerealType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherCerealType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.productionQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.productionQuantity')}
                    value={productionQuantity.value}
                    name='productionQuantity'
                    suffix={measurementUnit}
                    type={'double'}
                    onChange={(e) => {
                      setProductionQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={productionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.soldQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.soldQuantity')}
                    value={soldQuantity.value}
                    name='soldQuantity'
                    type={'double'}
                    suffix={measurementUnit}
                    onChange={(e) => {
                      setSoldQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.soldValue')}
                    placeholder={t('pages.plantProducts.placeholder.soldValue')}
                    value={soldValue.value}
                    name='soldValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSoldValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.ownConsumptionQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.ownConsumptionQuantity')}
                    value={ownConsumptionQuantity.value}
                    name='ownConsumptionQuantity'
                    suffix={measurementUnit}
                    type={'double'}
                    onChange={(e) => {
                      setOwnConsumptionQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={ownConsumptionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['ownConsumptionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.ownConsumptionValue')}
                    placeholder={t('pages.plantProducts.placeholder.ownConsumptionValue')}
                    value={ownConsumptionValue.value}
                    name='ownConsumptionValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOwnConsumptionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={ownConsumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['ownConsumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.paymentInProductsQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.paymentInProductsQuantity')}
                    value={paymentInProductsQuantity.value}
                    name='paymentInProductsQuantity'
                    suffix={measurementUnit}
                    type={'double'}
                    onChange={(e) => {
                      setPaymentInProductsQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={paymentInProductsQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['paymentInProductsQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.paymentInProductsValue')}
                    placeholder={t('pages.plantProducts.placeholder.paymentInProductsValue')}
                    value={paymentInProductsValue.value}
                    name='paymentInProductsValue'
                    type={'double'}
                    suffix='€'
                    onChange={(e) => {
                      setPaymentInProductsValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={paymentInProductsValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['paymentInProductsValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.spentOnProcessingQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.spentOnProcessingQuantity')}
                    value={spentOnProcessingQuantity.value}
                    name='spentOnProcessingQuantity'
                    suffix={measurementUnit}
                    type={'double'}
                    onChange={(e) => {
                      setSpentOnProcessingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={spentOnProcessingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['spentOnProcessingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.spentOnProcessingValue')}
                    placeholder={t('pages.plantProducts.placeholder.spentOnProcessingValue')}
                    value={spentOnProcessingValue.value}
                    name='spentOnProcessingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSpentOnProcessingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={spentOnProcessingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['spentOnProcessingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.consumedForCropsQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.consumedForCropsQuantity')}
                    value={consumedForCropsQuantity.value}
                    name='consumedForCropsQuantity'
                    suffix={measurementUnit}
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForCropsQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForCropsQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForCropsQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.consumedForCropsValue')}
                    placeholder={t('pages.plantProducts.placeholder.consumedForCropsValue')}
                    value={consumedForCropsValue.value}
                    name='consumedForCropsValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForCropsValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForCropsValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForCropsValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.consumedForCattleQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.consumedForCattleQuantity')}
                    value={consumedForCattleQuantity.value}
                    suffix={measurementUnit}
                    name='consumedForCattleQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForCattleQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForCattleQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForCattleQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.consumedForCattleValue')}
                    placeholder={t('pages.plantProducts.placeholder.consumedForCattleValue')}
                    value={consumedForCattleValue.value}
                    suffix='€'
                    name='consumedForCattleValue'
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForCattleValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForCattleValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForCattleValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.consumedForPigsQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.consumedForPigsQuantity')}
                    value={consumedForPigsQuantity.value}
                    name='consumedForPigsQuantity'
                    suffix={measurementUnit}
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForPigsQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForPigsQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForPigsQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.consumedForPigsValue')}
                    placeholder={t('pages.plantProducts.placeholder.consumedForPigsValue')}
                    value={consumedForPigsValue.value}
                    suffix='€'
                    name='consumedForPigsValue'
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForPigsValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForPigsValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForPigsValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.plantProducts.placeholder.consumedForPoultryQuantity')}
                    label={t('pages.plantProducts.consumedForPoultryQuantity')}
                    suffix={measurementUnit}
                    value={consumedForPoultryQuantity.value}
                    name='consumedForPoultryQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForPoultryQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForPoultryQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForPoultryQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.consumedForPoultryValue')}
                    placeholder={t('pages.plantProducts.placeholder.consumedForPoultryValue')}
                    value={consumedForPoultryValue.value}
                    name='consumedForPoultryValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForPoultryValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForPoultryValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForPoultryValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.closingQuantity')}
                    placeholder={t('pages.plantProducts.placeholder.closingQuantity')}
                    value={closingQuantity.value}
                    name='closingQuantity'
                    type={'double'}
                    suffix={measurementUnit}
                    onChange={(e) => {
                      setClosingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.closingValue')}
                    placeholder={t('pages.plantProducts.placeholder.closingValue')}
                    value={closingValue.value}
                    suffix='€'
                    name='closingValue'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.comment')}
                    placeholder={t('pages.plantProducts.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={!edit}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/production/plant-products`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};
export default PlantProductsForm;
