import { axiosGet } from '../../axios';
import { eng } from '../../../utils/const';

export const countSubsidyGrants = () => {
  return axiosGet(`subsidy-grants/count`);
};

export const getSubsidy = (id) => {
  return axiosGet(`subsidy-grants/${id}`);
};

const defaultOption = (option) => {
  return {
    label: eng ? option.nameEn : option.name,
    value: option.id,
    disabled: false,
  };
};

export const getAllBasicUnitTypeLists = () => {
  return axiosGet(`grant-basic-unit-types`)
    .then(r => {
      let arrTemp = [];
      for (let item of r.data) {
        arrTemp.push(
          defaultOption(item),
        );
      }
      return arrTemp;
    }).catch(() => []);
};

export const getAllSubsidyCategories = () => {
  return axiosGet(`subsidy-categories`)
    .then(r => {
      let arrTemp = [];
      for (let item of r.data) {
        arrTemp.push(
          defaultOption(item),
        );
      }
      return arrTemp;
    }).catch(() => []);
};

export const getAllGrantSubsidyCategories = (categoryId) => {
  return axiosGet(`grant-subsidy-categories?subsidyCategoryId.equals=${categoryId}`)
    .then(r => {
      let arrTemp = [];
      for (let item of r.data) {
        arrTemp.push(
          defaultOption(item),
        );
      }
      return { filteredOptions: arrTemp, rawOptions: r.data };
    }).catch(() => []);
};

export const getAllGrantTypes = () => {
  return axiosGet(`grant-types`)
    .then(r => {
      let arrTemp = [];
      for (let item of r.data) {
        arrTemp.push(
          defaultOption(item),
        );
      }
      return arrTemp;
    }).catch(() => []);
};

export const getAllFinancedTypes = () => {
  return axiosGet(`grant-financed-types`)
    .then(r => {
      let arrTemp = [];
      for (let item of r.data) {
        arrTemp.push(
          defaultOption(item),
        );
      }
      return arrTemp;
    }).catch(() => []);
};
