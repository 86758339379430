import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const AgriculturalEducation = () => {
  return (
    <div>
      <StandardTable api={'agricultural-educations'} title={t('administration.enums.title.agriculturalEducation')} />

    </div>
  );
};

export default AgriculturalEducation;
