import axios from 'axios';
import { trimObjectStringValues } from '../utils/const';
import { t } from 'react-switch-lang';
import Toast from '../components/toast/Toast';

export const BASE_URL = process.env.API_URL;

// export const BASE_URL = '/api';
// .concat(
//   `:${(window.location.protocol === 'http:') ? '8080' : '8443'}/api`);

// export const BASE_WEBSOCKET = window.location.protocol
//   .concat('//')
//   .concat(process.env.HOSTNAME)
//   .concat(
//     `:${process.env.PORT}/websocket?access_token=${localStorage.getItem('jwt-token')}`,
//   );

const lang = localStorage.getItem('language');

const headers = {
  Accept: 'application/json',
  'Accept-Language': lang === 'mne' ? 'mne' : 'en',
  'Content-Type': 'application/json',
};

const headersPatch = {
  Accept: '*/*',
  'Accept-Language': lang === 'mne' ? 'mne' : 'en',
  'Content-Type': 'application/merge-patch+json',
};

const headersDownload = {
  Accept: 'application/octet-stream',
  'Accept-Language': lang === 'mne' ? 'mne' : 'en',
  'Content-Type': 'application/octet-stream',
};

const headersMultipart = {
  Accept: 'application/json',
  'accept-language': lang === 'mne' ? 'mne' : 'en',
  'Content-Type': 'multipart/form-data',
};

// Global error handling
// Response interceptor
axios.interceptors.response.use(function(response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401 || error.response.status === 502) {
    // If logged in and token expires
    // With no condition there's constant redirect when user inserts wrong username/password on login
    if (localStorage.getItem('jwt-token')) {
      localStorage.removeItem('jwt-token');
      window.location = '/login';
    }
  }
  if (error.response.status === 403) {
    window.location = '/403';
  }
  if (error.response.status === 404) {
    window.location = '/404';
  }
  if (error.response.status === 500) {
    Toast.fire({
      title: t('common.error'),
      icon: 'error',
      iconColor: '#d70020',
    });
  }
  return Promise.reject(error);
});

// cancelObj - usage
// required in get methods - use to prevent memory leaks
// inside parent component set useEffect
// create cancel token in component
//      - let source = axios.CancelToken.source();
// add it to getApi
//      - getApi( ... , {cancelToken: source.token})
// cancel api on component unmounting
//      useEffect(() => {
//                return () => {
//                  source.cancel();
//              }
//      }, []);

export const axiosGet = (api, cancelObj) => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/${api}`,
    ...cancelObj,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headers,
    },
  });
};

export const axiosGetMulti = (apiArray, cancelObj) => {
  const requestsArray = apiArray.map((api) => {
    return axios({
      method: 'GET',
      url: `${BASE_URL}/${api}`,
      ...cancelObj,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
        ...headers,
      },
    });
  });
  return axios.all(requestsArray);
};

// post method for apis without formData
export const axiosPostParam = (api) => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/${api}`,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headers,
    },
  });
};

export const axiosPost = (api, data) => {
  const trimmedData =
    data !== undefined && data !== null
      ? Array.isArray(data)
        ? data
        : trimObjectStringValues(data)
      : undefined;
  return axios({
    method: 'POST',
    url: `${BASE_URL}/${api}`,
    data: JSON.stringify(trimmedData),
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headers,
    },
  });
};

export const axiosPut = (api, data) => {
  const trimmedData =
    data !== undefined && data !== null
      ? Array.isArray(data)
        ? data
        : trimObjectStringValues(data)
      : undefined;
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/${api}`,
    data: JSON.stringify(trimmedData),
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headers,
    },
  });
};

export const axiosPatch = (api, data) => {
  const trimmedData =
    data !== undefined && data !== null
      ? Array.isArray(data)
        ? data
        : trimObjectStringValues(data)
      : undefined;
  return axios({
    method: 'PATCH',
    url: `${BASE_URL}/${api}`,
    data: JSON.stringify(trimmedData),
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headersPatch,
    },
  });
};

export const axiosDelete = (api, id) => {
  return axios({
    method: 'DELETE',
    url: `${BASE_URL}/${api}/${id}`,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headers,
    },
  });
};

export const axiosDeletePayload = (api, payload) => {
  return axios({
    method: 'DELETE',
    url: `${BASE_URL}/${api}`,
    data: payload,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headers,
    },
  });
};

// files methods - blob
export const axiosGetFile = (api, filename) => {
  return axios({
    url: `${BASE_URL}/${api}`, //your url
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headersDownload,
    },
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};

export const axiosPostFile = (api, formData, setProgress) => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/${api}`,
    data: formData,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headersMultipart,
    },
    onUploadProgress: data => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
  });
};

export const axiosPutFile = (api, formData, id, setProgress) => {
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/${api}/${id}`,
    data: formData,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headersMultipart,
    },
    // in case setProgress isn't passed in (user didn't upload a file), don't create progress
    ...(
      setProgress &&
      {
        onUploadProgress: data => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      }),
  });
};
// -----

export const axiosDeactivateUser = (api) => {
  return axios({
    method: 'PUT',
    url: `${BASE_URL}/${api}`,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
      ...headers,
    },
  });
};
