import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';
import { hasValue } from '../../../../../utils/const';
import React from 'react';

const renderColWithTooltip = (col) => {
  return <Tooltip title={col} placement="topLeft" color={'#67B278'}
                  overlayInnerStyle={{ borderRadius: '5px' }}>{hasValue(col) ? col : '/'}</Tooltip>;
};

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: id => renderColWithTooltip(id),
    width: 50,
  },
  {
    title: t('administration.enums.columns.name'),
    dataIndex: ['name'],
    key: 'name',
    ellipsis: true,
    render: id => renderColWithTooltip(id),

  },
  {
    title: t('administration.enums.columns.nameEn'),
    dataIndex: ['nameEn'],
    key: 'nameEn',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.unit'),
    dataIndex: ['unit'],
    key: 'unit',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.sortVal'),
    dataIndex: 'sortVal',
    key: 'sortVal',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.depreciationRate'),
    dataIndex: 'depreciationRate',
    key: 'depreciationRate',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
];
