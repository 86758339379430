import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { t } from 'react-switch-lang';
import { Poultry } from './Poultry';
import { PoultryFattening } from './PoultryFattening';
import { useHistory } from 'react-router-dom';

const PoultryFormContainer = (props) => {

  const { TabPane } = Tabs;

  const [defaultTab, setDefaultTab] = useState(1);

  const history = useHistory();

  useEffect(() => {
    setDefaultTab(localStorage.getItem('poultryFattening'));
  }, []);

  useEffect(() => history.listen(() => {
    if (!history?.location?.pathname.includes('poultry')) {
      localStorage.setItem('poultryFattening', '1');
    }
  }), []);

  function callback(key) {
    setDefaultTab(key);
    localStorage.setItem('poultryFattening', key);
  }

  return (
    <Tabs defaultActiveKey={defaultTab} key={defaultTab} onChange={callback}>
      <TabPane tab={ t('pages.poultry.owned') } key="1">
        <Poultry />
      </TabPane>
      <TabPane tab={ t('pages.poultry.fattening') } key="2">
        <PoultryFattening />
      </TabPane>
    </Tabs>
  )
};

export default PoultryFormContainer;