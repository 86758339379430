import React, {useEffect, useRef, useState} from 'react';
import {axiosPost, axiosPut} from "../../../../../../services/axios";
import Toast from "../../../../../../components/toast/Toast";
import {t} from "react-switch-lang";
import ValidationForm from "../../../../../../components/form/validationForm/ValidationForm";
import InputField from "../../../../../../components/form/inputField/InputField";
import SaveBtn from "../../../../../../components/buttons/SaveBtn";
import CancelBtn from "../../../../../../components/buttons/CancelBtn";
import SelectAsyncField from "../../../../../../components/form/selectField/SelectAsyncField";
import {eng} from "../../../../../../utils/const";

const ProductionPlantProductsCategoriesForm = ({ data, type, updateTable, closeModal, isDisabled }) => {

  const formRef = useRef();

  const initialData = {
    productionPlantProduct: undefined,
    soCode: null,
    productionCode: undefined,
    frCategory: '',
  };
  const [changedData, setChangedData] = useState(initialData);

  const validation = {
    productionPlantProduct: { type: 'selectSingle', required: { value: true } },
    productionCode: { type: 'selectSingle', required: { value: true } },
    frCategory: { type: 'number', required: { value: true }, min: { value: 1 } },
    soCode: { type: 'string', required: { value: false }, min: { value: 1 } },
  }

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData,
        productionPlantProduct: { id: changedData.productionPlantProduct.value },
        productionCode: { id: changedData.productionCode.value },
      };

      try {
        const response = await axiosPost('production-plant-product-categories', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.productionPlantProductCategories.successAdd'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData,
        productionPlantProduct: { id: changedData.productionPlantProduct.value },
        productionCode: { id: changedData.productionCode.value }
      };

      try {
        const response = await axiosPut(`production-plant-product-categories/${data.id}`, payload);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.productionPlantProductCategories.successEdit'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        productionPlantProduct: { value: data.productionPlantProduct.id, label: eng ? data.productionPlantProduct.nameEn : data.productionPlantProduct.name },
        productionCode: { value: data.productionCode.id, label: eng ? data.productionCode.nameEn : data.productionCode.name },
        soCode: data.soCode,
        frCategory: data.frCategory
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <SelectAsyncField
          api={'production-plant-products'}
          label={t('administration.enums.columns.productionPlantProduct')}
          placeholder={t('administration.enums.placeholders.productionPlantProduct')}
          value={changedData.productionPlantProduct}
          name='productionPlantProduct'
          groupField
          pagination={false}
          onChange={(e) => {
            setChangedData({ ...changedData, productionPlantProduct: e, });
          }}
          disabled={isDisabled}
        />
        <SelectAsyncField
          api={'production-codes'}
          label={t('administration.enums.columns.productionCode')}
          placeholder={t('administration.enums.placeholders.productionCode')}
          value={changedData.productionCode}
          name='productionCode'
          groupField
          pagination={false}
          onChange={(e) => {
            setChangedData({ ...changedData, productionCode: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.soCode')}
          placeholder={t('administration.enums.placeholders.soCode')}
          value={changedData.soCode}
          name='soCode'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, soCode: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.frCategory')}
          placeholder={t('administration.enums.placeholders.frCategory')}
          value={changedData.frCategory}
          name='frCategory'
          type={'number'}
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, frCategory: e, });
          }}
          disabled={isDisabled}
        />
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)} />
        </div>
      )}
    </>
  );
};

export default ProductionPlantProductsCategoriesForm;
