import React, { useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import { t } from 'react-switch-lang';

export const MoneyExtra = ({ closeSidebar }) => {
  let { ahid } = useParams();

  const items = [
    {
      name: 'expenses',
      link: `/agricultural-holding/${ahid}/money/expenses`,
      text: t('sidebarExtra.money.expenses'),
      tooltip: t('sidebarExtra.money.expenses'),
    },
    {
      name: 'grants',
      link: `/agricultural-holding/${ahid}/money/subsidies`,
      text: t('sidebarExtra.money.grants'),
      tooltip: t('sidebarExtra.money.grants'),
    },
    {
      name: 'loans',
      link: `/agricultural-holding/${ahid}/money/loans`,
      text: t('sidebarExtra.money.loans'),
      tooltip: t('sidebarExtra.money.loans'),
    },
    {
      name: 'moneyFlow',
      link: `/agricultural-holding/${ahid}/money/money-flow`,
      text: t('sidebarExtra.money.moneyFlow'),
      tooltip: t('sidebarExtra.money.moneyFlow'),
    },
    {
      name: 'otherIncome',
      link: `/agricultural-holding/${ahid}/money/other-income`,
      text: t('sidebarExtra.money.otherIncome'),
      tooltip: t('sidebarExtra.money.otherIncome'),
    },
  ];

  const query = window.location.pathname;

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div
            className={`system-link links ${query === item.link ? 'active' : ''}`}
            key={index}
            onClick={() => closeSidebar()}
          >
            <Link to={item.link}>{item.text}</Link>
          </div>
        );
      })}
    </div>
  );
};
