import { t } from 'react-switch-lang';

export const formatGenerateFrErrorResponse = (errors, multi = true) => {
  const message = errors.map((ah) => {
    const ahErrorId = ah['agroHoldingDTO']['ahFadnId'];
    const ahErrors = ah['field']
      .map((err) => t(`pages.agriculturalHolding.${err}`).toLowerCase())
      .join(', ');

    return multi
      ? `${t('pages.agriculturalHolding.ahFadnId')} ${ahErrorId}: ${ahErrors}`
      : ahErrors;
  });

  return multi
    ? t('reports.messages.generateFailure') + '<br/><br/>' + message.join('<br/>')
    : t('reports.messages.generateSingleFailure') + ' ' + message;
};
