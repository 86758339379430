import React, { memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import useRefState from '../../../hooks/setRefHook/setRefHook';
import PropTypes from 'prop-types';
import loansFields from "../../../app/pages/explainableControls/utils/formFieldsLists/money/loans/moneyLoans";

// usage

//  const validation = {
//     'input-field': {
//       type: 'string',
//       required: {value: true, message: 'Polje je obavezno'},
//       min: {
//         value: 3,
//         message: 'Polje ne može imati ispod 3 karaktera',
//       },
//       max: {
//         value: 10,
//         message: 'Polje ne može imati iznad 10 karaktera',
//       },
//     },
//     'textarea-field': {
//       type: 'string',
//       required: {value: true, message: 'Polje je obavezno'},
//       min: {
//         value: 3,
//         message: 'Polje ne može imati ispod 3 karaktera',
//       },
//       max: {
//         value: 10,
//         message: 'Polje ne može imati iznad 10 karaktera',
//       },
//     },
//    'select-field': {
//       type: 'selectSingle',
//       required: {value: true, message: 'Polje je obavezno'},
//    },
// }

// in parent component
// const formRef = useRef();

// <button
//     onClick={(e) => {
//       e.preventDefault();
//       formRef.current.submitForm(onSubmit);
//     }}
// >
//   Click me!
// </button>
//    button can either go outside or inside ValidationForm

// <ValidationForm validation={validation} ref={formRef}>
//    {children}
// </ValidationForm>

const renderElements = (children, showErrors, setError, setRef, validation) => {
  const checkElement = (childElement, showErrors, setError, setRef, validation) => {
    if (!(React.isValidElement(childElement))) return childElement;
    if ((childElement.props.groupField === true || childElement.props.isComponentWrapper === true) && validation !== {}) {
      return React.cloneElement(childElement, {
        ref: setRef,
        validation: validation[childElement.props.name],
        setError: (isError) => setError(childElement.props.name, isError),
        displayError: showErrors,
      });
    } else if (childElement.props.children) {
      return React.cloneElement(childElement, { children: renderElements(childElement.props.children, showErrors, setError, setRef, validation) });
    } else return childElement;
  };

  return Array.isArray(children)
    ? React.Children.map(children, (child) =>
      checkElement(child, showErrors, setError, setRef, validation),
    )
    : checkElement(children, showErrors, setError, setRef, validation);
};


const ValidationForm = React.forwardRef(({ children, validation }, formRef) => {
  const [refs, setRef] = useRefState(); // for focusing fields
  const [errors, setErrors] = useState({}); // for error statuses in fields
  const [showErrors, setShowErrors] = useState({}); // true when submitted - show validation messages

  useEffect(() => {
    // on component unmounting return states to initial
    return () => {
      setRef({});
      setErrors({});
      setShowErrors({});
    };
    // eslint-disable-next-line
  }, []);

  // function passed to field to set error true/false in errors object
  const setError = useCallback((name, isError) => {
    if (name !== undefined && name !== null) {
      if (isError) {
        setErrors((prevDataState) => ({
          ...prevDataState,
          ...{ [name]: isError },
        }));
      } else {
        setErrors((prevDataState) => ({
          ...prevDataState,
          ...{ [name]: isError },
        }));
      }
    }
  }, []);

  // pass props ref and errors to children elements
  const childrenWithProps = React.Children.map(children, (child) => {
    return renderElements(child, showErrors, setError, setRef, validation);
  });

  // what happens on submit btn click
  useImperativeHandle(formRef, () => ({
    // function passed to parent component with ref
    submitForm(onSubmit) {
      const hasNoErrors = Object.keys(errors).every(k => errors[k] === 'noErrors');
      if (hasNoErrors) {
        // if validation ok
        setShowErrors({});
        onSubmit && onSubmit();
      } else {
        setShowErrors({ ...errors });
        focusField();
      }
    },
  }));

  // focus first field with error
  const focusField = () => {
    // eslint-disable-next-line
    const focusErrField = Object.entries(errors).find(([key, value]) => {
      if (value === 'error' || value === 'apiError') {
        return key;
      }
    });
    if (focusErrField !== undefined && refs.hasOwnProperty(focusErrField[0])) {
      refs[focusErrField[0]].focus();
    }
  };

  return (
    <form style={{ width: '100%' }}>
      {childrenWithProps}
    </form>
  );
});

ValidationForm.propTypes = {
  children: PropTypes.any.isRequired,
  validation: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default memo(ValidationForm);
