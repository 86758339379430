import { axiosGet } from '../../axios';
import { eng } from "../../../utils/const";

const defaultOption = (option) => {
  return {
    label: eng ? option.nameEn : option.name,
    value: option.id,
    disabled: false,
  };
};

export const fetchSingleLeasing = (id) => {
  return axiosGet(`leasings/${id}`);
};

export const getLeasingTypes = () => {
  return axiosGet('leasing-types').then(r => {
    let arrTemp = [];
    for (let item of r.data) {
      arrTemp.push(
        defaultOption(item),
      );
    }
    return arrTemp;
  }).catch(() => []);
};
