import React, { useState } from 'react';
import classes from './NavigationDropdown.module.scss';
import { Dropdown, Menu } from 'antd';
import HamburgerMenu from '../../../../../assets/images/navbar/hamburger-menu.svg';
import Close from '../../../../../assets/images/navbar/close-menu.svg';
import { Link } from 'react-router-dom';

const NavigationDropdown = ({ navLinks, className }) => {
  const [open, isOpen] = useState(false);

  const menu = (
    <Menu className={classes.dropdown__menu}>
      {
        navLinks?.map((link) => {
          return (
            <Menu.Item key={link.name} onClick={() => isOpen(prevState => !prevState)} className={classes['dropdown__menu--item']}>
              <Link to={link.path}>
                {link.name}
              </Link>
            </Menu.Item>
          )})
      }
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement={'bottomRight'}>
      <img src={open ? Close : HamburgerMenu} alt='Logout' className={className} onClick={() => isOpen(prevState => !prevState)} />
    </Dropdown>
  );
};

export default NavigationDropdown;