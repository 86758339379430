import React, { Suspense } from 'react';
import classes from './Buttons.module.scss';
import PropTypes from 'prop-types';
import { t } from 'react-switch-lang';
import { useRedirectPrompt } from '../../contexts/RedirectPrompt';

const MyIcon = React.lazy(() => import('../../components/icons/MyIcon'));
const Loader = React.lazy(() => import('../../components/loader/Loader'));

// if loaderOnDisabled true and loader true - loaderIcon is shown when btn is disabled
// if loaderOnDisabled false - loaderIcon is shown when loader is true

// only icon - set label '' icon true

const CancelBtn = ({
  onClick,
  label = t('common.cancel'),
  className = '',
  disabled = false,
  loader = false,
  icon = false,
  loaderOnDisabled = false,
  keepDirty = false
}) => {
  const { setIsDirty } = useRedirectPrompt();

  return (
    <button
      className={`${className} ${classes['cancel']} ${classes['button']} ${
        label.length > 0 ? '' : 'px-0 py-1'
      }`}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
        !keepDirty && setIsDirty(false);
      }}
    >
      {icon && (
        <Suspense fallback={<></>}>
          <MyIcon
            className={`${label.length > 0 ? 'mr-1 pl-1' : 'mx-1'}`}
            imgName="cancel-small-grey.svg"
          />
        </Suspense>
      )}
      <span className={`${label.length > 0 ? classes['text'] : ''}`}>
        {label}
      </span>
      {(loaderOnDisabled && loader && disabled) ||
      (!loaderOnDisabled && loader) ? (
        <Suspense fallback={<></>}>
          <Loader color="dark" />
        </Suspense>
      ) : null}
    </button>
  );
};

CancelBtn.propTypes = {
  label: PropTypes.string,
};

export default CancelBtn;