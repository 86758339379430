import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SelectConstField from '../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './MoneyFlowForm.module.scss';
import { Toast } from '../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import UserData from '../../../../../contexts/UserData';
import { fetchSingleMoneyFlow, getMoneyFlowTypes } from '../../../../../services/money/moneyFlow';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import Loader from '../../../../../components/loader/Loader';

const MoneyFlowForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    cashFlowType: { type: 'selectSingle', required: { value: true } },
    closingValue: { type: 'number', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    purchasesVATPaidValue: { type: 'number', required: { value: false } },
    salesVATChargedValue: { type: 'number', required: { value: false } },
    vatAdvanceValue: { type: 'number', required: { value: false } },
    vatReturnValue: { type: 'number', required: { value: false } },
  };
  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [moneyFlow, setMoneyFlow] = useState();

  const [cashFlowType, setCashFlowType] = useState({ value: undefined, disabled: !edit });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [purchasesVATPaidValue, setPurchasesVATPaidValue] = useState({ value: 0, disabled: !edit });
  const [salesVATChargedValue, setSalesVATChargedValue] = useState({ value: 0, disabled: !edit });
  const [vatAdvanceValue, setVatAdvanceValue] = useState({ value: 0, disabled: true });
  const [vatReturnValue, setVatReturnValue] = useState({ value: 0, disabled: true });

  // Enums
  const [cashFlowTypeList, setCashFlowTypeList] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/
  const setCashFlowTypeConditional = (e) => {
    if (e?.value) {
      if (e.value !== 4) {
        setClosingValue(prevState => ({
          ...prevState,
          value: prevState.value === 0
            ? undefined
            : prevState.value,
          disabled: !edit,
        }));
        setPurchasesVATPaidValue(prevState => ({ ...prevState, value: 0, disabled: true }));
        setSalesVATChargedValue(prevState => ({ ...prevState, value: 0, disabled: true }));
        setVatAdvanceValue(prevState => ({ ...prevState, value: 0 }));
        setVatReturnValue(prevState => ({ ...prevState, value: 0 }));
      } else {
        setClosingValue(prevState => ({ ...prevState, value: 0, disabled: true }));
        setPurchasesVATPaidValue(prevState => ({
          ...prevState,
          value: prevState.value === 0
            ? undefined
            : prevState.value,
          disabled: !edit,
        }));
        setSalesVATChargedValue(prevState => ({
          ...prevState,
          value: prevState.value === 0
            ? undefined
            : prevState.value,
          disabled: !edit,
        }));
        setVatAdvanceValue(prevState => ({
          ...prevState,
          value: prevState.value === 0
            ? undefined
            : prevState.value,
        }));
        setVatReturnValue(prevState => ({
          ...prevState,
          value: prevState.value === 0
            ? undefined
            : prevState.value,
        }));
      }
    }
  };

  const setValReturn = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;
    const calcReturnVal = parseFloatCustom(returnNullVal(salesVATChargedValue?.value)
      - returnNullVal(purchasesVATPaidValue?.value)).toFixed(2);
    setVatReturnValue(prevState => ({ ...prevState, value: calcReturnVal > 0 ? calcReturnVal : 0 }));
  };

  const setValAdvanced = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;
    const calcAdvancedVal = parseFloatCustom(returnNullVal(purchasesVATPaidValue?.value)
      - returnNullVal(salesVATChargedValue?.value)).toFixed(2);
    setVatAdvanceValue(prevState => ({ ...prevState, value: calcAdvancedVal > 0 ? calcAdvancedVal : 0 }));
  };
  /***** END DEPENDANT VALIDATION *****/

  useEffect(() => {
    if (!isAudit) {
      setValReturn();
      setValAdvanced();
    }
  }, [salesVATChargedValue?.value, purchasesVATPaidValue?.value]);


  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'moneyFlow', moneyFlow, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(cashFlowType.value?.value && { cashFlowType: { id: parseInt(cashFlowType.value?.value) } }),
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        ...(hasValue(purchasesVATPaidValue?.value) && { purchasesVATPaidValue: parseFloatCustom(purchasesVATPaidValue.value) }),
        ...(hasValue(salesVATChargedValue?.value) && { salesVATChargedValue: parseFloatCustom(salesVATChargedValue.value) }),
        ...(hasValue(vatAdvanceValue?.value) && { vatAdvanceValue: parseFloatCustom(vatAdvanceValue.value) }),
        ...(hasValue(vatReturnValue?.value) && { vatReturnValue: parseFloatCustom(vatReturnValue.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`cash-flows?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.moneyFlow') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`cash-flows/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.moneyFlow') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchMoneyFlow = async () => {
    try {
      const { data } = await fetchSingleMoneyFlow(props.match.params.id);
      setMoneyFlow(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchMoneyFlowTypes = async () => {
    const data = await getMoneyFlowTypes();
    setCashFlowTypeList(data);
  };

  useEffect(() => {
    fetchMoneyFlowTypes();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchMoneyFlow().then(moneyFlow => {
          moneyFlow?.cashFlowType && setCashFlowType(prevState => ({
            ...prevState,
            value: defaultOption(moneyFlow?.cashFlowType),
          }));
          setComment(prevState => ({ ...prevState, value: moneyFlow?.comment }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(moneyFlow?.closingValue) }));
          setPurchasesVATPaidValue(prevState => ({
            ...prevState,
            value: parseDouble(moneyFlow?.purchasesVATPaidValue),
          }));
          setSalesVATChargedValue(prevState => ({ ...prevState, value: parseDouble(moneyFlow?.salesVATChargedValue) }));
          setVatAdvanceValue(prevState => ({ ...prevState, value: parseDouble(moneyFlow?.vatAdvanceValue) }));
          setVatReturnValue(prevState => ({ ...prevState, value: parseDouble(moneyFlow?.vatReturnValue) }));

          setErrorExplanations(moneyFlow?.errorExplanations ? [...moneyFlow?.errorExplanations] : []);

          let explanations = moneyFlow?.errorExplanations ? moneyFlow?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'moneyFlow', moneyFlow, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        data?.cashFlowType && setCashFlowType(prevState => ({
          ...prevState,
          value: defaultOption(data?.cashFlowType),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setPurchasesVATPaidValue(prevState => ({ ...prevState, value: parseDouble(data?.purchasesVATPaidValue) }));
        setSalesVATChargedValue(prevState => ({ ...prevState, value: parseDouble(data?.salesVATChargedValue) }));
        setVatAdvanceValue(prevState => ({ ...prevState, value: parseDouble(data?.vatAdvanceValue) }));
        setVatReturnValue(prevState => ({ ...prevState, value: parseDouble(data?.vatReturnValue) }));

        setErrorExplanations(data?.errorExplanations ? [...data?.errorExplanations] : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'moneyFlow', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        // setValidation({ ...validationInitial });
        setCashFlowType(prevState => ({ ...prevState, disabled: false }));
        // setClosingValue(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        // setPurchasesVATPaidValue(prevState => ({ ...prevState, disabled: false }));
        // setSalesVATChargedValue(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);


  // const prevVal = usePrevious(submitDisabled);
  // useDidMountEffect(() => {
  //   (!compareObjects(submitDisabled, prevVal) && showErrors) && handleSubmit(false);
  // }, [submitDisabled]);

  useEffect(() => {
    if (!isAudit) {
      setCashFlowTypeConditional(cashFlowType?.value);
    }
  }, [cashFlowType?.value, edit]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.moneyFlow.moneyFlowType')}
                    placeholder={t('pages.moneyFlow.placeholder.moneyFlowType')}
                    value={cashFlowType.value}
                    name='cashFlowType'
                    onChange={(e) => {
                      setCashFlowType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={cashFlowTypeList}
                    disabled={cashFlowType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['cashFlowType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.moneyFlow.purchasesVATPaidValue')}
                    placeholder={t('pages.moneyFlow.placeholder.purchasesVATPaidValue')}
                    value={purchasesVATPaidValue.value}
                    suffix='€'
                    name='purchasesVATPaidValue'
                    type={'double'}
                    onChange={(e) => {
                      setPurchasesVATPaidValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchasesVATPaidValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchasesVATPaidValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    noMin={true}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.moneyFlow.salesVATChargedValue')}
                    placeholder={t('pages.moneyFlow.placeholder.salesVATChargedValue')}
                    value={salesVATChargedValue.value}
                    suffix='€'
                    name='salesVATChargedValue'
                    type={'double'}
                    onChange={(e) => {
                      setSalesVATChargedValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={salesVATChargedValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['salesVATChargedValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    noMin={true}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.moneyFlow.vatAdvanceValue')}
                    placeholder={t('pages.moneyFlow.placeholder.vatAdvanceValue')}
                    value={vatAdvanceValue.value}
                    name='vatAdvanceValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setVatAdvanceValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={vatAdvanceValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['vatAdvanceValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    noMin={true}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.moneyFlow.vatReturnValue')}
                    placeholder={t('pages.moneyFlow.placeholder.vatReturnValue')}
                    value={vatReturnValue.value}
                    name='vatReturnValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setVatReturnValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={vatReturnValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['vatReturnValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    noMin={true}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.moneyFlow.closingValue')}
                    placeholder={t('pages.moneyFlow.placeholder.closingValue')}
                    value={closingValue.value}
                    suffix='€'
                    name='closingValue'
                    type={'double'}
                    isNegative={true}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    noMin={true}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.moneyFlow.comment')}
                    placeholder={t('pages.moneyFlow.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/money-flow`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default MoneyFlowForm;
