import React, { createContext, useContext, useState } from 'react';

const NotificationBadge = createContext({});

const NotificationBadgeProvider = ({ children }) => {
  const [hasGlobalRequests, setHasGlobalRequests] = useState(false);

  const data = {
    hasGlobalRequests: hasGlobalRequests,
    setHasGlobalRequests: setHasGlobalRequests,
  };

  return <NotificationBadge.Provider value={data}>{children}</NotificationBadge.Provider>;
};

export const useNotificationBadge = () => useContext(NotificationBadge);

export default NotificationBadgeProvider;
