import React, { useEffect, useState } from 'react';
import FieldContainer from '../fieldContainer/FieldContainer';
import classes from './CheckboxField.module.scss';
import Checkbox from './components/Checkbox';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { checkIfExplanationValid, validate } from '../../../utils/validationDynamic';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { compareObjects } from '../../../utils/const';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

//     const optionList = [
//         {
//             value: 'val1',
//             label: 'Val 1',
//             disabled: true
//         },
//         {
//             value: 'val2',
//             label: 'Val 2'
//         },
//         {
//             value: 'val3',
//             label: 'Val 3'
//         }
//     ]

//  const [checkbox, setCheckbox] = useState();

// <CheckboxField
//                 label="Test checkbox"
//                 options={options}
//                 value={checkbox}
//                 onChange={(e) => setCheckbox(e)}/>

const CheckboxField = (
    {
      name,
      label,
      showErrors,
      helpLabel,
      value = [],
      showRequiredError = false,
      onChange,
      className,
      setSubmitDisabled,
      onSubmit = () => {},
      errorExplanations,
      setErrorExplanations = () => {},
      hasWriteExplanationPermission = true,
      validation = {type: ''},
      options,
      disabled,
      setError,
      displayError,
      styles,
      ...rest
    }) => {
    let uniqueId = uniqid();
    let componentName = name ? name : uniqueId;

  const redirectPrompt = useRedirectPrompt();

  const [messageData, setMessageData] = useState(validate({ ...validation, required: { value: false } }, value));
  const [isDirty, setIsDirty] = useState(false);

    const prevVal = usePrevious(value);
  const prevValidation = usePrevious({ ...validation, required: { value: false } });

      useEffect(() => {
          // on component unmounting return states to initial
          return () => {
            setMessageData({})
          }
      }, [])


    useEffect(() => {
        // eslint-disable-next-line
      if ((!compareObjects(prevVal, value)) || (!compareObjects({ ...validation, required: { value: false } }, prevValidation))) {
        let errorObject = validate({ ...validation, required: { value: false } }, value);
        if (errorObject?.required?.errorType === "required" || errorObject?.apiErrors?.errorType === "apiError") {
            setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
        } else {
          if (!checkIfExplanationValid(errorObject)) {
            setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
          } else {
            setSubmitDisabled(prevState => ({ ...prevState, [name]: false }));
          }
        }
        isDirty && disabled === false && onSubmit();
        setMessageData(errorObject);
      }
        // eslint-disable-next-line
    }, [value, validation, disabled]);

    // // handles setting checked/unchecked on data load
    // useEffect(() => {
    //   setCheckbox(value)
    // }, [value])

    const handleCheckbox = (e) => {
      if (value.includes(e)) {
        const newCheckbox = value.filter((item) => item !== e);
        onChange(newCheckbox);
      } else {
        const newCheckbox = [...value, e];
        onChange(newCheckbox);
      }
      setIsDirty(true)
    };

    return (
      <FieldContainer
        label={label}
        showErrors={showErrors}
        helpLabel={helpLabel}
        required={
          false
        }
        additionalMessageData={messageData}
        customClasses={`${styles}`}
        errorExplanations={errorExplanations}
        setErrorExplanations={setErrorExplanations}
        showRequiredMessage={false}
        hasWriteExplanationPermission={hasWriteExplanationPermission}
        {...rest}
      >
        <div
          className={`${className ? className : ''} ${
            classes['checkbox-field']
          }`}
        >
          {Array.isArray(options) &&
            options.map((item, index) => {
              return (
                <Checkbox
                  key={`${name}-${index}`}
                  checked={value}
                  name={componentName}
                  value={item.value}
                  label={item.label}
                  onChange={(e) => {
                    handleCheckbox(e);
                    redirectPrompt.setIsDirty(true);
                  }}
                  disabled={
                    disabled === true
                      ? true
                      : item.disabled !== undefined
                      ? item.disabled
                      : false
                  }
                />
              );
            })}
        </div>
      </FieldContainer>
    );
  };

CheckboxField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    })
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  validation: PropTypes.object,
  setError: PropTypes.func,
  displayError: PropTypes.bool,
};

export default CheckboxField;
