import React from 'react';
import { Tooltip } from 'antd';
import { t } from 'react-switch-lang';
import { eng, formatNumber, hasValue } from '../../../../../utils/const';

const renderColWithTooltip = (col) => {
  return (
    <Tooltip
      title={col}
      placement="topLeft"
      color={'#67B278'}
      overlayInnerStyle={{ borderRadius: '5px' }}
    >
      {hasValue(col) ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.unfinishedProducts.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: (id) => renderColWithTooltip(id),
  },
  {
    title: t('pages.unfinishedProducts.productionPlantProduct'),
    dataIndex: ['productionPlantProduct', eng ? 'nameEn' : 'name'],
    key: 'productionPlantProduct',
    ellipsis: true,
    render: (productionPlantProduct) => renderColWithTooltip(productionPlantProduct),
  },
  {
    title: t('pages.unfinishedProducts.area'),
    dataIndex: 'area',
    key: 'area',
    ellipsis: true,
    render: (area) => renderColWithTooltip(formatNumber(area)),
  },
  // {
  //   title: t('pages.unfinishedProducts.comment'),
  //   dataIndex: 'comment',
  //   key: 'comment',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  {
    title: t('pages.unfinishedProducts.value'),
    dataIndex: 'value',
    key: 'value',
    ellipsis: true,
    render: (value) => renderColWithTooltip(formatNumber(value)),
  },
];
