import FormLabel from '../formLabel/FormLabel';
import classes from './FieldContainer.module.scss';
import PropTypes from 'prop-types';
import React from 'react';
import FormHelp from '../formHelp/FormHelp';

const FieldContainer = React.forwardRef(({
                                           label,
                                           helpLabel = '',
                                           componentName,
                                           children,
                                           required,
                                           errorMessage,
                                           customClasses,
                                         }, ref) => {

  return (
    <div
      className={`${classes['field-container']} ${customClasses}`}
    >
      <div className="d-flex align-items-center">
        {label ? <FormLabel componentName={componentName} label={label} required={required}/> : <></>}
        {helpLabel !== '' ? <FormHelp helpLabel={helpLabel}/> : <></>}
      </div>
      <div ref={ref}>
        {children}
      </div>
      <div className={classes['error']}>
        {errorMessage?.type !== 'noErrors' ?
          <div>
            {
              React.Children.map(errorMessage?.body, message => (
                <div className='validationError mt-2'>
                  {message}
                </div>
              ))
            }
          </div>
          : ''}
      </div>
    </div>
  );
});

FieldContainer.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.element.isRequired,
};

export default FieldContainer;
