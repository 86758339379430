import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import classes from './FormHelp.module.scss';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';

const FormHelp = ({ helpLabel }) => {
  return (
    <div className={classes['tooltip']}>
      <Tooltip title={helpLabel} placement="topLeft" color={'#67B278'}>
        <QuestionCircleOutlined className={classes['icon']} />
      </Tooltip>
    </div>
  );
};

FormHelp.propTypes = {
  helpLabel: PropTypes.string.isRequired,
};

export default FormHelp;