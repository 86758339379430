import React from 'react';
import classes from './CardsGrid.module.scss';

// list
//    title
//    image
//    explanation
//        title
//        value
//    onClick
export const CardsGrid = ({ list }) => {
  return <div className={classes['container']}>
    {
      list.length === 0
      ? null
        : list && list.map(item => <div onClick={() => item.onClick && item.onClick()}
                                        className={classes['item']}>
        {item.image?.length > 0 &&
        <div className={classes['image']}>
          <img src={item.image} alt={item.title}/>
        </div>
        }
        <div className={classes['title']}>
          {item.title}
        </div>
        {item.explanation &&
        <div className={classes['explanation']}>
          <div>{item.explanation.title}</div>
          <div>{item.explanation.value}</div>
        </div>
        }
      </div>)
    }
  </div>
}