import React from 'react';
import FrCategory from "../../../../../components/administration/enums/frCategoryForm/FrCategory";
import {t} from "react-switch-lang";

const TaxExpenseType = () => {
  return (
    <div>
      <FrCategory api={'tax-expense-types'} title={t('administration.enums.title.taxExpenseType')}/>
    </div>
  );
};

export default TaxExpenseType;
