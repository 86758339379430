import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const unfinishedProductsFields = [
  {
    value: 'productionPlantProduct',
    label: () => t('pages.unfinishedProducts.productionPlantProduct'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'production-plant-products',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'area',
    label: () => t('pages.unfinishedProducts.area'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'value',
    label: () => t('pages.unfinishedProducts.value'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.unfinishedProducts.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default unfinishedProductsFields;