import Prompt from '../../../../../../components/prompt/Prompt';
import { t } from 'react-switch-lang';
import { axiosDelete } from '../../../../../../services/axios';
import Toast from '../../../../../../components/toast/Toast';
import Swal from 'sweetalert2';

export const onDelete = (record) => {
  return new Promise((resolve, reject) => {
    Prompt.fire({
      title: t('common.delete') + ' ' + t('messages.goatFattening') + ' ' + record.id,
      text:
        t('common.deleteQuestion', { entity: (t('messages.goatFattening') + ' ' + record.id) }) + '?',
      confirmButtonText: t('common.delete'),
      cancelButtonText: t('common.cancel'),
      preConfirm: async () => {
        try {
          const response = await axiosDelete('goat-service-fattenings', record.id);
          if (response.status === 204) {
            Toast.fire({
              title:
                t('common.successDelete', { entity: (t('messages.goatFattening') + ' ' + record.id) }) + '!',
              icon: 'success',
            });
            resolve(response);
          } else {
            reject(response);
          }
        } catch (err) {
          Swal.fire({
            text: t('common.apiErrorDelete'),
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      },
    });
  });
};