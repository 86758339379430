import React, { useContext } from 'react';
import classes from './OptionsDropdown.module.scss';
import { t } from 'react-switch-lang';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import EditImg from '../../../../table/img/edit.svg';
import DeleteImg from '../../../../table/img/delete.svg';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Toast from '../../../../toast/Toast';
import { axiosDelete } from '../../../../../services/axios';
import Prompt from '../../../../prompt/Prompt';
import Locked from '../../../../../assets/images/navbar/locked.svg';
import Unlocked from '../../../../../assets/images/navbar/unlocked.svg';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import { hasValue } from '../../../../../utils/const';
import {
  lockAgriculturalHolding,
  unlockAgriculturalHolding,
} from '../../../../../services/agriculturalHolding/agriculturalHoldingApi';

const OptionsDropdown = ({ isAdmin, isCollector, redirectLink, onDelete }) => {

  const history = useHistory();

  const agriculturalData = useContext(AgriculturalHoldingData);

  const lockHolding = async () => {
    try {
      const response = await lockAgriculturalHolding(agriculturalData.agriculturalHoldingData?.id);
      if (response.status === 200) {
        await Toast.fire({
          title: t('administration.lockingErrors.messages.lockingSuccessful'),
          icon: 'success',
        });
        history.push('/home');
      }
    } catch (e) {
      if (e.response?.data.status === 400) {
        await Toast.fire({
          title: t('administration.lockingErrors.messages.lockingUnsuccessful'),
          icon: 'error',
        }).then(() => {
          history.push(`/agricultural-holding/${agriculturalData.agriculturalHoldingData?.id}/locking-errors`);
        });
      }
    }
  };

  const unlockHolding = async () => {
    const response = await unlockAgriculturalHolding(agriculturalData.agriculturalHoldingData?.id);
    if (response.status === 200) {
      await Toast.fire({
        title: t('administration.lockingErrors.messages.unlockingSuccessful'),
        icon: 'success',
      });

      history.push('/home');
    }
  };

  function handleMenuClick(e) {
    if (e?.key === 'edit') {
      history.push({
        pathname: redirectLink,
        state: {
          add: false,
          edit: true,
        },
      });
    } else if (e?.key === 'delete') {
      onDelete();
    } else if (e?.key === 'lock') {
      lockHolding();
    } else if (e?.key === 'unlock') {
      unlockHolding();
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick} className={classes.dropdown__menu}>
      <Menu.Item key='edit' icon={<img src={EditImg} alt='Edit' className={classes['dropdown__menu--item']}/>}>
        {t('navbar.optionsDropdown.edit')}
      </Menu.Item>
      {isAdmin &&
      <Menu.Item key='delete' icon={<img src={DeleteImg} alt='Delete' className={classes['dropdown__menu--item']}/>}>
        {t('navbar.optionsDropdown.delete')}
      </Menu.Item>}
      {isAdmin || isCollector &&
      <>
        {hasValue(agriculturalData.agriculturalHoldingData?.lockedDate) ?
          <Menu.Item key='unlock'
                     icon={<img src={Unlocked} alt='Unlocked' className={classes['dropdown__menu--item']}/>}>
            {t('navbar.optionsDropdown.unlock')}
          </Menu.Item>
          :
          <Menu.Item key='lock' icon={<img src={Locked} alt='Locked' className={classes['dropdown__menu--item']}/>}>
            {t('navbar.optionsDropdown.lock')}
          </Menu.Item>
        }
      </>
      }
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement={'bottomRight'} className={classes.dropdown} arrow>
      <Button className={classes.dropdown__button}>
        {t('navbar.optionsDropdown.options')} <DownOutlined
        style={{ strokeWidth: '100', stroke: 'black', marginLeft: 20 }}/>
      </Button>
    </Dropdown>
  );
};

export default OptionsDropdown;