import React, { useContext } from 'react';
import SaveBtn from '../../../components/buttons/SaveBtn';
import { axiosGetFile } from '../../../services/axios';
import { t } from 'react-switch-lang';
import Swal from 'sweetalert2';
import AdministrationTable from '../../../components/table/AdministrationTable';
import { columns } from './utils/reportsColumns';
import UserData from '../../../contexts/UserData';
import { useLocation } from 'react-router-dom';

export const Reports = () => {
  const location = useLocation();

  const report = location?.state?.report;
  const ahId = location?.state?.ahId;

  const user = useContext(UserData);

  const onDownload = () => {
    axiosGetFile(ahId ? `frReportXmlExport/${ahId}` : 'frReportXmlExport', 'xml_report.xml')
      .then()
      .catch(() => {
        Swal.fire({
          text: t('reports.messages.exportFailure'),
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        {report === 'fr' &&
          <SaveBtn
            label={t('reports.actions.exportXml')}
            className='mb-5 mt-3'
            onClick={() => onDownload()}
          />
        }
      </div>
      <AdministrationTable
        api={report === 'sr' ? 'sr-cache-for-reports' : 'fr-cache-for-reports'}
        query={ahId ? `agroHoldingId.equals=${ahId}` : ''}
        user={user}
        columns={columns(report)}
        methods={false}
        className={'table-hover'}
      />
    </div>
  );
};
