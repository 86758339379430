import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Resizable } from 'react-resizable';
import DeleteImg from './img/delete.svg';
import EditImg from './img/edit.svg';
import Swal from 'sweetalert2';
import 'antd/dist/antd.css';
import './customTable.scss';
import { axiosGet } from '../../services/axios';
import { t } from 'react-switch-lang';
import uniqid from 'uniqid';
import GoToRevision from '../buttons/GoToRevision';
import { useParams } from 'react-router-dom';
import ReportsDropdown from '../../app/pages/reports/reportsDropdown/ReportsDropdown';
import Loader from '../loader/Loader';
import { hasNoValue } from '../../utils/const';
import { useWindowWidth } from '../../contexts/WindowWidth';

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className='react-resizable-handle'
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
/**
 *
 * @param {object} props
 * @param {string} props.api 'Endpoint for fetching table data'
 * @param {array} props.columns 'Columns structure'
 * @param {integer} [props.rows=20] 'Number of rows to fetch'
 * @param {callback} [props.onRowClick] 'Callback function that takes record, rowIndex and click event parameters'
 * @param {object} props.methods 'Object containing onUpdate, onCreate, onDelete methods.'
 * @param {function} props.methods.onDelete 'Handles onDelete event. Must return response object.'
 * @param {function} props.methods.onUpdate 'Handles onUpdate event. Must return response object.'
 */

const CustomTable = ({
                       historyEntity,
                       group,
                       api,
                       query,
                       queryParamName,
                       columns,
                       rows = 20,
                       onRowClick,
                       methods,
                       actionsWidth,
                       onClickRow,
                       updated,
                       user,
                       ignoreById,
                       isLocked,
                       reports,
                       xScroll = true,
                       noPagination = false,
                       ...rest
                     }) => {
  let { ahid } = useParams();
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(window.matchMedia('(pointer: coarse)').matches === true);

  const windowWidth = useWindowWidth();

  let uniqueId = uniqid();

  // query = query ? (queryParamName ? `${queryParamName}=${query}` : `name.contains=${query}`) : '';

  const { onDelete, onUpdate } = methods;

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (noPagination) {
        const { data } = await axiosGet(`${api}`);
        setItems(data);
        setHasMore(data.length === rows);
        setPage(1);
      } else {
        const { data } = await axiosGet(`${api}?page=0&size=${rows}${query ? `&${query}` : ''}`);
        setItems(data);
        setHasMore(data.length === rows);
        // setHasMore(false);
        setPage(1);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchMoreData = async () => {
    try {
      const { data } = await axiosGet(`${api}?page=${page}&size=${rows}${query ? `&${query}` : ''}`);
      setItems([...items, ...data]);
      setHasMore(data.length === rows);
      setPage((page) => page + 1);
    } catch (err) {
    }
  };

  useEffect(() => {
    if (api !== undefined && api !== '') {
      fetchData();
    }
  }, [api, query]);

  // useEffect for checking if device is working with touch input
  useEffect(() => {
    setIsTouchDevice(window.matchMedia('(pointer: coarse)').matches === true);
  }, [windowWidth]);

  const mergedColumns = columns.map((col, index) => {
    return {
      ...col,
      title: () => <Tooltip title={col.title} placement='top' color={'#67B278'}
                            overlayInnerStyle={{ borderRadius: '5px' }}>{col.title}</Tooltip>,
      onCell: (record) => ({
        record,
        dataindex: col.dataIndex,
        rules: col.rules,
        options: col.options,
      }),
    };
  });

  const handleDelete = async (record) => {
    try {
      const response = await onDelete(record);
      if (response.status === 204) {
        // const filteredItems = items.filter((item) => item.id !== record.id);
        // setItems(filteredItems);
        fetchData();
      } else if (response.status === 403) {
        Swal.fire({
          text: `Permission`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
    }
  };

  // div for setting table height when table is loading
  const heightSetter = (
    <div style={{ height: 40 }}/>
  );

  const loader = (
    <div style={{ width: 40, zIndex: 30 }} className=' mt-3'>
      <Loader size='sm'/>
    </div>
  );

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize = (index) => (e, { size }) => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    return { columns: nextColumns };
  };

  const resizeColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  (user.isAdmin || user.isCollector) && methods && mergedColumns.push({
    title: t('common.actions'),
    key: 'actions',
    // ellipsis: true,
    align: 'center',
    className: 'actions',
    width: actionsWidth ? actionsWidth : 150,
    fixed: 'right',
    render: (record) => {
      return (
        !record.isDeleted &&
        <>
          {!isLocked && Object.keys(methods).length !== 0 &&
          <>
            <Tooltip title={t('common.edit')} placement='top' color={'#67B278'}
                     overlayInnerStyle={{ borderRadius: '5px' }}>
              <button
                type='button'
                className='btn p-0 mr-2'
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdate(record);
                }}
              >
                <img src={EditImg} alt='edit'/>
              </button>
            </Tooltip>
            {hasNoValue(record?.importedFromPrevYearId) &&
            <Tooltip title={isTouchDevice ? '' : t('common.delete')} placement='top' color={'#E50019'}
                     overlayInnerStyle={{ borderRadius: '5px' }}>
              <button
                type='button'
                className='btn p-0 mr-2'
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(record);
                }}
              >
                <img src={DeleteImg} alt='delete'/>
              </button>
            </Tooltip>
            }
          </>
          }
          {historyEntity &&
          <Tooltip title={t('common.history')} placement='top' color={'#7C7C7C'}
                   overlayInnerStyle={{ borderRadius: '5px' }}>
            <GoToRevision ahId={ahid} group={group} entity={historyEntity} id={record?.id}/>
          </Tooltip>
          }
          {reports &&
          <ReportsDropdown record={record}/>
          }
        </>
      );
    },
  });

  return (
    <div style={{ minHeight: 100 }}>
      <InfiniteScroll
        next={!noPagination ? fetchMoreData : []}
        hasMore={hasMore}
        loader={loader}
        scrollableTarget='agroContainer'
        dataLength={items.length}
        style={{ overflowY: 'hidden' }}
      >
        <Table
          rowClassName={(record) => {
            if (record?.userActive === false || record?.isDeleted === true) {
              return 'ant-table-row-disabled';
            }
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => (onClickRow ? onClickRow(record) : null),
            };
          }}
          loading={{ indicator: loader, spinning: isLoading }}
          components={components}
          columns={mergedColumns}
          dataSource={ignoreById ? items.filter((elem) => elem.id !== ignoreById) : items}
          sticky={true}
          pagination={false}
          locale={{ emptyText: !isLoading ? t('common.noData') : heightSetter }}
          rowKey={(record) => record.id}
          scroll={{ x: xScroll && windowWidth < 1100 ? 1500 : false }}
          {...rest}
        />
      </InfiniteScroll>
    </div>
  );
};

export default CustomTable;
