import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdministrationTable from '../../../components/table/AdministrationTable';
import { columns } from './utils/table/tableColumns';
import SaveBtn from '../../../components/buttons/SaveBtn';
import { t } from 'react-switch-lang';
import UserData from '../../../contexts/UserData';
import Button from 'antd/lib/button';
import classes from './AgriculturalHolding.module.scss';
import Calendar from '../../../assets/images/calendar/calendar.svg';
// import CircleChecked from '../../../assets/images/icons/circle-checked.svg';
import ReportsModal from '../reports/reportsModal/ReportsModal';
import { readOnly } from '../../../utils/const';

const AgriculturalHolding = () => {
  const user = useContext(UserData);

  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onRowClick = (record) => {
    history.push({
      pathname: `/administration/agricultural-holdings/${record.id}`,
      state: {
        add: false,
        edit: false,
      },
    });
  };

  return (
    user.isAdmin && (
      <div className="d-flex flex-column h-100">
        <div className="d-flex justify-content-end">
          {!readOnly &&
          <SaveBtn
            label={t('common.add')}
            className="mb-5"
            onClick={() =>
              history.push({
                pathname: '/administration/agricultural-holdings/add',
                state: {
                  edit: true,
                  add: true,
                },
              })
            }
          />
          }
          <Button className={classes['button']} onClick={() => setIsModalOpen(true)}>
            <div className="d-flex align-items-center">
              <img className="mr-2" src={Calendar} alt="calendar" />
              <span>{t('sidebar.reports')}</span>
            </div>
          </Button>
          {/*<Button className={classes['button']}>*/}
          {/*  <div className="d-flex align-items-center">*/}
          {/*    <img className="mr-2" src={CircleChecked} alt="circle-checked" />*/}
          {/*    <span>{t('reports.actions.allowForSr')}</span>*/}
          {/*  </div>*/}
          {/*</Button>*/}
        </div>
        <AdministrationTable
          api="agro-holdings"
          query={`isDeleted.equals=false`}
          user={user}
          columns={columns}
          methods={true}
          className={'table-hover'}
          onClickRow={onRowClick}
          reports={true}
        />
        <ReportsModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </div>
    )
  );
};

export default AgriculturalHolding;
