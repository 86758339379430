import { axiosGet, axiosPut } from '../axios';

export const fetchAgriculturalHolding = (id) => {
  return axiosGet(`agro-holdings/${id}`);
};

export const lockAgriculturalHolding = (id) => {
  return axiosPut(`agro-holdings/lock/${id}`);
};

export const unlockAgriculturalHolding = (id) => {
  return axiosPut(`agro-holdings/unlock/${id}`);
};

export const fetchAgriculturalHoldingErrorExplanationsStatistics = (id) => {
  return axiosGet(`error-explanations/${id}/error-explanation-statistics`);
};

