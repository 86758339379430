import React, { createContext, useContext, useEffect, useState } from 'react';

const WindowWidth = createContext({});

const WindowWidthProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <WindowWidth.Provider value={windowWidth}>{children}</WindowWidth.Provider>;
};

export const useWindowWidth = () => {
  return useContext(WindowWidth);
};

export default WindowWidthProvider;
