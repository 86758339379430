import React, { memo, useEffect, useState } from 'react';
import { Switch, useParams } from 'react-router-dom';
import Route from './app/privateRoute/PrivateRoute';
import ErrorPage404 from './app/pages/errorPages/404';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import Login from './app/pages/login/Login';
import { setDefaultLanguage, setTranslations, t } from 'react-switch-lang';
import UserData from './contexts/UserData';
import LoadingPage from './app/pages/errorPages/Loading';
import Dashboard from './app/pages/dashboard/Dashboard';
import { Labour } from './app/pages/labour/Labour';
import LabourForm from './app/pages/labour/labourForm/LabourForm';
import { Documents } from './app/pages/documents/Documents';
import Register from './app/pages/register/Register';
import Home from './app/pages/home/Home';
import PermanentCrops from './app/pages/longTermAssets/permanentCrops/PermanentCrops';
import MachineryEquipment from './app/pages/longTermAssets/machineryEquipment/MachineryEquipment';
import FarmBuildings from './app/pages/longTermAssets/farmBuilding/FarmBuildings';
import { AnimalProducts } from './app/pages/production/animalProducts/AnimalProducts';
import { ManufacturedProducts } from './app/pages/production/manufacturedProducts/ManufacturedProducts';
import ManufacturedProductsForm from './app/pages/production/manufacturedProducts/manufacturedProductsForm/ManufacturedProductsForm';
import { OtherProducts } from './app/pages/production/otherProducts/OtherProducts';
import { UnfinishedProducts } from './app/pages/production/unfinishedProducts/UnfinishedProducts';
import { PlantProducts } from './app/pages/production/plantProducts/PlantProducts';
import { Expenses } from './app/pages/money/expenses/Expenses';
import { Grants } from './app/pages/money/grants/Grants';
import { Loans } from './app/pages/money/loans/Loans';
import { MoneyFlow } from './app/pages/money/moneyFlow/MoneyFlow';
import { OtherIncome } from './app/pages/money/otherIncome/OtherIncome';
import eng from './languages/eng';
import mne from './languages/mne';
import { Administration } from './app/pages/administration/Administration';
import { Forms } from './app/pages/forms/Forms';
import { Reports } from './app/pages/reports/Reports';
import AgriculturalLand from './app/pages/longTermAssets/agriculturalLand/AgriculturalLand';
import AgriculturalLandForm from './app/pages/longTermAssets/agriculturalLand/agriculturalLandForm/AgriculturalLandForm';
import OtherProductsForm from './app/pages/production/otherProducts/otherProductsForm/OtherProductsForm';
import UnfinishedProductsForm from './app/pages/production/unfinishedProducts/unfinishedProductsForm/UnfinishedProductsForm';
import { fetchUserApi } from './services/users/usersAPI';
import AgriculturalHolding from './app/pages/agriculturalHolding/AgriculturalHolding';
import AgriculturalHoldingForm from './app/pages/agriculturalHolding/agriculturalHoldingForm/AgriculturalHoldingForm';
import { Subsidies } from './app/pages/money/grants/subsidies/Subsidies';
import SubsidyForm from './app/pages/money/grants/subsidies/subsidyForm/SubsidyForm';
import AnimalProductsForm from './app/pages/production/animalProducts/animalProductsForm/AnimalProductsForm';
import AgriculturalHoldingData from './contexts/AgriculturalHoldingData';
import PlantProductsForm from './app/pages/production/plantProducts/plantProductsForm/PlantProductsForm';
import MoneyFlowForm from './app/pages/money/moneyFlow/moneyFlowForm/MoneyFlowForm';
import { Loan } from './app/pages/money/loans/loan/Loan';
import { Leasing } from './app/pages/money/loans/leasing/Leasing';
import LeasingForm from './app/pages/money/loans/leasing/leasingForm/LeasingForm';
import LoanForm from './app/pages/money/loans/loan/loanForm/LoanForm';
import OtherIncomeForm from './app/pages/money/otherIncome/otherIncomeForm/OtherIncomeForm';
import { LandAndBuildingExpenses } from './app/pages/money/expenses/landAndBuildingExpenses/LandAndBuildingExpenses';
import LandAndBuildingForm from './app/pages/money/expenses/landAndBuildingExpenses/landAndBuildingForm/LandAndBuildingForm';
import { InsuranceExpenses } from './app/pages/money/expenses/insuranceExpenses/InsuranceExpenses';
import InsuranceExpensesForm from './app/pages/money/expenses/insuranceExpenses/insuranceExpensesForm/InsuranceExpensesForm';
import { TaxExpenses } from './app/pages/money/expenses/taxExpenses/TaxExpenses';
import TaxForm from './app/pages/money/expenses/taxExpenses/taxForm/TaxForm';
import { OverheadExpenses } from './app/pages/money/expenses/overheadExpenses/OverheadExpenses';
import OverheadExpenseForm from './app/pages/money/expenses/overheadExpenses/overheadForm/OverheadExpenseForm';
import { LabourExpenses } from './app/pages/money/expenses/labourExpenses/LabourExpenses';
import LabourExpenseForm from './app/pages/money/expenses/labourExpenses/labourForm/LabourExpenseForm';
import { ProcessingExpenses } from './app/pages/money/expenses/processingExpenses/ProcessingExpenses';
import ProcessingExpenseForm from './app/pages/money/expenses/processingExpenses/processingForm/ProcessingExpenseForm';
import CattleFormContainer from './app/pages/livestock/cattle/CattleFormContainer';
import CattleBasicForm from './app/pages/livestock/cattle/cattleForms/CattleBasicForm';
import CattleFatteningForm from './app/pages/livestock/cattle/cattleForms/CattleFatteningForm';
import FarmBuildingsForm from './app/pages/longTermAssets/farmBuilding/farmBuildingsForm/FarmBuildingsForm';
import PlantProductionExpensesForm from './app/pages/money/expenses/plantProductionExpenses/plantForm/PlantProductionExpensesForm';
import { PlantProductionExpenses } from './app/pages/money/expenses/plantProductionExpenses/PlantProductionExpenses';
import { AnimalExpenses } from './app/pages/money/expenses/animalExpenses/AnimalExpenses';
import AnimalExpensesForm from './app/pages/money/expenses/animalExpenses/animalForm/AnimalExpensesForm';
import GoatFatteningForm from './app/pages/livestock/goats/goatsForms/GoatFatteningForm';
import GoatBasicForm from './app/pages/livestock/goats/goatsForms/GoatBasicForm';
import GoatsFormContainer from './app/pages/livestock/goats/GoatsFormContainer';
import SheepFormContainer from './app/pages/livestock/sheep/SheepFormContainer';
import SheepBasicForm from './app/pages/livestock/sheep/sheepForms/SheepBasicForm';
import SheepFatteningForm from './app/pages/livestock/sheep/sheepForms/SheepFatteningForm';
import PoultryFormContainer from './app/pages/livestock/poultry/PoultryFormContainer';
import PoultryBasicForm from './app/pages/livestock/poultry/poultryForms/PoultryBasicForm';
import PoultryFatteningForm from './app/pages/livestock/poultry/poultryForms/PoultryFatteningForm';
import OtherLivestockFormContainer from './app/pages/livestock/otherLivestock/OtherLivestockFormContainer';
import OtherLivestockBasicForm from './app/pages/livestock/otherLivestock/otherLivestockForms/OtherLivestockBasicForm';
import OtherLivestockFatteningForm from './app/pages/livestock/otherLivestock/otherLivestockForms/OtherLivestockFatteningForm';
import PigsFormContainer from './app/pages/livestock/pigs/PigsFormContainer';
import PigsBasicForm from './app/pages/livestock/pigs/pigsForms/PigsBasicForm';
import PigsFatteningForm from './app/pages/livestock/pigs/pigsForms/PigsFatteningForm';
import MachineryEquipmentForm from './app/pages/longTermAssets/machineryEquipment/machineryEnquipmentForm/MachineryEquipmentForm';
import PermanentCropsForm from './app/pages/longTermAssets/permanentCrops/permanentCropsForm/PermanentCropsForm';
import AccountActivation from './app/pages/accountActivation/AccountActivation';
import Users from './app/pages/administration/users/Users';
import Weightings from './app/pages/administration/weightings/Weightings';
import Regions from './app/pages/administration/enums/regions/Regions';
import Enums from './app/pages/administration/enums/Enums';
import Requests from './app/pages/administration/requests/Requests';
import ExplainableControls from './app/pages/explainableControls/ExplainableControls';
import ShowDeletedEntriesProvider from './contexts/ShowDeletedEntries';
import LandBuildingExpensesTypes from './app/pages/administration/enums/landBuildingExpensesTypes/LandBuildingExpensesTypes';
import ProductionPlantProducts from './app/pages/administration/enums/productionPlantProducts/ProductionPlantProducts';
import ProductionPlantProductsCategories from './app/pages/administration/enums/productionPlantProductsCategories/ProductionPlantProductsCategories';
import GrantsSubsidiesCategory from './app/pages/administration/enums/grantsSubsidiesCategory/GrantsSubsidiesCategory';
import ProductionManufacturedProducts from './app/pages/administration/enums/productionManufacturedProducts/ProductionManufacturedProducts';
import FarmTypes from './app/pages/administration/enums/farmTypes/FarmTypes';
import ProductionAnimalProducts from './app/pages/administration/enums/productionAnimalProducts/ProductionAnimalProducts';
import SoCoefficient from './app/pages/administration/enums/soCoefficient/SoCoefficient';
import UserType from './app/pages/administration/enums/standardEnums/UserType';
import Altitude from './app/pages/administration/enums/standardEnums/Altitude.jsx';
import EconomicSize from './app/pages/administration/enums/standardEnums/EconomicSize';
import LoanCategory from './app/pages/administration/enums/standardEnums/LoanCategory';
import LandCategory from './app/pages/administration/enums/standardEnums/LandCategory';
import OtherMoneyTypes from './app/pages/administration/enums/standardEnums/OtherMoneyTypes';
import CashFlowType from './app/pages/administration/enums/standardEnums/CashFlowType';
import OwnershipType from './app/pages/administration/enums/standardEnums/OwnershipType';
import OtherGainfulActivities from './app/pages/administration/enums/standardEnums/OtherGainfulActivities';
import ProtectedProductFarmType from './app/pages/administration/enums/standardEnums/ProtectedProductFarmType';
import AgriculturalEducation from './app/pages/administration/enums/standardEnums/AgriculturalEducation';
import TaxType from './app/pages/administration/enums/standardEnums/TaxType';
import GrantsPremiumType from './app/pages/administration/enums/standardEnums/GrantsPremiumType';
import AreaConstraint from './app/pages/administration/enums/standardEnums/AreaConstraint';
import WorkforceRole from './app/pages/administration/enums/standardEnums/WorkforceRole';
import WorkforceType from './app/pages/administration/enums/standardEnums/WorkforceType';
import ProductionType from './app/pages/administration/enums/standardEnums/ProductionType';
import PlantProductionType from './app/pages/administration/enums/standardEnums/PlantProductionType';
import LoanPurpose from './app/pages/administration/enums/standardEnums/LoanPurpose';
import LoanType from './app/pages/administration/enums/standardEnums/LoanType';
import LeasingType from './app/pages/administration/enums/standardEnums/LeasingType';
import ProtectedProductType from './app/pages/administration/enums/standardEnums/ProtectedProductType';
import GrantsBasicUnitsType from './app/pages/administration/enums/standardEnums/GrantsBasicUnitsType';
import GrantsFinancedType from './app/pages/administration/enums/standardEnums/GrantsFinancedType';
import GrantsType from './app/pages/administration/enums/standardEnums/GrantsType';
import Gender from './app/pages/administration/enums/standardEnums/Gender';
import ProtectedProducts from './app/pages/administration/enums/protectedProducts/ProtectedProducts';
import MechanizationType from './app/pages/administration/enums/enumsTypes/MechanizationType';
import PlantationType from './app/pages/administration/enums/enumsTypes/PlantationType';
import ObjectType from './app/pages/administration/enums/enumsTypes/ObjectType';
import CattleCategory from './app/pages/administration/enums/livestockEnums/CattleCategory';
import GoatsCategory from './app/pages/administration/enums/livestockEnums/GoatsCategory';
import SheepsCategory from './app/pages/administration/enums/livestockEnums/SheepsCategory';
import OtherLivestockCategory from './app/pages/administration/enums/livestockEnums/OtherLivestockCategory';
import PoultryCategory from './app/pages/administration/enums/livestockEnums/PoultryCategory';
import PigsCategory from './app/pages/administration/enums/livestockEnums/PigsCategory';
import MineralFertilizerType from './app/pages/administration/enums/mineralFertilizerType/MineralFertilizerType';
import OrganicSectors from './app/pages/administration/enums/frCategoryEnums/OrganicSectors';
import ProductionCode from './app/pages/administration/enums/frCategoryEnums/ProductionCode';
import ProductionOtherProduct from './app/pages/administration/enums/frCategoryEnums/ProductionOtherProduct';
import PlantExpenseType from './app/pages/administration/enums/frCategoryEnums/PlantExpenseType';
import InsuranceExpenseType from './app/pages/administration/enums/frCategoryEnums/InsuranceExpenseType';
import TaxExpenseType from './app/pages/administration/enums/frCategoryEnums/TaxExpenseType';
import LabourMachineryExpenseType from './app/pages/administration/enums/frCategoryEnums/LabourMachineryExpenseType';
import OverheadExpenseType from './app/pages/administration/enums/frCategoryEnums/OverheadExpenseType';
import LivestockExpenseType from './app/pages/administration/enums/frCategoryEnums/LivestockExpenseType';
import IrrigationType from './app/pages/administration/enums/frCategoryEnums/IrrigationType';
import ProcessingExpenseType from './app/pages/administration/enums/frCategoryEnums/ProcessingExpenseType';
import Limits from './app/pages/administration/limits/Limits';
import Revisions from './app/pages/revisions/Revisions';
import SubsidyCategory from './app/pages/administration/enums/standardEnums/SubsidyCategory';
import LockingErrors from './app/pages/home/components/lockingErrors/LockingErrors';
import LockingExplanation from './contexts/LockingExplanation';
import ResetPassword from './app/pages/resetPassword/ResetPassword';
import BackConfirmProvider from './contexts/RedirectPrompt';
import NotificationBadgeProvider from './contexts/NotificationBadge';
import WindowWidthProvider from './contexts/WindowWidth';
import WindowHeightProvider from './contexts/WindowHeight';
import { readOnly } from './utils/const';

function App() {
  let { ahid } = useParams();
  const [userData, setUserData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCollector, setIsCollector] = useState(false);
  const [isViewer, setIsViewer] = useState(false);

  const [agriculturalHoldingData, setAgriculturalHoldingData] = useState(undefined);
  const [preLockExplanation, setPreLockExplanation] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('jwt-token');

  const [isAuthorised, setIsAuthorised] = useState(false);

  setTranslations({ eng, mne });
  const lang = localStorage.getItem('language');

  if (lang === null) {
    setDefaultLanguage('mne');
  } else {
    setDefaultLanguage(lang);
  }

  const setAuthorities = (admin, collector, viewer) => {
    setIsAdmin(admin);
    setIsCollector(collector);
    setIsViewer(viewer);
  };

  const fetchUser = async () => {
    const { data } = await fetchUserApi();
    setUserData(data);
    data.authorities.includes('ROLE_ADMIN')
      ? setAuthorities(true, false, false)
      : data.authorities.includes('ROLE_COLLECTOR')
      ? setAuthorities(false, true, false)
      : data.authorities.includes('ROLE_VIEWER')
      ? setAuthorities(false, false, true)
      : setAuthorities(false, false, false);
  };

  useEffect(() => {
    if (token) {
      fetchUser().then(() => {
        setIsAuthorised(true);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [isLoading]);

  return (
    <UserData.Provider value={{ userData, isAdmin, isCollector, isViewer }}>
      <AgriculturalHoldingData.Provider
        value={{ agriculturalHoldingData, setAgriculturalHoldingData }}
      >
        <LockingExplanation.Provider value={{ preLockExplanation, setPreLockExplanation }}>
          <ShowDeletedEntriesProvider>
            <NotificationBadgeProvider>
              <BackConfirmProvider>
                <WindowWidthProvider>
                  <WindowHeightProvider>
                    {!isLoading ? (
                      <Switch>
                        <Route
                          component={ErrorPage404}
                          path="/404"
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/home"
                          exact
                          isPrivate
                          component={Home}
                          previous={[]}
                          pageTitle={t('home')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path={`/agricultural-holding/:ahid/dashboard`}
                          exact
                          isPrivate
                          component={Dashboard}
                          previous={[]}
                          authorized={isAuthorised}
                          pageTitle={t('dashboard')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path={`/agricultural-holding/:ahid/locking-errors`}
                          exact
                          isPrivate
                          component={LockingErrors}
                          previous={[]}
                          authorized={isAuthorised}
                          pageTitle={t('lockingErrors')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/labour"
                          exact
                          isPrivate
                          component={Labour}
                          previous={[]}
                          pageTitle={t('labour')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/labour/add"
                          exact
                          isPrivate
                          component={LabourForm}
                          previous={[{ title: 'labour', link: true }]}
                          pageTitle={t('labourAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/labour/:id"
                          exact
                          isPrivate
                          component={LabourForm}
                          previous={[{ title: 'labour', link: true }]}
                          pageTitle={t('labour')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/documents"
                          exact
                          isPrivate
                          component={Documents}
                          previous={[]}
                          pageTitle={t('documents')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/users"
                          exact
                          isPrivate
                          component={Users}
                          previous={[{ title: 'administration', link: true }]}
                          pageTitle={t('users')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/weightings"
                          exact
                          isPrivate
                          component={Weightings}
                          previous={[{ title: 'administration', link: true }]}
                          pageTitle={t('weightings')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/requests"
                          exact
                          isPrivate
                          component={Requests}
                          previous={[{ title: 'administration', link: true }]}
                          pageTitle={t('requests')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/ah-explainable-controls"
                          exact
                          isPrivate
                          component={ExplainableControls}
                          previous={[{ title: 'administration', link: true }]}
                          pageTitle={t('explainableControls')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums"
                          exact
                          isPrivate
                          component={Enums}
                          previous={[{ title: 'administration', link: true }]}
                          pageTitle={t('enums')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/limits"
                          exact
                          isPrivate
                          component={Limits}
                          previous={[{ title: 'administration', link: true }]}
                          pageTitle={t('limits')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/regions"
                          exact
                          isPrivate
                          component={Regions}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('regions')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/land-and-building-expenses-type"
                          exact
                          isPrivate
                          component={LandBuildingExpensesTypes}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('landBuildingExpensesTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-plant-products"
                          exact
                          isPrivate
                          component={ProductionPlantProducts}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('productionPlantProduct')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-plant-product-categories"
                          exact
                          isPrivate
                          component={ProductionPlantProductsCategories}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('productionPlantProductCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/grant-subsidy-categories"
                          exact
                          isPrivate
                          component={GrantsSubsidiesCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('grantsSubsidiesCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/subsidy-categories"
                          exact
                          isPrivate
                          component={SubsidyCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('subsidiesCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-manufactured-products"
                          exact
                          isPrivate
                          component={ProductionManufacturedProducts}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('manufacturedProducts')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/farm-types"
                          exact
                          isPrivate
                          component={FarmTypes}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('farmTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-animal-products"
                          exact
                          isPrivate
                          component={ProductionAnimalProducts}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('animalProducts')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/so-coefficients"
                          exact
                          isPrivate
                          component={SoCoefficient}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('soCoefficient')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/protected-products"
                          exact
                          isPrivate
                          component={ProtectedProducts}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('protectedProducts')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        {/*               Standard Enums            */}
                        <Route
                          path="/administration/enums/user-types"
                          exact
                          isPrivate
                          component={UserType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('userTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/altitudes"
                          exact
                          isPrivate
                          component={Altitude}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('altitudes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/economic-sizes"
                          exact
                          isPrivate
                          component={EconomicSize}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('economicSizes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/loan-categories"
                          exact
                          isPrivate
                          component={LoanCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('loanCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/land-categories"
                          exact
                          isPrivate
                          component={LandCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('landCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/other-money-types"
                          exact
                          isPrivate
                          component={OtherMoneyTypes}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('otherMoneyTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/cash-flow-types"
                          exact
                          isPrivate
                          component={CashFlowType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('cashFlowTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/ownership-types"
                          exact
                          isPrivate
                          component={OwnershipType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('ownershipTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/other-gainful-activities"
                          exact
                          isPrivate
                          component={OtherGainfulActivities}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('otherGainfulActivities')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/protected-product-farm-types"
                          exact
                          isPrivate
                          component={ProtectedProductFarmType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('protectedProductFarmTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/agricultural-educations"
                          exact
                          isPrivate
                          component={AgriculturalEducation}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('agriculturalEducations')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/tax-types"
                          exact
                          isPrivate
                          component={TaxType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('taxTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/grant-premium-types"
                          exact
                          isPrivate
                          component={GrantsPremiumType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('grantsPremiumTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/genders"
                          exact
                          isPrivate
                          component={Gender}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('genders')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/area-constraint"
                          exact
                          isPrivate
                          component={AreaConstraint}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('areaConstraints')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/workforce-roles"
                          exact
                          isPrivate
                          component={WorkforceRole}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('workforceRoles')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/workforce-types"
                          exact
                          isPrivate
                          component={WorkforceType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('workforceTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-types"
                          exact
                          isPrivate
                          component={ProductionType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('productionTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/plant-production-types"
                          exact
                          isPrivate
                          component={PlantProductionType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('plantProductionTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/loan-purposes"
                          exact
                          isPrivate
                          component={LoanPurpose}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('loanPurposes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/loan-types"
                          exact
                          isPrivate
                          component={LoanType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('loanTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/leasing-types"
                          exact
                          isPrivate
                          component={LeasingType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('leasingTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/protected-product-types"
                          exact
                          isPrivate
                          component={ProtectedProductType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('protectedProductTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/grant-basic-unit-types"
                          exact
                          isPrivate
                          component={GrantsBasicUnitsType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('grantsBasicUnitsTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/grant-financed-types"
                          exact
                          isPrivate
                          component={GrantsFinancedType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('grantsFinancedTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/grant-types"
                          exact
                          isPrivate
                          component={GrantsType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('grantsTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />

                        {/*               Standard Enums            */}
                        <Route
                          path="/administration/agricultural-holdings"
                          exact
                          isPrivate
                          component={AgriculturalHolding}
                          previous={[{ title: 'administration', link: true }]}
                          pageTitle={t('agriculturalHoldings')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/agricultural-holdings/add"
                          exact
                          isPrivate
                          component={AgriculturalHoldingForm}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'agriculturalHoldings', link: true },
                          ]}
                          pageTitle={t('agroHoldingAdd')}
                          authorized={isAuthorised && isAdmin && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/agricultural-holdings/:id"
                          exact
                          isPrivate
                          component={AgriculturalHoldingForm}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'agriculturalHoldings', link: true },
                          ]}
                          pageTitle={t('agroHoldingEdit')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holdings-collector/:id"
                          exact
                          isPrivate
                          component={AgriculturalHoldingForm}
                          previous={[]}
                          pageTitle={t('agroHoldingEdit')}
                          authorized={isAuthorised && isCollector}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holdings-viewer/:id"
                          exact
                          isPrivate
                          component={AgriculturalHoldingForm}
                          previous={[]}
                          pageTitle={t('agroHoldingView')}
                          authorized={isAuthorised && isViewer}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle"
                          exact
                          isPrivate
                          component={CattleFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('cattle')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle/add"
                          exact
                          isPrivate
                          component={CattleBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'cattle', link: true },
                          ]}
                          pageTitle={t('cattleOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle/:id"
                          exact
                          isPrivate
                          component={CattleBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'cattle', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('cattleOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle-fattening/add"
                          exact
                          isPrivate
                          component={CattleFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'cattle', link: true },
                          ]}
                          pageTitle={t('cattleFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle-fattening/:id"
                          exact
                          isPrivate
                          component={CattleFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'cattle', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('cattleFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs"
                          exact
                          isPrivate
                          component={PigsFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('pigs')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs/add"
                          exact
                          isPrivate
                          component={PigsBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'pigs', link: true },
                          ]}
                          pageTitle={t('pigsOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs/:id"
                          exact
                          isPrivate
                          component={PigsBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'pigs', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('pigsOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs-fattening/add"
                          exact
                          isPrivate
                          component={PigsFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'pigs', link: true },
                          ]}
                          pageTitle={t('pigsFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs-fattening/:id"
                          exact
                          isPrivate
                          component={PigsFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'pigs', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('pigsFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats"
                          exact
                          isPrivate
                          component={GoatsFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('goats')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats/add"
                          exact
                          isPrivate
                          component={GoatBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'goats', link: true },
                          ]}
                          pageTitle={t('goatOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats/:id"
                          exact
                          isPrivate
                          component={GoatBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'goats', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('goatOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats-fattening/add"
                          exact
                          isPrivate
                          component={GoatFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'goats', link: true },
                          ]}
                          pageTitle={t('goatFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats-fattening/:id"
                          exact
                          isPrivate
                          component={GoatFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'goats', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('goatFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry"
                          exact
                          isPrivate
                          component={PoultryFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('poultry')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry/add"
                          exact
                          isPrivate
                          component={PoultryBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'poultry', link: true },
                          ]}
                          pageTitle={t('poultryOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry/:id"
                          exact
                          isPrivate
                          component={PoultryBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'poultry', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('poultryOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry-fattening/add"
                          exact
                          isPrivate
                          component={PoultryFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'poultry', link: true },
                          ]}
                          pageTitle={t('poultryFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry-fattening/:id"
                          exact
                          isPrivate
                          component={PoultryFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'poultry', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('poultryFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep"
                          exact
                          isPrivate
                          component={SheepFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('sheep')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep/add"
                          exact
                          isPrivate
                          component={SheepBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'sheep', link: true },
                          ]}
                          pageTitle={t('sheepOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep/:id"
                          exact
                          isPrivate
                          component={SheepBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'sheep', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('sheepOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep-fattening/add"
                          exact
                          isPrivate
                          component={SheepFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'sheep', link: true },
                          ]}
                          pageTitle={t('sheepFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep-fattening/:id"
                          exact
                          isPrivate
                          component={SheepFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'sheep', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('sheepFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock"
                          exact
                          isPrivate
                          component={OtherLivestockFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('otherLivestock')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock/add"
                          exact
                          isPrivate
                          component={OtherLivestockBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'otherLivestock', link: true },
                          ]}
                          pageTitle={t('otherOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock/:id"
                          exact
                          isPrivate
                          component={OtherLivestockBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'otherLivestock', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('otherOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock-fattening/add"
                          exact
                          isPrivate
                          component={OtherLivestockFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'otherLivestock', link: true },
                          ]}
                          pageTitle={t('otherFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock-fattening/:id"
                          exact
                          isPrivate
                          component={OtherLivestockFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'otherLivestock', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('otherFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/permanent-crops"
                          exact
                          isPrivate
                          component={PermanentCrops}
                          previous={[{ title: 'longTermAssets', link: false }]}
                          pageTitle={t('permanentCrops')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/permanent-crops/add"
                          exact
                          isPrivate
                          component={PermanentCropsForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'permanentCrops', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('permanentCropsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/permanent-crops/:id"
                          exact
                          isPrivate
                          component={PermanentCropsForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'permanentCrops', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('permanentCropsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/machinery-and-equipment"
                          exact
                          isPrivate
                          component={MachineryEquipment}
                          previous={[{ title: 'longTermAssets', link: false }]}
                          pageTitle={t('machineryAndEquipment')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/machinery-and-equipment/add"
                          exact
                          isPrivate
                          component={MachineryEquipmentForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'machineryAndEquipment', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('machineryAndEquipmentAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/machinery-and-equipment/:id"
                          exact
                          isPrivate
                          component={MachineryEquipmentForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'machineryAndEquipment', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('machineryAndEquipmentEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/farm-buildings"
                          exact
                          isPrivate
                          component={FarmBuildings}
                          previous={[{ title: 'longTermAssets', link: false }]}
                          pageTitle={t('farmBuildings')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/farm-buildings/add"
                          exact
                          isPrivate
                          component={FarmBuildingsForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'farmBuildings', link: true },
                          ]}
                          pageTitle={t('farmBuildingsAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/farm-buildings/:id"
                          exact
                          isPrivate
                          component={FarmBuildingsForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'farmBuildings', link: true },
                          ]}
                          pageTitle={t('farmBuildingsEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/agricultural-lands"
                          exact
                          isPrivate
                          component={AgriculturalLand}
                          previous={[{ title: 'longTermAssets', link: false }]}
                          authorized={isAuthorised}
                          pageTitle={t('agriculturalLands')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/agricultural-lands/add"
                          exact
                          isPrivate
                          component={AgriculturalLandForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'agriculturalLands', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector)}
                          pageTitle={t('agriculturalLandAdd') && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/agricultural-lands/:id"
                          exact
                          isPrivate
                          component={AgriculturalLandForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'agriculturalLands', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('agriculturalLand')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/animal-products"
                          exact
                          isPrivate
                          component={AnimalProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('animalProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/animal-products/add"
                          exact
                          isPrivate
                          component={AnimalProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'animalProducts', link: true },
                          ]}
                          pageTitle={t('animalProductsAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/animal-products/:id"
                          exact
                          isPrivate
                          component={AnimalProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'animalProducts', link: true },
                          ]}
                          pageTitle={t('animalProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/manufactured-products"
                          exact
                          isPrivate
                          component={ManufacturedProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('manufacturedProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/manufactured-products/add"
                          exact
                          isPrivate
                          component={ManufacturedProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'manufacturedProducts', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('manufacturedProductsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/manufactured-products/:id"
                          exact
                          isPrivate
                          component={ManufacturedProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'manufacturedProducts', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('manufacturedProductsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/unfinished-products"
                          exact
                          isPrivate
                          component={UnfinishedProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('unfinishedProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/unfinished-products/add"
                          exact
                          isPrivate
                          component={UnfinishedProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'unfinishedProducts', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('unfinishedProductsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/unfinished-products/:id"
                          exact
                          isPrivate
                          component={UnfinishedProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'unfinishedProducts', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('unfinishedProductsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/plant-products"
                          exact
                          isPrivate
                          component={PlantProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('plantProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/plant-products/add"
                          exact
                          isPrivate
                          component={PlantProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'plantProducts', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('plantProductsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/plant-products/:id"
                          exact
                          isPrivate
                          component={PlantProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'plantProducts', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('plantProductsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/other-products"
                          exact
                          isPrivate
                          component={OtherProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('otherProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/other-products/add"
                          exact
                          isPrivate
                          component={OtherProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'otherProducts', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('otherProductsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/other-products/:id"
                          exact
                          isPrivate
                          component={OtherProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'plantProducts', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('otherProductsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses"
                          exact
                          isPrivate
                          component={Expenses}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('expenses')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/plant-production-expenses"
                          exact
                          isPrivate
                          component={PlantProductionExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('plantProduction')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/plant-production-expenses/add"
                          exact
                          isPrivate
                          component={PlantProductionExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'plantProduction', link: true },
                          ]}
                          pageTitle={t('plantProductionAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/plant-production-expenses/:id"
                          exact
                          isPrivate
                          component={PlantProductionExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'plantProduction', link: true },
                          ]}
                          pageTitle={t('plantProductionEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/animal-production-expenses"
                          exact
                          isPrivate
                          component={AnimalExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('animalProduction')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/animal-production-expenses/add"
                          exact
                          isPrivate
                          component={AnimalExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'animalProduction', link: true },
                          ]}
                          pageTitle={t('animalProductionAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/animal-production-expenses/:id"
                          exact
                          isPrivate
                          component={AnimalExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'animalProduction', link: true },
                          ]}
                          pageTitle={t('animalProductionEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/manufacturer-processing"
                          exact
                          isPrivate
                          component={ProcessingExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('manufacturerProcessing')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/manufacturer-processing/add"
                          exact
                          isPrivate
                          component={ProcessingExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'manufacturerProcessing', link: true },
                          ]}
                          pageTitle={t('manufacturerProcessingAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/manufacturer-processing/:id"
                          exact
                          isPrivate
                          component={ProcessingExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'manufacturerProcessing', link: true },
                          ]}
                          pageTitle={t('manufacturerProcessingEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/labour-and-machinery"
                          exact
                          isPrivate
                          component={LabourExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('labourMachinery')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/labour-and-machinery/add"
                          exact
                          isPrivate
                          component={LabourExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'labourMachinery', link: true },
                          ]}
                          pageTitle={t('labourMachineryAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/labour-and-machinery/:id"
                          exact
                          isPrivate
                          component={LabourExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'labourMachinery', link: true },
                          ]}
                          pageTitle={t('labourMachineryEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/land-and-building"
                          exact
                          isPrivate
                          component={LandAndBuildingExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('landBuilding')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/land-and-building/add"
                          exact
                          isPrivate
                          component={LandAndBuildingForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'landBuilding', link: true },
                          ]}
                          pageTitle={t('landBuildingAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/land-and-building/:id"
                          exact
                          isPrivate
                          component={LandAndBuildingForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'landBuilding', link: true },
                          ]}
                          pageTitle={t('landBuildingEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/overhead"
                          exact
                          isPrivate
                          component={OverheadExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('overhead')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/overhead/add"
                          exact
                          isPrivate
                          component={OverheadExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'overhead', link: true },
                          ]}
                          pageTitle={t('overheadAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/overhead/:id"
                          exact
                          isPrivate
                          component={OverheadExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'overhead', link: true },
                          ]}
                          pageTitle={t('overheadEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/insurance"
                          exact
                          isPrivate
                          component={InsuranceExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('insurance')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/insurance/add"
                          exact
                          isPrivate
                          component={InsuranceExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'insurance', link: true },
                          ]}
                          pageTitle={t('insuranceAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/insurance/:id"
                          exact
                          isPrivate
                          component={InsuranceExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'insurance', link: true },
                          ]}
                          pageTitle={t('insuranceEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/tax"
                          exact
                          isPrivate
                          component={TaxExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('tax')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/tax/add"
                          exact
                          isPrivate
                          component={TaxForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'tax', link: true },
                          ]}
                          pageTitle={t('taxAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/tax/:id"
                          exact
                          isPrivate
                          component={TaxForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'tax', link: true },
                          ]}
                          pageTitle={t('taxEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/grants"
                          exact
                          isPrivate
                          component={Grants}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('grants')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        {/*<Route*/}
                        {/*  path="/agricultural-holding/:ahid/money/grants/premiums"*/}
                        {/*  exact*/}
                        {/*  isPrivate*/}
                        {/*  component={Premiums}*/}
                        {/*  previous={[]}*/}
                        {/*  pageTitle={t('premiums')}*/}
                        {/*  authorized={true}*/}
                        {/*  setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*  path="/agricultural-holding/:ahid/money/grants/premiums/add"*/}
                        {/*  exact*/}
                        {/*  isPrivate*/}
                        {/*  component={PremiumForm}*/}
                        {/*  previous={[]}*/}
                        {/*  pageTitle={t('premiumsAdd')}*/}
                        {/*  authorized={true}*/}
                        {/*  setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*  path="/agricultural-holding/:ahid/money/grants/premiums/:id"*/}
                        {/*  exact*/}
                        {/*  isPrivate*/}
                        {/*  component={PremiumForm}*/}
                        {/*  previous={[]}*/}
                        {/*  pageTitle={t('premiumsEdit')}*/}
                        {/*  authorized={true}*/}
                        {/*  setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        <Route
                          path="/agricultural-holding/:ahid/money/subsidies"
                          exact
                          isPrivate
                          component={Subsidies}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('subsidies')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/subsidies/add"
                          exact
                          isPrivate
                          component={SubsidyForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'subsidies', link: true },
                          ]}
                          pageTitle={t('subsidiesAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/subsidies/:id"
                          exact
                          isPrivate
                          component={SubsidyForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'subsidies', link: true },
                          ]}
                          pageTitle={t('subsidiesEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans"
                          exact
                          isPrivate
                          component={Loans}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('loans')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/loan"
                          exact
                          isPrivate
                          component={Loan}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                          ]}
                          pageTitle={t('loans')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/loan/add"
                          exact
                          isPrivate
                          component={LoanForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                            { title: 'loans', link: true },
                          ]}
                          pageTitle={t('loansAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/loan/:id"
                          exact
                          isPrivate
                          component={LoanForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                            { title: 'loans', link: true },
                          ]}
                          pageTitle={t('loansEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/leasing"
                          exact
                          isPrivate
                          component={Leasing}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                          ]}
                          pageTitle={t('leasing')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/leasing/add"
                          exact
                          isPrivate
                          component={LeasingForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                            { title: 'leasing', link: true },
                          ]}
                          pageTitle={t('leasingAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/leasing/:id"
                          exact
                          isPrivate
                          component={LeasingForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                            { title: 'leasing', link: true },
                          ]}
                          pageTitle={t('leasingEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/money-flow"
                          exact
                          isPrivate
                          component={MoneyFlow}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('moneyFlow')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/money-flow/add"
                          exact
                          isPrivate
                          component={MoneyFlowForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'moneyFlow', link: true },
                          ]}
                          pageTitle={t('moneyFlowAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/money-flow/:id"
                          exact
                          isPrivate
                          component={MoneyFlowForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'moneyFlow', link: true },
                          ]}
                          pageTitle={t('moneyFlowEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/other-income"
                          exact
                          isPrivate
                          component={OtherIncome}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('otherIncome')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/other-income/add"
                          exact
                          isPrivate
                          component={OtherIncomeForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'otherIncome', link: true },
                          ]}
                          pageTitle={t('otherIncomeAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/other-income/:id"
                          exact
                          isPrivate
                          component={OtherIncomeForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'otherIncome', link: true },
                          ]}
                          pageTitle={t('otherIncomeEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration"
                          exact
                          isPrivate
                          component={Administration}
                          previous={[]}
                          pageTitle={t('administration')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/reports"
                          exact
                          isPrivate
                          component={Reports}
                          previous={[]}
                          pageTitle={t('reports')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                          hasBackButton={true}
                          backLink="/administration/agricultural-holdings"
                        />
                        <Route
                          path="/forms"
                          exact
                          isPrivate
                          component={Forms}
                          previous={[]}
                          pageTitle={t('forms')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/account/activate"
                          exact
                          component={AccountActivation}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/account/reset/finish"
                          exact
                          component={ResetPassword}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/register"
                          exact
                          component={Register}
                          previous={[]}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/login"
                          exact
                          component={Login}
                          previous={[]}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/"
                          exact
                          component={Login}
                          previous={[]}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        {/*===============================================*/}
                        {/*============      TEST PAGES     ==============*/}
                        {/*===============================================*/}
                        {/*<Route*/}
                        {/*  path="/search"*/}
                        {/*  exact*/}
                        {/*  isPrivate*/}
                        {/*  component={SearchData}*/}
                        {/*  previous={[]}*/}
                        {/*  pageTitle={'Search'}*/}
                        {/*  authorized={true}*/}
                        {/*  setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        {/* <Route
                        <Route
                          path="/home"
                          exact
                          isPrivate
                          component={Home}
                          previous={[]}
                          pageTitle={t('home')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path={`/agricultural-holding/:ahid/dashboard`}
                          exact
                          isPrivate
                          component={Dashboard}
                          previous={[]}
                          authorized={true}
                          pageTitle={t('dashboard')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/labour"
                          exact
                          isPrivate
                          component={Labour}
                          previous={[]}
                          pageTitle={t('labour')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/labour/add"
                          exact
                          isPrivate
                          component={LabourForm}
                          previous={[{title: 'labour', link: true}]}
                          pageTitle={t('labourAdd')}
                          authorized={token ? isAdmin || isCollector : false}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/labour/:id"
                          exact
                          isPrivate
                          component={LabourForm}
                          previous={[{title: 'labour', link: true}]}
                          pageTitle={t('labour')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/documents"
                          exact
                          isPrivate
                          component={Documents}
                          previous={[]}
                          pageTitle={t('documents')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/users"
                          exact
                          isPrivate
                          component={Users}
                          previous={[{title: 'administration', link: true}]}
                          pageTitle={t('users')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/weightings"
                          exact
                          isPrivate
                          component={Weightings}
                          previous={[{title: 'administration', link: true}]}
                          pageTitle={t('weightings')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/requests"
                          exact
                          isPrivate
                          component={Requests}
                          previous={[]}
                          pageTitle={t('requests')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/ah-explainable-controls"
                          exact
                          isPrivate
                          component={ExplainableControls}
                          previous={[]}
                          pageTitle={t('explainableControls')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums"
                          exact
                          isPrivate
                          component={Enums}
                          previous={[{title: 'administration', link: true}]}
                          pageTitle={t('enums')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/regions"
                          exact
                          isPrivate
                          component={Regions}
                          previous={[
                            {title: 'administration', link: true},
                            {title: 'enums', link: true},
                          ]}
                          pageTitle={t('regions')}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        {/*     FR Category Enums*/}
                        <Route
                          path="/administration/enums/organic-sectors"
                          exact
                          isPrivate
                          component={OrganicSectors}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('organicSectors')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-codes"
                          exact
                          isPrivate
                          component={ProductionCode}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('productionCodes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-other-products"
                          exact
                          isPrivate
                          component={ProductionOtherProduct}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('productionOtherProducts')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/plant-expense-types"
                          exact
                          isPrivate
                          component={PlantExpenseType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('plantExpenseTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/insurance-expense-types"
                          exact
                          isPrivate
                          component={InsuranceExpenseType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('insuranceExpenseTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/tax-expense-types"
                          exact
                          isPrivate
                          component={TaxExpenseType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('taxExpenseTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/labour-machinery-expense-types"
                          exact
                          isPrivate
                          component={LabourMachineryExpenseType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('labourMachineryExpenseTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/overhead-expense-types"
                          exact
                          isPrivate
                          component={OverheadExpenseType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('overheadExpenseTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/livestock-expense-types"
                          exact
                          isPrivate
                          component={LivestockExpenseType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('livestockExpenseTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/irrigation-types"
                          exact
                          isPrivate
                          component={IrrigationType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('irrigationTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/processing-expense-types"
                          exact
                          isPrivate
                          component={ProcessingExpenseType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('processingExpenseTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />

                        {/*     FR Category Enums*/}
                        <Route
                          path="/administration/enums/land-and-building-expenses-type"
                          exact
                          isPrivate
                          component={LandBuildingExpensesTypes}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('landBuildingExpensesTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-plant-products"
                          exact
                          isPrivate
                          component={ProductionPlantProducts}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('productionPlantProduct')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-plant-product-categories"
                          exact
                          isPrivate
                          component={ProductionPlantProductsCategories}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('productionPlantProductCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/grant-subsidy-categories"
                          exact
                          isPrivate
                          component={GrantsSubsidiesCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('grantsSubsidiesCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-manufactured-products"
                          exact
                          isPrivate
                          component={ProductionManufacturedProducts}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('manufacturedProducts')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/farm-types"
                          exact
                          isPrivate
                          component={FarmTypes}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('farmTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/production-animal-products"
                          exact
                          isPrivate
                          component={ProductionAnimalProducts}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('animalProducts')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/so-coefficients"
                          exact
                          isPrivate
                          component={SoCoefficient}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('soCoefficient')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        {/*              Livestock Enums       */}
                        <Route
                          path="/administration/enums/cattle-categories"
                          exact
                          isPrivate
                          component={CattleCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('cattleCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/goat-categories"
                          exact
                          isPrivate
                          component={GoatsCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('goatsCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/sheep-categories"
                          exact
                          isPrivate
                          component={SheepsCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('sheepCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/other-livestock-categories"
                          exact
                          isPrivate
                          component={OtherLivestockCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('otherLivestockCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/poultry-categories"
                          exact
                          isPrivate
                          component={PoultryCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('poultryCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/pig-categories"
                          exact
                          isPrivate
                          component={PigsCategory}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('pigsCategories')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        {/*              Livestock Enums       */}
                        <Route
                          path="/administration/enums/mineral-fertilizer-types"
                          exact
                          isPrivate
                          component={MineralFertilizerType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('mineralFertilizerTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/mechanization-types"
                          exact
                          isPrivate
                          component={MechanizationType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('mechanizationTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/plantation-types"
                          exact
                          isPrivate
                          component={PlantationType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('plantationTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/enums/object-types"
                          exact
                          isPrivate
                          component={ObjectType}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'enums', link: true },
                          ]}
                          pageTitle={t('objectTypes')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/agricultural-holdings"
                          exact
                          isPrivate
                          component={AgriculturalHolding}
                          previous={[{ title: 'administration', link: true }]}
                          pageTitle={t('agriculturalHoldings')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration/agricultural-holdings/add"
                          exact
                          isPrivate
                          component={AgriculturalHoldingForm}
                          previous={[
                            { title: 'administration', link: true },
                            { title: 'agriculturalHoldings', link: true },
                          ]}
                          pageTitle={t('agroHoldingAdd')}
                          authorized={isAuthorised && isAdmin && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle"
                          exact
                          isPrivate
                          component={CattleFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('cattle')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle/add"
                          exact
                          isPrivate
                          component={CattleBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'cattle', link: true },
                          ]}
                          pageTitle={t('cattleOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle/:id"
                          exact
                          isPrivate
                          component={CattleBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'cattle', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('cattleOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle-fattening/add"
                          exact
                          isPrivate
                          component={CattleFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'cattle', link: true },
                          ]}
                          pageTitle={t('cattleFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/cattle-fattening/:id"
                          exact
                          isPrivate
                          component={CattleFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'cattle', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('cattleFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs"
                          exact
                          isPrivate
                          component={PigsFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('pigs')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs/add"
                          exact
                          isPrivate
                          component={PigsBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'pigs', link: true },
                          ]}
                          pageTitle={t('pigsOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs/:id"
                          exact
                          isPrivate
                          component={PigsBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'pigs', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('pigsOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs-fattening/add"
                          exact
                          isPrivate
                          component={PigsFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'pigs', link: true },
                          ]}
                          pageTitle={t('pigsFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/pigs-fattening/:id"
                          exact
                          isPrivate
                          component={PigsFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'pigs', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('pigsFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats"
                          exact
                          isPrivate
                          component={GoatsFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('goats')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats/add"
                          exact
                          isPrivate
                          component={GoatBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'goats', link: true },
                          ]}
                          pageTitle={t('goatOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats/:id"
                          exact
                          isPrivate
                          component={GoatBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'goats', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('goatOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats-fattening/add"
                          exact
                          isPrivate
                          component={GoatFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'goats', link: true },
                          ]}
                          pageTitle={t('goatFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/goats-fattening/:id"
                          exact
                          isPrivate
                          component={GoatFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'goats', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('goatFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry"
                          exact
                          isPrivate
                          component={PoultryFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('poultry')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry/add"
                          exact
                          isPrivate
                          component={PoultryBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'poultry', link: true },
                          ]}
                          pageTitle={t('poultryOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry/:id"
                          exact
                          isPrivate
                          component={PoultryBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'poultry', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('poultryOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry-fattening/add"
                          exact
                          isPrivate
                          component={PoultryFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'poultry', link: true },
                          ]}
                          pageTitle={t('poultryFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/poultry-fattening/:id"
                          exact
                          isPrivate
                          component={PoultryFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'poultry', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('poultryFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep"
                          exact
                          isPrivate
                          component={SheepFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('sheep')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep/add"
                          exact
                          isPrivate
                          component={SheepBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'sheep', link: true },
                          ]}
                          pageTitle={t('sheepOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep/:id"
                          exact
                          isPrivate
                          component={SheepBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'sheep', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('sheepOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep-fattening/add"
                          exact
                          isPrivate
                          component={SheepFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'sheep', link: true },
                          ]}
                          pageTitle={t('sheepFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/sheep-fattening/:id"
                          exact
                          isPrivate
                          component={SheepFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'sheep', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('sheepFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock"
                          exact
                          isPrivate
                          component={OtherLivestockFormContainer}
                          previous={[{ title: 'livestock', link: false }]}
                          pageTitle={t('otherLivestock')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock/add"
                          exact
                          isPrivate
                          component={OtherLivestockBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'otherLivestock', link: true },
                          ]}
                          pageTitle={t('otherOwnedAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock/:id"
                          exact
                          isPrivate
                          component={OtherLivestockBasicForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'otherLivestock', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('otherOwnedEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock-fattening/add"
                          exact
                          isPrivate
                          component={OtherLivestockFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'otherLivestock', link: true },
                          ]}
                          pageTitle={t('otherFatteningAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/livestock/other-livestock-fattening/:id"
                          exact
                          isPrivate
                          component={OtherLivestockFatteningForm}
                          previous={[
                            { title: 'livestock', link: false },
                            { title: 'otherLivestock', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('otherFatteningEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/permanent-crops"
                          exact
                          isPrivate
                          component={PermanentCrops}
                          previous={[{ title: 'longTermAssets', link: false }]}
                          pageTitle={t('permanentCrops')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/permanent-crops/add"
                          exact
                          isPrivate
                          component={PermanentCropsForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'permanentCrops', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('permanentCropsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/permanent-crops/:id"
                          exact
                          isPrivate
                          component={PermanentCropsForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'permanentCrops', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('permanentCropsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/machinery-and-equipment"
                          exact
                          isPrivate
                          component={MachineryEquipment}
                          previous={[{ title: 'longTermAssets', link: false }]}
                          pageTitle={t('machineryAndEquipment')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/machinery-and-equipment/add"
                          exact
                          isPrivate
                          component={MachineryEquipmentForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'machineryAndEquipment', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('machineryAndEquipmentAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/machinery-and-equipment/:id"
                          exact
                          isPrivate
                          component={MachineryEquipmentForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'machineryAndEquipment', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('machineryAndEquipmentEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/farm-buildings"
                          exact
                          isPrivate
                          component={FarmBuildings}
                          previous={[{ title: 'longTermAssets', link: false }]}
                          pageTitle={t('farmBuildings')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/farm-buildings/add"
                          exact
                          isPrivate
                          component={FarmBuildingsForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'farmBuildings', link: true },
                          ]}
                          pageTitle={t('farmBuildingsAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/farm-buildings/:id"
                          exact
                          isPrivate
                          component={FarmBuildingsForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'farmBuildings', link: true },
                          ]}
                          pageTitle={t('farmBuildingsEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/agricultural-lands"
                          exact
                          isPrivate
                          component={AgriculturalLand}
                          previous={[{ title: 'longTermAssets', link: false }]}
                          authorized={isAuthorised}
                          pageTitle={t('agriculturalLands')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/agricultural-lands/add"
                          exact
                          isPrivate
                          component={AgriculturalLandForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'agriculturalLands', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('agriculturalLandAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/long-term-assets/agricultural-lands/:id"
                          exact
                          isPrivate
                          component={AgriculturalLandForm}
                          previous={[
                            { title: 'longTermAssets', link: false },
                            { title: 'agriculturalLands', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('agriculturalLand')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/animal-products"
                          exact
                          isPrivate
                          component={AnimalProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('animalProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/animal-products/add"
                          exact
                          isPrivate
                          component={AnimalProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'animalProducts', link: true },
                          ]}
                          pageTitle={t('animalProductsAdd') && !readOnly}
                          authorized={isAuthorised && (isAdmin || isCollector)}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/animal-products/:id"
                          exact
                          isPrivate
                          component={AnimalProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'animalProducts', link: true },
                          ]}
                          pageTitle={t('animalProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/manufactured-products"
                          exact
                          isPrivate
                          component={ManufacturedProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('manufacturedProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/manufactured-products/add"
                          exact
                          isPrivate
                          component={ManufacturedProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'manufacturedProducts', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('manufacturedProductsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/manufactured-products/:id"
                          exact
                          isPrivate
                          component={ManufacturedProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'manufacturedProducts', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('manufacturedProductsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/unfinished-products"
                          exact
                          isPrivate
                          component={UnfinishedProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('unfinishedProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/unfinished-products/add"
                          exact
                          isPrivate
                          component={UnfinishedProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'unfinishedProducts', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('unfinishedProductsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/unfinished-products/:id"
                          exact
                          isPrivate
                          component={UnfinishedProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'unfinishedProducts', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('unfinishedProductsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/plant-products"
                          exact
                          isPrivate
                          component={PlantProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('plantProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/plant-products/add"
                          exact
                          isPrivate
                          component={PlantProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'plantProducts', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('plantProductsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/plant-products/:id"
                          exact
                          isPrivate
                          component={PlantProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'plantProducts', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('plantProductsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/other-products"
                          exact
                          isPrivate
                          component={OtherProducts}
                          previous={[{ title: 'production', link: false }]}
                          pageTitle={t('otherProducts')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/other-products/add"
                          exact
                          isPrivate
                          component={OtherProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'otherProducts', link: true },
                          ]}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          pageTitle={t('otherProductsAdd')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/production/other-products/:id"
                          exact
                          isPrivate
                          component={OtherProductsForm}
                          previous={[
                            { title: 'production', link: false },
                            { title: 'plantProducts', link: true },
                          ]}
                          authorized={isAuthorised}
                          pageTitle={t('otherProductsEdit')}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses"
                          exact
                          isPrivate
                          component={Expenses}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('expenses')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/plant-production-expenses"
                          exact
                          isPrivate
                          component={PlantProductionExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('plantProduction')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/plant-production-expenses/add"
                          exact
                          isPrivate
                          component={PlantProductionExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'plantProduction', link: true },
                          ]}
                          pageTitle={t('plantProductionAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/plant-production-expenses/:id"
                          exact
                          isPrivate
                          component={PlantProductionExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'plantProduction', link: true },
                          ]}
                          pageTitle={t('plantProductionEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/animal-production-expenses"
                          exact
                          isPrivate
                          component={AnimalExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('animalProduction')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/animal-production-expenses/add"
                          exact
                          isPrivate
                          component={AnimalExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'animalProduction', link: true },
                          ]}
                          pageTitle={t('animalProductionAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/animal-production-expenses/:id"
                          exact
                          isPrivate
                          component={AnimalExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'animalProduction', link: true },
                          ]}
                          pageTitle={t('animalProductionEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/manufacturer-processing"
                          exact
                          isPrivate
                          component={ProcessingExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('manufacturerProcessing')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/manufacturer-processing/add"
                          exact
                          isPrivate
                          component={ProcessingExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'manufacturerProcessing', link: true },
                          ]}
                          pageTitle={t('manufacturerProcessingAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/manufacturer-processing/:id"
                          exact
                          isPrivate
                          component={ProcessingExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'manufacturerProcessing', link: true },
                          ]}
                          pageTitle={t('manufacturerProcessingEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/labour-and-machinery"
                          exact
                          isPrivate
                          component={LabourExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('labourMachinery')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/labour-and-machinery/add"
                          exact
                          isPrivate
                          component={LabourExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'labourMachinery', link: true },
                          ]}
                          pageTitle={t('labourMachineryAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/labour-and-machinery/:id"
                          exact
                          isPrivate
                          component={LabourExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'labourMachinery', link: true },
                          ]}
                          pageTitle={t('labourMachineryEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/land-and-building"
                          exact
                          isPrivate
                          component={LandAndBuildingExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('landBuilding')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/land-and-building/add"
                          exact
                          isPrivate
                          component={LandAndBuildingForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'landBuilding', link: true },
                          ]}
                          pageTitle={t('landBuildingAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/land-and-building/:id"
                          exact
                          isPrivate
                          component={LandAndBuildingForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'landBuilding', link: true },
                          ]}
                          pageTitle={t('landBuildingEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/overhead"
                          exact
                          isPrivate
                          component={OverheadExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('overhead')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/overhead/add"
                          exact
                          isPrivate
                          component={OverheadExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'overhead', link: true },
                          ]}
                          pageTitle={t('overheadAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/overhead/:id"
                          exact
                          isPrivate
                          component={OverheadExpenseForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'overhead', link: true },
                          ]}
                          pageTitle={t('overheadEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/insurance"
                          exact
                          isPrivate
                          component={InsuranceExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('insurance')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/insurance/add"
                          exact
                          isPrivate
                          component={InsuranceExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'insurance', link: true },
                          ]}
                          pageTitle={t('insuranceAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/insurance/:id"
                          exact
                          isPrivate
                          component={InsuranceExpensesForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'insurance', link: true },
                          ]}
                          pageTitle={t('insuranceEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/tax"
                          exact
                          isPrivate
                          component={TaxExpenses}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                          ]}
                          pageTitle={t('tax')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/tax/add"
                          exact
                          isPrivate
                          component={TaxForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'tax', link: true },
                          ]}
                          pageTitle={t('taxAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/expenses/tax/:id"
                          exact
                          isPrivate
                          component={TaxForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'expenses', link: true },
                            { title: 'tax', link: true },
                          ]}
                          pageTitle={t('taxEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/grants"
                          exact
                          isPrivate
                          component={Grants}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('grants')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        {/*<Route*/}
                        {/*  path="/agricultural-holding/:ahid/money/grants/premiums"*/}
                        {/*  exact*/}
                        {/*  isPrivate*/}
                        {/*  component={Premiums}*/}
                        {/*  previous={[]}*/}
                        {/*  pageTitle={t('premiums')}*/}
                        {/*  authorized={true}*/}
                        {/*  setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*  path="/agricultural-holding/:ahid/money/grants/premiums/add"*/}
                        {/*  exact*/}
                        {/*  isPrivate*/}
                        {/*  component={PremiumForm}*/}
                        {/*  previous={[]}*/}
                        {/*  pageTitle={t('premiumsAdd')}*/}
                        {/*  authorized={true}*/}
                        {/*  setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*  path="/agricultural-holding/:ahid/money/grants/premiums/:id"*/}
                        {/*  exact*/}
                        {/*  isPrivate*/}
                        {/*  component={PremiumForm}*/}
                        {/*  previous={[]}*/}
                        {/*  pageTitle={t('premiumsEdit')}*/}
                        {/*  authorized={true}*/}
                        {/*  setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        <Route
                          path="/agricultural-holding/:ahid/money/subsidies"
                          exact
                          isPrivate
                          component={Subsidies}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('subsidies')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/subsidies/add"
                          exact
                          isPrivate
                          component={SubsidyForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'subsidies', link: true },
                          ]}
                          pageTitle={t('subsidiesAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/subsidies/:id"
                          exact
                          isPrivate
                          component={SubsidyForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'subsidies', link: true },
                          ]}
                          pageTitle={t('subsidiesEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans"
                          exact
                          isPrivate
                          component={Loans}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('loans')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/loan"
                          exact
                          isPrivate
                          component={Loan}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                          ]}
                          pageTitle={t('loans')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/loan/add"
                          exact
                          isPrivate
                          component={LoanForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                            { title: 'loan', link: true },
                          ]}
                          pageTitle={t('loansAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/loan/:id"
                          exact
                          isPrivate
                          component={LoanForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                            { title: 'loan', link: true },
                          ]}
                          pageTitle={t('loansEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/leasing"
                          exact
                          isPrivate
                          component={Leasing}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                          ]}
                          pageTitle={t('leasing')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/leasing/add"
                          exact
                          isPrivate
                          component={LeasingForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                            { title: 'leasing', link: true },
                          ]}
                          pageTitle={t('leasingAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/loans/leasing/:id"
                          exact
                          isPrivate
                          component={LeasingForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'loans', link: true },
                            { title: 'leasing', link: true },
                          ]}
                          pageTitle={t('leasingEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/money-flow"
                          exact
                          isPrivate
                          component={MoneyFlow}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('moneyFlow')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/money-flow/add"
                          exact
                          isPrivate
                          component={MoneyFlowForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'money-flow', link: true },
                          ]}
                          pageTitle={t('moneyFlowAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/money-flow/:id"
                          exact
                          isPrivate
                          component={MoneyFlowForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'money-flow', link: true },
                          ]}
                          pageTitle={t('moneyFlowEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/other-income"
                          exact
                          isPrivate
                          component={OtherIncome}
                          previous={[{ title: 'money', link: false }]}
                          pageTitle={t('otherIncome')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/other-income/add"
                          exact
                          isPrivate
                          component={OtherIncomeForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'other-income', link: true },
                          ]}
                          pageTitle={t('otherIncomeAdd')}
                          authorized={isAuthorised && (isAdmin || isCollector) && !readOnly}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/agricultural-holding/:ahid/money/other-income/:id"
                          exact
                          isPrivate
                          component={OtherIncomeForm}
                          previous={[
                            { title: 'money', link: false },
                            { title: 'other-income', link: true },
                          ]}
                          pageTitle={t('otherIncomeEdit')}
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/administration"
                          exact
                          isPrivate
                          component={Administration}
                          previous={[]}
                          pageTitle={t('administration')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/reports"
                          exact
                          isPrivate
                          component={Reports}
                          previous={[]}
                          pageTitle={t('reports')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/revisions/:entity/:id"
                          exact
                          isPrivate
                          component={Revisions}
                          previous={[]}
                          pageTitle=""
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/revisions/:entity/:id/:revisionNumber"
                          exact
                          isPrivate
                          component={Revisions}
                          previous={[]}
                          pageTitle=""
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/:ahid/revisions/:group/:entity/:id"
                          exact
                          isPrivate
                          component={Revisions}
                          previous={[]}
                          pageTitle=""
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/:ahid/revisions/:group/:entity/:id/:revisionNumber"
                          exact
                          isPrivate
                          component={Revisions}
                          previous={[]}
                          pageTitle=""
                          authorized={isAuthorised}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/forms"
                          exact
                          isPrivate
                          component={Forms}
                          previous={[]}
                          pageTitle={t('forms')}
                          authorized={isAuthorised && isAdmin}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/account/activate"
                          exact
                          component={AccountActivation}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/register"
                          exact
                          component={Register}
                          previous={[]}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/login"
                          exact
                          component={Login}
                          previous={[]}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        <Route
                          path="/"
                          exact
                          component={Login}
                          previous={[]}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                        {/*===============================================*/}
                        {/*============      TEST PAGES     ==============*/}
                        {/*===============================================*/}
                        {/*<Route*/}
                        {/*  path="/search"*/}
                        {/*  exact*/}
                        {/*  isPrivate*/}
                        {/*  component={SearchData}*/}
                        {/*  previous={[]}*/}
                        {/*  pageTitle={'Search'}*/}
                        {/*  authorized={true}*/}
                        {/*  setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/* path="/components-form-validation"*/}
                        {/* exact*/}
                        {/* isPrivate*/}
                        {/* component={TestFormValidation}*/}
                        {/* previous={[]}*/}
                        {/* authorized={true}*/}
                        {/* setIsLoading={() => setIsLoading(true)}*/}
                        {/*/>*/}
                        {/*===============================================*/}
                        {/*==========      --TEST PAGES--     ============*/}
                        {/*===============================================*/}
                        <Route
                          path="*"
                          component={ErrorPage404}
                          authorized={true}
                          setIsLoading={() => setIsLoading(true)}
                        />
                      </Switch>
                    ) : (
                      <LoadingPage />
                    )}
                  </WindowHeightProvider>
                </WindowWidthProvider>
              </BackConfirmProvider>
            </NotificationBadgeProvider>
          </ShowDeletedEntriesProvider>
        </LockingExplanation.Provider>
      </AgriculturalHoldingData.Provider>
    </UserData.Provider>
  );
}

export default memo(App);
