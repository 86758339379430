import React, { useState } from 'react';
import ModalWrapper from '../../../../components/modal/modalWrapper/ModalWrapper';
import { t } from 'react-switch-lang';
import classes from './ReportsModal.module.scss';
import { axiosGet, axiosGetFile } from '../../../../services/axios';
import Swal from 'sweetalert2';
import Toast from '../../../../components/toast/Toast';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../../../components/loader/Loader';
import { formatGenerateFrErrorResponse } from '../utils/frErrorFormat';

const ReportsModal = ({ isModalOpen, setIsModalOpen }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const addData = (api, report) => {
    setIsLoading(true);
    axiosGet(api)
      .then((response) => {
        if (response.status === 200) {
          Toast.fire({
            title: t('reports.messages.generateSuccess'),
            icon: 'success',
          });
          history.push({
            pathname: '/reports',
            state: {
              report: report,
            },
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.entityName === 'sRCacheForReport') {
          Swal.fire({
            text: t('reports.messages.reportNotGenerated'),
            icon: 'error',
          });
        } else {
          Swal.fire({
            html: formatGenerateFrErrorResponse(err.response.data.errors, true),
            icon: 'error',
          });
        }
      });
  };

  const onDownload = (api) => {
    axiosGetFile(api, 'xml_report.xml')
      .then()
      .catch(() => {
        Swal.fire({
          text: t('reports.messages.reportNotGenerated'),
          icon: 'error',
        });
      });
  };

  return (
    <ModalWrapper
      onClose={() => setIsModalOpen(false)}
      isModalOpen={isModalOpen}
      header={<span>{t('sidebar.reports')}</span>}
    >
      {isLoading ? (
        <Loader size="sm" />
      ) : (
        <>
          <div className="d-flex flex-column">
            <div className="w-100">
              <div
                className={`d-flex flex-row justify-content-between mb-3 ${classes['container']}`}
              >
                <span>{t('reports.sidebar.frReport')}</span>
                <div className={`d-flex flex-row align-items-center ${classes['actions']}`}>
                  <div className={classes['links']}>
                    <a onClick={() => addData('fr-cache-for-reports/add-data', 'fr')}>
                      {t('reports.actions.generateOutput').toUpperCase()}
                    </a>
                  </div>
                  <div className="ml-1 mr-1">|</div>
                  <div className={classes['links']}>
                    <a onClick={() => onDownload('frReportXmlExport')}>
                      {t('reports.actions.exportOutput').toUpperCase()}
                    </a>
                  </div>
                </div>
              </div>
              <div className={`d-flex flex-row justify-content-between ${classes['container']}`}>
                <span>{t('reports.sidebar.srReport')}</span>
                <div className={`d-flex flex-row align-items-center ${classes['actions']}`}>
                  <div className={classes['links']}>
                    <a onClick={() => addData('sr-cache-for-reports/add-data', 'sr')}>
                      {t('reports.actions.generateOutput').toUpperCase()}
                    </a>
                  </div>
                  {/*<div className="ml-1 mr-1">|</div>*/}
                  {/*<div className={classes['links']}>*/}
                  {/*  <a>{t('reports.actions.exportOutput').toUpperCase()}</a>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default ReportsModal;

ReportsModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};
