import React, { useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../../../../components/form/validationForm/ValidationForm';
import InputField from '../../../../../../components/form/inputField/InputField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import Toast from '../../../../../../components/toast/Toast';
import { t } from 'react-switch-lang';

const WeightingsForm = ({ data, type, updateTable, closeModal, isDisabled }) => {
  const formRef = useRef();

  const initialData = {
    name: '',
    nameEn: '',
    nuts: '',
    sortVal: 0,
  };

  const [changedData, setChangedData] = useState(initialData);

  const validation = {
    name: { type: 'string', required: { value: true }, min: { value: 3 } },
    nameEn: { type: 'string', required: { value: true }, min: { value: 3 } },
    nuts: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 10 } },
    sortVal: { type: 'number', required: { value: true }, min: { value: 1 } },
  }

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData
      };

      try {
        const response = await axiosPost('regions', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.region.messages.successAdd'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData
      };

      try {
        const response = await axiosPut(`regions/${data.id}`, payload);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.region.messages.successEdit'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        name: data.name,
        nameEn: data.nameEn,
        nuts: data.nuts,
        sortVal: data.sortVal,
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <InputField
          label={t('administration.enums.columns.name')}
          placeholder={t('administration.enums.placeholders.name')}
          value={changedData.name}
          name='name'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, name: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.nameEn')}
          placeholder={t('administration.enums.placeholders.nameEn')}
          value={changedData.nameEn}
          name='nameEn'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, nameEn: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.nuts')}
          placeholder={t('administration.enums.placeholders.nuts')}
          value={changedData.nuts}
          name='nuts'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, nuts: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.sortVal')}
          placeholder={t('administration.enums.placeholders.sort')}
          value={changedData.sortVal}
          name='sortVal'
          type='number'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, sortVal: e, });
          }}
          disabled={isDisabled}
        />
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)} />
        </div>
      )}
    </>
  );
};

export default WeightingsForm;
