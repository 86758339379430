import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const Altitude = () => {
  return (
    <div>
      <StandardTable api={'altitudes'} title={t('administration.enums.title.altitude')} />
    </div>
  );
};

export default Altitude;
