import { axiosGet } from '../../axios';
import { eng } from "../../../utils/const";

const defaultOption = (option) => {
  return {
    label: eng ? option.nameEn : option.name,
    value: option.id,
    disabled: false,
  };
};

export const fetchSinglePlantProdExpense = (id) => {
  return axiosGet(`plant-production-expenses/${id}`);
};

export const getPlantExpenseType = () => {
  return axiosGet('plant-expense-types').then(r => {
    let arrTemp = [];
    for (let item of r.data) {
      arrTemp.push(
        defaultOption(item),
      );
    }
    return arrTemp;
  }).catch(() => []);
};
export const getMineralFertilizerType = () => {
  return axiosGet('mineral-fertilizer-types').then(r => {
    let arrTemp = [];
    for (let item of r.data) {
      arrTemp.push(
        defaultOption(item),
      );
    }
    return arrTemp;
  }).catch(() => []);
};
