import { axiosGet } from '../../axios';

export const getManufacturedProducts = (id) => {
  return axiosGet(`manufactured-products/${id}`);
};

export const getProductionManufacturedProducts = () => {
  return axiosGet('production-manufactured-products');
};

export const getProductionCodes = () => {
  return axiosGet('production-codes');
};

export const getProductionTypes = () => {
  return axiosGet('production-types');
};