import React from 'react';
import { capitalizeFirstLetter } from '../../../../../utils/const';
import { t } from 'react-switch-lang';
import { useHistory } from "react-router-dom";
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import classes from "./PageTitle.module.scss";
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import PropTypes from 'prop-types';
import { useWindowWidth } from '../../../../../contexts/WindowWidth';

const PageTitle = ({ previous, title,
                     isCustomTitle = false,
                     hasBackButton = false,
                     backLink = '' }) => {
  const history = useHistory();

  const windowWidth = useWindowWidth();

  const setTitle = () => {
    const text = `${t(`pageTitle.${title}`)}`;
    return isCustomTitle
          ? capitalizeFirstLetter(title)
          : capitalizeFirstLetter(text);
  };

  return title && title?.length > 0
      ? <div style={windowWidth > 768 ? { padding: '20px 0 50px' } : { padding: '10px 0 10px' }} className="d-flex flex-wrap align-items-center">
          {previous && previous?.length > 0 && <Breadcrumbs breadcrumbs={previous} />}
          {hasBackButton && <span className={`mr-3 ${classes['icon']}`} onClick={() => history.push(backLink)}>
            <ArrowLeftOutlined width={40}/>
          </span>}
          <a style={{ ...(windowWidth <= 605 && { fontSize: 14 }) }} className={`curr navbar-brand d-block`}>{setTitle()}</a>
  </div>
      : '';
};

export default PageTitle;

PageTitle.propTypes = {
  previous: PropTypes.array,
  title: PropTypes.string,
}
