import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { axiosGetMulti } from "../../../../services/axios";
import Swal from "sweetalert2";
import { t } from "react-switch-lang";
import PlantProductionImg from "../../../../assets/images/money/expenses/biljna_proizvodnja.svg";
import AnimalProductionImg from "../../../../assets/images/money/expenses/stoćarska_proizvodnja.svg";
import ManufacturerProcessingImg from "../../../../assets/images/money/expenses/prerada.svg";
import LabourAndMachineryImg from "../../../../assets/images/money/expenses/rad_i_mehanizacija.svg";
import LandAndBuildingImg from "../../../../assets/images/money/expenses/zemljište_i_objekti.svg";
import OverheadImg from "../../../../assets/images/money/expenses/režije.svg";
import InsuranceImg from "../../../../assets/images/money/expenses/osiguranje.svg";
import TaxImg from "../../../../assets/images/money/expenses/porezi_i_naknade.svg";
import { CardsGrid } from "../../../../components/cardsGrid/CardsGrid";

export const Expenses = () => {
  let { ahid } = useParams();
  const history = useHistory();

  const [expensesTotal, setExpensesTotal] = useState({
    plantProduction: 0,
    animalProduction: 0,
    processing: 0,
    labourMachinery: 0,
    landBuilding: 0,
    overhead: 0,
    insurance: 0,
    tax: 0,
  });

  useEffect(() => {
    axiosGetMulti([
      `plant-production-expenses/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      `animal-production-expenses/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      `processing-expenses/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      `labour-machinery-expenses/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      `land-building-expenses/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      `overhead-expenses/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      `insurance-expenses/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
      `tax-expenses/count?isDeleted.equals=false&agroHoldingId.equals=${ahid}`,
    ])
      .then((r) => {
        setExpensesTotal({
          plantProduction: r[0].data,
          animalProduction: r[1].data,
          processing: r[2].data,
          labourMachinery: r[3].data,
          landBuilding: r[4].data,
          overhead: r[5].data,
          insurance: r[6].data,
          tax: r[7].data,
        });
      })
      .catch((r) => {
        Swal.fire({
          text: t('common.error'),
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
  }, []);

  const list = [
    {
      title: t('pageTitle.plantProduction'),
      image: PlantProductionImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: expensesTotal.plantProduction,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/expenses/plant-production-expenses`),
    },
    {
      title: t('pageTitle.animalProduction'),
      image: AnimalProductionImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: expensesTotal.animalProduction,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/expenses/animal-production-expenses`),
    },
    {
      title: t('pageTitle.manufacturerProcessing'),
      image: ManufacturerProcessingImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: expensesTotal.processing,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/expenses/manufacturer-processing`),
    },
    {
      title: t('pageTitle.labourMachinery'),
      image: LabourAndMachineryImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: expensesTotal.labourMachinery,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/expenses/labour-and-machinery`),
    },
    {
      title: t('pageTitle.landBuilding'),
      image: LandAndBuildingImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: expensesTotal.landBuilding,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/expenses/land-and-building`),
    },
    {
      title: t('pageTitle.overhead'),
      image: OverheadImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: expensesTotal.overhead,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/expenses/overhead`),
    },
    {
      title: t('pageTitle.insurance'),
      image: InsuranceImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: expensesTotal.insurance,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/expenses/insurance`),
    },
    {
      title: t('pageTitle.tax'),
      image: TaxImg,
      explanation: {
        title: t('common.numberOfExpenses'),
        value: expensesTotal.tax,
      },
      onClick: () =>
        history.push(`/agricultural-holding/${ahid}/money/expenses/tax`),
    },
  ];

  return (
    <div className="d-flex">
      <CardsGrid list={list} />
    </div>
  );
};
