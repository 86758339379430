import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const loansFields = [
  {
    value: 'loanPurpose',
    label: () => t('pages.loan.loanPurpose'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'loan-purposes',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'loanType',
    label: () => t('pages.loan.loanType'),
    type: 'select',
    options: {
      list: [],
      api: 'loan-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'principalValue',
    label: () => t('pages.loan.principalValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.loan.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'interestValue',
    label: () => t('pages.loan.interestValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'loanCategory',
    label: () => t('pages.loan.loanCategory'),
    type: 'select',
    options: {
      list: [],
      api: 'loan-categories',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'otherLoanPurpose',
    label: () => t('pages.loan.otherLoanPurpose'),
    type: 'text',
    options: {},
    displayInLimits: false
  },
  {
    value: 'otherLoanPurposeApproved',
    label: () => t('pages.loan.otherLoanPurposeApproved'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'closingValue',
    label: () => t('pages.loan.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.loan.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default loansFields;