import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const farmBuildingsFields = [
  {
    value: 'objectType',
    label: () => t('pages.farmBuildings.objectType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'object-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'dateProcured',
    label: () => t('pages.farmBuildings.dateProcured'),
    type: 'date',
    options: {},
    displayInLimits: false
  },
  {
    value: 'openingArea',
    label: () => t('pages.farmBuildings.openingArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.farmBuildings.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'investmentArea',
    label: () => t('pages.farmBuildings.investmentArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'investmentValue',
    label: () => t('pages.farmBuildings.investmentValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchasedArea',
    label: () => t('pages.farmBuildings.purchasedArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchasedValue',
    label: () => t('pages.farmBuildings.purchasedValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'subsidiesValue',
    label: () => t('pages.farmBuildings.subsidiesValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldArea',
    label: () => t('pages.farmBuildings.soldArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldValue',
    label: () => t('pages.farmBuildings.soldValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'depreciationRate',
    label: () => t('pages.farmBuildings.depreciationRate'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'depreciationValue',
    label: () => t('pages.farmBuildings.depreciationValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'accumulatedDepreciationValue',
    label: () => t('pages.farmBuildings.accumulatedDepreciationValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'acquisitionArea',
    label: () => t('pages.farmBuildings.acquisitionArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'acquisitionValue',
    label: () => t('pages.farmBuildings.acquisitionValue'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'closingArea',
    label: () => t('pages.farmBuildings.closingArea'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.farmBuildings.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.farmBuildings.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default farmBuildingsFields;