import React, { useContext, useEffect, useRef, useState } from 'react';
import { fetchUserApi } from '../../../services/users/usersAPI';
import { t } from 'react-switch-lang';
import { axiosPost } from '../../../services/axios';
import InputField from '../../../components/form/inputField/InputField';
import ValidationForm from '../../../components/form/validationForm/ValidationForm';
import SaveBtn from '../../../components/buttons/SaveBtn';
import Swal from 'sweetalert2';
import classes from './UserProfile.module.scss';
import ModalWrapper from '../../../components/modal/modalWrapper/ModalWrapper';
import SelectConstField from '../../../components/form/selectField/SelectConstField';
import { REGEX } from '../../../utils/validationDefaults';
import UserData from '../../../contexts/UserData';
import { useHistory } from 'react-router-dom';
import { validateDuplicate } from './utils/form/duplicateValidation';
import { getOffices } from './utils/form/offices';
import { roles } from './utils/form/userRoles';
import { defaultOption } from '../../../utils/selectObjectFormatter';
import logout from '../../../utils/logout';

const UserProfile = ({ isModalOpen, setIsModalOpen }) => {
  const formRef = useRef();
  const history = useHistory();

  const user = useContext(UserData);

  const [userData, setUserData] = useState({});
  const [changePassword, setChangePassword] = useState(false);

  const [officeOptionsList, setOfficeOptionsList] = useState([]);

  const [refreshEmail, setRefreshEmail] = useState(false);

  const [refreshConfirmNewPassword, setRefreshConfirmNewPassword] = useState(false);
  const [refreshCurrentPassword, setRefreshCurrentPassword] = useState(false);

  // changes password value inside validation file
  REGEX.currentPassword = userData?.newPassword;

  const [validation, setValidation] = useState({
    login: { type: 'string', required: { value: false } },
    firstName: { type: 'string', required: { value: true }, min: { value: 2 }, max: { value: 20 } },
    lastName: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 40 } },
    email: { type: 'string', required: { value: true }, matches: { value: 'EMAIL' } },
    ...(user.isAdmin && { role: { type: 'selectSingle', required: { value: true } } }),
    office: { type: 'selectSingle', required: { value: true } },
  });

  // helper function that takes userData payload and sends request for update
  const postUserProfile = async (payload) => {
    const response = await axiosPost('account', payload);

    if (response.status === 200) {
      Swal.fire({
        text: user.userData.login !== payload.login ? t('userProfile.successRedirect') : t('userProfile.success'),
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        setIsModalOpen(false);
        user.userData.authorities[0] !== payload.authorities[0] && history.go(0);
        user.userData.login !== payload.login && logout(history);
      });
    }
  };

  const handleSubmit = async () => {
    let payload = {
      ...userData,
      authorities: [userData.role.value],
      login: userData?.login ? userData?.login?.trim() : userData?.email.split('@')[0],
      countyDTO: { id: userData?.office?.value },
    };

    try {
      // handles two cases: profile change without password change and profile change with password change
      if (changePassword) {
        const passwordResponse = await axiosPost('account/change-password', {
          currentPassword: payload?.password,
          newPassword: payload?.newPassword,
        });

        // checks if password could be changed, and only if it is successful does it change other profile information
        if (passwordResponse.status === 200) {
          await postUserProfile(payload);
        }
      } else {
        // case if user has chosen not to change password
        await postUserProfile(payload);
      }
    } catch (err) {
      if (changePassword) {
        setValidation({
          ...validation,
          'password': {
            ...validation['password'],
            error: { value: true, messages: t('userProfile.incorrectPassword') },
          },
        });
        setRefreshCurrentPassword(prevState => !prevState);
      }

      validateDuplicate(err, validation, setValidation, setRefreshEmail);

      formRef.current.submitForm();
    }
  };

  useEffect(() => {
    changePassword && setValidation(prevState => ({
      ...prevState,
      password: { type: 'string', required: { value: true }, min: { value: 8 }, max: { value: 100 } },
      newPassword: {
        type: 'string',
        required: { value: true },
        min: { value: 8 },
        max: { value: 100 },
        matches: { value: 'STRONGPASSWORD' },
      },
      confirmPassword: {
        type: 'string',
        required: { value: true },
        min: { value: 8 },
        max: { value: 100 },
        matches: { value: 'PASSWORD' },
      },
    }));
  }, [changePassword]);

  // watches confirmPassword field when changing password field
  useEffect(() => {
    setRefreshConfirmNewPassword(prevState => !prevState);
  }, [REGEX.PASSWORD]);

  useEffect(() => {
    getOffices(setOfficeOptionsList);
    fetchUserApi()
      .then(response => setUserData({
        ...response?.data,
        role: roles.find(option => response?.data?.authorities[0] === option.value),
        ...(response?.data?.countyDTO && { office: defaultOption(response?.data?.countyDTO) || undefined }),
      }))
      .catch(err => {
      });
  }, []);

  return (
    <ModalWrapper onClose={() => setIsModalOpen(false)} isModalOpen={isModalOpen}
                  header={<span className={`${classes['title']}`}>{t('pageTitle.profile')}</span>}>
      <div className='d-flex flex-column'>
        <div className='w-100 mt-3'>
          <div className='d-flex w-100 justify-content-center'>
            <div style={{ width: '100%' }}>
              <ValidationForm validation={validation} ref={formRef}>
                <InputField
                  label={t('userProfile.login')}
                  value={userData?.login}
                  name='login'
                  groupField
                  disabled={true}
                />
                <InputField
                  label={t('userProfile.firstName')}
                  placeholder={t('register.firstNamePlaceholder')}
                  value={userData?.firstName}
                  name='firstName'
                  groupField
                  onChange={(e) => {
                    setUserData(prevState => ({
                      ...prevState,
                      firstName: e,
                    }));
                  }}
                  disabled={false}
                />
                <InputField
                  label={t('userProfile.lastName')}
                  placeholder={t('register.lastNamePlaceholder')}
                  value={userData?.lastName}
                  name='lastName'
                  groupField
                  onChange={(e) => {
                    setUserData(prevState => ({
                      ...prevState,
                      lastName: e,
                    }));
                  }}
                  disabled={false}
                />
                <InputField
                  label={t('userProfile.email')}
                  placeholder={t('register.emailPlaceholder')}
                  value={userData?.email}
                  name='email'
                  groupField
                  onChange={(e) => {
                    setUserData(prevState => ({
                      ...prevState,
                      email: e,
                    }));
                    setValidation({
                      ...validation,
                      email: { ...validation['email'], error: { value: false } },
                    });
                  }}
                  disabled={false}
                  key={'email' + refreshEmail}
                />
                <SelectConstField
                  label={t('userProfile.office')}
                  placeholder={t('register.officePlaceholder')}
                  value={userData?.office}
                  name='office'
                  groupField
                  onChange={(e) => {
                    setUserData(prevState => ({
                      ...prevState,
                      office: e,
                    }));
                  }}
                  options={officeOptionsList}
                  disabled={false}
                />
                {
                  user.isAdmin &&
                  <SelectConstField
                    label={t('administration.users.columns.role')}
                    name='role'
                    value={userData.role}
                    optionValue={'name'}
                    optionLabel={'description'}
                    groupField
                    options={roles}
                    onChange={(e) => {
                      setUserData({ ...userData, role: e });
                    }}
                  />
                }
                <hr />
                {changePassword ?
                  <div className='d-flex flex-column w-100 justify-content-between'>
                    <InputField
                      label={t('userProfile.password')}
                      placeholder={t('register.passwordPlaceholder')}
                      value={userData?.password}
                      name='password'
                      type='password'
                      groupField
                      onChange={(e) => {
                        setUserData(prevState => ({
                          ...prevState,
                          password: e,
                        }));
                        setValidation({
                          ...validation,
                          'password': { ...validation['password'], error: { value: false } },
                        });
                      }}
                      disabled={false}
                      key={'password' + refreshCurrentPassword}
                    />
                    <InputField
                      label={t('userProfile.newPassword')}
                      placeholder={t('register.passwordPlaceholder')}
                      value={userData?.newPassword}
                      name='newPassword'
                      type='password'
                      groupField
                      onChange={(e) => {
                        setUserData(prevState => ({
                          ...prevState,
                          newPassword: e,
                        }));
                      }}
                      disabled={false}
                    />
                    <InputField
                      label={t('userProfile.confirmNewPassword')}
                      placeholder={t('register.passwordPlaceholder')}
                      value={userData?.confirmPassword}
                      name='confirmPassword'
                      type='password'
                      groupField
                      onChange={(e) => {
                        setUserData(prevState => ({
                          ...prevState,
                          confirmPassword: e,
                        }));
                      }}
                      disabled={false}
                      key={'confirmNewPassword' + refreshConfirmNewPassword}
                    />
                  </div>
                  :
                  <div className='d-flex w-100 justify-content-between'>
                    <div className='d-flex flex-column w-40'>
                      <div style={{ fontSize: 12 }}>{t('userProfile.password')}</div>
                      <div>{'*'.repeat(6)}</div>
                    </div>
                    <div onClick={() => setChangePassword(true)}
                         className={`${classes.changePassword} d-flex w-20 align-items-center justify-content-end`}>
                      {t('userProfile.changePassword')}
                    </div>
                  </div>
                }
                <hr />
                <div className='d-flex w-100 justify-content-end mt-2'>
                  <SaveBtn
                    loaderOnDisabled={false}
                    loader={false}
                    label={t('userProfile.save')}
                    className='pl-5 pr-5'
                    onClick={() => {
                      formRef.current.submitForm(handleSubmit);
                    }}
                  />
                </div>
              </ValidationForm>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UserProfile;