import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const PlantProductionType = () => {
  return (
    <div>
      <StandardTable api={'plant-production-types'} title={t('administration.enums.title.plantProductionType')} />

    </div>
  );
};

export default PlantProductionType;
