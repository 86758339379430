import React from 'react';
import { useHistory } from "react-router-dom";
import HistoryImg from "../../assets/images/icons/history.svg";

const GoToRevision = ({ahId, group, entity, id, revisionNumber}) => {
  const history = useHistory();

  const goTo = () => {
    history.push(`${ahId ? `/${ahId}` : ''}/revisions${group ? `/${group}` : ''}${entity ? `/${entity}` : ''}${id ? `/${id}` : ''}${revisionNumber ? `/${revisionNumber}` : ''}`)
  }

  return <button
            type="button"
            className="btn p-0 mr-2"
            onClick={(e) => {
              e.stopPropagation();
              goTo();
            }}
          >
          <img src={HistoryImg} alt="history" />
        </button>
}

export default GoToRevision;