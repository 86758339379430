import React, { useEffect, useState } from 'react';
import Treemap from '@ant-design/charts/lib/plots/treemap';
import { axiosGet } from '../../../services/axios';
import classes from '../Charts.module.scss';
import { formatNumber } from '../../../utils/const';

// model function should be a functions that takes api data and transforms into chart-readable data
//
// defaultClick determines if a chart should have a default onClick function or not (pie slice moving, etc.)

const TreemapChart = React.memo(({
                                   label,
                                   api,
                                   modelFunction,
                                   defaultClick = true,
                                   ...rest
                                 }) => {
  const [data, setData] = useState({ name: 'root', children: [] });

  // Configuration for treemapChart
  const config = {
    data: data,
    colorField: 'name',
    width: 280,
    tooltip: {
      formatter: (element) => {
        return { name: element.name, value: formatNumber(element.value) + ' ha' };
      },
    },
  };

  // useEffect that takes api and custom function for modeling data and feeds the data to chart
  useEffect(() => {
    axiosGet(api)
      .then((response) => setData(prevState => ({
        ...prevState,
        children: modelFunction(response?.data),
      })))
      .catch((err) => {
      });
  }, [api]);

  return (
    <>
      {
        data?.children?.length > 0 ?
          <div className={`d-flex flex-column align-items-center ${classes['chartContainer']}`}>
            <span className={classes['title']}>{label}</span>
            <Treemap {...rest} {...config} />
          </div>
          :
          <></>
      }
    </>
  );
});

export default TreemapChart;
