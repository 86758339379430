import React from 'react';
import LivestockTableEnums from "../../../../../components/administration/enums/livestockEnums/LivestockTableEnums";
import {t} from "react-switch-lang";

const SheepsCategory = () => {
  return (
    <div>
      <LivestockTableEnums api={'sheep-categories'} title={t('administration.enums.title.sheepCategory')}/>
    </div>
  );
};

export default SheepsCategory;
