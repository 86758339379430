import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';
import { eng, hasValue } from '../../../../../../utils/const';
import React from 'react';

const renderColWithTooltip = (col) => {
  return <Tooltip title={col} placement="topLeft" color={'#67B278'}
                  overlayInnerStyle={{ borderRadius: '5px' }}>{hasValue(col) ? col : '/'}</Tooltip>;
};

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: id => renderColWithTooltip(id),
    width: 50,
  },
  {
    title: t('administration.enums.columns.region'),
    dataIndex: ['region',  eng ? 'nameEn' : 'name'],
    key: 'region',
    ellipsis: true,
    render: id => renderColWithTooltip(id),

  },
  {
    title: t('administration.enums.columns.value'),
    dataIndex: ['value'],
    key: 'value',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.soCode'),
    dataIndex: ['soCode'],
    key: 'soCode',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  }
];
