import { t } from 'react-switch-lang';
import { eng } from '../../../../../../../utils/const';

const poultryFatteningFields = [
  {
    value: 'poultryCategory',
    label: () => t('pages.poultry.categoryNamePoultry'),
    type: 'select', // integer, double, select, textarea, string
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api:'poultry-categories',
      apiQuery: 'canBeServiceFattening.equals=true',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.poultry.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathQuantity',
    label: () => t('pages.poultry.deathQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'inputQuantity',
    label: () => t('pages.poultry.inputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'outputQuantity',
    label: () => t('pages.poultry.outputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.poultry.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.poultry.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default poultryFatteningFields;