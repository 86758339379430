import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UserData from '../../../../../contexts/UserData';
import { Tooltip } from 'antd';
import { t } from 'react-switch-lang';
import { eng, formatNumber, hasValue, readOnly } from '../../../../../utils/const';
import Prompt from '../../../../../components/prompt/Prompt';
import { axiosDelete } from '../../../../../services/axios';
import Toast from '../../../../../components/toast/Toast';
import Swal from 'sweetalert2';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import AdministrationTable from '../../../../../components/table/AdministrationTable';
import { useShowDeleted } from '../../../../../contexts/ShowDeletedEntries';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';

export const LabourExpenses = () => {
  let { ahid } = useParams();
  const history = useHistory();

  const user = useContext(UserData);
  const { showDeleted, toggleShowDeleted } = useShowDeleted();

  const agriculturalData = useContext(AgriculturalHoldingData);
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const renderColWithTooltip = (col) => {
    return (
      <Tooltip
        title={col}
        placement="topLeft"
        color={'#67B278'}
        overlayInnerStyle={{ borderRadius: '5px' }}
      >
        {hasValue(col) ? col : '/'}
      </Tooltip>
    );
  };

  const columns = [
    {
      title: t('pages.labourExpenses.id'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 100,
      render: (id) => renderColWithTooltip(id),
    },
    {
      title: t('pages.labourExpenses.labourMachineryExpenseType'),
      dataIndex: ['labourMachineryExpenseType', eng ? 'nameEn' : 'name'],
      key: 'labourMachineryExpenseType',
      ellipsis: true,
      render: (labourMachineryExpenseType) => renderColWithTooltip(labourMachineryExpenseType),
    },
    {
      title: t('pages.labourExpenses.openingQuantity'),
      dataIndex: ['openingQuantity'],
      key: 'openingQuantity',
      ellipsis: true,
      render: (openingQuantity) => renderColWithTooltip(formatNumber(openingQuantity)),
    },
    {
      title: t('pages.labourExpenses.openingValue'),
      dataIndex: 'openingValue',
      key: 'openingValue',
      ellipsis: true,
      render: (openingValue) => renderColWithTooltip(formatNumber(openingValue)),
    },
    {
      title: t('pages.labourExpenses.closingQuantity'),
      dataIndex: ['closingQuantity'],
      key: 'closingQuantity',
      ellipsis: true,
      render: (closingQuantity) => renderColWithTooltip(formatNumber(closingQuantity)),
    },
    {
      title: t('pages.labourExpenses.closingValue'),
      dataIndex: 'closingValue',
      key: 'closingValue',
      ellipsis: true,
      render: (closingValue) => renderColWithTooltip(formatNumber(closingValue)),
    },
    {
      title: t('pages.labourExpenses.consumptionQuantity'),
      dataIndex: ['consumptionQuantity'],
      key: 'consumptionQuantity',
      ellipsis: true,
      render: (consumptionQuantity) => renderColWithTooltip(formatNumber(consumptionQuantity)),
    },
    {
      title: t('pages.labourExpenses.consumptionValue'),
      dataIndex: 'consumptionValue',
      key: 'consumptionValue',
      ellipsis: true,
      render: (consumptionValue) => renderColWithTooltip(formatNumber(consumptionValue)),
    },
  ];

  // todo refactor to separate component
  const onDelete = (record) => {
    return new Promise((resolve, reject) => {
      Prompt.fire({
        title: t('common.delete') + ' ' + t('messages.labourMachinery') + ' ' + record.id,
        text:
          t('common.deleteQuestion', { entity: t('messages.labourMachinery') + ' ' + record.id }) +
          '?',
        confirmButtonText: t('common.delete'),
        cancelButtonText: t('common.cancel'),
        preConfirm: async () => {
          try {
            const response = await axiosDelete('labour-machinery-expenses', record.id);
            if (response.status === 204) {
              Toast.fire({
                title:
                  t('common.successDelete', {
                    entity: t('messages.labourMachinery') + ' ' + record.id,
                  }) + '!',
                icon: 'success',
              });
              resolve(response);
            } else {
              reject(response);
            }
          } catch (err) {
            Swal.fire({
              text: t('common.apiErrorDelete'),
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        },
      });
    });
  };

  const onUpdate = (record) => {
    history.push({
      pathname: `/agricultural-holding/${ahid}/money/expenses/labour-and-machinery/${record.id}`,
      state: {
        add: false,
        edit: true,
      },
    });
  };

  const onRowClick = (record) => {
    history.push({
      pathname: `/agricultural-holding/${ahid}/money/expenses/labour-and-machinery/${record.id}`,
      state: {
        add: false,
        edit: false,
        isDeleted: record.isDeleted,
      },
    });
  };
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-end mb-5">
        {user.isAdmin && (
          <SaveBtn
            label={showDeleted ? t('common.hideDeleted') : t('common.showDeleted')}
            className="mt-2 mr-3 grayText"
            onClick={() => toggleShowDeleted()}
            thin={true}
            icon={true}
            iconAntd={showDeleted ? EyeInvisibleOutlined : EyeOutlined}
          />
        )}
        {(user.isAdmin || user.isCollector) && !isLocked && !readOnly && (
          <SaveBtn
            label={t('common.add')}
            onClick={() =>
              history.push({
                pathname: `/agricultural-holding/${ahid}/money/expenses/labour-and-machinery/add`,
                state: {
                  edit: true,
                  add: true,
                },
              })
            }
          />
        )}
      </div>
      <AdministrationTable
        api="labour-machinery-expenses"
        query={`${!showDeleted ? 'isDeleted.equals=false&' : ''}agroHoldingId.equals=${ahid}`}
        columns={columns}
        methods={{ onDelete, onUpdate }}
        className={'table-hover'}
        onClickRow={onRowClick}
        user={user}
        isLocked={isLocked || readOnly}
        group={'money'}
        historyEntity={'labourMachineryExpense'}
      />
    </div>
  );
};
