import React from 'react';
import classes from "./History.module.scss";

const HistoryItem = ({time, type, user, revisionNumber, activeRevisionNumber, goTo}) => {

  return <div onClick={() => goTo()} className={`${classes['history-item']} ${Number(activeRevisionNumber) === revisionNumber ? classes['active'] : ''}`}>
            <div className={classes['status-container']}>
              <div className={classes['time']}>{time}</div>
              <div className={`${classes['status']} ${classes[type?.color]}`}>{type?.title}</div>
            </div>
            <div className={classes['user']}>{user}</div>
  </div>
}

export default HistoryItem;