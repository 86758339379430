import { t } from 'react-switch-lang';
import { formatNumber } from '../../../../../../utils/const';
import dataModels from '../../../../explainableControls/utils/limitsData';

const renderTableName = (dataModel) => {
  const model = dataModels.find(item => item.value === dataModel);
  return model?.label ? model?.label() : dataModel ? dataModel : '/';
};

const renderFieldName = (dataModel, fieldName) => {
  const model = dataModels.find(item => item.value === dataModel);
  const field = model?.fields ? model.fields.find(item => item.value === fieldName) : null;
  return field?.label ? field?.label() : fieldName ? fieldName : '/';
};

export const columns = [
  {
    title: t('administration.limits.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
  },
  {
    title: t('administration.limits.dataModel'),
    dataIndex: 'dataModel',
    key: 'dataModel',
    render: dataModel => renderTableName(dataModel),
    ellipsis: true,
  },
  {
    title: t('administration.limits.fieldName'),
    dataIndex: 'fieldName',
    key: 'fieldName',
    render: (_, data) => renderFieldName(data?.dataModel, data?.fieldName),
    ellipsis: true,
  },
  {
    title: t('administration.limits.conditionalAttribute'),
    dataIndex: 'conditionalAttribute',
    key: 'conditionalAttribute',
    render: (_, data) => renderFieldName(data?.dataModel, data?.conditionalAttribute),
    ellipsis: true,
  },
  {
    title: t('administration.limits.dividerDataModel'),
    dataIndex: 'dividerDataModel',
    key: 'dividerDataModel',
    render: dividerDataModel => renderTableName(dividerDataModel),
    ellipsis: true,
  },
  {
    title: t('administration.limits.dividerFieldName'),
    dataIndex: 'dividerFieldName',
    key: 'dividerFieldName',
    render: (_, data) => renderFieldName(data?.dividerDataModel, data?.dividerFieldName),
    ellipsis: true,
  },
  {
    title: t('administration.limits.limitMax'),
    dataIndex: 'limitMax',
    key: 'limitMax',
    ellipsis: true,
    render: (data) => formatNumber(data),
  },
  {
    title: t('administration.limits.limitMin'),
    dataIndex: 'limitMin',
    key: 'limitMin',
    ellipsis: true,
    render: (data) => formatNumber(data),
  },
];
