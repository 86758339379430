import React from 'react';
import './Avatar.scss';
import { BASE_URL } from '../../utils/const';

const UserAvatar = ({ user, name }) => {
  const baseUrl = BASE_URL.split('/');
  baseUrl.pop();
  const noApiBaseUrl = baseUrl.join('/');

  const makeInitials = (user) => {
    const initials = [];
    if (user.firstName) initials[0] = user.firstName[0];
    if (user.user?.firstName) initials[0] = user.user.firstName[0];
    if (user.lastName) initials[1] = user.lastName[0];
    if (user.user?.lastName) initials[1] = user.user.lastName[0];
    return initials.join('');
  };

  const makeInitialsName = (name) => {
  };

  const avatar = user.imageUrl ? (
    <img src={noApiBaseUrl + user.imageUrl} alt="Avatar" />
  ) : (
    `${user !== undefined ? makeInitials(user) : makeInitialsName(name)}`
  );

  return <div className="avatar-pic avatar-user">{avatar}</div>;
};

export default UserAvatar;
