import { axiosGet } from '../../axios';

export const getOtherProducts = (id) => {
  return axiosGet(`other-products/${id}`);
};

export const getProductionOtherProducts = () => {
  return axiosGet('production-other-products');
};

export const getProductionCodes = () => {
  return axiosGet('production-codes');
};