import React, {useEffect, useRef, useState} from 'react';
import {axiosPost, axiosPut} from "../../../../../../services/axios";
import Toast from "../../../../../../components/toast/Toast";
import {t} from "react-switch-lang";
import ValidationForm from "../../../../../../components/form/validationForm/ValidationForm";
import InputField from "../../../../../../components/form/inputField/InputField";
import SaveBtn from "../../../../../../components/buttons/SaveBtn";
import CancelBtn from "../../../../../../components/buttons/CancelBtn";
import SelectAsyncField from "../../../../../../components/form/selectField/SelectAsyncField";
import {eng} from "../../../../../../utils/const";

const SoCoeficientForm = ({ data, type, updateTable, closeModal, isDisabled }) => {

  const formRef = useRef();

  const initialData = {
    region: undefined,
    soCode: '',
    value: ''
  };

  const [changedData, setChangedData] = useState(initialData);
  const [disabled, setDisabled] = useState(false);

  const validation = {
    region: { type: 'selectSingle', required: { value: true } },
    value: { type: 'number', required: { value: true }, min: { value: 1 } },
    soCode: { type: 'string', required: { value: true }, min: { value: 1 } },
  }

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData,
        region: { id: changedData.region.value },

      };

      setDisabled(true);

      try {
        const response = await axiosPost('so-coefficients', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.soCoefficient.successAdd'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData,
        region: { id: changedData.region.value },

      };

      setDisabled(true);

      try {
        const response = await axiosPut(`so-coefficients/${data.id}`, payload);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.soCoefficient.successEdit'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        region: { value: data.region.id, label: eng ? data.region.nameEn : data.region.name },
        soCode: data.soCode,
        value: data.value,
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <SelectAsyncField
          label={t('administration.weightings.columns.region')}
          placeholder={t('administration.enums.placeholders.region')}
          name='region'
          value={changedData.region}
          api={'regions'}
          pagination={false}
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, region: e });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.soCode')}
          placeholder={t('administration.enums.placeholders.soCode')}
          value={changedData.soCode}
          name='soCode'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, soCode: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.value')}
          placeholder={t('administration.enums.placeholders.value')}
          value={changedData.value}
          name='value'
          type={"number"}
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, value: e, });
          }}
          disabled={isDisabled}
        />
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            disabled={disabled}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)} />
        </div>
      )}
    </>
  );
};

export default SoCoeficientForm;
