import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { t } from 'react-switch-lang';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import UserData from '../../../../../contexts/UserData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import { Toast } from '../../../../../utils/sweetAlert';
import Loader from '../../../../../components/loader/Loader';
import SelectAsyncField from '../../../../../components/formDynamic/selectField/SelectAsyncField';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import classes from './OtherLivestockBasicForm.module.scss';
import { fetchSingleOther } from '../../../../../services/otherLivestock/otherLivestockApi';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const OtherLivestockBasicForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    otherLivestockCategory: { type: 'selectSingle', required: { value: true } },
    openingQuantity: { type: 'number', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    comment: { type: 'string', required: { value: false }, min: { value: 3 } },
    offspringQuantity: { type: 'number', required: { value: true } },
    offspringValue: { type: 'number', required: { value: true } },
    purchaseQuantity: { type: 'number', required: { value: true } },
    purchaseValue: { type: 'number', required: { value: true } },
    soldForSlaughterQuantity: { type: 'number', required: { value: true } },
    soldForSlaughterValue: { type: 'number', required: { value: true } },
    soldForBreedingQuantity: { type: 'number', required: { value: true } },
    soldForBreedingValue: { type: 'number', required: { value: true } },
    soldForUnknownReasonQuantity: { type: 'number', required: { value: true } },
    soldForUnknownReasonValue: { type: 'number', required: { value: true } },
    saleQuantity: { type: 'number', required: { value: true } },
    saleValue: { type: 'number', required: { value: true } },
    ownConsumptionQuantity: { type: 'number', required: { value: true } },
    ownConsumptionValue: { type: 'number', required: { value: true } },
    spentOnProcessingQuantity: { type: 'number', required: { value: true } },
    spentOnProcessingValue: { type: 'number', required: { value: true } },
    paymentInProductsQuantity: { type: 'number', required: { value: true } },
    paymentInProductsValue: { type: 'number', required: { value: true } },
    deathQuantity: { type: 'number', required: { value: true } },
    deathValue: { type: 'number', required: { value: true } },
    inputQuantity: { type: 'number', required: { value: true } },
    outputQuantity: { type: 'number', required: { value: true } },
    closingQuantity: { type: 'number', required: { value: true } },
    closingValue: { type: 'number', required: { value: true } },
  };

  const [other, setOther] = useState();
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const [otherLivestockCategory, setOtherLivestockCategory] = useState({ value: undefined, disabled: !edit });
  const [openingQuantity, setOpeningQuantity] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [offspringQuantity, setOffspringQuantity] = useState({ value: 0, disabled: !edit });
  const [offspringValue, setOffspringValue] = useState({ value: 0, disabled: !edit });
  const [purchaseQuantity, setPurchaseQuantity] = useState({ value: 0, disabled: !edit });
  const [purchaseValue, setPurchaseValue] = useState({ value: 0, disabled: !edit });
  const [soldForSlaughterQuantity, setSoldForSlaughterQuantity] = useState({ value: 0, disabled: !edit });
  const [soldForSlaughterValue, setSoldForSlaughterValue] = useState({ value: 0, disabled: !edit });
  const [soldForBreedingQuantity, setSoldForBreedingQuantity] = useState({ value: 0, disabled: !edit });
  const [soldForBreedingValue, setSoldForBreedingValue] = useState({ value: 0, disabled: !edit });
  const [soldForUnknownReasonQuantity, setSoldForUnknownReasonQuantity] = useState({
    value: 0,
    disabled: !edit,
  });
  const [soldForUnknownReasonValue, setSoldForUnknownReasonValue] = useState({ value: 0, disabled: !edit });
  const [saleQuantity, setSaleQuantity] = useState({ value: 0, disabled: true });
  const [saleValue, setSaleValue] = useState({ value: 0, disabled: true });
  const [ownConsumptionQuantity, setOwnConsumptionQuantity] = useState({ value: 0, disabled: !edit });
  const [ownConsumptionValue, setOwnConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [spentOnProcessingQuantity, setSpentOnProcessingQuantity] = useState({ value: 0, disabled: !edit });
  const [spentOnProcessingValue, setSpentOnProcessingValue] = useState({ value: 0, disabled: !edit });
  const [paymentInProductsQuantity, setPaymentInProductsQuantity] = useState({ value: 0, disabled: !edit });
  const [paymentInProductsValue, setPaymentInProductsValue] = useState({ value: 0, disabled: !edit });
  const [deathQuantity, setDeathQuantity] = useState({ value: 0, disabled: !edit });
  const [deathValue, setDeathValue] = useState({ value: 0, disabled: !edit });
  const [inputQuantity, setInputQuantity] = useState({ value: 0, disabled: !edit });
  const [outputQuantity, setOutputQuantity] = useState({ value: 0, disabled: !edit });
  const [closingQuantity, setClosingQuantity] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });

  const [errorExplanations, setErrorExplanations] = useState([]);

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [validation, setValidation] = useState({ ...validationInitial });

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'otherOwned', other, ahid, agriculturalData, setErrorExplanations, setValidation);
  };

  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        errorExplanations: clearEmptyExplanations(errorExplanations),
        ...(otherLivestockCategory?.value?.value && { otherLivestockCategory: { id: parseInt(otherLivestockCategory?.value?.value) } }),
        ...(hasValue(openingQuantity?.value) && { openingQuantity: parseFloatCustom(openingQuantity?.value) }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue?.value) }),
        ...(comment?.value && { comment: comment?.value }),
        ...(hasValue(offspringQuantity?.value) && { offspringQuantity: parseFloatCustom(offspringQuantity?.value) }),
        ...(hasValue(offspringValue?.value) && { offspringValue: parseFloatCustom(offspringValue?.value) }),
        ...(hasValue(purchaseQuantity?.value) && { purchaseQuantity: parseFloatCustom(purchaseQuantity?.value) }),
        ...(hasValue(purchaseValue?.value) && { purchaseValue: parseFloatCustom(purchaseValue?.value) }),
        ...(hasValue(soldForSlaughterQuantity?.value) && { soldForSlaughterQuantity: parseFloatCustom(soldForSlaughterQuantity?.value) }),
        ...(hasValue(soldForSlaughterValue?.value) && { soldForSlaughterValue: parseFloatCustom(soldForSlaughterValue?.value) }),
        ...(hasValue(soldForBreedingQuantity?.value) && { soldForBreedingQuantity: parseFloatCustom(soldForBreedingQuantity?.value) }),
        ...(hasValue(soldForBreedingValue?.value) && { soldForBreedingValue: parseFloatCustom(soldForBreedingValue?.value) }),
        ...(hasValue(soldForUnknownReasonQuantity?.value) && { soldForUnknownReasonQuantity: parseFloatCustom(soldForUnknownReasonQuantity?.value) }),
        ...(hasValue(soldForUnknownReasonValue?.value) && { soldForUnknownReasonValue: parseFloatCustom(soldForUnknownReasonValue?.value) }),
        ...(hasValue(saleQuantity?.value) && { saleQuantity: parseFloatCustom(saleQuantity?.value) }),
        ...(hasValue(saleValue?.value) && { saleValue: parseFloatCustom(saleValue?.value) }),
        ...(hasValue(ownConsumptionQuantity?.value) && { ownConsumptionQuantity: parseFloatCustom(ownConsumptionQuantity?.value) }),
        ...(hasValue(ownConsumptionValue?.value) && { ownConsumptionValue: parseFloatCustom(ownConsumptionValue?.value) }),
        ...(hasValue(spentOnProcessingQuantity?.value) && { spentOnProcessingQuantity: parseFloatCustom(spentOnProcessingQuantity?.value) }),
        ...(hasValue(spentOnProcessingValue?.value) && { spentOnProcessingValue: parseFloatCustom(spentOnProcessingValue?.value) }),
        ...(hasValue(paymentInProductsQuantity?.value) && { paymentInProductsQuantity: parseFloatCustom(paymentInProductsQuantity?.value) }),
        ...(hasValue(paymentInProductsValue?.value) && { paymentInProductsValue: parseFloatCustom(paymentInProductsValue?.value) }),
        ...(hasValue(deathQuantity?.value) && { deathQuantity: parseFloatCustom(deathQuantity?.value) }),
        ...(hasValue(deathValue?.value) && { deathValue: parseFloatCustom(deathValue?.value) }),
        ...(hasValue(inputQuantity?.value) && { inputQuantity: parseFloatCustom(inputQuantity?.value) }),
        ...(hasValue(outputQuantity?.value) && { outputQuantity: parseFloatCustom(outputQuantity?.value) }),
        ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity?.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue?.value) }),
      };

      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`other-owneds?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.otherLivestock') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add');
          }
        } catch (e) {
          handleApiErrors(e, 'add');
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`other-owneds/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.otherLivestock') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit');
          }
        } catch (e) {
          handleApiErrors(e, 'edit');
        }
      }
    }
  };

  /***** START DEPENDANT VALIDATION *****/
  const setOtherLivestockCategoryDependantFields = (other) => {
    if (other === 3) {
      setOffspringQuantity(prevState => ({ ...prevState, value: 0, disabled: true }));
      setOffspringValue(prevState => ({ ...prevState, value: 0, disabled: true }));
    } else {
      setOffspringQuantity(prevState => ({ ...prevState, disabled: !edit }));
      setOffspringValue(prevState => ({ ...prevState, disabled: !edit }));
    }

    if (other !== 3) {
      setInputQuantity(prevState => ({ ...prevState, value: 0, disabled: true }));
    } else {
      setInputQuantity(prevState => ({ ...prevState, disabled: !edit }));
    }

    if (other !== 5) {
      setOutputQuantity(prevState => ({ ...prevState, value: 0, disabled: true }));
    } else {
      setOutputQuantity(prevState => ({ ...prevState, disabled: !edit }));
    }

    if (other === 2) {
      setSoldForSlaughterQuantity(prevState => ({ ...prevState, value: 0, disabled: true }));
      setSoldForSlaughterValue(prevState => ({ ...prevState, value: 0, disabled: true }));
      setSoldForBreedingQuantity(prevState => ({ ...prevState, value: 0, disabled: true }));
      setSoldForBreedingValue(prevState => ({ ...prevState, value: 0, disabled: true }));
    } else {
      setSoldForSlaughterQuantity(prevState => ({ ...prevState, disabled: !edit }));
      setSoldForSlaughterValue(prevState => ({ ...prevState, disabled: !edit }));
      setSoldForBreedingQuantity(prevState => ({ ...prevState, disabled: !edit }));
      setSoldForBreedingValue(prevState => ({ ...prevState, disabled: !edit }));
    }
  };

  useEffect(() => {
    if (!isAudit) {
      const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

      const valQuantity = parseFloatCustom(returnNullVal(soldForBreedingQuantity?.value) +
        returnNullVal(soldForSlaughterQuantity?.value) +
        returnNullVal(soldForUnknownReasonQuantity?.value)).toFixed(2);

      const valValue = parseFloatCustom(returnNullVal(soldForBreedingValue?.value) +
        returnNullVal(soldForSlaughterValue?.value) +
        returnNullVal(soldForUnknownReasonValue?.value)).toFixed(2);

      setSaleQuantity({
        ...saleQuantity,
        value: parseFloat(valQuantity) >= 0 ? valQuantity : 0,
      });

      setSaleValue({
        ...saleValue,
        value: parseFloat(valValue) >= 0 ? valValue : 0,
      });
    }
  }, [soldForBreedingQuantity.value,
    soldForBreedingValue.value,
    soldForSlaughterQuantity.value,
    soldForSlaughterValue.value,
    soldForUnknownReasonQuantity.value,
    soldForUnknownReasonValue.value]);

  useEffect(() => {
    if (!isAudit) {
      const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;
      const val = parseFloatCustom(returnNullVal(openingQuantity?.value) +
        returnNullVal(offspringQuantity?.value) +
        returnNullVal(inputQuantity?.value) +
        returnNullVal(purchaseQuantity?.value) -
        returnNullVal(outputQuantity?.value) -
        returnNullVal(saleQuantity?.value) -
        returnNullVal(ownConsumptionQuantity?.value) -
        returnNullVal(spentOnProcessingQuantity?.value) -
        returnNullVal(paymentInProductsQuantity?.value) -
        returnNullVal(deathQuantity?.value)).toFixed(2);

      setClosingQuantity({
        ...closingQuantity,
        value: parseFloat(val) >= 0 ? val : 0,
      });
    }
  }, [openingQuantity.value,
    offspringQuantity.value,
    inputQuantity.value,
    purchaseQuantity.value,
    outputQuantity.value,
    saleQuantity.value,
    ownConsumptionQuantity.value,
    spentOnProcessingQuantity.value,
    paymentInProductsQuantity.value,
    deathQuantity.value,
  ]);

  /***** END DEPENDANT VALIDATION *****/


  const getOther = async () => {
    try {
      const { data } = await fetchSingleOther(props.match.params.id);
      setOther(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        getOther().then(other => {
          setOtherLivestockCategory(prevState => ({
            ...prevState,
            value: other?.otherLivestockCategory ? defaultOption(other?.otherLivestockCategory) : undefined,
          }));
          setOpeningQuantity({ ...openingQuantity, value: parseDouble(other?.openingQuantity) });
          setOpeningValue({ ...openingValue, value: parseDouble(other?.openingValue) });
          setComment(prevState => ({ ...prevState, value: other?.comment }));
          setOffspringQuantity(prevState => ({ ...prevState, value: parseDouble(other?.offspringQuantity) }));
          setOffspringValue(prevState => ({ ...prevState, value: parseDouble(other?.offspringValue) }));
          setPurchaseQuantity(prevState => ({ ...prevState, value: parseDouble(other?.purchaseQuantity) }));
          setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(other?.purchaseValue) }));
          setSoldForSlaughterQuantity(prevState => ({
            ...prevState,
            value: parseDouble(other?.soldForSlaughterQuantity),
          }));
          setSoldForSlaughterValue(prevState => ({ ...prevState, value: parseDouble(other?.soldForSlaughterValue) }));
          setSoldForBreedingQuantity(prevState => ({
            ...prevState,
            value: parseDouble(other?.soldForBreedingQuantity),
          }));
          setSoldForBreedingValue(prevState => ({ ...prevState, value: parseDouble(other?.soldForBreedingValue) }));
          setSoldForUnknownReasonQuantity(prevState => ({
            ...prevState,
            value: parseDouble(other?.soldForUnknownReasonQuantity),
          }));
          setSoldForUnknownReasonValue(prevState => ({
            ...prevState,
            value: parseDouble(other?.soldForUnknownReasonValue),
          }));
          setSaleQuantity(prevState => ({ ...prevState, value: parseDouble(other?.saleQuantity) }));
          setSaleValue(prevState => ({ ...prevState, value: parseDouble(other?.saleValue) }));
          setOwnConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(other?.ownConsumptionQuantity) }));
          setOwnConsumptionValue(prevState => ({ ...prevState, value: parseDouble(other?.ownConsumptionValue) }));
          setSpentOnProcessingQuantity(prevState => ({
            ...prevState,
            value: parseDouble(other?.spentOnProcessingQuantity),
          }));
          setSpentOnProcessingValue(prevState => ({ ...prevState, value: parseDouble(other?.spentOnProcessingValue) }));
          setPaymentInProductsQuantity(prevState => ({
            ...prevState,
            value: parseDouble(other?.paymentInProductsQuantity),
          }));
          setPaymentInProductsValue(prevState => ({ ...prevState, value: parseDouble(other?.paymentInProductsValue) }));
          setDeathQuantity(prevState => ({ ...prevState, value: parseDouble(other?.deathQuantity) }));
          setDeathValue(prevState => ({ ...prevState, value: parseDouble(other?.deathValue) }));
          setInputQuantity(prevState => ({ ...prevState, value: parseDouble(other?.inputQuantity) }));
          setOutputQuantity(prevState => ({ ...prevState, value: parseDouble(other?.outputQuantity) }));
          setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(other?.closingQuantity) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(other?.closingValue) }));

          setErrorExplanations(other?.errorExplanations ? other?.errorExplanations : []);

          let explanations = other?.errorExplanations ? other?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'otherOwned', other, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        setOtherLivestockCategory(prevState => ({
          ...prevState,
          value: data?.otherLivestockCategory ? defaultOption(data?.otherLivestockCategory) : undefined,
        }));
        setOpeningQuantity({ ...openingQuantity, value: parseDouble(data?.openingQuantity) });
        setOpeningValue({ ...openingValue, value: parseDouble(data?.openingValue) });
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setOffspringQuantity(prevState => ({ ...prevState, value: parseDouble(data?.offspringQuantity) }));
        setOffspringValue(prevState => ({ ...prevState, value: parseDouble(data?.offspringValue) }));
        setPurchaseQuantity(prevState => ({ ...prevState, value: parseDouble(data?.purchaseQuantity) }));
        setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(data?.purchaseValue) }));
        setSoldForSlaughterQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.soldForSlaughterQuantity),
        }));
        setSoldForSlaughterValue(prevState => ({ ...prevState, value: parseDouble(data?.soldForSlaughterValue) }));
        setSoldForBreedingQuantity(prevState => ({ ...prevState, value: parseDouble(data?.soldForBreedingQuantity) }));
        setSoldForBreedingValue(prevState => ({ ...prevState, value: parseDouble(data?.soldForBreedingValue) }));
        setSoldForUnknownReasonQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.soldForUnknownReasonQuantity),
        }));
        setSoldForUnknownReasonValue(prevState => ({
          ...prevState,
          value: parseDouble(data?.soldForUnknownReasonValue),
        }));
        setSaleQuantity(prevState => ({ ...prevState, value: parseDouble(data?.saleQuantity) }));
        setSaleValue(prevState => ({ ...prevState, value: parseDouble(data?.saleValue) }));
        setOwnConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.ownConsumptionQuantity) }));
        setOwnConsumptionValue(prevState => ({ ...prevState, value: parseDouble(data?.ownConsumptionValue) }));
        setSpentOnProcessingQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.spentOnProcessingQuantity),
        }));
        setSpentOnProcessingValue(prevState => ({ ...prevState, value: parseDouble(data?.spentOnProcessingValue) }));
        setPaymentInProductsQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.paymentInProductsQuantity),
        }));
        setPaymentInProductsValue(prevState => ({ ...prevState, value: parseDouble(data?.paymentInProductsValue) }));
        setDeathQuantity(prevState => ({ ...prevState, value: parseDouble(data?.deathQuantity) }));
        setDeathValue(prevState => ({ ...prevState, value: parseDouble(data?.deathValue) }));
        setInputQuantity(prevState => ({ ...prevState, value: parseDouble(data?.inputQuantity) }));
        setOutputQuantity(prevState => ({ ...prevState, value: parseDouble(data?.outputQuantity) }));
        setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(data?.closingQuantity) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));

        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'otherOwned', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);


  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setOtherLivestockCategory(prevState => ({ ...prevState, disabled: false }));
        setOpeningQuantity(prevState => ({ ...prevState, disabled: false }));
        setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setOffspringQuantity(prevState => ({ ...prevState, disabled: false }));
        setOffspringValue(prevState => ({ ...prevState, disabled: false }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: false }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: false }));
        setSoldForSlaughterQuantity(prevState => ({ ...prevState, disabled: false }));
        setSoldForSlaughterValue(prevState => ({ ...prevState, disabled: false }));
        setSoldForBreedingQuantity(prevState => ({ ...prevState, disabled: false }));
        setSoldForBreedingValue(prevState => ({ ...prevState, disabled: false }));
        setSoldForUnknownReasonQuantity(prevState => ({ ...prevState, disabled: false }));
        setSoldForUnknownReasonValue(prevState => ({ ...prevState, disabled: false }));
        setSaleQuantity(prevState => ({ ...prevState, disabled: true }));
        setSaleValue(prevState => ({ ...prevState, disabled: true }));
        setOwnConsumptionQuantity(prevState => ({ ...prevState, disabled: false }));
        setOwnConsumptionValue(prevState => ({ ...prevState, disabled: false }));
        setSpentOnProcessingQuantity(prevState => ({ ...prevState, disabled: false }));
        setSpentOnProcessingValue(prevState => ({ ...prevState, disabled: false }));
        setPaymentInProductsQuantity(prevState => ({ ...prevState, disabled: false }));
        setPaymentInProductsValue(prevState => ({ ...prevState, disabled: false }));
        setDeathQuantity(prevState => ({ ...prevState, disabled: false }));
        setDeathValue(prevState => ({ ...prevState, disabled: false }));
        setClosingQuantity(prevState => ({ ...prevState, disabled: true }));
        setClosingValue(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  useDidMountEffect(() => {
    if (!isAudit) {
      setOtherLivestockCategoryDependantFields(otherLivestockCategory?.value?.value);
    }
  }, [otherLivestockCategory?.value, edit]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm'/>
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectAsyncField
                    pagination={false}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    api='other-livestock-categories'
                    label={t('pages.other.categoryNameOther')}
                    placeholder={t('pages.other.placeholders.otherCategory')}
                    value={otherLivestockCategory.value}
                    name='otherLivestockCategory'
                    onChange={(e) => {
                      setShowErrors(true);
                      setOtherLivestockCategory(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={otherLivestockCategory.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherLivestockCategory']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.openingQuantity')}
                    placeholder={t('pages.other.placeholders.openingQuantity')}
                    value={openingQuantity.value}
                    name='openingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.openingValue')}
                    placeholder={t('pages.other.placeholders.openingValue')}
                    value={openingValue.value}
                    suffix='€'
                    name='openingValue'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.offspringQuantity')}
                    placeholder={t('pages.other.placeholders.offspringQuantity')}
                    value={offspringQuantity.value}
                    name='offspringQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOffspringQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={offspringQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['offspringQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.offspringValue')}
                    placeholder={t('pages.other.placeholders.offspringValue')}
                    value={offspringValue.value}
                    name='offspringValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOffspringValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={offspringValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['offspringValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.purchaseQuantity')}
                    placeholder={t('pages.other.placeholders.purchaseQuantity')}
                    value={purchaseQuantity.value}
                    name='purchaseQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.purchaseValue')}
                    placeholder={t('pages.other.placeholders.purchaseValue')}
                    value={purchaseValue.value}
                    name='purchaseValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.soldForSlaughterQuantity')}
                    placeholder={t('pages.other.placeholders.soldForSlaughterQuantity')}
                    value={soldForSlaughterQuantity.value}
                    name='soldForSlaughterQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setSoldForSlaughterQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldForSlaughterQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldForSlaughterQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.soldForSlaughterValue')}
                    placeholder={t('pages.other.placeholders.soldForSlaughterValue')}
                    value={soldForSlaughterValue.value}
                    name='soldForSlaughterValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSoldForSlaughterValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldForSlaughterValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldForSlaughterValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.soldForBreedingQuantity')}
                    placeholder={t('pages.other.placeholders.soldForBreedingQuantity')}
                    value={soldForBreedingQuantity.value}
                    name='soldForBreedingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setSoldForBreedingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldForBreedingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldForBreedingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.soldForBreedingValue')}
                    placeholder={t('pages.other.placeholders.soldForBreedingValue')}
                    value={soldForBreedingValue.value}
                    suffix='€'
                    name='soldForBreedingValue'
                    type={'double'}
                    onChange={(e) => {
                      setSoldForBreedingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldForBreedingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldForBreedingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.soldForUnknownReasonQuantity')}
                    placeholder={t('pages.other.placeholders.soldForUnknownReasonQuantity')}
                    value={soldForUnknownReasonQuantity.value}
                    name='soldForUnknownReasonQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setSoldForUnknownReasonQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldForUnknownReasonQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldForUnknownReasonQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.soldForUnknownReasonValue')}
                    placeholder={t('pages.other.placeholders.soldForUnknownReasonValue')}
                    value={soldForUnknownReasonValue.value}
                    name='soldForUnknownReasonValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSoldForUnknownReasonValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldForUnknownReasonValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldForUnknownReasonValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.saleQuantity')}
                    placeholder={t('pages.other.placeholders.saleQuantity')}
                    value={saleQuantity.value}
                    name='saleQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setSaleQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={saleQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['saleQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.saleValue')}
                    placeholder={t('pages.other.placeholders.saleValue')}
                    value={saleValue.value}
                    suffix='€'
                    name='saleValue'
                    type={'double'}
                    onChange={(e) => {
                      setSaleValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={saleValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['saleValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.ownConsumptionQuantity')}
                    placeholder={t('pages.other.placeholders.ownConsumptionQuantity')}
                    value={ownConsumptionQuantity.value}
                    name='ownConsumptionQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOwnConsumptionQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={ownConsumptionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['ownConsumptionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.ownConsumptionValue')}
                    placeholder={t('pages.other.placeholders.ownConsumptionValue')}
                    value={ownConsumptionValue.value}
                    suffix='€'
                    name='ownConsumptionValue'
                    type={'double'}
                    onChange={(e) => {
                      setOwnConsumptionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={ownConsumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['ownConsumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.spentOnProcessingQuantity')}
                    placeholder={t('pages.other.placeholders.spentOnProcessingQuantity')}
                    value={spentOnProcessingQuantity.value}
                    name='spentOnProcessingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setSpentOnProcessingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={spentOnProcessingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['spentOnProcessingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.spentOnProcessingValue')}
                    placeholder={t('pages.other.placeholders.spentOnProcessingValue')}
                    value={spentOnProcessingValue.value}
                    suffix='€'
                    name='spentOnProcessingValue'
                    type={'double'}
                    onChange={(e) => {
                      setSpentOnProcessingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={spentOnProcessingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['spentOnProcessingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.paymentInProductsQuantity')}
                    placeholder={t('pages.other.placeholders.paymentInProductsQuantity')}
                    value={paymentInProductsQuantity.value}
                    name='paymentInProductsQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setPaymentInProductsQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={paymentInProductsQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['paymentInProductsQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.paymentInProductsValue')}
                    placeholder={t('pages.other.placeholders.paymentInProductsValue')}
                    value={paymentInProductsValue.value}
                    suffix='€'
                    name='paymentInProductsValue'
                    type={'double'}
                    onChange={(e) => {
                      setPaymentInProductsValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={paymentInProductsValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['paymentInProductsValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.deathQuantity')}
                    placeholder={t('pages.other.placeholders.deathQuantity')}
                    value={deathQuantity.value}
                    name='deathQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setDeathQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={deathQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['deathQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.deathValue')}
                    placeholder={t('pages.other.placeholders.deathValue')}
                    value={deathValue.value}
                    suffix='€'
                    name='deathValue'
                    type={'double'}
                    onChange={(e) => {
                      setDeathValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={deathValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['deathValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.inputQuantity')}
                    placeholder={t('pages.other.placeholders.inputQuantity')}
                    value={inputQuantity.value}
                    name='inputQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setInputQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={inputQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['inputQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.outputQuantity')}
                    placeholder={t('pages.other.placeholders.outputQuantity')}
                    value={outputQuantity.value}
                    name='outputQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOutputQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={outputQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['outputQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.closingQuantity')}
                    placeholder={t('pages.other.placeholders.closingQuantity')}
                    value={closingQuantity.value}
                    name='closingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setClosingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.closingValue')}
                    placeholder={t('pages.other.placeholders.closingValue')}
                    value={closingValue.value}
                    suffix='€'
                    name='closingValue'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.other.comment')}
                    placeholder={t('pages.other.placeholders.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/livestock/other-livestock`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};

export default OtherLivestockBasicForm;
