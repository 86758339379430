import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SelectConstField from '../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getManufacturedProducts,
  getProductionCodes,
  getProductionManufacturedProducts,
  getProductionTypes,
} from '../../../../../services/production/manufacturedProducts/manufacturedProductsApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './ManufacturedProductsForm.module.scss';
import { Toast } from '../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import SelectAsyncField from '../../../../../components/formDynamic/selectField/SelectAsyncField';
import UserData from '../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import CheckboxField from '../../../../../components/formDynamic/checkboxField/CheckboxField';
import Loader from '../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const ManufacturedProductsForm = ({ props, data, isAudit = false }) => {

  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  /***** INITIAl VALIDATION *****/
  const validationInitial = {
    productionManufacturedProduct: { type: 'selectSingle', required: { value: true } },
    productionCode: { type: 'selectSingle', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    area: { type: 'number', required: { value: true } },
    irrigated: { type: 'number', required: { value: true } },
    openingQuantity: { type: 'number', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    otherManufacturedProduct: { type: 'string', required: { value: false }, min: { value: 3 } },
    otherManufacturedProductApproved: { type: 'checkbox', required: { value: false } },
    productionQuantity: { type: 'number', required: { value: false } },
    soldQuantity: { type: 'number', required: { value: false } },
    soldValue: { type: 'number', required: { value: false } },
    ownConsumptionQuantity: { type: 'number', required: { value: false } },
    ownConsumptionValue: { type: 'number', required: { value: false } },
    paymentInProductsQuantity: { type: 'number', required: { value: false } },
    paymentInProductsValue: { type: 'number', required: { value: false } },
    consumedForAHQuantity: { type: 'number', required: { value: false } },
    consumedForAHValue: { type: 'number', required: { value: false } },
    closingQuantity: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
  };

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [manufacturedProducts, setManufacturedProducts] = useState();

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  const [productionManufacturedProduct, setProductionManufacturedProduct] = useState({
    value: undefined,
    disabled: !edit,
  });
  const [productionCode, setProductionCode] = useState({ value: undefined, disabled: !edit });
  const [otherManufacturedProductApproved, setOtherManufacturedProductApproved] = useState({
    value: undefined,
    disabled: !edit ? true : !user.isAdmin,
  });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [area, setArea] = useState({ value: 0, disabled: !edit });
  const [irrigated, setIrrigated] = useState({ value: 0, disabled: !edit });
  const [openingQuantity, setOpeningQuantity] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [otherManufacturedProduct, setOtherManufacturedProduct] = useState({ value: undefined, disabled: !edit });
  const [productionQuantity, setProductionQuantity] = useState({ value: 0, disabled: !edit });
  const [soldQuantity, setSoldQuantity] = useState({ value: 0, disabled: !edit });
  const [soldValue, setSoldValue] = useState({ value: 0, disabled: !edit });
  const [ownConsumptionQuantity, setOwnConsumptionQuantity] = useState({ value: 0, disabled: !edit });
  const [ownConsumptionValue, setOwnConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [paymentInProductsQuantity, setPaymentInProductsQuantity] = useState({ value: 0, disabled: !edit });
  const [paymentInProductsValue, setPaymentInProductsValue] = useState({ value: 0, disabled: !edit });
  const [consumedForAHQuantity, setConsumedForAHQuantity] = useState({ value: 0, disabled: !edit });
  const [consumedForAHValue, setConsumedForAHValue] = useState({ value: 0, disabled: !edit });
  const [closingQuantity, setClosingQuantity] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  // Enums
  const [productionManufacturedProductOptionsList, setProductionManufacturedProductOptionsList] = useState([]);
  const [productionTypeOptionsList, setProductionTypeOptionsList] = useState([]);
  const [productionCodeList, setProductionCodeList] = useState([]);
  const [productionCodeListConst, setProductionCodeListConst] = useState([]);

  const productId = (id) => {
    if ([1, 4, 5, 6].includes(id)) {
      return [10, 11];
    } else if ([7].includes(id)) {
      return [10, 11, 12, 13, 14];
    } else return [];
  };
  const filterProductionCode = (list, id) => {
    const listIds = productId(id);
    return listIds?.length > 0
      ? list.filter(item => listIds.includes(item.value))
      : list;
  };
  /***** START DEPENDANT VALIDATION *****/
    // Validation fields for resetting api errors

  const changeManufacturedProduct = (e) => {
      if ([2, 3, 8, 9, 10, 11].includes(e)) {
        setProductionCode(prevState => ({ ...prevState, value: null, disabled: true }));
        setArea(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setIrrigated(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setProductionCode(prevState => ({ ...prevState, disabled: !edit })); //reset
        setArea(prevState => ({ ...prevState, disabled: !edit }));
        setIrrigated(prevState => ({ ...prevState, disabled: !edit }));

      }
      if (e !== 7) {
        setOtherManufacturedProduct(prevState => ({ ...prevState, disabled: true, value: undefined }));
        setOtherManufacturedProductApproved(prevState => ({ ...prevState, disabled: true, value: undefined }));
      } else {
        setOtherManufacturedProduct(prevState => ({ ...prevState, disabled: !edit }));
        setOtherManufacturedProductApproved(prevState => ({ ...prevState, disabled: !edit }));
      }
      if (![1, 2, 3, 4, 5, 6, 7, 11, 12].includes(e)) {
        setOwnConsumptionQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setOwnConsumptionValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setOwnConsumptionQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setOwnConsumptionValue(prevState => ({ ...prevState, disabled: !edit }));
      }
      if (![8, 9, 10].includes(e)) {
        setConsumedForAHValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumedForAHQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setConsumedForAHQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setConsumedForAHValue(prevState => ({ ...prevState, disabled: !edit }));
      }
    };

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** END DEPENDANT VALIDATION *****/
  useEffect(() => {
    if (!isAudit) {
      const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

      const val = parseFloatCustom(returnNullVal(openingQuantity?.value) +
        returnNullVal(productionQuantity?.value) -
        returnNullVal(soldQuantity?.value) -
        returnNullVal(ownConsumptionQuantity?.value) -
        returnNullVal(paymentInProductsQuantity?.value) -
        returnNullVal(consumedForAHQuantity?.value)).toFixed(2);

      setClosingQuantity({
        ...closingQuantity,
        value: parseFloat(val) >= 0 ? val : 0,
      });
    }
  }, [openingQuantity.value,
    productionQuantity.value,
    soldQuantity.value,
    ownConsumptionQuantity.value,
    paymentInProductsQuantity.value,
    consumedForAHQuantity.value]);
  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'manufacturedProduct', manufacturedProducts, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(productionManufacturedProduct.value?.value && {
          productionManufacturedProduct: {
            id: parseInt(productionManufacturedProduct.value?.value),
          },
        }),
        ...(productionCode.value && {
          productionCode: {
            id: parseInt(productionCode.value?.value),
          },
        }),
        ...(comment?.value && { comment: comment.value }),
        otherManufacturedProductApproved: otherManufacturedProductApproved?.disabled ? null : hasValue(otherManufacturedProductApproved?.value) && otherManufacturedProductApproved?.value[0] === 'true',
        ...(otherManufacturedProduct?.value && { otherManufacturedProduct: otherManufacturedProduct.value }),
        ...(hasValue(area?.value) && { area: parseFloatCustom(area.value) }),
        ...(hasValue(irrigated?.value) && { irrigated: parseFloatCustom(irrigated.value) }),
        ...(hasValue(openingQuantity?.value) && { openingQuantity: parseFloatCustom(openingQuantity.value) }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(hasValue(productionQuantity?.value) && { productionQuantity: parseFloatCustom(productionQuantity.value) }),
        ...(hasValue(soldQuantity?.value) && { soldQuantity: parseFloatCustom(soldQuantity.value) }),
        ...(hasValue(soldValue?.value) && { soldValue: parseFloatCustom(soldValue.value) }),
        ...(hasValue(ownConsumptionQuantity?.value) && { ownConsumptionQuantity: parseFloatCustom(ownConsumptionQuantity.value) }),
        ...(hasValue(ownConsumptionValue?.value) && { ownConsumptionValue: parseFloatCustom(ownConsumptionValue.value) }),
        ...(hasValue(paymentInProductsQuantity?.value) && { paymentInProductsQuantity: parseFloatCustom(paymentInProductsQuantity.value) }),
        ...(hasValue(paymentInProductsValue?.value) && { paymentInProductsValue: parseFloatCustom(paymentInProductsValue.value) }),
        ...(hasValue(consumedForAHQuantity?.value) && { consumedForAHQuantity: parseFloatCustom(consumedForAHQuantity.value) }),
        ...(hasValue(consumedForAHValue?.value) && { consumedForAHValue: parseFloatCustom(consumedForAHValue.value) }),
        ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),

      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`manufactured-products?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.manufacturedProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`manufactured-products/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.manufacturedProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchManufacturedProduct = async () => {
    try {
      const { data } = await getManufacturedProducts(props.match.params.id);
      setManufacturedProducts(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };

  const fetchProductionManufacturedProducts = async () => {
    try {
      const { data } = await getProductionManufacturedProducts();
      let productionManufacturedProductsTemp = [];
      for (let productionManufacturedProduct of data) {
        productionManufacturedProductsTemp.push(
          defaultOption(productionManufacturedProduct),
        );
      }
      setProductionManufacturedProductOptionsList([...productionManufacturedProductsTemp]);
    } catch (e) {
    }
  };

  const fetchProductionCodes = async () => {
    try {
      const { data } = await getProductionCodes();
      let productionCodesTemp = [];
      for (let productionCode of data) {
        productionCodesTemp.push(
          defaultOption(productionCode),
        );
      }
      setProductionCodeListConst([...productionCodesTemp]);
      setProductionCodeList([...productionCodesTemp]);
    } catch (e) {
    }
  };


  const fetchProductionTypes = async () => {
    try {
      const { data } = await getProductionTypes();
      let productionTypesTemp = [];
      for (let productionType of data) {
        productionTypesTemp.push(
          defaultOption(productionType),
        );
      }
      setProductionTypeOptionsList([...productionTypesTemp]);
    } catch (e) {
    }
  };

  useEffect(() => {
    if (!isAudit) {
      setProductionCodeList(filterProductionCode(productionCodeListConst, productionManufacturedProduct?.value?.value));
    }
  }, [productionManufacturedProduct?.value?.value]);


  useEffect(() => {
    fetchProductionManufacturedProducts();
    fetchProductionCodes();
    fetchProductionTypes();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchManufacturedProduct().then(manufacturedProducts => {
          setProductionManufacturedProduct(prevState => ({
            ...prevState,
            value: manufacturedProducts?.productionManufacturedProduct ? defaultOption(manufacturedProducts?.productionManufacturedProduct) : undefined,
          }));
          setProductionCode(prevState => ({
            ...prevState,
            value: manufacturedProducts?.productionCode ? defaultOption(manufacturedProducts?.productionCode) : undefined,
          }));
          setComment(prevState => ({ ...prevState, value: manufacturedProducts?.comment }));
          setArea(prevState => ({ ...prevState, value: parseDouble(manufacturedProducts?.area) }));
          setIrrigated(prevState => ({ ...prevState, value: parseDouble(manufacturedProducts?.irrigated) }));
          setOpeningQuantity(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.openingQuantity),
          }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(manufacturedProducts?.openingValue) }));
          setProductionQuantity(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.productionQuantity),
          }));
          setOtherManufacturedProductApproved(prevState => ({
            ...prevState,
            value: manufacturedProducts?.otherManufacturedProductApproved ? [manufacturedProducts?.otherManufacturedProductApproved ? 'true' : 'false'] : [],
          }));
          setOtherManufacturedProduct(prevState => ({
            ...prevState,
            value: manufacturedProducts?.otherManufacturedProduct,
          }));
          setSoldQuantity(prevState => ({ ...prevState, value: parseDouble(manufacturedProducts?.soldQuantity) }));
          setSoldValue(prevState => ({ ...prevState, value: parseDouble(manufacturedProducts?.soldValue) }));
          setOwnConsumptionQuantity(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.ownConsumptionQuantity),
          }));
          setOwnConsumptionValue(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.ownConsumptionValue),
          }));
          setPaymentInProductsQuantity(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.paymentInProductsQuantity),
          }));
          setPaymentInProductsValue(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.paymentInProductsValue),
          }));
          setConsumedForAHQuantity(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.consumedForAHQuantity),
          }));
          setConsumedForAHValue(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.consumedForAHValue),
          }));
          setClosingQuantity(prevState => ({
            ...prevState,
            value: parseDouble(manufacturedProducts?.closingQuantity),
          }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(manufacturedProducts?.closingValue) }));
          setErrorExplanations(manufacturedProducts?.errorExplanations ? manufacturedProducts?.errorExplanations : []);

          let explanations = manufacturedProducts?.errorExplanations ? manufacturedProducts?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'manufacturedProduct', manufacturedProducts, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        setProductionManufacturedProduct(prevState => ({
          ...prevState,
          value: data?.productionManufacturedProduct ? defaultOption(data?.productionManufacturedProduct) : undefined,
        }));
        setProductionCode(prevState => ({
          ...prevState,
          value: data?.productionCode ? defaultOption(data?.productionCode) : undefined,
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setArea(prevState => ({ ...prevState, value: parseDouble(data?.area) }));
        setIrrigated(prevState => ({ ...prevState, value: parseDouble(data?.irrigated) }));
        setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(data?.openingQuantity) }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setProductionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.productionQuantity) }));
        setOtherManufacturedProductApproved(prevState => ({
          ...prevState,
          value: data?.otherManufacturedProductApproved ? [data?.otherManufacturedProductApproved ? 'true' : 'false'] : [],
        }));
        setOtherManufacturedProduct(prevState => ({
          ...prevState,
          value: data?.otherManufacturedProduct,
        }));
        setSoldQuantity(prevState => ({ ...prevState, value: parseDouble(data?.soldQuantity) }));
        setSoldValue(prevState => ({ ...prevState, value: parseDouble(data?.soldValue) }));
        setOwnConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.ownConsumptionQuantity) }));
        setOwnConsumptionValue(prevState => ({ ...prevState, value: parseDouble(data?.ownConsumptionValue) }));
        setPaymentInProductsQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.paymentInProductsQuantity),
        }));
        setPaymentInProductsValue(prevState => ({ ...prevState, value: parseDouble(data?.paymentInProductsValue) }));
        setConsumedForAHQuantity(prevState => ({
          ...prevState,
          value: parseDouble(data?.consumedForAHQuantity),
        }));
        setConsumedForAHValue(prevState => ({
          ...prevState,
          value: parseDouble(data?.consumedForAHValue),
        }));
        setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(data?.closingQuantity) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'manufacturedProduct', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setProductionManufacturedProduct(prevState => ({ ...prevState, disabled: false }));
        setProductionCode(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setArea(prevState => ({ ...prevState, disabled: false }));
        setIrrigated(prevState => ({ ...prevState, disabled: false }));
        setOpeningQuantity(prevState => ({ ...prevState, disabled: false }));
        setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        setProductionQuantity(prevState => ({ ...prevState, disabled: false }));
        setOtherManufacturedProductApproved(prevState => ({ ...prevState, disabled: !edit ? true : !user.isAdmin }));
        setOtherManufacturedProduct(prevState => ({ ...prevState, disabled: false }));
        setSoldQuantity(prevState => ({ ...prevState, disabled: false }));
        setSoldValue(prevState => ({ ...prevState, disabled: false }));
        setOwnConsumptionQuantity(prevState => ({ ...prevState, disabled: false }));
        setOwnConsumptionValue(prevState => ({ ...prevState, disabled: false }));
        setPaymentInProductsQuantity(prevState => ({ ...prevState, disabled: false }));
        setPaymentInProductsValue(prevState => ({ ...prevState, disabled: false }));
        setConsumedForAHQuantity(prevState => ({ ...prevState, disabled: false }));
        setConsumedForAHValue(prevState => ({ ...prevState, disabled: false }));
        // setClosingQuantity(prevState => ({...prevState, disabled: false}));
        setClosingValue(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  useDidMountEffect(() => {
    if (!isAudit) {
      changeManufacturedProduct(productionManufacturedProduct?.value?.value);
    }
  }, [productionManufacturedProduct?.value?.value, edit]);

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectAsyncField
                    pagination={false}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    api='production-manufactured-products'
                    label={t('pages.manufacturedProducts.productionManufacturedProduct')}
                    placeholder={t('pages.manufacturedProducts.placeholders.productionManufacturedProduct')}
                    value={productionManufacturedProduct.value}
                    name='productionManufacturedProduct'
                    onChange={(e) => {
                      setShowErrors(true);
                      // changePlantProduct(e);
                      setProductionManufacturedProduct(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={productionManufacturedProduct.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionManufacturedProduct']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.productionCode')}
                    label={t('pages.manufacturedProducts.productionCode')}
                    value={productionCode.value}
                    name='productionCode'
                    onChange={(e) => {
                      setProductionCode(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                      setShowErrors(true);
                    }}
                    options={productionCodeList}
                    disabled={productionCode.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionCode']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.area')}
                    label={t('pages.manufacturedProducts.area')}
                    value={area.value}
                    name='area'
                    type={'double'}
                    suffix='ha'
                    onChange={(e) => {
                      setArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={area.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['area']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.irrigated')}
                    label={t('pages.manufacturedProducts.irrigated')}
                    value={irrigated.value}
                    name='irrigated'
                    type={'double'}
                    suffix='ha'
                    onChange={(e) => {
                      setIrrigated(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={irrigated.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['irrigated']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.otherManufacturedProduct')}
                    label={t('pages.manufacturedProducts.otherManufacturedProduct')}
                    value={otherManufacturedProduct.value}
                    name='otherManufacturedProduct'
                    type={'text'}
                    onChange={(e) => {
                      setOtherManufacturedProduct(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={otherManufacturedProduct.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherManufacturedProduct']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.otherManufacturedProductApproved')}
                    label={t('pages.manufacturedProducts.otherManufacturedProductApproved')}
                    name='otherManufacturedProductApproved'
                    options={[{ value: 'true', label: '' }]}
                    value={otherManufacturedProductApproved?.value}
                    onChange={(e) => {
                      setOtherManufacturedProductApproved(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={otherManufacturedProductApproved?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherManufacturedProductApproved']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.openingQuantity')}
                    label={t('pages.manufacturedProducts.openingQuantity')}
                    value={openingQuantity.value}
                    name='openingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.openingValue')}
                    label={t('pages.manufacturedProducts.openingValue')}
                    value={openingValue.value}
                    name='openingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.productionQuantity')}
                    label={t('pages.manufacturedProducts.productionQuantity')}
                    value={productionQuantity.value}
                    name='productionQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setProductionQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={productionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.soldQuantity')}
                    label={t('pages.manufacturedProducts.soldQuantity')}
                    value={soldQuantity.value}
                    name='soldQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setSoldQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.soldValue')}
                    label={t('pages.manufacturedProducts.soldValue')}
                    value={soldValue.value}
                    suffix='€'
                    name='soldValue'
                    type={'double'}
                    onChange={(e) => {
                      setSoldValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.ownConsumptionQuantity')}
                    label={t('pages.manufacturedProducts.ownConsumptionQuantity')}
                    value={ownConsumptionQuantity.value}
                    name='ownConsumptionQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOwnConsumptionQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={ownConsumptionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['ownConsumptionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.ownConsumptionValue')}
                    label={t('pages.manufacturedProducts.ownConsumptionValue')}
                    value={ownConsumptionValue.value}
                    name='ownConsumptionValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOwnConsumptionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={ownConsumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['ownConsumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.paymentInProductsQuantity')}
                    label={t('pages.manufacturedProducts.paymentInProductsQuantity')}
                    value={paymentInProductsQuantity.value}
                    name='paymentInProductsQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setPaymentInProductsQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={paymentInProductsQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['paymentInProductsQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.paymentInProductsValue')}
                    label={t('pages.manufacturedProducts.paymentInProductsValue')}
                    value={paymentInProductsValue.value}
                    name='paymentInProductsValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setPaymentInProductsValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={paymentInProductsValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['paymentInProductsValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.consumedForAHQuantity')}
                    label={t('pages.manufacturedProducts.consumedForAHQuantity')}
                    value={consumedForAHQuantity.value}
                    name='consumedForAHQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setConsumedForAHQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForAHQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForAHQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.consumedForAHValue')}
                    label={t('pages.manufacturedProducts.consumedForAHValue')}
                    value={consumedForAHValue.value}
                    suffix='€'
                    name='consumedForAHValue'
                    type={'double'}
                    groupField
                    onChange={(e) => {
                      setConsumedForAHValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumedForAHValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumedForAHValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.closingQuantity')}
                    label={t('pages.manufacturedProducts.closingQuantity')}
                    value={closingQuantity.value}
                    name='closingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue({
                        ...closingValue,
                        value: e,
                      });
                      setValidation({
                        ...validation,
                        ['closingValue']: { ...validation['closingValue'], error: { value: false } },
                      });
                    }}
                    disabled={closingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.closingValue')}
                    label={t('pages.manufacturedProducts.closingValue')}
                    value={closingValue.value}
                    name='closingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.manufacturedProducts.placeholders.comment')}
                    label={t('pages.manufacturedProducts.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/production/manufactured-products`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};

export default ManufacturedProductsForm;
