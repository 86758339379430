export const routes = [
  'labour',
  'agriculturalLand',
  'permanentCrop',
  'farmBuilding',
  'machineryEquipment',
  'cattleOwned',
  'pigOwned',
  'sheepOwned',
  'goatOwned',
  'poultryOwned',
  'otherOwned',
  'cattleOwnedFattening',
  'pigOwnedFattening',
  'sheepOwnedFattening',
  'goatOwnedFattening',
  'poultryOwnedFattening',
  'otherServiceFattening',
  'plantProduct',
  'manufacturedProduct',
  'animalProduct',
  'otherProduct',
  'unfinishedProduct',
  'plantProductionExpense',
  'animalProductionExpense',
  'processingExpense',
  'labourMachineryExpense',
  'landBuildingExpense',
  'overheadExpense',
  'insuranceExpense',
  'taxExpense',
  'loan',
  'leasing',
  'moneyFlow',
  'otherIncome',
];

export const makeLink = (data) => {
  if (data?.objectName === 'agroHolding') {
    return `/administration/agricultural-holdings/${data?.agroHoldingId}`;
  } else if (data?.objectName === 'agriculturalLand') {
    return `/agricultural-holding/${data?.agroHoldingId}/long-term-assets/agricultural-lands/${data?.entityId}`;
  } else if (data?.objectName === 'permanentCrop') {
    return `/agricultural-holding/${data?.agroHoldingId}/long-term-assets/permanent-crops/${data?.entityId}`;
  } else if (data?.objectName === 'farmBuilding') {
    return `/agricultural-holding/${data?.agroHoldingId}/long-term-assets/farm-buildings/${data?.entityId}`;
  } else if (data?.objectName === 'machineryEquipment') {
    return `/agricultural-holding/${data?.agroHoldingId}/long-term-assets/machinery-and-equipment/${data?.entityId}`;
  } else if (data?.objectName === 'cattleOwned') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/cattle/${data?.entityId}`;
  } else if (data?.objectName === 'pigOwned') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/pigs/${data?.entityId}`;
  } else if (data?.objectName === 'sheepOwned') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/sheep/${data?.entityId}`;
  } else if (data?.objectName === 'goatOwned') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/sheep/${data?.entityId}`;
  } else if (data?.objectName === 'poultryOwned') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/poultry/${data?.entityId}`;
  } else if (data?.objectName === 'otherOwned') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/other-livestock/${data?.entityId}`;
  } else if (data?.objectName === 'cattleServiceFattening') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/cattle-fattening/${data?.entityId}`;
  } else if (data?.objectName === 'pigServiceFattening') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/pigs-fattening/${data?.entityId}`;
  } else if (data?.objectName === 'sheepServiceFattening') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/sheep-fattening/${data?.entityId}`;
  } else if (data?.objectName === 'goatServiceFattening') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/goats-fattening/${data?.entityId}`;
  } else if (data?.objectName === 'poultryServiceFattening') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/poultry-fattening/${data?.entityId}`;
  } else if (data?.objectName === 'otherServiceFattening') {
    return `/agricultural-holding/${data?.agroHoldingId}/livestock/other-livestock-fattening/${data?.entityId}`;
  } else if (data?.objectName === 'plantProduct') {
    return `/agricultural-holding/${data?.agroHoldingId}/production/plant-products/${data?.entityId}`;
  } else if (data?.objectName === 'manufacturedProduct') {
    return `/agricultural-holding/${data?.agroHoldingId}/production/manufactured-products/${data?.entityId}`;
  } else if (data?.objectName === 'animalProduct') {
    return `/agricultural-holding/${data?.agroHoldingId}/production/animal-products/${data?.entityId}`;
  } else if (data?.objectName === 'otherProduct') {
    return `/agricultural-holding/${data?.agroHoldingId}/production/other-products/${data?.entityId}`;
  } else if (data?.objectName === 'unfinishedProduct') {
    return `/agricultural-holding/${data?.agroHoldingId}/production/unfinished-products/${data?.entityId}`;
  } else if (data?.objectName === 'plantProductionExpense') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/expenses/plant-production-expenses/${data?.entityId}`;
  } else if (data?.objectName === 'animalProductionExpense') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/expenses/animal-production-expenses/${data?.entityId}`;
  } else if (data?.objectName === 'processingExpense') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/expenses/manufacturer-processing/${data?.entityId}`;
  } else if (data?.objectName === 'labourMachineryExpense') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/expenses/labour-and-machinery/${data?.entityId}`;
  } else if (data?.objectName === 'landBuildingExpense') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/expenses/land-and-building/${data?.entityId}`;
  } else if (data?.objectName === 'overheadExpense') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/expenses/overhead/${data?.entityId}`;
  } else if (data?.objectName === 'insuranceExpense') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/expenses/insurance/${data?.entityId}`;
  } else if (data?.objectName === 'taxExpense') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/expenses/tax/${data?.entityId}`;
  } else if (data?.objectName === 'moneyFlow') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/money-flow/${data?.entityId}`;
  } else if (data?.objectName === 'otherIncome') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/other-income/${data?.entityId}`;
  } else if (data?.objectName === 'loan') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/loans/loan/${data?.entityId}`;
  } else if (data?.objectName === 'leasing') {
    return `/agricultural-holding/${data?.agroHoldingId}/money/loans/leasing/${data?.entityId}`;
  } else {
    return `/agricultural-holding/${data?.agroHoldingId}/${data?.objectName}/${data?.entityId}`;
  }
};