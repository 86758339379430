import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { t } from 'react-switch-lang';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import CheckboxField from '../../../../../components/formDynamic/checkboxField/CheckboxField';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './AgriculturalLandForm.module.scss';
import {
  fetchLandCategories,
  fetchSingleAgriculturalLand,
} from '../../../../../services/agriculturalLand/agriculturalLandApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Toast } from '../../../../../utils/sweetAlert';
import UserData from '../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import SelectAsyncField from '../../../../../components/formDynamic/selectField/SelectAsyncField';
import Loader from '../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const AgriculturalLandForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    landCategory: { type: 'selectSingle', required: { value: true } },
    openingArea: { type: 'number', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    investmentValue: {
      type: 'number',
      required: { value: false },
      warning: { value: true, messages: t('pages.agriculturalLand.warnings.investmentValue') },
    },
    purchasedArea: { type: 'number', required: { value: false } },
    purchasedValue: { type: 'number', required: { value: false } },
    soldArea: { type: 'number', required: { value: false } },
    soldValue: { type: 'number', required: { value: false } },
    subsidiesValue: { type: 'number', required: { value: false } },
    closingArea: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
    rentedArea: { type: 'number', required: { value: false } },
    sharecroppedArea: { type: 'number', required: { value: false } },
    usedPublicLandArea: { type: 'number', required: { value: false } },
    usedPublicLandDays: { type: 'number', required: { value: false }, max: { value: 365 } },
    rentIsPayed: { type: 'checkbox', required: { value: false } },
  };
  const [agriculturalLand, setAgriculturalLand] = useState();
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const [landCategory, setLandCategory] = useState({ value: undefined, disabled: !edit });
  const [openingArea, setOpeningArea] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [investmentValue, setInvestmentValue] = useState({ value: 0, disabled: !edit });
  const [purchasedArea, setPurchasedArea] = useState({ value: 0, disabled: !edit });
  const [purchasedValue, setPurchasedValue] = useState({ value: 0, disabled: !edit });
  const [soldArea, setSoldArea] = useState({ value: 0, disabled: !edit });
  const [soldValue, setSoldValue] = useState({ value: 0, disabled: !edit });
  const [subsidiesValue, setSubsidiesValue] = useState({ value: 0, disabled: !edit });
  const [closingArea, setClosingArea] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: true });
  const [rentedArea, setRentedArea] = useState({ value: 0, disabled: !edit });
  const [sharecroppedArea, setSharecroppedArea] = useState({ value: 0, disabled: !edit });
  const [usedPublicLandArea, setUsedPublicLandArea] = useState({ value: 0, disabled: !edit });
  const [usedPublicLandDays, setUsedPublicLandDays] = useState({ value: 0, disabled: !edit });
  const [rentIsPayed, setRentIsPaid] = useState({ value: undefined, disabled: !edit });

  const [errorExplanations, setErrorExplanations] = useState([]);

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [validation, setValidation] = useState({ ...validationInitial });

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'agriculturalLand', agriculturalLand, ahid, agriculturalData, setErrorExplanations, setValidation);
  };

  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        errorExplanations: clearEmptyExplanations(errorExplanations),
        ...(landCategory?.value?.value && { landCategory: { id: parseInt(landCategory?.value?.value) } }),
        ...(hasValue(openingArea?.value) && { openingArea: parseFloatCustom(openingArea?.value) }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue?.value) }),
        ...(comment?.value && { comment: comment?.value }),
        ...(hasValue(investmentValue?.value) && { investmentValue: parseFloatCustom(investmentValue?.value) }),
        ...(hasValue(purchasedArea?.value) && { purchasedArea: parseFloatCustom(purchasedArea?.value) }),
        ...(hasValue(purchasedValue?.value) && { purchasedValue: parseFloatCustom(purchasedValue?.value) }),
        ...(hasValue(soldArea?.value) && { soldArea: parseFloatCustom(soldArea?.value) }),
        ...(hasValue(soldValue?.value) && { soldValue: parseFloatCustom(soldValue?.value) }),
        ...(hasValue(subsidiesValue?.value) && { subsidiesValue: parseFloatCustom(subsidiesValue?.value) }),
        ...(hasValue(closingArea?.value) && { closingArea: parseFloatCustom(closingArea?.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue?.value) }),
        ...(hasValue(rentedArea?.value) && { rentedArea: parseFloatCustom(rentedArea?.value) }),
        ...(hasValue(sharecroppedArea?.value) && { sharecroppedArea: parseFloatCustom(sharecroppedArea?.value) }),
        ...(hasValue(usedPublicLandArea?.value) && { usedPublicLandArea: parseFloatCustom(usedPublicLandArea?.value) }),
        ...(hasValue(usedPublicLandDays?.value) && { usedPublicLandDays: parseInt(usedPublicLandDays?.value) }),
        ...({ rentIsPayed: Boolean(rentIsPayed?.value && rentIsPayed?.value[0] === 'true') }),
      };

      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`agricultural-lands?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.agriculturalLand') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add');
          }
        } catch (e) {
          handleApiErrors(e, 'add');
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`agricultural-lands/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.agriculturalLand') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit');
          }
        } catch (e) {
          handleApiErrors(e, 'edit');
        }
      }
    }
  };

  // const prevVal = usePrevious(submitDisabled);
  //
  // useDidMountEffect(() => {
  //   (!compareObjects(submitDisabled, prevVal) && showErrors) && handleSubmit(false);
  // }, [submitDisabled]);


  const getAgriculturalLand = async (id) => {
    try {
      const { data } = await fetchSingleAgriculturalLand(props.match.params.id);
      setAgriculturalLand(data);
      return data;
    } catch (e) {

    }
  };
  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };
  const getLandCategories = async (setLandCategoriesOptions, lang) => {
    try {
      const { data } = await fetchLandCategories();
      let landCategoriesTemp = [];
      for (let landCategory of data) {
        landCategoriesTemp.push(defaultOption(landCategory, lang));
      }
      setLandCategoriesOptions([...landCategoriesTemp]);
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };
  /***** START DEPENDANT VALIDATION *****/

  const changeAgriculturalLand = (e) => {
    if ([4, 5].includes(e?.value)) {
      setSharecroppedArea(prevState => ({ ...prevState, disabled: true, value: 0 }));
    } else {
      setSharecroppedArea(prevState => ({ ...prevState, disabled: !edit }));
    }
    if (e?.value !== 4) {
      setUsedPublicLandArea(prevState => ({ ...prevState, disabled: true, value: 0 }));
      setUsedPublicLandDays(prevState => ({ ...prevState, disabled: true, value: 0 }));
    } else {
      setUsedPublicLandArea(prevState => ({ ...prevState, disabled: !edit }));
      setUsedPublicLandDays(prevState => ({ ...prevState, disabled: !edit }));
    }
  };

  const calculateClosingArea = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(returnNullVal(openingArea.value)
      + returnNullVal(purchasedArea.value)
      - returnNullVal(soldArea.value)).toFixed(2);

    setClosingArea(prevState => ({
      ...prevState,
      disabled: true,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };

  const calculateClosingValue = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(
      returnNullVal(openingValue.value)
      + returnNullVal(purchasedValue.value)
      + returnNullVal(investmentValue.value)
      - returnNullVal(soldValue.value)).toFixed(2);

    setClosingValue(prevState => ({
      ...prevState,
      disabled: true,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };

  /***** END DEPENDANT VALIDATION *****/
  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        getAgriculturalLand().then(agriculturalLands => {
          setLandCategory(prevState => ({
            ...prevState,
            value: agriculturalLands?.landCategory ? defaultOption(agriculturalLands?.landCategory) : undefined,
          }));
          setOpeningArea(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.openingArea) }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.openingValue) }));
          setComment(prevState => ({ ...prevState, value: agriculturalLands?.comment }));
          setInvestmentValue(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.investmentValue) }));
          setPurchasedArea(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.purchasedArea) }));
          setPurchasedValue(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.purchasedValue) }));
          setSoldArea(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.soldArea) }));
          setSoldValue(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.soldValue) }));
          setSubsidiesValue(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.subsidiesValue) }));
          setRentedArea(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.rentedArea) }));
          setRentIsPaid(prevState => ({
            ...prevState,
            value: agriculturalLands?.rentIsPayed ? [agriculturalLands?.rentIsPayed ? 'true' : 'false'] : [],
          }));
          setClosingArea(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.closingArea) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.closingValue) }));
          setSharecroppedArea(prevState => ({ ...prevState, value: parseDouble(agriculturalLands?.sharecroppedArea) }));
          setUsedPublicLandArea(prevState => ({
            ...prevState,
            value: parseDouble(agriculturalLands?.usedPublicLandArea),
          }));
          setUsedPublicLandDays(prevState => ({ ...prevState, value: agriculturalLands?.usedPublicLandDays }));
          setErrorExplanations(agriculturalLands?.errorExplanations ? agriculturalLands?.errorExplanations : []);

          let explanations = agriculturalLands?.errorExplanations ? agriculturalLands?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'agriculturalLand', agriculturalLand, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        if (!_.isEmpty(data)) {
          setLandCategory(prevState => ({
            ...prevState,
            value: data?.landCategory ? defaultOption(data?.landCategory) : undefined,
          }));
          setOpeningArea(prevState => ({ ...prevState, value: parseDouble(data?.openingArea) }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
          setComment(prevState => ({ ...prevState, value: data?.comment }));
          setInvestmentValue(prevState => ({ ...prevState, value: parseDouble(data?.investmentValue) }));
          setPurchasedArea(prevState => ({ ...prevState, value: parseDouble(data?.purchasedArea) }));
          setPurchasedValue(prevState => ({ ...prevState, value: parseDouble(data?.purchasedValue) }));
          setSoldArea(prevState => ({ ...prevState, value: parseDouble(data?.soldArea) }));
          setSoldValue(prevState => ({ ...prevState, value: parseDouble(data?.soldValue) }));
          setSubsidiesValue(prevState => ({ ...prevState, value: parseDouble(data?.subsidiesValue) }));
          setRentedArea(prevState => ({ ...prevState, value: parseDouble(data?.rentedArea) }));
          setRentIsPaid(prevState => ({
            ...prevState,
            value: data?.rentIsPayed ? [data?.rentIsPayed ? 'true' : 'false'] : [],
          }));
          setClosingArea(prevState => ({ ...prevState, value: parseDouble(data?.closingArea) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
          setSharecroppedArea(prevState => ({ ...prevState, value: parseDouble(data?.sharecroppedArea) }));
          setUsedPublicLandArea(prevState => ({ ...prevState, value: parseDouble(data?.usedPublicLandArea) }));
          setUsedPublicLandDays(prevState => ({ ...prevState, value: data?.usedPublicLandDays }));
          setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

          let explanations = data?.errorExplanations ? data?.errorExplanations : [];

          handleApiErrorsFn({}, 'audit', validation, 'agriculturalLand', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingArea();
    }
  }, [openingArea.value, purchasedArea.value, soldArea.value]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingValue();
    }
  }, [openingValue.value, purchasedValue.value, investmentValue.value, soldValue.value]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setLandCategory(prevState => ({ ...prevState, disabled: false }));
        setOpeningArea(prevState => ({ ...prevState, disabled: false }));
        setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setInvestmentValue(prevState => ({ ...prevState, disabled: false }));
        setPurchasedArea(prevState => ({ ...prevState, disabled: false }));
        setPurchasedValue(prevState => ({ ...prevState, disabled: false }));
        setSoldArea(prevState => ({ ...prevState, disabled: false }));
        setSoldValue(prevState => ({ ...prevState, disabled: false }));
        setSubsidiesValue(prevState => ({ ...prevState, disabled: false }));
        setRentedArea(prevState => ({ ...prevState, disabled: false }));
        setRentIsPaid(prevState => ({ ...prevState, disabled: false }));
        setClosingValue(prevState => ({ ...prevState, disabled: false }));
        setClosingArea(prevState => ({ ...prevState, disabled: false }));
        setSharecroppedArea(prevState => ({ ...prevState, disabled: false }));
        setUsedPublicLandArea(prevState => ({ ...prevState, disabled: false }));
        setUsedPublicLandDays(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  useEffect(() => {
    if (!isAudit) {
      changeAgriculturalLand(landCategory?.value);
    }
  }, [landCategory?.value, edit]);

  // useDidMountEffect(() => {
  //   (!compareObjects(submitDisabled, prevVal) && showErrors) && handleSubmit(false);
  // }, [submitDisabled]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm'/>
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>

              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>

                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectAsyncField
                    pagination={false}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    api='land-categories'
                    placeholder={t('pages.agriculturalLand.placeholders.landCategory')}
                    label={t('pages.agriculturalLand.landCategory')}
                    value={landCategory.value}
                    name='landCategory'
                    onChange={(e) => {
                      setShowErrors(true);
                      setLandCategory(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={landCategory.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['landCategory']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.openingArea')}
                    placeholder={t('pages.agriculturalLand.placeholders.openingArea')}
                    suffix='ha'
                    value={openingArea.value}
                    name='openingArea'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.openingValue')}
                    placeholder={t('pages.agriculturalLand.placeholders.openingValue')}
                    value={openingValue.value}
                    suffix='€'
                    name='openingValue'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.investmentValue')}
                    placeholder={t('pages.agriculturalLand.placeholders.investmentValue')}
                    value={investmentValue.value}
                    name='investmentValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setInvestmentValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={investmentValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['investmentValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.purchasedArea')}
                    placeholder={t('pages.agriculturalLand.placeholders.purchasedArea')}
                    value={purchasedArea.value}
                    suffix='ha'
                    name='purchasedArea'
                    type={'double'}
                    onChange={(e) => {
                      setPurchasedArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchasedArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchasedArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.purchasedValue')}
                    placeholder={t('pages.agriculturalLand.placeholders.purchasedValue')}
                    value={purchasedValue.value}
                    name='purchasedValue'
                    type={'double'}
                    suffix='€'
                    onChange={(e) => {
                      setPurchasedValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchasedValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchasedValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.soldArea')}
                    placeholder={t('pages.agriculturalLand.placeholders.soldArea')}
                    suffix='ha'
                    value={soldArea.value}
                    name='soldArea'
                    type={'double'}
                    onChange={(e) => {
                      setSoldArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.soldValue')}
                    placeholder={t('pages.agriculturalLand.placeholders.soldValue')}
                    value={soldValue.value}
                    name='soldValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSoldValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.subsidiesValue')}
                    placeholder={t('pages.agriculturalLand.placeholders.subsidiesValue')}
                    value={subsidiesValue.value}
                    name='subsidiesValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setSubsidiesValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={subsidiesValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['subsidiesValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.rentedArea')}
                    placeholder={t('pages.agriculturalLand.placeholders.rentedArea')}
                    suffix='ha'
                    value={rentedArea.value}
                    name='rentedArea'
                    type={'double'}
                    onChange={(e) => {
                      setRentedArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={rentedArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['rentedArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.sharecroppedArea')}
                    placeholder={t('pages.agriculturalLand.placeholders.sharecroppedArea')}
                    suffix='ha'
                    value={sharecroppedArea.value}
                    name='sharecroppedArea'
                    type={'double'}
                    onChange={(e) => {
                      setSharecroppedArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={sharecroppedArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['sharecroppedArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.usedPublicLandArea')}
                    placeholder={t('pages.agriculturalLand.placeholders.usedPublicLandArea')}
                    suffix='ha'
                    value={usedPublicLandArea.value}
                    name='usedPublicLandArea'
                    type={'number'}
                    onChange={(e) => {
                      setUsedPublicLandArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={usedPublicLandArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['usedPublicLandArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.usedPublicLandDays')}
                    placeholder={t('pages.agriculturalLand.placeholders.usedPublicLandDays')}
                    value={usedPublicLandDays.value}
                    name='usedPublicLandDays'
                    type={'number'}
                    onChange={(e) => {
                      setUsedPublicLandDays(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={usedPublicLandDays.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['usedPublicLandDays']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.rentIsPaid')}
                    name='rentIsPayed'
                    options={[{ value: 'true', label: '' }]}
                    value={rentIsPayed?.value}
                    onChange={(e) => {
                      setRentIsPaid(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={rentIsPayed?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['rentIsPayed']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.closingArea')}
                    placeholder={t('pages.agriculturalLand.placeholders.closingArea')}
                    suffix='ha'
                    value={closingArea.value}
                    name='closingArea'
                    type={'double'}
                    onChange={(e) => {
                      setClosingArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingArea.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingArea']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.agriculturalLand.closingValue')}
                    placeholder={t('pages.agriculturalLand.placeholders.closingValue')}
                    value={closingValue.value}
                    name='closingValue'
                    type={'double'}
                    suffix='€'
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.plantProducts.comment')}
                    placeholder={t('pages.agriculturalLand.placeholders.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className='d-flex justify-content-center pt-3'>
              <div className='d-flex flex-column-reverse flex-md-row'>
                <CancelBtn
                  className='button cancel'
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/long-term-assets/agricultural-lands`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className='button save'
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default AgriculturalLandForm;
