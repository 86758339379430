import React, { useEffect, useState } from 'react';
import FieldContainer from '../fieldContainer/FieldContainer';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import classes from './FilePicker.module.scss';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { validate } from '../../../utils/validation';
import { compareObjects } from '../../../utils/const';
import _ from 'lodash';
import { t } from 'react-switch-lang';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';
// <InputField
//                     label="Input field"
//                     value={value}
//                     name="input-field"
//                     onChange={(e) => {
//                         setValue(e)
//                     }}/>

const InputField = React.forwardRef(
  (
    {
      disabled = false,
      name,
      value,
      defaultValue,
      onChange,
      onBlur = () => {
      },
      placeholder,
      validation = { type: '' },
      setError,
      displayError,
      help,
      styles,
      tabIndex,
      whitelist,
      defaultFileName = '',
      ...rest
    },
    ref,
  ) => {
    let uniqueId = uniqid();

    let componentName = name ? name : uniqueId;

    const redirectPrompt = useRedirectPrompt();

    const [errMessage, setErrMessage] = useState(validate(validation, value));
    const [fileName, setFileName] = useState('');

    const prevVal = usePrevious(value);
    const prevValidation = usePrevious(validation);

    useEffect(() => {
      // eslint-disable-next-line
      if ((!compareObjects(prevVal, value)) || (!compareObjects(validation, prevValidation))) {
        let errorObject = validate(validation, value);
        if (errorObject.type !== 'noErrors') {
          if (errorObject.type === 'apiError') {
            setError('apiError');
          } else {
            setError('error');
          }
        } else {
          setError('noErrors');
        }
        setErrMessage(errorObject);
      }
      // eslint-disable-next-line
    }, [value, validation]);

    return (
      <FieldContainer
        required={
          validation
            ? validation.required
              ? validation.required.value === true
              : false
            : false
        }
        errorMessage={!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? errMessage : ''}
        customClasses={`${styles}`}
        {...rest}
      >
        <div className={`${classes.container}`}>
          <div className={`
                    ${classes['input-field']}
                    ${disabled ? classes['disabled'] : ''}
                    ${!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? classes['error'] : ''}
                `}>

            {/* label input that takes functionality of file input field but allows customization of button text */}
            <label htmlFor={componentName} className='btn btn-secondary mb-0'>{t('pages.documents.file.chooseFile')}</label>
            <label className='mb-0 ml-2'>{fileName ? fileName : defaultFileName ? defaultFileName : t('pages.documents.file.noFileChosen')}</label>
          </div>
          <input
            tabIndex={tabIndex}
            disabled={disabled}
            autoComplete='off'
            data-refkey={componentName}
            type='file'
            accept={whitelist}
            style={{ display: 'none' }}
            ref={ref}
            name={componentName}
            value={value}
            defaultValue={defaultValue}
            onBlur={(e) => onBlur(e)}
            onChange={(e) => {
              onChange(e.target.files[0], e.target.value);
              setFileName(e.target.files[0]?.name);
              redirectPrompt.setIsDirty(true);
            }}
            id={componentName}
          />
        </div>
      </FieldContainer>
    );
  },
);

InputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  validation: PropTypes.object,
  setError: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  displayError: PropTypes.object,
};

export default InputField;
