import React, { useContext, useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import { Resizable } from 'react-resizable';
import 'antd/dist/antd.css';
import './RequestsTable.scss';
import { axiosGet } from '../../../services/axios';
import { t } from 'react-switch-lang';
import SaveBtn from '../../buttons/SaveBtn';
import { formatDateTime } from '../../../utils/formatDate';
import AgriculturalHoldingData from '../../../contexts/AgriculturalHoldingData';
import { useNotificationBadge } from '../../../contexts/NotificationBadge';
import { useWindowWidth } from '../../../contexts/WindowWidth';

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className='react-resizable-handle'
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
/**
 *
 * @param {object} props
 * @param {string} props.api 'Endpoint for fetching table data'
 * @param {array} props.columns 'Columns structure'
 * @param {integer} [props.rows=20] 'Number of rows to fetch'
 */

const RequestTable = ({
                        api,
                        pageable = true,
                        query,
                        queryParamName,
                        columns,
                        rows = 20,
                        onRowClick,
                        style,
                        handleComplete,
                        user,
                        inModal = false,
                        xScroll = true,
                        ...rest
                      }) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState('');

  const [refreshNotification, setRefreshNotification] = useState(false);

  const agroHolding = useContext(AgriculturalHoldingData);
  const windowWidth = useWindowWidth();

  const { setHasGlobalRequests } = useNotificationBadge();

  // query = query
  //   ? queryParamName
  //     ? `${queryParamName}=${query}`
  //     : `name.contains=${query}`
  //   : '';

  const fetchData = async () => {
    try {
      // Extra condition added because API is not pageable by default
      if (!pageable) {
        const { data } = await axiosGet(`${api}`);
        setItems(data);
        setHasMore(false);
        setPage(0);
      } else if (api.includes('?')) {
        const { data } = await axiosGet(`${api}`);
        setItems(data);
        setHasMore(data.length === rows);
        setPage(1);
      } else {
        const { data } = await axiosGet(`${api}?page=0&size=${rows}`);
        setItems(data);
        setHasMore(data.length === rows);
        // setHasMore(false);
        setPage(1);
      }
    } catch (err) {
    }
  };

  const fetchMoreData = async () => {
    try {
      const { data } = await axiosGet(`${api}?page=${page}&size=${rows}`);
      setItems([...items, ...data]);
      setHasMore(data.length === rows);
      setPage((page) => page + 1);
    } catch (err) {
    }
  };

  useEffect(() => {
    if (api !== undefined && api !== '') {
      fetchData();
    }
  }, [api, query]);

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      title: () => <Tooltip title={col.title} placement='top' color={'#67B278'}
                            overlayInnerStyle={{ borderRadius: '5px' }}>{col.title}</Tooltip>,
      onCell: (record) => ({
        record,
        dataindex: col.dataIndex,
        rules: col.rules,
        options: col.options,
      }),
      ...(inModal && col.key === 'agroHolding' && { responsive: []})
    };
  });

  const loader = (
    <div className='text-center mt-3'>
      <Spinner variant='primary' animation='border' />
    </div>
  );

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize = (index) => (e, { size }) => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
    return { columns: nextColumns };
  };

  const resizeColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  // Creates extra column that contains button for completion if request isn't completed
  (user.isAdmin) && mergedColumns.push({
    key: 'completed',
    ellipsis: false,
    align: 'center',
    width: 200,
    fixed: 'right',
    render: (record) => !record.completed ?
      <SaveBtn label={t('administration.requests.actions.completeRequest')}
               onClick={() => {
                 handleComplete(record);
                 setRefreshNotification(prevState => !prevState);
               }}
               thin={true}
               icon={true} />
      :
      <div className='small'>
        <div>{record.completedBy && record.completedBy.firstName + " " + record.completedBy.lastName}</div>
        <div>{formatDateTime(record.completedAt)}</div>
      </div>
  });

  useEffect(() => {
    axiosGet('requests').then((response) => {
      setHasGlobalRequests(response.data.filter((elem) => !elem.completed).length > 0);
    });
  }, [refreshNotification])

  return (
    <div>
      <InfiniteScroll
        next={fetchMoreData}
        hasMore={hasMore}
        loader={loader}
        scrollableTarget='agroContainer'
        dataLength={items.length}
        style={{ overflowY: 'hidden' }}
      >
        <div id={'requestTable'}>
          <Table
            style={style ? style : {}}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => (onRowClick ? onRowClick(record) : null),
              };
            }}
            components={components}
            columns={mergedColumns}
            dataSource={inModal ? items.filter((elem) => elem?.agroHolding?.id === agroHolding.agriculturalHoldingData.id) : items}
            sticky={true}
            pagination={false}
            locale={{ emptyText: t('common.noData') }}
            rowKey={(record) => record.id}
            scroll={{ x: xScroll && windowWidth < 1100 ? 1100 : false }}
            {...rest}
          />
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default RequestTable;
