import React, {useState} from 'react';
import {CardsGridAdministration} from '../../../../components/cardsGridAdministration/CardsGridAdministration';
import {useHistory} from 'react-router-dom';
import {t} from 'react-switch-lang';
import SearchField from '../../../../components/searchField/SearchField';

const Enums = () => {
  const history = useHistory();

  const [query, setQuery] = useState('');

  const list = [
    {
      title: t('administration.enums.region.regions'),
      onClick: () => history.push('enums/regions'),
    },
    {
      title: `${t('administration.enums.landBuildingExpensesTypes.landBuildingExpensesTypes')}`,
      onClick: () => history.push('enums/land-and-building-expenses-type'),
    },
    {
      title: `${t('administration.enums.productionPlantProduct.productionPlantProducts')}`,
      onClick: () => history.push('enums/production-plant-products'),
    },
    {
      title: `${t('administration.enums.productionPlantProductCategories.productionPlantProductCategories')}`,
      onClick: () => history.push('enums/production-plant-product-categories'),
    },
    {
      title: `${t('administration.enums.grantsSubsidiesCategory.grantsSubsidiesCategories')}`,
      onClick: () => history.push('enums/grant-subsidy-categories'),
    },
    {
      title: `${t('administration.enums.title.subsidiesCategories')}`,
      onClick: () => history.push('enums/subsidy-categories'),
    },
    {
      title: `${t('administration.enums.productionManufacturedProducts.productionManufacturedProducts')}`,
      onClick: () => history.push('enums/production-manufactured-products'),
    },
    {
      title: `${t('administration.enums.farmTypes.farmTypes')}`,
      onClick: () => history.push('enums/farm-types'),
    },
    {
      title: `${t('administration.enums.productionAnimalProducts.productionAnimalProducts')}`,
      onClick: () => history.push('enums/production-animal-products'),
    },
    {
      title: `${t('administration.enums.soCoefficient.soCoefficients')}`,
      onClick: () => history.push('enums/so-coefficients'),
    },
    {
      title: `${t('administration.enums.title.userTypes')}`,
      onClick: () => history.push('enums/user-types'),
    },
    {
      title: `${t('administration.enums.title.altitudes')}`,
      onClick: () => history.push('enums/altitudes'),
    },
    {
      title: `${t('administration.enums.title.economicSizes')}`,
      onClick: () => history.push('enums/economic-sizes'),
    },
    {
      title: `${t('administration.enums.title.loanCategories')}`,
      onClick: () => history.push('enums/loan-categories'),
    },
    {
      title: `${t('administration.enums.title.landCategories')}`,
      onClick: () => history.push('enums/land-categories'),
    },
    {
      title: `${t('administration.enums.title.cashFlowTypes')}`,
      onClick: () => history.push('enums/cash-flow-types'),
    },
    {
      title: `${t('administration.enums.title.otherMoneyTypes')}`,
      onClick: () => history.push('enums/other-money-types'),
    },
    {
      title: `${t('administration.enums.title.otherGainfulActivities')}`,
      onClick: () => history.push('enums/other-gainful-activities'),
    },
    {
      title: `${t('administration.enums.title.ownershipTypes')}`,
      onClick: () => history.push('enums/ownership-types'),
    },
    {
      title: `${t('administration.enums.title.protectedProductFarmTypes')}`,
      onClick: () => history.push('enums/protected-product-farm-types'),
    },

    {
      title: `${t('administration.enums.title.agriculturalEducations')}`,
      onClick: () => history.push('enums/agricultural-educations'),
    },
    {
      title: `${t('administration.enums.title.taxTypes')}`,
      onClick: () => history.push('enums/tax-types'),
    },
    {
      title: `${t('administration.enums.title.grantsPremiumTypes')}`,
      onClick: () => history.push('enums/grant-premium-types'),
    },
    {
      title: `${t('administration.enums.title.genders')}`,
      onClick: () => history.push('enums/genders'),
    },
    {
      title: `${t('administration.enums.title.areaConstraints')}`,
      onClick: () => history.push('enums/area-constraint'),
    },
    {
      title: `${t('administration.enums.title.workforceRoles')}`,
      onClick: () => history.push('enums/workforce-roles'),
    },
    {
      title: `${t('administration.enums.title.workforceTypes')}`,
      onClick: () => history.push('enums/workforce-types'),
    },

    {
      title: `${t('administration.enums.title.productionTypes')}`,
      onClick: () => history.push('enums/production-types'),
    },

    {
      title: `${t('administration.enums.title.plantProductionTypes')}`,
      onClick: () => history.push('enums/plant-production-types'),
    },
    {
      title: `${t('administration.enums.title.loanPurposes')}`,
      onClick: () => history.push('enums/loan-purposes'),
    },
    {
      title: `${t('administration.enums.title.loanTypes')}`,
      onClick: () => history.push('enums/loan-types'),
    },
    {
      title: `${t('administration.enums.title.leasingTypes')}`,
      onClick: () => history.push('enums/leasing-types'),
    },
    {
      title: `${t('administration.enums.title.protectedProductTypes')}`,
      onClick: () => history.push('enums/protected-product-types'),
    },
    {
      title: `${t('administration.enums.title.grantsBasicUnitsTypes')}`,
      onClick: () => history.push('enums/grant-basic-unit-types'),
    },
    {
      title: `${t('administration.enums.title.grantsFinancedTypes')}`,
      onClick: () => history.push('enums/grant-financed-types'),
    },
    {
      title: `${t('administration.enums.title.grantsTypes')}`,
      onClick: () => history.push('enums/grant-types'),
    },
    {
      title: `${t('administration.enums.protectedProducts.protectedProducts')}`,
      onClick: () => history.push('enums/protected-products'),
    },
    {
      title: `${t('administration.enums.title.mechanizationTypes')}`,
      onClick: () => history.push('enums/mechanization-types'),
    },
    {
      title: `${t('administration.enums.title.plantationTypes')}`,
      onClick: () => history.push('enums/plantation-types'),
    },
    {
      title: `${t('administration.enums.title.objectTypes')}`,
      onClick: () => history.push('enums/object-types'),
    },
    {
      title: `${t('administration.enums.title.cattleCategories')}`,
      onClick: () => history.push('enums/cattle-categories'),
    },
    {
      title: `${t('administration.enums.title.goatsCategories')}`,
      onClick: () => history.push('enums/goat-categories'),
    },
    {
      title: `${t('administration.enums.title.sheepCategories')}`,
      onClick: () => history.push('enums/sheep-categories'),
    },
    {
      title: `${t('administration.enums.title.otherLivestockCategories')}`,
      onClick: () => history.push('enums/other-livestock-categories'),
    },
    {
      title: `${t('administration.enums.title.poultryCategories')}`,
      onClick: () => history.push('enums/poultry-categories'),
    },
    {
      title: `${t('administration.enums.title.pigsCategories')}`,
      onClick: () => history.push('enums/pig-categories'),
    },
    {
      title: `${t('administration.enums.mineralFertilizerType.mineralFertilizerTypes')}`,
      onClick: () => history.push('enums/mineral-fertilizer-types'),
    },
    {
      title: t('administration.enums.title.organicSectors'),
      onClick: () => history.push('enums/organic-sectors'),
    },
    {
      title: t('administration.enums.title.productionCodes'),
      onClick: () => history.push('enums/production-codes'),
    },
    {
      title: t('administration.enums.title.productionOtherProducts'),
      onClick: () => history.push('enums/production-other-products'),
    },
    {
      title: t('administration.enums.title.plantExpenseTypes'),
      onClick: () => history.push('enums/plant-expense-types'),
    },
    {
      title: t('administration.enums.title.insuranceExpenseTypes'),
      onClick: () => history.push('enums/insurance-expense-types'),
    },
    {
      title: t('administration.enums.title.taxExpenseTypes'),
      onClick: () => history.push('enums/tax-expense-types'),
    },
    {
      title: t('administration.enums.title.labourMachineryExpenseTypes'),
      onClick: () => history.push('enums/labour-machinery-expense-types'),
    },
    {
      title: t('administration.enums.title.overheadExpenseTypes'),
      onClick: () => history.push('enums/overhead-expense-types'),
    },
    {
      title: t('administration.enums.title.livestockExpenseTypes'),
      onClick: () => history.push('enums/livestock-expense-types'),
    },
    {
      title: t('administration.enums.title.irrigationTypes'),
      onClick: () => history.push('enums/irrigation-types'),
    },
    {
      title: t('administration.enums.title.processingExpenseTypes'),
      onClick: () => history.push('enums/processing-expense-types'),
    },

  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <SearchField
          onChange={setQuery}
          placeholder={t('administration.enums.searchEnums')}
          prefix={true}
          className="mb-5 w-25"
        />
      </div>
      <div className={`d-flex justify-content-left align-items-start`}>
        <CardsGridAdministration list={list} enums={true} query={query}/>
      </div>
    </>
  );
};

export default Enums;
