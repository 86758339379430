import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import './ModalWrapper.scss';
import React from 'react';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

// usage
// inside parent component const [isModalOpen, setIsModalOpen] = useState(false);
// add action to trigger modals opening - setIsModalOpen(true)

//          <ModalWrapper
//                 isModalOpen={isModalOpen}
//                 onClose={() => setIsModalOpen(false)}
//                 ...rest props>
//                 ...
//             </ModalWrapper>

const ModalWrapper = ({
  isModalOpen,
  onClose,
  children,
  className = '',
  header,
  showFooter,
  footer,
  scrollable = false,
}) => {
  const { setIsDirty } = useRedirectPrompt();

  return (
    <Modal
      className={`${className} modal-wrapper-custom`}
      show={isModalOpen}
      backdrop="static"
      keyboard={false}
      centered
      onHide={() => {
        onClose();
        setIsDirty(false);
      }}
      scrollable={scrollable}
      onKeyPress={(e) => {
        if (e.charCode === 13) {
          e.preventDefault();
        }
      }}
    >
      <Modal.Header closeButton>{header}</Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {showFooter && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

ModalWrapper.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  showFooter: PropTypes.bool,
};

export default ModalWrapper;
