import React, { useEffect, useState } from 'react';
import FieldContainer from '../fieldContainer/FieldContainer';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import classes from '../inputField/InputField.module.scss';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { validate } from '../../../utils/validation';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import _ from 'lodash';
import Icon, { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';
import NumberFormat from 'react-number-format';

// <InputField
//                     label="Input field"
//                     value={value}
//                     name="input-field"
//                     onChange={(e) => {
//                         setValue(e)
//                     }}/>

const FormatNumberField = React.forwardRef(
  (
    {
      label,
      helpLabel,
      disabled = false,
      type = 'text',
      name,
      value,
      defaultValue,
      onChange,
      onBlur = () => {
      },
      placeholder,
      validation = { type: '' },
      setError,
      displayError,
      help,
      styles,
      tabIndex,
      isNegative = false,
      isAudit = false,
      ...rest
    },
    ref,
  ) => {
    let uniqueId = uniqid();

    let componentName = name ? name : uniqueId;

    const redirectPrompt = useRedirectPrompt();

    const [errMessage, setErrMessage] = useState(validate(validation, value));

    const [showPassword, setShowPassword] = useState(false);

    const prevVal = usePrevious(value);
    const prevValidation = usePrevious(validation);

    // state for manual management of minus and dot characters
    const [inputValue, setInputValue] = useState('');

    // state used when deleting value, input automatically removes dot character when value is etc. "365.", this state keeps the dot intact to prevent further problems
    const [lastDecimal, setLastDecimal] = useState(false);

    useEffect(() => {
      document.getElementById(componentName).addEventListener('wheel', e => e.preventDefault());

      // on component unmounting return states to initial
      return () => {
        setErrMessage({});
      };
    }, []);

    useEffect(() => {
      // eslint-disable-next-line
      if ((!compareObjects(prevVal, value)) || (!compareObjects(validation, prevValidation))) {
        let errorObject = validate(validation, value);
        if (errorObject.type !== 'noErrors') {
          if (errorObject.type === 'apiError') {
            setError('apiError');
          } else {
            setError('error');
          }
        } else {
          setError('noErrors');
        }
        setErrMessage(errorObject);
      }
      // eslint-disable-next-line
    }, [value, validation]);

    // reformats commas
    const doubleWithComma = (double) => {
      return double?.toString().replace('.', ',');
    };

    // paste function for handling basic, decimal and negative numbers
    const handlePaste = (value) => {
      // sets regex based on number type
      // (?<=\,.*)\D removes all non-numeral character after encountering first ',' character
      const regex = type === 'double' ? '(?<=\\,.*)\\D' : '\\D';

      let temp = value.replace(new RegExp(regex, 'g'), '')
      // when type is double and after only one ',' is left, this line splits it and then trims the left side of ',' and slices 2 digits on the right side of ','
        .split(',').map((elem, i) => i === 0 ? elem.replace(/\D/g, '') : elem.slice(0, 2)).join(',');

      if (isNegative && value.charAt(0) === '-') {
        temp = '-' + temp;
      }

      onChange(temp);
      setInputValue(temp);
    };

    return (
      <FieldContainer
        componentName={componentName}
        label={label}
        helpLabel={helpLabel}
        required={
          validation
            ? validation.required
            ? validation.required.value === true
            : false
            : false
        }
        errorMessage={!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? errMessage : ''}
        customClasses={`${styles}`}
        {...rest}
      >
        <div className={`${classes.container}`}>
          <NumberFormat
            tabIndex={tabIndex}
            disabled={disabled}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={3}
            allowNegative={false}
            autoComplete='off'
            data-refkey={componentName}
            type={type === 'password' && !showPassword ? 'password' : type === 'number' ? 'number' : 'text'}
            className={`
                    ${classes['input-field']}
                    ${disabled ? classes['disabled'] : ''}
                    ${!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? classes['error'] : ''}
                `}
            ref={ref}
            name={componentName}
            value={value}
            defaultValue={defaultValue}
            onBlur={(e) => onBlur(e)}
            onPaste={(event) => {
              if (type === 'number' || type === 'double') {
                handlePaste(event.clipboardData.getData('Text'));
                event.preventDefault();
              }
            }}
            onKeyPress={(event) => {
              if (type === 'number' && !/[-]|[0-9]/.test(event.key)) {
                // allow only numbers
                event.preventDefault();
              } else if (type === 'double' && !/[-]|[,]|[0-9]/.test(event.key)) {
                // allow only numbers and dot
                event.preventDefault();
              } else if ((type === 'number' || type === 'double') && (!isNegative || inputValue !== '') && /[-]/.test(event.key)) {
                // prevents minuses in non-negative numbers
                event.preventDefault();
              } else if (type === 'double' && (inputValue === '-' || inputValue.includes(',')) && /[,]/.test(event.key)) {
                // prevents mispositioned dot (in empty field and after minus)
                event.preventDefault();
                // } else if (type === 'double' && /[0-9]*[,][0-9]{2}/.test(inputValue)) {
                //   // prevent input past 2 decimals
                //   event.preventDefault();
              } else if (/[-]|[,]|[0-9]/.test(event.key)) {
                // changes input only when it matches one of valid key inputs
                setInputValue(prevVal => prevVal + event.key);
              }
            }}
            onWheelCapture={e => {
              e.target.blur();
            }}
            onValueChange={(e) => {
              onChange(e.floatValue);
              !isAudit && redirectPrompt.setIsDirty(true);
            }}
            step={type === 'double' ? '0.01' : undefined}
            placeholder={placeholder ? !disabled && placeholder : !disabled && t('common.insertValue')}
            onClick={(e) => e.target.focus()}
            id={componentName}
          />
          {/* Dynamically setting the show password button and icon + icon switching logic */}
          {
            type === 'password' &&
            <Icon
              component={showPassword ? EyeInvisibleOutlined : EyeOutlined}
              alt='password_show'
              className={`${classes.eyeIcon}`}
              onClick={() => setShowPassword(!showPassword)}
            />
          }
        </div>
      </FieldContainer>
    );
  },
);

FormatNumberField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  validation: PropTypes.object,
  setError: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  type: PropTypes.oneOf(['text', 'number', 'double', 'password']),
  displayError: PropTypes.object,
};

export default FormatNumberField;
