import { t } from 'react-switch-lang';

// Example data
// [
//   {
//     type: 'Pigs',
//     value: 27,
//   },
//   {
//     type: 'Cows',
//     value: 25,
//   },
// ];

// Helper function for modeling api response for chart display

/* PIE FUNCTIONS */
export const modelPieData = (dataArr) => {
  return dataArr.map((elem) => ({
    type: elem.type,
    value: elem.number,
  }));
};

export const modelPieDataObject = (dataObj) => {
  return Object.entries(dataObj).map(([key, value]) => ({
    type: t(`pageTitle.${key}`),
    value: value,
  }));
};

export const modelLivestockData = (dataObj) => {
  return Object.entries(dataObj).map(([key, value]) => ({
    type: t(`pageTitle.${key}`),
    value: value,
    apiCall: (key.split('O')[0] === 'pigs' ? 'pig' : key.split('O')[0]) + '-owned',
  }));
};

/* TREE FUNCTIONS */
export const modelTreeData = (dataArr) => {
  return dataArr.map((elem) => ({
    name: elem.type,
    value: elem.number,
  }));
};


/* STACKED COLUMN FUNCTIONS */
export const modelStackedColumnData = (dataArr) => {
  return dataArr.map((elem) => ({
    type: elem.type,
    value: elem.number,
    xAxis: '',
  }));
};

export const modelIncomeStackedColumnData = (dataObj) => {
  return Object.entries(dataObj).map(([key, value]) => ({
    type: t(`income.${key}`),
    value: value,
    xAxis: '',
  }));
};
