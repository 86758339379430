import { formatDate } from '../../../../../../utils/formatDate';
import { t } from 'react-switch-lang';
import React from 'react';
import { eng, formatNumber, hasValue } from '../../../../../../utils/const';
import classes from '../../Goats.module.scss';

const renderColWithTooltip = (col, date) => {
  // return <Tooltip title={hasValue(date) ? formatDate(col) : hasValue(col) && col} placement="top" color={'#67B278'}
  //                 overlayInnerStyle={{ borderRadius: '5px' }}>
  return hasValue(col) ? (date ? formatDate(col) : col) : '-';
  // </Tooltip>;
};

const renderBigCol = (col1) => {
  // return <Tooltip title={col} placement="top" color={'#67B278'}
  //                 overlayInnerStyle={{ borderRadius: '5px' }}>
  return (
    <div className="d-flex flex-column">
      <div className={`${classes['customColumn']} mb-2`}>
        {hasValue(col1) ? formatNumber(col1) : '-'}
      </div>
      <div>{'-'}</div>
    </div>
  );
  // </Tooltip>;
};

const renderBigColTwoFields = (col1, col2) => {
  // return <Tooltip title={col1 + ' ' + col2} placement="top" color={'#67B278'}
  //                 overlayInnerStyle={{ borderRadius: '5px' }}>
  return (
    <div className="d-flex flex-column">
      <div className={`${classes['customColumn']} mb-2`}>
        {hasValue(col1) ? formatNumber(col1) : '-'}
      </div>
      <div>{hasValue(col2) ? formatNumber(col2) : '-'}</div>
    </div>
  );
  // </Tooltip>;
};

export const columns = [
  {
    title: t('pages.goat.categoryNameGoat'),
    dataIndex: eng ? 'categoryNameEn' : 'categoryName',
    key: 'categoryName',
    render: (categoryName) => renderColWithTooltip(categoryName.toUpperCase()),
  },
  {
    title: t('pages.goat.openingInitial'),
    dataIndex: ['openingQuantity', 'openingValue'],
    key: 'openingInitial',
    render: (text, row) => renderBigColTwoFields(row['openingQuantity'], row['openingValue']),
  },
  {
    title: t('pages.goat.offspring'),
    dataIndex: ['offspringQuantity', 'offspringValue'],
    key: 'offspring',
    render: (text, row) => renderBigColTwoFields(row['offspringQuantity'], row['offspringValue']),
  },
  {
    title: t('pages.goat.input').toUpperCase(),
    dataIndex: ['inputQuantity'],
    key: 'input',
    render: (text, row) => renderBigCol(row['inputQuantity']),
  },
  {
    title: t('pages.goat.output').toUpperCase(),
    dataIndex: ['outputQuantity'],
    key: 'output',
    render: (text, row) => renderBigCol(row['outputQuantity']),
  },
  {
    title: t('pages.goat.purchase'),
    dataIndex: ['purchaseQuantity', 'purchaseValue'],
    key: 'purchase',
    render: (text, row) => renderBigColTwoFields(row['purchaseQuantity'], row['purchaseValue']),
  },
  {
    title: t('pages.goat.sale'),
    dataIndex: ['saleQuantity', 'saleValue'],
    key: 'sale',
    render: (text, row) => renderBigColTwoFields(row['saleQuantity'], row['saleValue']),
  },
  {
    title: t('pages.goat.ownConsumption'),
    dataIndex: ['ownConsumptionQuantity', 'ownConsumptionValue'],
    key: 'ownConsumption',
    render: (text, row) =>
      renderBigColTwoFields(row['ownConsumptionQuantity'], row['ownConsumptionValue']),
  },
  {
    title: t('pages.goat.spentOnProcessing'),
    dataIndex: ['spentOnProcessingQuantity', 'spentOnProcessingValue'],
    key: 'spentOnProcessing',
    render: (text, row) =>
      renderBigColTwoFields(row['spentOnProcessingQuantity'], row['spentOnProcessingValue']),
  },
  {
    title: t('pages.goat.paymentInProducts'),
    dataIndex: ['paymentInProductsQuantity', 'paymentInProductsValue'],
    key: 'paymentInProducts',
    render: (text, row) =>
      renderBigColTwoFields(row['paymentInProductsQuantity'], row['paymentInProductsValue']),
  },
  {
    title: t('pages.goat.death'),
    dataIndex: ['deathQuantity', 'deathValue'],
    key: 'death',
    render: (text, row) => renderBigColTwoFields(row['deathQuantity'], row['deathValue']),
  },
  {
    title: t('pages.cattle.closing'),
    dataIndex: ['closingQuantity', 'closingValue'],
    key: 'closing',
    render: (text, row) =>
      renderBigColTwoFields(
        hasValue(row['closingQuantity']) ? row['closingQuantity'] : '-',
        hasValue(row['closingValue']) ? row['closingValue'] + '€' : '-',
      ),
  },
  // {
  //   title: t('pages.goat.id'),
  //   dataIndex: 'categoryId',
  //   key: 'id',
  // /
  //   render: id => renderColWithTooltip(id),
  // },
  // {
  //   title: t('pages.goat.comment'),
  //   dataIndex: 'comment',
  //   key: 'comment',
  // /
  //   render: comment => renderColWithTooltip(comment),
  // },
  // {
  //   title: t('pages.goat.importedFromPrevYearId'),
  //   dataIndex: 'importedFromPrevYearId',
  //   key: 'importedFromPrevYearId',
  // /
  //   render: importedFromPrevYearId => renderColWithTooltip(importedFromPrevYearId),
  // },
  // {
  //   title: t('pages.goat.offspringArea'),
  //   dataIndex: 'offspringArea',
  //   key: 'offspringArea',
  // /
  //   render: offspringArea => renderColWithTooltip(offspringArea),
  // },
  // {
  //   title: t('pages.goat.offspringValue'),
  //   dataIndex: 'offspringValue',
  //   key: 'offspringValue',
  // /
  //   render: offspringValue => renderColWithTooltip(offspringValue),
  // },
  // {
  //   title: t('pages.goat.soldForSlaughterQuantity'),
  //   dataIndex: 'soldForSlaughterQuantity',
  //   key: 'soldForSlaughterQuantity',
  // /
  //   render: soldForSlaughterQuantity => renderColWithTooltip(soldForSlaughterQuantity),
  // },
  // {
  //   title: t('pages.goat.soldForSlaughterValue'),
  //   dataIndex: 'soldForSlaughterValue',
  //   key: 'soldForSlaughterValue',
  // /
  //   render: soldForSlaughterValue => renderColWithTooltip(soldForSlaughterValue),
  // },
  // {
  //   title: t('pages.goat.soldForBreedingQuantity'),
  //   dataIndex: 'soldForBreedingQuantity',
  //   key: 'soldForBreedingQuantity',
  // /
  //   render: soldForBreedingQuantity => renderColWithTooltip(soldForBreedingQuantity),
  // },
  // {
  //   title: t('pages.goat.soldForBreedingValue'),
  //   dataIndex: 'soldForBreedingValue',
  //   key: 'soldForBreedingValue',
  // /
  //   render: soldForBreedingValue => renderColWithTooltip(soldForBreedingValue),
  // },
  // {
  //   title: t('pages.goat.soldForUnknownReason'),
  //   dataIndex: 'soldForUnknownReason',
  //   key: 'soldForUnknownReason',
  // /
  //   render: soldForUnknownReason => renderColWithTooltip(soldForUnknownReason),
  // },
  // {
  //   title: t('pages.goat.quantity'),
  //   dataIndex: 'quantity',
  //   key: 'quantity',
  // /
  //   render: quantity => renderColWithTooltip(quantity),
  // },
  // {
  //   title: t('pages.goat.soldForUnknownReasonValue'),
  //   dataIndex: 'soldForUnknownReasonValue',
  //   key: 'soldForUnknownReasonValue',
  // /
  //   render: soldForUnknownReasonValue => renderColWithTooltip(soldForUnknownReasonValue),
  // },
  // {
  //   title: t('pages.goat.inputQuantity'),
  //   dataIndex: 'inputQuantity',
  //   key: 'inputQuantity',
  // /
  //   render: inputQuantity => renderColWithTooltip(inputQuantity),
  // },
  // {
  //   title: t('pages.goat.outputQuantity'),
  //   dataIndex: 'outputQuantity',
  //   key: 'outputQuantity',
  // /
  //   render: outputQuantity => renderColWithTooltip(outputQuantity),
  // }
];
