import moment from 'moment';

export const token = localStorage.getItem('jwt-token');

export const eng = localStorage.getItem('language') === 'eng';

export const BASE_URL = process.env.API_URL;
// export const BASE_URL = '/api';

export const readOnly = process.env.READONLY === 'true';

export const validGlobalURL = RegExp(
  /https?|http?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
);

export const validUsernameRegex = RegExp(
  /^[_.@A-Za-z0-9-ŠšĐđČčĆćŽž]*$/,
);
export const validNameRegex = RegExp(
  /^[.A-Za-z-ŠšĐđČčĆćŽž]*$/,
);

export const capitalizeFirstLetter = (string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const trimObjectStringValues = (objectData) => {
  const trimmedObj = {};
  // eslint-disable-next-line
  Object.entries(objectData).map(([key, value]) => {
    if (typeof value === 'string') {
      trimmedObj[key] = value.trim();
    } else {
      trimmedObj[key] = value;
    }
  });
  return trimmedObj;
};


export const getFileTypeImage = (type) => {
  // returns url of image for given type
  switch (type) {
    case 'docx':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return 'images/document/doc.svg';
    case 'txt':
    case 'text/plain':
      return 'images/document/txt.svg';
    case 'pdf':
      return 'images/document/pdf.svg';
    case 'xlsx':
    case 'application/vnd.ms-excel':
      return 'images/document/xls.svg';
    default:
      return 'images/document/whiteDocument.svg';
  }
};

//return some date format
export const toDate = (date, format) => {
  return moment(date).format(format);
};

export const hasValue = (value) => {
  return value !== null && value !== undefined && value !== '';
};

export const hasNoValue = (value) => {
  return (value === null || value === undefined || value === '');
};

export const notOnlySpacesAndLineBreaks = (str) => {
  if (/\S/.test(str)) {
    return true;
  }
};

export const compareObjects = (obj1, obj2) => {
  // return JSON.stringify(obj1) === JSON.stringify(obj2)
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  } catch (err) {
    return false;
  }
};

export const formatNumber = (number) => {
  return number?.toLocaleString(
    'sr-ME',
    { minimumFractionDigits: 2 },
  );
};

export const parseFloatCustom = (number) => {
  return hasValue(number) ? number.toString().includes(',') ? parseFloat(number.toString().replace(/,/, '.')) : parseFloat(number) : number;
};

export const parseDouble = (data) => {
  return (parseFloatCustom(data) ? parseFloatCustom(data) : 0).toFixed(2);
};

export const scrollToBottom = (ref) => {
  const scrollHeight = ref.current.scrollHeight;
  const height = ref.current.clientHeight;
  const maxScrollTop = scrollHeight - height;
  ref.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
};

export const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
