import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import SelectConstField from '../../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import classes from './ProcessingExpenseForm.module.scss';
import { Toast } from '../../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../../utils/const';
import UserData from '../../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../../components/formDynamic/helpers';
import {
  fetchSingleManProcExpense,
  getProcessingExpenseType,
} from '../../../../../../services/money/expenses/manufactureProcessingExpenses';
import CheckboxField from '../../../../../../components/formDynamic/checkboxField/CheckboxField';
import Loader from '../../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';

const ProcessingExpenseForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    processingExpenseType: { type: 'selectSingle', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    closingValue: { type: 'number', required: { value: false } },
    consumptionValue: { type: 'number', required: { value: false } },
    purchaseValue: { type: 'number', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    otherProcessingExpense: { type: 'string', required: { value: false }, min: { value: 3 } },
    otherProcessingExpenseApproved: { type: 'checkbox', required: { value: false } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [processing, setProcessing] = useState();

  const [processingExpenseType, setProcessingExpenseType] = useState({ value: undefined, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });
  const [consumptionValue, setConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [purchaseValue, setPurchaseValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [otherProcessingExpense, setOtherProcessingExpense] = useState({ value: undefined, disabled: !edit });
  const [otherProcessingExpenseApproved, setOtherProcessingExpenseApproved] = useState({
    value: undefined,
    disabled: user.isAdmin ? !edit : true,
  });

  // Enums
  const [processingExpenseTypeList, setProcessingExpenseTypeList] = useState([]);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/
  const changeProcessingExpense = (e) => {
    if (e?.value) {
      if (e?.value === 6) {
        setOpeningValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setClosingValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: !edit }));
        setClosingValue(prevState => ({ ...prevState, disabled: !edit }));
      }
      if (e?.value !== 7) {
        setOtherProcessingExpense(prevState => ({ ...prevState, disabled: true, value: undefined }));
        setOtherProcessingExpenseApproved(prevState => ({ ...prevState, disabled: true, value: undefined }));
      } else {
        setOtherProcessingExpense(prevState => ({ ...prevState, disabled: !edit }));
        setOtherProcessingExpenseApproved(prevState => ({ ...prevState, disabled: user.isAdmin ? !edit : true }));
      }
    }
  };

  const calculateClosingValue = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(returnNullVal(openingValue.value)
      + returnNullVal(purchaseValue.value)
      - returnNullVal(consumptionValue.value)).toFixed(2);

    setClosingValue(prevState => ({
      ...prevState,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };
  /***** END DEPENDANT VALIDATION *****/


  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'processingExpense', processing, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(processingExpenseType.value?.value && { processingExpenseType: { id: parseInt(processingExpenseType.value?.value) } }),
        otherProcessingExpenseApproved: otherProcessingExpenseApproved.disabled ? null : hasValue(otherProcessingExpenseApproved?.value) && otherProcessingExpenseApproved.value[0] === 'true',
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        ...(hasValue(consumptionValue?.value) && { consumptionValue: parseFloatCustom(consumptionValue.value) }),
        ...(hasValue(purchaseValue?.value) && { purchaseValue: parseFloatCustom(purchaseValue.value) }),
        ...(otherProcessingExpense?.value && { otherProcessingExpense: otherProcessingExpense.value }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`processing-expenses?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.manufacturerProcessing') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`processing-expenses/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.manufacturerProcessing') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchManProc = async () => {
    try {
      const { data } = await fetchSingleManProcExpense(props.match.params.id);
      setProcessing(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchManProcType = async () => {
    const data = await getProcessingExpenseType();
    setProcessingExpenseTypeList(data);
  };

  useEffect(() => {
    fetchManProcType();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchManProc().then(processing => {
          processing?.processingExpenseType && setProcessingExpenseType(prevState => ({
            ...prevState,
            value: defaultOption(processing?.processingExpenseType),
          }));
          setComment(prevState => ({ ...prevState, value: processing?.comment }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(processing?.openingValue) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(processing?.closingValue) }));
          setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(processing?.consumptionValue) }));
          setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(processing?.purchaseValue) }));
          setOtherProcessingExpense(prevState => ({ ...prevState, value: processing?.otherProcessingExpense }));
          setOtherProcessingExpenseApproved(prevState => ({
            ...prevState,
            value: processing?.otherProcessingExpenseApproved
              ? processing?.otherProcessingExpenseApproved ? ['true'] : ['false']
              : [],
          }));
          setErrorExplanations(processing?.errorExplanations ? processing?.errorExplanations : []);
          let explanations = processing?.errorExplanations ? processing?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'processingExpense', processing, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        data?.processingExpenseType && setProcessingExpenseType(prevState => ({
          ...prevState,
          value: defaultOption(data?.processingExpenseType),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(data?.consumptionValue) }));
        setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(data?.purchaseValue) }));
        setOtherProcessingExpense(prevState => ({ ...prevState, value: data?.otherProcessingExpense }));
        setOtherProcessingExpenseApproved(prevState => ({
          ...prevState,
          value: data?.otherProcessingExpenseApproved
            ? data?.otherProcessingExpenseApproved ? ['true'] : ['false']
            : [],
        }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);
        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'processingExpense', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setProcessingExpenseType(prevState => ({ ...prevState, disabled: false }));
        setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        setClosingValue(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setConsumptionValue(prevState => ({ ...prevState, disabled: false }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: false }));
        setOtherProcessingExpense(prevState => ({ ...prevState, disabled: false }));
        setOtherProcessingExpenseApproved(prevState => ({ ...prevState, disabled: !user.isAdmin }));
      }
    }
  }, [edit]);

  const oneEdit = () => {
    setEdit(true);
    //    const payload = {
    //   id: props.match.params.id,
    //   agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
    //   agroHoldingId: ahid,
    //   processingExpenseType: { id: parseInt(processingExpenseType.value?.value) },
    //   otherProcessingExpenseApproved: Boolean(otherProcessingExpenseApproved.value && otherProcessingExpenseApproved.value[0] === 'true'),
    //   ...(comment?.value && { comment: comment.value }),
    //   ...(otherProcessingExpense?.value && { otherProcessingExpense: otherProcessingExpense.value }),
    //   ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
    //   ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
    //   ...(hasValue(consumptionValue?.value) && { consumptionValue: parseFloatCustom(consumptionValue.value) }),
    //   ...(hasValue(purchaseValue?.value) && { purchaseValue: parseFloatCustom(purchaseValue.value) }),
    //   errorExplanations: clearEmptyExplanations(errorExplanations),
    // }
    //
    // handleSubmit(false, true, payload);
  };


  useEffect(() => {
    if (!isAudit) {
      changeProcessingExpense(processingExpenseType?.value);
    }
  }, [processingExpenseType?.value, edit]);


  // First fetch value from back and then start calculation on dependant fields value change
  const [closingValueCalculateCounter, setClosingValueCalculateCounter] = useState(0);
  useEffect(() => {
    if (!isAudit) {
      if (!location.pathname.includes('add')) {
        setClosingValueCalculateCounter(closingValueCalculateCounter + 1);
        if (!closingValue.disabled) {
          closingValueCalculateCounter > 5 && calculateClosingValue();
        }
      } else {
        if (!closingValue.disabled) {
          calculateClosingValue();
        }
      }
    }
  }, [openingValue,
    purchaseValue,
    consumptionValue]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.processingExpenses.processingExpenseType')}
                    placeholder={t('pages.processingExpenses.placeholder.processingExpenseType')}
                    value={processingExpenseType.value}
                    name='processingExpenseType'
                    onChange={(e) => {
                      setProcessingExpenseType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={processingExpenseTypeList}
                    disabled={processingExpenseType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['processingExpenseType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showRequiredError={showRequiredError}
                    showErrors={showErrors}
                    label={t('pages.processingExpenses.openingValue')}
                    placeholder={t('pages.processingExpenses.placeholder.openingValue')}
                    value={openingValue.value}
                    name='openingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.processingExpenses.purchaseValue')}
                    placeholder={t('pages.processingExpenses.placeholder.purchaseValue')}
                    value={purchaseValue.value}
                    name='purchaseValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.processingExpenses.consumptionValue')}
                    placeholder={t('pages.processingExpenses.placeholder.consumptionValue')}
                    value={consumptionValue.value}
                    name='consumptionValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setConsumptionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.processingExpenses.otherProcessingExpense')}
                    placeholder={t('pages.processingExpenses.placeholder.otherProcessingExpense')}
                    helpLabel={t('pages.processingExpenses.help.otherProcessingExpense')}
                    value={otherProcessingExpense.value}
                    name='otherProcessingExpense'
                    type={'text'}
                    onChange={(e) => {
                      setOtherProcessingExpense(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={otherProcessingExpense.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherProcessingExpense']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.processingExpenses.otherProcessingExpenseApproved')}
                    placeholder={t('pages.processingExpenses.placeholder.otherProcessingExpenseApproved')}
                    helpLabel={t('pages.processingExpenses.help.otherProcessingExpenseApproved')}
                    name='otherProcessingExpenseApproved'
                    options={[{ value: 'true', label: '' }]}
                    value={otherProcessingExpenseApproved?.value}
                    onChange={(e) => {
                      setOtherProcessingExpenseApproved(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={otherProcessingExpenseApproved?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherProcessingExpenseApproved']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.processingExpenses.closingValue')}
                    placeholder={t('pages.processingExpenses.placeholder.closingValue')}
                    value={closingValue.value}
                    suffix='€'
                    name='closingValue'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.processingExpenses.comment')}
                    placeholder={t('pages.processingExpenses.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      oneEdit();
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/expenses/manufacturer-processing`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                oneEdit();
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default ProcessingExpenseForm;
