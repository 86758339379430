import React, { useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../../components/form/validationForm/ValidationForm';
import InputField from '../../../../components/form/inputField/InputField';
import SaveBtn from '../../../../components/buttons/SaveBtn';
import CancelBtn from '../../../../components/buttons/CancelBtn';
import { axiosPost, axiosPut } from '../../../../services/axios';
import Toast from '../../../../components/toast/Toast';
import { t } from 'react-switch-lang';
import SelectConstField from '../../../../components/form/selectField/SelectConstField';
import { roles } from './utils/form/userRoles';
import { validateDuplicate } from './utils/form/duplicateValidation';
import CheckboxField from '../../../../components/form/checkboxField/CheckboxField';
import { defaultOption } from '../../../../utils/selectObjectFormatter';
import { getOffices } from './utils/form/offices';

const UsersForm = ({ data, type, updateTable, closeModal, isDisabled }) => {
  const formRef = useRef();

  const initialData = {
    login: '',
    firstName: '',
    lastName: '',
    email: '',
    userActive: [],
  };

  const [changedData, setChangedData] = useState(initialData);

  const [officeOptionsList, setOfficeOptionsList] = useState([]);

  const [refreshLogin, setRefreshLogin] = useState(false);
  const [refreshEmail, setRefreshEmail] = useState(false);

  const [validation, setValidation] = useState({
    login: { type: 'string', required: { value: false } },
    firstName: { type: 'string', required: { value: true }, min: { value: 2 }, max: { value: 20 } },
    lastName: { type: 'string', required: { value: true }, min: { value: 3 }, max: { value: 40 } },
    email: { type: 'string', required: { value: true }, matches: { value: 'EMAIL' } },
    role: { type: 'selectSingle', required: { value: true } },
    county: { type: 'selectSingle', required: { value: false } },
  });

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData,
        login: changedData?.login ? changedData?.login?.trim() : changedData?.email.split('@')[0],
        authorities: [changedData.role.value],
        ...(changedData?.county && { countyDTO: { id: changedData?.county?.value } }),
        activated: true,
        userActive: true,
      };

      try {
        const response = await axiosPost('admin/users', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.users.messages.successAdd', {
              entity: payload.firstName + " " + payload.lastName,
            }),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
        validateDuplicate(err, validation, setValidation, setRefreshLogin, setRefreshEmail);

        formRef.current.submitForm();
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData,
        login: changedData?.login ? changedData?.login?.trim() : changedData?.email.split('@')[0],
        authorities: [changedData.role.value],
        ...(changedData?.county && { countyDTO: { id: changedData?.county?.value } }),
        activated: changedData.activated,
        userActive: changedData.userActive[0] === 'true',
      };

      try {
        const response = await axiosPut('admin/users', payload);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.users.messages.successEdit', {
              entity: payload.firstName + " " + payload.lastName,
            }),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
        validateDuplicate(err, validation, setValidation, setRefreshLogin, setRefreshEmail);

        formRef.current.submitForm();
      }
    }
  };

  // dynamically sets username if user hasn't specified it
  useEffect(() => {
    if ((/^[a-z0-9]+([-._]?[a-z0-9]+)?@[a-z0-9]+(-?[a-z0-9]+)?\.[a-z]{2,}$/i).test(changedData?.email) && !changedData?.login) {
      setChangedData(prevState => ({
        ...prevState,
        login: changedData?.email.split('@')[0],
      }));
    }
  }, [changedData?.email]);

  // dynamically sets if county field is required based on selected role
  useEffect(() => {
    changedData.role?.value === 'ROLE_COLLECTOR' ?
      setValidation(prevState => ({
        ...prevState,
        county: { type: 'selectSingle', required: { value: true } },
      }))
      :
      setValidation(prevState => ({
        ...prevState,
        county: { type: 'selectSingle', required: { value: false } },
      }));
  }, [changedData.role?.value])

  useEffect(() => {
    getOffices(setOfficeOptionsList);

    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        login: data.login,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        role: roles.find(option => data.authorities[0] === option.value),
        ...(data?.countyDTO && { county: defaultOption(data?.countyDTO) || undefined }),
        activated: data.activated,
        userActive: data.userActive ? ['true'] : [],
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <InputField
          label={t('administration.users.columns.email')}
          placeholder={t('register.emailPlaceholder')}
          value={changedData.email}
          name='email'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, email: e });
            setValidation({
              ...validation,
              email: { ...validation['email'], error: { value: false } },
            });
          }}
          disabled={isDisabled}
          key={'email' + refreshEmail}
        />
        <InputField
          label={t('administration.users.columns.username')}
          placeholder={t('register.loginPlaceholder')}
          value={changedData.login}
          name='login'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, login: e, });
            setValidation({
              ...validation,
              login: { ...validation['login'], error: { value: false } },
            });
          }}
          disabled={type !== 'add'}
          key={'login' + refreshLogin}
        />
        <InputField
          label={t('administration.users.columns.firstName')}
          placeholder={t('register.firstNamePlaceholder')}
          value={changedData.firstName}
          name='firstName'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, firstName: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.users.columns.lastName')}
          placeholder={t('register.lastNamePlaceholder')}
          value={changedData.lastName}
          name='lastName'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, lastName: e });
          }}
          disabled={isDisabled}
        />
        <SelectConstField
          label={t('administration.users.columns.role')}
          placeholder={t('administration.users.placeholders.role')}
          name='role'
          value={changedData.role}
          optionValue={'name'}
          optionLabel={'description'}
          // isMulti={true}
          groupField
          options={roles}
          onChange={(e) => {
            setChangedData({ ...changedData, role: e });
          }}
          disabled={isDisabled}
        />
        {
          type === 'edit' &&
          <CheckboxField
            label={t('administration.users.columns.activated')}
            value={changedData.userActive}
            options={[{ value: 'true', label: '' }]}
            onChange={(e) => {
              setChangedData({ ...changedData, userActive: e });
            }}
          />
        }
        {
          changedData?.role?.value === 'ROLE_COLLECTOR' &&
          <SelectConstField
            label={t('administration.users.columns.office')}
            placeholder={t('register.officePlaceholder')}
            name='county'
            value={changedData.county}
            groupField
            options={officeOptionsList}
            onChange={(e) => {
              setChangedData({ ...changedData, county: e });
            }}
            disabled={isDisabled}
          />
        }
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)} />
        </div>
      )}
    </>
  );
};

export default UsersForm;
