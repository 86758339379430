import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import Swal from 'sweetalert2';
import Toast from '../../../../components/toast/Toast';
import { axiosDelete, axiosGet, axiosGetFile } from '../../../../services/axios';
import { t } from 'react-switch-lang';
import { useHistory } from 'react-router-dom';
import { EllipsisOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Prompt from '../../../../components/prompt/Prompt';
import EditImg from '../../../../components/table/img/edit.svg';
import DeleteImg from '../../../../components/table/img/delete.svg';
import classes from './ReportsDropdown.module.scss';
import Loader from '../../../../components/loader/Loader';
import { formatGenerateFrErrorResponse } from '../utils/frErrorFormat';
import { readOnly } from '../../../../utils/const';

const ReportsDropdown = ({ record }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const onDelete = (e) => {
    e.stopPropagation();
    return new Promise((resolve, reject) => {
      Prompt.fire({
        title: t('common.delete') + ' ' + t('messages.agriculturalHolding') + ' ' + record.ahFadnId,
        text:
          t('common.deleteQuestion', {
            entity: t('messages.agriculturalHolding') + ' ' + record.ahFadnId,
          }) + '?',
        confirmButtonText: t('common.delete'),
        cancelButtonText: t('common.cancel'),
        preConfirm: async () => {
          try {
            const response = await axiosDelete('agro-holdings', record.id);
            if (response.status === 204) {
              Toast.fire({
                title:
                  t('common.successDelete', {
                    entity: t('messages.agriculturalHolding') + ' ' + record.ahFadnId,
                  }) + '!',
                icon: 'success',
              });
              resolve(response);
            } else {
              reject(response);
            }
          } catch (err) {
            Swal.fire({
              text: t('pages.agriculturalHolding.messages.deleteError'),
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        },
      });
    });
  };

  const onUpdate = (e) => {
    e.stopPropagation();
    history.push({
      pathname: `/administration/agricultural-holdings/${record.id}`,
      state: {
        add: false,
        edit: true,
      },
    });
  };

  const handleMenuGenerate = (e, api, report) => {
    setIsLoading(true);
    e.stopPropagation();
    axiosGet(api)
      .then((response) => {
        if (response.status === 200) {
          Toast.fire({
            title: t('reports.messages.generateSuccess'),
            icon: 'success',
          });
          history.push({
            pathname: '/reports',
            state: {
              report: report,
              ahId: record.id,
            },
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.entityName === 'sRCacheForReport') {
          Swal.fire({
            text: t('reports.messages.reportNotGenerated'),
            icon: 'error',
          });
        } else {
          Swal.fire({
            html: formatGenerateFrErrorResponse(err.response.data.errors, false),
            icon: 'error',
          });
        }
      });
  };

  const handleMenuExport = (e, api, filename) => {
    e.stopPropagation();
    axiosGetFile(api, filename)
      .then()
      .catch(() => {
        Swal.fire({
          text: t('reports.messages.reportNotGenerated'),
          icon: 'error',
        });
      });
  };

  const menu = (
    <Menu className={classes.dropdown__menu}>
      {isLoading ? (
        <Loader size="sm"/>
      ) : (
        <>
          {!readOnly &&
          <>
            <Menu.Item
              key="1"
              icon={<img src={EditImg} alt="edit" className={classes['dropdown__menu--item']}/>}
            >
              <a target="_blank" rel="noopener noreferrer" onClick={(e) => onUpdate(e)}>
                {t('common.edit')}
              </a>
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<img src={DeleteImg} alt="delete" className={classes['dropdown__menu--item']}/>}
            >
              <a target="_blank" rel="noopener noreferrer" onClick={(e) => onDelete(e)}>
                {t('common.delete')}
              </a>
            </Menu.Item>
          </>
          }
          <Menu.Item key="3" className={classes['dropdown__menu--item_iconless']}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handleMenuGenerate(e, `fr-cache-for-reports/add-data/${record.id}`, 'fr')
              }
            >
              {t('reports.actions.generateFr')}
            </a>
          </Menu.Item>
          <Menu.Item key="5" className={classes['dropdown__menu--item_iconless']}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handleMenuExport(e, `frReportXmlExport/${record.id}`, 'xml_report.xml')
              }
            >
              {t('reports.actions.exportXml')}
            </a>
          </Menu.Item>
          <Menu.Item key="4" className={classes['dropdown__menu--item_iconless']}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handleMenuGenerate(e, `sr-cache-for-reports/add-data/${record.id}`, 'sr')
              }
            >
              {t('reports.actions.generateSr')}
            </a>
          </Menu.Item>
          <Menu.Item key="6" className={classes['dropdown__menu--item_iconless']}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handleMenuExport(
                  e,
                  `agro-holdings/report/${record.id}?reportFormat=PDF`,
                  'pdf_report.pdf',
                )
              }
            >
              {t('reports.actions.exportPdf')}
            </a>
          </Menu.Item>
          <Menu.Item key="7" className={classes['dropdown__menu--item_iconless']}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) =>
                handleMenuExport(
                  e,
                  `agro-holdings/report/${record.id}?reportFormat=XLSX`,
                  'excel_report.xlsx',
                )
              }
            >
              {t('reports.actions.exportXlsx')}
            </a>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} overlayClassName="" placement="bottomCenter" arrow>
      <button
        type="button"
        className="btn p-0 mr-2"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <EllipsisOutlined/>
      </button>
    </Dropdown>
  );
};

export default ReportsDropdown;

ReportsDropdown.propTypes = {
  record: PropTypes.object,
};
