import React from 'react';
import FrCategory from "../../../../../components/administration/enums/frCategoryForm/FrCategory";
import {t} from "react-switch-lang";

const LivestockExpenseType = () => {
  return (
    <>
      <FrCategory api={'livestock-expense-types'} title={t('administration.enums.title.livestockExpenseType')}/>
    </>
  );
};

export default LivestockExpenseType;
