import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import SelectConstField from '../../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import classes from './LabourExpenseForm.module.scss';
import { Toast } from '../../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../../utils/const';
import UserData from '../../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../../components/formDynamic/helpers';
import {
  fetchSingleLabourExpense,
  getLabourExpenseType,
} from '../../../../../../services/money/expenses/labourExpenses';
import Loader from '../../../../../../components/loader/Loader';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';

const LabourExpenseForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    labourMachineryExpenseType: { type: 'selectSingle', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    openingQuantity: { type: 'number', required: { value: true } },
    closingQuantity: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
    consumptionQuantity: { type: 'number', required: { value: false } },
    consumptionValue: { type: 'number', required: { value: false } },
    purchaseQuantity: { type: 'number', required: { value: false } },
    purchaseValue: { type: 'number', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [labour, setLabour] = useState();

  const [labourMachineryExpenseType, setLabourMachineryExpenseType] = useState({ value: undefined, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [openingQuantity, setOpeningQuantity] = useState({ value: 0, disabled: !edit });
  const [closingQuantity, setClosingQuantity] = useState({ value: 0, disabled: true });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });
  const [consumptionQuantity, setConsumptionQuantity] = useState({ value: 0, disabled: !edit });
  const [consumptionValue, setConsumptionValue] = useState({ value: 0, disabled: !edit });
  const [purchaseQuantity, setPurchaseQuantity] = useState({ value: 0, disabled: !edit });
  const [purchaseValue, setPurchaseValue] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });

  // Enums
  const [labourMachineryExpenseTypeList, setLabourMachineryExpenseTypeList] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/
  const changeLabourExtenseType = (e) => {
    if (e?.value) {
      if (e?.value !== 4) {
        setOpeningValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setOpeningQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: true, value: 0 }));
        setClosingQuantity(prevState => ({ ...prevState, value: 0 }));
        setClosingValue(prevState => ({ ...prevState, disabled: true, value: 0 }));
      } else {
        setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
        setOpeningQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: !edit }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: !edit }));
        setClosingValue(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };

  const calculateClosingQuantity = () => {
    if (labourMachineryExpenseType?.value?.value === 4) {
      const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

      const calculatedValue = parseFloatCustom(returnNullVal(openingQuantity.value)
        + returnNullVal(purchaseQuantity.value)
        - returnNullVal(consumptionQuantity.value)).toFixed(2);

      setClosingQuantity(prevState => ({
        ...prevState,
        disabled: true,
        value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
      }));
    }
  };

  const calculateClosingValue = () => {
    if (labourMachineryExpenseType?.value?.value === 4) {
      const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

      const calculatedValue = parseFloatCustom(returnNullVal(openingValue.value)
        + returnNullVal(purchaseValue.value)
        - returnNullVal(consumptionValue.value)).toFixed(2);

      setClosingValue(prevState => ({
        ...prevState,
        value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
      }));
    }
  };
  /***** END DEPENDANT VALIDATION *****/


  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'labourMachineryExpense', labour, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(labourMachineryExpenseType.value?.value && { labourMachineryExpenseType: { id: parseInt(labourMachineryExpenseType.value?.value) } }),
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(hasValue(openingQuantity?.value) && { openingQuantity: parseFloatCustom(openingQuantity.value) }),
        ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        ...(hasValue(consumptionQuantity?.value) && { consumptionQuantity: parseFloatCustom(consumptionQuantity.value) }),
        ...(hasValue(consumptionValue?.value) && { consumptionValue: parseFloatCustom(consumptionValue.value) }),
        ...(hasValue(purchaseQuantity?.value) && { purchaseQuantity: parseFloatCustom(purchaseQuantity.value) }),
        ...(hasValue(purchaseValue?.value) && { purchaseValue: parseFloatCustom(purchaseValue.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`labour-machinery-expenses?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.labourMachinery') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`labour-machinery-expenses/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.labourMachinery') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchOverhead = async () => {
    try {
      const { data } = await fetchSingleLabourExpense(props.match.params.id);
      setLabour(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchOverheadExpenseType = async () => {
    const data = await getLabourExpenseType();
    setLabourMachineryExpenseTypeList(data);
  };

  useEffect(() => {
    fetchOverheadExpenseType();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchOverhead().then(labour => {
          labour?.labourMachineryExpenseType && setLabourMachineryExpenseType(prevState => ({
            ...prevState,
            value: defaultOption(labour?.labourMachineryExpenseType),
          }));
          setComment(prevState => ({ ...prevState, value: labour?.comment }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(labour?.openingValue) }));
          setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(labour?.openingQuantity) }));
          setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(labour?.closingQuantity) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(labour?.closingValue) }));
          setConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(labour?.consumptionQuantity) }));
          setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(labour?.consumptionValue) }));
          setPurchaseQuantity(prevState => ({ ...prevState, value: parseDouble(labour?.purchaseQuantity) }));
          setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(labour?.purchaseValue) }));
          setErrorExplanations(labour?.errorExplanations ? labour?.errorExplanations : []);
          let explanations = labour?.errorExplanations ? labour?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'labourMachineryExpense', labour, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        data?.labourMachineryExpenseType && setLabourMachineryExpenseType(prevState => ({
          ...prevState,
          value: defaultOption(data?.labourMachineryExpenseType),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setOpeningQuantity(prevState => ({ ...prevState, value: parseDouble(data?.openingQuantity) }));
        setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(data?.closingQuantity) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setConsumptionQuantity(prevState => ({ ...prevState, value: parseDouble(data?.consumptionQuantity) }));
        setConsumptionValue(prevState => ({ ...prevState, value: parseDouble(data?.consumptionValue) }));
        setPurchaseQuantity(prevState => ({ ...prevState, value: parseDouble(data?.purchaseQuantity) }));
        setPurchaseValue(prevState => ({ ...prevState, value: parseDouble(data?.purchaseValue) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);
        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'labourMachineryExpense', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setLabourMachineryExpenseType(prevState => ({ ...prevState, disabled: false }));
        setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setOpeningQuantity(prevState => ({ ...prevState, disabled: false }));
        setConsumptionQuantity(prevState => ({ ...prevState, disabled: false }));
        setConsumptionValue(prevState => ({ ...prevState, disabled: false }));
        setPurchaseQuantity(prevState => ({ ...prevState, disabled: false }));
        setPurchaseValue(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  const oneEdit = () => {
    setEdit(true);

    // const payload = {
    //   id: props.match.params.id,
    //   agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
    //   agroHoldingId: ahid,
    //   labourMachineryExpenseType: { id: parseInt(labourMachineryExpenseType.value?.value) },
    //   ...(comment?.value && { comment: comment.value }),
    //   ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
    //   ...(hasValue(openingQuantity?.value) && { openingQuantity: parseFloatCustom(openingQuantity.value) }),
    //   ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity.value) }),
    //   ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
    //   ...(hasValue(consumptionQuantity?.value) && { consumptionQuantity: parseFloatCustom(consumptionQuantity.value) }),
    //   ...(hasValue(consumptionValue?.value) && { consumptionValue: parseFloatCustom(consumptionValue.value) }),
    //   ...(hasValue(purchaseQuantity?.value) && { purchaseQuantity: parseFloatCustom(purchaseQuantity.value) }),
    //   ...(hasValue(purchaseValue?.value) && { purchaseValue: parseFloatCustom(purchaseValue.value) }),
    //   errorExplanations: clearEmptyExplanations(errorExplanations),
    // }
    //
    // handleSubmit(false, true, payload);
  };

  useEffect(() => {
    if (!isAudit) {
      changeLabourExtenseType(labourMachineryExpenseType?.value);
    }
  }, [labourMachineryExpenseType?.value, edit]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingQuantity();
    }
  }, [openingQuantity.value,
    purchaseQuantity.value,
    consumptionQuantity.value]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingValue();
    }
  }, [openingValue.value,
    purchaseValue.value,
    consumptionValue.value]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.labourMachineryExpenseType')}
                    placeholder={t('pages.labourExpenses.placeholder.labourMachineryExpenseType')}
                    value={labourMachineryExpenseType.value}
                    name='labourMachineryExpenseType'
                    onChange={(e) => {
                      setLabourMachineryExpenseType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={labourMachineryExpenseTypeList}
                    disabled={labourMachineryExpenseType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['labourMachineryExpenseType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.openingQuantity')}
                    placeholder={t('pages.labourExpenses.placeholder.openingQuantity')}
                    value={openingQuantity.value}
                    name='openingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.openingValue')}
                    placeholder={t('pages.labourExpenses.placeholder.openingValue')}
                    value={openingValue.value}
                    suffix='€'
                    name='openingValue'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.purchaseQuantity')}
                    placeholder={t('pages.labourExpenses.placeholder.purchaseQuantity')}
                    value={purchaseQuantity.value}
                    name='purchaseQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.purchaseValue')}
                    placeholder={t('pages.labourExpenses.placeholder.purchaseValue')}
                    value={purchaseValue.value}
                    suffix='€'
                    name='purchaseValue'
                    type={'double'}
                    onChange={(e) => {
                      setPurchaseValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchaseValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchaseValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.consumptionQuantity')}
                    placeholder={t('pages.labourExpenses.placeholder.consumptionQuantity')}
                    value={consumptionQuantity.value}
                    name='consumptionQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setConsumptionQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumptionQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumptionQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.consumptionValue')}
                    placeholder={t('pages.labourExpenses.placeholder.consumptionValue')}
                    value={consumptionValue.value}
                    suffix='€'
                    name='consumptionValue'
                    type={'double'}
                    onChange={(e) => {
                      setConsumptionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={consumptionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['consumptionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.closingQuantity')}
                    placeholder={t('pages.labourExpenses.placeholder.closingQuantity')}
                    value={closingQuantity.value}
                    name='closingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setClosingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.closingValue')}
                    placeholder={t('pages.labourExpenses.placeholder.closingValue')}
                    value={closingValue.value}
                    suffix='€'
                    name='closingValue'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.labourExpenses.comment')}
                    placeholder={t('pages.labourExpenses.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      oneEdit();
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/expenses/labour-and-machinery`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                oneEdit();
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default LabourExpenseForm;
