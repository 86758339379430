import React, { useContext, useEffect, useState } from 'react';
import { getAgroHoldings } from '../../../services/dashboard/dashboardApi';
import AgriculturalHoldingCard from './components/agriculturalHoldingCard/AgriculturalHoldingCard';
import classes from './Home.module.scss';
import { NavbarTop } from '../../../components/layout/auth/navbar/Navbar';
import { useHistory } from 'react-router-dom';
import AgriculturalHoldingData from '../../../contexts/AgriculturalHoldingData';
import InfiniteScroll from 'react-infinite-scroll-component';
import { axiosGet } from '../../../services/axios';
import { Spinner } from 'react-bootstrap';
import { t } from 'react-switch-lang';
import UserData from '../../../contexts/UserData';
import SaveBtn from '../../../components/buttons/SaveBtn';
import FarmImg from '../../../assets/images/home/farm.png';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import Button from 'antd/lib/button';
import AhRequest from './components/ahRequest/AhRequest';
import LoadingPage from '../errorPages/Loading';
import { useWindowWidth } from '../../../contexts/WindowWidth';

const Home = () => {
  const history = useHistory();

  const [agriculturalHoldings, setAgriculturalHoldings] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [hasAgriculturalHoldings, setHasAgriculturalHoldings] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const windowWidth = useWindowWidth();

  const rows = 20;

  const loader = (
    <div className='text-center mt-3'>
      <Spinner variant='primary' animation='border'/>
    </div>
  );

  const fetchData = async () => {
    try {
      const { data } = await getAgroHoldings(rows);
      setAgriculturalHoldings(data);
      setHasMore(data.length === rows);
      setPage(1);
    } catch (err) {
    }
  };

  const fetchMoreData = async () => {
    try {
      const { data } = await axiosGet(`agro-holdings?page=${page}&size=${rows}`);
      setAgriculturalHoldings([...agriculturalHoldings, ...data]);
      setHasMore(data.length === rows);
      setPage((page) => page + 1);
    } catch (err) {
    }
  };

  useDidMountEffect(() => {
    const activeHoldings = agriculturalHoldings.filter(item => item?.isActive);
    setHasAgriculturalHoldings(activeHoldings.length > 0);
  }, [agriculturalHoldings]);

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const passSelectedAgriculturalHolding = (agriculturalHolding) => {
    agriculturalData.setAgriculturalHoldingData(agriculturalHolding);
    history.push(`/agricultural-holding/${agriculturalHolding.id}/dashboard`);
  };

  const setValidationLoading = (isValidationLoading) => {
    setIsLoading(isValidationLoading);
    !isValidationLoading && fetchData();
  };

  return (
    <>
      {isLoading ?
        <LoadingPage/>
        :
        <div className='d-flex w-100 flex-column position-relative'>
          <NavbarTop home={true}/>
          <AhRequest isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
          <div id='agroContainer' className={`${classes['content']}`}>
            <InfiniteScroll
              next={fetchMoreData}
              hasMore={hasMore}
              loader={loader}
              scrollableTarget='agroContainer'
              dataLength={agriculturalHoldings.length}
              style={{ overflowY: 'hidden', padding: windowWidth >= 450 ? '50px' : '20px' }}
            >
              <div className={`w-100 flex-column`}>
                <div className={`d-flex flex-wrap justify-content-between`}>
                  <div className={classes['content--title']}>
                    {t('pageTitle.home')}
                  </div>
                  <Button className={classes['button']} onClick={() => setIsModalOpen(true)}>
                    {t('ahRequest.sendRequest')}
                  </Button>
                </div>
                <div
                  className={`d-flex flex-wrap justify-content-center w-100 ${classes['content--card']}`}
                >
                  {
                    !hasAgriculturalHoldings &&
                    <div
                      className={`d-flex flex-column justify-content-center align-items-center ${classes.placeholder}`}>
                      <img src={FarmImg} alt='farm-placeholder'/>
                      <div className='mt-5'>{t('pages.dashboard.emptyMessage')}</div>
                      {
                        user.isAdmin && !readOnly &&
                        <SaveBtn
                          label={t('pages.dashboard.addAgriHolding')}
                          className='mb-5 mt-3'
                          onClick={() =>
                            history.push({
                              pathname: 'administration/agricultural-holdings/add',
                              state: {
                                edit: true,
                                add: true,
                              },
                            })
                          }
                        />
                      }
                    </div>
                  }
                  {agriculturalHoldings.map((agriculturalHoldingItem, index) => {
                    return (
                      agriculturalHoldingItem?.isActive &&
                      <AgriculturalHoldingCard
                        setValidationLoading={setValidationLoading}
                        key={index + refresh}
                        agriculturalHolding={agriculturalHoldingItem}
                        onClick={() => {
                          passSelectedAgriculturalHolding(agriculturalHoldingItem);
                        }}
                        refresh={isModalOpen}
                        setRefresh={() => setRefresh((prevState) => !prevState)}
                      />
                    );
                  })}
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      }
    </>
  );
};

export default Home;
