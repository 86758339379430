import React, { useContext, useEffect, useState } from 'react';
import classes from './AgriculturalHoldingCard.module.scss';
import Locked from '../../../../../assets/images/dashboard/locked.svg';
import Unlocked from '../../../../../assets/images/dashboard/unlocked.svg';
import Edit from '../../../../../assets/images/dashboard/edit.svg';
import View from '../../../../../assets/images/dashboard/eye.svg';
import Delete from '../../../../../assets/images/dashboard/delete.svg';
import Bell from '../../../../../assets/images/dashboard/bell.svg';
import { t } from 'react-switch-lang';
import { eng, hasValue, readOnly, isSafari } from '../../../../../utils/const';
import UserData from '../../../../../contexts/UserData';
import { useHistory } from 'react-router-dom';
import Prompt from '../../../../../components/prompt/Prompt';
import { axiosDelete, axiosGet } from '../../../../../services/axios';
import Toast from '../../../../../components/toast/Toast';
import Swal from 'sweetalert2';
import RequestsModal from '../../../administration/requests/RequestsModal';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import BadgeWrapper from '../../../../../components/badgeWrapper/BadgeWrapper';
import {
  lockAgriculturalHolding,
  unlockAgriculturalHolding,
} from '../../../../../services/agriculturalHolding/agriculturalHoldingApi';
import { Tooltip } from 'antd';
import { useNotificationBadge } from '../../../../../contexts/NotificationBadge';

const AgriculturalHoldingCard = React.forwardRef(({
                                                    agriculturalHolding,
                                                    onClick,
                                                    refresh,
                                                    setRefresh,
                                                    setValidationLoading,
                                                  }, ref) => {

  const history = useHistory();

  const user = useContext(UserData);
  const agroHoldingData = useContext(AgriculturalHoldingData);
  const { setHasGlobalRequests } = useNotificationBadge();

  const [isRequestsModalOpen, setIsRequestsModalOpen] = useState(false);
  const [hasRequests, setHasRequests] = useState(false);

  // redirects to different forms for admin and collector
  const agroEditLink = user.isAdmin ? `administration/agricultural-holdings/${agriculturalHolding?.id}` : user.isCollector ? `/agricultural-holdings-collector/${agriculturalHolding?.id}` : `/agricultural-holdings-viewer/${agriculturalHolding?.id}`;

  const onDelete = () => {
    return new Promise((resolve, reject) => {
      Prompt.fire({
        title: t('common.delete') + ' ' + t('messages.agriculturalHolding') + ' ' + agriculturalHolding?.ahFadnId,
        text:
          t('common.deleteQuestion', {
            entity: t('messages.agriculturalHolding') + ' ' + agriculturalHolding?.ahFadnId,
          }) + '?',
        confirmButtonText: t('common.delete'),
        cancelButtonText: t('common.cancel'),
        preConfirm: async () => {
          try {
            const response = await axiosDelete('agro-holdings', agriculturalHolding?.id);
            if (response.status === 204) {
              Toast.fire({
                title:
                  t('common.successDelete', {
                    entity: t('messages.agriculturalHolding') + ' ' + agriculturalHolding?.ahFadnId,
                  }) + '!',
                icon: 'success',
              });
              resolve(response);
              setRefresh();
              // history.push('/home');
            } else {
              reject(response);
            }
          } catch (err) {
            Swal.fire({
              text: t('pages.agriculturalHolding.messages.deleteError'),
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        },
      });
    });
  };

  const lockHolding = async () => {
    setValidationLoading(true);
    try {
      const response = await lockAgriculturalHolding(agriculturalHolding?.id);
      if (response.status === 200) {
        await Toast.fire({
          title: t('administration.lockingErrors.messages.lockingSuccessful'),
          icon: 'success',
        });
        setValidationLoading(false);
      }

    } catch (e) {
      if (e.response?.data.status === 400) {
        await Toast.fire({
          title: t('administration.lockingErrors.messages.lockingUnsuccessful'),
          icon: 'error',
        }).then(() => {
          history.push(`/agricultural-holding/${agriculturalHolding?.id}/locking-errors`);
          setValidationLoading(false);
        });
      }
    }
  };

  const unlockHolding = async () => {
    setValidationLoading(true);
    const response = await unlockAgriculturalHolding(agriculturalHolding?.id);
    if (response.status === 200) {
      await Toast.fire({
        title: t('administration.lockingErrors.messages.unlockingSuccessful'),
        icon: 'success',
      });

      history.push('/home');
      setValidationLoading(false);
    }
  };

  useEffect(() => {
    agriculturalHolding?.id && axiosGet(`requests?agroHoldingId.equals=${agriculturalHolding.id}`)
      .then((response) => {
        setHasRequests(response.data.filter((elem) => !elem.completed).length > 0);
      });

    axiosGet('requests').then((response) => {
      setHasGlobalRequests(response.data.filter((elem) => !elem.completed).length > 0);
    });
  }, [isRequestsModalOpen, refresh]);

  return (
    <div>
      <div className={`d-flex flex-column justify-content-between ${classes['card']} ${isSafari && 'm-3'}`} ref={ref}
           onClick={(e) => {
             e.preventDefault();
             onClick();
           }}>
        <div className={`d-flex justify-content-between align-items-center ${classes['card__header']}`}>
          <div className='d-flex flex-column'>
            <div
              className={`${classes['card__header__title']}`}>{t('common.ah')}&nbsp;{agriculturalHolding.ahFadnId}</div>
            <div />
            <div
              className={`${classes['card__header__subtitle']}`}>{t('pages.dashboard.mibpg')}: {agriculturalHolding.ricaAhId}</div>
          </div>
          <div>
            {(user.isAdmin || user.isCollector) && (hasValue(agriculturalHolding?.lockedDate) ?
              <div>
                <img src={Locked} alt='Locked'
                     onClick={(e) => {
                       e.stopPropagation();
                       unlockHolding();
                     }}
                />
              </div>
              :
              <div>
                <img src={Unlocked} alt='Unlocked'
                     onClick={(e) => {
                       e.stopPropagation();
                       lockHolding();
                     }}
                />
              </div>)
            }
          </div>
        </div>
        <div className={`w-100 d-flex ${classes['card__body']}`}>

          <div className={`w-100 d-flex flex-column ${classes['card__body__box']}`}>
            <div className='w-100 d-flex justify-content-between'>
              <div className='d-flex w-40'>{t('pages.dashboard.organizationalForm')}</div>
              <div
                className='d-flex w-60 text-bold'>{eng ? agriculturalHolding.ownershipType?.nameEn : agriculturalHolding.ownershipType?.name}</div>
            </div>
            <div className='w-100 d-flex justify-content-between'>
              <div className='d-flex w-40'>{t('pages.dashboard.settlement')}</div>
              <div
                className='d-flex w-60 text-bold'>{eng ? agriculturalHolding.county?.nameEn : agriculturalHolding.county?.name}</div>
            </div>
            <div className='w-100 d-flex justify-content-between'>
              <div className='d-flex w-40'>{t('pages.dashboard.typeInitial')}</div>
              <div
                className={`w-60 ${classes['card__body__box__type-initial']}`}>
                <Tooltip title={eng ? agriculturalHolding.farmType?.nameEn : agriculturalHolding.farmType?.name}>
                  {eng ? agriculturalHolding.farmType?.nameEn : agriculturalHolding.farmType?.name}
                </Tooltip>
              </div>
            </div>
            <div className='w-100 d-flex justify-content-between'>
              <div className='d-flex w-40'>{t('pages.dashboard.evInitial')}</div>
              <div
                className='d-flex w-60'>{eng ? agriculturalHolding.economicSize?.nameEn : agriculturalHolding.economicSize?.name}</div>
            </div>
          </div>

        </div>
        <div className={`d-flex justify-content-end ${classes['card__footer']}`}>
          {!readOnly && <img onClick={(e) => {
            e.stopPropagation();
            history.push({
              pathname: agroEditLink,
              state: {
                add: false,
                edit: user.isAdmin || user.isCollector,
              },
            });
          }} src={user.isViewer ? View : Edit} alt={user.isViewer ? 'View' : 'Edit'} />}
          {user.isAdmin && !readOnly && <img onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }} src={Delete} alt='Delete' />}
          <BadgeWrapper id='img' condition={hasRequests}>
            <img style={{ margin: 0 }} onClick={(e) => {
              e.stopPropagation();
              agroHoldingData.setAgriculturalHoldingData(agriculturalHolding);
              setIsRequestsModalOpen(true);
            }} src={Bell} alt='Notification' />
          </BadgeWrapper>
        </div>
      </div>
      {isRequestsModalOpen &&
      <RequestsModal isModalOpen={isRequestsModalOpen} setIsModalOpen={setIsRequestsModalOpen} />}
    </div>
  );
});

export default AgriculturalHoldingCard;