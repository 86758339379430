import React, { useEffect, useRef, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { checkIfExplanationValid, validate } from '../../../utils/validationDynamic';
import { REGEX } from '../../../utils/validationDefaults';
import { axiosGet } from '../../../services/axios';
import './SelectField.scss';
import FieldContainer from '../fieldContainer/FieldContainer';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import usePrevious from '../../../hooks/previousHook/previousHook';
import UserAvatar from '../../avatar/UserAvatar';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

// ! label is optional prop !

// <SelectAsyncField
//                 api='/client-types'
//                 isMulti={true}
//                 onChange={(e) => {
//                 }}
//             />

const SelectAsyncField = (
  {
    label,
    helpLabel,
    showErrors,
    showRequiredError,
    name,
    className,
    placeholder,
    isMulti = false,
    value,
    onSubmit = () => {
    },
    errorExplanations,
    setErrorExplanations = () => {
    },
    hasWriteExplanationPermission = true,
    api,
    apiQuery,
    onChange,
    optionValue,
    optionLabel,
    defaultValue,
    setSubmitDisabled,
    disabled = true,
    pagination = true,
    pageSize = 20,
    searchTerm,
    customLabelFormat,
    sort,
    isClearable = false,
    isSearchable = true,
    validation = { type: '' },
    user = false,
    styles,
    ...rest
  }) => {
  let uniqueId = uniqid();
  let componentName = name ? name : uniqueId;

  const redirectPrompt = useRedirectPrompt();

  const [messageData, setMessageData] = useState(validate({ ...validation, required: { value: !disabled } }, value));
  const [isDirty, setIsDirty] = useState(false);

  const prevVal = usePrevious(value);
  const prevValidation = usePrevious({ ...validation, required: { value: !disabled } });

  const lang = localStorage.getItem('language');

  useEffect(() => {
    // on component unmounting return states to initial
    return () => {
      setMessageData('');
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    if ((!compareObjects(prevVal, value)) || (!compareObjects({ ...validation, required: { value: !disabled } }, prevValidation))) {

      let errorObject = validate({ ...validation, required: { value: !disabled } }, value);

      if (errorObject?.required?.errorType === "required" || errorObject?.apiErrors?.errorType === "apiError") {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
      } else {
        if (!checkIfExplanationValid(errorObject)) {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
        } else {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: false }));
        }
      }
      isDirty && disabled === false && onSubmit();
      setMessageData(errorObject);
    }
    // eslint-disable-next-line
  }, [value, validation, disabled]);

  const [isFocused, setIsFocused] = useState(true);
  const optionVal = optionValue !== undefined ? optionValue : 'id';
  const optionLab = optionLabel !== undefined ? optionLabel : lang === 'eng' ? 'nameEn' : 'name';

  const formatApi = (page, search) => {
    const apiArr = [
      sort ? sort : null,
      pagination ? `page=${page}&size=${pageSize}` : null,
      apiQuery ? `${apiQuery}` : null,
      isSearchable && search
        ? `${searchTerm ? searchTerm : `${optionLab}.contains`}=${search}`
        : null,
    ];
    return `?${apiArr.filter(Boolean).join('&')}`;
  };

  const loadOptions = async (search, previous, { page, pageSize }) => {
    if (isFocused) {
      let filteredOptions = [];
      let hasMore = true;

      const formatOptions = (options) => {
        return {
          options: options.map((option) => ({
            value: option[optionVal],
            label:
              user === true ? (
                <div className="d-flex">
                  <UserAvatar user={option} className="mr-2"/>
                  <div className="d-flex align-items-center ml-2 ">
                    {option.firstName} {option.lastName}
                  </div>
                </div>
              ) : customLabelFormat ? (
                customLabelFormat(option)
              ) : (
                option[optionLab]
              ),
          })),
          hasMore: hasMore,
          additional: {
            page: page + 1,
            pageSize,
          },
          disabled: true,
        };
      };

      if (!search) {
        try {
          const { data } = await axiosGet(`${api}${formatApi(page)}`);
          filteredOptions = data;
          hasMore = data.length > 0 && pagination !== false;
        } catch (e) {
          hasMore = false;
        }
        return formatOptions(filteredOptions);
      }

      if (search.length < 1) {
        return { options: filteredOptions, hasMore: false };
      }

      try {
        if (search.match(REGEX.validNameAndNumberRegex) && search.length < 200) {
          const { data } = await axiosGet(`${api}${formatApi(page, search)}`);
          filteredOptions = data;
          hasMore = data > 0 && pagination !== false;
        } else {
          filteredOptions = [];
          hasMore = false;
        }
      } catch (e) {
        hasMore = false;
      }
      return formatOptions(filteredOptions);
    }
  };

  /*Hide select on outside click*/
  const select = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(false);
    setIsFocused(false);
  };

  const handleClickOutside = (e) => {
    if (select.current && !select.current.contains(e.target)) {
      toggle(e);
    }
  };

  useEffect(() => {
    isOpen
      ? document.addEventListener('mousedown', handleClickOutside, false)
      : document.removeEventListener('mousedown', handleClickOutside, false);
    // Clear eventListener
    return () => document.removeEventListener('mousedown', toggle, false);
  }, [isOpen]);
  /*End hide select on outside click*/

  return (
    <FieldContainer
      label={label}
      componentName={componentName}
      showErrors={showErrors}
      required={
        !disabled
      }
      additionalMessageData={messageData}
      customClasses={`${styles}`}
      errorExplanations={errorExplanations}
      setErrorExplanations={setErrorExplanations}
      hasWriteExplanationPermission={hasWriteExplanationPermission}
      onSubmit={onSubmit}
      showRequiredMessage={(isDirty || showRequiredError)}
      {...rest}
    >
      <AsyncPaginate
        defaultOptions
        inputId={name}
        name={name}
        placeholder={placeholder ? !disabled && placeholder : !disabled && t('common.selectValue')}
        className={`select-field-general ${className ? className : ''} ${
          disabled ? 'disabled ' : ''
        } ${showErrors && messageData?.errors?.errorType !== 'noErrors' 
          ? ' error ' : ''} ${(isDirty || showRequiredError) && messageData?.required?.errorType !== 'noErrors' 
          ? ' error ' : ''}`}
        onChange={(e) => {
          onChange(e);
          setIsDirty(true);
          redirectPrompt.setIsDirty(true);
        }}
        value={value}
        isMulti={isMulti}
        loadOptions={isFocused ? loadOptions : { options: [] }}
        noOptionsMessage={() => {
          t('common.noData');
        }}
        loadingMessage={() => t('common.loading')}
        debounceTimeout={300}
        additional={{ page: 0, pageSize: 20 }}
        closeMenuOnSelect={!isMulti}
        defaultValue={defaultValue}
        isDisabled={disabled}
        isClearable={isClearable}
        menuIsOpen={isOpen}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => {
          setIsOpen(false);
          setIsDirty(true);
        }}
        onFocus={() => setIsFocused(true)}
      />
    </FieldContainer>
  );
};
SelectAsyncField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isClearable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isSearchable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isMulti: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  pageSize: PropTypes.number,
  api: PropTypes.string.isRequired,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  validation: PropTypes.object,
};

export default SelectAsyncField;
