import { t } from 'react-switch-lang';
import { eng } from '../../../../../../../utils/const';

const sheepFatteningFields = [
  {
    value: 'sheepCategory',
    label: () => t('pages.sheep.categoryNameSheep'),
    type: 'select', // integer, double, select, textarea, string
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api:'sheep-categories',
      apiQuery: 'canBeServiceFattening.equals=true',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.sheep.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathQuantity',
    label: () => t('pages.sheep.deathQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'inputQuantity',
    label: () => t('pages.sheep.inputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'outputQuantity',
    label: () => t('pages.sheep.outputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.sheep.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.sheep.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default sheepFatteningFields;