import React, { useEffect, useState } from 'react';
import Logo from '../../../assets/images/error/Group 981.svg';
import './ErrorPages.scss';
import { withRouter } from 'react-router-dom';
import { t } from 'react-switch-lang';

// eslint-disable-next-line react/prop-types
function ErrorPage404({ history }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 200);
    return () => {
      setShow(false);
    };
  }, []);
  return (
    <div className={`error-page-container position-relative ${show ? '' : 'hide'}`}>
      <div className={`d-inline-block position-absolute info-containter`}>
        <div className="errorCode d-flex justify-content-center align-items-center">
          <img src={Logo} alt="Logo"  />
        </div>
        <div className="message-text">
          <span>{t('common.pageNotFound')}</span>
        </div>
        <div className="return-text">
          {/* eslint-disable-next-line react/prop-types */}
          <button onClick={() => history.go(-1)}>{t('common.goBack')}</button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ErrorPage404);
