import { axiosGet } from '../axios';
import { eng } from "../../utils/const";

const defaultOption = (option) => {
  return {
    label: eng ? option.nameEn : option.name,
    value: option.id,
    disabled: false,
  };
};

export const fetchSingleFarmBuilding = (id) => {
  return axiosGet(`farm-buildings/${id}`);
};

export const fetchObjectTypes = () => {
  return axiosGet('object-types').then(r => {
    let arrTemp = [];
    for (let item of r.data) {
      arrTemp.push(
        defaultOption(item),
      );
    }
    return { data: r.data, filtered: arrTemp };
  }).catch(() => ({ data: [], filtered: [] }));
};
