import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getOtherProducts,
  getProductionCodes,
  getProductionOtherProducts,
} from '../../../../../services/production/otherProducts/otherProductsApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './OtherProductsForm.module.scss';
import { Toast } from '../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import UserData from '../../../../../contexts/UserData';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import SelectAsyncField from '../../../../../components/formDynamic/selectField/SelectAsyncField';
import Loader from '../../../../../components/loader/Loader';

const OtherProductsForm = ({ props, data, isAudit = false }) => {

  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    productionOtherProduct: { type: 'selectSingle', required: { value: true } },
    productionCode: { type: 'selectSingle', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    totalValue: { type: 'number', required: { value: false } },
    area: { type: 'number', required: { value: true } },
  };

  const [errorExplanations, setErrorExplanations] = useState([]);
  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState(validationInitial);

  // Enums
  const [productionOtherProductOptionsList, setProductionOtherProductOptionsList] = useState([]);
  const [productionCodeOptionsList, setProductionCodeOptionsList] = useState([]);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);


  const [otherProducts, setOtherProducts] = useState();
  const [productionOtherProduct, setProductionOtherProduct] = useState({ value: undefined, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [productionCode, setProductionCode] = useState({ value: undefined, disabled: !edit });
  const [totalValue, setTotalValue] = useState({ value: 0, disabled: !edit });
  const [area, setArea] = useState({ value: 0, disabled: !edit });

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/

  const setProductionFields = (e) => {
    if (e?.value) {
      if (e?.value !== 10) {
        setProductionCode(prevState => ({ ...prevState, value: null, disabled: true }));
        setArea(prevState => ({ ...prevState, value: 0, disabled: true }));
      } else {
        setProductionCode(prevState => ({ ...prevState, disabled: !edit }));
        setArea(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };
  /***** END DEPENDANT VALIDATION *****/

  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'otherProduct', otherProducts, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/

  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(productionOtherProduct.value?.value && {
          productionOtherProduct: { id: parseInt(productionOtherProduct.value?.value) },
        }),
        ...(productionCode.value && {
          productionCode: {
            id: parseInt(productionCode.value?.value),
          },
        }),
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(area?.value) && { area: parseFloatCustom(area.value) }),
        ...(hasValue(totalValue?.value) && { totalValue: parseFloatCustom(totalValue.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`other-products?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.otherProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`other-products/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.otherProducts') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchOtherProduct = async () => {
    try {
      const { data } = await getOtherProducts(props.match.params.id);
      setOtherProducts(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };

  const fetchProductionOtherProducts = async () => {
    try {
      const { data } = await getProductionOtherProducts();
      let productionOtherProductsTemp = [];
      for (let productionOtherProduct of data) {
        productionOtherProductsTemp.push(
          defaultOption(productionOtherProduct),
        );
      }
      setProductionOtherProductOptionsList([...productionOtherProductsTemp]);
    } catch (e) {
    }
  };

  const fetchProductionCodes = async () => {
    try {
      const { data } = await getProductionCodes();
      let productionCodesTemp = [];
      for (let productionCode of data) {
        productionCodesTemp.push(
          defaultOption(productionCode),
        );
      }
      setProductionCodeOptionsList([...productionCodesTemp]);
    } catch (e) {
    }
  };

  useEffect(() => {
    fetchProductionOtherProducts();
    fetchProductionCodes();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchOtherProduct().then(otherProducts => {
          setProductionOtherProduct(prevState => ({
            ...prevState,
            value: otherProducts?.productionOtherProduct ? defaultOption(otherProducts?.productionOtherProduct) : undefined,
          }));
          setProductionCode(prevState => ({
            ...prevState,
            value: otherProducts?.productionCode ? defaultOption(otherProducts?.productionCode) : undefined,
          }));
          setComment(prevState => ({ ...prevState, value: otherProducts?.comment }));
          setTotalValue(prevState => ({ ...prevState, value: parseDouble(otherProducts?.totalValue) }));
          setArea(prevState => ({ ...prevState, value: parseDouble(otherProducts?.area) }));
          setErrorExplanations(otherProducts?.errorExplanations ? otherProducts?.errorExplanations : []);

          let explanations = otherProducts?.errorExplanations ? otherProducts?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'otherProduct', otherProducts, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        setProductionOtherProduct(prevState => ({
          ...prevState,
          value: data?.productionOtherProduct ? defaultOption(data?.productionOtherProduct) : undefined,
        }));
        setProductionCode(prevState => ({
          ...prevState,
          value: data?.productionCode ? defaultOption(data?.productionCode) : undefined,
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setTotalValue(prevState => ({ ...prevState, value: parseDouble(data?.totalValue) }));
        setArea(prevState => ({ ...prevState, value: parseDouble(data?.area) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'otherProduct', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setProductionOtherProduct(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setProductionCode(prevState => ({ ...prevState, disabled: false }));
        setTotalValue(prevState => ({ ...prevState, disabled: false }));
        setArea(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  useEffect(() => {
    if (!isAudit) {
      setProductionFields(productionOtherProduct?.value);
    }
  }, [productionOtherProduct?.value, edit]);

  // const prevVal = usePrevious(submitDisabled);
  //
  // useDidMountEffect(() => {
  //   (!compareObjects(submitDisabled, prevVal) && showErrors) && handleSubmit(false);
  //   (!compareObjects(submitDisabled, prevVal) && showErrors);
  // }, [submitDisabled]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectAsyncField
                    pagination={false}
                    api='production-other-products'
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.otherProducts.productionOtherProduct')}
                    placeholder={t('pages.otherProducts.placeholders.productionOtherProduct')}
                    value={productionOtherProduct.value}
                    name='productionOtherProduct'
                    onChange={(e) => {
                      setProductionOtherProduct(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                      setShowErrors(true);
                    }}
                    disabled={productionOtherProduct.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionOtherProduct']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <SelectAsyncField
                    pagination={false}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    api='production-codes'
                    label={t('pages.otherProducts.productionCode')}
                    placeholder={t('pages.otherProducts.placeholders.productionCode')}
                    value={productionCode.value}
                    name='productionCode'
                    onChange={(e) => {
                      setProductionCode(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                      setShowErrors(true);
                    }}
                    disabled={productionCode.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['productionCode']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.otherProducts.area')}
                    placeholder={t('pages.otherProducts.placeholders.area')}
                    value={area.value}
                    name='area'
                    suffix='ha'
                    type={'double'}
                    onChange={(e) => {
                      setArea(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={area.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['area']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    label={t('pages.otherProducts.totalValue')}
                    placeholder={t('pages.otherProducts.placeholders.totalValue')}
                    value={totalValue.value}
                    suffix='€'
                    name='totalValue'
                    type={'double'}
                    onChange={(e) => {
                      setTotalValue({
                        ...totalValue,
                        value: e,
                      });
                    }}
                    disabled={totalValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['totalValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.otherProducts.comment')}
                    placeholder={t('pages.otherProducts.placeholders.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/production/other-products`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};

export default OtherProductsForm;
