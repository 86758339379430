import { t } from 'react-switch-lang';
import Tooltip from 'antd/lib/tooltip';
import React from 'react';
import { eng, formatNumber, hasValue } from '../../../../../utils/const';

// tooltip that shows on table entry hover
const renderColWithTooltip = (col) => {
  return (
    <Tooltip title={col} placement="topLeft" color={'#67B278'}>
      {hasValue(col) ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.machineryAndEquipment.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: (agroHoldingFadnId) => renderColWithTooltip(agroHoldingFadnId),
  },
  {
    title: t('pages.machineryAndEquipment.mechanizationType'),
    dataIndex: ['mechanizationType', eng ? 'nameEn' : 'name'],
    key: 'mechanizationType',
    ellipsis: true,
    render: (mechanizationType) => renderColWithTooltip(mechanizationType),
  },
  {
    title: t('pages.machineryAndEquipment.power'),
    dataIndex: 'power',
    key: 'power',
    ellipsis: true,
    render: (power) => renderColWithTooltip(formatNumber(power)),
  },
  {
    title: t('pages.machineryAndEquipment.openingValue'),
    dataIndex: 'openingValue',
    key: 'openingValue',
    ellipsis: true,
    render: (openingValue) => renderColWithTooltip(formatNumber(openingValue)),
  },
  {
    title: t('pages.machineryAndEquipment.closingValue'),
    dataIndex: 'closingValue',
    key: 'closingValue',
    ellipsis: true,
    render: (closingValue) => renderColWithTooltip(formatNumber(closingValue)),
  },
];
