import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classes from './AccountActivation.module.scss';
import AgroLogo from '../../../assets/images/agro-logo.png';
import Ministry from '../../../assets/images/ministry.svg';
import Government from '../../../assets/images/government.svg';
import { eng } from '../../../utils/const';
import LogoEn from '../../../assets/images/logo-en-big.svg';
import Logo from '../../../assets/images/logo-big.svg';
import { setLanguage, t } from 'react-switch-lang';
import SaveBtn from '../../../components/buttons/SaveBtn';
import { activateUser } from '../../../services/users/usersAPI';
import { Spinner } from 'react-bootstrap';

const AccountActivation = () => {
  const history = useHistory();
  const { search } = useLocation();

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  setLanguage(search.split('lang=')[1]);

  useEffect(() => {
    activateUser(search)
      .then(() => {
        setLoading(false);
        setMessage(t('register.validationSuccess'));
      })
      .catch(() => history.push('/login'));
  }, []);

  return (
    <div
      className={`d-flex vw-100 vh-100 align-items-center justify-content-center ${classes['contentWrapper']}`}
    >
      <div
        className={`${classes['contentWrapper__background']} w-50 h-100 d-flex flex-column justify-content-between align-items-center`}
      >
        <div className="pt-4">
          <img src={AgroLogo} alt="Agricultural logo" width={'330px'} height={'auto'} />
        </div>
        <div className="d-flex w-75 pb-4 justify-content-between">
          <img src={Ministry} alt="Ministry" />
          <img src={Government} alt="Government" />
        </div>
      </div>
      <div className="w-50 d-flex flex-column align-items-center">
        <img
          src={eng ? LogoEn : Logo}
          alt="Logo"
          className={`${classes['contentWrapper__logo']}`}
          width={'183px'}
          height={'auto'}
        />
        {loading ? (
          <div className="text-center mt-3">
            <Spinner variant="primary" animation="border" />
          </div>
        ) : (
          <>
            <div className={`${classes['contentWrapper__welcome']}`}>{message}</div>
            <SaveBtn
              loaderOnDisabled={false}
              loader={false}
              label={t('login.enter')}
              className="pl-5 pr-5"
              onClick={() => {
                history.push('/login');
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AccountActivation;
