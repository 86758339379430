import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../components/formDynamic/inputField/InputField';
import DateField from '../../../../components/formDynamic/dateField/DateField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../services/axios';
import SelectConstField from '../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getLabour, getWorkforceRoles, getWorkforceTypes } from '../../../../services/labour/labourApi';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../components/buttons/CancelBtn';
import classes from './LabourForm.module.scss';
import { Toast } from '../../../../utils/sweetAlert';
import { agriculturalEducationEnum, genderEnum } from '../utils/labourEnums';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../utils/const';
import UserData from '../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../components/formDynamic/helpers';
import Loader from '../../../../components/loader/Loader';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const LabourForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const [labour, setLabour] = useState();
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const [workforceType, setWorkforceType] = useState({ value: undefined, disabled: !edit });
  const [workforceRole, setWorkforceRole] = useState({ value: undefined, disabled: !edit });
  const [yearOfBirth, setYearOfBirth] = useState({ value: undefined, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [gender, setGender] = useState({ value: undefined, disabled: !edit });
  const [agriculturalEducation, setAgriculturalEducation] = useState({ value: undefined, disabled: !edit });
  const [numberOfPersons, setNumberOfPersons] = useState({ value: 0, disabled: !edit });
  const [numberOfWorkingDays, setNumberOfWorkingDays] = useState({ value: 0, disabled: !edit });
  const [annualWorkUnits, setAnnualWorkUnits] = useState({ value: 0, disabled: !edit });
  const [percentOfAWU, setPercentOfAWU] = useState({ value: 0, disabled: !edit });
  const [salary, setSalary] = useState({ value: 0, disabled: !edit });
  const [additionalActivitiesPercent, setAdditionalActivitiesPercent] = useState({ value: 0, disabled: !edit });

  const [errorExplanations, setErrorExplanations] = useState([]);

  // Enums
  const [workforceTypeOptionsList, setWorkforceTypeOptionsList] = useState([]);
  const [workforceRoleOptionsList, setWorkforceRoleOptionsList] = useState([]);
  const genderOptionsList = genderEnum;
  const agriculturalEducationOptionsList = agriculturalEducationEnum;

  const validationInitial = {
    workforceType: { type: 'selectSingle', required: { value: true } },
    workforceRole: { type: 'selectSingle', required: { value: true } },
    yearOfBirth: { type: 'date', required: { value: false } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    gender: { type: 'selectSingle', required: { value: false } },
    agriculturalEducation: { type: 'selectSingle', required: { value: false } },
    numberOfPersons: { type: 'number', required: { value: false } },
    numberOfWorkingDays: { type: 'number', required: { value: false } },
    annualWorkUnits: { type: 'number', required: { value: false }, min: { value: 0 } },
    percentOfAWU: { type: 'number', required: { value: false }, min: { value: 0 }, max: { value: 100 } },
    salary: {
      type: 'number',
      required: { value: false },
      warning: { value: true, messages: t('pages.labour.warnings.salary') },
    },
    additionalActivitiesPercent: { type: 'number', required: { value: false } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [validation, setValidation] = useState(validationInitial);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit;

  /***** START DEPENDANT VALIDATION *****/
  const setWorkforceTypeDependentFields = (workForceType) => {
    if (workForceType) {
      const list = workforceRoleOptionsList;
      if (workForceType === 1) {
        list[1].disabled = true;
        setWorkforceRoleOptionsList([...list]);
        if (workforceRole?.value?.value === 2) {
          setWorkforceRole(prevState => ({ ...prevState, value: null }));
        }
      } else {
        list[1].disabled = false;
        setWorkforceRoleOptionsList([...list]);
      }

      if (workForceType === 2) {
        setSalary(prevState => ({ ...prevState, value: 0, disabled: true }));
      } else {
        setSalary(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };

  const [agriculturalEducationRequired, setAgriculturalEducationRequired] = useState(false);
  const setWorkforceRoleDependentFields = (workForceRole) => {
    if (workForceRole !== 1) {
      setGender(prevState => ({ ...prevState, value: null, disabled: true }));
      setAgriculturalEducationRequired(false);
    } else {
      setGender(prevState => ({ ...prevState, disabled: !edit }));
      setAgriculturalEducationRequired(true);
    }

    if ([1, 2].includes(workForceRole)) {
      setNumberOfPersons(prevState => ({ ...prevState, value: 1, disabled: true }));
    } else {
      setNumberOfPersons(prevState => ({ ...prevState, disabled: !edit }));
    }

    if ([3, 4].includes(workForceRole)) {
      setYearOfBirth(prevState => ({ ...prevState, value: null, disabled: true }));
      setAgriculturalEducation(prevState => ({ ...prevState, value: null, disabled: true }));
    } else {
      setYearOfBirth(prevState => ({ ...prevState, disabled: !edit }));
      setAgriculturalEducation(prevState => ({ ...prevState, disabled: !edit }));
    }

    if (workForceRole === 4) {
      setAnnualWorkUnits(prevState => ({ ...prevState, value: null, disabled: true }));
    } else {
      setAnnualWorkUnits(prevState => ({ ...prevState, disabled: !edit }));
    }
  };

  const setNumberOfPersonsDependentFields = (numberOfPersons) => {
    if (numberOfPersons === 1) {
      validation['numberOfWorkingDays'] = { type: 'number', required: { value: false }, max: { value: 365 } };
    } else {
      validation['numberOfWorkingDays'] = { type: 'number', required: { value: false } };
    }
  };
  /***** END DEPENDANT VALIDATION *****/


  /***** START SET API ERRORS *****/

//labour
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'labour', labour, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /***** START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => true)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        ...(hasValue(workforceType.value?.value) && { workforceType: { id: parseInt(workforceType.value?.value) } }),
        ...(hasValue(workforceRole.value?.value) && { workforceRole: { id: parseInt(workforceRole.value?.value) } }),
        ...(hasValue(yearOfBirth?.value) && { yearOfBirth: yearOfBirth.value.getFullYear() }),
        ...(hasValue(comment?.value) && { comment: comment.value }),
        ...(hasValue(gender?.value?.value) && { gender: { id: parseInt(gender.value?.value) } }),
        ...(hasValue(numberOfPersons?.value) && { numberOfPersons: parseInt(numberOfPersons.value) }),
        ...(hasValue(numberOfWorkingDays?.value) && { numberOfWorkingDays: parseInt(numberOfWorkingDays.value) }),
        ...(hasValue(annualWorkUnits?.value) && { annualWorkUnits: parseFloatCustom(annualWorkUnits.value) }),
        ...(hasValue(percentOfAWU?.value) && { percentOfAWU: parseFloatCustom(percentOfAWU.value) }),
        ...(hasValue(salary?.value) && { salary: parseFloatCustom(salary.value) }),
        ...(hasValue(agriculturalEducation?.value?.value) && { agriculturalEducation: { id: parseInt(agriculturalEducation.value?.value) } }),
        ...(hasValue(additionalActivitiesPercent?.value) && { additionalActivitiesPercent: parseInt(additionalActivitiesPercent.value) }),
        errorExplanations: clearEmptyExplanations(errorExplanations),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`labour?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.labour') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add');
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`labour/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.labour') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit');
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /***** END SUBMIT FORM *****/

  const fetchLabour = async () => {
    try {
      const { data } = await getLabour(props.match.params.id);
      setLabour(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };

  const fetchWorkForceTypes = async () => {
    try {
      const { data } = await getWorkforceTypes();
      let workforceTypesTemp = [];
      for (let workforceType of data) {
        workforceTypesTemp.push(
          defaultOption(workforceType),
        );
      }
      setWorkforceTypeOptionsList([...workforceTypesTemp]);
    } catch (e) {
    }
  };

  const fetchWorkForceRoles = async () => {
    try {
      const { data } = await getWorkforceRoles();
      let workforceRolesTemp = [];
      for (let workforceRole of data) {
        workforceRolesTemp.push(
          defaultOption(workforceRole),
        );
      }
      setWorkforceRoleOptionsList([...workforceRolesTemp]);
    } catch (e) {
    }
  };

  useEffect(() => {
    fetchWorkForceRoles();
    fetchWorkForceTypes();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchLabour().then(labour => {
          setWorkforceType(prevState => ({
            ...prevState,
            value: defaultOption(labour?.workforceType),
          }));
          setWorkforceRole(prevState => ({
            ...prevState,
            value: defaultOption(labour?.workforceRole),
          }));
          setGender(prevState => ({
            ...prevState,
            value: genderOptionsList.find(option => parseInt(option.value) === labour?.gender?.id),
          }));
          setAgriculturalEducation(prevState => ({
            ...prevState,
            value: agriculturalEducationOptionsList.find(option => parseInt(option.value) === labour?.agriculturalEducation?.id),
          }));
          setYearOfBirth(prevState => ({
            ...prevState,
            value: labour?.yearOfBirth ? new Date(parseInt(labour?.yearOfBirth), 1, 1) : null,
          }));
          setComment(prevState => ({ ...prevState, value: labour?.comment }));
          setNumberOfPersons(prevState => ({ ...prevState, value: labour?.numberOfPersons }));
          setNumberOfWorkingDays(prevState => ({ ...prevState, value: labour?.numberOfWorkingDays }));
          setAnnualWorkUnits(prevState => ({ ...prevState, value: parseDouble(labour?.annualWorkUnits) }));
          setPercentOfAWU(prevState => ({ ...prevState, value: labour?.percentOfAWU }));
          setSalary(prevState => ({ ...prevState, value: parseDouble(labour?.salary) }));
          setAdditionalActivitiesPercent(prevState => ({ ...prevState, value: labour?.additionalActivitiesPercent }));
          setErrorExplanations(labour?.errorExplanations ? labour?.errorExplanations : []);

          let explanations = labour?.errorExplanations ? labour?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'labour', labour, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        if (!_.isEmpty(data)) {
          setWorkforceType(prevState => ({
            ...prevState,
            value: defaultOption(data?.workforceType),
          }));
          setWorkforceRole(prevState => ({
            ...prevState,
            value: defaultOption(data?.workforceRole),
          }));
          setGender(prevState => ({
            ...prevState,
            value: genderOptionsList.find(option => parseInt(option.value) === data?.gender?.id),
          }));
          setAgriculturalEducation(prevState => ({
            ...prevState,
            value: agriculturalEducationOptionsList.find(option => parseInt(option.value) === data?.agriculturalEducation?.id),
          }));
          setYearOfBirth(prevState => ({
            ...prevState,
            value: data?.yearOfBirth ? new Date(parseInt(data?.yearOfBirth), 1, 1) : null,
          }));
          setComment(prevState => ({ ...prevState, value: data?.comment }));
          setNumberOfPersons(prevState => ({ ...prevState, value: data?.numberOfPersons }));
          setNumberOfWorkingDays(prevState => ({ ...prevState, value: data?.numberOfWorkingDays }));
          setAnnualWorkUnits(prevState => ({ ...prevState, value: parseDouble(data?.annualWorkUnits) }));
          setPercentOfAWU(prevState => ({ ...prevState, value: data?.percentOfAWU }));
          setSalary(prevState => ({ ...prevState, value: parseDouble(data?.salary) }));
          setAdditionalActivitiesPercent(prevState => ({ ...prevState, value: data?.additionalActivitiesPercent }));
          setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

          let explanations = data?.errorExplanations ? data?.errorExplanations : [];

          handleApiErrorsFn({}, 'audit', validation, 'labour', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        }
      }
    }

    // hasValue(isLocked) && setEdit(false);
  }, [data]);

  useEffect(() => {
    if (edit) {
      // setValidation({ ...validationInitial });
      setWorkforceType(prevState => ({ ...prevState, disabled: false }));
      setWorkforceRole(prevState => ({ ...prevState, disabled: false }));
      setComment(prevState => ({ ...prevState, disabled: false }));
      setNumberOfWorkingDays(prevState => ({ ...prevState, disabled: false }));
      setPercentOfAWU(prevState => ({ ...prevState, disabled: false }));
      setAdditionalActivitiesPercent(prevState => ({ ...prevState, disabled: false }));
    }
  }, [edit]);

  useDidMountEffect(() => {
    if (!isAudit) {
      setWorkforceTypeDependentFields(workforceType?.value?.value);
    }
  }, [workforceType?.value, edit]);

  useDidMountEffect(() => {
    if (!isAudit) {
      setWorkforceRoleDependentFields(workforceRole?.value?.value);
    }
  }, [workforceRole?.value, edit]);

  useDidMountEffect(() => {
    if (!isAudit) {
      setNumberOfPersonsDependentFields(parseFloat(numberOfPersons?.value));
    }
  }, [numberOfPersons?.value, edit]);

  // useDidMountEffect(() => {
  //   handleSubmit(false);
  // }, [agriculturalEducation?.value, yearOfBirth?.value, gender?.value, annualWorkUnits?.value, numberOfPersons?.value]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size="sm"/>
        :
        <>
          <div className="d-flex flex-column justify-content-center w-100">
            <form style={{ width: '100%' }}>
              <div className="d-flex justify-content-center flex-column flex-md-row w-100">
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    label={t('pages.labour.workforceType')}
                    placeholder={t('pages.labour.placeholder.workforceType')}
                    value={workforceType.value}
                    name="workforceType"
                    onChange={(e) => {
                      setWorkforceType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={workforceTypeOptionsList}
                    disabled={workforceType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['workforceType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <SelectConstField
                    label={t('pages.labour.workforceRole')}
                    placeholder={t('pages.labour.placeholder.workforceRole')}
                    value={workforceRole.value}
                    name="workforceRole"
                    onChange={(e) => {
                      setWorkforceRole(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={workforceRoleOptionsList}
                    disabled={workforceRole.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['workforceRole']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <DateField
                    label={t('pages.labour.yearOfBirth')}
                    placeholder={t('pages.labour.placeholder.yearOfBirth')}
                    value={yearOfBirth.value}
                    name={'yearOfBirth'}
                    onChange={(e) => {
                      setYearOfBirth(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    disabled={yearOfBirth.disabled}
                    showYearPicker={true}
                    isClearable={false}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['yearOfBirth']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    minDate={new Date('02-01-1899')}
                  />
                  <SelectConstField
                    label={t('pages.labour.gender')}
                    placeholder={t('pages.labour.placeholder.gender')}
                    value={gender.value}
                    name="gender"
                    onChange={(e) => {
                      setGender(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={genderOptionsList}
                    disabled={gender.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['gender']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <SelectConstField
                    label={t('pages.labour.agriculturalEducation')}
                    placeholder={t('pages.labour.placeholder.agriculturalEducation')}
                    value={agriculturalEducation.value}
                    name="agriculturalEducation"
                    onChange={(e) => {
                      setAgriculturalEducation(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={agriculturalEducationOptionsList}
                    disabled={agriculturalEducation.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['agriculturalEducation']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={agriculturalEducationRequired}
                    isClearable={!agriculturalEducationRequired}
                    isDirtyProp={false}
                  />
                  <InputField
                    label={t('pages.labour.numberOfPersons')}
                    placeholder={t('pages.labour.placeholder.numberOfPersons')}
                    value={numberOfPersons.value}
                    name="numberOfPersons"
                    type={'number'}
                    onChange={(e) => {
                      setNumberOfPersons(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={numberOfPersons.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['numberOfPersons']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    label={t('pages.labour.numberOfWorkingDays')}
                    placeholder={t('pages.labour.placeholder.numberOfWorkingDays')}
                    helpLabel={t('pages.labour.help.numberOfWorkingDays')}
                    value={numberOfWorkingDays.value}
                    name="numberOfWorkingDays"
                    type={'number'}
                    onChange={(e) => {
                      setNumberOfWorkingDays(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={numberOfWorkingDays.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['numberOfWorkingDays']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.labour.annualWorkUnits')}
                    placeholder={t('pages.labour.placeholder.annualWorkUnits')}
                    value={annualWorkUnits.value}
                    name="annualWorkUnits"
                    type={'double'}
                    onChange={(e) => {
                      setAnnualWorkUnits(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={annualWorkUnits.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['annualWorkUnits']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.labour.additionalAnnualWorkUnit')}
                    placeholder={t('pages.labour.placeholder.additionalAnnualWorkUnit')}
                    value={percentOfAWU.value}
                    name="percentOfAWU"
                    type={'number'}
                    // suffix={'%'}
                    onChange={(e) => {
                      setPercentOfAWU(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={percentOfAWU.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['percentOfAWU']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.labour.salary')}
                    placeholder={t('pages.labour.placeholder.salary')}
                    helpLabel={t('pages.labour.help.salary')}
                    suffix="€"
                    value={salary.value}
                    name="salary"
                    type={'double'}
                    onChange={(e) => {
                      setSalary(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={salary.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['salary']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    label={t('pages.labour.additionalActivitiesPercent')}
                    placeholder={t('pages.labour.placeholder.additionalActivitiesPercent')}
                    helpLabel={t('pages.labour.help.additionalActivitiesPercent')}
                    value={additionalActivitiesPercent.value}
                    name="additionalActivitiesPercent"
                    type={'number'}
                    onChange={(e) => {
                      setAdditionalActivitiesPercent(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={additionalActivitiesPercent.disabled}
                    styles={`${classes['formFieldsWidth']}`}

                    validation={validation['additionalActivitiesPercent']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <TextareaField
                    label={t('pages.labour.comment')}
                    placeholder={t('pages.labour.placeholder.comment')}
                    value={comment.value}
                    name="comment"
                    groupField
                    onChange={(e) => {
                      setComment(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/labour`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  formValidation={validation}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default LabourForm;
