export const routes = [
  'labour',
  'agriculturalLand',
  'permanentCrop',
  'farmBuilding',
  'machineryEquipment',
  'cattleOwned',
  'pigOwned',
  'sheepOwned',
  'goatOwned',
  'poultryOwned',
  'otherOwned',
  // explain error for fattening tables don't exist, except on other livestock
  'otherServiceFattening',
  'plantProduct',
  'manufacturedProduct',
  'animalProduct',
  'otherProduct',
  'unfinishedProduct',
  'plantProductionExpense',
  'animalProductionExpense',
  'processingExpense',
  'labourMachineryExpense',
  'landBuildingExpense',
  'overheadExpense',
  'insuranceExpense',
  'taxExpense',
  'loan',
  'leasing',
  // 'moneyFlow',
  // 'otherIncome'
];


export const makeLink = (data) => {
  if (data?.dataModel === 'agriculturalLand') {
    return `agricultural-holding/${data?.agroHoldingId}/long-term-assets/agricultural-lands/${data?.dataModelID}`;
  } else if (data?.dataModel === 'permanentCrop') {
    return `agricultural-holding/${data?.agroHoldingId}/long-term-assets/permanent-crops/${data?.dataModelID}`;
  } else if (data?.dataModel === 'farmBuilding') {
    return `agricultural-holding/${data?.agroHoldingId}/long-term-assets/farm-buildings/${data?.dataModelID}`;
  } else if (data?.dataModel === 'machineryEquipment') {
    return `agricultural-holding/${data?.agroHoldingId}/long-term-assets/machinery-and-equipment/${data?.dataModelID}`;
  } else if (data?.dataModel === 'cattleOwned') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/cattle/${data?.dataModelID}`;
  } else if (data?.dataModel === 'pigOwned') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/pigs/${data?.dataModelID}`;
  } else if (data?.dataModel === 'sheepOwned') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/sheep/${data?.dataModelID}`;
  } else if (data?.dataModel === 'goatOwned') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/sheep/${data?.dataModelID}`;
  } else if (data?.dataModel === 'poultryOwned') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/poultry/${data?.dataModelID}`;
  } else if (data?.dataModel === 'otherOwned') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/other-livestock/${data?.dataModelID}`;
  } else if (data?.dataModel === 'cattleServiceFattening') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/cattle-fattening/${data?.dataModelID}`;
  } else if (data?.dataModel === 'pigServiceFattening') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/pigs-fattening/${data?.dataModelID}`;
  } else if (data?.dataModel === 'sheepServiceFattening') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/sheep-fattening/${data?.dataModelID}`;
  } else if (data?.dataModel === 'goatServiceFattening') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/goats-fattening/${data?.dataModelID}`;
  } else if (data?.dataModel === 'poultryServiceFattening') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/poultry-fattening/${data?.dataModelID}`;
  } else if (data?.dataModel === 'otherServiceFattening') {
    return `agricultural-holding/${data?.agroHoldingId}/livestock/other-livestock-fattening/${data?.dataModelID}`;
  } else if (data?.dataModel === 'plantProduct') {
    return `agricultural-holding/${data?.agroHoldingId}/production/plant-products/${data?.dataModelID}`;
  } else if (data?.dataModel === 'manufacturedProduct') {
    return `agricultural-holding/${data?.agroHoldingId}/production/manufactured-products/${data?.dataModelID}`;
  } else if (data?.dataModel === 'animalProduct') {
    return `agricultural-holding/${data?.agroHoldingId}/production/animal-products/${data?.dataModelID}`;
  } else if (data?.dataModel === 'otherProduct') {
    return `agricultural-holding/${data?.agroHoldingId}/production/other-products/${data?.dataModelID}`;
  } else if (data?.dataModel === 'unfinishedProduct') {
    return `agricultural-holding/${data?.agroHoldingId}/production/unfinished-products/${data?.dataModelID}`;
  } else if (data?.dataModel === 'plantProductionExpense') {
    return `agricultural-holding/${data?.agroHoldingId}/money/expenses/plant-production-expenses/${data?.dataModelID}`;
  } else if (data?.dataModel === 'animalProductionExpense') {
    return `agricultural-holding/${data?.agroHoldingId}/money/expenses/animal-production-expenses/${data?.dataModelID}`;
  } else if (data?.dataModel === 'processingExpense') {
    return `agricultural-holding/${data?.agroHoldingId}/money/expenses/manufacturer-processing/${data?.dataModelID}`;
  } else if (data?.dataModel === 'labourMachineryExpense') {
    return `agricultural-holding/${data?.agroHoldingId}/money/expenses/labour-and-machinery/${data?.dataModelID}`;
  } else if (data?.dataModel === 'landBuildingExpense') {
    return `agricultural-holding/${data?.agroHoldingId}/money/expenses/land-and-building/${data?.dataModelID}`;
  } else if (data?.dataModel === 'overheadExpense') {
    return `agricultural-holding/${data?.agroHoldingId}/money/expenses/overhead/${data?.dataModelID}`;
  } else if (data?.dataModel === 'insuranceExpense') {
    return `agricultural-holding/${data?.agroHoldingId}/money/expenses/insurance/${data?.dataModelID}`;
  } else if (data?.dataModel === 'taxExpense') {
    return `agricultural-holding/${data?.agroHoldingId}/money/expenses/tax/${data?.dataModelID}`;
  } else if (data?.dataModel === 'loan') {
    return `agricultural-holding/${data?.agroHoldingId}/money/loans/loan/${data?.dataModelID}`;
  } else if (data?.dataModel === 'leasing') {
    return `agricultural-holding/${data?.agroHoldingId}/money/loans/leasing/${data?.dataModelID}`;
  } else {
    return `agricultural-holding/${data?.agroHoldingId}/${data?.dataModel}/${data?.dataModelID}`;
  }
};