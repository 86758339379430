import React, {useEffect, useRef, useState} from 'react';
import {axiosPost, axiosPut} from "../../../../../../services/axios";
import Toast from "../../../../../../components/toast/Toast";
import {t} from "react-switch-lang";
import ValidationForm from "../../../../../../components/form/validationForm/ValidationForm";
import InputField from "../../../../../../components/form/inputField/InputField";
import SaveBtn from "../../../../../../components/buttons/SaveBtn";
import CancelBtn from "../../../../../../components/buttons/CancelBtn";

const LandBuildingForm = ({ data, type, updateTable, closeModal, isDisabled }) => {

  const formRef = useRef();

  const initialData = {
    name: '',
    nameEn: '',
    unit: null,
    sortVal: '',
    yieldUnit: null
  };
  const [changedData, setChangedData] = useState(initialData);

  const validation = {
    name: { type: 'string', required: { value: true }, min: { value: 3 } },
    nameEn: { type: 'string', required: { value: true }, min: { value: 3 } },
    unit: { type: 'string', required: { value: true }, min: { value: 1 } },
    sortVal: { type: 'number', required: { value: true }, min: { value: 1 } },
    yieldUnit: { type: 'string', required: { value: false }, min: { value: 1 } },
  }

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    if (type === 'add') {
      const payload = {
        ...changedData
      };

      try {
        const response = await axiosPost('production-plant-products', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.productionPlantProduct.successAdd'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    } else if (type === 'edit') {
      const payload = {
        id: data.id,
        ...changedData
      };

      try {
        const response = await axiosPut(`production-plant-products/${data.id}`, payload);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.enums.productionPlantProduct.successEdit'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      setChangedData({
        id: data.id,
        name: data.name,
        nameEn: data.nameEn,
        unit: data.unit,
        sortVal: data.sortVal,
        yieldUnit: data.yieldUnit
      });
    }
  }, [data]);

  return (
    <>
      <ValidationForm validation={validation} ref={formRef}>
        <InputField
          label={t('administration.enums.columns.name')}
          placeholder={t('administration.enums.placeholders.name')}
          value={changedData.name}
          name='name'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, name: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.nameEn')}
          placeholder={t('administration.enums.placeholders.nameEn')}
          value={changedData.nameEn}
          name='nameEn'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, nameEn: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.sortVal')}
          placeholder={t('administration.enums.placeholders.sort')}
          value={changedData.sortVal}
          name='sortVal'
          type='number'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, sortVal: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.unit')}
          placeholder={t('administration.enums.placeholders.unit')}
          value={changedData.unit}
          name='unit'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, unit: e, });
          }}
          disabled={isDisabled}
        />
        <InputField
          label={t('administration.enums.columns.yieldUnit')}
          placeholder={t('administration.enums.placeholders.yieldUnit')}
          value={changedData.yieldUnit}
          name='yieldUnit'
          groupField
          onChange={(e) => {
            setChangedData({ ...changedData, yieldUnit: e, });
          }}
          disabled={isDisabled}
        />
      </ValidationForm>
      {!isDisabled && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)} />
        </div>
      )}
    </>
  );
};

export default LandBuildingForm;
