import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { t } from 'react-switch-lang';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import UserData from '../../../../../contexts/UserData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import { Toast } from '../../../../../utils/sweetAlert';
import Loader from '../../../../../components/loader/Loader';
import SelectAsyncField from '../../../../../components/formDynamic/selectField/SelectAsyncField';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import classes from './CattleBasicForm.module.scss';
import { fetchSingleCattleFattening } from '../../../../../services/cattle/cattleApi';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const CattleFatteningForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    cattleCategory: { type: 'selectSingle', required: { value: true } },
    openingQuantity: { type: 'number', required: { value: true } },
    comment: { type: 'string', required: { value: false }, min: { value: 3 } },
    deathQuantity: { type: 'number', required: { value: true } },
    inputQuantity: { type: 'number', required: { value: true } },
    outputQuantity: { type: 'number', required: { value: true } },
    closingQuantity: { type: 'number', required: { value: true } },
  };

  const [cattle, setCattle] = useState();
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const [cattleCategory, setCattleCategory] = useState({ value: undefined, disabled: !edit });
  const [openingQuantity, setOpeningQuantity] = useState({ value: 0, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [deathQuantity, setDeathQuantity] = useState({ value: 0, disabled: !edit });
  const [inputQuantity, setInputQuantity] = useState({ value: 0, disabled: !edit });
  const [outputQuantity, setOutputQuantity] = useState({ value: 0, disabled: !edit });
  const [closingQuantity, setClosingQuantity] = useState({ value: 0, disabled: true });

  const [errorExplanations, setErrorExplanations] = useState([]);

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [validation, setValidation] = useState({ ...validationInitial });

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'cattleServiceFattening', cattle, ahid, agriculturalData, setErrorExplanations, setValidation);
  };

  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        errorExplanations: clearEmptyExplanations(errorExplanations),
        ...(cattleCategory?.value?.value && { cattleCategory: { id: parseInt(cattleCategory?.value?.value) } }),
        ...(hasValue(openingQuantity?.value) && { openingQuantity: parseFloatCustom(openingQuantity?.value) }),
        ...(comment?.value && { comment: comment?.value }),
        ...(hasValue(deathQuantity?.value) && { deathQuantity: parseFloatCustom(deathQuantity?.value) }),
        ...(hasValue(inputQuantity?.value) && { inputQuantity: parseFloatCustom(inputQuantity?.value) }),
        ...(hasValue(outputQuantity?.value) && { outputQuantity: parseFloatCustom(outputQuantity?.value) }),
        ...(hasValue(closingQuantity?.value) && { closingQuantity: parseFloatCustom(closingQuantity?.value) }),
      };

      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`cattle-service-fattenings?submitData=${isSubmit}`, payload);

          if (response.status === 201 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.cattleFattening') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add');
          }
        } catch (e) {
          handleApiErrors(e, 'add');
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`cattle-service-fattenings/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.cattleFattening') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit');
          }
        } catch (e) {
          handleApiErrors(e, 'edit');
        }
      }
    }
  };

  /***** START DEPENDANT VALIDATION *****/
  useEffect(() => {
    if (!isAudit) {
      const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;
      const val = parseFloatCustom(returnNullVal(openingQuantity?.value) +
        returnNullVal(inputQuantity?.value) -
        returnNullVal(outputQuantity?.value) -
        returnNullVal(deathQuantity?.value)).toFixed(2);

      setClosingQuantity({
        ...closingQuantity,
        value: parseFloat(val) >= 0 ? val : 0,
      });
    }
  }, [openingQuantity.value,
    inputQuantity.value,
    outputQuantity.value,
    deathQuantity.value,
  ]);
  /***** END DEPENDANT VALIDATION *****/


  const getCattle = async () => {
    try {
      const { data } = await fetchSingleCattleFattening(props.match.params.id);
      setCattle(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        getCattle().then(cattle => {
          setCattleCategory(prevState => ({
            ...prevState,
            value: cattle?.cattleCategory ? defaultOption(cattle?.cattleCategory) : undefined,
          }));
          setOpeningQuantity({ ...openingQuantity, value: parseDouble(cattle?.openingQuantity) });
          setComment(prevState => ({ ...prevState, value: cattle?.comment }));
          setDeathQuantity(prevState => ({ ...prevState, value: parseDouble(cattle?.deathQuantity) }));
          setInputQuantity(prevState => ({ ...prevState, value: parseDouble(cattle?.inputQuantity) }));
          setOutputQuantity(prevState => ({ ...prevState, value: parseDouble(cattle?.outputQuantity) }));
          setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(cattle?.closingQuantity) }));

          setErrorExplanations(cattle?.errorExplanations ? cattle?.errorExplanations : []);

          let explanations = cattle?.errorExplanations ? cattle?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'cattleServiceFattening', cattle, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        setCattleCategory(prevState => ({
          ...prevState,
          value: data?.cattleCategory ? defaultOption(data?.cattleCategory) : undefined,
        }));
        setOpeningQuantity({ ...openingQuantity, value: parseDouble(data?.openingQuantity) });
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setDeathQuantity(prevState => ({ ...prevState, value: parseDouble(data?.deathQuantity) }));
        setInputQuantity(prevState => ({ ...prevState, value: parseDouble(data?.inputQuantity) }));
        setOutputQuantity(prevState => ({ ...prevState, value: parseDouble(data?.outputQuantity) }));
        setClosingQuantity(prevState => ({ ...prevState, value: parseDouble(data?.closingQuantity) }));

        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'cattleServiceFattening', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);


  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setCattleCategory(prevState => ({ ...prevState, disabled: false }));
        setOpeningQuantity(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        setDeathQuantity(prevState => ({ ...prevState, disabled: false }));
        setInputQuantity(prevState => ({ ...prevState, disabled: false }));
        setOutputQuantity(prevState => ({ ...prevState, disabled: false }));
        setClosingQuantity(prevState => ({ ...prevState, disabled: true }));
      }
    }
  }, [edit]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  // useEffect(() => {
  //   setCattleCategoryDependantFields(cattleCategory?.value);
  // }, [cattleCategory?.value, edit]);


  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectAsyncField
                    pagination={false}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    api='cattle-categories'
                    apiQuery={'canBeServiceFattening.equals=true'}
                    label={t('pages.cattle.categoryNameCattle')}
                    placeholder={t('pages.cattle.placeholders.cattleCategory')}
                    value={cattleCategory.value}
                    name='cattleCategory'
                    onChange={(e) => {
                      setShowErrors(true);
                      setCattleCategory(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={cattleCategory.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['cattleCategory']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.cattle.openingQuantity')}
                    placeholder={t('pages.cattle.placeholders.openingQuantity')}
                    value={openingQuantity.value}
                    name='openingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.cattle.deathQuantity')}
                    placeholder={t('pages.cattle.placeholders.deathQuantity')}
                    value={deathQuantity.value}
                    name='deathQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setDeathQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={deathQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['deathQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.cattle.inputQuantity')}
                    placeholder={t('pages.cattle.placeholders.inputQuantity')}
                    value={inputQuantity.value}
                    name='inputQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setInputQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={inputQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['inputQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.cattle.outputQuantity')}
                    placeholder={t('pages.cattle.placeholders.outputQuantity')}
                    value={outputQuantity.value}
                    name='outputQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setOutputQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={outputQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['outputQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.cattle.closingQuantity')}
                    placeholder={t('pages.cattle.placeholders.closingQuantity')}
                    value={closingQuantity.value}
                    name='closingQuantity'
                    type={'double'}
                    onChange={(e) => {
                      setClosingQuantity(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingQuantity.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingQuantity']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.cattle.comment')}
                    placeholder={t('pages.cattle.placeholders.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className='button cancel'
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/livestock/cattle`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className='button save'
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};

export default CattleFatteningForm;