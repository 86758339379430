import React from 'react';
import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';

const lang = localStorage.getItem('language');

const renderColWithTooltip = (col) => {
  return (
    <Tooltip title={col} placement="topLeft" color={'#10394c'}>
      {col ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.agriculturalHolding.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    responsive: [],
  },
  {
    title: t('pages.agriculturalHolding.ahFadnId'),
    dataIndex: 'ahFadnId',
    key: 'ahFadnId',
    ellipsis: true,
    width: 120,
    render: (ahFadnId) => renderColWithTooltip(ahFadnId)
  },
  {
    title: t('pages.agriculturalHolding.mnRegistrationId'),
    dataIndex: 'mnRegistrationId',
    key: 'mnRegistrationId',
    ellipsis: true,
    render: (mnRegistrationId) => renderColWithTooltip(mnRegistrationId)
  },
  {
    title: t('pages.agriculturalHolding.office'),
    dataIndex: ['county', lang === 'eng' ? 'nameEn' : 'name'],
    key: 'county',
    ellipsis: true,
    render: (county) => renderColWithTooltip(county)
  },
  {
    title: t('pages.agriculturalHolding.city'),
    dataIndex: ['city', lang === 'eng' ? 'nameEn' : 'name'],
    key: 'city',
    ellipsis: true,
    render: (city) => renderColWithTooltip(city)
  },
  {
    title: t('pages.agriculturalHolding.ownershipType'),
    dataIndex: ['ownershipType', lang === 'eng' ? 'nameEn' : 'name'],
    key: 'ownershipType',
    ellipsis: true,
    render: (ownershipType) => renderColWithTooltip(ownershipType)
  },
  {
    title: t('pages.agriculturalHolding.productionType'),
    dataIndex: ['productionType', lang === 'eng' ? 'nameEn' : 'name'],
    key: 'productionType',
    ellipsis: true,
    render: (productionType) => renderColWithTooltip(productionType)
  },
  {
    title: t('pages.agriculturalHolding.economicSize'),
    dataIndex: ['economicSize', lang === 'eng' ? 'nameEn' : 'name'],
    key: 'economicSize',
    ellipsis: true,
    render: (economicSize) => renderColWithTooltip(economicSize)
  },
];
