import React from 'react';
import { useHistory } from 'react-router-dom';
import { t } from 'react-switch-lang';
import UsersImg from '../../../assets/images/administration/users.svg';
import AgriculturalHoldingsImg from '../../../assets/images/administration/agricultural-holdings.svg';
import RequestsImg from '../../../assets/images/administration/requests.svg';
import AhExplainableControlsImg from '../../../assets/images/administration/ah-explainable-controls.svg';
import LimitsImg from '../../../assets/images/administration/limits.svg';
import WeightingsImg from '../../../assets/images/administration/weightings.svg';
import EnumsImg from '../../../assets/images/administration/enums.svg';
import { CardsGridAdministration } from '../../../components/cardsGridAdministration/CardsGridAdministration';

export const Administration = () => {
  const history = useHistory();

  const list = [
    {
      title: t('administration.sidebar.users'),
      image: UsersImg,
      onClick: () => history.push('administration/users'),
    },
    {
      title: t('administration.sidebar.agriculturalHoldings'),
      image: AgriculturalHoldingsImg,
      onClick: () => history.push('administration/agricultural-holdings'),
    },
    {
      title: t('administration.sidebar.requests'),
      image: RequestsImg,
      onClick: () => history.push('administration/requests'),
    },
    {
      title: t('administration.sidebar.ahExplainableControls'),
      image: AhExplainableControlsImg,
      onClick: () => history.push('administration/ah-explainable-controls'),
    },
    {
      title: t('administration.sidebar.limits'),
      image: LimitsImg,
      onClick: () => history.push('administration/limits'),
    },
    {
      title: t('administration.sidebar.weightings'),
      image: WeightingsImg,
      onClick: () => history.push('administration/weightings'),
    },
    {
      title: t('administration.sidebar.enums'),
      image: EnumsImg,
      onClick: () => history.push('administration/enums'),
    },
  ];

  return (
    <div className="d-flex">
      <CardsGridAdministration list={list} />
    </div>
  );
};
