import React, { useEffect, useState } from 'react';
import Pie from '@ant-design/charts/lib/plots/pie';
import { axiosGet } from '../../../services/axios';
import classes from '../Charts.module.scss';
import { formatNumber } from '../../../utils/const';
import { useWindowWidth } from '../../../contexts/WindowWidth';

// model function should be a functions that takes api data and transforms into chart-readable data
//
// defaultClick determines if a chart should have a default onClick function or not (pie slice moving, etc.)

const PieChart = React.memo(({
                               label,
                               api,
                               modelFunction,
                               defaultClick = true,
                               ...rest
                             }) => {
  const windowWidth = useWindowWidth();

  const [data, setData] = useState([]);

  // Configuration for pie charts
  const config = {
    appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'type',
    width: 280,
    radius: 0.90,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    legend: {
      maxItemWidth: 90,
      ...(windowWidth < 400 ? { position: 'bottom', layout: 'horizontal' } : {position: 'right', layout: 'vertical', maxItemWidth: 90,})
    },
    tooltip: {
      formatter: (element) => {
        return { name: element.type, value: formatNumber(element.value) };
      }
    },
    interactions: [...(defaultClick ? [{ type: 'element-selected' }] : []), { type: 'element-active' }],
  };

  // useEffect that takes api and custom function for modeling data and feeds the data to chart
  useEffect(() => {
    axiosGet(api)
      .then((response) => setData(modelFunction(response?.data)))
      .catch((err) => {
      });
  }, [api]);

  return (
    <>
      {
        data?.length > 0 ?
          <div className={`d-flex flex-column align-items-center ${classes['chartContainer']}`}>
            <span className={classes['title']}>{label}</span>
            <Pie {...rest} {...config} />
          </div>
          :
          <></>
      }
    </>
  );
});

export default PieChart;
