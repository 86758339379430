import React, { useContext, useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../../../components/form/validationForm/ValidationForm';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import Toast from '../../../../../components/toast/Toast';
import { t } from 'react-switch-lang';
import SelectConstField from '../../../../../components/form/selectField/SelectConstField';
import dataModels from '../../../explainableControls/utils/limitsData';
import ConditionalValue from './ConditionalValue';
import Swal from 'sweetalert2';
import { parseFloatCustom } from '../../../../../utils/const';
import FormatNumberField from '../../../../../components/form/formatNumber/FormatNumber';
import classes from './LimitsForm.module.scss';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import UserData from '../../../../../contexts/UserData';

const LimitsForm = ({ data, type, updateTable, closeModal, isDisabled, isAudit = false }) => {
  const formRef = useRef();
  const user = useContext(UserData);

  const [edit, setEdit] = useState(type === 'edit');

  const initialData = {
    conditionalAttribute: undefined,
    conditionalValue: '',
    dataModel: undefined,
    dividerConditionalAttribute: undefined,
    dividerConditionalValue: '',
    dividerDataModel: undefined,
    dividerFieldName: undefined,
    fieldName: undefined,
    limitMax: '',
    limitMin: '',
  };

  const [changedData, setChangedData] = useState(initialData);

  const validationInitial = {
    dataModel: { type: 'selectSingle', required: { value: true } },
    fieldName: { type: 'selectSingle', required: { value: true } },
    limitMax: { type: 'number', required: { value: true } },
    limitMin: { type: 'number', required: { value: true } },
    conditionalAttribute: { type: 'selectSingle', required: { value: true } },
    conditionalValue: { type: 'number', required: { value: true } },
    dividerConditionalAttribute: { type: 'selectSingle', required: { value: false } },
    dividerConditionalValue: { type: 'number', required: { value: false } },
    dividerDataModel: { type: 'selectSingle', required: { value: false } },
    dividerFieldName: { type: 'selectSingle', required: { value: false } },
  };

  const [validation, setValidation] = useState(validationInitial)

  useEffect(() => {
    setValidation(prevState => ({
      ...prevState,
      limitMax: { type: 'number', required: { value: true }, ...(changedData?.limitMin && { min: { value: changedData?.limitMin } }) },
      limitMin: { type: 'number', required: { value: true }, ...(changedData?.limitMax && { max: { value: changedData?.limitMax } }) },
    }))
  }, [changedData?.limitMax, changedData?.limitMin])

  const cancel = () => {
    closeModal();
  };

  const submitData = async () => {
    const payload = {
      ...changedData,
      dataModel: changedData?.dataModel?.value ? changedData.dataModel.value : undefined,
      fieldName: changedData?.fieldName?.value ? changedData.fieldName.value : undefined,
      conditionalAttribute: changedData?.conditionalAttribute?.value ? changedData.conditionalAttribute.value : undefined,
      conditionalValue: changedData?.conditionalValue ? changedData.conditionalValue : undefined,
      dividerConditionalAttribute: changedData?.dividerConditionalAttribute?.value ? changedData.dividerConditionalAttribute.value : undefined,
      dividerConditionalValue: changedData?.dividerConditionalValue ? changedData.dividerConditionalValue : undefined,
      dividerDataModel: changedData?.dividerDataModel?.value ? changedData.dividerDataModel.value : undefined,
      dividerFieldName: changedData?.dividerFieldName?.value ? changedData.dividerFieldName.value : undefined,
      limitMax: changedData?.limitMax ? parseFloatCustom(changedData.limitMax) : undefined,
      limitMin: changedData?.limitMin ? parseFloatCustom(changedData.limitMin) : undefined,
    };
    if (type === 'add') {
      try {
        const response = await axiosPost('limits', payload);
        if (response.status === 201) {
          updateTable();
          Toast.fire({
            title: t('administration.limits.messages.successAdd'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
        if (err?.response?.data?.errorKey === 'limitexists') {
          Swal.fire({
            text: t('administration.limits.messages.limitExists'),
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            text: t('messages.addError2'),
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      }
    } else if (type === 'edit' || edit) {
      const payloadEdit = {
        ...data,
        id: data.id,
        ...payload,
      };
      try {
        const response = await axiosPut(`limits/${data.id}`, payloadEdit);
        if (response.status === 200) {
          updateTable();
          Toast.fire({
            title: t('administration.limits.messages.successEdit'),
            icon: 'success',
          });
        }
        cancel();
      } catch (err) {
        if (err?.response?.data?.errorKey === 'limitexists') {
          Swal.fire({
            text: t('administration.limits.messages.limitExists'),
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            text: t('messages.addError2'),
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      }
    }
  };

  useEffect(() => {
    if (type === 'edit' || type === 'show') {
      const dataModel = dataModels.find(item => item.value === data.dataModel);
      const fieldName = dataModel?.fields?.find(item => item.value === data.fieldName);
      const conditionalAttribute = dataModel?.fields?.find(item => item.value === data.conditionalAttribute);
      const dividerDataModel = dataModels.find(item => item.value === data.dividerDataModel);
      const dividerFieldName = dividerDataModel?.fields?.find(item => item.value === data.dividerFieldName);
      const dividerConditionalAttribute = dividerDataModel?.fields?.find(item => item.value === data.dividerConditionalAttribute);

      setChangedData({
        id: data.id,
        dataModel: dataModel ? {...dataModel, label: dataModel?.label()} : undefined,
        dividerDataModel: dividerDataModel ? {...dividerDataModel, label: dividerDataModel?.label()} : undefined,
        fieldName: fieldName ? {...fieldName, label: fieldName?.label(), additional: fieldName} : undefined,
        dividerFieldName: dividerFieldName ? {...dividerFieldName, label: dividerFieldName?.label(), additional: dividerFieldName} : undefined,
        conditionalAttribute: conditionalAttribute ? {...conditionalAttribute, label: conditionalAttribute?.label(), additional: conditionalAttribute} : undefined,
        dividerConditionalAttribute: dividerConditionalAttribute ? {...dividerConditionalAttribute, label: dividerConditionalAttribute?.label(), additional: dividerConditionalAttribute} : undefined,
        conditionalValue: data.conditionalValue,
        dividerConditionalValue: data.dividerConditionalValue,
        limitMax: data.limitMax,
        limitMin: data.limitMin,
      });
    }
  }, [data]);

  return (
    <div style={{maxWidth: '500px', margin: '10px auto'}}>
      <ValidationForm validation={validation} ref={formRef}>
        {type !== 'add' && !edit && user.isAdmin && !isAudit &&
          <div className={`${classes['editBtn']}`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
        }

        {/* ------------------ */}
        {/* --- DATA MODEL --- */}
        {/* ------------------ */}
        <SelectConstField
          label={t('administration.limits.dataModel')}
          name='dataModel'
          value={changedData.dataModel}
          groupField
          options={dataModels?.filter(item => item.isDataCollection && item?.displayInLimits)?.map(item => ({...item, label: item.label()}))}
          onChange={(e) => {
            setChangedData(prevState => ({
              ...prevState,
              dataModel: e,
              fieldName: null,
              conditionalAttribute: null
            }));
          }}
          disabled={isDisabled && !edit}
          isAudit={isAudit}
        />
        <SelectConstField
          label={t('administration.limits.fieldName')}
          name='fieldName'
          value={changedData.fieldName}
          groupField
          options={changedData?.dataModel?.fields
            ?.filter(item => item.displayInLimits && (item.type === 'integer' || item.type === 'double')
                              && (item?.value !== changedData.conditionalAttribute?.value) // field and conditional field cannot be the same
                              && (changedData?.dataModel?.value === changedData?.dividerDataModel?.value        // if tables are the same then
                                  ? (item?.value !== changedData.dividerFieldName?.value) : true)               // field and divider field cannot be the same
            )
            ?.map(item => ({value: item.value, label: item.label(), additional: item}))}
          onChange={(e) => {
            setChangedData(prevState => ({ ...prevState, fieldName: e }));
          }}
          disabled={Boolean(changedData?.dataModel?.value) ? isDisabled && !edit : true}
          isAudit={isAudit}
        />
        <SelectConstField
          label={t('administration.limits.conditionalAttribute')}
          name='conditionalAttribute'
          value={changedData.conditionalAttribute}
          groupField
          isClearable
          options={changedData.dataModel?.fields
            ?.filter(item => {
              return item.displayInLimits
              && (item?.value !== changedData.fieldName?.value); // field and conditional field cannot be the same
            })
            ?.map(item => ({value: item.value, label: item.label(), additional: item}))}
          onChange={(e) => {
            setChangedData(prevState => ({ ...prevState, conditionalAttribute: e }));
          }}
          disabled={Boolean(changedData.dataModel?.value) ? isDisabled && !edit : true}
          isAudit={isAudit}
        />
            <ConditionalValue conditionalAttribute={changedData.conditionalAttribute}
                              label={t('administration.limits.conditionalValue')}
                              name="conditionalValue"
                              isComponentWrapper
                              isDisabled={isDisabled && !edit}
                              value={changedData?.conditionalValue}
                              onValueChange={(e) => {
                                setChangedData(prevState => ({
                                  ...prevState,
                                  conditionalValue: e
                                  }));
                              }}/>

            {/* ------------------- */}
            {/* ----- DIVIDER ----- */}
            {/* ------------------- */}
        <SelectConstField
          label={t('administration.limits.dividerDataModel')}
          name='dividerDataModel'
          value={changedData.dividerDataModel}
          groupField
          isClearable
          options={dataModels?.filter(item =>  item?.displayInLimits)?.map(item => ({...item, label: item.label()}))}
          onChange={(e) => {
            setChangedData(prevState => ({
              ...prevState,
              dividerDataModel: e,
              dividerFieldName: null,
              dividerConditionalAttribute: null
            }));
          }}
          disabled={isDisabled && !edit}
          isAudit={isAudit}
        />
        <SelectConstField
          label={t('administration.limits.dividerFieldName')}
          name='dividerFieldName'
          value={changedData.dividerFieldName}
          groupField
          isClearable
          options={changedData.dividerDataModel?.fields
            ?.filter(item => item.displayInLimits && (item.type === 'integer' || item.type === 'double')
              && (item?.value !== changedData.dividerConditionalAttribute?.value) // divider field and divider conditional field cannot be the same
              && (changedData?.dataModel?.value === changedData?.dividerDataModel?.value // if tables are the same then divider field and field
                ? (item?.value !== changedData.fieldName?.value ) : true)                 // cannot be the same
            )
            ?.map(item => ({value: item.value, label: item.label(), additional: item}))}
          onChange={(e) => {
            setChangedData(prevState => ({ ...prevState, dividerFieldName: e }));
          }}
          disabled={Boolean(changedData.dividerDataModel?.value) ? isDisabled && !edit : true}
          isAudit={isAudit}
        />
        <SelectConstField
          label={t('administration.limits.dividerConditionalAttribute')}
          name='dividerConditionalAttribute'
          value={changedData.dividerConditionalAttribute}
          groupField
          isClearable
          options={changedData.dividerDataModel?.fields
            ?.filter(item => item.displayInLimits
              && (item?.value !== changedData.dividerFieldName?.value) // divider field and divider conditional field cannot be the same
            )
            ?.map(item => ({value: item.value, label: item.label(), additional: item}))}
          onChange={(e) => {
            setChangedData(prevState => ({ ...prevState, dividerConditionalAttribute: e }));
          }}
          disabled={Boolean(changedData.dividerDataModel?.value) ? isDisabled && !edit : true}
          isAudit={isAudit}
        />
            <ConditionalValue conditionalAttribute={changedData.dividerConditionalAttribute}
                              label={t('administration.limits.dividerConditionalValue')}
                              isComponentWrapper
                              name="dividerConditionalValue"
                              isDisabled={isDisabled && !edit}
                              value={changedData?.dividerConditionalValue}
                              onValueChange={(e) => {
                                setChangedData(prevState => ({ ...prevState, dividerConditionalValue: e }));
                              }}/>
        <FormatNumberField
          label={t('administration.limits.limitMin')}
          value={changedData.limitMin}
          name='limitMin'
          type='double'
          groupField
          onChange={(e) => {
            setChangedData(prevState => ({ ...prevState, limitMin: e, }));
          }}
          disabled={isDisabled && !edit}
          isAudit={isAudit}
        />
        <FormatNumberField
          label={t("administration.limits.limitMax")}
          value={changedData.limitMax}
          name='limitMax'
          type='double'
          groupField
          onChange={(e) => {
            setChangedData(prevState => ({ ...prevState, limitMax: e, }));
          }}
          disabled={isDisabled && !edit}
          isAudit={isAudit}
        />
      </ValidationForm>
      {(!isDisabled || edit) && !isAudit && (
        <div className='d-flex justify-content-end pt-4'>
          <SaveBtn
            loaderOnDisabled={false}
            loader={false}
            onClick={() => {
              formRef.current.submitForm(submitData);
            }}
          />
          <CancelBtn className='button cancel' loaderOnDisabled={true} onClick={(e) => cancel(e)} />
        </div>
      )}
    </div>
  );
};

export default LimitsForm;
