import { t } from 'react-switch-lang';
import { eng } from '../../../../../../../utils/const';

const cattleOwnedFields = [
  {
    value: 'cattleCategory',
    label: () => t('pages.cattle.categoryNameCattle'),
    type: 'select', // integer, double, select, textarea, string
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api:'cattle-categories',
      optionValue: 'id',
      optionLabel: eng ? 'nameEn' : 'name',
    },
    displayInLimits: true
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.cattle.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.cattle.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'offspringQuantity',
    label: () => t('pages.cattle.offspringQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'offspringValue',
    label: () => t('pages.cattle.offspringValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseQuantity',
    label: () => t('pages.cattle.purchaseQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchaseValue',
    label: () => t('pages.cattle.purchaseValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForSlaughterQuantity',
    label: () => t('pages.cattle.soldForSlaughterQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForSlaughterValue',
    label: () => t('pages.cattle.soldForSlaughterValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForBreedingQuantity',
    label: () => t('pages.cattle.soldForBreedingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForBreedingValue',
    label: () => t('pages.cattle.soldForBreedingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForUnknownReasonQuantity',
    label: () => t('pages.cattle.soldForUnknownReasonQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldForUnknownReasonValue',
    label: () => t('pages.cattle.soldForUnknownReasonValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'saleQuantity',
    label: () => t('pages.cattle.saleQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'saleValue',
    label: () => t('pages.cattle.saleValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionQuantity',
    label: () => t('pages.cattle.ownConsumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionValue',
    label: () => t('pages.cattle.ownConsumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingQuantity',
    label: () => t('pages.cattle.spentOnProcessingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingValue',
    label: () => t('pages.cattle.spentOnProcessingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsQuantity',
    label: () => t('pages.cattle.paymentInProductsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsValue',
    label: () => t('pages.cattle.paymentInProductsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathQuantity',
    label: () => t('pages.cattle.deathQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'deathValue',
    label: () => t('pages.cattle.deathValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'inputQuantity',
    label: () => t('pages.cattle.inputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'outputQuantity',
    label: () => t('pages.cattle.outputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.cattle.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.cattle.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.cattle.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default cattleOwnedFields;