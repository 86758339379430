import { t } from 'react-switch-lang';

export const eng = localStorage.getItem('language') === 'eng';

const otherProductsFields = [
  {
    value: 'productionOtherProduct',
    label: () => t('pages.otherProducts.productionOtherProduct'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'production-other-products',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'productionCode',
    label: () => t('pages.otherProducts.productionCode'),
    type: 'select',
    options: {
      list: [],
      api: 'production-codes',
      optionValue: 'id',
      optionLabel: 'name',
      pagination: false
    },
    displayInLimits: false
  },
  {
    value: 'area',
    label: () => t('pages.otherProducts.area'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'totalValue',
    label: () => t('pages.otherProducts.totalValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.otherProducts.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default otherProductsFields;