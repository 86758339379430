import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const animalProductsFields = [
  {
    value: 'productionAnimalProduct',
    label: () => t('pages.animalProducts.productionAnimalProduct'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'production-animal-products',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'otherAnimalProduct',
    label: () => t('pages.animalProducts.otherAnimalProduct'),
    type: 'text',
    options: {},
    displayInLimits: false
  },
  {
    value: 'otherAnimalProductApproved',
    label: () => t('pages.animalProducts.otherAnimalProductApproved'),
    type: 'checkbox',
    options: {},
    displayInLimits: false
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.animalProducts.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'openingValue',
    label: () => t('pages.animalProducts.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'productionQuantity',
    label: () => t('pages.animalProducts.productionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldQuantity',
    label: () => t('pages.animalProducts.soldQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldValue',
    label: () => t('pages.animalProducts.soldValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionQuantity',
    label: () => t('pages.animalProducts.ownConsumptionQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'ownConsumptionValue',
    label: () => t('pages.animalProducts.ownConsumptionValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsQuantity',
    label: () => t('pages.animalProducts.paymentInProductsQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'paymentInProductsValue',
    label: () => t('pages.animalProducts.paymentInProductsValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingQuantity',
    label: () => t('pages.animalProducts.spentOnProcessingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'spentOnProcessingValue',
    label: () => t('pages.animalProducts.spentOnProcessingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.animalProducts.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingValue',
    label: () => t('pages.animalProducts.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.animalProducts.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default animalProductsFields;