import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './AuthLayout.scss';
import Sidebar from './sidebar/Sidebar';
import { NavbarTop } from './navbar/Navbar';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import PageTitle from './navbar/pageTitle/PageTitle';
import Home from '../../../app/pages/home/Home';
import { fetchSingleAgriculturalHolding } from '../../../services/agriculturalHoldings/agriculturalHoldingsApi';
import AgriculturalHoldingData from '../../../contexts/AgriculturalHoldingData';
import Loader from '../../loader/Loader';

export default function AuthLayout({ previous, pageTitle, hasBackButton, backLink, children }) {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAhLoading, setIsAhLoading] = useState(true);

  const location = useLocation();

  // todo change to route containing agricultural-holding
  const isAgriculturalHolding =
    location.pathname.includes('/administration') ||
    location.pathname.includes('/agricultural-holdings-collector') ||
    location.pathname.includes('/agricultural-holdings-viewer') ||
    location.pathname === '/forms' ||
    location.pathname === '/documents' ||
    location.pathname === '/reports' ||
    location.pathname === '/profile';

  useEffect(() => {
    if (ahid) {
      fetchSingleAgriculturalHolding(ahid)
        .then((r) => {
          agriculturalData.setAgriculturalHoldingData(r.data);
          setIsAhLoading(false);
        })
        .catch(() => {
          agriculturalData.setAgriculturalHoldingData({});
          setIsAhLoading(false);
        });
    } else {
      setIsAhLoading(false);
    }
  }, []);

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div className="App">
      {location.pathname === '/home' ? (
        <Home />
      ) : (
        <>
          {ahid || isAgriculturalHolding || location.pathname.includes('/revisions') ? (
            <>
              {isAhLoading ? (
                <Loader size="lg" />
              ) : (
                <>
                  <NavbarTop agroHolding={true} />
                  <div className="d-flex justify-content-center w-100 h-100 position-relative appContent">
                    {ahid && !location.pathname.includes('locking-errors') && (
                      <Sidebar open={sidebarOpen} setSidebarOpen={(e) => setSidebarOpen(e)} />
                    )}
                    <div
                      id="agroContainer"
                      className={`${
                        !ahid || location.pathname.includes('locking-errors')
                          ? 'appContainer'
                          : 'agroContainer'
                      } ${location.pathname.includes('/revisions') && 'pl-0 pr-0'} ${
                        sidebarOpen === true ? 'opened' : 'closed'
                      }`}
                      onClick={() => closeSidebar()}
                    >
                      <PageTitle
                        previous={previous}
                        title={pageTitle}
                        hasBackButton={hasBackButton}
                        backLink={backLink}
                      />
                      {children}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <Redirect to="/home" />
          )}
        </>
      )}
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
