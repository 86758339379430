import React from 'react';
import EnumsTable from "../../../../../components/administration/enums/enumsTypes/EnumsTable";
import {t} from "react-switch-lang";

const PlantationType = () => {
  return (
    <div>
      <EnumsTable api={'plantation-types'} title={t('administration.enums.title.plantationType')}/>

    </div>
  );
};

export default PlantationType;
