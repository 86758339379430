import React from 'react';
import classes from './Loader.module.scss';
import PropTypes from 'prop-types';

// size
// xs for buttons
// sm for table
// lg for components
// xl for full page

//color
// light and dark on colored backgrounds
// color - on light background

const Loader = ({ className = '', size = 'xs', color = 'color' }) => {
  return (
    <div className={`${classes['preloader']} ${className}`}>
      <div className={`${classes['loader']} ${classes[size]} ${classes[color]}`}> </div>
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'lg', 'xl']),
  color: PropTypes.oneOf(['color', 'light', 'dark']),
};

export default Loader;
