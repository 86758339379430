import { t } from "react-switch-lang";
export const eng = localStorage.getItem('language') === 'eng';

const machineryFields = [
  {
    value: 'mechanizationType',
    label: () => t('pages.machineryAndEquipment.mechanizationType'),
    type: 'select', // integer, double, select, textarea, string, checkbox
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api: 'mechanization-types',
      optionValue: 'id',
      optionLabel: (option) => eng ? option.nameEn : option.name,
      pagination: false
    },
    displayInLimits: true
  },
  {
    value: 'description',
    label: () => t('pages.machineryAndEquipment.description'),
    type: 'text',
    options: {},
    displayInLimits: false
  },
  {
    value: 'dateProcured',
    label: () => t('pages.machineryAndEquipment.dateProcured'),
    type: 'date',
    options: {},
    displayInLimits: false
  },
  {
    value: 'manufactureYear',
    label: () => t('pages.machineryAndEquipment.manufactureYear'),
    type: 'date',
    options: {},
    displayInLimits: false
  },
  {
    value: 'openingValue',
    label: () => t('pages.machineryAndEquipment.openingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'investmentValue',
    label: () => t('pages.machineryAndEquipment.investmentValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'purchasedValue',
    label: () => t('pages.machineryAndEquipment.purchasedValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'soldValue',
    label: () => t('pages.machineryAndEquipment.soldValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'subsidiesValue',
    label: () => t('pages.machineryAndEquipment.subsidiesValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'depreciationRate',
    label: () => t('pages.machineryAndEquipment.depreciationRate'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'depreciationValue',
    label: () => t('pages.machineryAndEquipment.depreciationValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'accumulatedDepreciationValue',
    label: () => t('pages.machineryAndEquipment.accumulatedDepreciationValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'power',
    label: () => t('pages.machineryAndEquipment.power'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'acquisitionValue',
    label: () => t('pages.machineryAndEquipment.acquisitionValue'),
    type: 'double',
    options: {},
    displayInLimits: false
  },
  {
    value: 'closingValue',
    label: () => t('pages.machineryAndEquipment.closingValue'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.machineryAndEquipment.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
]

export default machineryFields;