import React, { useEffect, useState } from 'react';
import useDebounce from '../../hooks/debounceHook/debounceHook';
import classes from './SearchField.module.scss';
import PropTypes from 'prop-types';
import { t } from 'react-switch-lang';
import Icon, { SearchOutlined } from '@ant-design/icons';

// <SearchField onChange={(e) => setSearch(e)}/>

const SearchField = ({ onChange, className, prefix = false, placeholder = t('common.search') }) => {
  const [value, setValue] = useState('');
  const debouncedTerm = useDebounce(value, 300);

  // dynamically creates search input with styles that change according to props
  const searchInput = (
    <input
      className={`
        ${!prefix && className ? className : ''} 
        ${prefix ? classes['prefix'] : classes['search-field']}
      `}
      placeholder={placeholder}
      type="search"
      onChange={(e) => setValue(e.target.value)}
    />
  );

  useEffect(() => {
    onChange(debouncedTerm);
    // eslint-disable-next-line
  }, [debouncedTerm]);
  return (
    <>
      {prefix ? (
        // creates a wrapper for search input if it is specified that it should have a prefix
        <span className={`${className ? className : ''} ${classes['search-field']}`}>
          <Icon className="mr-2" style={{ fontSize: 22 }} component={SearchOutlined} />
          {searchInput}
        </span>
      ) : (
        <>{searchInput}</>
      )}
    </>
  );
};

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.bool,
};

export default SearchField;
