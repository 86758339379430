import React from 'react';
import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';
import { hasValue } from '../../../../../utils/const';
import { formatDateTime } from '../../../../../utils/formatDate';
import Status from '../components/status/Status';

const renderColWithTooltip = (col) => {
  return <Tooltip title={col} placement='top' color={'#67B278'}
                  overlayInnerStyle={{ borderRadius: '5px' }}>{hasValue(col) ? col : '/'}</Tooltip>;
};

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    responsive: [],
  },
  {
    title: t('administration.requests.columns.user'),
    dataIndex: ['submittedBy'],
    key: 'submittedBy',
    ellipsis: true,
    render: (submittedBy) => submittedBy && renderColWithTooltip(submittedBy.firstName + " " + submittedBy.lastName),
  },
  {
    title: t('administration.requests.columns.agroHolding'),
    dataIndex: ['agroHolding'],
    key: 'agroHolding',
    ellipsis: true,
    render: (agroHolding) => renderColWithTooltip(agroHolding?.ahFadnId),
  },
  {
    title: t('administration.requests.columns.submittedAt'),
    dataIndex: ['submittedAt'],
    key: 'submittedAt',
    ellipsis: true,
    render: (submittedAt) => renderColWithTooltip(formatDateTime(submittedAt)),
  },
  {
    title: t('administration.requests.columns.message'),
    dataIndex: ['message'],
    key: 'message',
    ellipsis: false,
    render: (message) => renderColWithTooltip(message),
  },
  {
    title: t('administration.requests.columns.status'),
    dataIndex: ['completed'],
    key: 'completed',
    ellipsis: true,
    align: 'center',
    width: 130,
    render: (completed) => <Status
      completed={completed}
      label={
        completed ?
          t('administration.requests.messages.statusCompleted')
          :
          t('administration.requests.messages.statusOnHold')}
    />,
  },
];
