import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { t } from 'react-switch-lang';
import { axiosPost, axiosPut } from '../../../../../services/axios';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import InputField from '../../../../../components/formDynamic/inputField/InputField';
import CancelBtn from '../../../../../components/buttons/CancelBtn';
import classes from './MachineryEquipmentForm.module.scss';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { Toast } from '../../../../../utils/sweetAlert';
import UserData from '../../../../../contexts/UserData';
import AgriculturalHoldingData from '../../../../../contexts/AgriculturalHoldingData';
import TextareaField from '../../../../../components/formDynamic/textareaField/TextareaField';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../components/formDynamic/helpers';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../utils/const';
import Loader from '../../../../../components/loader/Loader';
import { getIsCurrentFADNYear } from '../../../../../services/financialYear';
import SelectConstField from '../../../../../components/formDynamic/selectField/SelectConstField';
import DateField from '../../../../../components/formDynamic/dateField/DateField';
import {
  fetchMechanizationType,
  fetchSingleMachinery,
} from '../../../../../services/machineryEquipment/MachineryEquipment';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const checkIfSelectedYearIsCurrent = async (date) => {
  const selectedYear = date && !isNaN(date.getTime()) ? new Date(date).getFullYear() : null;
  const fadnYear = await getIsCurrentFADNYear();
  return fadnYear && selectedYear
    ? selectedYear === fadnYear
      ? true
      : selectedYear <= (fadnYear - 1)
        ? false
        : null
    : null;
};


const MachineryEquipmentForm = ({ props, data, isAudit = false }) => {

  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const [mechanizationType, setMechanizationType] = useState({ value: undefined, disabled: !edit });
  const [description, setDescription] = useState({ value: undefined, disabled: !edit });
  const [power, setPower] = useState({ value: 0, disabled: !edit });
  const [dateProcured, setDateProcured] = useState({ value: undefined, disabled: !edit });
  const [purchasedValue, setPurchasedValue] = useState({ value: 0, disabled: true });
  const [manufactureYear, setManufactureYear] = useState({ value: undefined, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: true });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [investmentValue, setInvestmentValue] = useState({ value: 0, disabled: !edit });
  const [soldValue, setSoldValue] = useState({ value: 0, disabled: !edit });
  const [accumulatedDepreciationValue, setAccumulatedDepreciationValue] = useState({
    value: 0,
    disabled: isAudit ? true : location.pathname.includes('add') ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : !edit,
  });
  const [depreciationRate, setDepreciationRate] = useState({ value: 0, disabled: true });
  const [depreciationValue, setDepreciationValue] = useState({ value: 0, disabled: true });
  const [subsidiesValue, setSubsidiesValue] = useState({ value: 0, disabled: !edit });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: true });
  const [acquisitionValue, setAcquisitionValue] = useState({
    value: 0,
    disabled: isAudit ? true : location.pathname.includes('add') ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : !edit,
  });

  const [machineryEquipment, setMachineryEquipment] = useState();
  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const [mechanizationTypeList, setMechanizationTypeList] = useState([]);
  const [allMechanizationType, setAllMechanizationType] = useState([]);
  const [measurementUnit, setMeasurementUnit] = useState('');

  const [errorExplanations, setErrorExplanations] = useState([]);

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();

  const validationInitial = {
    mechanizationType: { type: 'selectSingle', required: { value: true } },
    description: { type: 'string', required: { value: false }, min: { value: 3 } },
    power: { type: 'number', required: { value: true }, min: { value: 0 }, max: { value: 10000 } },
    dateProcured: { type: 'date', required: { value: true } },
    purchasedValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    manufactureYear: { type: 'date', required: { value: true } },
    openingValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    comment: { type: 'string', required: { value: false }, min: { value: 3 } },
    investmentValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    soldValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    accumulatedDepreciationValue: { type: 'number', required: { value: false } },
    depreciationRate: { type: 'number', required: { value: false } },
    depreciationValue: { type: 'number', required: { value: false } },
    subsidiesValue: { type: 'number', required: { value: false }, min: { value: 0 } },
    closingValue: { type: 'number', required: { value: false } },
    acquisitionValue: { type: 'number', required: { value: false } },
  };

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [validation, setValidation] = useState({ ...validationInitial });

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'machineryEquipment', machineryEquipment, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        errorExplanations: clearEmptyExplanations(errorExplanations),
        ...(mechanizationType.value?.value && { mechanizationType: { id: parseInt(mechanizationType.value?.value) } }),
        ...(description?.value && { description: description.value }),
        ...(hasValue(power?.value) && { power: parseFloatCustom(power.value) }),
        ...(hasValue(dateProcured?.value) && { dateProcured: dateProcured?.value }),
        ...(hasValue(purchasedValue?.value) && { purchasedValue: parseFloatCustom(purchasedValue.value) }),
        ...(hasValue(manufactureYear?.value) && { manufactureYear: manufactureYear.value }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(comment?.value && { comment: comment.value }),
        ...(hasValue(investmentValue?.value) && { investmentValue: parseFloatCustom(investmentValue.value) }),
        ...(hasValue(soldValue?.value) && { soldValue: parseFloatCustom(soldValue.value) }),
        ...(hasValue(accumulatedDepreciationValue?.value) && { accumulatedDepreciationValue: parseFloatCustom(accumulatedDepreciationValue.value) }),
        ...(hasValue(depreciationRate?.value) && { depreciationRate: parseInt(depreciationRate.value) }),
        ...(hasValue(depreciationValue?.value) && { depreciationValue: parseFloatCustom(depreciationValue.value) }),
        ...(hasValue(subsidiesValue?.value) && { subsidiesValue: parseFloatCustom(subsidiesValue.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
        ...(hasValue(acquisitionValue?.value) && { acquisitionValue: parseFloatCustom(acquisitionValue?.value) }),
      };

      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`machinery-equipments?submitData=${isSubmit}`, payload);

          if (response.status === 201 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.machineryEquipment') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add');
          }
        } catch (e) {
          handleApiErrors(e, 'add');
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`machinery-equipments/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false);
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.machineryEquipment') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit');
          }
        } catch (e) {
          handleApiErrors(e, 'edit');
        }
      }
    }
  };

  const getMachinery = async () => {
    try {
      const { data } = await fetchSingleMachinery(props.match.params.id);
      setMachineryEquipment(data);
      return data;
    } catch (e) {

    }
  };
  const defaultOption = (option) => {
    return {
      label: eng ? option.nameEn : option.name,
      value: option.id,
      disabled: false,
    };
  };

  const getMechanizationType = async () => {
    try {
      const { data, filtered } = await fetchMechanizationType();
      setAllMechanizationType(data);
      setMechanizationTypeList(filtered);
    } catch (e) {
    }
  };

  const changeMachineryType = (e) => {
    if (e?.value) {
      if (![1, 5, 6].includes(e?.value)) {
        setPower(prevState => ({ ...prevState, value: 0, disabled: true }));
      } else {
        setPower(prevState => ({ ...prevState, disabled: !edit }));

      }
    }
  };
  const changeDateProcured = async (e) => {
    if (e?.value) {
      const isSelectedCurrentYear = await checkIfSelectedYearIsCurrent(e?.value);
      if (isSelectedCurrentYear === true) {
        setOpeningValue(prevState => ({ ...prevState, value: 0, disabled: true }));
      } else {
        setOpeningValue(prevState => ({ ...prevState, disabled: !edit }));
      }

      if (isSelectedCurrentYear === false) {
        setPurchasedValue(prevState => ({ ...prevState, value: 0, disabled: true }));
      } else {
        setPurchasedValue(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };

  const calculateDepreciationValue = async () => {

    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;
    const returnMuliVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 1;
    const getMonths = async () => {
      const isSelectedCurrentYear = await checkIfSelectedYearIsCurrent(dateProcured?.value);

      return isSelectedCurrentYear === true
        ? (dateProcured?.value ? 12 - dateProcured?.value.getMonth() : 0)
        : isSelectedCurrentYear === false
          ? 12 : 1;
    };

    const monthsNo = await getMonths();

    setDepreciationValue(prevState => ({
      ...prevState,
      disabled: true,
      value: parseFloatCustom(
        (returnNullVal(acquisitionValue.value)
          * (returnMuliVal(depreciationRate.value) / 100)
          / 12
          * monthsNo).toFixed(2)),
    }));
  };

  const checkClosingAndSold = () => {
    if (soldValue?.value > 0) {
      setClosingValue(prevState => ({ ...prevState, value: 0 }));
    }
  };

  const calculateClosingValue = () => {
    const returnNullVal = (data) => parseFloatCustom(data) ? parseFloatCustom(data) : 0;

    const calculatedValue = parseFloatCustom(
      returnNullVal(openingValue.value)
      + returnNullVal(purchasedValue.value)
      + returnNullVal(investmentValue.value)
      - returnNullVal(soldValue.value)
      - returnNullVal(depreciationValue.value)).toFixed(2);

    setClosingValue(prevState => ({
      ...prevState,
      disabled: true,
      value: parseFloat(calculatedValue) >= 0 ? calculatedValue : 0,
    }));
  };

  // Manufactured year can't be > than date procured
  useEffect(() => {
    if (!isAudit) {
      const yearProcured = new Date(dateProcured?.value).getFullYear();
      const yearManufacture = new Date(manufactureYear?.value).getFullYear();

      if (hasValue(yearManufacture) && hasValue(yearProcured)) {
        if (yearManufacture > yearProcured) {
          setManufactureYear(prevState => ({ ...prevState, value: undefined }));
        } else {
          setManufactureYear(prevState => ({ ...prevState }));
        }
      }
    }
  }, [dateProcured?.value, manufactureYear?.value]);


  const onObjectTypeChange = (e) => {
    if (e?.value) {
      const selected = allMechanizationType.find(item => item.id === e?.value);
      if (selected) {
        setMeasurementUnit(selected?.unit);
        setDepreciationRate(prevState => ({ ...prevState, value: selected?.depreciationRate }));
      }
    }
  };

  useEffect(() => {
    getMechanizationType();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        getMachinery().then(machinery => {
          setMechanizationType(prevState => ({
            ...prevState,
            value: machinery?.mechanizationType ? defaultOption(machinery?.mechanizationType) : undefined,
          }));
          setDescription(prevState => ({ ...prevState, value: machinery?.description }));
          setPower(prevState => ({ ...prevState, value: parseDouble(machinery?.power) }));
          setDateProcured(prevState => ({
            ...prevState,
            value: machinery?.dateProcured ? new Date(machinery?.dateProcured) : undefined,
          }));
          setPurchasedValue(prevState => ({ ...prevState, value: parseDouble(machinery?.purchasedValue) }));
          setManufactureYear(prevState => ({
            ...prevState,
            value: machinery?.manufactureYear ? new Date(parseInt(machinery?.manufactureYear), 5, 1) : null,
          }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(machinery?.openingValue) }));
          setComment(prevState => ({ ...prevState, value: machinery?.comment }));
          setInvestmentValue(prevState => ({ ...prevState, value: parseDouble(machinery?.investmentValue) }));
          setSoldValue(prevState => ({ ...prevState, value: parseDouble(machinery?.soldValue) }));
          setAccumulatedDepreciationValue(prevState => ({
            ...prevState,
            value: parseDouble(machinery?.accumulatedDepreciationValue),
          }));
          setDepreciationRate(prevState => ({ ...prevState, value: parseDouble(machinery?.depreciationRate) }));
          setDepreciationValue(prevState => ({ ...prevState, value: parseDouble(machinery?.depreciationValue) }));
          setSubsidiesValue(prevState => ({ ...prevState, value: parseDouble(machinery?.subsidiesValue) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(machinery?.closingValue) }));
          setAcquisitionValue(prevState => ({ ...prevState, value: parseDouble(machinery?.acquisitionValue) }));

          setErrorExplanations(machinery?.errorExplanations ? machinery?.errorExplanations : []);

          let explanations = machinery?.errorExplanations ? machinery?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'machineryEquipment', machineryEquipment, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        setMechanizationType(prevState => ({
          ...prevState,
          value: data?.mechanizationType ? defaultOption(data?.mechanizationType) : undefined,
        }));
        setDescription(prevState => ({ ...prevState, value: data?.description }));
        setPower(prevState => ({ ...prevState, value: parseDouble(data?.power) }));
        setDateProcured(prevState => ({
          ...prevState,
          value: data?.dateProcured ? new Date(data?.dateProcured) : undefined,
        }));
        setPurchasedValue(prevState => ({ ...prevState, value: parseDouble(data?.purchasedValue) }));
        setManufactureYear(prevState => ({
          ...prevState,
          value: data?.manufactureYear ? new Date(parseInt(data?.manufactureYear), 5, 1) : null,
        }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setInvestmentValue(prevState => ({ ...prevState, value: parseDouble(data?.investmentValue) }));
        setSoldValue(prevState => ({ ...prevState, value: parseDouble(data?.soldValue) }));
        setAccumulatedDepreciationValue(prevState => ({
          ...prevState,
          value: parseDouble(data?.accumulatedDepreciationValue),
        }));
        setDepreciationRate(prevState => ({ ...prevState, value: parseDouble(data?.depreciationRate) }));
        setDepreciationValue(prevState => ({ ...prevState, value: parseDouble(data?.depreciationValue) }));
        setSubsidiesValue(prevState => ({ ...prevState, value: parseDouble(data?.subsidiesValue) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setAcquisitionValue(prevState => ({ ...prevState, value: parseDouble(data?.acquisitionValue) }));

        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'machineryEquipment', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    setMechanizationType(prevState => ({ ...prevState, disabled: !edit }));
    setComment(prevState => ({ ...prevState, disabled: !edit }));
    setDescription(prevState => ({ ...prevState, disabled: !edit }));
    setInvestmentValue(prevState => ({ ...prevState, disabled: !edit }));
    setSoldValue(prevState => ({ ...prevState, disabled: !edit }));
    setSubsidiesValue(prevState => ({ ...prevState, disabled: !edit }));
    // setDepreciationValue(prevState => ({ ...prevState, disabled: !edit }));
    setDateProcured(prevState => ({ ...prevState, disabled: !edit }));
    setPower(prevState => ({ ...prevState, disabled: !edit }));
    setManufactureYear(prevState => ({ ...prevState, disabled: !edit }));
    setAccumulatedDepreciationValue(prevState => ({
      ...prevState,
      disabled: isAudit ? true : edit ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : true,
    }));
    setAcquisitionValue(prevState => ({
      ...prevState,
      disabled: isAudit ? true : edit ? agriculturalData?.agriculturalHoldingData?.fromPreviousYear : true,
    }));
  }, [edit]);

  useEffect(() => {
    if (!isAudit) {
      changeDateProcured(dateProcured);
    }
  }, [
    dateProcured.value,
    edit,
  ]);

  const [depreciationValueCounter, setDepreciationValueCounter] = useState(0);

  useEffect(() => {
    if (!isAudit) {
      setDepreciationValueCounter(depreciationValueCounter + 1);
      depreciationValueCounter >= 4 && calculateDepreciationValue();
    }
  }, [
    mechanizationType.value,
    dateProcured.value,
    acquisitionValue.value,
    depreciationRate.value,
  ]);

  useEffect(() => {
    if (!isAudit) {
      calculateClosingValue();
    }
  }, [
    openingValue.value,
    purchasedValue.value,
    investmentValue.value,
    soldValue.value,
    depreciationValue.value,
  ]);


  useEffect(() => {
    if (!isAudit) {
      onObjectTypeChange(mechanizationType?.value);
    }
  }, [
    mechanizationType?.value,
    edit,
  ]);

  useEffect(() => {
    if (!isAudit) {
      checkClosingAndSold();
    }
  }, [
    closingValue?.value,
    soldValue?.value,
  ]);

  useEffect(() => {
    if (!isAudit) {
      changeMachineryType(mechanizationType?.value);
    }
  }, [mechanizationType?.value, edit]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm'/>
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>

              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>

                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    label={t('pages.machineryAndEquipment.mechanizationType')}
                    placeholder={t('pages.machineryAndEquipment.placeholder.mechanizationType')}
                    value={mechanizationType.value}
                    name='mechanizationType'
                    onChange={(e) => {
                      setMechanizationType(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={mechanizationTypeList}
                    disabled={mechanizationType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['mechanizationType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.description')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.description')}
                    value={description.value}
                    name='description'
                    type={'text'}
                    onChange={(e) => {
                      setDescription(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={description.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['description']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <DateField
                    label={t('pages.machineryAndEquipment.dateProcured')}
                    placeholder={t('pages.machineryAndEquipment.placeholder.dateProcured')}
                    value={dateProcured.value}
                    name={'dateProcured'}
                    onChange={(e) => {
                      setDateProcured({
                        ...dateProcured,
                        value: e,
                      });
                      setShowErrors(true);
                    }}
                    disabled={dateProcured.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    isClearable={false}
                    validation={validation['dateProcured']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <DateField
                    label={t('pages.machineryAndEquipment.manufactureYear')}
                    placeholder={t('pages.machineryAndEquipment.placeholder.manufactureYear')}
                    value={manufactureYear.value}
                    name={'manufactureYear'}
                    onChange={(e) => {
                      setManufactureYear({
                        ...manufactureYear,
                        value: new Date(e.setMonth(e.getMonth() + 8)), // just so it doesn't set year to 1st January cause it switches it to year before on fetch
                      });
                      setShowErrors(true);
                    }}
                    disabled={manufactureYear.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    showYearPicker={true}
                    isClearable={false}
                    maxDate={dateProcured?.value && new Date(dateProcured?.value)}
                    validation={validation['manufactureYear']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.openingValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.openingValue')}
                    value={openingValue.value}
                    name='openingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.investmentValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.investmentValue')}
                    value={investmentValue.value}
                    name="investmentValue"
                    suffix="€"
                    type={'double'}
                    onChange={(e) => {
                      setInvestmentValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={investmentValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['investmentValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.purchasedValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.purchasedValue')}
                    value={purchasedValue.value}
                    suffix='€'
                    name='purchasedValue'
                    type={'double'}
                    onChange={(e) => {
                      setPurchasedValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={purchasedValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['purchasedValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.soldValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.soldValue')}
                    value={soldValue.value}
                    suffix='€'
                    name='soldValue'
                    type={'double'}
                    onChange={(e) => {
                      setSoldValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={soldValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['soldValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.subsidiesValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.subsidiesValue')}
                    value={subsidiesValue.value}
                    suffix='€'
                    name='subsidiesValue'
                    type={'double'}
                    onChange={(e) => {
                      setSubsidiesValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={subsidiesValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['subsidiesValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.depreciationRate')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.depreciationRate')}
                    value={depreciationRate.value}
                    name='depreciationRate'
                    type={'double'}
                    onChange={(e) => {
                      setDepreciationRate(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={depreciationRate.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['depreciationRate']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.depreciationValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.depreciationValue')}
                    value={depreciationValue.value}
                    name='depreciationValue'
                    type={'double'}
                    suffix='€'
                    onChange={(e) => {
                      setDepreciationValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={depreciationValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['depreciationValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.accumulatedDepreciationValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.accumulatedDepreciationValue')}
                    value={accumulatedDepreciationValue.value}
                    name='accumulatedDepreciationValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setAccumulatedDepreciationValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={accumulatedDepreciationValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['accumulatedDepreciationValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.power')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.power')}
                    value={power.value}
                    name='power'
                    suffix={eng ? 'HP' : 'KS'}
                    type={'double'}
                    onChange={(e) => {
                      setPower(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={power.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['power']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.acquisitionValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.acquisitionValue')}
                    value={acquisitionValue.value}
                    name='acquisitionValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setAcquisitionValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={acquisitionValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['acquisitionValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.closingValue')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.closingValue')}
                    value={closingValue.value}
                    name='closingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.machineryAndEquipment.comment')}
                    placeholder={t('pages.machineryAndEquipment.placeholders.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/long-term-assets/machinery-and-equipment`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                setEdit(true);
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default MachineryEquipmentForm;
