import React from 'react';
import { Tooltip } from 'antd';
import { t } from 'react-switch-lang';
import { eng, formatNumber, hasValue } from '../../../../../utils/const';

const renderColWithTooltip = (col) => {
  return (
    <Tooltip
      title={col}
      placement="topLeft"
      color={'#67B278'}
      overlayInnerStyle={{ borderRadius: '5px' }}
    >
      {hasValue(col) ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.otherProducts.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: (id) => renderColWithTooltip(id),
  },
  {
    title: t('pages.otherProducts.productionOtherProduct'),
    dataIndex: ['productionOtherProduct', eng ? 'nameEn' : 'name'],
    key: 'productionOtherProduct',
    ellipsis: true,
    render: (productionOtherProduct) => renderColWithTooltip(productionOtherProduct),
  },
  {
    title: t('pages.otherProducts.productionCode'),
    dataIndex: ['productionCode', eng ? 'nameEn' : 'name'],
    key: 'productionCode',
    ellipsis: true,
    render: (productionCode) => renderColWithTooltip(productionCode),
  },
  {
    title: t('pages.otherProducts.area'),
    dataIndex: 'area',
    key: 'area',
    ellipsis: true,
    render: (area) => renderColWithTooltip(formatNumber(area)),
  },
  // {
  //   title: t('pages.otherProducts.comment'),
  //   dataIndex: 'comment',
  //   key: 'comment',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id),
  //   responsive: ['md', 'lg']
  // },
  {
    title: t('pages.otherProducts.totalValue'),
    dataIndex: 'totalValue',
    key: 'totalValue',
    ellipsis: true,
    render: (totalValue) => renderColWithTooltip(formatNumber(totalValue)),
  },
];
