import { t } from 'react-switch-lang';
import { eng, toDate } from './const';

export const formatDate = (date) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();

  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;

  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return t('message.dateInvalid');
  }

  return `${day}.${month}.${year}.`;
};

export const formatIfDate = (value) => {
  if (isNaN(Date.parse(value))) {
    return value;
  }
  return formatDate(value);
};

export const formatDateTime = (date, isoFormat = false) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();
  let hours = isoFormat ? newDate.getHours() - 1 : newDate.getHours();
  let minutes = newDate.getMinutes();
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;
  return eng ? `${day}.${month}.${year}, ${hours}:${minutes}h` : `${day}.${month}.${year}, ${hours}:${minutes}`;
};

export const setTableDate = (date) => {
  return (date !== undefined && date !== null) ?
      toDate(date, "DD.MM.YYYY.") !== 'Invalid date' ?
          toDate(date, "DD.MM.YYYY.")
          :
          ""
      :
      ""
}