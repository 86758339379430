import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { validate } from '../../../utils/validation';
import { REGEX } from '../../../utils/validationDefaults';
import { axiosGet } from '../../../services/axios';
import './SelectField.scss';
import FieldContainer from '../fieldContainer/FieldContainer';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import usePrevious from '../../../hooks/previousHook/previousHook';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import _ from 'lodash';
import UserLabel from '../../labels/userLabel/UserLabel';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

// ! label is optional prop !

// <SelectAsyncField
//                 api='/client-types'
//                 isMulti={true}
//                 onChange={(e) => {
//                 }}
//             />

const SelectAsyncField = React.forwardRef(
  (
    {
      label,
      name,
      className,
      placeholder,
      isMulti = false,
      value,
      api,
      apiQuery,
      onChange,
      optionValue,
      optionLabel,
      defaultValue,
      disabled = true,
      pagination = true,
      pageSize = 20,
      searchTerm,
      roleName,
      customLabelFormat,
      sort,
      isClearable = false,
      isSearchable = true,
      validation = { type: '' },
      displayError,
      setError,
      duplicate,
      user = false,
      styles,
      ...rest
    },
    ref,
  ) => {
    let uniqueId = uniqid();
    let componentName = name ? name : uniqueId;

    const redirectPrompt = useRedirectPrompt();

    const [errMessage, setErrMessage] = useState(validate(validation, value));

    const prevVal = usePrevious(value);
    const prevValidation = usePrevious(validation);

    const lang = localStorage.getItem('language');

    useEffect(() => {
      // on component unmounting return states to initial
      return () => {
        setErrMessage('');
      };
    }, []);

    useEffect(() => {
      // eslint-disable-next-line
      if ((!compareObjects(prevVal, value)) || (!compareObjects(validation, prevValidation))) {
        let errorObject = validate(validation, value);
        if (errorObject.type !== 'noErrors') {
          if (errorObject.type === 'apiError') {
            setError('apiError');
          } else if (errorObject.type === 'error') {
            setError('error');
          }
        } else {
          setError('noErrors');
        }
        setErrMessage(errorObject);
      }
      // eslint-disable-next-line
    }, [value, validation]);

    const [isFocused, setIsFocused] = useState(true);
    const optionVal = optionValue !== undefined ? optionValue : 'id';
    const optionLab = optionLabel !== undefined ? optionLabel : lang === 'eng' ? 'nameEn' : 'name';

    const formatApi = (page, search) => {
      const apiArr = [
        sort ? sort : null,
        roleName ? `roleName=${roleName}` : null,
        pagination ? `page=${page}&size=${pageSize}` : null,
        apiQuery ? `${apiQuery}` : null,
        isSearchable && search
          ? `${searchTerm ? searchTerm : `${optionLab}.contains`}=${search}`
          : null,
      ];
      return `?${apiArr.filter(Boolean).join('&')}`;
    };

    const loadOptions = async (search, previous, { page, pageSize }) => {
      if (isFocused) {
        let filteredOptions = [];
        let filteredOptionsNoDuplicates = [];
        let hasMore = true;

        const formatOptions = (options) => {
          return {
            options: options.map((option) => ({
              value: option[optionVal],
              label:
                user === true ? (
                  <UserLabel option={option} />
                ) : customLabelFormat ? (
                  customLabelFormat(option)
                ) : (
                  option[optionLab]
                ),
            })),
            hasMore: hasMore,
            additional: {
              page: page + 1,
              pageSize,
            },
            disabled: true,
          };
        };

        if (!search) {
          try {
            const { data } = await axiosGet(`${api}${formatApi(page)}`);
            filteredOptions = user ? data?.content : data;
            filteredOptionsNoDuplicates = filteredOptions.filter(elem => elem?.id !== duplicate?.value);
            hasMore = filteredOptions.length > pageSize && pagination !== false;
          } catch (e) {
            hasMore = false;
          }
          return formatOptions(filteredOptionsNoDuplicates);
        }

        if (search.length < 1) {
          return { options: filteredOptionsNoDuplicates, hasMore: false };
        }

        try {
          if (search.match(REGEX.validNameAndNumberRegex) && search.length < 200) {
            const { data } = await axiosGet(`${api}${formatApi(page, search)}`);
            filteredOptions = user ? data?.content : data;
            filteredOptionsNoDuplicates = filteredOptions.filter(elem => elem?.id !== duplicate?.value);
            hasMore = filteredOptions.length > pageSize && pagination !== false;
          } else {
            hasMore = false;
          }
        } catch (e) {
          hasMore = false;
        }
        return formatOptions(filteredOptionsNoDuplicates);
      }
    };

    /*Hide select on outside click*/
    // const select = useRef(null);
    // const [isOpen, setIsOpen] = useState(false);
    //
    // const toggle = (e) => {
    //     e.preventDefault();
    //     setIsOpen(false);
    //     setIsFocused(false);
    // };
    //
    // const handleClickOutside = (e) => {
    //     if (select.current && !select.current.contains(e.target)) {
    //         toggle(e);
    //     }
    // };
    //
    // useEffect(() => {
    //     isOpen
    //         ? document.addEventListener('mousedown', handleClickOutside, false)
    //         : document.removeEventListener('mousedown', handleClickOutside, false);
    //     // Clear eventListener
    //     return () => document.removeEventListener('mousedown', toggle, false);
    // }, [isOpen]);
    /*End hide select on outside click*/

    return (
      <FieldContainer
        label={label}
        required={
          validation ? (validation.required ? validation.required.value === true : false) : false
        }
        componentName={componentName}
        errorMessage={!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? errMessage : ''}
        // ref={select}
        customClasses={`${styles}`}
        {...rest}
      >
        <AsyncPaginate
          inputId={name}
          defaultOptions
          data-refkey={componentName}
          name={name}
          placeholder={disabled ? '' : placeholder ? placeholder : t('common.selectValue')}
          className={`select-field-general ${className ? className : ''} ${
            disabled ? 'disabled ' : ''
          } ${!_.isEmpty(displayError) && errMessage?.type !== 'noErrors' ? ' error ' : ''}`}
          onChange={(e) => {
            onChange(e);
            redirectPrompt.setIsDirty(true);
          }}
          value={value}
          isMulti={isMulti}
          loadOptions={isFocused ? loadOptions : { options: [] }}
          noOptionsMessage={() => t('common.noData')}
          loadingMessage={() => t('common.loading')}
          debounceTimeout={300}
          additional={{ page: 0, pageSize: 20 }}
          closeMenuOnSelect={!isMulti}
          selectRef={ref}
          defaultValue={defaultValue}
          isDisabled={disabled}
          isClearable={isClearable}
          // menuIsOpen={isOpen}
          // onMenuOpen={() => setIsOpen(true)}
          // onMenuClose={() => setIsOpen(false)}
          onFocus={() => setIsFocused(true)}
        />
      </FieldContainer>
    );
  },
);
SelectAsyncField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isClearable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isSearchable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isMulti: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  pageSize: PropTypes.number,
  api: PropTypes.string.isRequired,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  validation: PropTypes.object,
  displayError: PropTypes.object,
  setError: PropTypes.func,
};

export default SelectAsyncField;
