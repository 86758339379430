import React, { memo, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateField.scss';
import uniqid from 'uniqid';
import srLatn from 'date-fns/locale/sr-Latn';
import PropTypes from 'prop-types';
import FieldContainer from '../fieldContainer/FieldContainer';
import { checkIfExplanationValid, validate } from '../../../utils/validationDynamic';
import usePrevious from '../../../hooks/previousHook/previousHook';
import CalendarLight from '../../../assets/images/calendar/calendar_light.svg';
import CalendarDark from '../../../assets/images/calendar/calendar_dark.svg';
import { t } from 'react-switch-lang';
import { compareObjects } from '../../../utils/const';
import { useRedirectPrompt } from '../../../contexts/RedirectPrompt';

registerLocale('srLatn', srLatn);

// ! label is optional prop !

//
// const [date, setDate] = useState(new Date(initialDate)) or if empty val ''
// if datetime picker - set showTimeSelect prop true
//
// <DateField
//               value={date}
//               onChange={(date) => handleDate(date)}
//               error={errors.travelDate}
//               refProp={dateRef}
//             />
//
// !!    important - format dates onSubmit   !!

const DateField = (
  {
    name,
    label,
    helpLabel,
    showErrors,
    showRequiredError = false,
    value,
    onChange,
    onSubmit = () => {
    },
    onCalendarClose,
    dateFormat,
    errorExplanations,
    setErrorExplanations = () => {
    },
    hasWriteExplanationPermission = true,
    showYearDropdown = true,
    showTimeSelect = false,
    showYearPicker = false,
    timeIntervals,
    timeFormat,
    setSubmitDisabled,
    timeInputLabel,
    placeholderText,
    disabled = false,
    error,
    isClearable = true,
    refProp,
    minDate,
    maxDate,
    validation = { type: '' },
    setError,
    displayError,
    className,
    styles,
    ...rest
  }) => {
  let uniqueId = uniqid();
  let componentName = name ? name : uniqueId;

  const redirectPrompt = useRedirectPrompt();

  const [messageData, setMessageData] = useState(validate({ ...validation, required: { value: !disabled } }, value));
  const [isDirty, setIsDirty] = useState(false);

  const prevVal = usePrevious(value);
  const prevValidation = usePrevious({ ...validation, required: { value: !disabled } });

  const lang = localStorage.getItem('language');

  useEffect(() => {
    // on component unmounting return states to initial
    return () => {
      setMessageData({});
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    if ((!compareObjects(prevVal, value)) || (!compareObjects({ ...validation, required: { value: !disabled } }, prevValidation))) {
      let errorObject = validate({ ...validation, required: { value: !disabled } }, value);
      if (errorObject?.required?.errorType === "required" || errorObject?.apiErrors?.errorType === "apiError") {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
      } else {
        if (!checkIfExplanationValid(errorObject)) {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: true }));
        } else {
          setSubmitDisabled(prevState => ({ ...prevState, [name]: false }));
        }
      }
      isDirty && disabled === false && onSubmit();
      setMessageData(errorObject);
    }
    // eslint-disable-next-line
  }, [value, validation, disabled]);

  return (
    <FieldContainer
      label={label}
      componentName={componentName}
      helpLabel={helpLabel}
      showErrors={showErrors}
      required={
        !disabled
      }
      additionalMessageData={messageData}
      customClasses={`${styles}`}
      errorExplanations={errorExplanations}
      setErrorExplanations={setErrorExplanations}
      hasWriteExplanationPermission={hasWriteExplanationPermission}
      showRequiredMessage={(isDirty || showRequiredError)}
      {...rest}
    >
      <div
        className={`dateFnsComponent form-field-date w-100 ${
          showErrors && messageData?.errors?.errorType !== 'noErrors' ? ' error ' : ''
        } ${
          (isDirty || showRequiredError) && messageData?.required?.errorType !== 'noErrors' ? ' error ' : ''
        } ${!disabled ? '' : ' disabled '}`}
      >
        <DatePicker
          id={componentName}
          locale={lang === 'eng' ? 'en' : 'srLatn'}
          selected={value}
          name={componentName}
          onChange={(e) => {
            onChange(e);
            setIsDirty(true);
            redirectPrompt.setIsDirty(true);
          }}
          onCalendarClose={(e) => onCalendarClose && onCalendarClose(e)}
          dateFormat={
            showYearPicker ?
              'yyyy'
              :
              showTimeSelect
                ? `${dateFormat ? dateFormat : 'dd.MM.yyyy.'} ${
                  timeFormat ? timeFormat : 'HH:mm'
                }`
                : dateFormat
                ? dateFormat
                : 'dd.MM.yyyy.'
          }
          showYearDropdown={showYearDropdown}
          showTimeSelect={showTimeSelect}
          timeIntervals={timeIntervals ? timeIntervals : 1}
          timeFormat={timeFormat}
          timeInputLabel={timeInputLabel}
          placeholderText={
            placeholderText ? !disabled && placeholderText : !disabled && t('common.chooseDate')
          }
          title={placeholderText ? placeholderText : t('common.chooseDate')}
          disabled={disabled}
          autoComplete="off"
          isClearable={isClearable ? !disabled ? isClearable : false : false}
          className={`input-date ${className}`}
          minDate={minDate ? minDate : null}
          maxDate={maxDate ? maxDate : new Date()}
          showYearPicker={showYearPicker}
          yearItemNumber={8}
        />

        <img
          className={`calendar-img-aps ${disabled ? 'd-none' : ''}`}
          src={
            value === undefined || value === '' || value === null
              ? CalendarLight
              : CalendarDark
          }
          alt="Calendar"
        />
      </div>
    </FieldContainer>
  );
};

DateField.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
  timeInputLabel: PropTypes.string,
  placeholderText: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isClearable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  timeFormat: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  validation: PropTypes.object.isRequired,
  setError: PropTypes.func,
  displayError: PropTypes.object,
};

export default memo(DateField);
