export const makeValidationKeys = (validationInitial) => {
  const obj = {};
  Object.keys(validationInitial).map(item => obj[item] = true);
  return obj;
};

export const clearValidationApiErrorsExplanations = (validation) => {
  let clearedValidation = {};
  Object.entries({ ...validation }).map(([key, value]) => {
    let newVal = { ...value, apiError: undefined, explanation: undefined };
    delete newVal.apiError;
    delete newVal.explanation;

    Object.assign(clearedValidation, { [key]: newVal });
  });
  return clearedValidation;
};

export const handleApiErrorsFn = (
  // edit, add , view
  e, crud, validation, dataModel, data, ahid, agriculturalData, setErrorExplanations, setValidation, view = false, errorExplanations) => {
  const errors = view ? errorExplanations : e?.response?.data?.fieldErrors ? e?.response?.data?.fieldErrors : [];
  const fieldExplanations = view ? errorExplanations : e?.response?.data?.errorExplanations ? e?.response?.data?.errorExplanations :
    e.data?.errorExplanations ? e.data?.errorExplanations : [];
  let validationTemp = clearValidationApiErrorsExplanations(validation);


  let errorExplanationsTemp = {
    agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
    agroHoldingId: ahid,
    dataModel: dataModel,
    ...(crud === "add" ? { isApproved: null } : { isApproved: data?.errorExplanations?.isApproved }),
    ...(crud === "add" ? { dataModelID: null } : { dataModelID: data?.id }),
    // ...(crud === "add" ? { id: null } : { id: data?.errorExplanations?.id }) //  MOLIM ????
  };

  let apiErrorsTemp = [];
  let explanationErrorsTemp = [];


  for (let i = 0; i < errors.length; i++) {

    let field = { ...validationTemp[errors[i].field] };
    let messages = field?.error?.messages ? field.error.messages : [];
    !messages.includes(errors[i].message) && messages.push(errors[i].message);

    if (errors[i].errorType === "EXPLAIN") {
      if (explanationErrorsTemp.some(explanationError => errors[i].field === explanationError.field)) {
        if (explanationErrorsTemp.every(explanationError => errors[i].dvcNumber !== explanationError.dvcNumber)) {
          explanationErrorsTemp.push({
            ...explanationErrorsTemp,
            field: errors[i].field,
            message: errors[i].message,
            dvcNumber: errors[i].dvcNumber, ...errorExplanationsTemp
          });

          let messages = field?.explanation?.messages ? field.explanation.messages : [];
          messages.push({ ...errors[i], ...errorExplanationsTemp });
          field = { ...field, explanation: { value: true, messages: messages } };

          validationTemp = {
            ...validationTemp,
            [errors[i].field]: field
          };
        }
      } else {
        explanationErrorsTemp.push({
          field: errors[i].field,
          message: errors[i].message,
          dvcNumber: errors[i].dvcNumber, ...errorExplanationsTemp
        });

        let messages = field?.explanation?.messages ? field.explanation.messages : [];
       messages.push({ ...errors[i], ...errorExplanationsTemp });
        field = { ...field, explanation: { value: true, messages: messages } };

        validationTemp = {
          ...validationTemp,
          [errors[i].field]: field
        };
      }
    } else if (errors[i].errorType === "ERROR") {
      if (crud !== 'audit') {
        if (apiErrorsTemp.some(apiError => errors[i].field === apiError.field)) {
          if (apiErrorsTemp.every(apiError => errors[i].dvcNumber !== apiError.dvcNumber)) {
            apiErrorsTemp.push(errors[i]);

            let messages = field?.apiError?.messages ? field.apiError.messages : [];
            messages.push({ ...errors[i] });
            field = { ...field, apiError: { value: true, messages: messages } };

            validationTemp = {
              ...validationTemp,
              [errors[i].field]: field,
            };
          }
        } else {
          apiErrorsTemp.push(errors[i]);

          let messages = field?.apiError?.messages ? field.apiError.messages : [];
          messages.push({ ...errors[i] });
          field = { ...field, apiError: { value: true, messages: messages } };

          validationTemp = {
            ...validationTemp,
            [errors[i].field]: field,
          };
        }
      }
    }
  }

  if (fieldExplanations) {
    fieldExplanations.map(item => {

      let field = { ...validationTemp[item.field] };

      let messages = field?.explanation?.messages ? field.explanation.messages : [];
      messages.push({ ...item, ...errorExplanationsTemp });
      field = { ...field, explanation: { value: true, messages: messages } };

      validationTemp = {
        ...validationTemp,
        [item.field]: field
      };
      explanationErrorsTemp.push(item);
    });
  }
  setErrorExplanations(explanationErrorsTemp);
  setValidation({ ...validationTemp });
};

export const clearEmptyExplanations = (explanationsList) => {
  return Array.isArray(explanationsList) && explanationsList.length > 0
  ? explanationsList.filter(item => item?.explanation)
    : [];
};
