import { t } from 'react-switch-lang';
import { Tooltip } from 'antd';
import { eng, hasValue } from '../../../../../../utils/const';
import React from 'react';

const renderColWithTooltip = (col) => {
  return <Tooltip title={col} placement="topLeft" color={'#67B278'}
                  overlayInnerStyle={{ borderRadius: '5px' }}>{hasValue(col) ? col : '/'}</Tooltip>;
};

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: id => renderColWithTooltip(id),
    width: 100,
  },
  {
    title: t('administration.enums.columns.name'),
    dataIndex: ['name'],
    key: 'name',
    ellipsis: true,
    render: id => renderColWithTooltip(id),

  },
  {
    title: t('administration.enums.columns.nameEn'),
    dataIndex: ['nameEn'],
    key: 'nameEn',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.sortVal'),
    dataIndex: 'sortVal',
    key: 'sortVal',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
  {
    title: t('administration.enums.columns.frCategory'),
    dataIndex: 'frCategory',
    key: 'frCategory',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
    {
    title: t('administration.enums.columns.grantFinancedType'),
    dataIndex: ['grantFinancedType', eng ? "nameEn" : "name" ],
    key: 'grantFinancedType',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
    {
    title: t('administration.enums.columns.subsidyCategory'),
    dataIndex: ['subsidyCategory', eng ? "nameEn" : "name" ],
    key: 'subsidyCategory',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },
    {
    title: t('administration.enums.columns.grantBasicUnitType'),
    dataIndex: ['grantBasicUnitType', eng ? "nameEn" : "name" ],
    key: 'grantBasicUnitType',
    ellipsis: true,
    render: id => renderColWithTooltip(id)
  },

];
