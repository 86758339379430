import React from 'react';
import uniqid from 'uniqid';
import classes from './Checkbox.module.scss';
import PropTypes from 'prop-types';

//const [checkbox, setCheckbox] = useState([])

// <Checkbox
//                         checked={checkbox}
//                         name="some name"
//                         value={value}
//                         label={label}
//                         onChange={(e) => setCheckbox(e)}/>

const Checkbox = React.forwardRef(
  (
    {className, align, index, checked = [], value, label, name, disabled, onChange},
    ref
  ) => {
    const uniqId = uniqid();
    return (
      <div key={index} className={align === 'center' ? 'text-center' : align === 'right' ? 'text-right' : 'text-left'}>
        <label
          className={`${classes['checkbox']} ${
            disabled === true ? classes['disabled'] : ''
          } ${className ? className : ''}`}
        >
          <input
            type="checkbox"
            id={uniqId}
            name={name}
            multiple
            ref={ref}
            checked={Array.isArray(checked) && checked.includes(value)}
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
          />
          {label && <span className='pl-2'>{label}</span>}
        </label>
      </div>
    );
  }
);

Checkbox.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  align: PropTypes.string,
};

export default Checkbox;
