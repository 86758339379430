import { axiosGet } from '../../axios';
import { eng } from "../../../utils/const";

const defaultOption = (option) => {
  return {
    label: eng ? option.nameEn : option.name,
    value: option.id,
    disabled: false,
  };
};

export const fetchSingleInsurance = (id) => {
  return axiosGet(`insurance-expenses/${id}`);
};

export const getInsuranceExpenseType = () => {
  return axiosGet('insurance-expense-types').then(r => {
    let arrTemp = [];
    for (let item of r.data) {
      arrTemp.push(
        defaultOption(item),
      );
    }
    return arrTemp;
  }).catch(() => []);
};
