import { Tooltip } from 'antd';
import { t } from 'react-switch-lang';
import { eng, formatNumber, hasValue } from '../../../../../utils/const';
import React from 'react';

const renderColWithTooltip = (col) => {
  return (
    <Tooltip
      title={col}
      placement="topLeft"
      color={'#67B278'}
      overlayInnerStyle={{ borderRadius: '5px' }}
    >
      {hasValue(col) ? col : '/'}
    </Tooltip>
  );
};

export const columns = [
  {
    title: t('pages.animalProducts.id'),
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
    render: (id) => renderColWithTooltip(id),
  },
  {
    title: t('pages.animalProducts.productionAnimalProduct'),
    dataIndex: ['productionAnimalProduct', eng ? 'nameEn' : 'name'],
    key: 'productionAnimalProduct',
    ellipsis: true,
    render: (productionAnimalProduct) => renderColWithTooltip(productionAnimalProduct),
  },
  {
    title: t('pages.animalProducts.openingQuantity'),
    dataIndex: 'openingQuantity',
    key: 'openingQuantity',
    ellipsis: true,
    render: (openingQuantity) => renderColWithTooltip(formatNumber(openingQuantity)),
  },
  {
    title: t('pages.animalProducts.openingValue'),
    dataIndex: 'openingValue',
    key: 'openingValue',
    ellipsis: true,
    render: (openingValue) => renderColWithTooltip(formatNumber(openingValue)),
  },
  {
    title: t('pages.animalProducts.otherAnimalProduct'),
    dataIndex: 'otherAnimalProduct',
    key: 'otherAnimalProduct',
    ellipsis: true,
    render: (otherAnimalProduct) => renderColWithTooltip(otherAnimalProduct),
  },
  {
    title: t('pages.animalProducts.productionQuantity'),
    dataIndex: 'productionQuantity',
    key: 'productionQuantity',
    ellipsis: true,
    render: (productionQuantity) => renderColWithTooltip(formatNumber(productionQuantity)),
  },
  {
    title: t('pages.animalProducts.soldQuantity'),
    dataIndex: 'soldQuantity',
    key: 'soldQuantity',
    ellipsis: true,
    render: (soldQuantity) => renderColWithTooltip(formatNumber(soldQuantity)),
  },
  {
    title: t('pages.animalProducts.soldValue'),
    dataIndex: 'soldValue',
    key: 'soldValue',
    ellipsis: true,
    render: (soldValue) => renderColWithTooltip(formatNumber(soldValue)),
  },
  // {
  //   title: t('pages.animalProducts.ownConsumptionQuantity'),
  //   dataIndex: 'ownConsumptionQuantity',
  //   key: 'ownConsumptionQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // },
  // {
  //   title: t('pages.animalProducts.ownConsumptionValue'),
  //   dataIndex: 'ownConsumptionValue',
  //   key: 'ownConsumptionValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // },
  // {
  //   title: t('pages.animalProducts.paymentInProductsQuantity'),
  //   dataIndex: 'paymentInProductsQuantity',
  //   key: 'paymentInProductsQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // },
  // {
  //   title: t('pages.animalProducts.paymentInProductsValue'),
  //   dataIndex: 'paymentInProductsValue',
  //   key: 'paymentInProductsValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // },
  // {
  //   title: t('pages.animalProducts.spentOnProcessingQuantity'),
  //   dataIndex: 'spentOnProcessingQuantity',
  //   key: 'spentOnProcessingQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // },
  // {
  //   title: t('pages.animalProducts.spentOnProcessingValue'),
  //   dataIndex: 'spentOnProcessingValue',
  //   key: 'spentOnProcessingValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // },
  // {
  //   title: t('pages.animalProducts.closingQuantity'),
  //   dataIndex: 'closingQuantity',
  //   key: 'closingQuantity',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // },
  // {
  //   title: t('pages.animalProducts.closingValue'),
  //   dataIndex: 'closingValue',
  //   key: 'closingValue',
  //   ellipsis: true,
  //   render: id => renderColWithTooltip(id)
  // }
];
