import { t } from 'react-switch-lang';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 100,
  },
  {
    title: t('administration.enums.columns.name'),
    dataIndex: ['name'],
    key: 'name',
    ellipsis: true,
  },
  {
    title: t('administration.enums.columns.nameEn'),
    dataIndex: ['nameEn'],
    key: 'nameEn',
    ellipsis: true,
  },
  {
    title: t('administration.enums.columns.nuts'),
    dataIndex: ['nuts'],
    key: 'nuts',
    ellipsis: true,
  },
  {
    title: t('administration.enums.columns.sortVal'),
    dataIndex: 'sortVal',
    key: 'sortVal',
    ellipsis: true,
  },
];
