import { t } from 'react-switch-lang';
import moment from 'moment';
import { axiosGetFile } from '../../../../services/axios';

const onDownload = (document) => {
  const filename =
    document.name.trim().toLowerCase().replace(/ +/g, '-') + '.' + document.url.split('.')[1];
  axiosGetFile(`documents/${document.id}/download`, filename);
};

export const moldData = (response, onDelete, onModalOpen) => {
  return response.data.map((document) => ({
    title: document.name,
    explanations: [
      {
        title: t('pages.documents.columns.description'),
        value: document.description,
      },
      {
        title: t('pages.documents.columns.uploadedBy'),
        value: document.uploadedBy.login,
      },
      {
        title: t('pages.documents.columns.uploadedAt'),
        value: moment(document.uploadedAt).format('DD.MM.YYYY.'),
      },
    ],
    download: () => onDownload(document),
    delete: () => onDelete(document),
    edit: () => onModalOpen(document, false, 'edit'),
    // onClick: () => onModalOpen(document, false, 'show'),
  }));
};
