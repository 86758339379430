import React from 'react';
import FrCategory from "../../../../../components/administration/enums/frCategoryForm/FrCategory";
import {t} from "react-switch-lang";

const ProductionCode = () => {
  return (
    <>
      <FrCategory api={'production-codes'} title={t('administration.enums.title.productionCode')}/>

    </>
  );
};

export default ProductionCode;
