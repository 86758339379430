import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const WorkforceRole = () => {
  return (
    <div>
      <StandardTable api={'workforce-roles'} title={t('administration.enums.title.workforceRoles')} />
    </div>
  );
};

export default WorkforceRole;
