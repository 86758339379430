import { axiosDelete, axiosPost, axiosPut } from '../axios';

export const sendErrorExplanation = (payload) => {
  return axiosPost('error-explanations', payload);
};

export const updateErrorExplanation = (id, payload) => {
  return axiosPut(`error-explanations/${id}`, payload);
};

export const deleteErrorExplanation = (id) => {
  return axiosDelete('error-explanations', id);
};