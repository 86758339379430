import { t } from 'react-switch-lang';
import { eng } from '../../../../../../../utils/const';

const cattleFatteningFields = [
  {
    value: 'cattleCategory',
    label: () => t('pages.cattle.categoryNameCattle'),
    type: 'select', // integer, double, select, textarea, string
    options: { // define if type is select
      list: [], // fill list or define api, optionValue and optionLabel
      api:'cattle-categories',
      apiQuery: 'canBeServiceFattening.equals=true',
      optionValue: 'id',
      optionLabel: eng ? 'nameEn' : 'name',
    },
    displayInLimits: true
  },
  {
    value: 'deathQuantity',
    label: () => t('pages.cattle.deathQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'inputQuantity',
    label: () => t('pages.cattle.inputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'outputQuantity',
    label: () => t('pages.cattle.outputQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'closingQuantity',
    label: () => t('pages.cattle.closingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
  {
    value: 'comment',
    label: () => t('pages.cattle.comment'),
    type: 'textarea',
    options: {},
    displayInLimits: false
  },
  {
    value: 'openingQuantity',
    label: () => t('pages.cattle.openingQuantity'),
    type: 'double',
    options: {},
    displayInLimits: true
  },
]

export default cattleFatteningFields;