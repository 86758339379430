import React from 'react';
import LivestockTableEnums from "../../../../../components/administration/enums/livestockEnums/LivestockTableEnums";
import {t} from "react-switch-lang";

const GoatsCategory = () => {
  return (
    <div>
      <LivestockTableEnums api={'goat-categories'} title={t('administration.enums.title.goatsCategory')}/>

    </div>
  );
};

export default GoatsCategory;
