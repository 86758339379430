import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../../components/formDynamic/inputField/InputField';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../../components/formDynamic/textareaField/TextareaField';
import SaveBtn from '../../../../../../components/buttons/SaveBtn';
import { axiosPost, axiosPut } from '../../../../../../services/axios';
import SelectConstField from '../../../../../../components/formDynamic/selectField/SelectConstField';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CancelBtn from '../../../../../../components/buttons/CancelBtn';
import classes from './LoanForm.module.scss';
import { Toast } from '../../../../../../utils/sweetAlert';
import { eng, hasValue, parseDouble, parseFloatCustom, readOnly } from '../../../../../../utils/const';
import UserData from '../../../../../../contexts/UserData';
import {
  fetchSingleLoan,
  getLoanCategories,
  getLoanPurposes,
  getLoanTypes,
} from '../../../../../../services/money/loans/loans';
import CheckboxField from '../../../../../../components/formDynamic/checkboxField/CheckboxField';
import AgriculturalHoldingData from '../../../../../../contexts/AgriculturalHoldingData';
import {
  clearEmptyExplanations,
  handleApiErrorsFn,
  makeValidationKeys,
} from '../../../../../../components/formDynamic/helpers';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';
import Loader from '../../../../../../components/loader/Loader';

const LoanForm = ({ props, data, isAudit = false }) => {
  let { ahid } = useParams();
  const agriculturalData = useContext(AgriculturalHoldingData);
  const user = useContext(UserData);
  const location = useLocation();
  const history = useHistory();
  const add = location?.state?.add !== undefined ? location.state.add : false;
  const isDeleted = location?.state?.isDeleted;
  const [edit, setEdit] = useState(location.pathname.includes('add') ? true : location?.state?.edit !== undefined ? location.state.edit : false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);

  const validationInitial = {
    loanPurpose: { type: 'selectSingle', required: { value: true } },
    loanType: { type: 'selectSingle', required: { value: false } },
    principalValue: { type: 'number', required: { value: true } },
    openingValue: { type: 'number', required: { value: true } },
    comment: { type: 'textarea', required: { value: false }, min: { value: 3 } },
    loanCategory: { type: 'selectSingle', required: { value: false } },
    otherLoanPurpose: { type: 'string', required: { value: false }, min: { value: 3 } },
    otherLoanPurposeApproved: { type: 'checkbox', required: { value: false } },
    interestValue: { type: 'number', required: { value: false } },
    closingValue: { type: 'number', required: { value: false } },
  };

  const [loading, setLoading] = useState(!add);
  const [showErrors, setShowErrors] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [validation, setValidation] = useState({ ...validationInitial });
  const [errorExplanations, setErrorExplanations] = useState([]);

  useEffect(() => {
    makeValidationKeys(validationInitial);
  }, []);

  const [loan, setLoan] = useState();

  const [loanPurpose, setLoanPurpose] = useState({ value: undefined, disabled: !edit });
  const [loanType, setLoanType] = useState({ value: undefined, disabled: !edit });
  const [loanCategory, setLoanCategory] = useState({ value: undefined, disabled: !edit });
  const [comment, setComment] = useState({ value: undefined, disabled: !edit });
  const [principalValue, setPrincipalValue] = useState({ value: 0, disabled: !edit });
  const [openingValue, setOpeningValue] = useState({ value: 0, disabled: !edit });
  const [otherLoanPurpose, setOtherLoanPurpose] = useState({ value: undefined, disabled: !edit });
  const [otherLoanPurposeApproved, setOtherLoanPurposeApproved] = useState({ value: undefined, disabled: !edit });
  const [interestValue, setInterestValue] = useState({ value: 0, disabled: !edit });
  const [closingValue, setClosingValue] = useState({ value: 0, disabled: !edit });

  // Enums
  const [loanPurposeList, setLoanPurposeList] = useState([]);
  const [loanTypeList, setLoanTypeList] = useState([]);
  const [loanCategoryList, setLoanCategoryList] = useState([]);

  const isLocked = hasValue(agriculturalData.agriculturalHoldingData?.lockedDate);

  const isDisplayed = !add && !edit && (user.isAdmin || user.isCollector) && !isDeleted && !isLocked && !isAudit && !readOnly;

  /***** START DEPENDANT VALIDATION *****/
  const setLoanPurposeAndFields = (e) => {
    if (e?.value) {
      if ([6, 7].includes(e.value)) {
        setLoanCategory(prevState => ({ ...prevState, value: null, disabled: true }));
        setPrincipalValue(prevState => ({ ...prevState, value: 0, disabled: true }));
        setInterestValue(prevState => ({ ...prevState, value: 0, disabled: true }));
      } else {
        setLoanCategory(prevState => ({ ...prevState, disabled: !edit }));
        setPrincipalValue(prevState => ({
          ...prevState,
          disabled: !edit,
          value: prevState.value === 0
            ? 0
            : prevState.value,
        }));
        setInterestValue(prevState => ({ ...prevState, disabled: !edit }));
      }
      if (![5, 6].includes(e.value)) {
        setOtherLoanPurpose(prevState => ({ ...prevState, value: null, disabled: true }));
        setOtherLoanPurposeApproved(prevState => ({ ...prevState, value: ['false'], disabled: true }));
      } else {
        setOtherLoanPurpose(prevState => ({ ...prevState, disabled: !edit }));
        setOtherLoanPurposeApproved(prevState => ({ ...prevState, disabled: !edit }));
      }
    }
  };
  /***** END DEPENDANT VALIDATION *****/


  /***** START SET API ERRORS *****/
  const handleApiErrors = (e, crud) => {
    handleApiErrorsFn(e, crud, validation, 'loan', loan, ahid, agriculturalData, setErrorExplanations, setValidation);
  };
  /***** END SET API ERRORS *****/

  /*****START SUBMIT FORM *****/
  const handleSubmit = async (isSubmit, isPreLocking, initialPayload) => {
    setShowErrors(true);
    if (isPreLocking
      ? isPreLocking
      : isSubmit
        ? Object.values(submitDisabled).every(value => !value)
        : true) {
      const payload = {
        agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
        agroHoldingId: ahid,
        errorExplanations: clearEmptyExplanations(errorExplanations),
        ...(loanPurpose.value?.value && { loanPurpose: { id: parseInt(loanPurpose.value?.value) } }),
        ...(loanType.value?.value && { loanType: { id: parseInt(loanType.value?.value) } }),
        ...(comment?.value && { comment: comment.value }),
        ...(loanCategory?.value?.value && { loanCategory: { id: parseInt(loanCategory.value?.value) } }),
        ...(otherLoanPurpose?.value && { otherLoanPurpose: otherLoanPurpose.value }),
        otherLoanPurposeApproved: otherLoanPurposeApproved?.disabled ? null : hasValue(otherLoanPurposeApproved?.value) && otherLoanPurposeApproved?.value[0] === 'true',
        ...(hasValue(principalValue?.value) && { principalValue: parseFloatCustom(principalValue.value) }),
        ...(hasValue(interestValue?.value) && { interestValue: parseFloatCustom(interestValue.value) }),
        ...(hasValue(openingValue?.value) && { openingValue: parseFloatCustom(openingValue.value) }),
        ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
      };
      if (location.pathname.includes('add')) {
        try {
          const response = await axiosPost(`loans?submitData=${isSubmit}`, payload);
          if (response.status === 201 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successAdd', { entity: t('messages.loan') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'add', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'add', isSubmit);
        }
      } else {
        try {
          const editPayload = isPreLocking ? initialPayload : {
            ...payload,
            id: props.match.params.id,
          };
          const response = await axiosPut(`loans/${props.match.params.id}?submitData=${isSubmit}`, editPayload);
          if (response.status === 200 && isSubmit) {
            setEdit(false); // Disable submit
            setIsCancelDisabled(true);
            Toast.fire({
              title: t('common.successEdit', { entity: t('messages.loan') }),
              icon: 'success',
            });
            history.goBack();
          } else if ((response.status === 201 || response.status === 200) && !isSubmit) {
            handleApiErrors(response, 'edit', isSubmit);
          }
        } catch (e) {
          handleApiErrors(e, 'edit', isSubmit);
        }
      }
    }
  };
  /*****END SUBMIT FORM *****/

  const fetchLoan = async () => {
    try {
      const { data } = await fetchSingleLoan(props.match.params.id);
      setLoan(data);
      return data;
    } catch (e) {
    }
  };

  const defaultOption = (option) => {
    return {
      label: eng ? option?.nameEn : option?.name,
      value: option?.id,
      disabled: false,
    };
  };

  const fetchLoanPurposes = async () => {
    const data = await getLoanPurposes();
    setLoanPurposeList(data);
  };

  const fetchLoanCategories = async () => {
    const data = await getLoanCategories();
    setLoanCategoryList(data);
  };

  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    setLoanTypeList(data);
  };

  useEffect(() => {
    fetchLoanPurposes();
    fetchLoanCategories();
    fetchLoanTypes();
    if (!location.pathname.includes('add')) {
      if (!isAudit) {
        fetchLoan().then(loan => {
          loan?.loanPurpose && setLoanPurpose(prevState => ({
            ...prevState,
            value: defaultOption(loan?.loanPurpose),
          }));
          loan?.loanType && setLoanType(prevState => ({
            ...prevState,
            value: defaultOption(loan?.loanType),
          }));
          loan?.loanCategory && setLoanCategory(prevState => ({
            ...prevState,
            value: defaultOption(loan?.loanCategory),
          }));
          setComment(prevState => ({ ...prevState, value: loan?.comment }));
          setPrincipalValue(prevState => ({ ...prevState, value: parseDouble(loan?.principalValue) }));
          setOpeningValue(prevState => ({ ...prevState, value: parseDouble(loan?.openingValue) }));
          setOtherLoanPurpose(prevState => ({ ...prevState, value: loan?.otherLoanPurpose }));
          setOtherLoanPurposeApproved(prevState => ({
            ...prevState,
            value: loan?.otherLoanPurposeApproved
              ? [loan?.otherLoanPurposeApproved ? 'true' : 'false']
              : [],
          }));
          setInterestValue(prevState => ({ ...prevState, value: parseDouble(loan?.interestValue) }));
          setClosingValue(prevState => ({ ...prevState, value: parseDouble(loan?.closingValue) }));
          setErrorExplanations(loan?.errorExplanations ? loan?.errorExplanations : []);

          let explanations = loan?.errorExplanations ? loan?.errorExplanations : [];

          handleApiErrorsFn({}, 'edit', validation, 'loan', loan, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
          setLoading(false);
        });
      } else {
        data?.loanPurpose && setLoanPurpose(prevState => ({
          ...prevState,
          value: defaultOption(data?.loanPurpose),
        }));
        data?.loanType && setLoanType(prevState => ({
          ...prevState,
          value: defaultOption(data?.loanType),
        }));
        data?.loanCategory && setLoanCategory(prevState => ({
          ...prevState,
          value: defaultOption(data?.loanCategory),
        }));
        setComment(prevState => ({ ...prevState, value: data?.comment }));
        setPrincipalValue(prevState => ({ ...prevState, value: parseDouble(data?.principalValue) }));
        setOpeningValue(prevState => ({ ...prevState, value: parseDouble(data?.openingValue) }));
        setOtherLoanPurpose(prevState => ({ ...prevState, value: data?.otherLoanPurpose }));
        setOtherLoanPurposeApproved(prevState => ({
          ...prevState,
          value: loan?.otherLoanPurposeApproved
            ? [loan?.otherLoanPurposeApproved ? 'true' : 'false']
            : [],
        }));
        setInterestValue(prevState => ({ ...prevState, value: parseDouble(data?.interestValue) }));
        setClosingValue(prevState => ({ ...prevState, value: parseDouble(data?.closingValue) }));
        setErrorExplanations(data?.errorExplanations ? data?.errorExplanations : []);

        let explanations = data?.errorExplanations ? data?.errorExplanations : [];

        handleApiErrorsFn({}, 'audit', validation, 'loan', data, ahid, agriculturalData, setErrorExplanations, setValidation, true, explanations);
        setLoading(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!location.pathname.includes('add')) {
      if (edit) {
        setLoanPurpose(prevState => ({ ...prevState, disabled: false }));
        setLoanType(prevState => ({ ...prevState, disabled: false }));
        // setLoanCategory(prevState => ({ ...prevState, disabled: false }));
        setComment(prevState => ({ ...prevState, disabled: false }));
        // setPrincipalValue(prevState => ({ ...prevState, disabled: false }));
        setOpeningValue(prevState => ({ ...prevState, disabled: false }));
        // setOtherLoanPurpose(prevState => ({ ...prevState, disabled: false }));
        // setOtherLoanPurposeApproved(prevState => ({ ...prevState, disabled: false }));
        // setInterestValue(prevState => ({ ...prevState, disabled: false }));
        setClosingValue(prevState => ({ ...prevState, disabled: false }));
      }
    }
  }, [edit]);

  useEffect(() => {
    if (!isAudit) {
      setLoanPurposeAndFields(loanPurpose?.value);
    }
  }, [loanPurpose?.value, edit]);

  const oneEdit = () => {
    setEdit(true);

    // const payload = {
    //   id: props.match.params.id,
    //   agroHoldingFadnId: agriculturalData.agriculturalHoldingData.ahFadnId,
    //   agroHoldingId: ahid,
    //   loanPurpose: { id: parseInt(loanPurpose.value?.value) },
    //   principalValue: parseFloatCustom(principalValue.value),
    //   openingValue: parseFloatCustom(openingValue.value),
    //   ...(loanType.value && { loanType: { id: parseInt(loanType.value?.value) } }),
    //   ...(comment?.value && { comment: comment.value }),
    //   ...(loanCategory?.value?.value && { loanCategory: { id: parseInt(loanCategory.value?.value) } }),
    //   ...(otherLoanPurpose?.value && { otherLoanPurpose: otherLoanPurpose.value }),
    //   ...(otherLoanPurposeApproved?.value && otherLoanPurposeApproved?.value[0] && { otherLoanPurposeApproved: otherLoanPurposeApproved.value[0] }),
    //   ...(hasValue(interestValue?.value) && { interestValue: parseFloatCustom(interestValue.value) }),
    //   ...(hasValue(closingValue?.value) && { closingValue: parseFloatCustom(closingValue.value) }),
    //   errorExplanations: clearEmptyExplanations(errorExplanations),
    // }
    //
    // handleSubmit(false, true, payload);
  };
  //
  // const prevVal = usePrevious(submitDisabled);
  // useDidMountEffect(() => {
  //   (!compareObjects(submitDisabled, prevVal) && showErrors) && handleSubmit(false);
  // }, [submitDisabled]);

  // START SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED
  const [isSubmit, setIsSubmit] = useState(false);
  useDidMountEffect(() => {
    const scrollDiv = document.getElementsByClassName('validation-error')[0];
    if (isSubmit) {
      hasValue(scrollDiv) && scrollDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    isSubmit && setIsSubmit(false);
  }, [isSubmit]);
  // END SCROLL TO FIRST ELEMENT WITH AN ERROR AFTER SUBMIT BUTTON IS CLICKED

  return (
    <div className='d-flex w-100'>
      {loading
        ? <Loader size='sm' />
        :
        <>
          <div className='d-flex flex-column justify-content-center w-100'>
            <form style={{ width: '100%' }}>
              <div className='d-flex justify-content-center flex-column flex-md-row w-100'>
                <div className={`d-flex flex-column pr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.loanPurpose')}
                    placeholder={t('pages.loan.placeholder.loanPurpose')}
                    value={loanPurpose.value}
                    name='loanPurpose'
                    onChange={(e) => {
                      setLoanPurpose(prevState => ({ ...prevState, value: e }));
                      setShowErrors(true);
                    }}
                    options={loanPurposeList}
                    disabled={loanPurpose.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['loanPurpose']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.loanType')}
                    placeholder={t('pages.loan.placeholder.loanType')}
                    value={loanType.value}
                    name='loanType'
                    onChange={(e) => {
                      setLoanType(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                      setShowErrors(true);
                    }}
                    options={loanTypeList}
                    disabled={loanType.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['loanType']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.principalValue')}
                    placeholder={t('pages.loan.placeholder.principalValue')}
                    value={principalValue.value}
                    name='principalValue'
                    type={'double'}
                    suffix='€'
                    onChange={(e) => {
                      setPrincipalValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={principalValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['principalValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.openingValue')}
                    placeholder={t('pages.loan.placeholder.openingValue')}
                    value={openingValue.value}
                    name='openingValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setOpeningValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={openingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['openingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.interestValue')}
                    placeholder={t('pages.loan.placeholder.interestValue')}
                    value={interestValue.value}
                    name='interestValue'
                    suffix='€'
                    type={'double'}
                    onChange={(e) => {
                      setInterestValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={interestValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['interestValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                <div className={`d-flex flex-column pl-md-3 mr-md-3 ${classes['formFields']}`}>
                  <SelectConstField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    placeholder={t('pages.loan.placeholder.loanCategory')}
                    label={t('pages.loan.loanCategory')}
                    value={loanCategory.value}
                    name='loanCategory'
                    onChange={(e) => {
                      setLoanCategory(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    options={loanCategoryList}
                    disabled={loanCategory.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['loanCategory']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.otherLoanPurpose')}
                    placeholder={t('pages.loan.placeholder.otherLoanPurpose')}
                    helpLabel={t('pages.loan.help.otherLoanPurpose')}
                    value={otherLoanPurpose.value}
                    name='otherLoanPurpose'
                    onChange={(e) => {
                      setOtherLoanPurpose(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={otherLoanPurpose.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherLoanPurpose']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <CheckboxField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.otherLoanPurposeApproved')}
                    helpLabel={t('pages.loan.help.otherLoanPurposeApproved')}
                    name='otherLoanPurposeApproved'
                    options={[{ value: 'true', label: '' }]}
                    value={otherLoanPurposeApproved?.value}
                    onChange={(e) => {
                      setOtherLoanPurposeApproved(prevState => ({ ...prevState, value: e }));
                    }}
                    disabled={!user.isAdmin ? true : otherLoanPurposeApproved?.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['otherLoanPurposeApproved']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <InputField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.closingValue')}
                    placeholder={t('pages.loan.placeholder.closingValue')}
                    value={closingValue.value}
                    suffix='€'
                    name='closingValue'
                    type={'double'}
                    onChange={(e) => {
                      setClosingValue(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={closingValue.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['closingValue']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                  <TextareaField
                    showErrors={showErrors}
                    showRequiredError={showRequiredError}
                    label={t('pages.loan.comment')}
                    placeholder={t('pages.loan.placeholder.comment')}
                    value={comment.value}
                    name='comment'
                    onChange={(e) => {
                      setComment(prevState => ({
                        ...prevState,
                        value: e,
                      }));
                    }}
                    disabled={comment.disabled}
                    styles={`${classes['formFieldsWidth']}`}
                    validation={validation['comment']}
                    errorExplanations={errorExplanations}
                    setErrorExplanations={setErrorExplanations}
                    onSubmit={() => handleSubmit(false)}
                    hasWriteExplanationPermission={!add && !edit}
                    setSubmitDisabled={setSubmitDisabled}
                  />
                </div>
                {isDisplayed &&
                <div className={`${classes['editBtn']}`}>
                  <EditOutlined
                    onClick={() => {
                      oneEdit();
                    }}
                  />
                </div>
                }
              </div>
            </form>
            {!isLocked && !isAudit && !isDeleted && !readOnly &&
            <div className="d-flex justify-content-center pt-3">
              <div className="d-flex flex-column-reverse flex-md-row">
                <CancelBtn
                  className="button cancel"
                  loader={false}
                  disabled={isCancelDisabled}
                  onClick={(e) => {
                    history.goBack() === undefined ?
                      history.push(`/agricultural-holding/${agriculturalData?.agriculturalHoldingData?.id}/money/loans/loan`)
                      :
                      history.goBack();
                  }}
                />
                <SaveBtn
                  className="button save"
                  loader={false}
                  disabled={!edit}
                  form={true}
                  onClick={(e) => {
                    handleSubmit(true);
                    setShowRequiredError(true);
                    setIsSubmit(true);
                  }}
                />
              </div>
            </div>
            }
          </div>
          {isDisplayed &&
          <div className={`${classes['editBtnSmallScreen']} pl-3`}>
            <EditOutlined
              onClick={() => {
                oneEdit();
              }}
            />
          </div>
          }
        </>
      }
    </div>
  );
};


export default LoanForm;
