import React from 'react';
import StandardTable from "../../../../../components/administration/enums/standardEnums/StandardTable";
import {t} from "react-switch-lang";

const LoanType = () => {
  return (
    <div>
      <StandardTable api={'loan-types'} title={t('administration.enums.title.loanType')} />
    </div>
  );
};

export default LoanType;
