import React, { useEffect, useRef, useState } from 'react';
import ValidationForm from '../../../../../components/form/validationForm/ValidationForm';
import SaveBtn from '../../../../../components/buttons/SaveBtn';
import { axiosPost } from '../../../../../services/axios';
import classes from './AhRequest.module.scss';
import { t } from 'react-switch-lang';
import TextareaField from '../../../../../components/form/textareaField/TextareaField';
import ModalWrapper from '../../../../../components/modal/modalWrapper/ModalWrapper';
import Toast from '../../../../../components/toast/Toast';
import SelectAsyncField from '../../../../../components/form/selectField/SelectAsyncField';

const AhRequest = ({ isModalOpen, setIsModalOpen }) => {
  const formRef = useRef();

  const [requestData, setRequestData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validation = {
    agriculturalHolding: { type: 'selectSingle', required: { value: false } },
    message: { type: 'textarea', required: { value: true }, min: { value: 3 }, max: { value: 200 } },
  };

  const submitData = async () => {
    const payload = {
      ...(requestData?.agriculturalHolding && { agroHolding: { id: requestData.agriculturalHolding.value } }),
      message: requestData.message,
      completed: false
    }

    try {
      const response = await axiosPost('requests', payload);
      if (response.status === 201) {
        // makes a popup that prompts user to go back to login page
        Toast.fire({
          title: t('ahRequest.success'),
          icon: 'success',
        });
        setIsSubmitted(true);
        setIsModalOpen(false);
      }
    } catch (err) {
      Toast.fire({
        title: t('ahRequest.error'),
        icon: 'error',
        iconColor: '#f27474',
      });
    }
  };

  // Effect for clearing modal data after submitting or opening modal
  useEffect(() => {
    setRequestData({});
    setIsSubmitted(false);
  }, [isModalOpen]);


  return (
    <ModalWrapper onClose={() => setIsModalOpen(false)} isModalOpen={isModalOpen} header={<span className={`${classes['title']}`}>{t('ahRequest.title')}</span>}>
      <div className='d-flex flex-column'>
        <div className='w-100 mt-3'>
          <div className='d-flex w-100 justify-content-center'>
            <div style={{ width: '100%' }}>
              <ValidationForm validation={validation} ref={formRef}>
                <div className='d-flex flex-column'>
                  <SelectAsyncField
                    label={t('ahRequest.agroHolding')}
                    placeholder={t('ahRequest.agroHoldingPlaceholder')}
                    value={requestData.agriculturalHolding}
                    name='agriculturalHolding'
                    groupField
                    api='agro-holdings'
                    // apiQuery={`isActive.equals=true`}
                    optionLabel='ahFadnId'
                    optionValue='id'
                    isSearchable={true}
                    searchTerm={'ahFadnId.startsWith'}
                    onChange={(e) => {
                      setRequestData(prevState => ({
                        ...prevState,
                        agriculturalHolding: e,
                      }));
                    }}
                    disabled={false}
                    isClearable={true}
                    pagination={false}
                  />
                  <TextareaField
                    label={t('ahRequest.message')}
                    placeholder={t('ahRequest.messagePlaceholder')}
                    value={requestData.message}
                    name='message'
                    groupField
                    onChange={(e) => {
                      setRequestData(prevState => ({
                        ...prevState,
                        message: e,
                      }));
                    }}
                    disabled={false}
                    rows={6}
                  />
                </div>
                <div className='d-flex w-100 justify-content-end mt-2'>
                  <SaveBtn
                    loaderOnDisabled={false}
                    loader={false}
                    disabled={isSubmitted}
                    label={t('common.send')}
                    className='pl-5 pr-5'
                    onClick={() => {
                      formRef.current.submitForm(submitData);
                    }}
                  />
                </div>
              </ValidationForm>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AhRequest;